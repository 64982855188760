import NavBar from "../../componentes/Navbar";
import {Toast} from "primereact/toast";
import React, {useContext, useEffect, useRef, useState} from "react";
import {Loading} from "../../componentes/Loading";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import ParecerService from "../../app/service/ParecerService";
import {AuthContext} from "../../main/ProvedorAutenticacao";
import {ButtonSm} from "../../componentes/ButtonSm";
import {strings} from "../../utils/strings";
import {OverlayPanel} from "primereact/overlaypanel";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {Calendar} from "primereact/calendar";
import {IsNullOrEmpty} from "../../utils/IsNullOrEmpy";
import {DateFormat, formatDateHoraTela} from "../../utils/DateFormat";
import {coresTipoParecer, findParecerName, statusParecer} from "../../utils/ConstsParecer";
import TipoParecerService from "../../app/service/TipoParecerService";
import {CadastrarParecerDialog} from "../../componentes/Parecer/CadastrarParecerDialog";
import {DetalheParecer} from "./DetalheParecer";
import {useHistory} from "react-router-dom";
import {concatenarCampos, exportToCSV} from "../../utils/ToCsv";
import {AutoCompletePessoas} from "../../componentes/Parecer/AutoCompletePessoas";


function Parecer() {

    const [pareceres, setPareceres] = useState([])
    const [tipoPareceres, setTipoPareceres] = useState([])
    const [parecerDetail, setParecerDetail] = useState(null)

    const [showDialogNewParecer, setShowDialogNewParecer] = useState(false)
    const [loading, setLoading] = useState(false)
    const toast = useRef(null)
    const op = useRef(null)

    const {usuarioAutenticado} = useContext(AuthContext)
    const parecerService = new ParecerService()
    const tipoParecerService = new TipoParecerService()


    const navigation = useHistory()

    const [filtro, setFiltro] = useState({
        dataInicio: '',
        dataFim: '',
        pessoa: null,
        dataRecebimentoInicio: '',
        dataRecebimentoFim: '',
        protocoloCitrus: '',
        status: null,
        tipoParecer: null
    });


    // Exemplo de como atualizar um campo do filtro
    const handleChange = (event) => {

        const {name, value} = event.target;
        setFiltro(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    function buscarParecerPorFiltro() {
        const {
            dataInicio,
            dataFim,
            dataRecebimentoInicio,
            dataRecebimentoFim,
            status,
            tipoParecer,
        } = filtro;
        let newFiltro = {...filtro}
        //formatando as datas para envio pro back-end
        if (!IsNullOrEmpty(dataInicio)) {
            newFiltro.dataInicio = DateFormat(dataInicio)
        }
        if (!IsNullOrEmpty(dataFim)) {
            newFiltro.dataFim = DateFormat(dataFim)
        }
        if (!IsNullOrEmpty(dataRecebimentoInicio)) {
            newFiltro.dataRecebimentoInicio = DateFormat(dataRecebimentoInicio)
        }
        if (!IsNullOrEmpty(dataRecebimentoFim)) {
            newFiltro.dataRecebimentoFim = DateFormat(dataRecebimentoFim)
        }
        if (!IsNullOrEmpty(status)) {
            newFiltro.status = status.valor
        }
        if (!IsNullOrEmpty(tipoParecer)) {
            newFiltro.tipoParecer = tipoParecer.codigo
        }
        setLoading(true)
        parecerService.consultaParecerLista(newFiltro, usuarioAutenticado.codigo).then((res) => {
            setPareceres(res.data)
        }).catch(() => {
            toast.current.show({
                severity: 'error',
                summary: 'Parecer',
                detail: 'Não foi Possivel Carregar os Pareceres',
                life: 4000
            });

        })
            .finally(() => {
                setLoading(false)
            })
    }

    function openDialogCadastrarParecer() {
        setShowDialogNewParecer(true)
    }

    function carregarTipoParecer() {
        const codigoEmpresa = usuarioAutenticado.empresa.codigo
        tipoParecerService.buscarTipoParecerPorEmpresa(codigoEmpresa).then((res) => {
            setTipoPareceres(res.data)
        })
    }

    function handleDetalheParecer(e) {
        const parecer = e.data
        navigation.push(`/parecerDetalhe/${parecer.codigo}`)
    }

    const handleExportClick = () => {
        const columnNames = ["Codigo", "Tipo de parecer", "SLA", "Protocolo cítrou", "Pessoa", "Data de criacao", "Status", "Data de recebimento", "Responsavel", "Data de resposta"]
        let newFilter = {...filtro}
        newFilter.pessoa = newFilter.pessoa ? newFilter.pessoa.nome : ""
        let fileName = "pareceres - " + concatenarCampos(newFilter)
        let items = []
        pareceres.forEach((item) => {
            let newItem = {
                codigo: item.codigo,
                tipoParecer: item.tipo.nome,
                sla: item.tipo.tempoSla,
                codigoCitrus: item.codigoCitrus,
                pessoa: item.pessoa.nome,
                dataCriacao: item.dtParecer,
                status: findParecerName(item).nome,
                dataRecebimento: item.dtRecebimento || "",
                usuarioResponsavel: item.usuarioResponsavel ? item.usuarioResponsavel.nome : "",
                dtParecerResponsavel: item.dtParecerResponsavel || ""
            }
            items.push(newItem)
        })
        if (items.length > 0) {
            exportToCSV(items, fileName, columnNames);
        }
    };

    useEffect(() => {
        buscarParecerPorFiltro()
    }, [])

    const bodyParecerStatus = (parecer) => {
        return (
            <div>
                {findParecerName(parecer).nome}
            </div>
        )
    }
    const bodyData = (data) => {
        return (
            <div>
                {formatDateHoraTela(data)}
            </div>
        )
    }
    const bodyTipoCor = (data) => {
        return (
            <div
                style={{
                    backgroundColor: `${coresTipoParecer[data.tipo.cor]}`,
                    width: '20px', // Defina a largura desejada
                    height: '20px', // Defina a altura desejada
                    borderRadius: '50%', // Torna a borda redonda
                    display: 'inline-block' // Para garantir que a div tenha apenas o tamanho do conteúdo
                }}
            ></div>

        )
    }

    return (
        <>
            <NavBar/>
            <Toast ref={toast}/>
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-primary card-outline">
                                    <div className="card-header">
                                        <div className={"flex-1 flex-row justify-content-between"}>
                                            <div className={"flex-row gap-1 align-center"}>
                                                <h3 className="card-title">
                                                    <i className="fas fa-regular fa-file"></i>
                                                    <span> Pareceres</span>
                                                </h3>
                                                <div className="flex-row">
                                                    <div>
                                                  <span className="input-group">
                                                    <div className="flex-row gap-1 space-right">
                                                      <ButtonSm id="CadParecer" type="button"
                                                                className={strings.buttonPrimarySm}
                                                                onClick={openDialogCadastrarParecer}
                                                                aria-haspopup aria-controls="overlay_panel">
                                                      <i className="fas fa-plus"></i> Cadastrar</ButtonSm>
                                                    <ButtonSm id="FiltroAv" type="button"
                                                              className={strings.buttonSecondarySm}
                                                              data-toggle="modal"
                                                              title="Filtro avançado"
                                                              onClick={(e) => op.current.toggle(e)}
                                                              aria-haspopup aria-controls="overlay_panel">
                                                      <i className="fas fa-filter"></i></ButtonSm>
                                                    </div>

                                                    <OverlayPanel ref={op} showCloseIcon id="overlay_panel"
                                                                  style={{width: '750px'}}
                                                                  className="overlaypanel-demo"
                                                                  onShow={carregarTipoParecer}
                                                    >

                                                      <div className="row">
                                                        <div className="col-sm-6">
                                                          <div className="form-group">
                                                            <label>Data Inicio</label>
                                                            <Calendar id="ini"
                                                                      name={"dataInicio"}
                                                                      value={filtro.dataInicio}
                                                                      onChange={handleChange}
                                                                      className="p-inputtext-sm p-d-block p-mb-1"
                                                                      locale="es"
                                                                      style={{width: '100%', height: '37px'}}
                                                                      dateFormat="dd/mm/yy"
                                                                      mask="99/99/9999"/>

                                                          </div>

                                                        </div>
                                                        <div className="col-sm-6">
                                                          <div className="form-group">
                                                            <label>Data Final</label>
                                                            <Calendar id="fim-datafim-calenter-1"
                                                                      name={"dataFim"}
                                                                      value={filtro.dataFim}
                                                                      onChange={handleChange}
                                                                      className="p-inputtext-sm p-d-block p-mb-1"
                                                                      locale="es"
                                                                      style={{width: '100%', height: '37px'}}
                                                                      dateFormat="dd/mm/yy"
                                                                      mask="99/99/9999"/>
                                                          </div>
                                                      </div>
                                                        </div>
                                                        <div className="row">
                                                        <div className="col-sm-6">
                                                          <div className="form-group">
                                                            <label>Pessoa </label>
                                                              <AutoCompletePessoas
                                                                  selectPessoa={filtro.pessoa}
                                                                  setSelect={handleChange}
                                                              />
                                                          </div>

                                                        </div>
                                                        <div className="col-sm-6">
                                                          <div className="form-group">
                                                            <label>Nº do documento</label>
                                                            <InputText id="proto-sfhgskjfhsdjkfhsd"
                                                                       type="text"
                                                                       style={{width: '100%'}}
                                                                       name={"protocoloCitrus"}
                                                                       value={filtro.protocoloCitrus}
                                                                       onChange={handleChange}
                                                                       className="p-inputtext-sm p-d-block p-mb-1"/>
                                                          </div>
                                                      </div>


                                                        </div>

                                                        <div className="row">
                                                        <div className="col-sm-6">
                                                          <div className="form-group">
                                                            <label>Data de Recebimento Inicio</label>
                                                               <Calendar
                                                                   locale="es"
                                                                   style={{width: '100%', height: '37px'}}
                                                                   dateFormat="dd/mm/yy"
                                                                   mask="99/99/9999"
                                                                   id="ini-fsddfdsfs"
                                                                   name={"dataRecebimentoInicio"}
                                                                   value={filtro.dataRecebimentoInicio}
                                                                   onChange={handleChange}/>

                                                          </div>

                                                        </div>
                                                        <div className="col-sm-6">
                                                          <div className="form-group">
                                                            <label>Data de Recebimento Final</label>
                                                              <Calendar
                                                                  locale="es"
                                                                  style={{width: '100%', height: '37px'}}
                                                                  dateFormat="dd/mm/yy"
                                                                  mask="99/99/9999"
                                                                  name={"dataRecebimentoFim"}
                                                                  value={filtro.dataRecebimentoFim}
                                                                  onChange={handleChange}
                                                              />
                                                          </div>
                                                      </div>
                                                        </div>
                                                        <div className="row">
                                                        <div className="col-sm-6">
                                                          <div className="form-group">
                                                            <label>Tipo de Parecer</label>
                                                               <Dropdown
                                                                   value={filtro.tipoParecer}
                                                                   name={"tipoParecer"}
                                                                   optionLabel="nome"
                                                                   options={tipoPareceres}
                                                                   filter showClear filterBy="nome"
                                                                   style={{width: '100%'}}
                                                                   onChange={handleChange}
                                                                   id="tipop-id-no-internal"
                                                                   className="p-inputtext-sm p-d-block p-mb-1"/>
                                                          </div>

                                                        </div>
                                                        <div className="col-sm-6">
                                                          <div className="form-group">
                                                            <label>Status</label>
                                                              <Dropdown
                                                                  value={filtro.status}
                                                                  name={"status"}
                                                                  optionLabel="nome"
                                                                  options={statusParecer}
                                                                  filter showClear filterBy="nome"
                                                                  style={{width: '100%'}}
                                                                  onChange={handleChange}
                                                                  id="status-id-no-internal"
                                                                  className="p-inputtext-sm p-d-block p-mb-1"/>
                                                          </div>
                                                      </div>
                                                        </div>
                                                      <div className="direita">
                                                        <ButtonSm type="submit" className={strings.buttonSuccessSm}
                                                                  onClick={buscarParecerPorFiltro}>
                                                          <i className="fas fa-check"></i>Aplicar
                                                        </ButtonSm>
                                                      </div>


                                                    </OverlayPanel>

                                                  </span>
                                                    </div>

                                                </div>
                                            </div>
                                            <ButtonSm id='BtnExp' type="button"
                                                      className={strings.buttonPrimarySm}
                                                      onClick={handleExportClick}
                                                      aria-haspopup aria-controls="overlay_panel">
                                                <i className="pi pi-file-excel"></i> Exportar</ButtonSm>
                                        </div>
                                    </div>
                                    <div className={"card-body"}>
                                        <Loading open={loading}/>

                                        <DataTable id="TableParecer" value={pareceres} paginator
                                                   responsiveLayout="scroll"
                                                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                                   currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}"
                                                   rows={10} emptyMessage=" "
                                                   onRowClick={handleDetalheParecer}
                                                   selectionMode="multiple">
                                            <Column field="codigo" header="Código"></Column>
                                            <Column field="tipo.nome" header="Tipo de Parecer"></Column>
                                            <Column field="tipo.tempoSla" header="SLA"></Column>
                                            <Column body={bodyTipoCor} header="Urgência"></Column>
                                            <Column field="codigoCitrus" header="Nº do documento"></Column>
                                            <Column field="pessoa.nome" header="Pessoa"></Column>
                                            <Column body={parecer => bodyData(parecer.dtParecer)}
                                                    header="Data de Criação"></Column>
                                            <Column body={bodyParecerStatus} header="Status"></Column>
                                            <Column body={parecer => bodyData(parecer.dtRecebimento)}
                                                    header="Data de recebimento"></Column>
                                            <Column field="usuarioResponsavel.nome" header="Responsavel"></Column>
                                            <Column body={parecer => bodyData(parecer.dtParecerResponsavel)}
                                                    header="Data de Resposta"></Column>
                                        </DataTable>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {showDialogNewParecer &&
                <CadastrarParecerDialog open={showDialogNewParecer} close={() => setShowDialogNewParecer(false)}
                                        toast={toast} refresh={buscarParecerPorFiltro}/>}
            {parecerDetail && <DetalheParecer open={!!parecerDetail} close={() => setParecerDetail(null)} toast={toast}
                                              parecer={parecerDetail}/>
            }        </>
    )
}

export default Parecer
