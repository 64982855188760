export const EmpresaConsts = {
   AGUARDANDO_CONFIRMACAO_EMAIL : 1,
   ATIVO : 2,
   SUSPENSA : 3,
   CANCELADA : 4,

   TRY : 1,
   STARTED : 2,
   STARTED_YEAR : 3,
   STANDART : 4,
   STANDART_YEAR : 5,
   ENTERPRISE : 6,

   ETRIUM : "39.327.910/0001-19",

   getEmpresaStatusString(status){
      if(status === EmpresaConsts.ATIVO){
         return "Ativo"
      }else if(status === EmpresaConsts.SUSPENSA){
         return "Suspensa"
      }else if(status === EmpresaConsts.CANCELADA){
         return "Cancelada"
      }else{
         return "Aguardando confirmar email"
      }
   }
}


