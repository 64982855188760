import {Button} from "primereact/button";
import {strings} from "../../utils/strings";
import React, {useContext, useState} from "react";
import {Dialog} from "primereact/dialog";
import {AuthContext} from "../../main/ProvedorAutenticacao";
import {InputText} from "primereact/inputtext";
import {IsNullOrEmpty} from "../../utils/IsNullOrEmpy";
import TipoParecerService from "../../app/service/TipoParecerService";


export const CadastrarTipoParecerDialog = ({open, close, toast, reloadData}) => {

    const [state, setState] = useState({
        nome: '',
        empresa: null,
        cor: "",
        tempoSla: 0

    });

    function stateChange(e) {
        const {name, value} = e.target
        setState({
            ...state,
            [`${name}`]: value
        });

    }
    const tipoParecerService = new TipoParecerService()

    const {usuarioAutenticado, setLoading} = useContext(AuthContext);


    function cadastrarTipoParecer (){
        if(IsNullOrEmpty(state.nome)){
            toast.show({
                severity: 'error',
                summary: 'Tipo parecer',
                detail: `Campo nome é obrigatório`,
                life: 4000
            });
            return
        }
        if(state.tempoSla <= 0){
            toast.show({
                severity: 'error',
                summary: 'Tipo parecer',
                detail: `Campo SLA é obrigatório`,
                life: 4000
            })
            return
        }
        if(IsNullOrEmpty(state.cor)){
            toast.show({
                severity: 'error',
                summary: 'Tipo parecer',
                detail: `Campo cor é obrigatório`,
                life: 4000
            })

            return
        }
        setLoading(true)
        let newTipo = state
        newTipo.empresa = usuarioAutenticado.empresa
        tipoParecerService.cadastrarTipoParecer(newTipo)
            .then(()=>{
                toast.show({
                    severity: 'success',
                    summary: 'Tipo parecer',
                    detail: `Cadastro realizado.`,
                    life: 4000
                })
                reloadData()
                close()
            })
            .catch(()=>{
                toast.show({
                    severity: 'error',
                    summary: 'Tipo parecer',
                    detail: `Erro ao Cadastrar novo tipo parecer`,
                    life: 4000
                })
            })
            .finally(()=>{
                setLoading(false)
            })
    }



    function renderFooter() {
        return (
            <div className="row-etrium gap-1">
                <Button label="Cadastrar"
                        className={strings.buttonPrimaryStyle}
                    onClick={cadastrarTipoParecer}
                />
            </div>
        );
    }

    return (<>
        <Dialog header="Cadastrar tipo parecer"
                visible={open}
                style={{width: '40vw'}}
                footer={renderFooter}
                onHide={close}>

            <div className="p-fluid">
                <div className="p-field mb-3">
                    <label htmlFor="nome">Nome<span className="obrigatorioAsterisco"> *</span></label>
                    <InputText id="nome"
                               name={"nome"}
                               type="text"
                               maxLength={50}
                               value={state.nome}
                               onChange={stateChange}/>
                </div>
                <div className="p-field  mb-3">
                    <label htmlFor="cor">SLA <span className="obrigatorioAsterisco"> *</span></label>
                    <InputText id="tempoSla"
                               name="tempoSla"
                               type="number"
                               value={state.tempoSla}
                               onChange={stateChange}/>
                </div>
                <div style={{display: 'inline-block', position: 'relative'}}>
                    <div className="p-field">
                        <label htmlFor="descricao">Cor <span className="obrigatorioAsterisco">*</span></label>
                        <div
                            onClick={() => {
                                document.getElementById('colorPicker').click();
                            }}
                            style={{
                                backgroundColor: `${state.cor}`,
                                width: '35px',
                                height: '35px',
                                border: '2px solid #000',
                                borderRadius: '50%',
                                position: 'relative', // adicione a posição relativa para posicionar o seletor de cores
                                cursor: 'pointer', // adicione um cursor para indicar que é clicável
                            }}
                        >
                            <input
                                id="colorPicker"
                                name="cor"
                                type="color"
                                style={{
                                    // display: 'none',
                                    opacity: 0

                                }}
                                value={state.cor}
                                onChange={stateChange}
                            />
                        </div>
                    </div>
                </div>

            </div>

        </Dialog>
    </>)
}
