import ApiService from "../apiservice";

class EmpresaGraficoService extends ApiService {

    constructor() {
        super("/empresa/graficos");
    }

    findStatus(dataIni, dataFim) {
        return this.get(`/status?dataInicial=${dataIni}&dataFinal=${dataFim}`)
    }

    findQauntidadeProcessosAndAtividades(dataIni, dataFim) {
        return this.get(`/processosAndAtividades?dataInicial=${dataIni}&dataFinal=${dataFim}`)
    }
    findEmpresaDetailInfo(codigoEmpresa) {
        return this.get(`/empresaDetail?empresa=${codigoEmpresa}`)
    }

}
export default EmpresaGraficoService
