import React from 'react'

import { withRouter } from 'react-router-dom'

import { Toast } from 'primereact/toast'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'

import TipoDespesaReceitaService from '../../app/service/TipoDespesaReceita'
import NavBar from '../../componentes/Navbar'
import Progresso from '../../componentes/Progresso'
import { AuthContext } from '../../main/ProvedorAutenticacao'
import {strings} from "../../utils/strings";
import {Loading} from "../../componentes/Loading";
import {IsNullOrEmpty} from "../../utils/IsNullOrEmpy";

class TiposDespesasReceitas extends React.Component {
  constructor(props) {
    super(props);

    this.tipoDespesaReceitaService = new TipoDespesaReceitaService();

    this.acoes = this.acoes.bind(this);
    this.onChangeTipo = this.onChangeTipo.bind(this);
    this.renderFooterCadastroTipos = this.renderFooterCadastroTipos.bind(this);
    this.renderFooterAlterarTipos = this.renderFooterAlterarTipos.bind(this);
    this.accept = this.accept.bind(this);

    this.state = {
      //cadastro
      codigo: '',
      tipoDespesaReceita: null,
      nome: '',
      tipo: null,
      empresa: null,
      tipos: [],
      modalCadastro: false,
      modalAlterar: false,
      visivble: false,
      detail: '',
        loading: true
    };

    this.tiposCodigos = [
      {codigo: 'D', nome: 'Despesa'},
      {codigo: 'R', nome: 'Receita'}
    ]
  }

  fecharModalCadastro(name) {
    this.setState({
        [`${name}`]: false
    });
  }

  abrirModalCadastro(name, position) {
    let state = {
        [`${name}`]: true
    };

    if (position) {
        state = {
            ...state,
            position
        }
    }
    this.limparCampos();
    this.setState(state);
  }

  abrirModaAlterar(name, position) {
    let state = {
        [`${name}`]: true
    };

    if (position) {
        state = {
            ...state,
            position
        }
    }

    this.setState(state);
  }

  limparCampos = () => {
    this.setState({nome: ''});
  }

  listarTipos = async () => {
    this.setState({classeDiv: 'show'});
    const usuarioLogado = this.context.usuarioAutenticado

    await this.tipoDespesaReceitaService.listarTiposDespesasReceitas(usuarioLogado.empresa.codigo)
      .then(response => {
        this.setState({tipos: response.data});
      }).catch(error => {
        this.setState(error.response.data);
        this.toast.show({severity:'error', summary: 'Tipos de despesas e receitas', detail:`${this.state.detail}`, life: 4000});
      })

      this.setState({classeDiv: 'hide',loading: false});
  }

  cadastrarTopoDespesaReceita = async () => {
    this.setState({classeDiv: 'show'});
    const usuarioLogado = this.context.usuarioAutenticado

    if(IsNullOrEmpty(this.state.nome)) {
      this.toast.show({severity:'error', summary: 'Tipos de despesa e receitas', detail:'Informe o nome do tipo de despesa ou receita', life: 4000});
      this.setState({classeDiv: 'hide'});
      return false;
    }

    if(IsNullOrEmpty(this.state.tipo)) {
      this.toast.show({severity:'error', summary: 'Tipos de despesa e receitas', detail:'Informe o tipo de despesa ou receita', life: 4000});
      this.setState({classeDiv: 'hide'});
      return false;
    }

    await this.tipoDespesaReceitaService.cadastrarTipoDespesaReceita({
      nome: this.state.nome,
      tipo: this.state.tipo.codigo,
      empresa: usuarioLogado.empresa
    }).then(response => {
      this.toast.show({severity:'success', summary: 'Tipos de despesa e receitas', detail:'Tipo de despesa ou receita cadastrada com sucesso', life: 4000});
      this.listarTipos();
      this.fecharModalCadastro('modalCadastro');
    }).catch(error => {
      this.setState(error.response.data);
      this.toast.show({severity:'error', summary: 'Tipos de despesas e receitas', detail:`${this.state.detail}`, life: 4000});
    })
  }

  async consultarTipoDespesaReceita(tipo) {
    await this.tipoDespesaReceitaService.consultarTipoDespesaReceita(tipo.codigo)
      .then(response => {
        this.setState({tipoDespesaReceita: response.data});
        this.setState({codigo: response.data.codigo});
        this.setState({nome: response.data.nome});
        if(response.data.tipo === 'D') {
          this.setState({tipo: {
            codigo: 'D',
            nome: 'Despesa'
          }})
        } else {
          this.setState({tipo: {
            codigo: 'R',
            nome: 'Receita'
          }})
        }
        this.setState({empresa: response.data.empresa});
        this.abrirModaAlterar('modalAlterar');
      }).catch(error => {
        this.setState(error.response.data);
        this.toast.show({severity:'error', summary: 'Tipos de despesas e receitas', detail:`${this.state.detail}`, life: 4000});
      })
  }

  acoes(rowData) {
    return(
      <div className="row gap-1">
        <Button icon="pi pi-pencil" className={strings.buttonIconSecundary}
                onClick={() => this.consultarTipoDespesaReceita(rowData)}
                tooltip="Editar tipo de despesa ou receita"
                tooltipOptions={{position: 'top'}} />
        <Button icon="pi pi-trash" className={strings.buttonIconDanger}
                onClick={() => this.confirmaExclusao(rowData)}
                tooltip="Excluir tipo de receita ou despesa"
                tooltipOptions={{position: 'top'}}/>
      </div>
    );
  }

  alterarTiposDespesaReceita = async () => {
    this.setState({classeDiv: 'show'});
    const usuarioLogado = this.context.usuarioAutenticado

    if(IsNullOrEmpty(this.state.nome)) {
      this.toast.show({severity:'error', summary: 'Tipos de despesa e receitas', detail:'Informe o nome do tipo de despesa ou receita', life: 4000});
      this.setState({classeDiv: 'hide'});
      return false;
    }

    if(IsNullOrEmpty(this.state.tipo)) {
      this.toast.show({severity:'error', summary: 'Tipos de despesa e receitas', detail:'Informe o tipo de despesa ou receita', life: 4000});
      this.setState({classeDiv: 'hide'});
      return false;
    }

    this.tipoDespesaReceitaService.alterarTipoDespesaReceita({
      codigo: this.state.codigo,
      nome: this.state.nome,
      tipo: this.state.tipo.codigo,
      empresa: usuarioLogado.empresa
    }).then(response => {
      this.toast.show({severity:'success', summary: 'Tipos de despesa e receitas', detail:'Tipo de despesa e receita alterado com sucesso', life: 4000});
      this.listarTipos();
      this.fecharModalCadastro('modalAlterar')
    }).catch(error => {
      this.setState(error.response.data);
      this.toast.show({severity:'error', summary: 'Tipos de despesas e receitas', detail:`${this.state.detail}`, life: 4000});
      this.setState({classeDiv: 'hide'});
    })

    this.setState({classeDiv: 'hide'});
  }

  renderFooterCadastroTipos(name) {
    return (
        <div className="row-etrium gap-1 ">
            <Button label="Cadastrar"
                    className={strings.buttonPrimaryStyle}
                    onClick={this.cadastrarTopoDespesaReceita}/>

        </div>
    );
  }

  renderFooterAlterarTipos(name) {
    return (
        <div className="row gap-1">
            <Button label="Alterar"
                    className={strings.buttonPrimaryStyle}
                    onClick={this.alterarTiposDespesaReceita}/>

        </div>
    );
  }

  componentDidMount() {
    this.listarTipos();
  }

  onChangeTipo(e) {
    this.setState({tipo: e.value});
  }

  confirmaExclusao(tipo) {
    this.setState({codigo: tipo.codigo});
    confirmDialog({
        message: 'Deseja realmente excluir o tipo de despesa ou receita?',
        header: 'Exclusão de tipos de despesas ou receitas',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        accept: this.accept,
        reject: this.reject
    });
  }

  excluirTipoDespesaReceita = async() => {
    this.setState({classeDiv: 'show'});
    await this.tipoDespesaReceitaService.excluirTipoDespesaReceita(this.state.codigo)
      .then(response => {
        this.toast.show({severity:'success', summary: 'Tipos de despesas e receitas', detail:'Tipo de despesa ou receita excluído com sucesso', life: 4000});
        this.listarTipos();
      }).catch(error => {
        this.setState(error.response.data);
        this.toast.show({severity:'error', summary: 'Garantias de processo', detail:`${this.state.detail}`, life: 4000});
      });

      this.setState({classeDiv: 'hide'});
  }

  accept() {
    this.excluirTipoDespesaReceita();
  }

  render() {
    return(
      <>
          <Loading open={this.state.loading} />
          <NavBar/>
        <Toast ref={(el) => this.toast = el} />
        {/**Cadastro de Tipos de Despesas e Receitas */}
        <div className="content-wrapper">
          <Dialog header="Cadastro de tipos de despesas e receitas"
                  visible={this.state.modalCadastro}
                  style={{ width: '40vw' }}
                  footer={this.renderFooterCadastroTipos('modalCadastro')}
                  onHide={() => this.fecharModalCadastro('modalCadastro')}>

              <div className="row">
                <div className="col-sm-12">
                  <div className={"form-group"}>
                    <label htmlFor="tipo">Tipo<span className="obrigatorioAsterisco"> *</span></label>
                    <Dropdown value={this.state.tipo}
                              options={this.tiposCodigos} style={{width: '100%', height: '37px'}}
                              optionLabel="nome"
                              onChange={this.onChangeTipo}
                              id="tipoPagamento"
                              className="p-inputtext-sm p-d-block p-mb-1"/>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className={"form-group"}>
                    <label htmlFor="nomeTipo">Nome<span className="obrigatorioAsterisco"> *</span></label>
                    <InputText id="nomeTipo"
                                type="text"
                               maxLength={50}
                                value={this.state.nome} style={{width: '100%', height: '37px'}}
                                onChange={e => this.setState({nome: e.target.value})}
                                className="p-inputtext-sm p-d-block p-mb-1"/>
                  </div>
                </div>
              </div>
          </Dialog>
          {/**Alterar Tipos de Despesas e Receitas */}
          <Dialog header="Alterar tipos de despesas e receitas"
                  visible={this.state.modalAlterar}
                  style={{ width: '40vw' }}
                  footer={this.renderFooterAlterarTipos('modalAlterar')}
                  onHide={() => this.fecharModalCadastro('modalAlterar')}>

              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label htmlFor="tipoAlterar">Tipo<span className="obrigatorioAsterisco"> *</span></label>
                    <Dropdown value={this.state.tipo}
                              options={this.tiposCodigos} style={{width: '100%', height: '37px'}}
                              optionLabel="nome"
                              onChange={this.onChangeTipo}
                              id="tipoAlterar"
                              className="p-inputtext-sm p-d-block p-mb-1"/>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label htmlFor="nomeTipoAlterar">Nome<span className="obrigatorioAsterisco"> *</span></label>
                    <InputText id="nomeTipoAlterar"
                                type="text"
                                maxLength={50}
                                value={this.state.nome} style={{width: '100%', height: '37px'}}
                                onChange={e => this.setState({nome: e.target.value})}
                                className="p-inputtext-sm p-d-block p-mb-1"/>
                  </div>
                </div>
              </div>
          </Dialog>
          <ConfirmDialog visible={this.state.visible}
                         onHide={() => this.setState({ visible: false })}
                         message="Are you sure you want to proceed?"
                         header="Confirmation"
                         icon="pi pi-exclamation-triangle"
                         accept={() => this.excluirTipoDespesaReceita}
                         reject={() => this.setState({visible: false})}/>

          <section className="content-header">
              <div className="container-fluid">
              <div className="row">
                  <div className="col-md-12">
                    <div className="card card-primary card-outline">
                      <div className="card-header">
                        <h3 className="card-title">
                          <i className="fas fa-hand-holding-usd"></i>
                          <span> Tipos de despesas e receitas</span>
                        </h3>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-5">
                            <span className="input-group">
                              <Button className={strings.buttonPrimarySm}
                                      onClick={() => this.abrirModalCadastro('modalCadastro')}
                                      data-toggle="modal"
                                      data-target="#modal-default">
                                  <div className="gap-3 flex-row">
                                       <i className="fas fa-plus"></i>Cadastrar
                                  </div>
                             </Button>
                            </span>
                          </div>
                        </div>
                      <Progresso classeSecundaria={this.state.classeDiv}/>
                      </div>
                      <DataTable value={this.state.tipos}  paginator responsiveLayout="scroll"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}" emptyMessage="Nenhum tipo de despesa ou receita cadastrados" rows={10}>
                        <Column field="nome" header="Nome"></Column>
                        <Column field="tipoCalculado" header="Tipo"></Column>
                        <Column body={this.acoes} exportable={false} style={{ minWidth: '8rem' }} header="Ações"></Column>
                      </DataTable>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
      </>
    )
  }
}

TiposDespesasReceitas.contextType = AuthContext;
export default withRouter (TiposDespesasReceitas)
