import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {confirmDialog} from "primereact/confirmdialog";
import React, {useContext} from "react";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {strings} from "../../../utils/strings";
import {useHistory} from "react-router-dom";
import {AuthContext} from "../../../main/ProvedorAutenticacao";
import ProcessoService from "../../../app/service/ProcessoService";
import ReactToPrint from "react-to-print";
import {ButtonSm} from "../../ButtonSm";
import {exportToCSV} from "../../../utils/ToCsv";
import {DateFormatHour} from "../../../utils/DateFormat";
import {ProcessosFiltradosComponentPrint} from "./ProcessosFiltradosComponentPrint";


export const ProcessosFiltradoDialog = ({processos, open, onClose, toast, refresh, tipo})=>{

    const navigation = useHistory()
    const {usuarioAutenticado} = useContext(AuthContext);

    const componentRef = React.useRef(null)

    // service
    const processoService = new ProcessoService()
    function editarProcesso(processo) {
        navigation.push(`/fichaProcesso/${processo.codigo}`);
    }

    function confirmaExclusaoProcesso(processo) {
        confirmDialog({
            message: 'Deseja realmente excluir este processo ?',
            header: 'Exclusão de processo',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: ()=>excluirProcesso(processo)
        });
    }

    const excluirProcesso = async (processo) => {
        await processoService.excluirProcesso({
            codigo: processo.codigo,
            status: 3, // Processo excluído
            usuario: usuarioAutenticado
        })
            .then(() => {
                toast.show({ severity: 'success', summary: 'Processos', detail: 'Processo excluído com sucesso', life: 4000 });

            }).finally(refresh)

    }

    const  handleClickExportToCSV = () => {
        if (processos.length > 0) {
            const colunas = ["Pasta", "Numeração única", "Grupo de Trabalho"]
            const processosFormatToExport = processos.map(processo => {
                return {
                    pasta: processo.pasta,
                    numeracao: processo.nrCnj,
                    grupoTrabalho: processo.grupoTrabalho.nome
                }
            })
            const fileName = `Relatorio Painel Gerencial Processos - ${tipo} - ${DateFormatHour(new Date())}`
            exportToCSV(processosFormatToExport, fileName, colunas)
        }
    }

    const acoes = (rowData)=>{
      return(  <div className="flex-1 gap-1">
            <Button icon="pi pi-folder-open" className="p-button-rounded p-button-text"
                    tooltip="Abrir processo"
                    onClick={() => editarProcesso(rowData)}
                    tooltipOptions={{ position: 'top' }} />
            <Button icon="pi pi-trash" className={strings.buttonIconDanger}
                    tooltip="Excluir processo"
                    onClick={() => confirmaExclusaoProcesso(rowData)}
                    tooltipOptions={{ position: 'top' }} />
        </div>)
    }

    const footer=()=>{
        return(
            <div className="flex-row gap-2">
                <ReactToPrint
                    // eslint-disable-next-line
                    trigger={() => <ButtonSm
                        className={strings.buttonPrimarySm}
                    > <i className="pi pi-print"></i> Imprimir</ButtonSm>}
                    content={() => componentRef.current}>
                </ReactToPrint>

                <ButtonSm
                    id='BtnExp' type="button"
                    className={strings.buttonPrimarySm}
                    onClick={handleClickExportToCSV}
                    aria-haspopup aria-controls="overlay_panel">
                    <i className="pi pi-file-excel"></i>
                    Exportar</ButtonSm>
            </div>
        )

    }

    return(
        <>
            <Dialog header=" Processos e casos"
                    visible={open}
                    footer={footer}
                    onHide={onClose}>
            <DataTable value={processos} paginator responsiveLayout="scroll"
                       paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                       currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}" rows={10}
                       paginatorRight selectionMode="multiple"
                       emptyMessage="Nenhum processo encontrado"
            >
                <Column field="pasta" header="Pasta" style={{ width: '200px' }}></Column>
                {/*<Column field="nrProcesso" header="Processo Original" style={{ width: '180px' }}></Column>*/}
                <Column field="nrCnj" header="Numeração única" style={{ width: '180px' }}></Column>
                {/*<Column field="autor" header="Autor x Réu" style={{ width: '300px' }}></Column>*/}
                <Column field="grupoTrabalho.nome" header="Grupo trab." style={{ width: '180px' }}></Column>

                <Column body={acoes} exportable={false} style={{ width: '150px' }} header="Ações"></Column>
            </DataTable>
            </Dialog>
            <div hidden>
<                ProcessosFiltradosComponentPrint processos={processos} filtro={tipo} quantidade={processos.length} ref={componentRef}/>
            </div>

        </>

    )
}
