import React from 'react'
import { withRouter } from 'react-router-dom'

import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { Dropdown } from 'primereact/dropdown'
import { OverlayPanel } from 'primereact/overlaypanel';
import { InputText } from 'primereact/inputtext'
import { Tag } from 'primereact/tag';
import { Checkbox } from 'primereact/checkbox';
import { ButtonSm } from '../../componentes/ButtonSm'

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import NavBar from '../../componentes/Navbar'

import LocalStorageService from '../../app/service/localStorageService'
import ProcessoService from '../../app/service/ProcessoService'
import GrupoTrabalhoService from '../../app/service/GrupoTrabalhoService'
import TipoAcaoService from '../../app/service/TipoAcaoService'
import StatusProcessualService from '../../app/service/StatusProcessualService'
import TipoDecisaoService from '../../app/service/TipoDecisao'
import ProcessoImportanciaService from '../../app/service/ProcessoImportanciaService'
import { AuthContext } from '../../main/ProvedorAutenticacao'
import {Loading} from "../../componentes/Loading";

import './processo.css'
import {strings} from "../../utils/strings";
import {IsNullOrEmpty} from "../../utils/IsNullOrEmpy";

class Processo extends React.Component {

  constructor(props) {
    super(props);

    this.processoService = new ProcessoService();
    this.grupoTrabalhoService = new GrupoTrabalhoService();
    this.tipoAcaoService = new TipoAcaoService();
    this.statusProcessualService = new StatusProcessualService();
    this.tipoDecisaoService = new TipoDecisaoService();
    this.processoImportanciaService = new ProcessoImportanciaService();
    this.onGrupoChange = this.onGrupoChange.bind(this);
    this.onTiposAcaoChange = this.onTiposAcaoChange.bind(this);
    this.onTiposDecisaoChange = this.onTiposDecisaoChange.bind(this);
    this.onStatusProcessualChange = this.onStatusProcessualChange.bind(this);
    this.acoes = this.acoes.bind(this);
    this.arquivado = this.arquivado.bind(this);
    this.importante = this.importante.bind(this);
    this.accepExcluirProcesso = this.accepExcluirProcesso.bind(this);

    this.state = {
      processos: [],
      processo: null,
      //Filtro Processo
      pessoa: '',
      gruposTrabalho: [],
      grupoTrabalho: null,
      tiposAcao: [],
      tipoAcao: null,
      statusProcessuais: [],
      statusProcessual: null,
      tiposDecisao: [],
      tipoDecisao: null,
      pasta: '',
      numeroProcesso: '',
      numeroCnj: '',
      consultaGeral: '',
      visible: false,
      //Fim de Filtro
      classeDiv: 'hide',
      detail: '',
      //Processo Importancia
      iconeImportanteParaMim: '',
      importanteParaMim: false,
      openSpinner: false,
    };
  }

  carregaCombosConsulta = () => {
    this.setState({openSpinner: true});
    const usuarioLogado = this.context.usuarioAutenticado;
    this.limparCamposConsulta();
    this.listarGruposTrabalho(usuarioLogado);
    this.listarTiposAcao(usuarioLogado.empresa);
    this.listarStatusProcessual(usuarioLogado.empresa);
    this.listarTiposDecisao(usuarioLogado.empresa);
    this.setState({openSpinner: false});
  }

  limparCamposConsulta = () => {
    this.setState({
      pessoa: '',
      grupoTrabalho: null,
      tipoAcao: null,
      statusProcessual: null,
      tipoDecisao: null,
      pasta: '',
      numeroProcesso: '',
      numeroCnj: '',
      indice: ''
    })
  }

  listarGruposTrabalho = (usuario) => {
    this.grupoTrabalhoService.listarGruposUsuario(usuario.codigo)
      .then(response => {
        this.setState({ gruposTrabalho: response.data });
      }).catch(() => {
        this.toast.show({ severity: 'error', summary: 'Processos', detail: 'Erro ao carregar grupos de trabalhos do usuário', life: 4000 });
      });
  }

  listarTiposAcao = (empresa) => {
    this.tipoAcaoService.listarTipoAcao(empresa.codigo)
      .then(response => {
        this.setState({ tiposAcao: response.data });
      }).catch(() => {
        this.toast.show({ severity: 'error', summary: 'Processos', detail: 'Erro ao carregar tipos de ação', life: 4000 });
      })
  }

  listarStatusProcessual = (empresa) => {
    this.statusProcessualService.listarStatusProcessual(empresa.codigo)
      .then(response => {
        this.setState({ statusProcessuais: response.data });
      }).catch(() => {
        this.toast.show({ severity: 'error', summary: 'Processos', detail: 'Erro ao carregar status processual', life: 4000 });
      })
  }

  listarTiposDecisao = (empresa) => {
    this.tipoDecisaoService.listarTiposDecisao(empresa.codigo)
      .then(response => {
        this.setState({ tiposDecisao: response.data });
      }).catch(() => {
        this.toast.show({ severity: 'error', summary: 'Processos', detail: 'Erro ao carregar tipos de decisão', life: 4000 });
      })
  }

  onGrupoChange(e) {
    this.setState({ grupoTrabalho: e.value });
  }

  onTiposAcaoChange(e) {
    this.setState({ tipoAcao: e.value });
  }

  onStatusProcessualChange(e) {
    this.setState({ statusProcessual: e.value });
  }

  onTiposDecisaoChange(e) {
    this.setState({ tipoDecisao: e.value });
  }

  consultarProcessoPorIndice = async () => {
    //this.setState({classeDiv: "show"});
    const usuarioLogado = this.context.usuarioAutenticado;
    const {indice} = this.state;
    if(!IsNullOrEmpty(indice) && indice.length > 2) {
    this.setState({ openSpinner: true });
      const _indice = {
        indice: indice,
        empresa: usuarioLogado.empresa.codigo,
        usuario: usuarioLogado.codigo
      }

      await this.processoService.consultarProcessoPorIndice(_indice)
          .then(response => {
            this.setState({processos: response.data});
            LocalStorageService.adicionarConsulta('_consulta_processo_indice', _indice);
          }).catch(error => {
            this.setState(error.response.data)
            this.toast.show({severity: 'error', summary: 'Processos', detail: `${this.state.detail}`, life: 4000});
            this.setState({openSpinner: false});
          });
    this.setState({ openSpinner: false });
    }else{
      // this.toast.show({ severity: 'error', summary: 'Processos', detail: `Digite pelo menos 3`, life: 4000 });
    }

  }

  listarProcessos = async () => {
    this.setState({ classeDiv: 'show', openSpinner: true });

    // const { pessoa,grupoTrabalho,tipoAcao,statusProcessual,tipoDecisao, pasta } = this.state

    const usuarioLogado = this.context.usuarioAutenticado

    const IPM = this.state.importanteParaMim ? 'SIM' : 'NAO';
    const filtroProcesso = {
      pessoa: this.state.pessoa,
      grupoTrabalho: this.state.grupoTrabalho,
      tipoAcao: this.state.tipoAcao,
      statusProcessual: this.state.statusProcessual,
      tipoDecisao: this.state.tipoDecisao,
      pasta: this.state.pasta,
      numeroProcesso: this.state.numeroProcesso,
      numeroCnj: this.state.numeroCnj,
      empresa: usuarioLogado.empresa,
      usuario: usuarioLogado.codigo,
      importanteParaMim: IPM
    }

    await this.processoService.listarProcessos(filtroProcesso)
      .then(response => {
        this.setState({ processos: response.data });
        LocalStorageService.adicionarConsulta('_consulta_processo_filtro', filtroProcesso);
      }).catch(error => {
        this.setState(error.response.data)
        this.toast.show({ severity: 'error', summary: 'Processos', detail: `${this.state.detail}`, life: 4000 });
      })
    this.setState({ classeDiv: 'hide', openSpinner:false });
    this.op.hide();
  }

  acoes(rowData) {
    return (
      <React.Fragment>
        <div className="flex-1 gap-1">
          <Button icon="pi pi-trash" className={strings.buttonIconDanger}
            tooltip="Excluir processo"
            onClick={() => this.confirmaExclusaoProcesso(rowData)}
            tooltipOptions={{ position: 'top' }} />
        </div>
      </React.Fragment>
    );
  }

  accepExcluirProcesso() {
    this.excluirProcesso();
  }

  confirmaExclusaoProcesso(processo) {
    this.setState({ processo: processo });
    confirmDialog({
      message: 'Deseja realmente excluir este processo ?',
      header: 'Exclusão de processo',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      accept: this.accepExcluirProcesso,
      reject: this.reject
    });
  }

  excluirProcesso = async () => {

    const usuarioLogado = this.context.usuarioAutenticado
    this.setState({openSpinner: true});
    await this.processoService.excluirProcesso({
      codigo: this.state.processo.codigo,
      status: 3, // Processo excluído
      usuario: usuarioLogado
    })
      .then(() => {
        this.toast.show({ severity: 'success', summary: 'Processos', detail: 'Processo excluído com sucesso', life: 4000 });

        const consultaIndiceJson = LocalStorageService.obterConsulta('_consulta_processo_indice');
        // const consultaIndiceJson = JSON.parse(consultaIndice);

        const consultaFiltroJson = LocalStorageService.obterConsulta('_consulta_processo_filtro');
        // const consultaFiltroJson = JSON.parse(consultaFiltro);

        if (consultaIndiceJson != null) {
          this.processoService.consultarProcessoPorIndice(consultaIndiceJson)
            .then(response => {
              this.setState({ processos: response.data });
            }).catch(error => {
              this.setState(error.response.data)
              this.toast.show({ severity: 'error', summary: 'Processos', detail: `${this.state.detail}`, life: 4000 });
            });
        }

        if (consultaFiltroJson != null) {
          this.processoService.listarProcessos(consultaFiltroJson)
            .then(response => {
              this.setState({ processos: response.data });
            }).catch(error => {
              this.setState(error.response.data)
              this.toast.show({ severity: 'error', summary: 'Processos', detail: `${this.state.detail}`, life: 4000 });
            })
        }
      })
    this.setState({openSpinner: false})

  }

  arquivado(rowData) {
    if (rowData.statusProcessual.descricao === 'Arquivado') {
      return (
        <React.Fragment>
          <Tag className="p-mr-2" severity="info" value="Arquivado"></Tag>
        </React.Fragment>
      )
    }
  }

  importante(rowData) {
    return (
      <React.Fragment>
        <div className="flex-1 gap-1">
          <Button icon="pi pi-star" className={rowData.importanteParaMim}
            style={{ width: '29px', height: '26px' }} disabled="true" />
          <Button icon="pi pi-heart" className={rowData.importanteParaEmpresa}
            style={{ width: '29px', height: '26px' }} disabled="false" />
        </div>
      </React.Fragment>
    )
  }

  editarProcesso(processo) {
    this.props.history.push(`/fichaProcesso/${processo.codigo}`);
  }

  async componentDidMount() {
    this.setState({ openSpinner: true });
    const consultaIndiceJson = LocalStorageService.obterConsulta('_consulta_processo_indice');
    // const consultaIndiceJson = JSON.parse(consultaIndice);

    const consultaFiltroJson = LocalStorageService.obterConsulta('_consulta_processo_filtro');
    // const consultaFiltroJson = JSON.parse(consultaFiltro);

    if (consultaIndiceJson != null) {
      await this.processoService.consultarProcessoPorIndice(consultaIndiceJson)
        .then(response => {
          this.setState({ processos: response.data });
        }).catch(error => {
          this.setState(error.response.data)
          this.toast.show({ severity: 'error', summary: 'Processos', detail: `${this.state.detail}`, life: 4000 });
        });
    }

    if (consultaFiltroJson != null) {
      await this.processoService.listarProcessos(consultaFiltroJson)
        .then(response => {
          this.setState({ processos: response.data });
        }).catch(error => {
          this.setState(error.response.data)
          this.toast.show({ severity: 'error', summary: 'Processos', detail: `${this.state.detail}`, life: 4000 });
        })
    }

    this.setState({ openSpinner: false });
  }

  cadastrarProcesso = () => {
    this.props.history.push('/cadastrarProcesso')
  }

  render() {
    return (
      <>
        <NavBar />
        <Toast ref={(el) => this.toast = el} />
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-primary card-outline">
                    <div className="card-header">
                      <h3 className="card-title">
                        <i className="fas fa-balance-scale"></i>
                        <span> Processos e casos</span>
                      </h3>
                    </div>

                    <div className="card-body">
                      <div className="row">
                        <span className="input-group gap-1">
                          {/*<Button type="button" className={strings.buttonPrimarySm}*/}
                          {/*  onClick={this.cadastrarProcesso}*/}
                          {/*  aria-haspopup aria-controls="overlay_panel"*/}
                          {/*  style={{height:"37px"}}>*/}
                          {/*  <div className="row gap-3">*/}
                          {/*    <i className="fas fa-plus"></i>*/}
                          {/*    Cadastrar*/}
                          {/*  </div>*/}
                          {/*</Button>*/}
                          <ButtonSm className={strings.buttonPrimarySm}
                                    onClick={this.cadastrarProcesso}>
                            {/*<div className="row gap-3 center-items">*/}
                              <i className="fas fa-plus"></i>
                              Cadastrar
                            {/*</div>*/}
                          </ButtonSm>
                          {/*&nbsp;*/}
                          <ButtonSm id='FiltroAvancado' type="button" className={strings.buttonSecondarySm}
                            data-toggle="modal"
                            title="Filtro avançado"
                            onClick={(e) => this.op.toggle(e)}
                            aria-haspopup aria-controls="overlay_panel">
                            <i className="fas fa-filter"></i></ButtonSm>

                          <OverlayPanel ref={(el) => this.op = el} showCloseIcon id="overlay_panel"
                            style={{ width: '750px' }}
                            className="overlaypanel-demo"
                            onShow={this.carregaCombosConsulta}>

                            <div className="row">
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label>Pessoa</label>
                                  <InputText id="consultaPessoa"
                                    type="text"
                                    style={{ width: '100%' }}
                                    value={this.state.pessoa}
                                    onChange={e => this.setState({ pessoa: e.target.value })}
                                    className="p-inputtext-sm p-d-block p-mb-1" />
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label>Pasta</label>
                                  <InputText id="pastaConsultas"
                                    type="text"
                                    style={{ width: '100%' }}
                                    value={this.state.pasta}
                                    onChange={e => this.setState({ pasta: e.target.value })}
                                    className="p-inputtext-sm p-d-block p-mb-1" />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label>Número CNJ</label>
                                  <InputText id="cnjConsulta"
                                    type="text"
                                    style={{ width: '100%' }}
                                    value={this.state.numeroCnj}
                                    onChange={e => this.setState({ numeroCnj: e.target.value })}
                                    className="p-inputtext-sm p-d-block p-mb-1" />
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label>Número processo</label>
                                  <InputText id="numeroProcessoConsulta"
                                    type="text"
                                    style={{ width: '100%' }}
                                    value={this.state.numeroProcesso}
                                    onChange={e => this.setState({ numeroProcesso: e.target.value })}
                                    className="p-inputtext-sm p-d-block p-mb-1" />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label>Grupo de trabalho</label>
                                  <Dropdown value={this.state.grupoTrabalho}
                                    options={this.state.gruposTrabalho} style={{ width: '100%' }}
                                    optionLabel="nome"
                                    filter showClear filterBy="nome"
                                    onChange={this.onGrupoChange}
                                    id="grupoConsulta"
                                    className="p-inputtext-sm p-d-block p-mb-1" />
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label>Tipos de ação</label>
                                  <Dropdown value={this.state.tipoAcao}
                                    options={this.state.tiposAcao} style={{ width: '100%' }}
                                    optionLabel="nome"
                                    filter showClear filterBy="nome"
                                    onChange={this.onTiposAcaoChange}
                                    id="tipoAcaoConsulta"
                                    className="p-inputtext-sm p-d-block p-mb-1" />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label>Status processual</label>
                                  <Dropdown value={this.state.statusProcessual}
                                    options={this.state.statusProcessuais} style={{ width: '100%' }}
                                    optionLabel="descricao"
                                    filter showClear filterBy="descricao"
                                    onChange={this.onStatusProcessualChange}
                                    id="statusProcessualConsulta"
                                    className="p-inputtext-sm p-d-block p-mb-1" />
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label>Tipos de decisão</label>
                                  <Dropdown value={this.state.tipoDecisao}
                                    options={this.state.tiposDecisao} style={{ width: '100%' }}
                                    optionLabel="nome"
                                    filter showClear filterBy="nome"
                                    onChange={this.onTiposDecisaoChange}
                                    id="tiposDecisapConsulta"
                                    className="p-inputtext-sm p-d-block p-mb-1" />
                                </div>
                              </div>
                            </div>
                            <div className="row-etrium gap-1">
                              <Checkbox inputId="importanteParaMim"
                                checked={this.state.importanteParaMim}
                                onChange={e => this.setState({ importanteParaMim: e.checked })} />
                              <label>Importante para mim</label>
                            </div>
                            <div className="direita">
                              <ButtonSm type="submit" className={strings.buttonSuccessSm} onClick={this.listarProcessos}>
                                <i className="fas fa-check"></i>
                                 Aplicar
                              </ButtonSm>
                            </div>

                          </OverlayPanel>
                          {/*&nbsp;*/}
                          <form onSubmit={()=>this.consultarProcessoPorIndice()} className="flex-row">
                            <input type="text" className="form-control"
                                placeholder="Consulte um processo"
                                value={this.state.indice}
                                onChange={e => this.setState({ indice: e.target.value })}
                                // onKeyPress={event => event.key === 'Enter' && this.consultarProcessoPorIndice()}
                                />
                            <span className="input-group-prepend">
                              <button type={'submit'} className="input-group-text">
                                <i className="fas fa-search"></i>
                              </button>
                            </span>
                          </form>
                        </span>
                      </div>
            <Loading  open={this.state.openSpinner}/>


                    </div>
                    <DataTable id='InfosTable' value={this.state.processos} paginator responsiveLayout="scroll"
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}" rows={10} emptyMessage=" "
                      paginatorRight selectionMode="multiple" onRowClick={(e) => this.editarProcesso(e.data)}>
                      <Column field="pasta" header="Pasta" style={{ width: '200px' }}></Column>
                      <Column field="nrProcesso" header="Processo original" style={{ width: '180px' }}></Column>
                      <Column field="nrCnj" header="Numeração única" style={{ width: '180px' }}></Column>
                      <Column field="autor" header="Autor x Réu" style={{ width: '300px' }}></Column>
                      <Column field="grupoTrabalho.nome" header="Grupo trab." style={{ width: '180px' }}></Column>

                      <Column body={this.acoes} exportable={false} style={{ width: '150px' }} header="Ações"></Column>
                    </DataTable>

                    <ConfirmDialog visible={this.state.visible}
                      onHide={() => this.setState({ visible: false })}
                      message="Are you sure you want to proceed?"
                      header="Confirmation"
                      icon="pi pi-exclamation-triangle"
                      accept={() => this.enviarProcessoLixeira}
                      reject={() => this.setState({ visible: false })} />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    )
  }
}

Processo.contextType = AuthContext;
export default withRouter(Processo)
