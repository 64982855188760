import ApiService from '../apiservice'

export default class TipoParecerService extends ApiService {
  constructor() {
    super('/tipoParecer')
  }
  buscarTipoParecerPorEmpresa (codigoEmpresa) {
    return this.get("/all?empresa="+codigoEmpresa);
  }
  cadastrarTipoParecer(tipoParecer){
    return this.post("", tipoParecer)
  }
  buscarPorCodigo(codigoTipo){
    return this.get(`/${codigoTipo}`)
  }

  alterarTipoParecer(tipoParecer){
    return this.put("/alterar", tipoParecer)
  }
  deltearTipo(codigoTipo){
    return this.delete(`/${codigoTipo}`)
  }
}
