import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import React, {useContext, useState} from "react";
import {Button} from "primereact/button";
import GrupoTrabalhoService from "../app/service/GrupoTrabalhoService";
import {AuthContext} from "../main/ProvedorAutenticacao";
import {strings} from "../utils/strings";



export const DialogGrupoTrabalho = ({open, toast, close, refrashList}) => {

    const [state, setState] = useState(
        {
            grupoService: new GrupoTrabalhoService(),
            //Cadastro de Grupos
            codigo: '',
            nome: '',
            descricao: '',
            empresa: '',
            detail: '',
            //Combo de Usuários
            openSpinner: false,
            classeDiv: 'hide',
        }
    )

    const {usuarioAutenticado} = useContext(AuthContext);
    const limparCampos = () => {
        setState({nome: '', descricao: ''});
    }

    const cadastrarGrupo = async () => {

        setState({openSpinner: true});

        if (!state.nome) {
            toast.show({
                severity: 'error',
                summary: 'Grupo de trabalho',
                detail: 'Informe o nome do grupo de trabalho',
                life: 90000
            });
            stateChange("classeDiv", 'hide')
            return false;
        }
        const empresa = usuarioAutenticado.empresa;

        await state.grupoService.cadastrarGrupo({
            nome: state.nome,
            descricao: state.descricao,
            empresa: empresa
        }).then(response => {
            toast.show({
                severity: 'success',
                summary: 'Grupo de trabalho',
                detail: 'Grupo de trabalho cadastrado com sucesso',
                life: 4000
            })
            refrashList()
            limparCampos()
            close()
        }).catch(error => {
            stateChange("detail", error.response.data.detail)
            toast.show({
                severity: 'error',
                summary: 'Grupo de trabalho',
                detail: `${state.detail}`,
                life: 4000
            });
        })

        stateChange("openSpinner", false);
    }

    function stateChange(name, value) {
        setState({
            ...state,
            [`${name}`]: value
        });
    }

    function renderFooterCadastroGrupos() {
        return (
            <div className="row-etrium gap-1" >
                <Button label="Cadastrar"
                        className={strings.buttonPrimaryStyle}
                        onClick={cadastrarGrupo}/>
            </div>
        );
    }

    return (

        <Dialog header="Cadastrar grupo de trabalho"
                visible={open}
                style={{width: '40vw'}}
                footer={() => renderFooterCadastroGrupos()}
                onHide={close}>

            <div className="p-fluid">
                <div className="p-field mb-3">
                    <label htmlFor="nomeGrupo">Nome<span className="obrigatorioAsterisco"> *</span></label>
                    <InputText id="nomeGrupo"
                               type="text"
                               maxLength={50}
                               value={state.nome}
                               onChange={e => stateChange("nome", e.target.value)}/>
                </div>
                <div className="p-field">
                    <label htmlFor="descricao">Descrição</label>
                    <InputText id="descricao"
                               type="text"
                               maxLength={100}
                               value={state.descricao}
                               onChange={e => stateChange("descricao", e.target.value)}/>
                </div>
            </div>
        </Dialog>
    );


}

