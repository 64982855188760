export const strings = {
    buttonPrimaryStyle: "p-button-primary p-button-outlined",
    buttonSecondaryStyle: "p-button-secondary p-button-outlined",
    buttonWarningStyle: "p-button-warning p-button-outlined",
    buttonHelp: "p-button-help p-button-outlined",
    buttonPlain: "p-button-plain p-button-outlined",
    buttonDanger: "p-button-danger p-button-outlined",
    buttonInfo: "p-button-info p-button-outlined",
    buttonSuccess: "p-button-success p-button-outlined ",


    //sm
    buttonSecondarySm : "p-button-secondary p-button-outlined p-button-sm",
    buttonSuccessSm : "p-button-success p-button-outlined p-button-sm",
    buttonInfoSm : "p-button-info p-button-outlined p-button-sm",
    buttonPrimarySm : "p-button-primary p-button-outlined p-button-sm",
    buttonDangerSm: "p-button-danger p-button-outlined p-button-sm",

    //buttons pencil
    buttonIconSecundary: "p-button-rounded p-button-text p-button-secondary",

    //buttons trash
    buttonIconDanger: "p-button-rounded p-button-text p-button-danger",
}

export const STRING = {
    PH_PesquisaProcesso: "Pesquise um processo",
    title_remover_usuario_cadastro_auto_processo: "Remover cadastro de processo automático",
    ALERT_MSG_NO_CREDENTIAL_PROCESSO: "Identificamos que a empresa não possui usuário habilitado para busca automática de processos. Acesse o link abaixo para habilita-lo:"
}
// export const ALERT_MSG_PROCESSO_AUTO = "Ao incluir um usuário no processo automático, você deve obrigatoriamente informar o CPF e a senha do usuário deve ser a mesma dos respectivos tribunais."
export const ALERT_MSG_PROCESSO_AUTO_ALTER = "Ao incluir um usuário no processo automático, certifique-se de que ele tenha o campo CPF devidamente preenchido e que a senha cadastrada no sistema Etrium seja a mesma dos respectivos tribunais."
export const CERTIFICADO_PROCESSO_AUTO = {
    PROCESSO : {
        ALERT_SUMARY_CADASTRO: 'Adicionar certificado ao cadastro automático',
        ALERT_SUMARY_REMOVER: 'Retirar certificado do cadastro automático',
    }
}
export const graficosLegendas = {
    atividadeSituacaoGeral:  "Atividades por Situação: atrasadas, aoncluídas com atraso ou no prazo",
    atividadeSituacaoIndividual:  "Atividades do usuário por situação: atrasadas, concluídas com atraso ou no prazo",
    atividadeStatusGeral:  "Atividades por status",
    atividadeStatusIndividual:  "Atividades do usuário por status",
    atividadeGrupoTrabalho:  "Atividades por grupo de trabalho",
    atividadesCriadas:  "Atividades criadas por mês no período do ano atual.",

    //procesos
    processoStatusProcessual:  "Processos por status processual.",
    processosGrupoTrabalho:  "Processos por grupo de trabalho.",
    processosAreaAtuacao:  "Processos por area de atuaçao.",
    processosObjetos:  "Processos com objetos de ação por objeto.",
    processosTipoAcao:  "Processos por tipo ação.",
    processosCadastradosArquivados:  "Processos cadastrados e arquivados no sistema por mês.",

    //parecer
    parecerProdutividade: "Pareceres cadastrados no sistema, com ou sem responsável.",
    parecerEficiencia:  "Pareceres sem atraso.",
    parecerEficacia: "Pareceres com status finalizado, sem devolução.",


    //financeiro
    financeiroDespesas: "Despesas por tipo.",
    financeiroReceitas: "Receitas por tipo.",
    financeiroReceitasDespesas: "Receitas e despesas por mês.",

    //empresa
    empresaGrafico: "Processos e atividades cadastrados da empresa.",
    empresaStatus: "Empresas nos respectivos planos.",
}