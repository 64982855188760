import {Dropdown} from "primereact/dropdown";
import React from "react";


export const DropdownComponente = ({...rest})=>{

    return(
        <Dropdown
            {...rest}
            style={{width: '100%', height: '37px'}}
            className="p-inputtext-sm p-d-block p-mb-1"
        />
    )
}
