import ApiService from '../apiservice'

export default class TiposCustasService extends ApiService {

  constructor() {
    super('tiposCusta');
  }

  listarTiposCusta(empresa) {
    const params = `?empresa=${empresa}`;
    return this.get(params);
  }

  cadastrarTipoCusta(tipo) {
    return this.post('',tipo);
  }

  consultarTipoCusta(codigo) {
    return this.get(`/${codigo}`);
  }

  alterarTipoCusta(tipo) {
    return this.put(`/${tipo.codigo}`,tipo);
  }

  excluirTipoCusta(codigo) {
    return this.delete(`/${codigo}`);
  }
}