import React from 'react'

import { withRouter } from 'react-router-dom'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { confirmDialog } from 'primereact/confirmdialog'

import NavBar from '../../componentes/Navbar'
import ProcessoService from '../../app/service/ProcessoService'
import { AuthContext } from '../../main/ProvedorAutenticacao'
import {Loading} from "../../componentes/Loading";

class ProcessosExcluidos extends React.Component {
  constructor(props) {
    super(props);

    this.processoService = new ProcessoService();

    this.aceptAtivarProcesso = this.aceptAtivarProcesso.bind(this);
    this.acoes = this.acoes.bind(this);

    this.state = {
      detail: '',
      processos: [],
      codigo: '',
      openSpinner: false
    }
  }

  consultarProcessosExcluidos = () => {
    const usuarioLogado = this.context.usuarioAutenticado;
    this.setState({openSpinner:true})
    this.processoService.consultarProcessosExcluidos(usuarioLogado.empresa.codigo)
      .then(response => {
        this.setState({processos: response.data});
      }).catch(error => {
        this.setState(error.response.data);
        this.toast.show({severity:'error', summary: 'Processos excluídos', detail:`${this.state.detail}`, life: 4000});
      })
    this.setState({openSpinner:false})
  }

  acoes(rowData) {
    return(
      <React.Fragment>
        <Button icon="pi pi-history" className="p-button-rounded p-button-text"
                onClick={() => this.confirmacaoAtivacaoProcesso(rowData)}
                tooltip="Retornar a ativo"
                tooltipOptions={{position: 'top'}} />
      </React.Fragment>
    );
  }

  confirmacaoAtivacaoProcesso(processo) {
    this.setState({codigo: processo.processo.codigo});
    confirmDialog({
        message: 'Deseja realmente ativar o processo selecionado?',
        header: 'Ativação de processo',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        accept: this.aceptAtivarProcesso,
        reject: this.reject
    });
  }

  async ativarProcesso() {
    this.setState({openSpinner: true});
    const usuarioLogado = this.context.usuarioAutenticado;
    const processo = {
      codigo: this.state.codigo,
      status: 0,
      usuario: usuarioLogado.codigo
    }

    await this.processoService.ativarProcesso(processo)
      .then(() => {
        this.toast.show({severity:'success', summary: 'Processos excluídos', detail:'Processo ativado com sucesso!', life: 4000});
        this.consultarProcessosExcluidos();
      }).catch(error => {
        this.setState(error.response.data);
        this.toast.show({severity:'error', summary: 'Processos excluído', detail:`${this.state.detail}`, life: 4000});
      });

      this.setState({openSpinner: false});
  }

  aceptAtivarProcesso() {
    this.ativarProcesso();
  }

  componentDidMount() {
    this.consultarProcessosExcluidos();
  }

  render() {
    return(
      <>
        <NavBar/>
        <Loading open={this.state.openSpinner}/>
        <Toast ref={(el) => this.toast = el} />
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-primary card-outline">
                    <div className="card-header">
                      <h3 className="card-title">
                        <i className="nav-icon fas fa-balance-scale"></i>
                        <span> Processos excluídos</span>
                      </h3>
                    </div>
                    <Backdrop
                      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                      open={this.state.openSpinner}>
                      <CircularProgress color="primary" style={{width: '130px', height: '130px'}}/>
                    </Backdrop>
                    <div id='ProcessosExcluidosList' className="card-body">
                      <DataTable value={this.state.processos}  paginator responsiveLayout="scroll" emptyMessage="Nenhum processo excluído"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}" rows={10}>
                        <Column field="processo.nrCnj" header="Número CNJ"></Column>
                        <Column field="processo.nrProcesso" header="Número processo"></Column>
                        <Column field="processo.pasta" header="Pasta"></Column>
                        <Column field="processo.grupoTrabalho.nome" header="Grupo de trabalho"></Column>
                        <Column body={this.acoes} exportable={false} style={{ minWidth: '8rem' }} header="Ações"></Column>
                      </DataTable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    )
  }
  }

ProcessosExcluidos.contextType = AuthContext;
export default withRouter(ProcessosExcluidos)
