import ApiService from '../apiservice'

export default class TipoAcaoService extends ApiService {

  constructor() {
    super('/tipoAcao');
  }

  listarTipoAcao(empresa) {
    return this.get(`?empresa=${empresa}`);
  }

  cadastrarTipoAcao(tipoAcao) {
    return this.post("", tipoAcao)
  }
}