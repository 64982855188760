import React, {useEffect, useRef, useState} from "react";
import ConfirmeEmailService from "../../app/service/ConfirmeEmailService";
import {withRouter} from "react-router-dom";
import Logo from "../../img/etrium-logo1.svg";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {strings} from "../../utils/strings";
import {Toast} from "primereact/toast";
import ReCAPTCHA from "react-google-recaptcha";
import {IsNullOrEmpty} from "../../utils/IsNullOrEmpy";
import {AuthContext} from "../../main/ProvedorAutenticacao";


export const ConfirmarEmail = (props) => {
    const [mailCode, setMailCode] = useState("")
    const [email, setEmail] = useState("")
    const [captcha, setCaptcha] = useState("")
    const [token, setToken] = useState("")
    const [timeoutToSendEmailAgain, setTimeoutToSendEmailAgain] = useState(0)
    // const [loading, setLoading] = useState(false)
    const [hasNotToken, setHasNotToken] = useState(false)
    const toasRef = useRef(null)

    const service = new ConfirmeEmailService()

    const {iniciarSessao, setLoading} = React.useContext(AuthContext)

    function getConfirmerEmail() {
        let params = props.location.search
        if (params.split("=")[1] === undefined) {
            // props.history.push("/login")
            setHasNotToken(true)
        }else{
        let _token = params.split("=")[1]
        setToken(_token)
        }
    }

   function handleConfirmarEmail(e) {
        e.preventDefault()
       if(IsNullOrEmpty(token)){
           showAlert('error', 'Token expirou. Por favor, solicite um novo e-mail.')
           return
       }
       setLoading(true)
       service.confirmarEmailByTokenAndCodigoConfirmacao(token, mailCode).then(async res => {
          toasRef.current.show({severity: 'success',
              summary: 'E-mail',
              detail: 'E-mail confirmado com sucesso.',
              life: 3000});
          let data = res.data
           await iniciarSessao(data);
           props.history.push('/home');
           window.location.reload();
           setTimeout(() => {
               props.history.push("/home")
           }, 2000)
       }).catch((error) => {
               let detail = error.response.data.detail || 'Erro no sistema. Tente novamente mais tarde.'
                if(detail){
                    if(detail.toString().includes("Email já foi confirmado.")){
                        showAlert('success', detail)
                        setTimeout(() => {
                            props.history.push("/login")
                        }, 2000)
                        return

                    }else if(detail.toString().includes("O código expirou")){
                        setToken("")
                        setHasNotToken(true)
                    }
                        showAlert('error', detail)

                }
       }
       ).finally(()=>
           setLoading(false))
   }

   function handleRequestMailAgain(){
       if(IsNullOrEmpty(token)){
           showAlert('error', 'Por favor, confirme o captcha.')
           return
       }
        setLoading(true)
       service.solicitarEmailAgainByToken(token)
           .then(res=>{
               showAlert('success', 'E-mail solicitado.')
               let data = res.data
               if(data){
                   setTimeout(() => {
                       setToken(data)
                       setHasNotToken(false)
                       props.history.push(`/confirmarEmail?token=${data}`)
                   }, 2000)
               }
           }).catch(error => {
               let detail = error.response.data.detail || 'Erro no serviço, tente novamente em alguns instantes.'
               if(detail){
                   if(detail.toString().includes("Token inválido ou expirado.")){
                       props.history.push("/confirmarEmail")
                       window.location.reload()
                   }
                   if(detail.toString().includes("Aguarde um minuto para enviar outro email")){
                       setTimeoutToSendEmailAgain(60)
                   }
               }
               showAlert('error', detail)
           })
           .finally(()=>setLoading(false))


   }

   function handleRequestMail(e){
        e.preventDefault()
       if(IsNullOrEmpty(email)){
           showAlert('error', 'Por favor, informe o e-mail.')
           return
       }
        if(IsNullOrEmpty(captcha)){
            showAlert('error', 'Por favor, confirme o captcha.')
            return
        }
        setLoading(true)
        service.solicitarEmail(email, captcha).then(res => {
            showAlert('success', 'E-mail solicitado.')
            let data = res.data
            if(data){
                setTimeout(() => {
                    setToken(data)
                    setHasNotToken(false)
                    props.history.push(`/confirmarEmail?token=${data}`)
                }, 2000)
            }

        })
            .catch(error=>{
                let detail = error.response.data.detail
                if(detail){
                    if(detail.toString().includes("Email já foi verificado.")){
                        setTimeout(() => {
                            props.history.push("/login")
                        }, 2000)
                    }
                showAlert('error', detail)
                }
            })
            .finally(()=>{
                setLoading(false)
            })


   }

    const  formStepEmail = () => {
        return (
            <form onSubmit={handleConfirmarEmail}>
                <div className="form-group">
                    <label>Codigo <span
                        className="obrigatorioAsterisco"> *</span></label>
                    <InputText id="nome"
                               required
                               autoFocus={true}
                               type="text"
                               style={{width: '100%'}}
                               value={mailCode}
                               onChange={onChangeMailCode}
                               className="p-inputtext-sm p-d-block p-mb-1"/>
                </div>
                <div className='flex-column gap-1'>
                    <Button
                        type={'submit'}
                        className={strings.buttonPrimaryStyle + " btn-login100"}
                        onClick={handleConfirmarEmail}
                    >
                        Confirmar
                    </Button>
                    <Button
                        type={'button'}
                        disabled={timeoutToSendEmailAgain > 0}
                        className={strings.buttonSecondaryStyle + " btn-login100"}
                        onClick={handleRequestMailAgain}
                    >
                        {timeoutToSendEmailAgain > 0 ? `${timeoutToSendEmailAgain}s` : "Solicitar e-mail novamente"}
                    </Button>
                </div>
            </form>
        )
    }

    const onChangeMailCode = (e) => {
        const value = e.target.value
        if(value){
            setMailCode(value.toString().toUpperCase())
        }else {
        setMailCode("")
        }
    }

    const  formEmail = () => {
        return (
            <form onSubmit={handleRequestMail}>
                <div className="form-group">
                    <label>E-mail</label>
                    <InputText id="email"
                               type="text"
                               required={true}
                               style={{width: '100%'}}
                               autoComplete="off"
                               value={email}
                               onChange={event => setEmail(event.target.value)}
                               className="p-inputtext-sm p-d-block p-mb-1"/>

                </div>

                <div className='form-group'>
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_SITE_KEY}
                        name='captcha'
                        onChange={(value) => setCaptcha(value)}/>
                </div>

                <div className='row-etrium gap-1'>
                    <Button
                        type={'submit'}
                        className={strings.buttonPrimaryStyle + " btn-login100"}
                        onClick={handleRequestMail}
                    >
                        Solicitar
                    </Button>
                </div>


            </form>
        )
    }

    const showAlert = (type, message) => {
        toasRef.current.show({severity: type,
            summary: 'E-mail',
            detail: message,
            life: 3000});
    }

    useEffect(getConfirmerEmail, [])

    useEffect(() => {
        if(IsNullOrEmpty(token)){
            setHasNotToken(true)
        }else{
            setHasNotToken(false)
        }
    }, [token]);

    useEffect(() => {
        if(timeoutToSendEmailAgain === 0 ) return;
        const timeId = setInterval(() => {
            setTimeoutToSendEmailAgain(prevState => prevState - 1);
        }, 1000);
        return () => {
            clearInterval(timeId);
        };

    }, [timeoutToSendEmailAgain]);

    const messageText = hasNotToken ? "Por favor, informe o e-mail para solicitar o codigo de verificação." : "Você recebeu um e-mail para ativação da conta. Verifique sua caixa de entrada ou spam e informe abaixo o código recebido para ativar a conta."

    return <>
        <Toast ref={toasRef}/>
        {/*<Loading open={loading}/>*/}
        <div className="limiter">
            <div className="container-login100">
                <div className={"flex flex-row w-full"}>
                    <div className={"full-screen-container"}>
                    </div>
                    <div className="container-logo-form">
                        <div className="logo-container">
                            <img className="logo" src={Logo} alt='Logo Etrium'></img>
                        </div>
                        <div className={"form-s"}>
                            <div>
                                <div>
                                    <h1 className="text-login-h1">Confirmar e-mail</h1>
                                    <p className="txt2">{messageText}</p>
                                </div>
                                {hasNotToken ? formEmail() : formStepEmail()}
                                {/*fim inputs*/}

                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>

    </>

}
export default withRouter(ConfirmarEmail)
