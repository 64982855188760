import ApiService from '../apiservice'

export default class HistoricoParecerService extends ApiService {
  constructor() {
    super('/parecer/historico')
  }
  consultarTodosHistorico(codigo){
    return this.get(`/${codigo}`);
  }
}
