import React from 'react'
import {withRouter} from 'react-router-dom'

import {Toast} from 'primereact/toast'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Dialog} from 'primereact/dialog'
import {ConfirmDialog, confirmDialog} from 'primereact/confirmdialog'

import TipoGarantiaService from '../../app/service/TipoGarantiaService'
import {AuthContext} from '../../main/ProvedorAutenticacao'

import NavBar from '../../componentes/Navbar'
import {strings} from "../../utils/strings";
import {IsNullOrEmpty} from "../../utils/IsNullOrEmpy";
import {ButtonSm} from "../../componentes/ButtonSm";
import {Loading} from "../../componentes/Loading";

class TipoGarantia extends React.Component {

  constructor(props) {
    super(props);

    this.tipoGarantiaService = new TipoGarantiaService();
    this.accept = this.accept.bind(this);
    this.renderFooterCadastroGarantia = this.renderFooterCadastroGarantia.bind(this);
    this.renderFooterAlterarGarantia = this.renderFooterAlterarGarantia.bind(this);
    this.acoes = this.acoes.bind(this);

    this.state = {
      //Cadastro
      codigo: '',
      nome: '',
      empresa: null,
      garantias: [],
      modalCadastro: false,
      modalAlterar: false,
      visivble: false,
      //Progresso
      classeDiv: 'hide',
        loading: true,
    };
  }

  fecharModalCadastro(name) {
    this.setState({
        [`${name}`]: false
    });
  }

  abrirModalCadastro(name, position) {
    let state = {
        [`${name}`]: true
    };

    if (position) {
        state = {
            ...state,
            position
        }
    }
    this.limparCampos();
    this.setState(state);
  }

  abrirModaAlterar(name, position) {
    let state = {
        [`${name}`]: true
    };

    if (position) {
        state = {
            ...state,
            position
        }
    }

    this.setState(state);
  }

  limparCampos = () => {
    this.setState({nome: ''});
  }

  accept() {
    this.excluirGarantia();
  }

  listarGarantias = async() => {
    this.setState({classeDiv: 'show'});
    const usuarioLogado = this.context.usuarioAutenticado
    await this.tipoGarantiaService.listarGarantias(usuarioLogado.empresa.codigo)
      .then(response => {
        this.setState({garantias: response.data});
      }).catch(error => {
        this.setState(error.response.data);
        this.toast.show({severity:'error', summary: 'Garantias de processo', detail:`${this.state.detail}`, life: 4000});
      }).finally(()=>{
            this.setState({loading:false});
        });

      this.setState({classeDiv: 'hide'});
  }

  cadastrarGarantias = async() => {

    if(IsNullOrEmpty(this.state.nome)) {
      this.toast.show({severity:'error', summary: 'Garantias de processo', detail:'Informe o nome da garantia de processo', life: 4000});
      this.setState({classeDiv: 'hide'});
      return false;
    }
    this.setState({classeDiv: 'show'});

    const usuarioLogado = this.context.usuarioAutenticado

    await this.tipoGarantiaService.cadastrarGarantia({
      nome: this.state.nome,
      empresa: usuarioLogado.empresa
    }).then(response => {
      this.toast.show({severity:'success', summary: 'Garantias de processo', detail:'Garantia de processo cadastrada com sucesso', life: 4000});
      this.listarGarantias();
    }).catch(error => {
      this.setState(error.response.data);
      this.toast.show({severity:'error', summary: 'Garantias de processo', detail:`${this.state.detail}`, life: 4000});
    });

    this.fecharModalCadastro('modalCadastro');
    this.setState({classeDiv: 'hide'});
  }

  async editar(garantia) {
    this.setState({classeDiv: 'show'});
    if(garantia != null) {
      await this.tipoGarantiaService.consultarGarantia(garantia.codigo)
        .then(response => {
          this.setState({codigo: response.data.codigo,
                         nome: response.data.nome});
          this.abrirModaAlterar('modalAlterar');
        }).catch(error => {
          this.setState(error.response.data);
          this.toast.show({severity:'error', summary: 'Garantias de processo', detail:`${this.state.detail}`, life: 4000});
        });

        this.setState({classeDiv: 'hide'});
    }

    this.setState({classeDiv: 'hide'});
  }

  alterarGarantia = async() => {
    this.setState({classeDiv: 'show'});

    if(this.state.nome === '') {
      this.toast.show({severity:'error', summary: 'Garantias de processo', detail:'Informe o nome da garantia de processo', life: 4000});
      this.setState({classeDiv: 'hide'});
      return false;
    }

    const usuarioLogado = this.context.usuarioAutenticado

    await this.tipoGarantiaService.alterarGarantia({
      codigo: this.state.codigo,
      nome: this.state.nome,
      empresa: usuarioLogado.empresa
    }).then(response => {
      this.toast.show({severity:'success', summary: 'Garantias de processo', detail:'Garantia de processo alterada com sucesso', life: 4000});
      this.listarGarantias();
    }).catch(error => {
      this.setState(error.response.data);
      this.toast.show({severity:'error', summary: 'Garantias de processo', detail:`${this.state.detail}`, life: 4000});
    });

    this.fecharModalCadastro('modalAlterar');
    this.setState({classeDiv: 'hide'});
  }

  confirmaExclusao(garantia) {
    this.setState({codigo: garantia.codigo});
    confirmDialog({
        message: 'Deseja realmente excluir a garantia de processo?',
        header: 'Exclusão de garantia de processo',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        accept: this.accept,
        reject: this.reject
    });
  }

  excluirGarantia = async() => {
    this.setState({classeDiv: 'show'});
    await this.tipoGarantiaService.excluirGarantia(this.state.codigo)
      .then(response => {
        this.toast.show({severity:'success', summary: 'Garantias de processo', detail:'Garantia de processo excluído com sucesso', life: 4000});
        this.listarGarantias();
      }).catch(error => {
        this.setState(error.response.data);
        this.toast.show({severity:'error', summary: 'Garantias de processo', detail:`${this.state.detail}`, life: 4000});
      });

      this.setState({classeDiv: 'hide'});
  }

  renderFooterCadastroGarantia(name) {
    return (
        <div className="row-etrium gap-1">
            <Button label="Cadastrar"
                    className={strings.buttonPrimaryStyle}
                    onClick={this.cadastrarGarantias}/>
        </div>
    );
  }

  renderFooterAlterarGarantia(name) {
    return (
        <div className="row-etrium gap-1">
            <Button label="Alterar"
                    className={strings.buttonPrimaryStyle}
                    onClick={this.alterarGarantia}/>
        </div>
    );
  }

  acoes(rowData) {
    return(
      <div className="row gap-1">
        <Button icon="pi pi-pencil" className={strings.buttonIconSecundary}
                onClick={() => this.editar(rowData)}
                tooltip="Editar Garantia de processo"
                tooltipOptions={{position: 'top'}} />
        <Button icon="pi pi-trash" className={strings.buttonIconDanger}
                onClick={() => this.confirmaExclusao(rowData)}
                tooltip="Excluir Garantia de processo"
                tooltipOptions={{position: 'top'}}/>
      </div>
    );
  }

  componentDidMount() {
    this.listarGarantias();
  }

  render() {
    return(
      <>
          <Loading open={this.state.loading} />
          <NavBar/>
        <Toast ref={(el) => this.toast = el} />
        <div className="content-wrapper">
          {/**Cadastro de Garantias de Processo */}
          <Dialog header="Cadastro de garantias de processo"
                    visible={this.state.modalCadastro}
                    style={{ width: '40vw' }}
                    footer={this.renderFooterCadastroGarantia('modalCadastro')}
                    onHide={() => this.fecharModalCadastro('modalCadastro')}>

                <div className="p-fluid">
                    <div className="p-field">
                        <label htmlFor="nomeGarantia">Nome<span className="obrigatorioAsterisco"> *</span></label>
                        <InputText id="nomeGarantia"
                                   type="text"
                                   maxLength={50}
                                   value={this.state.nome}
                                   onChange={e => this.setState({nome: e.target.value})}
                                   className="p-inputtext-sm p-d-block p-mb-1"/>
                    </div>
                </div>
          </Dialog>
          {/**Alterar Garantias de Processo */}
          <Dialog header="Alterar garantias de processo"
                    visible={this.state.modalAlterar}
                    style={{ width: '40vw' }}
                    footer={this.renderFooterAlterarGarantia('modalAlterar')}
                    onHide={() => this.fecharModalCadastro('modalAlterar')}>

                <div className="p-fluid">
                    <div className="p-field">
                        <label htmlFor="nomeGarantiaAlterar">Nome<span className="obrigatorioAsterisco"> *</span></label>
                        <InputText id="nomeGarantiaAlterar"
                                   type="text"
                                   maxLength={50}
                                   value={this.state.nome}
                                   onChange={e => this.setState({nome: e.target.value})}
                                   className="p-inputtext-sm p-d-block p-mb-1"/>
                    </div>
                </div>
          </Dialog>
          <ConfirmDialog visible={this.state.visible}
                         onHide={() => this.setState({ visible: false })}
                         message="Are you sure you want to proceed?"
                         header="Confirmation"
                         icon="pi pi-exclamation-triangle"
                         accept={() => this.excluirGarantia}
                         reject={() => this.setState({visible: false})}/>
          <section className="content-header">
            <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                  <div className="card card-primary card-outline">
                    <div className="card-header">
                      <h3 className="card-title">
                        <i className="fas fa-university"></i>
                        <span> Garantias de processo</span>
                      </h3>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-5">
                          <span className="input-group">
                            <ButtonSm type="button" className={strings.buttonPrimarySm}
                                    onClick={() => this.abrirModalCadastro('modalCadastro')}
                                    data-toggle="modal"
                                    data-target="#modal-default">
                            <i className="fas fa-plus"></i> Cadastrar</ButtonSm>
                          </span>
                        </div>
                      </div>
                    </div>
                    <DataTable value={this.state.garantias}  paginator responsiveLayout="scroll"
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}" rows={10}>
                      <Column field="nome" header="Nome"></Column>
                      <Column key="codigo"></Column>
                      <Column body={this.acoes} exportable={false} style={{ minWidth: '8rem' }}header="Ações"></Column>
                    </DataTable>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

TipoGarantia.contextType = AuthContext;
export default withRouter (TipoGarantia)
