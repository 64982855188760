import {AuthContext} from "../../main/ProvedorAutenticacao";
import AtividadeService from "../../app/service/AtividadeService";
import {useContext, useEffect, useState} from "react";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {HistoricoAtividadeDialog} from "../../componentes/Atividade/HistoricoAtividadeDialog";
import {IncluirHistoricoDialog} from "../../componentes/Atividade/IncluirHistoricoDialog";


export const MeusSuportes = ({toast, setLoading, filtro, codigoAtividade}) => {

    const [atividades, setAtividades] = useState([])
    const [historico, setHistorico] = useState(null)
    const [atividadeSelected, setAtividadeSelected] = useState({})
    //modal
    const [alterarSuporte, setAlterarSuporte] = useState(false)
    const [modalIncuirHistorico, setModalIncuirHistorico] = useState(false)


    const {usuarioAutenticado} = useContext(AuthContext)

    //service
    const atividadeService = new AtividadeService()

    const vizualizarAtendimentos = async () => {
        setLoading(true)
        await atividadeService.consultarUsuarioAtendimentos(usuarioAutenticado.codigo).then((r) => {
            setAtividades(r.data)
        }).catch((error) => {
            const detail = error.response.data.detail
            toast.show({severity: 'error', summary: 'Suporte', detail: `${detail}`, life: 4000});
        }).finally(() => {
            setLoading(false)
        })
    }

    async function consultarAtividade(codigo){
        setLoading(true)

        await atividadeService.consultarAtividade(codigo)
                .then((response)=>{
                    openAtendimento(response.data)
                })
                .catch((error) => {
                    const detail = error.response.data.detail
                    toast.show({severity: 'error', summary: 'Suporte', detail: `${detail}`, life: 4000});
                }).finally(() => {
                    setLoading(false)
                })
    }

    // function acoes(data) {
    //     return (
    //             <ButtonEtrium
    //                     onClick={() => {
    //                         openAtendimento(data)
    //                     }}
    //                     type="info">Ver</ButtonEtrium>
    //     )
    // }

    const openAtendimento = (atividade) => {
        setAtividadeSelected(atividade)
        listarHistorico(atividade.codigo)
        setAlterarSuporte(true)
    }
    const listarHistorico = async (codigo) => {
        let atividade = codigo ? codigo : atividadeSelected.codigo
        setLoading(true)
        await atividadeService.consultarHistorico(atividade)
                .then(response => {
                    setHistorico(response.data)
                }).catch(error => {
                    toast.show({
                        severity: 'error',
                        summary: 'Suporte',
                        detail: `${error.response.data.detail}`,
                        life: 4000
                    });
                })
        setLoading(false)
    }

    //filtrando atividade
    const atividadesFiltradas = atividades.filter(atvd => atvd.titulo.toUpperCase().includes(filtro.toUpperCase()))

    useEffect(() => {
        vizualizarAtendimentos()
        if(codigoAtividade){
            consultarAtividade(codigoAtividade)
        }
        // eslint-disable-next-line
    }, [])

    return (
            // <div className="card-max-width">
            //     {atividades.map(atvd => (
            //             <div className="card ">
            <>
                <DataTable value={atividadesFiltradas} paginator responsiveLayout="scroll"
                           paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                           currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}"
                           rows={10}
                           emptyMessage="Nenhum atendimento de suporte encontrado"
                           selectionMode="multiple"
                           onRowClick={(e) => openAtendimento(e.data)}
                >
                    <Column field="titulo" header="Título"></Column>
                    {/*<Column field="grupo.nome" header="Grupo de Assuntos"></Column>*/}
                    <Column field="dtRegistro" header="Data"></Column>
                    <Column field="status.status" header="Status"></Column>
                    {/*<Column body={acoes} exportable={false} style={{minWidth: '8rem'}} header="Ações"></Column>*/}
                </DataTable>
                {
                        alterarSuporte && <HistoricoAtividadeDialog
                                historico={historico}
                                listarHistorico={listarHistorico}
                                novoHistorico={() => setModalIncuirHistorico(true)}
                                toast={toast}
                                open={alterarSuporte}
                                onClose={() => setAlterarSuporte(false)}

                        />
                }
                {modalIncuirHistorico && <IncluirHistoricoDialog
                        toast={toast}
                        atividade={atividadeSelected}
                        open={modalIncuirHistorico}
                        onClose={() => setModalIncuirHistorico(false)}
                        listarHistorico={listarHistorico}
                />}
            </>
            //                 <div className="flex-column gap-1">
            //                         <text className="card-title text-bold">{atvd.titulo}</text>
            //                         {/*<text className="flex-column">*/}
            //                         {/*    <span className="text-bold">{"Descrição"}</span>*/}
            //                         {/*    {atvd.descricao}*/}
            //                         {/*</text>*/}
            //                         <ButtonEtrium  type="info">Detalhe</ButtonEtrium>
            //                         <text><span className="text-bold">{"Status: "}</span>{atvd.status.status}</text>
            //
            //                 </div>
            //             </div>
            //     ))}
            //
            // </div>
    );
}
