import ApiService from '../apiservice'

export default class ArquivoParecerService extends ApiService {

  constructor() {
    super('/parecer/arquivo')
  }

  incluirArquivoTemp(formData) {
    return this.post('/temp', formData);
  }

  listarArquivosTemp(usuario) {
    return this.get(`/listarArquivosTemp/${usuario}`);
  }

  excluirArquivosTemp(usuario) {
    return this.delete(`/excluirArquivosTemp/${usuario}`);
  }

  excluirArquivoTemp(codigo) {
    return this.delete(`/excluirArquivoTemp/${codigo}`);
  }

  consultarArquivoTemp(codigo) {
    return this.get(`/consultarArquivoTemp/${codigo}`);
  }

  adicionarDescricaoTemp(arquivo) {
    return this.put(`/adicionarDescricaoTemp/${arquivo.codigo}`, arquivo);
  }

  incluirArquivo(formData) {
    return this.post('', formData);
  }

  listarArquivos(parecer) {
    return this.get(`/listarArquivos/${parecer}`);
  }

  consultarArquivo(codigo) {
    return this.get(`/consultarArquivo/${codigo}`);
  }

  adicionarDescricao(arquivo) {
    return this.put(`/adicionarDescricao/${arquivo.codigo}`,arquivo);
  }

  excluirArquivo(codigo) {
    return this.delete(`/excluirArquivo/${codigo}`);
  }

  downloadTemp(codigo) {
    return this.get2(`/downloadTemp/${codigo}`);
  }

  download(codigo) {
    return this.get2(`/download/${codigo}`);
  }
}
