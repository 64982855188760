import {Dropdown} from "primereact/dropdown";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Dialog} from "primereact/dialog";
import React, {useContext, useEffect, useState} from "react";
import UsuarioGrupoTrabalhoService from "../../app/service/UsuarioGrupoTrabalhoService";
import {AuthContext} from "../../main/ProvedorAutenticacao";
import {Button} from "primereact/button";
import GrupoTrabalhoService from "../../app/service/GrupoTrabalhoService";


export const UsuarioGrupoTrabalhoDialog = ({open, onClose, usuario, usuarioNome, toast}) => {

    const [selectGrupo, setSelectGrupo] = useState(null);
    const [gruposSemUsuario, setGruposSemUsuario] = useState([]);
    const [gruposTrabalho, setGruposTrabalho] = useState([]);


    const {usuarioAutenticado} = useContext(AuthContext)

    const usuarioGrupoTrabalhoService = new UsuarioGrupoTrabalhoService()
    const grupoTrabalhoService = new GrupoTrabalhoService()

    async function onGrupoChange(e) {
        await setSelectGrupo(e.value);
        await incluirGrupoUsuario(e.value);
    }

    const incluirGrupoUsuario = (grupo) => {

        if (grupo == null) {
            toast.show({
                severity: 'error',
                summary: 'Usuários',
                detail: 'Informe o Grupo de Trabalho',
                life: 4000
            });
            return false;
        }

        usuarioGrupoTrabalhoService.incluirUsuarioGrupo({
            grupo: grupo,
            usuario: usuario
        }).then(() => {
            listarGruposUsuarios();
            listarGruposSemUsuario();
            setSelectGrupo(null);
        }).catch(error => {
            let detail = error.response.data.detail;
            toast.show({severity: 'error', summary: 'Usuários', detail: `${detail}`, life: 4000});
        })
    }

    const listarGruposUsuarios = () => {
        usuarioGrupoTrabalhoService.listarGruposPorUsuario(usuario.codigo)
            .then(response => {
                setGruposTrabalho(response.data)
            }).catch(() => {
            toast.show({
                severity: 'error',
                summary: 'Usuários',
                detail: 'Ocorreu um erro ao listar Grupos de Trabalho por Usuário',
                life: 4000
            });
        })
    }
    const listarGruposSemUsuario = async () => {

        const filtroGrupo = {
            empresa: usuarioAutenticado.empresa.codigo,
            usuario: usuario.codigo
        }

        await grupoTrabalhoService.listarGruposSemUsuario(filtroGrupo)
            .then(response => {
                setGruposSemUsuario(response.data);
            }).catch(() => {
                console.log('Nenhum grupos encontrado')
            })
    }

    const acoesGrupos = (rowData) => {
        return (
                <Button icon="pi pi-trash" className="p-button-rounded p-button-text"
                        tooltip="Excluir Grupo de Trabalho"
                        tooltipOptions={{position: 'top'}}
                        onClick={() => excluirGrupoUsuario(rowData)}/>
        );
    }

    async function excluirGrupoUsuario(data) {
        await usuarioGrupoTrabalhoService.excluirUsuarioGrupo(data.codigo)
            .then(() => {
                listarGruposUsuarios(data.usuario);
                listarGruposSemUsuario(data.usuario);
              setSelectGrupo(null);
            }).catch(error => {
                let detail = error.response.data.detail
                toast.show({severity: 'error', summary: 'Usuários', detail: `${detail}`, life: 4000});
            })
    }

    useEffect(()=>{
        listarGruposUsuarios()
        listarGruposSemUsuario()
        // eslint-disable-next-line
    },[])
    return (
        <>
            <Dialog header={`Grupos de Trabalho do Usuário - (${usuarioNome})`}
                    visible={open}
                    onHide={onClose}>
                <div class="row">
                    <div class="col-md-8">
                        <div className="p-fluid">
                            <div className="p-field">
                                <label htmlFor="usuarioGrupo">Grupos de Trabalho(Adicionar)</label>
                                <Dropdown value={selectGrupo} options={gruposSemUsuario}
                                          onChange={onGrupoChange}
                                          optionLabel="nome" filter showClear filterBy="nome"
                                          placeholder="Selecione um grupo de trabalho ou usuário"
                                          className="p-inputtext-sm p-d-block p-mb-1"/>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
                <div className="row">
                    <div class="col-md-12">
                        <DataTable value={gruposTrabalho} paginator responsiveLayout="scroll"
                                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                   currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}"
                                   rows={5}
                                   emptyMessage="Nenhum grupo para este usuário">
                            <Column field="grupo.nome" header="Grupo"></Column>
                            <Column body={acoesGrupos} exportable={false} style={{minWidth: '8rem'}}
                                    header="Ações"/>
                        </DataTable>
                    </div>
                </div>
            </Dialog>

        </>
    )
}
