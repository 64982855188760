import React, {useContext, useEffect, useState,} from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {Accordion, AccordionTab} from "primereact/accordion";
import AtividadeService from "../../app/service/AtividadeService";
import {IsNotNull} from "../../utils/IsNullOrEmpy";
import './kanban.css'
import {Loading} from "../../componentes/Loading";
import {AuthContext} from "../../main/ProvedorAutenticacao";

function Kanban({ colunas, toast, filtro, consultarAtividade, editarProcesso }) {
    const [columnsState, setColumnsState] = useState([])
    const [atividades, setAtividades] = useState([])
    const [loading, setLoading] = useState(false)

    //service
    const atividadeService = new AtividadeService();
    const { usuarioAutenticado } =  useContext(AuthContext);
    const handleDragEnd = async (result) => {
        const { destination, source, draggableId } = result;

        if (!destination) {
            return;
        }

        if (
            destination.droppableId === source.droppableId
            // && destination.index === source.index

        ) {

            let newTask = Array.from(atividades)
            // let indexAtividade = newTask.findIndex((ativ)=> ativ.codigo === Number(draggableId))
            let atividadeWithSomeStatus = newTask.filter(it => (it.status.codigo === Number(destination.droppableId)))

           await alterarOrdemAtividades(draggableId, destination.index, atividadeWithSomeStatus)
            return;
        }

        const newTasks = [...atividades];
        const task = newTasks.find((t) => t.codigo === Number(draggableId));
        task.status = Number(destination.droppableId);
        newTasks.splice(source.index, 1);
        newTasks.splice(destination.index, 0, task);
        await alterarStatusAtividade(task)

    };
    const alterarStatusAtividade = async (atividade) => {

        await atividadeService.alterarKanban(atividade, usuarioAutenticado.codigo
        ).then(() => {
            consultarKanban()
        }).catch(error => {
            let detail = error.response.data.detail || "Erro no servidor."
            if(detail === "Usuário não tem permissão para alterar esta atividade"){
                consultarKanban()
            }
            toast.show({ severity: 'error', summary: 'Atividades', detail: `${detail}`, life: 4000 });
        });

    }
    const alterarOrdemAtividades = async (codigoAtividade, destino, listaAtividades) => {
        await atividadeService.alterarKanbanOrdem(codigoAtividade, destino, listaAtividades
        ).then(() => {
            consultarKanban()
        }).catch(error => {
            toast.show({ severity: 'error', summary: 'Atividades', detail: `${error.response.data.detail}`, life: 4000 });
        });

    }

    function consultarKanban(){
        if (IsNotNull(filtro)) {
            // setLoading(true)
            atividadeService.consultarKanban(filtro)
                .then(response => {
                    setAtividades(response.data);
                }).catch(error => {
                toast.show({
                    severity: 'error',
                    summary: 'Painel Kanban ativo',
                    detail: `${error.response.data.detail}`,
                    life: 4000
                });
            })
                // .finally(() => setLoading(false))
        }
    }

    useEffect(() => {
        const newTeste = colunas.map((item) => {
            const newAtvds = atividades.filter(atvd => atvd.status.codigo === item.codigo)
            return { ...item, quantity: newAtvds.length };

        });
        setColumnsState(newTeste);
        // eslint-disable-next-line
    }, [atividades])

    useEffect(consultarKanban, [filtro])

    const card = (data) => {
        return (

                <div className="col-12">
                    <div>
                        <div>
                            <div className="flex-col text-font">
                                <text id={'text-consult'}
                                        onClick={() => consultarAtividade(data)}
                                        className="cursor font-bold mb-2">{data.titulo}</text>
                                <text><span className={'text-bold'}>Data: </span>{`${data.dtLimite} - `}
                                    <span className="dataFatal">{data.dtFatal}</span></text>{
                                    data.dtConcluido &&
                                    <text><span className={'text-bold'}>Concluído: </span>{`${data.dtConcluido}`}</text>
                            }

                            </div>
                            <div className={'text-font'}><span className={'text-bold'}>Grupo: </span>{data.subGrupo.nome}</div>
                            <div className="usuario mt-2">
                                {"Responsável(eis): " + data.responsaveis}
                            </div>
                            {data.processo &&
                                <>

                                    <div className="product-name">
                                        <text className="cursor text-font"
                                              onClick={() => editarProcesso(data)}
                                        >
                                            <span className={'text-bold'}>Processo: </span> {data.processo.nrCnj}
                                        </text>
                                    </div>
                                    {data.processo.partes &&
                                        <div className={'text-font'} style={{ paddingBottom: '5px', paddingTop: '5px' }}>
                                            <span className={'text-bold'}>Partes: </span>
                                            {data.processo.partes}</div>

                                    }
                                </>
                            }

                            <br />
                        </div>
                    </div>
                    {/*Exibindo um numero para atividade */}
                    <span className={'row-etrium justify-content-end gap-1 fs-12'}>{`ETR-${data.codigo}`} </span>
                </div>

        );
    }
    //     setColumnsState(colunas)
    // }, [colunas])

    if (loading) {
        return <Loading open={loading} />
    }
    return (<>

        <DragDropContext onDragEnd={handleDragEnd}>
            <div className="grid-row gap-1">

                {columnsState.map((column) => (
                    <div key={column.codigo}
                    >
                            <Accordion  activeIndex={0} >
                                <AccordionTab header={

                                    <div>
                                        {/* pegando nome da atividade */}
                                        <div>{column.status} - ({column.quantity ? column.quantity : 0})
                                        </div>
                                    </div>

                                } >

                                    <Droppable droppableId={column.codigo.toString()}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                                className={`sortable ${snapshot.isDraggingOver ? 'dragging-over' : ''}`}
                                            >
                                    <div className={"columns-cards"}>
                                                {atividades
                                                    .filter((task) => task.status.codigo === column.codigo)
                                                    .map((task, index) => {
                                                        return (
                                                            <Draggable key={task.codigo}
                                                                draggableId={task.codigo.toString()}
                                                                index={index}>
                                                                {(provided, snapshot) => (
                                                                    <div  onClick={() => { consultarAtividade(task) }}
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        className={`card-kanban ${snapshot.isDragging ? 'dragging' : ''}`}
                                                                    >
                                                                        {card(task)}
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        )
                                                    }
                                                    )}
                                                {provided.placeholder}
                                            </div>
                                    </div>
                                        )}
                                    </Droppable>

                                </AccordionTab>
                            </Accordion>
                    </div>


                ))}
            </div>
        </DragDropContext>
    </>
    );
}

export default Kanban;


