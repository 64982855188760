import ApiSeervice from '../apiservice'

export default class HistoricoProcessoService extends ApiSeervice {

  constructor() {
    super('/historicoProcesso')
  }

  incluirHistorico(historico) {
    return this.post('',historico);
  }

  incluirHistoricoComTipoDecisao(historico) {
    return this.post('/tipoDecisao',historico);
  }

  listarHistorico(processo) {
    return this.get(`/${processo}`);
  }

  excluirHistorico(codigo) {
    return this.delete(`/${codigo}`);
  }
}
