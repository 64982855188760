import EmpresaGraficoService from "../../app/service/EmpresaGraficoService";
import React, {useEffect, useState} from "react";
import Navbar from "../../componentes/Navbar";
import {Badge} from "primereact/badge";
import {Chart} from "primereact/chart";
import {Calendar} from "primereact/calendar";
import {ButtonSm} from "../../componentes/ButtonSm";
import {graficosLegendas, strings} from "../../utils/strings";
import {IsNullOrEmpty} from "../../utils/IsNullOrEmpy";
import {GraficoEmpresaDetailDialog} from "../../componentes/Empresa/GraficoEmpresaDetailDialog";
import {TooltipInfo} from "../../componentes/TooltipInfo";


export default function GraficoEmpresa() {

    const [graficosEmpresas, setGraficoEmpresas] = useState([])
    const [graficosEmpresasStatus, setGraficoEmpresasStatus] = useState([])
    const [graficosEmpresasStatusChart, setGraficoEmpresasStatusChart] = useState([])
    const [dataInicial, setDataInicial] = useState(null)
    const [dataFinal, setDataFinal] = useState(null)
    const [empresaDetail, setEmpresaDetail] = useState(null)

    const empresaGraficoService = new EmpresaGraficoService()

    // const {usuarioAutenticado} = useContext(AuthContext)

    function getStatus(dataIni, dataFim) {
        empresaGraficoService.findStatus(dataIni, dataFim)
            .then((res) => {
                setGraficoEmpresasStatus(res.data)
            })
    }

    function findQauntidadeProcessosAndAtividades(dataIni, dataFim) {
        empresaGraficoService.findQauntidadeProcessosAndAtividades(dataIni, dataFim)
            .then((res) => {
                setGraficoEmpresas(res.data)
            })
    }

    const findALL = () => {
        let dateini = new Date()
        let dateFim = new Date();
        dateini.setDate(1)
        dateFim.setMonth(dateini.getMonth() + 1, 1)
        setDataInicial(dateini)
        setDataFinal(dateFim)
        getStatus(dateini, dateFim)
        findQauntidadeProcessosAndAtividades(dateini, dateFim)
    }

    function filtrarGraficos() {
        if (IsNullOrEmpty(dataInicial)) {
            return
        }
        if (IsNullOrEmpty(dataFinal)) {
            return
        }

        getStatus(dataInicial, dataFinal)
        findQauntidadeProcessosAndAtividades(dataInicial, dataFinal)
    }

    function consultarEmpresaByCodigo(codigo) {
        empresaGraficoService.findEmpresaDetailInfo(codigo).then(r => {
            setEmpresaDetail(r.data)
        })
    }


    const empresasStatus = () => {
        let nomes = graficosEmpresasStatus.map(item => (item.nome))
        let total = graficosEmpresasStatus.map(item => (item.total))
        // let cores = graficosEmpresasStatus.map(item => ((gerarCorAleatoria())))
        setGraficoEmpresasStatusChart({
            labels: nomes,
            datasets: [
                {
                    label: 'Status',
                    backgroundColor: [
                        "#4e8814",
                        "#1229ab",
                        "#ff0000",
                        "#ffe700",
                    ],
                    data: total
                }
            ]
        })
    }

    useEffect(empresasStatus, [graficosEmpresasStatus])
    useEffect(findALL, [])
    const chartsOptions = {
        maintainAspectRatio: false,
        aspectRatio: .8,
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                },
                position: 'bottom',
            }
        },
    }
    const templateGraficosEmpresas = (data) => {
        // let cores = data.map(item => ((gerarCorAleatoria())))
        let chartData = {
            labels: [`Processos (${data.processos})`, `Atividades (${data.atividades})`],
            datasets: [
                {
                    data: [data.processos, data.atividades],
                    backgroundColor: [
                        "#FFCE56",
                        "#36A2EB",
                    ],
                    // hoverBackgroundColor: [
                    //     "#FFCE56",
                    //     "#36A2EB",
                    // ]
                }
            ]
        }


        return (
            <div className="col-sm-4">
                <div className="card">
                    <div className="formGroup">
                        <div className={"cursor"} onClick={() => consultarEmpresaByCodigo(data.codigo)}><Badge
                            value={data.nome +" - " +data.plano} severity={"success"} size='normal'></Badge>
                            <TooltipInfo tooltip={graficosLegendas.empresaGrafico}/>

                        </div>
                        <Chart type="pie" data={chartData} options={chartsOptions} width="150"/>
                    </div>

                </div>
            </div>
        )
    }

    const listTemplate = (items) => {
        if (!items || items.length === 0) return null;

        let list = items.map((empresas, index) => {
            return templateGraficosEmpresas(empresas, index);
        });

        return <div className="grid-etrium mt-0">{list}</div>;
    };


    const basicOptions = {
        maintainAspectRatio: false,
        aspectRatio: .8,
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                },
                position: 'bottom',
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            }
        }
    };

    return (<>
        <Navbar/>
        <div className="content-wrapper">
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-primary card-outline">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i class="pi pi-building"></i>
                                        <span> Painel de Empresas</span>
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className={'formGroup'}>
                                                <div className="card">
                                                      <span style={{textAlign: 'center'}} className="flex-1 gap-1">
                                                        <Calendar id="mask"
                                                                  value={dataInicial}
                                                                  onChange={(e) => setDataInicial(e.value)}
                                                                  locale="es"
                                                                  style={{height: '37px', width: '145px'}}
                                                                  dateFormat="dd/mm/yy"
                                                                  mask="99/99/9999"/>
                                                        <Calendar id="mask"
                                                                  value={dataFinal}
                                                                  onChange={(e) => setDataFinal(e.value)}
                                                                  locale="es"
                                                                  style={{height: '37px', width: '145px'}}
                                                                  dateFormat="dd/mm/yy"
                                                                  mask="99/99/9999"/>
                                                        <ButtonSm style={{height: '37px'}} type="button"
                                                                  className={strings.buttonSecondarySm}
                                                                  onClick={filtrarGraficos}>
                                                            <i className="pi pi-filter"></i>Filtrar</ButtonSm>
                                                      </span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className={'row'}>
                                        <div className="col-sm-12">
                                            <div className="formGroup">
                                                <div className="card flex justify-content-center">
                                                    <h5>Empresas status <TooltipInfo tooltip={graficosLegendas.empresaStatus}/></h5>
                                                    <Chart type="bar" data={graficosEmpresasStatusChart}
                                                           options={basicOptions}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="formGroup">
                                                {listTemplate(graficosEmpresas)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        {empresaDetail && <GraficoEmpresaDetailDialog open={!!empresaDetail} close={() => {
            setEmpresaDetail(null)
        }
        }
                                                      empresa={empresaDetail}
        />}
    </>)

}
