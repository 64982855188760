import ApiService from '../apiservice'

export default class ObjetoAcaoService extends ApiService {

  constructor() {
    super('/objetos')
  }

  listarObjetos(empresa) {
    return this.get(`?empresa=${empresa}`);
  }

  listarObjetosForaProcesso(filtro) {
    return this.get(`/foraProcesso?empresa=${filtro.empresa}&processo=${filtro.processo}`);
  }

  cadastrarObjeto(objeto) {
    return this.post('',objeto);
  }

  consultarObjeto(codigo) {
    return this.get(`/${codigo}`);
  }

  alterarObjeto(objeto) {
    return this.put(`/${objeto.codigo}`, objeto);
  }

  excluirObjeto(codigo) {
    return this.delete(`/${codigo}`);
  }

  listarSubObjetosAcao(objetoPai) {
    return this.get(`/subObjetos?objetoPai=${objetoPai}`);
  }
}
