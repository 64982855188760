import NavBar from "../../componentes/Navbar";
import React, {useContext, useEffect, useRef, useState} from "react";
import {Calendar} from "primereact/calendar";
import {ButtonSm} from "../../componentes/ButtonSm";
import {graficosLegendas, strings} from "../../utils/strings";
import ParecerGraficoService from "../../app/service/ParecerGraficoService";
import {AuthContext} from "../../main/ProvedorAutenticacao";
import {
    DateFormatHour,
    diferencaMaiorQue31Dias,
    formatDateToStringTela,
    primeiroDiaDoMes,
    ultimoDiaDoMes
} from "../../utils/DateFormat";
import {IsNullOrEmpty} from "../../utils/IsNullOrEmpy";
import {Chart} from "primereact/chart";
import {gerarCorAleatoria} from "../../utils/Cores";
import {Toast} from "primereact/toast";
import {chartsOptions} from "../../utils/graficosTheme";
import {Button} from "primereact/button";
import {useHistory} from "react-router-dom";
import {TooltipInfo} from "../../componentes/TooltipInfo";


export const GraficoParecer = () => {

    const [dataInicial, setDataInicial] = useState(null)
    const [dataFinal, setDataFinal] = useState(null)
    const [graficoProdutividade, setGraficoProdutividade] = useState([])
    const [graficoProdutividadeDataset, setGraficoProdutividadeDataset] = useState({})
    const [graficoEficacia, setGraficoEficacia] = useState([])
    const [graficoEficaciaDateset, setGraficoEficaciaDataset] = useState({})
    const [graficoEficiencia, setGraficoEficiencia] = useState([])
    const [graficoEficienciaDateset, setGraficEficienciaDataset] = useState({})

    const navigation = useHistory()
    function goToImprimirRelatorio(){
        let state = {
            graficoProdutividade:graficoProdutividade,
            graficoEficiencia:graficoEficiencia,
            graficoEficacia:graficoEficacia,
            filtro:`${formatDateToStringTela(dataInicial)} - ${formatDateToStringTela(dataFinal)}`
        }
        navigation.push({ pathname: "/painelParecer/imprimir", state: state})
    }

    const graficoService = new ParecerGraficoService()

    const toast =  useRef(null)
    const {usuarioAutenticado, setLoading} = useContext(AuthContext)

    function consultarGraficos(dataIni, dataFim) {
        let _dataIni = dataIni || dataInicial
        let _dataFim = dataFim || dataFinal
        if(diferencaMaiorQue31Dias(_dataIni, _dataFim)){
            let detail = "O período máximo do filtro é 31 dias"
            toast.current.show({severity:'error', summary: 'Graficos Parecer', detail:`${detail}`, life: 4000});
            return
        }
        setLoading(true)
        consultarGraficoProdutividade(_dataIni, _dataFim)
        consultarGraficoEficacia(_dataIni, _dataFim)
        consultarGraficoEficiencia(_dataIni, _dataFim)
        setLoading(false)
    }

    function consultarGraficoProdutividade(dataInicial, dataFinal) {

        if (IsNullOrEmpty(dataInicial)) {
            return
        }
        if (IsNullOrEmpty(dataFinal)) {
            return
        }

        let dataIni = DateFormatHour(dataInicial)
        let dataFim = DateFormatHour(dataFinal)
        graficoService.findGraficosProdutividade(usuarioAutenticado.empresa.codigo, dataIni, dataFim).then((res) => {
            setGraficoProdutividade(res.data)
        }).catch((error)=>{
            let detail = error.response.data.detail
            toast.current.show({severity:'error', summary: 'Graficos Parecer', detail:`${detail}`, life: 4000});
        })
    }
    function consultarGraficoEficacia(dataInicial, dataFinal) {

        if (IsNullOrEmpty(dataInicial)) {
            return
        }
        if (IsNullOrEmpty(dataFinal)) {
            return
        }

        let dataIni = DateFormatHour(dataInicial)
        let dataFim = DateFormatHour(dataFinal)
        graficoService.findGraficosEficacia(usuarioAutenticado.empresa.codigo, dataIni, dataFim).then((res) => {
            setGraficoEficacia(res.data)
        }).catch((error)=>{
            let detail = error.response.data.detail
            toast.current.show({severity:'error', summary: 'Graficos Parecer', detail:`${detail}`, life: 4000});
        })
    }
    function consultarGraficoEficiencia(dataInicial, dataFinal) {

        if (IsNullOrEmpty(dataInicial)) {
            return
        }
        if (IsNullOrEmpty(dataFinal)) {
            return
        }
        let dataIni = DateFormatHour(dataInicial)
        let dataFim = DateFormatHour(dataFinal)
        graficoService.findGraficosEficiencia(usuarioAutenticado.empresa.codigo, dataIni, dataFim).then((res) => {
            setGraficoEficiencia(res.data)
        }).catch((error)=>{
            let detail = error.response.data.detail
            toast.current.show({severity:'error', summary: 'Graficos Parecer', detail:`${detail}`, life: 4000});
        })
    }

    function findDates() {
        // let data = new Date();
        // String(data.getDate()).padStart(2, '0');
        // String(data.getMonth() + 1).padStart(2, '0');
        // let ano = data.getFullYear();
        // let mes =  String(data.getMonth() + 1).padStart(2, '0');
        // let dataInicial = `${ano}-${mes}-01`;
        // let dataFinal = `${ano}-${mes}-31`;
        //
        // let dataInicial2 = new Date(dataInicial);
        // dataInicial2.setDate(dataInicial2.getDate() + 1)
        //
        // let dataFinal2 = new Date(dataFinal);
        // dataFinal2.setDate(dataFinal2.getDate() + 1)
        let _dataInicial = primeiroDiaDoMes()
        let _dataFinal = ultimoDiaDoMes()
        consultarGraficos(_dataInicial, _dataFinal)
        setDataInicial(_dataInicial)
        setDataFinal(_dataFinal)
    }

    function mountGraficos(graficos){
        let responsaveis = graficos.map(item => (`${item.responsavel} - (${item.total})`))
        let total = graficos.map(item => (item.total))
        let cores = graficos.map(() => ((gerarCorAleatoria())))
        return {
            labels: responsaveis,
            datasets: [
                {
                    data: total,
                    backgroundColor: cores,
                }
            ]
        }
    }

    useEffect(() => {
        findDates()
    }, [])
    useEffect(()=> {
        setGraficoProdutividadeDataset(mountGraficos(graficoProdutividade))
    }, [graficoProdutividade])
    useEffect(() => {
        setGraficoEficaciaDataset(mountGraficos(graficoEficacia))
    }, [graficoEficacia])
    useEffect(() => {
        setGraficEficienciaDataset(mountGraficos(graficoEficiencia))
    }, [graficoEficiencia])
    return (
        <>
            <NavBar/>
            <Toast ref={toast} />
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-primary card-outline">
                                    <div className="card-header">
                                        <h3 className="card-title">
                                            <i className="fas fa-regular fa-file nav-icon"></i>
                                            <span> Painel de Pareceres</span>
                                        </h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="card">
                                            <div className="row">
                                                <div className="flex-1">
                                                        <div className={'formGroupP'}>
                                                              <span style={{textAlign: 'center'}} className="flex-1 gap-1">
                                                                <Calendar id="mask"
                                                                          name={"dataInicial"}
                                                                          value={dataInicial}
                                                                          onChange={e => setDataInicial(e.value)}
                                                                          locale="es"
                                                                          style={{height: '37px', width: '145px'}}
                                                                          dateFormat="dd/mm/yy"
                                                                          mask="99/99/9999"/>
                                                                <Calendar id="mask"
                                                                          name={"dataFinal"}
                                                                          value={dataFinal}
                                                                          onChange={e => setDataFinal(e.value)}
                                                                          locale="es"
                                                                          style={{height: '37px', width: '145px'}}
                                                                          dateFormat="dd/mm/yy"
                                                                          mask="99/99/9999"/>
                                                                <ButtonSm style={{height: '37px'}} type="button"
                                                                          className={strings.buttonSecondarySm}
                                                                          onClick={()=>consultarGraficos()}>
                                                                    <i className="pi pi-filter"></i>Filtrar</ButtonSm>
                                                              </span>
                                                    </div>
                                                </div>
                                                <div className={"flex-1 justify-content-end formGroupP"}>
                                                    <Button onClick={goToImprimirRelatorio}
                                                            className={strings.buttonSecondarySm}><i className={"pi pi-print mr-2"}/> Imprimir</Button>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="formGroup">
                                                    <div className="card flex justify-content-center"
                                                         style={{alignItems: 'center'}}>
                                                        <h5 className={"flex flex-row gap-0"}>
                                                            Produtividade
                                                            <TooltipInfo tooltip={graficosLegendas.parecerProdutividade}/>
                                                        </h5>
                                                        <Chart type="pie"
                                                               data={graficoProdutividadeDataset}
                                                               options={chartsOptions}/>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="formGroup">
                                                    <div className="card flex justify-content-center"
                                                         style={{alignItems: 'center'}}>
                                                        <h5 className={"flex flex-row gap-0"}>
                                                            Eficiência
                                                            <TooltipInfo tooltip={graficosLegendas.parecerEficiencia}/>
                                                        </h5>
                                                        <Chart type="pie"
                                                               data={graficoEficienciaDateset}
                                                               options={chartsOptions}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="formGroup">
                                                    <div className="card flex justify-content-center"
                                                         style={{alignItems: 'center'}}>
                                                    <h5 className={"flex flex-row gap-0"}>
                                                            Eficácia
                                                        <TooltipInfo tooltip={graficosLegendas.parecerEficacia}/>
                                                    </h5>
                                                        <Chart type="pie"
                                                               data={graficoEficaciaDateset}
                                                               options={chartsOptions}/>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )

}
