import React from 'react'

import {withRouter} from 'react-router-dom'
import {AuthContext} from '../../main/ProvedorAutenticacao'
import {Toast} from 'primereact/toast';
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {confirmDialog} from 'primereact/confirmdialog'
import {Calendar} from 'primereact/calendar'
import {Dropdown} from 'primereact/dropdown';
import {MultiSelect} from 'primereact/multiselect'
import {ButtonSm} from '../../componentes/ButtonSm';
import {strings} from '../../utils/strings'

import NavBar from '../../componentes/Navbar'
import PushService from '../../app/service/PushService'
import GrupoTrabalhoService from '../../app/service/GrupoTrabalhoService'
import StatusProcessualService from '../../app/service/StatusProcessualService'
import HistoricoProcessoService from '../../app/service/HistoricoProcessoService'
import {DateFormat, primeiroDiaDoMes, ultimoDiaDoMes} from "../../utils/DateFormat";
import {IsNullOrEmpty} from "../../utils/IsNullOrEmpy";
import {Loading} from "../../componentes/Loading";
import {formatCnj} from "../../utils/nrCnj-format";

class Push extends React.Component {
  constructor(props) {
    super(props)

    this.pushService = new PushService();
    this.grupoTrabalhoService = new GrupoTrabalhoService();
    this.statusProcessualService = new StatusProcessualService();
    this.historicoProcessoService = new HistoricoProcessoService();

    this.onChangeOculto = this.onChangeOculto.bind(this);
    this.onChangeClassificacao = this.onChangeClassificacao.bind(this);
    this.onChangeOrdenacao = this.onChangeOrdenacao.bind(this);
    this.acoes = this.acoes.bind(this);
    this.aceptIncluirNoHistorico = this.aceptIncluirNoHistorico.bind(this);

    this.state = {
      movimentos: [],
      movimento: null,
      consultaPorGrupos: '',
      gruposTrabalho: [],
      gruposLista: [],
      consultaPorDataInicial: '',
      consultaPorDataFinal: '',
      statusProcessuais: [],
      statusProcessuaisLista: [],
      consultaPorProcesso: '',
      consultaPorPasta: '',
      consultaPorMovimentacao: '',
      consultaPorOculto: '',
      classificacao: null,
      ordenacao: null,
      selectPush: null,
      openSpinner: false,

    };

    this.ocultos = [
      {codigo: 'P', nome: 'Visíveis'},
      {codigo: 'A', nome: 'Ocultos'},
      {codigo: 'T', nome: 'Todos'}
    ];

    this.classificacao = [
      {codigo: '1', nome: 'Data do movimento'},
      {codigo: '2', nome: 'Movimentação'},
      {codigo: '3', nome: 'Autor'},
      {codigo: '4', nome: 'Réu'},
      {codigo: '5', nome: 'St. processual'},
      {codigo: '6', nome: 'Gp. trabalho'}
    ];

    this.ordem = [
      {codigo: '1', nome: 'Ascendente'},
      {codigo: '2', nome: 'Decrescente'}
    ]
  }

  listarGruposTrabalho = async () => {
    const usuarioLogado = this.context.usuarioAutenticado;
    await this.grupoTrabalhoService.listarGruposUsuario(usuarioLogado.codigo)
      .then(response => {
        this.setState({gruposTrabalho: response.data});
        this.setState({gruposLista: response.data});
      }).catch(error => {
        this.setState(error.response.data)
        this.toast.show({severity:'error', summary: 'Movimentações do push', detail:`${this.state.detail}`, life: 4000});
      })
  }

  listarStatusProcessuais = async () => {
    const usuarioLogado = this.context.usuarioAutenticado;
    await this.statusProcessualService.listarStatusProcessual(usuarioLogado.empresa.codigo)
      .then(response =>  {
        this.setState({statusProcessuais: response.data});
        this.setState({statusProcessuaisLista: response.data});
      }).catch(error => {
        this.setState(error.response.data)
        this.toast.show({severity:'error', summary: 'Movimentações do push', detail:`${this.state.detail}`, life: 4000});
      })
  }

  onChangeOculto(e) {
    this.setState({consultaPorOculto: e.value});
  }

  onChangeClassificacao(e) {
    console.log(e.value)
    this.setState({classificacao: e.value});
  }

  onChangeOrdenacao(e) {
    this.setState({ordenacao: e.value});
  }

  pad2 = (n) => {
    return (n < 10 ? '0' : '') + n;
  }

  consultarMovimentos = async () => {
    this.setState({openSpinner: true})
    const usuarioLogado = this.context.usuarioAutenticado;

    let dataCarregamento = null;
    let dataInicial = null;
    let dataFinal = null;

    if(!IsNullOrEmpty(this.state.consultaPorDataInicial)){
      dataInicial =  DateFormat(this.state.consultaPorDataInicial);
    }
      if (!IsNullOrEmpty(this.state.consultaPorDataFinal)) {
      dataFinal =  DateFormat(this.state.consultaPorDataFinal);
    } else {
      const dataAtual = new Date();
      dataCarregamento =  DateFormat(dataAtual);
      //Data Inicial
      //Data Final
    }

    //Filtro pelos grupos de Trabalhos
    let codigosGrupos = '';
    for(let i = 0; i < this.state.gruposLista.length; i++) {
      if(i < this.state.gruposLista.length -1) {
        codigosGrupos = codigosGrupos + this.state.gruposLista[i].codigo + ",";
      } else {
        codigosGrupos = codigosGrupos + this.state.gruposLista[i].codigo;
      }
    }

    //Filtro por Status Processual
    let codigosStatus = '';
    for(let i = 0; i < this.state.statusProcessuaisLista.length; i++) {
      if(i < this.state.statusProcessuaisLista.length -1) {
        codigosStatus = codigosStatus + this.state.statusProcessuaisLista[i].codigo + ",";
      } else {
        codigosStatus = codigosStatus + this.state.statusProcessuaisLista[i].codigo;
      }
    }

    let classific
    let ordem
    if(IsNullOrEmpty(this.state.classificacao)) {
      classific = '1'
    }else{
      classific = this.state.classificacao.codigo
    }

    if(IsNullOrEmpty(this.state.ordenacao)) {
      ordem = '2'
    }else{
      ordem = this.state.ordenacao.codigo
    }

    const filtro = {
      empresa: usuarioLogado.empresa.codigo,
      grupos: codigosGrupos,
      status: codigosStatus,
      processo: this.state.consultaPorProcesso,
      pasta: this.state.consultaPorPasta,
      movimentacao: this.state.consultaPorMovimentacao,
      dataInicial: dataInicial,
      dataFinal: dataFinal,
      dataCarregamento: dataCarregamento,
      classificacao: classific,
      ordem: ordem
    }

    await this.pushService.consultarMovimentos(filtro)
      .then(response => {
        this.setState({movimentos: response.data});
      }).catch(error => {
        this.setState(error.response.data)
        this.toast.show({severity:'error', summary: 'Movimentações do push', detail:`${this.state.detail}`, life: 4000});
      })

    this.setState({openSpinner: false})

  }

  limparCampos = ()=>{
    this.setState({
      movimento: null,
      consultaPorGrupos: '',
      consultaPorDataInicial: '',
      consultaPorDataFinal: '',
      consultaPorProcesso: '',
      consultaPorPasta: '',
      consultaPorMovimentacao: '',
      consultaPorOculto: '',
      classificacao: null,
      ordenacao: null,
    })
  }

  acoes(rowData) {
    return(
      <div className="row gap-1">
        <Button icon="fas fa-tasks" className="p-button-rounded p-button-text"
                tooltip="Criar atividade"
                tooltipOptions={{position: 'top'}} />
        <Button icon="fas fa-comment-alt" className="p-button-rounded p-button-text"
                tooltip="Incluir no histórico"
                onClick={() => this.confirmaIncluirHistorico(rowData)}
                disabled={rowData.temHistorico !== 'S'}
                tooltipOptions={{position: 'top'}} />
      </div>
    );
  }

  atualizaMovimentoTemHistorico = async (movimento) => {
    await this.pushService.atualizaMovimento(movimento)
      .then(() => {
        this.consultarMovimentos();
      }).catch(error => {
        this.setState(error.response.data)
        this.toast.show({severity:'error', summary: 'Movimentações do push - Atualização', detail:`${this.state.detail}`, life: 4000});
      })
  }

  incluirNoHistorico = async (movimento) => {
    const usuarioLogado = this.context.usuarioAutenticado;
    // const dataAtual =;
    var dataCarregamento = DateFormat( new Date())
    // var month = this.pad2(dataCarregamento.getMonth()+1);//months (0-11)
    // var day = this.pad2(dataCarregamento.getDate());//day (1-31)
    // var year= dataCarregamento.getFullYear();
    // dataCarregamento =  year+"-"+month+"-"+day;

    const processo = {
      codigo: movimento.cdprocesso
    }
    const historico = {
      processo: processo,
      historico: movimento.dsmovimentacao,
      usuario: usuarioLogado,
      dataHistorico: dataCarregamento,
      dataOcorrencia: movimento.dataRegistroHistorico
    };

    await this.historicoProcessoService.incluirHistorico(historico)
      .then(() => {
        this.atualizaMovimentoTemHistorico(movimento);
        this.toast.show({severity:'success', summary: 'Movimentações do push', detail:'Histórico incluído com sucesso', life: 4000});
      }).catch(error => {
        this.setState(error.response.data)
        this.toast.show({severity:'error', summary: 'Movimentações do push', detail:`${this.state.detail}`, life: 4000});
      })
  }

  aceptIncluirNoHistorico() {
    this.incluirNoHistorico(this.state.movimento);
  }

  confirmaIncluirHistorico(movimento) {
      this.setState({movimento: movimento});
      confirmDialog({
          message: 'Deseja realmente incluir esta movimentação no histórico ?',
          header: 'Inclusão de histórico',
          icon: 'pi pi-question-circle',
          acceptClassName: 'p-button-information',
          acceptLabel: 'Sim',
          rejectLabel: 'Não',
          accept: this.aceptIncluirNoHistorico,
          reject: this.reject
      });
  }

  bodyNrCnj = (rowData) => {
      return formatCnj.formatarNumeroProcesso(rowData.nrcnj)
  }

  async componentDidMount() {
    this.setState({openSpinner: true});
    await this.setState({
      consultaPorDataInicial: primeiroDiaDoMes(),
      consultaPorDataFinal: ultimoDiaDoMes()
    })
    await this.listarGruposTrabalho();
    await this.listarStatusProcessuais();
    await this.consultarMovimentos();
    this.setState({openSpinner: false});
  }

  render() {
    return(
        <>
        <NavBar />
        <Toast ref={(el) => this.toast = el} />
        <Loading
          open={this.state.openSpinner}>
        </Loading>
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-primary card-outline">
                    <div className="card-header">
                      <h3 className="card-title">
                        <i className="fas fa-level-down-alt"></i>
                        <span> Movimentações do push</span>
                      </h3>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Grupo de trabalho</label>
                            <MultiSelect inputId="idGrupos"
                                         value={this.state.gruposLista}
                                         options={this.state.gruposTrabalho}
                                         style={{width: '100%', height: '37px', padding_top: '1px'}}
                                         onChange={(e) => this.setState({gruposLista: e.value})}
                                         filter showClear filterBy="nome"
                                         placeholder="Grupos de trabalho"
                                         optionLabel="nome"/>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Status processual</label>
                            <MultiSelect inputId="idStatus"
                                         value={this.state.statusProcessuaisLista}
                                         options={this.state.statusProcessuais}
                                         style={{width: '100%', height: '37px', padding_top: '1px'}}
                                         onChange={(e) => this.setState({statusProcessuaisLista: e.value})}
                                         filter showClear filterBy="descricao"
                                         placeholder="Status processuais"
                                         optionLabel="descricao"/>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            <label>Número do processo</label>
                            <InputText id="idConsultaPorProcesso"
                                       type="text"
                                       maxLength={20}
                                       style={{width: '100%', height: '37px'}}
                                       value={this.state.consultaPorProcesso}
                                       onChange={e => this.setState({consultaPorProcesso: e.target.value})}
                                       placeholder="Número do processo"
                                       className="p-inputtext-sm p-d-block p-mb-1"/>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="flex-1 gap-1">
                            <div className="form-group">
                            <label>Data inicial</label>
                            <Calendar id="idConsultaPorDataInicial"
                                      value={this.state.consultaPorDataInicial}
                                      onChange={(e) => this.setState({consultaPorDataInicial: e.value})}
                                      locale="es"
                                      style={{height: '37px', width: '115px'}}
                                      dateFormat="dd/mm/yy"
                                      placeholder="Dt inicial"
                                      mask="99/99/9999"/>

                            </div>
                            <div className="form-group">
                              <label>Data final</label>
                              <Calendar id="idConsultaPorDataFinal"
                                        value={this.state.consultaPorDataFinal}
                                        onChange={(e) => this.setState({consultaPorDataFinal: e.value})}
                                        locale="es"
                                        style={{height: '37px', width: '115px'}}
                                        dateFormat="dd/mm/yy"
                                        placeholder="Dt final"
                                        mask="99/99/9999"/>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Pasta</label>
                            <InputText id="idConsultaPorPasta"
                                       type="text"
                                       maxLength={50}
                                       style={{width: '100%', height: '37px'}}
                                       value={this.state.consultaPorPasta}
                                       placeholder="Pasta"
                                       onChange={e => this.setState({consultaPorPasta: e.target.value})}
                                       className="p-inputtext-sm p-d-block p-mb-1"/>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Movimentação</label>
                            <InputText id="idConsultaPorMovimentacao"
                                       type="text"
                                       maxLength={100}
                                       style={{width: '100%', height: '37px'}}
                                       value={this.state.consultaPorMovimentacao}
                                       placeholder="Movimentação"
                                       onChange={e => this.setState({consultaPorMovimentacao: e.target.value})}
                                       className="p-inputtext-sm p-d-block p-mb-1"/>
                          </div>
                        </div>
                        {/*temporariamente removido ate entendo oq isso significa*/}
                        {/*<div className="col-md-2">*/}
                        {/*  /!*<div className="form-group">*!/*/}
                        {/*  /!*  <Dropdown value={this.state.consultaPorOculto}*!/*/}
                        {/*  /!*              options={this.ocultos} style={{width: '100%', height: '37px'}}*!/*/}
                        {/*  /!*              optionLabel="nome"*!/*/}
                        {/*  /!*              onChange={this.onChangeOculto}*!/*/}
                        {/*  /!*              id="idVisivelOculto"*!/*/}
                        {/*  /!*              placeholder="Visível/Oculto"*!/*/}
                        {/*  /!*              className="p-inputtext-sm p-d-block p-mb-0"/>*!/*/}
                        {/*  /!*</div>*!/*/}
                        {/*</div>*/}
                        <div className="col-md-2">
                          <div className="form-group">
                            <label>Classificação</label>
                            <Dropdown value={this.state.classificacao}
                                      options={this.classificacao} style={{width: '100%', height: '37px'}}
                                      optionLabel="nome"
                                      onChange={this.onChangeClassificacao}
                                      id="idClassificacao"
                                      placeholder="Classificação"
                                      className="p-inputtext-sm p-d-block p-mb-0"/>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            <label>Ordenação</label>
                            <Dropdown value={this.state.ordenacao}
                                        options={this.ordem} style={{width: '100%', height: '37px'}}
                                        optionLabel="nome"
                                        onChange={this.onChangeOrdenacao}
                                        id="idOrdenação"
                                        placeholder="Ordenação"
                                        className="p-inputtext-sm p-d-block p-mb-0"/>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-8">
                          <div className="form-group flex-1 gap-1">
                            <ButtonSm style={{height: '37px'}} type="button" className={strings.buttonPrimarySm}
                                      onClick={this.consultarMovimentos}>
                              <i className="fas fa-search"></i>Consultar</ButtonSm>
                            <ButtonSm type="button" className={strings.buttonSecondarySm}
                                      onClick={this.limparCampos}>
                              <i className="fa fas fa-eraser"></i>Limpar</ButtonSm>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <DataTable value={this.state.movimentos}  paginator responsiveLayout="scroll"
                              selection={this.state.selectPush} onSelectionChange={(e) => this.setState({ selectPush: e.value })}
                              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                              emptyMessage="Nenhuma movimentação"
                              currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}" rows={10}>
                              <Column field="nrpasta" header="Pasta" style={{width: '200px'}}></Column>
                              <Column body={this.bodyNrCnj} header="CNJ" style={{width: '300px'}}></Column>
                              <Column field="autor" header="Polo ativo" style={{width: '200px'}}></Column>
                              <Column field="reu" header="Polo passivo" style={{width: '200px'}}></Column>
                              <Column field="statusProcessual.descricao" header="Status processual" style={{width: '200px'}}></Column>
                              <Column field="dtUltimaMovimentacao" header="Data última movimentação" style={{width: '200px'}}></Column>
                              <Column field="ultimaMovimentacao" header="Última movimentação" style={{width: '200px'}}></Column>
                              {/*<Column field="dtMovimentacao" header="Dt. Mov." style={{width: '200px'}}></Column>*/}
                              {/*<Column field="dsmovimentacao" header="Movimentação" style={{width: '200px'}}></Column>*/}
                              {/*<Column body={this.acoes} exportable={false} style={{width: '150px'}} header="Ações"></Column>*/}
                            </DataTable>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    )
  }
}

Push.contextType = AuthContext;
export default withRouter (Push)
