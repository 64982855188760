import PessoaService from "../../app/service/PessoaService";
import React, {useContext, useState} from "react";
import {AuthContext} from "../../main/ProvedorAutenticacao";
import {AutoComplete} from "primereact/autocomplete";


export const AutoCompletePessoas = ({selectPessoa, setSelect})=>{
    const [filter, setFilter]= useState();
    const pessoaService = new PessoaService()

    const {usuarioAutenticado} = useContext(AuthContext)

    function consultarPessoa(event) {
        let filtro = {
            usuario: usuarioAutenticado.codigo,
            empresa: usuarioAutenticado.empresa.codigo,
            nome: ""
        }
        if(event.query.toLowerCase().length > 2){
            filtro.nome = event.query.toLowerCase()
            pessoaService.consultarPessoas(filtro).then((res)=>{
                setFilter(res.data)
            })
        }
    }

    return(
        <>
            <AutoComplete value={selectPessoa}
                          suggestions={filter}
                          id={"ac-pessoa"}
                          completeMethod={consultarPessoa}
                          style={{ width: '100%'}}
                          field="nome"
                          name={"pessoa"}
                          placeholder="Pesquise por nome"
                          onChange={setSelect}
                          className="p-inputtext-sm width-100 p-d-block p-mb-1" />
        </>
    )
}
