import ApiService from '../apiservice'

export default class ParecerGraficoService extends ApiService {
  constructor() {
    super('/parecer/graficos')
  }

  findGraficosProdutividade(empresa, dataInicial, dataFinal) {
    return this.get(`/produtividade?empresa=${empresa}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`)
  }
  findGraficosEficacia(empresa, dataInicial, dataFinal) {
    return this.get(`/eficacia?empresa=${empresa}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`)
  }
  findGraficosEficiencia(empresa, dataInicial, dataFinal) {
    return this.get(`/eficiencia?empresa=${empresa}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`)
  }

}
