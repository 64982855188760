import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {strings} from "../../utils/strings";
import React, {useContext, useEffect, useState} from "react";
import {Dropdown} from "primereact/dropdown";
import UsuarioService from "../../app/service/UsuarioService";
import {AuthContext} from "../../main/ProvedorAutenticacao";
import {IsNullOrEmpty} from "../../utils/IsNullOrEmpy";
import ParecerService from "../../app/service/ParecerService";


export const AtribuirResponsavelDialog = ({open, close, toast, parecer, refresh}) => {
    const [usuario, setUsuario] = useState(null)
    const [usuarios, setUsuarios] = useState([])

    const usuarioService = new UsuarioService()
    const parecerService = new ParecerService()
    const {usuarioAutenticado, setLoading} = useContext(AuthContext)
    const handleSubmit = () => {
            if(IsNullOrEmpty(usuario)){
                toast.current.show({severity: 'error', summary: 'Parecer', detail: 'Selecione um usuario', life: 4000});
                return
            }if(IsNullOrEmpty(parecer)){
                toast.current.show({severity: 'error', summary: 'Parecer', detail: 'Erro ao localizar o parecer', life: 4000});
                return
            }
            const newParecer = {...parecer}
            newParecer.usuarioResponsavel = usuario
        setLoading(true)
            parecerService.atribuirResponsavel(newParecer)
                .then(()=>{
                    toast.current.show({severity: 'success', summary: 'Parecer', detail: 'Usuario atribuido', life: 4000});
                    refresh()
                    close()
                })
                .catch(()=>{
                    toast.current.show({severity: 'error', summary: 'Parecer', detail: 'Erro ao tentar atribuir usuario', life: 4000});
                }).finally(()=>{
                    setLoading(false)
            })



    };
    const handleChange = (event) => {
        setUsuario(event.value)
    };

    function getUsers() {
        setLoading(true)
        usuarioService.getUsersByPerfilParecer(usuarioAutenticado.empresa.codigo).then((res) => {
            setUsuarios(res.data)
        })
            .catch(() => {
                toast.current.show({severity: 'error', summary: 'Parecer', detail: 'Erro ao buscar os usuarios', life: 4000});

            })
            .finally(()=>{
                setLoading(false)
            })


    }

    useEffect(() => {
        getUsers()
    }, [])

    function renderFooter() {
        return (
            <div className="flex-row justify-content-start">
                <Button label="Atribuir responsavel"
                        className={strings.buttonPrimaryStyle}
                        onClick={handleSubmit}/>
            </div>
        );
    }

    return (
        <Dialog header="Atribuir um responsável"
                visible={open}
            style={{width: '40vw'}}
                footer={renderFooter()}
                onHide={close}>
            <div className="flex-column">
                    <label>Selecione um Usuario <span className="obrigatorioAsterisco"> * </span></label>
                    <Dropdown
                        value={usuario}
                        name={"tipo"}
                        optionLabel="nome"
                        options={usuarios}
                        filter showClear filterBy="nome"
                        style={{width: '100%'}}
                        onChange={handleChange}
                        id="tipop-id-no-internal"
                        className="p-inputtext-sm p-d-block "/>

            </div>

        </Dialog>
    )
}
