import ApiService from '../apiservice'

export default class PagamentoProcessoService extends ApiService {

  constructor() {
    super('/pagamentoProcesso')
  }

  listarPagamentos(processo) {
    return this.get(`/${processo}`);
  }

  incluirPagamento(pagamento) {
    return this.post(``,pagamento);
  }

  excluirPagamento(codigo) {
    return this.delete(`/${codigo}`);
  }
}
