import ApiService from '../apiservice'

export default class AreaAtuacaoService extends ApiService {

  constructor() {
    super('/areaAtuacao')
  }

  listarAreaAtuacao(empresa) {
    return this.get(`?empresa=${empresa}`);
  }
  cadastrarAreaAtuacao(areaAtuacao) {
    return this.post(``, areaAtuacao);
  }
}