import ApiService from '../apiservice'

export default class StatusAtividadeService extends ApiService {
  constructor() {
    super('/localTramite')
  }

  listarLocalTramite() {
    return this.get(`/all`);
  }
  listarLocalTramitePorNomes(nome) {
    return this.get(`/${nome}`);
  }
}