import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";


export const DialogPesquisaProcesso = ({open, onClose, processos, selecionarProcesso})=>{
    function setProcesso(rowData) {
        selecionarProcesso(rowData)
        onClose();
    }

    function acoesProcesso(rowData) {
        return (
                <Button icon="pi pi-check" className="p-button-rounded p-button-text"
                        tooltip="Selecionar"
                        id={"btn-selecionar-processo"}
                        onClick={()=>setProcesso(rowData)}
                        tooltipOptions={{ position: 'top' }} />
        );
    }

    return(
        <Dialog header="Busca de processo"
                visible={open}
                style={{ width: '60vw' }}
                onHide={onClose}
        >

            <DataTable value={processos} alwaysShowPaginator responsiveLayout="scroll"
                       paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                       currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}"
                       rows={4} emptyMessage=" "
                       paginatorRight selectionMode="multiple">
                <Column field="pasta" header="Pasta"></Column>
                <Column field="nrProcesso" header="Processo original"></Column>
                <Column field="nrCnj" header="Numeração única"></Column>
                <Column field="autor" header="Autor x Réu" style={{ width: '300px' }}></Column>
                <Column field="grupoTrabalho.nome" header="Grupo trab."></Column>
                <Column body={acoesProcesso} exportable={false} style={{ minWidth: '8rem' }}
                        header="Ações"></Column>
            </DataTable>
        </Dialog>
    )
}
