import {Dialog} from "primereact/dialog";
import React, {useEffect} from "react";
import {ALERT_MSG_PROCESSO_AUTO_ALTER, STRING} from "../utils/strings";
import {ButtonEtrium} from "./ButtonEtrium";
import UsuarioService from "../app/service/UsuarioService";
import {InputMask} from "primereact/inputmask";
import {Password} from "primereact/password";
import {IsNullOrEmpty} from "../utils/IsNullOrEmpy";
import {AuthContext} from "../main/ProvedorAutenticacao";
import {validaCPF} from "../utils/Validator";


export const AdicionarUsuarioProcessoAutoDialog =({ usuario, open, onClose,reload, toast})=>{

    const usuarioService = new UsuarioService();
    const { setLoading } = React.useContext(AuthContext);
    const [state, setState] = React.useState({
        cpf: null,
        senha: null
})
    const [hasCpf, setHasCpf] = React.useState(false)
    const removerDoProcessoAuto = usuario.processoAutomatico



    function stateChange(e) {
        const {value: valor, name: campo} = e.target;
        if(campo === 'cpf' && state.cpf !== null){
           return
        }
        setState({...state, [campo]: valor});
    }

    const handleConfirm = () => {
        if(IsNullOrEmpty(state.cpf)){
            toast.show({severity:'error', summary: 'Adicionar processo automático', detail:'CPF obrigatório', life: 4000});
            return
        }
        if(IsNullOrEmpty(state.senha)){
            toast.show({severity:'error', summary: 'Adicionar processo automático', detail:'Senha obrigatória', life: 4000});
            return
        }

        let usuarioAuto = {
            codigo: usuario.codigo,
            cpf: state.cpf,
            senha: state.senha
        }

        setLoading(true)

      usuarioService.adicionarUsuarioProcessoAutomatico(usuarioAuto)
            .then(() => {
                toast.show({severity:'success', summary: 'Adicionar processo automático', detail:'Processo automático adicionado', life: 4000});
               reload()
                onClose();
            })
            .catch(error => {
                let detail = error.response.data.detail || "Erro ao adicionar processo automático"
                toast.show({severity:'error', summary: 'Adicionar processo automático', detail: detail, life: 4000});
            }).finally(()=>{
            setLoading(false)
      })
    };

    const handleConfirmDelete = () => {
        setLoading(true)

        usuarioService.removerUsuarioProcessoAutomatico(usuario.codigo)
            .then(() => {
                toast.show({severity:'success', summary: STRING.title_remover_usuario_cadastro_auto_processo, detail:'Usuario removido do cadastro de processo automático com sucesso', life: 4000});
                reload()
                onClose();
            })
            .catch(error => {
                let detail = error.response.data.detail || "Erro ao tentar remover usuario do cadastro de processo automático"
                toast.show({severity:'error', summary: STRING.title_remover_usuario_cadastro_auto_processo, detail: detail, life: 4000});
            }).finally(()=>{
            setLoading(false)
        })
    };

    function validatedCPF(e){
        const {value } = e.target
        if(IsNullOrEmpty(value)){
            return
        }
        if(!validaCPF(value)){
            toast.show({severity:'error', summary: 'Adicionar processo automático', detail:'CPF invalido', life: 4000});
            setState({...state, cpf: null});
        }
    }

    useEffect(() => {
        if(usuario && !IsNullOrEmpty(usuario.cpf)){
            setState({...state, ['cpf']: usuario.cpf});
            setHasCpf(true)
        }
    }, []);

    const renderFooter = () => {
        return (
            <div className={"row-etrium"}>
              <ButtonEtrium label={"Confirmar"} onClick={handleConfirm}/>
            </div>
        );
    };

    const renderFooterRemover = () => {
        return (
            <div className={"row-etrium"}>
                <ButtonEtrium label={"Confirmar"} onClick={handleConfirmDelete}/>
            </div>
        );
    };

    if(removerDoProcessoAuto){
        const message = "Deseja remover seu usuário do cadastro de processo automático?";
        const messageBody = "Ao remover seu usuário, você poderá perder a funcionalidade de cadastro de processo automático.";

        return (
            <Dialog
                header={STRING.title_remover_usuario_cadastro_auto_processo} autoComplete="nope"
                visible={open}
                style={{width: '50vw'}}
                footer={renderFooterRemover}
                onHide={onClose}

            >
                <div className="flex-row align-content-center gap-1 mb-0">
                    <div className="my-auto">
                        <i className="pi pi-exclamation-triangle" style={{fontSize: '2.5rem',}}></i>
                    </div>
                    <div className={"flex-column"}>
                    <p className="mb-0">
                        {message}
                    </p><p className="mb-0">
                        {messageBody}
                    </p>
                    </div>

                </div>
            </Dialog>
        )
    }
    return(
        <Dialog
            header="Cadastro de processo automático" autoComplete="nope"
            visible={open}
            style={{width: '50vw'}}
            footer={renderFooter}
            onHide={onClose}

        >
            <div className="flex-row align-content-center gap-1 form-group">
                <div className="my-auto">
                    <i className="pi pi-exclamation-triangle" style={{fontSize: '2.5rem',}}></i>
                </div>
                <p className="mb-0">
                    {ALERT_MSG_PROCESSO_AUTO_ALTER}
                </p>

            </div>
            <div className="p-fluid">
                <div className="form-group">
                    <label>CPF<span className="obrigatorioAsterisco"> *</span></label>
                    <InputMask mask="999.999.999-99"
                               id="cpf"
                               type="text"
                               style={{width: '100%'}}
                               name={"cpf"}
                               value={state.cpf}
                               disabled={hasCpf}
                               onBlur={(e)=>validatedCPF(e)}
                               onChange={stateChange}
                               className="p-inputtext-sm p-d-block p-mb-1"/>
                </div>
            </div>
            <div className="p-fluid">
                <div className="form-group">
                    <label htmlFor="nomeStatus">Senha<span className="obrigatorioAsterisco"> *</span></label>
                    <Password id="senha"
                              size="60"
                              autoComplete="nope"
                              toggleMask
                              feedback={false}
                              name={"senha"}
                              value={state.senha}
                              onChange={stateChange}
                              className="p-inputtext-sm p-d-block p-mb-1"
                              placeholder="Senha do sistema Etrium"/>

                </div>
            </div>
        </Dialog>
    )
}
