import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";


export const Loading = ({open})=>{
    return( <Backdrop
        sx={{color: '#fff', zIndex: 9999}}
        open={open}>
        <CircularProgress color="primary" style={{width: '70px', height: '70px'}}/>
    </Backdrop>);
}
