import {Button} from "primereact/button";
import React, {useContext, useEffect, useRef, useState} from "react";
import {Dialog} from "primereact/dialog";
import {strings} from "../utils/strings";
import {Dropdown} from "primereact/dropdown";
import ProcessoAutomaticoService from "../app/service/ProcessoAutomaticoService";
import {IsNullOrEmpty} from "../utils/IsNullOrEmpy";
import {AuthContext} from "../main/ProvedorAutenticacao";
import {Toast} from "primereact/toast";
import {AlertDialog} from "./AlertDialog";
import FilaMovimentacaoService from "../app/service/FilaMovimentacaoService";
import {messageAlertCredentials} from "../pages/processo/CadastrarProcessoAutomatico";


export const DialogSelectSystemProcesso = ({systemName, open, close, processo, handleClose, reloadData}) => {

    const [filas, setFilas] = useState([])
    const processoAutoService = new ProcessoAutomaticoService()
    const filaService = new FilaMovimentacaoService()
    const [credenciaisList, setCredenciaisList] = useState([]);
    const [credential, setCredential] = useState(null);
    const [sistema, setSistema] = useState();
    const {usuarioAutenticado} = useContext(AuthContext);
    const [showAlert, setShowAlert] = useState(false);
    const toastRef = useRef(null)



    const getFilas =()=>{

        processoAutoService.getSistemas().then((res) => {
            const data = res.data
            setFilas(data)
            findSystemBySystemName(data)
        }).catch(
            (error) => {
                const errorData = error.response.data
                const detail = errorData.detail || "Erro ao buscar sistemas"
                console.log(errorData)
                toastRef.current.show({
                    severity: 'error',
                    summary: 'Erro',
                    detail: `${detail}`,
                    life: 4000
                })
            }
        )
    }

    const getUsuarios = () => {
        processoAutoService.getUsuarioNoCadastroDeProcesso(usuarioAutenticado.empresa.codigo).then((res) => {
            const data = res.data
            setCredenciaisList(data)
            if (data.length > 0) {
                setCredential(data[0])
            } else {
                setShowAlert(true)
            }
        })
            .catch(
                (error) => {
                    const detail = error.response.data.detail || "Erro ao buscar credenciais"
                    toastRef.current.show({
                        severity: 'error',
                        summary: 'Erro',
                        detail: `${detail}`,
                        life: 4000
                    })
                }
            )
    }

    function findSystemBySystemName(_filas){
        if(systemName){
        let _sistema = _filas.find((sistema) => systemName.includes(sistema.systemName))
            setSistema(_sistema)
        }
    }

    const isItPush = processo?.snPush === 'S'

    function onSubmit(){
        if(IsNullOrEmpty(sistema)){
            toastRef.current.show({
                severity: 'error',
                summary: 'Campos obrigatórios',
                detail: 'Selecione um sistema',
                life: 4000
            })
            return
        }
        if(IsNullOrEmpty(credential)){
            toastRef.current.show({
                severity: 'error',
                summary: 'Campos obrigatórios',
                detail: 'Selecione uma credencial',
                life: 4000
            })
            return
        }
        if(IsNullOrEmpty(processo)){
            toastRef.current.show({
                severity: 'error',
                summary: 'Erro processo',
                detail: 'Erro ao adicionar processo',
                life: 4000
            })
            return
        }
        const codigoUsuario = usuarioAutenticado?.codigo
        const codigoProcesso = processo?.codigo
        filaService.adicionarProcessoFilaMovimentacao(codigoProcesso, codigoUsuario, credential.codigo, sistema.codigo).then(async () => {
            toastRef.current.show({
                severity: 'success',
                summary: 'Sucesso',
                detail: 'Processo adicionado com sucesso',
                life: 4000
            })
            await reloadData()
            close()
        })

    }

    function removerProcesso(){
        if(IsNullOrEmpty(processo)){
            toastRef.current.show({
                severity: 'error',
                summary: 'Erro processo',
                detail: 'Erro ao adicionar processo',
                life: 4000
            })
            return
        }
        const codigoUsuario = usuarioAutenticado?.codigo
        const codigoProcesso = processo?.codigo
        filaService.removerProcessoFilaMovimentacao(codigoProcesso, codigoUsuario)
            .then(async () => {
                toastRef.current.show({
                    severity: 'success',
                    summary: 'Sucesso',
                    detail: 'Processo removido com sucesso',
                    life: 4000
                })
                await reloadData()
                close()
            })
    }

    useEffect(() => {
        getFilas()
        getUsuarios()
    }, []);

    function renderFooter() {
        return (
            <div className="flex-row justify-content-start">
                {isItPush?
                    <Button label="Remover"
                            className={strings.buttonDanger}
                            onClick={removerProcesso}/>
                    :<Button label="Confirmar"
                         className={strings.buttonPrimaryStyle}
                         onClick={onSubmit}/>}
            </div>
        );
    }

    const title = isItPush ? "Remover processo do push" : "Adicionar processo no push"

    return (
<>
    <Toast ref={toastRef} />
        <Dialog header={title}
                visible={open}
                style={{width: '40vw'}}
                footer={renderFooter()}
                onHide={handleClose}>

            <div className="form-group">
                <label>Sistema
                    <span className="obrigatorioAsterisco"> *</span>
                </label>
                <Dropdown value={sistema}
                          options={filas}
                          style={{width: '100%', height: '37px'}}
                          optionLabel="systemName"
                          filter showClear filterBy="systemName"
                          onChange={(e) => setSistema(e.value)}
                          id="sistema"
                          className="p-inputtext-sm p-d-block p-mb-1 card-max-width"/>
            </div>
            <div className="form-group">
                <label>Credencial<span
                    className="obrigatorioAsterisco"> *</span></label>
                <Dropdown value={credential}
                          options={credenciaisList}
                          style={{width: '100%', height: '37px'}}
                          optionLabel="usuario.nome"
                          filter showClear filterBy="usuario.nome"
                          onChange={(e) => setCredential(e.value)}
                          id="credential"
                          className="p-inputtext-sm p-d-block p-mb-1 card-max-width"/>
            </div>
        </Dialog>
    {showAlert &&
        <AlertDialog
            title="Busca automática de processo"
            open={showAlert}
            onClose={() => setShowAlert(false)}
            message={messageAlertCredentials()}
        />
    }
</>
    );
}
