import React from "react";
import {Button} from 'primereact/button';


export const ButtonSm=({children,...rest})=>{
    return(
        <Button {...rest} style={{height:"37px"}}>
            <div className="row gap-3 center-items">
                {children}
            </div>
        </Button>
    )
}