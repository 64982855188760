import React from 'react'
import { withRouter } from 'react-router-dom'

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

import NavBar from '../../componentes/Navbar'

import GrupoTrabalhoService from '../../app/service/GrupoTrabalhoService'
import { AuthContext } from '../../main/ProvedorAutenticacao'
import {strings} from "../../utils/strings";
import {ButtonSm} from "../../componentes/ButtonSm";
import {Loading} from "../../componentes/Loading";

class SubGrupos extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      //Modal de Cadastro
      displayBasic: false,
      displayBasic2: false,
      position: 'center',
      //Cadastro de Grupos
      codigo: '',
      nome: '',
      descricao: '',
      empresa: '',
      grupoPai: '',
      detail: '',
      visible: false,
      //Tabela
      subGrupos: [],
      grupo: {},
      nomeGrupo: '',
      classeDiv: 'hide',
      openSpinner: false,
    };

    this.acoes = this.acoes.bind(this);
    this.confirmaExclusao = this.confirmaExclusao.bind(this);
    this.grupoService = new GrupoTrabalhoService();
    this.accept = this.accept.bind(this);
  }

  cadastrarSubGrupo = async () => {

    this.setState({openSpinner: true});

    if(!this.state.nome) {
      this.toast.show({severity:'error', summary: 'Sub-grupo de trabalho', detail:'Informe o nome do sub-grupo de trabalho', life: 90000});
      this.setState({classeDiv: 'hide'})
      return false;
    }

    const usuarioLogado = this.context.usuarioAutenticado;
    const empresa = usuarioLogado.empresa;

    await this.grupoService.cadastrarSubGrupo({
      nome: this.state.nome,
      descricao: this.state.descricao,
      empresa: empresa,
      grupoPai: this.state.grupo.grupoPai
    }).then(response => {
      this.toast.show({severity:'success', summary: 'Sub-grupo de trabalho', detail:'Sub-grupo de trabalho cadastrado com sucesso', life: 90000});
      this.listarSubGruposPosCadastro(this.state.grupo.grupoPai);
      this.fecharModalCadastro('displayBasic');
    }).catch(error => {
      this.setState({classeDiv: 'hide'})
      this.setState(error.response.data)
      this.toast.show({severity:'error', summary: 'Sub-grupo de trabalho', detail:`${this.state.detail}`, life: 4000});
    });

    this.setState({openSpinner: false});
  }

  alterarGrupoTrabalho = async () => {
    this.setState({openSpinner: true});
    const usuarioLogado = this.context.usuarioAutenticado;
    await this.grupoService.alterarGrupo({
        codigo: this.state.codigo,
        nome: this.state.nome,
        descricao: this.state.descricao,
        empresa: usuarioLogado.empresa,
        grupoPai: this.state.grupo.codigo
    }).then(response => {
        this.toast.show({severity:'success', summary: 'Sub-grupo de trabalho', detail:'Sub-grupo de trabalho alterado com sucesso', life: 4000})
        this.fecharModalCadastro('displayBasic2')
        this.listarSubGruposPosCadastro(this.state.grupo.codigo);
    }).catch(error => {
        this.setState(error.response.data)
        this.toast.show({severity:'error', summary: 'Sub-grupo de trabalho', detail:`${this.state.detail}`, life: 4000});
    })
    this.setState({openSpinner: false});
  }

  componentDidMount() {
      this.setState({openSpinner: true})
      const params = this.props.match.params;
    if(params.codigo) {
      this.listarSubGrupos(params);
    }
      this.setState({openSpinner: false})
  }

  listarSubGrupos = (params) => {
     this.consultarGrupoTrabalhoPai(params.codigo);
     this.grupoService.listarSubGrupos(params.codigo)
      .then(response => {
        this.setState({subGrupos: response.data})
      }).catch(error => {
        console.log('Erro', error.response);
      })
  }
  listarSubGruposPosCadastro = (codigo) => {
    this.consultarGrupoTrabalhoPai(codigo);
    this.grupoService.listarSubGrupos(codigo)
     .then(response => {
       this.setState({subGrupos: response.data})
     }).catch(error => {
       console.log('Erro', error.response);
     })
  }

  voltar = () => {
    this.props.history.push('/grupoTrabalho')
  }

  consultarGrupoTrabalhoPai = async (codigo) => {
    await this.grupoService.consultarGrupo(codigo)
      .then(response => {
        this.setState({grupo: response.data});
      }).catch(error => {
        console.log('Bola')
      })
  }

  renderFooterCadastroGrupos(name) {
    return (
        <div className="row-etrium">
          <Button label="Cadastrar"
                  className={strings.buttonPrimaryStyle}
                  onClick={this.cadastrarSubGrupo}/>

        </div>
    );
  }

  renderFooterAlterarGrupos(name) {
    return (
        <div className="row gap-1">
          <Button label="Alterar"
                  className={strings.buttonPrimaryStyle}
                  onClick={this.alterarGrupoTrabalho}/>
        </div>
    );
  }

  limparCampos = () => {
    this.setState({nome: '', descricao: ''});
  }

  abrirModalCadastro(name, position) {
    let state = {
        [`${name}`]: true
    };

    if (position) {
        state = {
            ...state,
            position
        }
    }
    this.limparCampos();
    this.setState(state);
  }

  abrirModaAlterar(name, position) {
    let state = {
        [`${name}`]: true
    };

    if (position) {
        state = {
            ...state,
            position
        }
    }

    this.setState(state);
  }

  fecharModalCadastro(name) {
    this.setState({
        [`${name}`]: false
    });
  }

  acoes(rowData) {
    return (
        <div className="row gap-1">
            <Button icon="pi pi-pencil" className={strings.buttonIconSecundary}
                    onClick={() => this.editar(rowData)}
                    tooltip="Editar grupo de trabalho"
                    tooltipOptions={{position: 'top'}} />
            <Button icon="pi pi-trash" className={strings.buttonIconDanger}
                    onClick={() => this.confirmaExclusao(rowData)}
                    tooltip="Excluir grupo de trabalho"
                    tooltipOptions={{position: 'top'}}/>
        </div>
    );
  }

  editar = async (grupo) => {
    this.setState({classeDiv: 'show',openSpinner: true})
    await this.grupoService.consultarGrupo(grupo.codigo)
        .then(response => {
            this.setState({nome: response.data.nome})
            this.setState({descricao: response.data.descricao})
            this.setState({codigo: response.data.codigo})
        }).catch(error => {
            this.setState(error.response.data)
            this.toast.show({severity:'error', summary: 'Sub-grupo de trabalho', detail:`${this.state.detail}`, life: 4000});
        })
    this.setState({classeDiv: 'hide',openSpinner: false})
    this.abrirModaAlterar('displayBasic2')
  }

  async confirmaExclusao(grupo) {
    this.setState({codigo: grupo.codigo,openSpinner:true});
    confirmDialog({
        message: 'Deseja realmente excluir o sub-grupo selecionado?',
        header: 'Exclusão de grupo de trabalho',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        accept: this.accept,
        reject: this.reject
    });
      this.setState({openSpinner: false})

  }

  accept() {
    this.excluirGrupoTrabalho();
  }

  excluirGrupoTrabalho = async () => {
    this.setState({openSpinner: true});
    await this.grupoService.excluirSubGrupo(this.state.codigo)
    .then(response => {
        this.listarSubGruposPosCadastro(this.state.grupo.grupoPai);
        this.toast.show({severity:'success', summary: 'Sub-grupo de trabalho', detail:'Sub-grupo de trabalho excluído com sucesso', life: 4000})
    }).catch(error => {
      this.setState(error.response.data)
      this.toast.show({severity:'error', summary: 'Sub-grupo de trabalho', detail:`${this.state.detail}`, life: 4000});
    })

    this.setState({visible: false});
    this.listarSubGruposPosCadastro(this.state.grupo.codigo);
    this.setState({openSpinner: false});
  }

  render() {
    return(
      <>
        <NavBar/>
          <Loading open={this.state.openSpinner}/>
        <Toast ref={(el) => this.toast = el} />
        <div className="content-wrapper">
        {/**Cadastro de Grupos de Trabalho */}
        <Dialog header="Cadastro de sub-grupo de trabalho"
                  visible={this.state.displayBasic}
                  style={{ width: '40vw' }}
                  footer={this.renderFooterCadastroGrupos('displayBasic')}
                  onHide={() => this.fecharModalCadastro('displayBasic')}>

              <div className="p-fluid">
                  <div className="p-field mb-3">
                      <label htmlFor="nomeGrupo">Nome<span className="obrigatorioAsterisco"> *</span></label>
                      <InputText id="nomeGrupo"
                                  type="text"
                                  maxLength={50}
                                  value={this.state.nome}
                                  onChange={e => this.setState({nome: e.target.value})}/>
                  </div>
                  <div className="p-field">
                      <label htmlFor="descricao">Descrição</label>
                      <InputText id="descricao"
                                  type="text"
                                  maxLength={100}
                                  value={this.state.descricao}
                                  onChange={e => this.setState({descricao: e.target.value})}/>
                  </div>
              </div>
          </Dialog>
          {/**Alterar Grupo de Trabalho */}
          <Dialog header="Alterar grupo de trabalho"
                    visible={this.state.displayBasic2}
                    style={{ width: '40vw' }}
                    footer={this.renderFooterAlterarGrupos('displayBasic2')}
                    onHide={() => this.fecharModalCadastro('displayBasic2')}>

                <div className="p-fluid">
                    <div className="p-field mb-3">
                        <label htmlFor="nomeGrupo">Nome<span className="obrigatorioAsterisco"> *</span></label>
                        <InputText id="nomeGrupo"
                                   type="text"
                                   maxLength={50}
                                   value={this.state.nome}
                                   onChange={e => this.setState({nome: e.target.value})}/>
                    </div>
                    <div className="p-field">
                        <label htmlFor="descricao">Descrição</label>
                        <InputText id="descricao"
                                   type="text"
                                   maxLength={100}
                                   value={this.state.descricao}
                                   onChange={e => this.setState({descricao: e.target.value})}/>
                    </div>
                </div>
            </Dialog>
            <ConfirmDialog visible={this.state.visible}
                           onHide={() => this.setState({ visible: false })}
                           message="Are you sure you want to proceed?"
                           header="Confirmation"
                           icon="pi pi-exclamation-triangle"
                           accept={() => this.excluirGrupoTrabalho}
                           reject={() => this.setState({visible: false})}/>
          <section className="content-header">
              <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                      <div className="card card-primary card-outline">
                        <div className="card-header">
                          <h3 className="card-title">
                            <i className="fas fa-sitemap"></i>
                            <span> Sub-grupo de trabalho - ({this.state.grupo.nome})</span>
                          </h3>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-4 flex-1 gap-1">
                                <ButtonSm type="button" className={strings.buttonPrimarySm}
                                          data-toggle="modal"
                                          onClick={() => this.abrirModalCadastro('displayBasic')}
                                          data-target="#modal-default">
                                    <i className="fas fa-plus"></i>Cadastrar</ButtonSm>
                                <ButtonSm type="button" className={strings.buttonSecondarySm}
                                          data-toggle="modal"
                                          onClick={this.voltar}
                                          data-target="#modal-default">
                                    Voltar</ButtonSm>
                            </div>
                          </div>
                        </div>

                        <DataTable value={this.state.subGrupos}  paginator responsiveLayout="scroll"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}" rows={10} emptyMessage="Nenhum sub-grupo">
                            <Column field="nome" header="Nome"></Column>
                            <Column field="descricao" header="Descrição"></Column>
                            <Column key="codigo"></Column>
                            <Column body={this.acoes} exportable={false} style={{ minWidth: '8rem' }}header="Ações">
                            </Column>
                        </DataTable>
                      </div>
                    </div>
                </div>
              </div>
            </section>
        </div>
      </>
    );
  }
}

SubGrupos.contextType = AuthContext;
export default withRouter (SubGrupos)
