import ApiService from '../apiservice'

export default class TipoDecisaoService extends ApiService {

  constructor() {
    super('/tiposDecisao');
  }

  listarTiposDecisao(empresa) {
    return this.get(`?empresa=${empresa}`);
  }

  cadastrarTipoDecisao(tipo) {
    return this.post('',tipo);
  }

  consultarTipoDecisao(codigo) {
    return this.get(`/${codigo}`);
  }

  alterarTipoDecisao(tipo) {
    return this.put(`/${tipo.codigo}`,tipo);
  }

  excluirTipoDecisao(codigo) {
    return this.delete(`/${codigo}`);
  }
}