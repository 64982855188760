import React, {useContext} from "react";
import {AuthContext} from "../../main/ProvedorAutenticacao";
import Navbar from "../../componentes/Navbar";
import AlterarPlanos from "./AlterarPlano";


const PlanosEmpresa = () => {

    const {usuarioAutenticado} = useContext(AuthContext)
    const empresa = usuarioAutenticado.empresa;

    const Cards = () => {
        return (
            <div className='card card-primary card-outline gap-1'>
                <div className='card-header'>
                    <h3 className="card-title row-etrium gap-1 align-center">
                        <i className="fa fa-solid fa-cart-plus"></i>
                        <span>Contratar um plano</span>
                    </h3>
                </div>
                <div className={"card-body"}>
                   <AlterarPlanos/>
                </div>
            </div>
        )
    }
    if(empresa.status === 3){
        return (
            <div className="">
                <div className="container-fluid">
                    <section className="content-header">
                        <Cards/>
                    </section>
                </div>
            </div>
        )
    }
    return (
        <>
             <Navbar/>
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <section className="content-header">
                            <Cards/>
                        </section>
                    </div>
                </div>
        </>

    )
}
export default PlanosEmpresa
