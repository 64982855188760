import React from 'react';
import '../../componentes/styles/navbar.css';
import logo from './img/etrium-logomenu.png'
import AlterarPlanos from "../planos/AlterarPlano";

const Welcome = () => {

    const NavbarWelcome = () => {
        return (
            <div
                className="flex-1 mb-3">
                <div className="navbar navbar-white navbar-light border-blue flex-1">
                    <div className="navbar-logo">
                        <a href={"/#/login"} className="text-primary no-effect">
                            <img src={logo} alt="AdminLTE Logo" className="img-logo-home" style={{marginLeft: '30px'}}/>
                            <span className="navbar-title">Etrium</span>
                        </a>
                    </div>
                </div>
            </div>
        );
    };
    return (
        <>
            <NavbarWelcome/>
            <div className="container-fluid">
                <div className={"row"}>
                    <div className={"col-sm-12"}>
                        <div className="card text-center w-100">
                            <div className={"card-body flex-col"}>
                                <h1 className={"card-title text-xl"}>Bem-vindo!</h1>
                                <span className={"card-text"}>
                                Obrigado por se cadastrar no Etrium.
                            </span>
                                <span className="card-text text-primary font-weight-bold">
                                Por favor, escolha um dos planos abaixo para começar.
                            </span>

                                <div className={"flex-col gap-0 justify-content-center mt-1"}>
                                    <AlterarPlanos/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


export default Welcome;
