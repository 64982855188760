export const SOLICITADO = 1;
export const RESPONDIDO = 2;
export const DEVOLVIDO = 3;
export const FINALIZADO = 4;
export const RECEBIDO = 5;
export const EM_ANALISE = 6;
export const ATRASADO = 7;
export const AGUARDANDO_FINALIZACAO = 8;

export  const statusParecer = [
    {nome: 'Solicitado', valor: SOLICITADO},
    {nome: 'Respondido', valor: RESPONDIDO},
    {nome: 'Devolvido', valor: DEVOLVIDO},
    {nome: 'Finalizado', valor: FINALIZADO},
    {nome: 'Recebido', valor: RECEBIDO},
    {nome: 'Em analise', valor: EM_ANALISE},
    {nome: 'Em atraso', valor: ATRASADO},
    {nome: 'Aguardando finalizar', valor: AGUARDANDO_FINALIZACAO},
];

export function findParecerName(parecer){
    return statusParecer.find(({nome, valor}) =>  (valor === parecer.status))

}

export const PERFIL_ADM = 1
export const PERFIL_PARECER = 10
export const  PERFIL_PARECER_MASTER = 11
export const  PERFIL_PARECER_ADMIN = 14

export const TEXT_CONCLUIR_PARECER = "Ao solicitar a conclusão do parecer, uma mensagem será enviada para o solicitante do parecer. " +
    "Aguarde o aceite ou a devolução. "+
    "Se desejar, envie uma mensagem de justificativa."


export const coresTipoParecer = {
    Vermelho: '#FF0000',
    Amarelo: '#FFFF00',
    Verde: '#00FF00',
    Azul: '#0000FF',
    Cinza: '#808080'
};


