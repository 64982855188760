import React from 'react'

import {withRouter} from 'react-router-dom'

import {Chart} from 'primereact/chart';
import {DataTable} from 'primereact/datatable'
import {addLocale} from 'primereact/api'
import {Calendar} from 'primereact/calendar'
import {Dropdown} from 'primereact/dropdown'
import {Column} from 'primereact/column'

import NavBar from '../../componentes/Navbar'

import GraficoAtividadeService from '../../app/service/GraficoAtividadeService'
import FinanceiroService from '../../app/service/FinanceiroService'
import UsuarioService from '../../app/service/UsuarioService'
import {AuthContext} from '../../main/ProvedorAutenticacao'

import '../home/painel.css'
import {DateFormat} from "../../utils/DateFormat";
import {strings} from "../../utils/strings";
import {ButtonSm} from "../../componentes/ButtonSm";
import {Loading} from "../../componentes/Loading";
import {nomesMeses} from "../../utils/nomes-meses";

class GraficoAtividadesUsuario extends React.Component {
  constructor(props) {
    super(props);

    this.graficoAtividadeService = new GraficoAtividadeService();
    this.financeiroService = new FinanceiroService();
    this.usuarioService = new UsuarioService();

    this.onChangeSituacao = this.onChangeSituacao.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.templateStatus = this.templateStatus.bind(this);
    this.templateDataLimite = this.templateDataLimite.bind(this);
    this.templateDataConclusao = this.templateDataConclusao.bind(this);

    this.state = {
      meses: [],
      graficoAtividades: [],
      graficoAtividadesData: null,
      graficoAtividadesDataLimite: [],
      graficoAtividadesConcluido: [],
      graficoAtividadesDataLImiteConcluidoData: null,
      graficoStatus: [],
      graficoStatusData: null,
      graficoSituacaoUsuario: [],
      graficoSituacaoUsuarioData: null,
      graficoAtividadeGrupoUsuario: [],
      graficoAtividadeGrupoUsuarioData: null,
      detail: '',
      dataInicial: '',
      dataFinal: '',
      nomeUsuario: '',
      atividadesUsuario: [],
      situacao: null,
      status: null,
      codigoUsuario: '',
      openSpinner: false,
    };

    this.tipoSituacao = [
      {codigo: 'T', nome: 'Tudo'},
      {codigo: 'AT', nome: 'Atrasadas'},
      {codigo: 'CA', nome: 'Concluídas com atraso'},
      {codigo: 'CP', nome: 'Concluídas no prazo'}
    ]

    this.statusOpcao = [
      {codigo: '1', nome: 'Aguardando início'},
      {codigo: '2', nome: 'Não priorizado'},
      {codigo: '3', nome: 'Canceladas'},
      {codigo: '4', nome: 'Suspensas'},
      {codigo: '5', nome: 'Concluídas'},
      {codigo: '6', nome: 'Em atendimento'},
      {codigo: '7', nome: 'Todos os status'}
    ]

    this.basicOptions = {
      maintainAspectRatio: false,
      aspectRatio: .8,
      plugins: {
          legend: {
              labels: {
                  color: '#495057'
              }
          }
      },
    }

    addLocale('es', {
      firstDayOfWeek: 1,
      dayNames: ['Domingo', 'Domingo', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
      dayNamesShort: ['Domingo', 'Domingo', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
      dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoy',
      clear: 'Claro'
    });
  }

  consultarAtividadesCriadas = async (usuario, dataInicial, dataFinal) => {

      var meses = [];

    await this.graficoAtividadeService.graficoTotalUsuario(usuario, dataInicial, dataFinal)
      .then(async response => {
        await this.setState({graficoAtividades: response.data});
          for(let i = 0; i < response.data.length; i++) {
              meses.push(response.data[i].mes);
          }
      }).catch(error => {
        this.setState(error.response.data);
        this.toast.show({severity:'error', summary: 'Gráfico por usuário', detail:`${this.state.detail}`, life: 4000});
      });

      let atividades = [];
      for(let i = 0; i < this.state.graficoAtividades.length; i++) {
        atividades.push(this.state.graficoAtividades[i].total);
      }
    //gera o gráfico

      this.setState({graficoAtividadesData: {
        labels: meses,
        datasets: [
          {

            label: 'Atividades',
            backgroundColor: '#008000',
            data: atividades
          }
        ]
      }});
  }

  atividadesDataLimiteConcluidas = async (usuario, dataInicial, dataFinal) => {
    await this.graficoAtividadeService.graficoDataLimiteUsuario(usuario, dataInicial, dataFinal)
      .then(response => {
        this.setState({graficoAtividadesDataLimite: response.data});
      }).catch(error => {
        this.setState(error.response.data);
        this.toast.show({severity:'error', summary: 'Graficos atividades', detail:`${this.state.detail}`, life: 4000});
      });

    await this.graficoAtividadeService.graficoDataConcluidoUsuario(usuario, dataInicial, dataFinal)
      .then(response => {
        this.setState({graficoAtividadesConcluido: response.data});
      }).catch(error => {
        this.setState(error.response.data);
        this.toast.show({severity:'error', summary: 'Graficos atividades', detail:`${this.state.detail}`, life: 4000});
      });

    // let nomeMeses = no;
    // for(let i = 0; i < this.state.meses.length; i++) {
    //   nomeMeses.push(this.state.meses[i].mes);
    // }

    let dataLimite = [];
    for(let i = 0; i < this.state.graficoAtividadesDataLimite.length; i++) {
      dataLimite.push(this.state.graficoAtividadesDataLimite[i].total);
    }

    let concluidos = [];
    for(let i = 0; i < this.state.graficoAtividadesConcluido.length; i++) {
      concluidos.push(this.state.graficoAtividadesConcluido[i].total);
    }

    this.setState({graficoAtividadesDataLImiteConcluidoData: {
      labels: nomesMeses,
      datasets: [
        {

          label: 'Data Limite',
          backgroundColor: '#FF0000',
          data: dataLimite

        },
        {

          label: 'Concluídas',
          backgroundColor: '#008000',
          data: concluidos

        }
      ]
    }})
  }

  graficoAtividadeGrupoUsuario = async (usuario, dataInicial, dataFinal) => {
    await this.graficoAtividadeService.graficoAtividadeGrupoUsuario(usuario, dataInicial, dataFinal)
      .then(response => {
        this.setState({graficoAtividadeGrupoUsuario: response.data});
      }).catch(error => {
        console.log('Erro gráfico usuário', error);
        this.setState(error.response.data);
        this.toast.show({severity:'error', summary: 'Graficos atividades grupos usuário', detail:`${this.state.detail}`, life: 4000});
      });

      //Grupos
      let grupos = [];
      for(let i = 0; i < this.state.graficoAtividadeGrupoUsuario.length; i++) {
        grupos.push(this.state.graficoAtividadeGrupoUsuario[i].grupo);
      }
      //Cores
      let cores = [];
      for(let i = 0; i < this.state.graficoAtividadeGrupoUsuario.length; i++) {
        cores.push(this.state.graficoAtividadeGrupoUsuario[i].cor);
      }

      //Total
      let valores = [];
      for(let i = 0; i < this.state.graficoAtividadeGrupoUsuario.length; i++) {
        valores.push(this.state.graficoAtividadeGrupoUsuario[i].total);
      }

      await this.setState({graficoAtividadeGrupoUsuarioData: {
        labels: grupos,
        datasets: [
          {
            data: valores,
            backgroundColor: cores,
          }
        ]
      }});
  }

  ativdadesStatus = async (usuario, dataInicial, dataFinal) => {
    await this.graficoAtividadeService.graficoAtividadesStatusUsuario(usuario, dataInicial, dataFinal)
      .then(response => {
        this.setState({graficoStatus: response.data});
      }).catch(error => {
        this.setState(error.response.data);
        this.toast.show({severity:'error', summary: 'Gráficos atividades', detail:`${this.state.detail}`, life: 4000});
      });

      let status = [];
      for(let i = 0; i < this.state.graficoStatus.length; i++) {
        status.push(this.state.graficoStatus[i].status);
      }

      let totais = [];
      for(let i = 0; i < this.state.graficoStatus.length; i++) {
        totais.push(this.state.graficoStatus[i].total);
      }

      await this.setState({graficoStatusData: {
        labels: status,
        datasets: [
          {
            data: totais,
            backgroundColor: [
              "#b4b4aae3",
              "#b8860b",
              "#00ffff",
              "#ff0000",
              "#4e8814",
              "#1229ab"
            ],
          }
        ]
      }});
  }

  graficosAtividadesSituacaoUsuario = async (usuario, dataInicial, dataFinal) => {
    await this.graficoAtividadeService.graficoAtividadesSituacaoUsuario(usuario, dataInicial, dataFinal)
      .then(response => {
        this.setState({graficoSituacaoUsuario: response.data});
      }).catch(error => {
        this.setState(error.response.data);
        this.toast.show({severity:'error', summary: 'Gráficos atividades', detail:`${this.state.detail}`, life: 4000});
      });

    await this.setState({graficoSituacaoUsuarioData: {
      labels: ['Atrasados', 'Concl. atraso', 'Concl. prazo'],
      datasets: [
        {
          data: [this.state.graficoSituacaoUsuario[0].atrasados,
                 this.state.graficoSituacaoUsuario[0].concluidosAtrasados,
                 this.state.graficoSituacaoUsuario[0].concluidosPrazo],
          backgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56"
          ],
          hoverBackgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56"
          ]
        }
      ]
    }})
  }

  atualizarConsultas = async () => {
     //Data Inicial
     var dataFormatadaInicial = '';
     if(this.state.dataInicial !== '') {
       dataFormatadaInicial =  DateFormat(this.state.dataInicial);
     }

     //Data final
     var dataFormatadaFinal = '';
     if(this.state.dataFinal !== '') {
       dataFormatadaFinal =  DateFormat(this.state.dataFinal);
     }
    this.setState({openSpinner: true});
    await this.graficosAtividadesSituacaoUsuario(this.state.codigoUsuario, dataFormatadaInicial, dataFormatadaFinal);
    await this.ativdadesStatus(this.state.codigoUsuario, dataFormatadaInicial, dataFormatadaFinal);
    await this.consultarAtividadesCriadas(this.state.codigoUsuario, dataFormatadaInicial, dataFormatadaFinal);
    await this.graficoAtividadeGrupoUsuario(this.state.codigoUsuario, dataFormatadaInicial, dataFormatadaFinal);
    await this.consultarAtividadesPorUsuario(this.state.codigoUsuario, dataFormatadaInicial, dataFormatadaFinal, this.state.situacao.codigo,
      this.state.status.codigo);
    this.setState({openSpinner: false});

  }


  templateStatus(data) {
    let status
    switch(data.status.codigo) {
      case 1:
          status = 'statusAtivo';
          break;
      case 2:
          status = 'statusAguardando';
          break;
      case 3:
          status = 'statusCancelado'
          break;
      case 4:
          status = 'statusCiente';
          break;
      default:
          status = 'statusConcluido'
          break;
    }

    return(
      <React.Fragment>
        <div className={status}>{data.status.status}</div>
      </React.Fragment>
    );
  }

  templateDataLimite(data) {
    return(
      <React.Fragment>
        <div className="status-cancelado">{data.dtFatal}</div>
      </React.Fragment>
    );
  }

  templateDataConclusao(data) {
    return(
      <React.Fragment>
        <div className="status-concluido">{data.dtConcluido}</div>
      </React.Fragment>
    );
  }

  listarmeses = () => {
    this.financeiroService.listarMeses()
      .then(response => {
        this.setState({meses: response.data});
      }).catch(error => {
        this.setState(error.response.data);
        this.toast.show({severity:'error', summary: 'Gráfico por usuário', detail:`${this.state.detail}`, life: 4000});
      })
  }

  pad2 = (n) => {
    return (n < 10 ? '0' : '') + n;
  }

  consultarUsuario = (usuario) => {
    this.usuarioService.consultarUsuario(usuario)
      .then(response => {
        this.setState({nomeUsuario: response.data.nome});
      }).catch(error => {
        this.setState(error.response.data);
        this.toast.show({severity:'error', summary: 'Gráfico por usuário', detail:`${this.state.detail}`, life: 4000});
      })
  }

  async onChangeSituacao(e) {
    await this.setState({situacao: e.value});
    //Data Inicial
    var dataFormatadaInicial = '';
    if(this.state.dataInicial !== '') {
      dataFormatadaInicial =  DateFormat(this.state.dataInicial);
    }

    //Data final
    var dataFormatadaFinal = '';
    if(this.state.dataFinal !== '') {
      dataFormatadaFinal =  DateFormat(this.state.dataFinal);
    }

    this.setState({openSpinner: true});
    await this.consultarAtividadesPorUsuario(this.state.codigoUsuario, dataFormatadaInicial, dataFormatadaFinal,
      this.state.situacao.codigo, this.state.status.codigo);

    this.setState({openSpinner: false});
  }

  async onChangeStatus(e) {
    await this.setState({status: e.value});
    //Data Inicial
    var dataFormatadaInicial = '';
    if(this.state.dataInicial !== '') {
      dataFormatadaInicial =  DateFormat(this.state.dataInicial);
    }

    //Data final
    var dataFormatadaFinal = '';
    if(this.state.dataFinal !== '') {
      dataFormatadaFinal =  DateFormat(this.state.dataFinal);
    }
    this.setState({openSpinner: true});
    await this.consultarAtividadesPorUsuario(this.state.codigoUsuario, dataFormatadaInicial, dataFormatadaFinal,
      this.state.situacao.codigo, this.state.status.codigo);

    this.setState({openSpinner: false});
  }

  consultarAtividadesPorUsuario = async (usuario, dataInicial, dataFinal, situacao, status) => {
    await this.graficoAtividadeService.consultarAtividadesPorUsuario(usuario, dataInicial, dataFinal, situacao, status)
      .then(response => {
        this.setState({atividadesUsuario: response.data});
      }).catch(error => {
        this.setState(error.response.data);
        this.toast.show({severity:'error', summary: 'Gráfico por usuário', detail:`${this.state.detail}`, life: 4000});
      })
  }

  async componentDidMount() {
    this.setState({openSpinner: true});
    const params = this.props.match.params;
    this.setState({codigoUsuario: params.usuario});

    this.setState({situacao: {
      codigo: 'T', nome: 'Todos'
    }});
    this.setState({status: {
      codigo: '7', nome: 'Todos'
    }});

    var dataInicial = new Date(params.dataInicial);
    dataInicial.setDate(dataInicial.getDate()+1)
    var dataFinal = new Date(params.dataFinal);
    dataFinal.setDate(dataFinal.getDate()+1)

    await this.setState({dataInicial: dataInicial});
    await this.setState({dataFinal: dataFinal});
    await this.listarmeses();
    await this.consultarAtividadesCriadas(params.usuario, params.dataInicial, params.dataFinal);
    await this.atividadesDataLimiteConcluidas(params.usuario, params.dataInicial, params.dataFinal);
    await this.graficosAtividadesSituacaoUsuario(params.usuario, params.dataInicial, params.dataFinal);
    await this.graficoAtividadeGrupoUsuario(params.usuario, params.dataInicial, params.dataFinal);
    await this.ativdadesStatus(params.usuario, params.dataInicial, params.dataFinal);

    await this.consultarUsuario(params.usuario);
    await this.consultarAtividadesPorUsuario(params.usuario, params.dataInicial, params.dataFinal, this.state.situacao.codigo,this.state.status.codigo);
    this.setState({openSpinner: false});

  }

  render() {
    return(
      <>
        <NavBar/>
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                  <div className="card card-primary card-outline">
                    <div className="card-header">
                      <h3 className="card-title gap-1 flex-1">
                        <i className="pi pi-user"></i>
                        Atividades por usuário - Desempenho individual
                      </h3>
                    </div>
                    <br></br>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="formGroup">
                          <div className="flex-1 gap-1 align-items-center" style={{paddingLeft: '30px'}}>
                            <span style={{fontSize: '18px', color: '#36A2EB', fontWeight: 'bold'}}>
                              {this.state.nomeUsuario}
                            </span>
                            <Calendar id="mask"
                                          value={this.state.dataInicial}
                                          onChange={(e) => this.setState({ dataInicial: e.value })}
                                          locale="es"
                                          style={{height: '37px', width: '195px'}}
                                          dateFormat="dd/mm/yy"
                                          mask="99/99/9999"/>
                            <Calendar id="mask"
                                      value={this.state.dataFinal}
                                      onChange={(e) => this.setState({ dataFinal: e.value })}
                                      locale="es"
                                      style={{height: '37px', width: '195px'}}
                                      dateFormat="dd/mm/yy"
                                      mask="99/99/9999"/>
                            <ButtonSm style={{height: '37px'}} type="button" className={strings.buttonInfoSm}
                                    onClick={this.atualizarConsultas}>
                                <i className="pi pi-filter"></i>Consultar</ButtonSm>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="formGroup">
                            <div className="card flex justify-content-center">
                                <h5>Atividades criadas</h5>
                                <Chart type="bar" data={this.state.graficoAtividadesData} options={this.basicOptions} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="formGroup">
                            <div className="card flex justify-content-center">
                                <h5>Situação - Filtrados por data limite</h5>
                                <Chart type="doughnut" data={this.state.graficoSituacaoUsuarioData} options={this.basicOptions} />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="formGroup">
                            <div className="card flex justify-content-center">
                                <h5>Status - Filtrados por data limite</h5>
                                <Chart type="pie" data={this.state.graficoStatusData} options={this.basicOptions} />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="formGroup">
                            <div className="card flex justify-content-center">
                                <h5>Grupos de trabalho - Filtrados por data limite</h5>
                                <Chart type="pie" data={this.state.graficoAtividadeGrupoUsuarioData} options={this.basicOptions} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <br></br>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="formGroup flex-1 gap-1">
                            <Dropdown value={this.state.situacao}
                                    options={this.tipoSituacao} style={{width: '20%', height: '37px'}}
                                    optionLabel="nome"
                                    onChange={this.onChangeSituacao}
                                    id="situacao"
                                    placeholder="Situação"
                                    className="p-inputtext-sm p-d-block p-mb-1"/>
                            <Dropdown value={this.state.status}
                                    options={this.statusOpcao} style={{width: '20%', height: '37px'}}
                                    optionLabel="nome"
                                    onChange={this.onChangeStatus}
                                    id="status"
                                    placeholder="Status"
                                    className="p-inputtext-sm p-d-block p-mb-1"/>
                          </div>
                        </div>
                      </div>
                      <br></br>

                      <Loading
                        open={this.state.openSpinner}>
                      </Loading>

                      <div className="row">
                        <div className="col-sm-12">
                          <div className="formGroup">
                          <DataTable value={this.state.atividadesUsuario} paginator responsiveLayout="scroll" size="small"
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}" rows={10}
                                    emptyMessage="Nenhum Histórico">
                              <Column field="titulo" header="Título"></Column>
                              <Column field="subGrupo.nome" header="Sub-grupo"></Column>
                              <Column field="dtRegistro" header="Data criação"></Column>
                              <Column field={"dtLimite"} exportable={false} style={{ minWidth: '8rem' }} header="Data limite"></Column>
                              <Column body={this.templateDataLimite} header="Prazo fatal"></Column>
                              <Column body={this.templateDataConclusao} exportable={false} style={{ minWidth: '8rem' }} header="Data conclusão"></Column>
                              <Column body={this.templateStatus} exportable={false} style={{ minWidth: '8rem' }} header="Situação"></Column>
                          </DataTable>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    )
  }
}
GraficoAtividadesUsuario.contextType = AuthContext;
export default withRouter (GraficoAtividadesUsuario)
