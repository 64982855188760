import Upload from "../../Upload/Index";
import FileList from "../../FileList";
import Progresso from "../../Progresso";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../../main/ProvedorAutenticacao";
import {reject, uniqueId} from "lodash";
import filesize from "filesize";
import {confirmDialog} from "primereact/confirmdialog";
import {Button} from "primereact/button";
import {strings} from "../../../utils/strings";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";


export const ArquivosTempCadastrarLancamento = ({arquivoFinanceiroService, toast})=>{
    const [upLoadFiles, setUpLoadFiles] = useState([]);
    const [arquivos, setArquivos] = useState([]);
    const [arquivo, setArquivo] = useState(null);
    const [modalDescricaoArquivo, setModalDescricaoArquivo] = useState(false);
    const [dsArquivo, setDsArquivo] = useState('');

    //conditions
    const [loading, setLoading] = useState(false);


    //context
    const {usuarioAutenticado} = useContext(AuthContext)


    const   handleUpload = async files => {
        const upLoadFiles = files.map(file => ({
            file,
            id: uniqueId(),
            name: file.name,
            readableSize: filesize(file.size),
            progress: 0,
            uploaded: false,
            error: false,
            url: file.url
        }))
        setUpLoadFiles(upLoadFiles.concat(upLoadFiles))

        await upLoadFiles.forEach(processar);
    }
    const handleDelete = async id => {
        setUpLoadFiles(upLoadFiles.filter(file => file.id !== id))
    }

    const processar = async (upLoadFile) => {
        const usuarioLogado = usuarioAutenticado;
        const data = new FormData();
        data.append('file', upLoadFile.file);
        data.append('usuario', upLoadFile.file, usuarioLogado.codigo) //Envia código de atividade para salvar os arquivos

        await arquivoFinanceiroService.incluirArquivoTemp(data)
            .then(() => {
                listarArquivosTemp();
                handleDelete(upLoadFile.id);
            }).catch(error => {
                toast.show({ severity: 'error', summary: 'Arquivos', detail: `${error.response.data.detail}`, life: 4000 });
            })
    }

    const listarArquivosTemp = async () => {
        await arquivoFinanceiroService.listarArquivosTemp(usuarioAutenticado.codigo)
            .then(response => {
                setArquivos(response.data)

            }).catch((error) => {
                toast.show({ severity: 'error', summary: 'Arquivos', detail: `${error.response.data.detail}`, life: 4000 });
            })
    }

    const adicionarDescricao = () => {
        arquivoFinanceiroService.adicionarDescricaoTemp({
            codigo: arquivo.codigo,
            descricao: dsArquivo
        }).then(async () => {
            toast.show({ severity: 'success', summary: 'Arquivos', detail: 'Descrição adicionada com sucesso', life: 4000 });
            await listarArquivosTemp();
        }).catch(error => {
            toast.show({ severity: 'error', summary: 'Arquivos', detail: `${error.response.data.detail}`, life: 4000 });
        });

        setModalDescricaoArquivo(false)
    }

    async function consultarArquivoDescricao(arquivo) {
        setArquivo(arquivo)
        setDsArquivo(arquivo.descricao)

        setModalDescricaoArquivo(true)
    }

    async function download(rowData) {
        await consultarArquivo(rowData.codigo);
        await arquivoFinanceiroService.downloadTemp(rowData.codigo)
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', arquivo.arquivo); //or any other extension
                document.body.appendChild(link);
                link.click();
            });
    }

    async function consultarArquivo(arquivo) {
        await arquivoFinanceiroService.consultarArquivoTemp(arquivo.codigo)
            .then(response => {
                setArquivo(response.data)
                setDsArquivo(response.data.descricao)
            }).catch(error => {
                toast.show({ severity: 'error', summary: 'Arquivos', detail: `${error.response.data.detail}`, life: 4000 });
            });
    }
    function  confirmaExclusaoArquivo(arquivo) {

        confirmDialog({
            message: 'Deseja realmente excluir este arquivo?',
            header: 'Exclusão de arquivos',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: ()=>excluirArquivo(arquivo.codigo),
            reject: reject
        });
    }

    const excluirArquivo = (codigo) => {
        arquivoFinanceiroService.excluirArquivoTemp(codigo)
            .then(async () => {
                toast.show({ severity: 'success', summary: 'Arquivos', detail: 'Arquivo excluído com sucesso', life: 4000 });
                await listarArquivosTemp();
            }).catch(error => {
            toast.show({ severity: 'error', summary: 'Arquivos', detail: `${error.response.data.detail}`, life: 4000 });
        })
    }

    async function load(){
        setLoading(true)
        await listarArquivosTemp()
        setLoading(false)
    }

    useEffect(()=>{
        load()
    },[])

    function acoesArquivos(rowData) {
        return (
            <div className="row gap-1">
                <Button icon="pi pi-comment" className="p-button-rounded p-button-text"
                        tooltip="Adicionar descrição ao arquivo"
                        tooltipOptions={{ position: 'top' }}
                        onClick={() => consultarArquivoDescricao(rowData)}
                        style={{ width: '20px', height: '10px' }} />

                <Button icon="pi pi-cloud-download" className="p-button-rounded p-button-text"
                        tooltip="Baixar arquivo"
                        onClick={() => download(rowData)}
                        tooltipOptions={{ position: 'top' }}
                        style={{ width: '20px', height: '10px' }} />

                <Button icon="pi pi-trash" className="p-button-rounded p-button-text"
                        tooltip="Excluir arquivo"
                        onClick={() => confirmaExclusaoArquivo(rowData)}
                        tooltipOptions={{ position: 'top' }}
                        style={{ width: '20px', height: '10px' }} />
            </div>
        );
    }


    function renderFooterDescricaoArquivo() {
        return (
            <div>
                <Button label="Alterar"
                        className={strings.buttonPrimaryStyle}
                        onClick={adicionarDescricao} />
            </div>
        );
    }

    return(
        <>
            <div className="row">
                <div className="col-sm-12">
                    <div className="form-group">
                        <div className="container">

                            <Upload onUpload={handleUpload} />
                            {!!upLoadFiles && (
                                <FileList files={upLoadFiles} onDelete={handleDelete} />
                            )}

                        </div>
                    </div>
                </div>
            </div>
            {loading ? <Progresso />
                :<DataTable value={arquivos} paginator responsiveLayout="scroll" size="small"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}" rows={10}
                            emptyMessage="Nenhum arquivo">
                    <Column field="arquivo" header="Arquivo"/>
                    <Column field="descricao" header="Descrição"/>
                    <Column body={acoesArquivos} exportable={false} style={{minWidth: '8rem'}} header="Ações"/>
                </DataTable>}

            {/**Adicionar descrição ao arquivo */}
            {modalDescricaoArquivo && <Dialog header="Descrição do arquivo"
                                              visible={modalDescricaoArquivo}
                                              style={{width: '40vw'}}
                                              footer={renderFooterDescricaoArquivo}
                                              onHide={() => setModalDescricaoArquivo(false)}>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label htmlFor="descricaoArquivo">Descrição</label>
                            <InputText id="descricaoArquivo"
                                       type="text"
                                       style={{width: '100%', height: '37px'}}
                                       value={dsArquivo}
                                       onChange={e => setDsArquivo(e.target.value)}
                                       className="p-inputtext-sm p-d-block p-mb-1"/>
                        </div>
                    </div>
                </div>
            </Dialog>}
        </>
    )
}
