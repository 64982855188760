import CryptoJS from 'crypto-js';

export function encryptJson(obj, secret) {
    const jsonString = JSON.stringify(obj);
    return CryptoJS.AES.encrypt(jsonString, secret).toString();
}

export function decryptJson(encrypted, secret) {
    const bytes = CryptoJS.AES.decrypt(encrypted, secret);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decrypted);
}