import React from 'react'

import {withRouter} from 'react-router-dom'

import {AutoComplete} from 'primereact/autocomplete';
import {MultiSelect} from 'primereact/multiselect'
import {Dropdown} from 'primereact/dropdown'
import {Calendar} from 'primereact/calendar'
import {InputNumber} from 'primereact/inputnumber'
import {Checkbox} from 'primereact/checkbox'
import {Button} from 'primereact/button'
import {Toast} from 'primereact/toast';
import {confirmDialog} from 'primereact/confirmdialog'

import LocalStorageService from '../../app/service/localStorageService'
import RelatorioProcessoService from '../../app/service/RelatorioProcessoService'
import PessoaService from '../../app/service/PessoaService'
import GrupoTrabalhoService from '../../app/service/GrupoTrabalhoService'
import StatusProcessualService from '../../app/service/StatusProcessualService'
import TipoAcaoService from '../../app/service/TipoAcaoService'
import TipoDecisaoService from '../../app/service/TipoDecisao'
import AreaAtuacaoService from '../../app/service/AreaAtuacaoService'
import ObjetoAcaoService from '../../app/service/ObjetoAcaoService'
import TipoPagamentoService from '../../app/service/TipoPagamentoService'
import TiposCustasService from '../../app/service/TiposCustasService'

import NavBar from '../../componentes/Navbar'
import {DateFormat} from "../../utils/DateFormat";
import {USUARIO_LOGADO} from "../../app/service/AuthService";
import {strings} from "../../utils/strings";
import TipoGarantiaService from "../../app/service/TipoGarantiaService";
import LiminarService from "../../app/service/LiminarService";
import {IsNullOrEmpty} from "../../utils/IsNullOrEmpy";

class RelatorioProcessos extends React.Component {
    constructor(props) {
        super(props);
        this.relatorioProcessoService = new RelatorioProcessoService();
        this.pessoaService = new PessoaService();
        this.grupoTrabalhoService = new GrupoTrabalhoService();
        this.statusProcessualService = new StatusProcessualService();
        this.tipoAcaoService = new TipoAcaoService();
        this.tipoDecisaoService = new TipoDecisaoService();
        this.areaAtuacaoService = new AreaAtuacaoService();
        this.objetoAcaoService = new ObjetoAcaoService();
        this.tipoPagamentoService = new TipoPagamentoService();
        this.tiposCustasService = new TiposCustasService();
        this.tipoGarantiaService = new TipoGarantiaService();
        this.liminarService = new LiminarService()

        this.consultarPessoa = this.consultarPessoa.bind(this);
        this.onCamposChange = this.onCamposChange.bind(this);
        this.acoes = this.acoes.bind(this);
        this.accepExcluirRelatorio = this.accepExcluirRelatorio.bind(this);

        this.state = {
            usuario: null,
            empresa: '',
            detail: '',
            pessoas: [],
            filterPessoas: null,
            selectedPessoa: null,
            gruposTrabalho: [],
            selectedGrupoTrabalho: null,
            statusProcessual: [],
            selectedStatusProcessual: null,
            tiposAcao: [],
            selectedTipoAcao: null,
            tiposDecisao: [],
            selectedTipoDecisao: null,
            areasAtuacao: [],
            selectedAreaAtuacao: null,
            objetosAcao: [],
            selectedObjetoAcao: null,
            //Pesquisas por datas
            dataDistribuicaoInicial: null,
            dataDistribuicaoFinal: null,
            dataSentencaInicial: null,
            dataSentencaFinal: null,
            dataDecisaoInicial: null,
            dataDecisaoFinal: null,
            dataAlteracaoInicial: null,
            dataAlteracaoFinal: null,
            dataCadastroInicial: null,
            dataCadastroFinal: null,
            importancia: null,
            excetoArquivados: null,
            //as pesquisas que envolvem valor
            tiposPagamento: [],
            selectedTipoPagamento: null,
            operadorLogicoPagamentos: null,
            valorPagamento: '0',
            tiposCusta: [],
            selectedTipoCusta: null,
            operadorLogicoCustas: null,
            valorCustas: '0',
            tiposGarantias: [],
            selectedTipoGarantias: null,
            operadorLogicoGarantias: null,
            valorGarantias: '0',
            camposRelatorio: [],
            relatoriosExportados: [],
            codigoExcluirRelatorio: '',
            //relatorio
            // caminhoRelatorio: ''
            // liminar
            cumprimentoLiminar: null,
            statusLiminar: null,
            cumprimentoLiminarOptions: [],
            statusLiminarOptions: []
        }

        this.operadorLogicoOptions = [
            {codigo: 'IGUAL', nome: 'Igual'},
            {codigo: 'MAIOR', nome: 'Maior que'},
            {codigo: 'MAIORIGUAL', nome: 'Maior ou igual'},
            {codigo: 'MENOR', nome: 'Menor'},
            {codigo: 'MENORIGUAL', nome: 'Menor ou igual'}
        ]

    }


    consultaRelatoriosUsuario = () => {
        this.relatorioProcessoService.listarRelatoriosExportados(this.state.usuario.codigo)
            .then(response => {
                this.setState({relatoriosExportados: response.data});
            }).catch(error => {
            this.setState(error.response.data)
            this.toast.show({
                severity: 'error',
                summary: 'Relatórios de processos',
                detail: `${this.state.detail}`,
                life: 4000
            });
        })
    }

    listarPessoas = () => {
        const user = LocalStorageService.obterItem(USUARIO_LOGADO);
        this.setState({usuario: user})

        this.pessoaService.listarPessoas(user.empresa.codigo)
            .then(response => {
                this.setState({pessoas: response.data});
            }).catch(error => {
            this.setState(error.response.data)
            this.toast.show({
                severity: 'error',
                summary: 'Relatórios de processos',
                detail: `${this.state.detail}`,
                life: 4000
            });
        })
    }

    consultarPessoa(event) {
        setTimeout(() => {
            let filterPessoas;
            if (!event.query.trim().length) {
                filterPessoas = [...this.state.pessoas];
            } else {
                filterPessoas = this.state.pessoas.filter((pessoa) => {
                    return pessoa.nome.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            this.setState({filterPessoas});
        }, 250);
    }

    listarGruposTrabalho = () => {

        this.grupoTrabalhoService.listarGruposUsuario(this.state.usuario.codigo)
            .then(response => {
                this.setState({gruposTrabalho: response.data});
            }).catch(error => {
            this.setState(error.response.data)
            this.toast.show({
                severity: 'error',
                summary: 'Relatório de processos',
                detail: `${this.state.detail}`,
                life: 4000
            });
        })
    }

    listarStatusProcessual = () => {

        this.statusProcessualService.listarStatusProcessual(this.state.usuario.empresa.codigo)
            .then(response => {
                this.setState({statusProcessual: response.data});
            }).catch(error => {
            this.setState(error.response.data)
            this.toast.show({
                severity: 'error',
                summary: 'Relatório de processos',
                detail: `${this.state.detail}`,
                life: 4000
            });
        })
    }

    listarTiposAcao = () => {

        this.tipoAcaoService.listarTipoAcao(this.state.usuario.empresa.codigo)
            .then(response => {
                this.setState({tiposAcao: response.data});
            }).catch(error => {
            this.setState(error.response.data)
            this.toast.show({
                severity: 'error',
                summary: 'Relatório de processos',
                detail: `${this.state.detail}`,
                life: 4000
            });
        })
    }

    listarTiposDecisao = () => {

        this.tipoDecisaoService.listarTiposDecisao(this.state.usuario.empresa.codigo)
            .then(response => {
                this.setState({tiposDecisao: response.data});
            }).catch(error => {
            this.setState(error.response.data)
            this.toast.show({
                severity: 'error',
                summary: 'Relatório de processos',
                detail: `${this.state.detail}`,
                life: 4000
            });
        })
    }

    listaAreaAtuacao = () => {

        this.areaAtuacaoService.listarAreaAtuacao(this.state.usuario.empresa.codigo)
            .then(response => {
                this.setState({areasAtuacao: response.data});
            }).catch(error => {
            this.setState(error.response.data)
            this.toast.show({
                severity: 'error',
                summary: 'Relatório de processos',
                detail: `${this.state.detail}`,
                life: 4000
            });
        })
    }

    listarObjetosAcao = () => {

        this.objetoAcaoService.listarObjetos(this.state.usuario.empresa.codigo)
            .then(response => {
                this.setState({objetosAcao: response.data});
            }).catch(error => {
            this.setState(error.response.data)
            this.toast.show({
                severity: 'error',
                summary: 'Relatório de processos',
                detail: `${this.state.detail}`,
                life: 4000
            });
        })
    }

    listarTiposPagamentos = () => {

        this.tipoPagamentoService.listarTiposPagamentos(this.state.usuario.empresa.codigo)
            .then(response => {
                this.setState({tiposPagamento: response.data});
            }).catch(error => {
            this.setState(error.response.data)
            this.toast.show({
                severity: 'error',
                summary: 'Relatório de processos',
                detail: `${this.state.detail}`,
                life: 4000
            });
        })
    }

    listarTiposCustas = () => {

        this.tiposCustasService.listarTiposCusta(this.state.usuario.empresa.codigo)
            .then(response => {
                this.setState({tiposCusta: response.data})
            }).catch(error => {
            this.setState(error.response.data)
            this.toast.show({
                severity: 'error',
                summary: 'Relatório de processos',
                detail: `${this.state.detail}`,
                life: 4000
            });
        })
    }

    listarTiposGarantias = () => {
        this.tipoGarantiaService.listarGarantias(this.state.usuario.empresa.codigo)
            .then(response => {
                this.setState({tiposGarantias: response.data})
            }).catch(error => {
            this.setState(error.response.data)
            this.toast.show({
                severity: 'error',
                summary: 'Relatório de processos',
                detail: `${this.state.detail}`,
                life: 4000
            });
        })
    }

    async componentDidMount() {
        await this.listarPessoas();
        await this.listarGruposTrabalho();
        await this.listarStatusProcessual();
        await this.listarTiposAcao();
        await this.listarTiposDecisao();
        await this.listaAreaAtuacao();
        await this.listarObjetosAcao();
        await this.listarTiposPagamentos();
        await this.listarTiposCustas();
        await this.listarTiposGarantias();
        await this.consultaRelatoriosUsuario();
        await this.getCumprimentoOptions()
        await this.getStatusLiminarOptions()
    }

    onCamposChange(e) {
        let selectCampos = [...this.state.camposRelatorio];

        if (e.checked)
            selectCampos.push(e.value);
        else
            selectCampos.splice(selectCampos.indexOf(e.value), 1);

        this.setState({camposRelatorio: selectCampos});
    }

    pad2 = (n) => {
        return (n < 10 ? '0' : '') + n;
    }

    emitirRelatorio = () => {


        var areaAtuacao = 0;
        var tipoDecisao = 0;
        let tipoAcao = 0;
        var statusProcessual = 0;
        let liminarCumprimento = 0
        let liminarStatus = 0

        if (this.state.selectedAreaAtuacao !== null) {
            areaAtuacao = this.state.selectedAreaAtuacao.codigo;
        }

        if (this.state.selectedTipoAcao !== null) {
            tipoAcao = this.state.selectedTipoAcao.codigo;
        }
        if (this.state.selectedTipoDecisao !== null) {
            tipoDecisao = this.state.selectedTipoDecisao.codigo;
        }

        if (this.state.selectedStatusProcessual !== null) {
            statusProcessual = this.state.selectedStatusProcessual.codigo;
        }
        if (!IsNullOrEmpty(this.state.cumprimentoLiminar)) {
            liminarCumprimento = this.state.cumprimentoLiminar.codigo
        }
        if (!IsNullOrEmpty(this.state.statusLiminar)) {
            liminarStatus = this.state.statusLiminar.codigo
        }

        var contador = 0;
        var tamanhoLista = 0;

        //Pessoas
        var codigosPessoa = '0';
        if (this.state.selectedPessoa !== null) {
            contador = 0;
            tamanhoLista = this.state.selectedPessoa.length;
            for (let i = 0; i < this.state.selectedPessoa.length; i++) {
                if (contador < tamanhoLista - 1) {
                    codigosPessoa = codigosPessoa + this.state.selectedPessoa[i].codigo + ",";
                } else {
                    codigosPessoa = codigosPessoa + this.state.selectedPessoa[i].codigo;
                }
                contador++;
            }
        }

        //Objetos de Ação
        var codigosObjetos = '0';
        if (this.state.selectedObjetoAcao !== null) {
            contador = 0;
            tamanhoLista = this.state.selectedObjetoAcao.length;
            for (let i = 0; i < this.state.selectedObjetoAcao.length; i++) {
                if (contador < tamanhoLista - 1) {
                    codigosObjetos = codigosObjetos + this.state.selectedObjetoAcao[i].codigo + ",";
                } else {
                    codigosObjetos = codigosObjetos + this.state.selectedObjetoAcao[i].codigo;
                }

                contador++;
            }
        }

        //Grupos de Trabalho
        var codigosGrupos = '0';
        if (this.state.selectedGrupoTrabalho !== null) {
            contador = 0;
            tamanhoLista = this.state.selectedGrupoTrabalho.length;
            for (let i = 0; i < this.state.selectedGrupoTrabalho.length; i++) {
                if (contador < tamanhoLista - 1) {
                    codigosGrupos = codigosGrupos + this.state.selectedGrupoTrabalho[i].codigo + ",";
                } else {
                    codigosGrupos = codigosGrupos + this.state.selectedGrupoTrabalho[i].codigo;
                }

                contador++;
            }
        }

        //Pagamentos
        var codigosPagamentos = '0';
        if (this.state.selectedTipoPagamento !== null) {
            contador = 0;
            tamanhoLista = this.state.selectedTipoPagamento.length;
            for (let i = 0; i < this.state.selectedTipoPagamento.length; i++) {
                if (contador < tamanhoLista - 1) {
                    codigosPagamentos = codigosPagamentos + this.state.selectedTipoPagamento[i].codigo + ",";
                } else {
                    codigosPagamentos = codigosPagamentos + this.state.selectedTipoPagamento[i].codigo;
                }

                contador++;
            }
        }

        //Valores do pagamento
        var operadorLogicoPagamentos = '0'
        if(IsNullOrEmpty(this.state.valorPagamento)){
            this.toast.show({
                severity: 'error',
                summary: 'Relatório de processos',
                detail: 'Informe o valor do pagamento',
                life: 4000
            });
            return false;
        }
        if (!IsNullOrEmpty(this.state.valorPagamento) && parseFloat(this.state.valorPagamento) > 0) {
            if (IsNullOrEmpty(this.state.operadorLogicoPagamentos)) {
                this.toast.show({
                    severity: 'error',
                    summary: 'Relatório de processos',
                    detail: 'Informe o operador lógico dos pagamentos',
                    life: 4000
                });
                return false;
            }

            operadorLogicoPagamentos = this.state.operadorLogicoPagamentos.codigo;
        }

        //Custas
        var codigosCustas = '0';
        if (this.state.selectedTipoCusta !== null) {
            contador = 0;
            tamanhoLista = this.state.selectedTipoCusta.length;
            for (let i = 0; i < this.state.selectedTipoCusta.length; i++) {
                if (contador < tamanhoLista - 1) {
                    codigosCustas = codigosCustas + this.state.selectedTipoCusta[i].codigo + ",";
                }

                codigosCustas = codigosCustas + this.state.selectedTipoCusta[i].codigo;

                contador++;
            }
        }

        //Valores das custas
        var operadorLogicoCustas = '0';
        if(IsNullOrEmpty(this.state.valorCustas)){
            this.toast.show({
                severity: 'error',
                summary: 'Relatório de processos',
                detail: 'Informe o valor das custas',
                life: 4000
            });
            return false;
        }
        if (!IsNullOrEmpty(this.state.valorCustas) && parseFloat(this.state.valorCustas) > 0) {
            if (this.state.operadorLogicoCustas === null) {
                this.toast.show({
                    severity: 'error',
                    summary: 'Relatório de processos',
                    detail: 'Informe o operador lógico das custas',
                    life: 4000
                });
                return false;
            }
            operadorLogicoCustas = this.state.operadorLogicoCustas.codigo;
        }

        //garantias
        var codigosGarantias = '0';
        if (this.state.selectedTipoGarantias !== null && this.state.selectedTipoGarantias.length > 0) {
            codigosGarantias = this.state.selectedTipoGarantias.map(garantia => garantia.codigo).join(',');
        }
        //Valores das garantias
        let operadorLogicoGarantias = '0';
        if(IsNullOrEmpty(this.state.valorGarantias)){
            this.toast.show({
                severity: 'error',
                summary: 'Relatório de processos',
                detail: 'Informe o valor das garantias',
                life: 4000
            });
            return false;
        }
        if (!IsNullOrEmpty(this.state.valorGarantias) && parseFloat(this.state.valorGarantias) > 0) {
            if (this.state.operadorLogicoGarantias === null) {
                this.toast.show({
                    severity: 'error',
                    summary: 'Relatório de processos',
                    detail: 'Informe o operador lógico das garantias',
                    life: 4000
                });
                return false;
            }
            operadorLogicoGarantias = this.state.operadorLogicoGarantias.codigo;
        }

        //Data de decisão
        var dataDecisaoInicial = '0';
        var dataDecisaoFinal = '0';
        if (this.state.dataDecisaoInicial !== null && this.state.dataDecisaoFinal !== null) {
            //Data inicial
            dataDecisaoInicial = DateFormat(this.state.dataDecisaoInicial);
            //Data final
            dataDecisaoFinal = DateFormat(this.state.dataDecisaoFinal);
        }

        //Data da sentença
        var dataSentencaInicial = '0';
        var dataSentencaFinal = '0';
        if (this.state.dataSentencaInicial !== null && this.state.dataSentencaFinal !== null) {
            //Data inicial
            dataSentencaInicial = DateFormat(this.state.dataSentencaInicial);
            //Data final
            dataSentencaFinal = DateFormat(this.state.dataSentencaFinal);
        }

        //Data da distribuição
        var dataDistribuicaoInicial = '0';
        var dataDistribuicaoFinal = '0';
        if (this.state.dataDistribuicaoInicial !== null && this.state.dataDistribuicaoFinal !== null) {
            //Data inicial
            dataDistribuicaoInicial = DateFormat(this.state.dataDistribuicaoInicial);
            //Data final
            dataDistribuicaoFinal = DateFormat(this.state.dataDistribuicaoFinal);
        }

        //Data de Cadastro
        var dataCadastroInicial = '0';
        var dataCadastroFinal = '0';
        if (this.state.dataCadastroInicial !== null && this.state.dataCadastroFinal !== null) {
            //Data inicial
            dataCadastroInicial = DateFormat(this.state.dataCadastroInicial);
            //Data final
            dataCadastroFinal = DateFormat(this.state.dataCadastroFinal);
        }

        //Data de Alteração
        var dataAlteracaoInicial = '0';
        var dataAlteracaoFinal = '0';
        if (this.state.dataAlteracaoInicial !== null && this.state.dataAlteracaoFinal !== null) {
            //Data inicial
            dataAlteracaoInicial = DateFormat(this.state.dataAlteracaoInicial);
            //Data final
            dataAlteracaoFinal = DateFormat(this.state.dataAlteracaoFinal);
        }

        var campoNumeroProcesso = 'none';
        var campoNumeroCnj = 'none';
        var campoPasta = 'none';
        var campoCliente = 'none';
        var campoParteContraria = 'none';
        var campoOutrasPartes = 'none';
        var campoTipoDecisao = 'none';
        var campoTipoAcao = 'none';
        var campoAreaAtuacao = 'none';
        var campoDataDecisao = 'none';
        var campoDataCadastro = 'none';
        var campoDataSentenca = 'none';
        var campoDataAlteracao = 'none';
        var campoDataDistribuicao = 'none';
        var campoStatusProcessual = 'none';
        var campoGrupoTrabalho = 'none';
        var campoObjetoAcao = 'none';
        var campoGarantia = 'none';
        var campoValorGarantia = 'none';
        var campoPagamento = 'none';
        var campoValorPagamento = 'none';
        var campoCustas = 'none';
        var campoValorCustas = 'none';
        var campoPush = 'none';
        var campoPushEmail = 'none';
        var campoPushHistorico = 'none';
        var campoAtividade = 'none';
        var campoDataUltimaAtividade = 'none';
        var campoUltimoHistorico = 'none';
        var campoDataUltimoHistorico = 'none';
        var campoCumprimentoLiminar = 'none'
        var campoStatusLiminar = 'none'
        if (this.state.camposRelatorio.length === 0) {
            this.toast.show({
                severity: 'error',
                summary: 'Campos do relatório',
                detail: 'Selecione pelo menos um campo para gerar o relatório',
                life: 4000
            });
            return false;
        }

        for (let i = 0; i < this.state.camposRelatorio.length; i++) {

            if (this.state.camposRelatorio[i] === 'numeroProcesso') {
                campoNumeroProcesso = 'hide';
            }

            if (this.state.camposRelatorio[i] === 'numeroCnj') {
                campoNumeroCnj = 'hide';
            }

            if (this.state.camposRelatorio[i] === 'pasta') {
                campoPasta = 'hide';
            }

            if (this.state.camposRelatorio[i] === 'cliente') {
                campoCliente = 'hide';
            }

            if (this.state.camposRelatorio[i] === 'parteContraria') {
                campoParteContraria = 'hide';
            }

            if (this.state.camposRelatorio[i] === 'outrasPartes') {
                //mudou para outras partes
                campoOutrasPartes = 'hide';
            }

            if (this.state.camposRelatorio[i] === 'tipoDecisao') {
                campoTipoDecisao = 'hide';
            }

            if (this.state.camposRelatorio[i] === 'tipoAcao') {
                campoTipoAcao = 'hide';
            }

            if (this.state.camposRelatorio[i] === 'areaAtuacao') {
                campoAreaAtuacao = 'hide';
            }

            if (this.state.camposRelatorio[i] === 'dataDecisao') {
                campoDataDecisao = 'hide';
            }

            if (this.state.camposRelatorio[i] === 'dataCadastro') {
                campoDataCadastro = 'hide';
            }

            if (this.state.camposRelatorio[i] === 'dataSentenca') {
                campoDataSentenca = 'hide';
            }

            if (this.state.camposRelatorio[i] === 'dataAlteracao') {
                campoDataAlteracao = 'hide';
            }

            if (this.state.camposRelatorio[i] === 'dataDistribuicao') {
                campoDataDistribuicao = 'hide';
            }

            if (this.state.camposRelatorio[i] === 'statusProcessual') {
                campoStatusProcessual = 'hide';
            }

            if (this.state.camposRelatorio[i] === 'grupoTrabalho') {
                campoGrupoTrabalho = 'hide';
            }

            if (this.state.camposRelatorio[i] === 'objetoAcao') {
                campoObjetoAcao = 'hide';
            }

            if (this.state.camposRelatorio[i] === 'garantia') {
                campoGarantia = 'hide';
            }

            if (this.state.camposRelatorio[i] === 'valorGarantia') {
                campoValorGarantia = 'hide';
            }

            if (this.state.camposRelatorio[i] === 'pagamento') {
                campoPagamento = 'hide';
            }

            if (this.state.camposRelatorio[i] === 'valorPagamento') {
                campoValorPagamento = 'hide';
            }

            if (this.state.camposRelatorio[i] === 'custas') {
                campoCustas = 'hide';
            }

            if (this.state.camposRelatorio[i] === 'valorCustas') {
                campoValorCustas = 'hide';
            }

            if (this.state.camposRelatorio[i] === 'push') {
                campoPush = 'hide';
            }

            if (this.state.camposRelatorio[i] === 'pushEmail') {
                campoPushEmail = 'hide';
            }

            if (this.state.camposRelatorio[i] === 'pushHistorico') {
                campoPushHistorico = 'hide';
            }

            if (this.state.camposRelatorio[i] === 'atividade') {
                campoAtividade = 'hide';
            }

            if (this.state.camposRelatorio[i] === 'dataUltimaAtividade') {
                campoDataUltimaAtividade = 'hide';
            }

            if (this.state.camposRelatorio[i] === 'ultimoHistorico') {
                campoUltimoHistorico = 'hide';
            }

            if (this.state.camposRelatorio[i] === 'dataUltimoHistorico') {
                campoDataUltimoHistorico = 'hide';
            }
            if (this.state.camposRelatorio[i] === 'liminarCumprimento') {
                campoCumprimentoLiminar = 'hide';
            }
            if (this.state.camposRelatorio[i] === 'liminarStatus') {
                campoStatusLiminar = 'hide';
            }
        }
        window.open(`/#/relatorioProcessoResult/${this.state.usuario.empresa.codigo}/${this.state.usuario.codigo}/${codigosPessoa}/${dataDecisaoInicial}/${dataDecisaoFinal}/${dataSentencaInicial}/${dataSentencaFinal}/${dataDistribuicaoInicial}/${dataDistribuicaoFinal}/${dataCadastroInicial}/${dataCadastroFinal}/${dataAlteracaoInicial}/${dataAlteracaoFinal}/${codigosGrupos}/${codigosObjetos}/${codigosPagamentos}/${operadorLogicoPagamentos}/${this.state.valorPagamento}/${codigosCustas}/${operadorLogicoCustas}/${this.state.valorCustas}/${codigosGarantias}/${operadorLogicoGarantias}/${this.state.valorGarantias}/${statusProcessual}/${areaAtuacao}/${tipoDecisao}/${this.state.excetoArquivados}/${this.state.importancia}/${campoNumeroProcesso}/${campoNumeroCnj}/${campoPasta}/${campoCliente}/${campoParteContraria}/${campoOutrasPartes}/${campoTipoDecisao}/${campoTipoAcao}/${campoAreaAtuacao}/${campoDataDecisao}/${campoDataCadastro}/${campoDataSentenca}/${campoDataAlteracao}/${campoDataDistribuicao}/${campoStatusProcessual}/${campoGrupoTrabalho}/${campoObjetoAcao}/${campoGarantia}/${campoValorGarantia}/${campoPagamento}/${campoValorPagamento}/${campoCustas}/${campoValorCustas}/${campoPush}/${campoPushEmail}/${campoPushHistorico}/${campoAtividade}/${campoDataUltimaAtividade}/${campoUltimoHistorico}/${campoDataUltimoHistorico}/${campoCumprimentoLiminar}/${campoStatusLiminar}/${liminarCumprimento}/${liminarStatus}/${tipoAcao}`, "_blank");

        // this.setState({ caminhoRelatorio: `#/relatorioProcessoResult/${this.state.usuario.empresa.codigo}/${this.state.usuario.codigo}/${codigosPessoa}/${dataDecisaoInicial}/${dataDecisaoFinal}/${dataSentencaInicial}/${dataSentencaFinal}/${dataDistribuicaoInicial}/${dataDistribuicaoFinal}/${dataCadastroInicial}/${dataCadastroFinal}/${dataAlteracaoInicial}/${dataAlteracaoFinal}/${codigosGrupos}/${codigosObjetos}/${codigosPagamentos}/${operadorLogicoPagamentos}/${this.state.valorPagamento}/${codigosCustas}/${operadorLogicoCustas}/${this.state.valorCustas}/${statusProcessual}/${areaAtuacao}/${tipoDecisao}/${this.state.excetoArquivados}/${this.state.importancia}/${campoNumeroProcesso}/${campoNumeroCnj}/${campoPasta}/${campoCliente}/${campoParteContraria}/${campoAdvogado}/${campoTipoDecisao}/${campoTipoAcao}/${campoAreaAtuacao}/${campoDataDecisao}/${campoDataCadastro}/${campoDataSentenca}/${campoDataAlteracao}/${campoDataDistribuicao}/${campoStatusProcessual}/${campoGrupoTrabalho}/${campoObjetoAcao}/${campoGarantia}/${campoValorGarantia}/${campoPagamento}/${campoValorPagamento}/${campoCustas}/${campoValorCustas}/${campoPush}/${campoPushEmail}/${campoPushHistorico}/${campoAtividade}/${campoDataUltimaAtividade}/${campoUltimoHistorico}/${campoDataUltimoHistorico}` });

    }

    limparCamposPesquisa = () => {
        this.setState({selectedPessoa: null});
        this.setState({selectedGrupoTrabalho: null});
        this.setState({selectedStatusProcessual: null});
        this.setState({selectedTipoDecisao: null});
        this.setState({selectedTipoAcao: null});
        this.setState({selectedAreaAtuacao: null});
        this.setState({selectedObjetoAcao: null});
        this.setState({dataDistribuicaoInicial: null});
        this.setState({dataDistribuicaoFinal: null});
        this.setState({dataSentencaInicial: null});
        this.setState({dataSentencaFinal: null});
        this.setState({dataDistribuicaoInicial: null});
        this.setState({dataDistribuicaoFinal: null});
        this.setState({dataDecisaoInicial: null});
        this.setState({dataDecisaoFinal: null});
        this.setState({dataCadastroInicial: null});
        this.setState({dataCadastroFinal: null});
        this.setState({dataAlteracaoInicial: null});
        this.setState({dataAlteracaoFinal: null});
        this.setState({operadorLogicoPagamentos: null});
        this.setState({operadorLogicoCustas: null});
        this.setState({selectedTipoPagamento: null});
        this.setState({selectedTipoCusta: null});
        this.setState({valorPagamento: '0'});
        this.setState({valorCustas: '0'});
    }

    exportarRelatorio = async () => {
        const usuarioLogado = LocalStorageService.obterUsuario('_usuario_logado');
        const usuario = JSON.parse(usuarioLogado);

        let listaCampos = [];

        for (let i = 0; i < this.state.camposRelatorio.length; i++) {
            if (this.state.camposRelatorio[i] === 'numeroProcesso') {
                listaCampos.push({
                    codigo: 1,
                    nome: 'Numero processo'
                });
            }

            if (this.state.camposRelatorio[i] === 'numeroCnj') {
                listaCampos.push({
                    codigo: 2,
                    nome: 'Numero CNJ'
                });
            }

            if (this.state.camposRelatorio[i] === 'pasta') {
                listaCampos.push({
                    codigo: 3,
                    nome: 'Pasta'
                });
            }

            if (this.state.camposRelatorio[i] === 'cliente') {
                listaCampos.push({
                    codigo: 20,
                    nome: 'Autores'
                });
            }

            if (this.state.camposRelatorio[i] === 'parteContraria') {
                listaCampos.push({
                    codigo: 21,
                    nome: 'Réus'
                })
            }

            if (this.state.camposRelatorio[i] === 'advogado') {
                listaCampos.push({
                    codigo: 22,
                    nome: 'Advogados'
                })
            }

            if (this.state.camposRelatorio[i] === 'tipoDecisao') {
                listaCampos.push({
                    codigo: 26,
                    nome: 'Tipo decisao'
                });
            }

            if (this.state.camposRelatorio[i] === 'tipoAcao') {
                listaCampos.push({
                    codigo: 25,
                    nome: 'Tipo ação'
                });
            }

            if (this.state.camposRelatorio[i] === 'areaAtuacao') {
                listaCampos.push({
                    codigo: 27,
                    nome: 'Área de atuacao'
                });
            }

            if (this.state.camposRelatorio[i] === 'dataDecisao') {
                listaCampos.push({
                    codigo: 5,
                    nome: 'Data decisao'
                });
            }

            if (this.state.camposRelatorio[i] === 'dataCadastro') {
                listaCampos.push({
                    codigo: 8,
                    nome: 'Data cadastro'
                });
            }

            if (this.state.camposRelatorio[i] === 'dataSentenca') {
                listaCampos.push({
                    codigo: 7,
                    nome: 'Data sentenca'
                });
            }

            if (this.state.camposRelatorio[i] === 'dataAlteracao') {
                listaCampos.push({
                    codigo: 6,
                    nome: 'Data movimentacao'
                });
            }

            if (this.state.camposRelatorio[i] === 'dataDistribuicao') {
                listaCampos.push({
                    codigo: 4,
                    nome: 'Data distribuicao'
                });
            }

            if (this.state.camposRelatorio[i] === 'statusProcessual') {
                listaCampos.push({
                    codigo: 24,
                    nome: 'Status processual'
                });
            }

            if (this.state.camposRelatorio[i] === 'grupoTrabalho') {
                listaCampos.push({
                    codigo: 23,
                    nome: 'Grupo trabalho'
                });
            }

            if (this.state.camposRelatorio[i] === 'objetoAcao') {
                listaCampos.push({
                    codigo: 19,
                    nome: 'Objeto ação'
                });
            }

            if (this.state.camposRelatorio[i] === 'pagamento') {
                listaCampos.push({
                    codigo: 28,
                    nome: 'Pagamento'
                });
            }

            if (this.state.camposRelatorio[i] === 'valorPagamento') {
                listaCampos.push({
                    codigo: 29,
                    nome: 'Valor pagamentos'
                });
            }

            if (this.state.camposRelatorio[i] === 'custas') {
                listaCampos.push({
                    codigo: 30,
                    nome: 'Custas'
                });
            }

            if (this.state.camposRelatorio[i] === 'valorCustas') {
                listaCampos.push({
                    codigo: 31,
                    nome: 'Valor custas'
                });
            }

            if (this.state.camposRelatorio[i] === 'push') {
                listaCampos.push({
                    codigo: 11,
                    nome: 'Envia push'
                });
            }

            if (this.state.camposRelatorio[i] === 'pushEmail') {
                listaCampos.push({
                    codigo: 12,
                    nome: 'Envia email'
                });
            }

            if (this.state.camposRelatorio[i] === 'pushHistorico') {
                listaCampos.push({
                    codigo: 13,
                    nome: 'Envia histórico'
                });
            }

            if (this.state.camposRelatorio[i] === 'atividade') {
                listaCampos.push({
                    codigo: 15,
                    nome: 'Última atividade'
                });
            }

            if (this.state.camposRelatorio[i] === 'dataUltimaAtividade') {
                listaCampos.push({
                    codigo: 16,
                    nome: 'Dt última atividade'
                });
            }

            if (this.state.camposRelatorio[i] === 'ultimoHistorico') {
                listaCampos.push({
                    codigo: 17,
                    nome: 'Último histórico'
                });
            }

            if (this.state.camposRelatorio[i] === 'dataUltimoHistorico') {
                listaCampos.push({
                    codigo: 18,
                    nome: 'Dt último histórico'
                });
            }
        }

        var areaAtuacao = 0;
        var tipoDecisao = 0;
        var statusProcessual = 0;

        if (this.state.selectedAreaAtuacao !== null) {
            areaAtuacao = this.state.selectedAreaAtuacao.codigo;
        }

        if (this.state.selectedTipoDecisao !== null) {
            tipoDecisao = this.state.selectedTipoDecisao.codigo;
        }

        if (this.state.selectedStatusProcessual !== null) {
            statusProcessual = this.state.selectedStatusProcessual.codigo;
        }

        var contador = 0;
        var tamanhoLista = 0;

        //Pessoas
        var codigosPessoa = '0';
        if (this.state.selectedPessoa !== null) {
            contador = 0;
            tamanhoLista = this.state.selectedPessoa.length;
            for (let i = 0; i < this.state.selectedPessoa.length; i++) {
                if (contador < tamanhoLista - 1) {
                    codigosPessoa = codigosPessoa + this.state.selectedPessoa[i].codigo + ",";
                } else {
                    codigosPessoa = codigosPessoa + this.state.selectedPessoa[i].codigo;
                }
                contador++;
            }
        }

        //Objetos de Ação
        var codigosObjetos = '0';
        if (this.state.selectedObjetoAcao !== null) {
            contador = 0;
            tamanhoLista = this.state.selectedObjetoAcao.length;
            for (let i = 0; i < this.state.selectedObjetoAcao.length; i++) {
                if (contador < tamanhoLista - 1) {
                    codigosObjetos = codigosObjetos + this.state.selectedObjetoAcao[i].codigo + ",";
                } else {
                    codigosObjetos = codigosObjetos + this.state.selectedObjetoAcao[i].codigo;
                }

                contador++;
            }
        }

        //Grupos de Trabalho
        var codigosGrupos = '0';
        if (this.state.selectedGrupoTrabalho !== null) {
            contador = 0;
            tamanhoLista = this.state.selectedGrupoTrabalho.length;
            for (let i = 0; i < this.state.selectedGrupoTrabalho.length; i++) {
                if (contador < tamanhoLista - 1) {
                    codigosGrupos = codigosGrupos + this.state.selectedGrupoTrabalho[i].codigo + ",";
                } else {
                    codigosGrupos = codigosGrupos + this.state.selectedGrupoTrabalho[i].codigo;
                }

                contador++;
            }
        }

        //Pagamentos
        var codigosPagamentos = '0';
        if (this.state.selectedTipoPagamento !== null) {
            contador = 0;
            tamanhoLista = this.state.selectedTipoPagamento.length;
            for (let i = 0; i < this.state.selectedTipoPagamento.length; i++) {
                if (contador < tamanhoLista - 1) {
                    codigosPagamentos = codigosPagamentos + this.state.selectedTipoPagamento[i].codigo + ",";
                } else {
                    codigosPagamentos = codigosPagamentos + this.state.selectedTipoPagamento[i].codigo;
                }

                contador++;
            }
        }

        //Valores do pagamento
        var operadorLogicoPagamentos = '0'
        if (this.state.valorPagamento !== '0') {
            if (this.state.operadorLogicoPagamentos === null) {
                this.toast.show({
                    severity: 'error',
                    summary: 'Relatório de processos',
                    detail: 'Informe o operador lógico dos pagamentos',
                    life: 4000
                });
                return false;
            }

            operadorLogicoPagamentos = this.state.operadorLogicoPagamentos.codigo;
        }

        //Custas
        var codigosCustas = '0';
        if (this.state.selectedTipoCusta !== null) {
            contador = 0;
            tamanhoLista = this.state.selectedTipoCusta.length;
            for (let i = 0; i < this.state.selectedTipoCusta.length; i++) {
                if (contador < tamanhoLista - 1) {
                    codigosCustas = codigosCustas + this.state.selectedTipoCusta[i].codigo + ",";
                } else {
                    codigosCustas = codigosCustas + this.state.selectedTipoCusta[i].codigo;
                }

                contador++;
            }
        }

        //Valores das custas
        var operadorLogicoCustas = '0';
        if (this.state.valorCustas !== '0') {
            if (this.state.operadorLogicoCustas === null) {
                this.toast.show({
                    severity: 'error',
                    summary: 'Relatório de processos',
                    detail: 'Informe o operador lógico das custas',
                    life: 4000
                });
                return false;
            }
            operadorLogicoCustas = this.state.operadorLogicoCustas.codigo;
        }

        //Data de decisão
        var dataDecisaoInicial = '0';
        var dataDecisaoFinal = '0';
        if (this.state.dataDecisaoInicial !== null && this.state.dataDecisaoFinal !== null) {
            //Data inicial

            dataDecisaoInicial = DateFormat(this.state.dataDecisaoInicial);
            //Data final

            dataDecisaoFinal = DateFormat(this.state.dataDecisaoFinal);

        }

        //Data da sentença
        var dataSentencaInicial = '0';
        var dataSentencaFinal = '0';
        if (this.state.dataSentencaInicial !== null && this.state.dataSentencaFinal !== null) {
            //Data inicial

            dataSentencaInicial = DateFormat(this.state.dataSentencaInicial);

            //Data final

            dataSentencaFinal = DateFormat(this.state.dataSentencaFinal);
        }

        //Data da distribuição
        var dataDistribuicaoInicial = '0';
        var dataDistribuicaoFinal = '0';
        if (this.state.dataDistribuicaoInicial !== null && this.state.dataDistribuicaoFinal !== null) {
            //Data inicial

            dataDistribuicaoInicial = DateFormat(this.state.dataDistribuicaoInicial);


            //Data final

            dataDistribuicaoFinal = DateFormat(this.state.dataDistribuicaoFinal);
        }

        //Data de Cadastro
        var dataCadastroInicial = '0';
        var dataCadastroFinal = '0';
        if (this.state.dataCadastroInicial !== null && this.state.dataCadastroFinal !== null) {
            //Data inicial

            dataCadastroInicial = DateFormat(this.state.dataCadastroInicial);
            //Data final

            dataCadastroFinal = DateFormat(this.state.dataCadastroFinal);
        }

        //Data de Alteração
        var dataAlteracaoInicial = '0';
        var dataAlteracaoFinal = '0';
        if (this.state.dataAlteracaoInicial !== null && this.state.dataAlteracaoFinal !== null) {
            //Data inicial


            dataAlteracaoInicial = DateFormat(this.state.dataAlteracaoInicial)
            //Data final

            dataAlteracaoFinal = DateFormat(this.state.dataAlteracaoFinal)
        }

        const filtro = {
            pessoa: codigosPessoa,
            grupoTrabalho: codigosGrupos,
            areaAtucacao: areaAtuacao,
            tipoDecisao: tipoDecisao,
            dataInicialDecisao: dataDecisaoInicial,
            dataFinalDecisao: dataDecisaoFinal,
            dataInicialDistribuicao: dataDistribuicaoInicial,
            dataFinalDistribuicao: dataDistribuicaoFinal,
            statusProcessual: statusProcessual,
            excetoProcessosArquivados: '',
            dataInicialSentenca: dataSentencaInicial,
            dataFinalSentenca: dataSentencaFinal,
            objetoAcao: codigosObjetos,
            garantia: '',
            operadorLogicoGarantia: '',
            valorGarantia: '',
            pagamentos: codigosPagamentos,
            operadorLogicoPagamento: operadorLogicoPagamentos,
            valorPagamento: this.state.valorPagamento,
            custas: codigosCustas,
            operadorLogicoCustas: operadorLogicoCustas,
            valorCustas: this.state.valorCustas,
            dataInicialCadastro: dataCadastroInicial,
            dataFinalCadastro: dataCadastroFinal,
            dataInicialAlteracao: dataAlteracaoInicial,
            dataFinalAlteracao: dataAlteracaoFinal,
            importancia: this.state.importancia,
            empresa: this.state.usuario.empresa.codigo,
            usuario: this.state.usuario.codigo
        };

        const relatorioProcesso = {
            usuario: usuario,
            campos: listaCampos,
            filtro: filtro
        };

        await this.relatorioProcessoService.exportarRelatorio(relatorioProcesso)
            .then(() => {
                this.toast.show({
                    severity: 'success',
                    summary: 'Relatório de processo',
                    detail: 'Processo gerado com sucesso',
                    life: 4000
                });
                this.consultaRelatoriosUsuario();
            }).catch(error => {
                this.setState(error.response.data)
                this.toast.show({
                    severity: 'error',
                    summary: 'Relatório de processo',
                    detail: `${this.state.detail}`,
                    life: 4000
                });
            });
    }

    acoes(rowData) {
        return (
            <div className="row gap-1">
                <Button icon="pi pi-cloud-download" className="p-button-rounded p-button-text"
                        tooltip="Download"
                        onClick={this.download}
                        tooltipOptions={{position: 'top'}}/>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-text"
                        tooltip="Excluir relatório"
                        onClick={() => this.confirmaExclusaoRelatorio(rowData)}
                        tooltipOptions={{position: 'top'}}/>
            </div>
        );
    }

    accepExcluirRelatorio() {
        this.excluirRelatorio();
    }

    confirmaExclusaoRelatorio(relatorio) {
        this.setState({codigoExcluirRelatorio: relatorio.codigo});
        confirmDialog({
            message: 'Deseja realmente excluir este relatório ?',
            header: 'Exclusão de relatório',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: this.accepExcluirRelatorio,
            reject: this.reject
        });
    }

    excluirRelatorio = () => {


        this.relatorioProcessoService.excluirRelatorio(this.state.codigoExcluirRelatorio, this.state.usuario.codigo)
            .then(() => {
                this.toast.show({
                    severity: 'success',
                    summary: 'Relatório de processo',
                    detail: 'Relatório excluído',
                    life: 4000
                });
                this.consultaRelatoriosUsuario();
            }).catch(error => {
            this.setState(error.response.data)
            this.toast.show({
                severity: 'error',
                summary: 'Relatório de processo',
                detail: `${this.state.detail}`,
                life: 4000
            });
        })
    }


    onCumprimentoLiminarChange = (e) => {
        const {value} = e.target
        this.setState({cumprimentoLiminar: value})
    }

    onStatusLiminarChange = (e) => {
        const {value} = e.target
        this.setState({statusLiminar: value})
    }


    getCumprimentoOptions = () => {
        this.liminarService.getAllCumprimento()
            .then(res => {
                this.setState({cumprimentoLiminarOptions: res.data})
            })
    }

    getStatusLiminarOptions = () => {
        this.liminarService.getAllStatus()
            .then(res => {
                this.setState({statusLiminarOptions: res.data})
            })
    }

    render() {
        return (
            <>
                <NavBar/>
                <Toast ref={(el) => this.toast = el}/>
                <div className="content-wrapper">
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card card-primary card-outline">
                                        <div className="card-header">
                                            <h3 className="card-title">
                                                <i className="pi pi-file-pdf"></i>
                                                <span> Relatório de processos</span>
                                            </h3>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-8">
                                                    <div className="form-group">
                                                        <label>Pessoa(s) (Autores, parte contrária, advogados outra
                                                            parte)</label>
                                                        <AutoComplete value={this.state.selectedPessoa}
                                                                      suggestions={this.state.filterPessoas}
                                                                      completeMethod={this.consultarPessoa}
                                                                      style={{width: '100%'}}
                                                                      field="nome" multiple
                                                                      id={"ac-rp-pessoa"}
                                                                      onChange={(e) => this.setState({selectedPessoa: e.value})}
                                                                      className="p-autoComplete-sm p-d-block p-mb-0"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Grupo de trabalho</label>
                                                        <MultiSelect inputId="multiselect"
                                                                     value={this.state.selectedGrupoTrabalho}
                                                                     options={this.state.gruposTrabalho}
                                                                     style={{
                                                                         width: '100%',
                                                                         height: '37px',
                                                                         padding_top: '1px'
                                                                     }}
                                                                     panelStyle={{
                                                                         maxWidth: '200px', // Limita a altura do painel para evitar overflow
                                                                         overflowX: 'auto', // Adiciona barra de rolagem se o conteúdo exceder a altura
                                                                     }}
                                                                     id={"rp-multiselect-grupo-trabalho"}
                                                                     onChange={(e) => this.setState({selectedGrupoTrabalho: e.value})}
                                                                     filter showClear filterBy="nome"
                                                                     optionLabel="nome"/>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Status processual</label>
                                                        <Dropdown inputId="statusProcessualId"
                                                                  value={this.state.selectedStatusProcessual}
                                                                  options={this.state.statusProcessual}
                                                                  style={{
                                                                      width: '100%',
                                                                      height: '37px',
                                                                      padding_top: '1px'
                                                                  }}
                                                                  panelStyle={{
                                                                      maxWidth: '200px', // Limita a altura do painel para evitar overflow
                                                                      overflowX: 'auto', // Adiciona barra de rolagem se o conteúdo exceder a altura
                                                                  }}
                                                                  id={"rp-status-processual"}
                                                                  onChange={(e) => this.setState({selectedStatusProcessual: e.value})}
                                                                  filter showClear filterBy="descricao"
                                                                  optionLabel="descricao"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Tipo de ação</label>
                                                        <Dropdown inputId="tipoAcaoId"
                                                                  value={this.state.selectedTipoAcao}
                                                                  options={this.state.tiposAcao}
                                                                  style={{
                                                                      width: '100%',
                                                                      height: '37px',
                                                                      padding_top: '1px'
                                                                  }}
                                                                  panelStyle={{
                                                                      maxWidth: '200px', // Limita a altura do painel para evitar overflow
                                                                      overflowX: 'auto', // Adiciona barra de rolagem se o conteúdo exceder a altura
                                                                  }}
                                                                  id={"rp-tipo-acao"}
                                                                  onChange={(e) => this.setState({selectedTipoAcao: e.value})}
                                                                  filter showClear filterBy="nome"
                                                                  optionLabel="nome"/>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Tipo de decisão</label>
                                                        <Dropdown inputId="tipoDecisaoId"
                                                                  value={this.state.selectedTipoDecisao}
                                                                  options={this.state.tiposDecisao}
                                                                  style={{
                                                                      width: '100%',
                                                                      height: '37px',
                                                                      padding_top: '1px'
                                                                  }}
                                                                  panelStyle={{
                                                                      maxWidth: '200px', // Limita a altura do painel para evitar overflow
                                                                      overflowX: 'auto', // Adiciona barra de rolagem se o conteúdo exceder a altura
                                                                  }}
                                                                  id={"rp-tipo-decisao"}
                                                                  onChange={(e) => this.setState({selectedTipoDecisao: e.value})}
                                                                  filter showClear filterBy="nome"
                                                                  optionLabel="nome"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Área de atuação</label>
                                                        <Dropdown inputId="areaAtuacaoId"
                                                                  value={this.state.selectedAreaAtuacao}
                                                                  options={this.state.areasAtuacao}
                                                                  id={'rp-area-atuacao'}
                                                                  style={{
                                                                      width: '100%',
                                                                      height: '37px',
                                                                      padding_top: '1px'
                                                                  }}
                                                                  panelStyle={{
                                                                      maxWidth: '200px', // Limita a altura do painel para evitar overflow
                                                                      overflowX: 'auto', // Adiciona barra de rolagem se o conteúdo exceder a altura
                                                                  }}
                                                                  onChange={(e) => this.setState({selectedAreaAtuacao: e.value})}
                                                                  filter showClear filterBy="nome"
                                                                  optionLabel="nome"/>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Objetos de ação</label>
                                                        <MultiSelect inputId="multiselect"
                                                                     value={this.state.selectedObjetoAcao}
                                                                     options={this.state.objetosAcao}
                                                                     id={'rp-multiselect-objeto-acao'}
                                                                     style={{
                                                                         width: '100%',
                                                                         height: '37px',
                                                                         padding_top: '1px'
                                                                     }}
                                                                     panelStyle={{
                                                                         maxWidth: '200px', // Limita a altura do painel para evitar overflow
                                                                         overflowX: 'auto', // Adiciona barra de rolagem se o conteúdo exceder a altura
                                                                     }}
                                                                     onChange={(e) => this.setState({selectedObjetoAcao: e.value})}
                                                                     filter showClear filterBy="nome"
                                                                     optionLabel="nome"/>
                                                    </div>
                                                </div>
                                            </div>

                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label>Cumprimento da liminar
                                                            </label>
                                                            <Dropdown value={this.state.cumprimentoLiminar}
                                                                // placeholder={"Selecione uma opção"}
                                                                      options={this.state.cumprimentoLiminarOptions}
                                                                      style={{width: '100%', height: '37px'}}
                                                                      panelStyle={{
                                                                          maxWidth: '200px', // Limita a altura do painel para evitar overflow
                                                                          overflowX: 'auto', // Adiciona barra de rolagem se o conteúdo exceder a altura
                                                                      }}
                                                                      optionLabel="nome"
                                                                      filter showClear filterBy="nome"
                                                                      onChange={this.onCumprimentoLiminarChange}
                                                                      id="cumprimentoLiminar"
                                                                      className="p-inputtext-sm p-d-block p-mb-1"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label>Status da liminar
                                                            </label>
                                                            <Dropdown value={this.state.statusLiminar}
                                                                      options={this.state.statusLiminarOptions}
                                                                      panelStyle={{
                                                                          maxWidth: '200px', // Limita a altura do painel para evitar overflow
                                                                          overflowX: 'auto', // Adiciona barra de rolagem se o conteúdo exceder a altura
                                                                      }}
                                                                      optionLabel="nome"
                                                                // placeholder={"Selecione um status"}
                                                                      filter showClear filterBy="nome"
                                                                      style={{width: '100%', height: '37px'}}
                                                                      onChange={this.onStatusLiminarChange}
                                                                      id="statusLiminar"
                                                                      className="p-inputtext-sm p-d-block p-mb-1"/>
                                                        </div>
                                                    </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                        <label>Data de distribuição</label>
                                                    <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <Calendar id="mask-dt-distribuicao-inicial"
                                                                  value={this.state.dataDistribuicaoInicial}
                                                                  onChange={(e) => this.setState({dataDistribuicaoInicial: e.value})}
                                                                  style={{width: '100%', height: '37px'}}

                                                                  dateFormat="dd/mm/yy"
                                                                  mask="99/99/9999"/>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">

                                                        <Calendar id="mask-dt-distribuicao-final"
                                                                  value={this.state.dataDistribuicaoFinal}
                                                                  onChange={(e) => this.setState({dataDistribuicaoFinal: e.value})}
                                                                  style={{width: '100%', height: '37px'}}
                                                                  dateFormat="dd/mm/yy"
                                                                  mask="99/99/9999"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                                <div className="col-sm-4">
                                                        <label>Data da última decisão</label>
                                                <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <Calendar id="mask-dt-decisao-inicial"
                                                                  value={this.state.dataDecisaoInicial}
                                                                  onChange={(e) => this.setState({dataDecisaoInicial: e.value})}
                                                                  style={{width: '100%', height: '37px'}}
                                                                  dateFormat="dd/mm/yy"
                                                                  mask="99/99/9999"/>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <Calendar id="mask-dt-decisao-final"
                                                                  value={this.state.dataDecisaoFinal}
                                                                  onChange={(e) => this.setState({dataDecisaoFinal: e.value})}
                                                                  style={{width: '100%', height: '37px'}}
                                                                  dateFormat="dd/mm/yy"
                                                                  mask="99/99/9999"/>
                                                    </div>
                                                </div>
                                                </div>
                                                </div>
                                                </div>
                                            <div className="row">
                                                <div className={"col-sm-4"}>
                                                    <label>Data da última movimentação</label>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <Calendar id="mask-dt-alteracao-inicial"
                                                                          value={this.state.dataAlteracaoInicial}
                                                                          onChange={(e) => this.setState({dataAlteracaoInicial: e.value})}
                                                                          style={{width: '100%', height: '37px'}}
                                                                          dateFormat="dd/mm/yy"
                                                                          mask="99/99/9999"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-group">

                                                                <Calendar id="mask-dt-alteracao-final"
                                                                          value={this.state.dataAlteracaoFinal}
                                                                          onChange={(e) => this.setState({dataAlteracaoFinal: e.value})}
                                                                          style={{width: '100%', height: '37px'}}
                                                                          dateFormat="dd/mm/yy"
                                                                          mask="99/99/9999"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={"col-sm-4"}>
                                                    <label>Data de cadastro</label>
                                                    <div className={"row"}>
                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <Calendar id="mask-dt-cadastro-inicial"
                                                                          value={this.state.dataCadastroInicial}
                                                                          onChange={(e) => this.setState({dataCadastroInicial: e.value})}
                                                                          style={{width: '100%', height: '37px'}}
                                                                          dateFormat="dd/mm/yy"
                                                                          mask="99/99/9999"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <Calendar id="mask-dt-cadastro-final"
                                                                          value={this.state.dataCadastroFinal}
                                                                          onChange={(e) => this.setState({dataCadastroFinal: e.value})}
                                                                          style={{width: '100%', height: '37px'}}
                                                                          dateFormat="dd/mm/yy"
                                                                          mask="99/99/9999"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-sm-8">
                                                    <div className="form-group">
                                                        <label>Pagamentos</label>
                                                        <MultiSelect inputId="pagamentoId"
                                                                     value={this.state.selectedTipoPagamento}
                                                                     options={this.state.tiposPagamento}
                                                                     style={{
                                                                         width: '100%',
                                                                         height: '37px',
                                                                         padding_top: '1px'
                                                                     }}
                                                                     panelStyle={{
                                                                         maxWidth: '200px', // Limita a altura do painel para evitar overflow
                                                                         overflowX: 'auto', // Adiciona barra de rolagem se o conteúdo exceder a altura
                                                                     }}
                                                                     id={'rp-multi-select-pagamento'}
                                                                     onChange={(e) => this.setState({selectedTipoPagamento: e.value})}
                                                                     filter showClear filterBy="nome"
                                                                     optionLabel="nome"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Operador lógico pagamentos</label>
                                                        <Dropdown inputId="opLogicoPagamentoId"
                                                                  value={this.state.operadorLogicoPagamentos}
                                                                  options={this.operadorLogicoOptions}
                                                                  style={{
                                                                      width: '100%',
                                                                      height: '37px',
                                                                      padding_top: '1px'
                                                                  }}
                                                                  id={'rp-op-logico-pagamento'}
                                                                  onChange={(e) => this.setState({operadorLogicoPagamentos: e.value})}
                                                                  filter showClear filterBy="nome"
                                                                  optionLabel="nome"/>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Valor pagamentos</label>
                                                        <InputNumber inputId="valorPagamentoId"
                                                                     id={'rp-valor-pagamento'}
                                                                     value={this.state.valorPagamento}
                                                                     style={{width: '100%', height: '37px'}}
                                                                     onChange={(e) => this.setState({valorPagamento: e.value})}
                                                                     mode="decimal" locale="de-BR"
                                                                     minFractionDigits={2}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-8">
                                                    <div className="form-group">
                                                        <label>Custas</label>
                                                        <MultiSelect inputId="pagamentoId"
                                                                     id={'rp-multiselect-custas'}
                                                                     value={this.state.selectedTipoCusta}
                                                                     options={this.state.tiposCusta}
                                                                     style={{
                                                                         width: '100%',
                                                                         height: '37px',
                                                                         padding_top: '1px'
                                                                     }}
                                                                     panelStyle={{
                                                                         maxWidth: '200px', // Limita a altura do painel para evitar overflow
                                                                         overflowX: 'auto', // Adiciona barra de rolagem se o conteúdo exceder a altura
                                                                     }}
                                                                     onChange={(e) => this.setState({selectedTipoCusta: e.value})}
                                                                     filter showClear filterBy="nome"
                                                                     optionLabel="nome"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Operador lógico custas</label>
                                                        <Dropdown inputId="opLogicoCustasId"
                                                                  id={'rp-op-logico-custas'}
                                                                  value={this.state.operadorLogicoCustas}
                                                                  options={this.operadorLogicoOptions}
                                                                  style={{
                                                                      width: '100%',
                                                                      height: '37px',
                                                                      padding_top: '1px'
                                                                  }}
                                                                  onChange={(e) => this.setState({operadorLogicoCustas: e.value})}
                                                                  filter showClear filterBy="nome"
                                                                  optionLabel="nome"/>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Valor custas</label>
                                                        <InputNumber inputId="valorCustaId"
                                                                     id={'rp-valor-custas'}
                                                                     value={this.state.valorCustas}
                                                                     style={{width: '100%', height: '37px'}}
                                                                     onChange={(e) => this.setState({valorCustas: e.value})}
                                                                     mode="decimal" locale="de-BR"
                                                                     minFractionDigits={2}/>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-8">
                                                    <div className="form-group">
                                                        <label>Garantias</label>
                                                        <MultiSelect inputId="garantias-select-d1"
                                                                     id={'rp-multiselect-garantias'}
                                                                     value={this.state.selectedTipoGarantias}
                                                                     options={this.state.tiposGarantias}
                                                                     style={{
                                                                         width: '100%',
                                                                         height: '37px',
                                                                         padding_top: '1px'
                                                                     }}
                                                                     panelStyle={{
                                                                         maxWidth: '200px', // Limita a altura do painel para evitar overflow
                                                                         overflowX: 'auto', // Adiciona barra de rolagem se o conteúdo exceder a altura
                                                                     }}
                                                                     onChange={(e) => this.setState({selectedTipoGarantias: e.value})}
                                                                     filter showClear filterBy="nome"
                                                                     optionLabel="nome"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Operador lógico garantias</label>
                                                        <Dropdown inputId="opLogicoGarantiasId"
                                                                  id={'rp-op-logico-garantias'}
                                                                  value={this.state.operadorLogicoGarantias}
                                                                  options={this.operadorLogicoOptions}
                                                                  style={{
                                                                      width: '100%',
                                                                      height: '37px',
                                                                      padding_top: '1px'
                                                                  }}
                                                                  onChange={(e) => this.setState({operadorLogicoGarantias: e.value})}
                                                                  filter showClear filterBy="nome"
                                                                  optionLabel="nome"/>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Valor garantias</label>
                                                        <InputNumber inputId="valorGarantiasId"
                                                                     id={'rp-valor-garantias'}
                                                                     value={this.state.valorGarantias}
                                                                     style={{width: '100%', height: '37px'}}
                                                                     onChange={(e) => this.setState({valorGarantias: e.value})}
                                                                     mode="decimal" locale="de-BR"
                                                                     minFractionDigits={2}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <span style={{
                                                padding: '12px',
                                                fontSize: '16px',
                                                fontStyle: 'italic',
                                                fontWeight: 'bold',
                                                // marginLeft: '-10px'
                                            }}>
                        Campos para exibição
                      </span>
                                            <br></br>
                                            <br></br>
                                            <div id='CamposExibicao' className="grid-row">
                                                <div className="col-sm-12">
                                                    <div className="flex-col gap-0">
                                                        <label htmlFor="camposRelatorio flex-1 gap-1">
                                                            <Checkbox className={"mr-1"} inputId="numeroProcesso"
                                                                      name="numeroProcesso"
                                                                      value="numeroProcesso"
                                                                      id={"ckb-numeroProcesso"}
                                                                      onChange={this.onCamposChange}
                                                                      checked={this.state.camposRelatorio.indexOf('numeroProcesso') !== -1}/>
                                                            Número do processo
                                                        </label>
                                                        <label htmlFor="camposRelatorio flex-1 gap-1">
                                                            <Checkbox className={"mr-1"} inputId="numeroCNJ"
                                                                      name="numeroCnj"
                                                                      value="numeroCnj"
                                                                      id={"ckb-numeroCnj"}
                                                                      onChange={this.onCamposChange}
                                                                      checked={this.state.camposRelatorio.indexOf('numeroCnj') !== -1}/>
                                                            Número do CNJ
                                                        </label>
                                                        <label htmlFor="camposRelatorio flex-1 gap-1">
                                                            <Checkbox className={"mr-1"} inputId="pasta"
                                                                      name="pasta"
                                                                      value="pasta"
                                                                      id={"ckb-pasta"}
                                                                      onChange={this.onCamposChange}
                                                                      checked={this.state.camposRelatorio.indexOf('pasta') !== -1}/>
                                                            Número da pasta
                                                        </label>
                                                        <label htmlFor="camposRelatorio flex-1 gap-1">
                                                            <Checkbox className={"mr-1"} inputId="cliente"
                                                                      name="cliente"
                                                                      value="cliente"
                                                                      id={"ckb-pa"}
                                                                      onChange={this.onCamposChange}
                                                                      checked={this.state.camposRelatorio.indexOf('cliente') !== -1}/>
                                                            Polo ativo
                                                        </label>
                                                        <label htmlFor="camposRelatorio flex-1 gap-1">
                                                            <Checkbox className={"mr-1"} inputId="parteContraria"
                                                                      name="parteContraria"
                                                                      value="parteContraria"
                                                                      id={"ckb-pp"}
                                                                      onChange={this.onCamposChange}
                                                                      checked={this.state.camposRelatorio.indexOf('parteContraria') !== -1}/>
                                                            Polo passivo
                                                        </label>
                                                        {/*advogado mudou para outras partes no sistema*/}
                                                        <label htmlFor="camposRelatorio flex-1 gap-1">
                                                            <Checkbox className={"mr-1"} inputId="op"
                                                                      name="op"
                                                                      value="outrasPartes"
                                                                      id={"ckb-outrasPartes"}
                                                                      onChange={this.onCamposChange}
                                                                      checked={this.state.camposRelatorio.indexOf('outrasPartes') !== -1}/>
                                                            Outra(s) parte(s)
                                                        </label>
                                                        <label htmlFor="camposRelatorio flex-1 gap-1">
                                                            <Checkbox className={"mr-1"} inputId="areaAtuacao"
                                                                      name="areaAtuacao"
                                                                      value="areaAtuacao"
                                                                      id={"ckb-areaAtuacao"}
                                                                      onChange={this.onCamposChange}
                                                                      checked={this.state.camposRelatorio.indexOf('areaAtuacao') !== -1}/>
                                                            Área de atuação
                                                        </label>
                                                        <label htmlFor="camposRelatorio flex-1 gap-1">
                                                            <Checkbox className={"mr-1"} inputId="tipoDecisao"
                                                                      name="tipoDecisao"
                                                                      value="tipoDecisao"
                                                                      id={"ckb-seqDecisao"}
                                                                      onChange={this.onCamposChange}
                                                                      checked={this.state.camposRelatorio.indexOf('tipoDecisao') !== -1}/>
                                                            Tipo de decisao
                                                        </label>

                                                        <label htmlFor="camposRelatorio flex-1 gap-1">
                                                            <Checkbox className={"mr-1"} inputId="statusProcessual"
                                                                      name="statusProcessual"
                                                                      value="statusProcessual"
                                                                      id={"ckb-statusProcessual"}
                                                                      onChange={this.onCamposChange}
                                                                      checked={this.state.camposRelatorio.indexOf('statusProcessual') !== -1}/>

                                                            Status processual
                                                        </label>
                                                        <label htmlFor="camposRelatorio flex-1 gap-1">
                                                            <Checkbox className={"mr-1"} inputId="grupoTrabalho"
                                                                      name="grupoTrabalho"
                                                                      value="grupoTrabalho"
                                                                      id={"ckb-grupoTrabalho"}
                                                                      onChange={this.onCamposChange}
                                                                      checked={this.state.camposRelatorio.indexOf('grupoTrabalho') !== -1}/>

                                                            Grupo de trabalho
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="col-sm-12">
                                                    <div className="flex-col gap-0">
                                                        <label htmlFor="camposRelatorio flex-1 gap-1">
                                                            <Checkbox className={"mr-1"} inputId="tipoAcao"
                                                                      name="tipoAcao"
                                                                      value="tipoAcao"
                                                                      id={"ckb-tipoAcao"}
                                                                      onChange={this.onCamposChange}
                                                                      checked={this.state.camposRelatorio.indexOf('tipoAcao') !== -1}/>
                                                            Tipo de ação
                                                        </label>

                                                        <label htmlFor="camposRelatorio flex-1 gap-1">
                                                            <Checkbox className={"mr-1"} inputId="objetoAcao"
                                                                      name="objetoAcao"
                                                                      value="objetoAcao"
                                                                      id={"ckb-objetoAcao"}
                                                                      onChange={this.onCamposChange}
                                                                      checked={this.state.camposRelatorio.indexOf('objetoAcao') !== -1}/>

                                                            Objeto de ação
                                                        </label>
                                                        <label htmlFor="camposRelatorio flex-1 gap-1">
                                                            <Checkbox className={"mr-1"} inputId="garantia"
                                                                      name="garantia"
                                                                      value="garantia"
                                                                      id={"ckb-garantia"}
                                                                      onChange={this.onCamposChange}
                                                                      checked={this.state.camposRelatorio.indexOf('garantia') !== -1}/>

                                                            Garantias
                                                        </label>
                                                        <label htmlFor="camposRelatorio flex-1 gap-1">
                                                            <Checkbox className={"mr-1"} inputId="valorGarantia"
                                                                      name="valorGarantia"
                                                                      value="valorGarantia"
                                                                      id={"ckb-valorGarantia"}
                                                                      onChange={this.onCamposChange}
                                                                      checked={this.state.camposRelatorio.indexOf('valorGarantia') !== -1}/>

                                                            Valor garantias
                                                        </label>
                                                        <label htmlFor="camposRelatorio flex-1 gap-1">
                                                            <Checkbox className={"mr-1"} inputId="pagamento"
                                                                      name="pagamento"
                                                                      value="pagamento"
                                                                      id={"ckb-pagamento"}
                                                                      onChange={this.onCamposChange}
                                                                      checked={this.state.camposRelatorio.indexOf('pagamento') !== -1}/>

                                                            Pagamentos
                                                        </label>
                                                        <label htmlFor="camposRelatorio flex-1 gap-1">
                                                            <Checkbox className={"mr-1"} inputId="valorPagamento"
                                                                      name="valorPagamento"
                                                                      value="valorPagamento"
                                                                      id={"ckb-valorPagamento"}
                                                                      onChange={this.onCamposChange}
                                                                      checked={this.state.camposRelatorio.indexOf('valorPagamento') !== -1}/>
                                                            Valor pagamentos
                                                        </label>
                                                        <label htmlFor="camposRelatorio flex-1 gap-1">
                                                            <Checkbox className={"mr-1"} inputId="custas"
                                                                      name="custas"
                                                                      value="custas"
                                                                      id={"ckb-custas"}
                                                                      onChange={this.onCamposChange}
                                                                      checked={this.state.camposRelatorio.indexOf('custas') !== -1}/>

                                                            Custas
                                                        </label>
                                                        <label htmlFor="camposRelatorio flex-1 gap-1">
                                                            <Checkbox className={"mr-1"} inputId="valorCustas"
                                                                      name="valorCustas"
                                                                      value="valorCustas"
                                                                      id={"ckb-valorCustas"}
                                                                      onChange={this.onCamposChange}
                                                                      checked={this.state.camposRelatorio.indexOf('valorCustas') !== -1}/>

                                                            Valor custas
                                                        </label>
                                                        <label htmlFor="camposRelatorio flex-1 gap-1">
                                                            <Checkbox className={"mr-1"} inputId="liminarCumprimento"
                                                                      name="liminarCumprimento"
                                                                      value="liminarCumprimento"
                                                                      id={"ckb-liminarCumprimento"}
                                                                      onChange={this.onCamposChange}
                                                                      checked={this.state.camposRelatorio.indexOf('liminarCumprimento') !== -1}/>

                                                            Cumprimento da liminar
                                                        </label>
                                                        <label htmlFor="camposRelatorio flex-1 gap-1">
                                                            <Checkbox className={"mr-1"} inputId="liminarStatus"
                                                                      name="liminarStatus"
                                                                      value="liminarStatus"
                                                                      id={"ckb-liminarStatus"}
                                                                      onChange={this.onCamposChange}
                                                                      checked={this.state.camposRelatorio.indexOf('liminarStatus') !== -1}/>

                                                            Status da liminar
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="col-sm-12">
                                                    <div className="flex-col gap-0">
                                                        <label htmlFor="camposRelatorio flex-1 gap-1">
                                                            <Checkbox className={"mr-1"} inputId="dataDecisao"
                                                                      name="dataDecisao"
                                                                      value="dataDecisao"
                                                                      id={"ckb-dataDecisao"}
                                                                      onChange={this.onCamposChange}
                                                                      checked={this.state.camposRelatorio.indexOf('dataDecisao') !== -1}/>

                                                            Data última decisão
                                                        </label>
                                                        <label htmlFor="camposRelatorio flex-1 gap-1">
                                                            <Checkbox className={"mr-1"} inputId="dataDistribuicao"
                                                                      name="dataDistribuicao"
                                                                      value="dataDistribuicao"
                                                                      id={"ckb-dataDistribuicao"}
                                                                      onChange={this.onCamposChange}
                                                                      checked={this.state.camposRelatorio.indexOf('dataDistribuicao') !== -1}/>

                                                            Data de distribuição
                                                        </label>
                                                        {/*//removido por conta de conflito*/}
                                                        {/*<label htmlFor="camposRelatorio flex-1 gap-1">*/}
                                                        {/*    <Checkbox className={"mr-1"} inputId="dataAlteracao"*/}
                                                        {/*              name="dataAlteracao"*/}
                                                        {/*              value="dataAlteracao"*/}
                                                        {/*              id={"ckb-dataAlteracao"}*/}
                                                        {/*              onChange={this.onCamposChange}*/}
                                                        {/*              checked={this.state.camposRelatorio.indexOf('dataAlteracao') !== -1}/>*/}
                                                        {/*    Data última alteração*/}
                                                        {/*</label>*/}
                                                        <label htmlFor="camposRelatorio flex-1 gap-1">
                                                            <Checkbox className={"mr-1"} inputId="dataCadastro"
                                                                      name="dataCadastro"
                                                                      value="dataCadastro"
                                                                      id={"ckb-dataCadastro"}
                                                                      onChange={this.onCamposChange}
                                                                      checked={this.state.camposRelatorio.indexOf('dataCadastro') !== -1}/>

                                                            Data cadastro
                                                        </label>
                                                        <label htmlFor="camposRelatorio flex-1 gap-1">
                                                            <Checkbox className={"mr-1"} inputId="dataSentenca"
                                                                      name="dataSentenca"
                                                                      value="dataSentenca"
                                                                      id={"ckb-dataSentenca"}
                                                                      onChange={this.onCamposChange}
                                                                      checked={this.state.camposRelatorio.indexOf('dataSentenca') !== -1}/>

                                                            Data sentença
                                                        </label>
                                                        <label htmlFor="camposRelatorio flex-1 gap-1">
                                                            <Checkbox className={"mr-1"} inputId="push"
                                                                      name="push"
                                                                      value="push"
                                                                      id={"ckb-push"}
                                                                      onChange={this.onCamposChange}
                                                                      checked={this.state.camposRelatorio.indexOf('push') !== -1}/>
                                                            Processo no push
                                                        </label>
                                                        {/*<label htmlFor="camposRelatorio flex-1 gap-1">*/}
                                                        {/*  <Checkbox className={"mr-1"}  inputId="pushEmail"*/}
                                                        {/*    name="pushEmail"*/}
                                                        {/*    value="pushEmail"*/}
                                                        {/*    onChange={this.onCamposChange}*/}
                                                        {/*    checked={this.state.camposRelatorio.indexOf('pushEmail') !== -1} />*/}

                                                        {/*  Envio por email*/}
                                                        {/*</label>*/}
                                                        {/*<label htmlFor="camposRelatorio flex-1 gap-1">*/}
                                                        {/*  <Checkbox className={"mr-1"}  inputId="pushHistorico"*/}
                                                        {/*    name="pushHistorico"*/}
                                                        {/*    value="pushHistorico"*/}
                                                        {/*    onChange={this.onCamposChange}*/}
                                                        {/*    checked={this.state.camposRelatorio.indexOf('pushHistorico') !== -1} />*/}

                                                        {/*  Registro no histórico*/}
                                                        {/*</label>*/}
                                                        <label htmlFor="camposRelatorio flex-1 gap-1">
                                                            <Checkbox className={"mr-1"} inputId="atividade"
                                                                      name="atividade"
                                                                      value="atividade"
                                                                      id={"ckb-ultima-atividade"}
                                                                      onChange={this.onCamposChange}
                                                                      checked={this.state.camposRelatorio.indexOf('atividade') !== -1}/>
                                                            Última atividade
                                                        </label>
                                                        <label htmlFor="camposRelatorio flex-1 gap-1">
                                                            <Checkbox className={"mr-1"} inputId="dataUltimaAtividade"
                                                                      name="dataUltimaAtividade"
                                                                      value="dataUltimaAtividade"
                                                                      id={"ckb-dataUltimaAtividade"}
                                                                      onChange={this.onCamposChange}
                                                                      checked={this.state.camposRelatorio.indexOf('dataUltimaAtividade') !== -1}/>

                                                            Data última atividade
                                                        </label>
                                                        <label htmlFor="camposRelatorio flex-1 gap-1">
                                                            <Checkbox className={"mr-1"} inputId="ultimoHistorico"
                                                                      name="ultimoHistorico"
                                                                      value="ultimoHistorico"
                                                                      id={"ckb-observacao"}
                                                                      onChange={this.onCamposChange}
                                                                      checked={this.state.camposRelatorio.indexOf('ultimoHistorico') !== -1}/>

                                                            Última movimentação
                                                        </label>
                                                        <label htmlFor="camposRelatorio flex-1 gap-1">
                                                            <Checkbox className={"mr-1"} inputId="dataUltimoHistorico"
                                                                      name="dataUltimoHistorico"
                                                                      value="dataUltimoHistorico"
                                                                      id={"ckb-dataUltimoHistorico"}
                                                                      onChange={this.onCamposChange}
                                                                      checked={this.state.camposRelatorio.indexOf('dataUltimoHistorico') !== -1}/>

                                                            Data Última movimentação
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <br></br>
                                            <div className="row gap-1" style={{paddingLeft: '12px'}}>
                                                <Button id='EmitirRelatorioBtn' label="Emitir relatório"
                                                        onClick={this.emitirRelatorio}
                                                        className={strings.buttonPrimaryStyle}/>

                                                {/*<Button label="Exportar Relatório"*/}
                                                {/*  className={strings.buttonPrimaryStyle}*/}
                                                {/*  onClick={this.exportarRelatorio} />*/}

                                                <Button label="Limpar campos"
                                                        className={strings.buttonSecondaryStyle}
                                                        onClick={this.limparCamposPesquisa}/>

                                            </div>
                                        </div>
                                        <hr></hr>
                                        <br></br>
                                        {/*<div className="row">*/}
                                        {/*  <div className="col-sm-12">*/}
                                        {/*    <DataTable value={this.state.relatoriosExportados} paginator responsiveLayout="scroll"*/}
                                        {/*      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"*/}
                                        {/*      currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}" rows={10} emptyMessage="Nehum relatório gerado"*/}
                                        {/*      selectionMode="multiple">*/}
                                        {/*      <Column field="relatorio" header="Relatório"></Column>*/}
                                        {/*      <Column field="dataRelatorio" header="Data de geração"></Column>*/}
                                        {/*      <Column body={this.acoes} exportable={false} style={{ minWidth: '8rem' }} header="Ações"></Column>*/}
                                        {/*    </DataTable>*/}
                                        {/*  </div>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default withRouter(RelatorioProcessos)

