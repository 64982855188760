import ApiService from '../apiservice'

export default class ArquivoPessoaService extends ApiService {

  constructor() {
    super('/arquivoPessoa');
  }

  //Temporarios
  incluirArquivoTemp(formData) {
    return this.post('/incluirArquivoTemp', formData);
  }

  listarArquivosTemp(usuario) {
    return this.get(`/listarArquivosTemp/${usuario}`);
  }

  adicionarDescricaoTemp(arquivo) {
    return this.put(`/adicionarDescricaoArquivoTemp/${arquivo.codigo}`, arquivo);
  }

  excluirArquivoTemp(codigo) {
    return this.delete(`/excluirArquivoTemp/${codigo}`);
  }

  excluirArquivosTemp(usuario) {
    return this.delete(`/excluirArquivosTemp/${usuario}`);
  }

  downloadTemp(codigo) {
    return this.get2(`/downloadTemp/${codigo}`);
  }

  consultarArquivoTemp(codigo) {
    return this.get(`/consultarArquivoTemp/${codigo}`);
  }

  //Definitivos
  incluirArquivo(formData) {
    return this.post('/incluirArquivo', formData);
  }
  cadastrarArquivos(pessoaCodigo, arquivos) {
    return this.post(`/cadastrarArquivos/${pessoaCodigo}`, arquivos);
  }

  listarArquivos(pessoa) {
    return this.get(`/listarArquivos/${pessoa}`);
  }

  consultarArquivo(codigo) {
    return this.get(`/consultarArquivo/${codigo}`);
  }

  adicionarDescricao(arquivo) {
    return this.put(`/adicionarDescricao/${arquivo.codigo}`, arquivo);
  }

  excluirArquivo(codigo) {
    return this.delete(`/excluirArquivo/${codigo}`);
  }

  download(codigo) {
    return this.get2(`/download/${codigo}`);
  }
}