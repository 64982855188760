import React, {useContext, useEffect, useRef, useState} from "react";
import ParecerService from "../../app/service/ParecerService";
import {AuthContext} from "../../main/ProvedorAutenticacao";
import {Button} from "primereact/button";
import {strings} from "../../utils/strings";
import {formatDateHoraTela} from "../../utils/DateFormat";
import {
    AGUARDANDO_FINALIZACAO,
    FINALIZADO,
    PERFIL_ADM,
    PERFIL_PARECER, PERFIL_PARECER_ADMIN,
    RESPONDIDO,
    statusParecer,
    TEXT_CONCLUIR_PARECER
} from "../../utils/ConstsParecer";
import NavBar from "../../componentes/Navbar";
import {Toast} from "primereact/toast";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import HistoricoParecerService from "../../app/service/HistoricoParecerService";
import {AtribuirResponsavelDialog} from "../../componentes/Parecer/AtribuirResponsavelDialog";
import {EnviarMensagemDialog} from "../../componentes/Parecer/EnviarMensagemDialog";
import {confirmDialog} from "primereact/confirmdialog";
import {Loading} from "../../componentes/Loading";
import ArquivoParecerService from "../../app/service/ArquivoParecerService";
import {reject} from "lodash";
import {AnexarArquivosParecerDialog} from "../../componentes/Parecer/AnexarArquivosParecerDialog";


export const DetalheParecer = (props) => {

    const [arquivos, setArquivos] = useState([])
    const [mensagens, setMensagens] = useState([])
    const [parecer, setParecer] = useState(null)
    const [showDialogAtribuirUsuario, setShowDialogAtribuirUsuario] = useState(false)
    const [showDialogResponderParecer, setShowDialogshowDialogResponderParecer] = useState(false)
    const [showDialogDevolverParecer, setShowDialogshowDialogDevolverParecer] = useState(false)
    const [showDialogConcluir, setShowDialogConcluir] = useState(false)
    const [showDialogAnexarArquivo, setShowDialogAnexarArquivo] = useState(false)

    const toast = useRef(null)

    const {usuarioAutenticado, setLoading} = useContext(AuthContext)

    const parecerService = new ParecerService()
    const historicoParecerService = new HistoricoParecerService()
    const arquivosParecerService = new ArquivoParecerService()

    function handleOpenDialogAtribuirResponsavel() {
        setShowDialogAtribuirUsuario(true)
    }
    function handlecloseDialogAtribuirResponsavel() {
        setShowDialogAtribuirUsuario(false)
    }
    function handleOpenDialogResponerParecer() {
        setShowDialogshowDialogResponderParecer(true)
    }
    function handleCloseDialogResponerParecer() {
        setShowDialogshowDialogResponderParecer(false)
    }
    function handleSubmitMessageResposta(message) {
        handleCloseDialogResponerParecer()
        const newParecer = {...parecer}
        newParecer.dsParecer = message
        parecerService.responderParecer(newParecer).then((res)=>{
            toast.current.show({severity: 'success', summary: 'Enviar Mensagem', detail: res.data, life: 4000});
            refreshData()
        })
            .catch((error)=>{
                let detail = error.response.data.detail
                toast.current.show({severity: 'error', summary: 'Enviar Mensagem', detail: detail, life: 4000});

            })

    }

    function handleOpenDialogDevolverParecer() {
        setShowDialogshowDialogDevolverParecer(true)
    }
    function handleCloseDialogDevolverParecer() {
        setShowDialogshowDialogDevolverParecer(false)
    }

    function handleSubmitMessageDevolucao(message) {
        handleOpenDialogDevolverParecer()
        const newParecer = {...parecer}
        newParecer.descricao = message
        parecerService.devolverParecer(newParecer).then((res)=>{
            toast.current.show({severity: 'success', summary: 'Enviar Mensagem', detail: res.data, life: 4000});
            refreshData()
            handleCloseDialogDevolverParecer()
        })
            .catch((error)=>{
                let detail = error.response.data.detail
                toast.current.show({severity: 'error', summary: 'Enviar Mensagem', detail: detail, life: 4000});

            })

    }

    function handleOpenDialogConcluir() {
        setShowDialogConcluir(true)
    }
    function handleCloseDialogConcluir() {
        setShowDialogConcluir(false)
    }

    function handleSubmitConcluirParecer(message) {
        handleCloseDialogConcluir()
        let newMessage = message || null
        parecerService.concluirParecer(parecer.codigo, newMessage)
            .then((res)=>{
            toast.current.show({severity: 'success', summary: 'Enviar Mensagem', detail: res.data, life: 4000});
            refreshData()
        })
            .catch((error)=>{
                let detail = error.response.data.detail
                toast.current.show({severity: 'error', summary: 'Enviar Mensagem', detail: detail, life: 4000});

            })

    }

    function handleOpenDialogAnexarArquivo() {
        setShowDialogAnexarArquivo(true)
    }
    function handleCloseDialogAnexarArquivo() {
        getArquivosParecer()
        setShowDialogAnexarArquivo(false)
    }

    //confirme dialog finalizar parecer
    const handleConfirmDialog = () => {
        confirmDialog({
            message: 'Deseja finalizar o parecer?',
            header: 'Parecer',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-success',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: confirmfinalizarParecer,
        });
    };

    const confirmfinalizarParecer = ()=>{
        parecerService.finalizarParecer(parecer.codigo)
            .then((res)=>{
                toast.current.show({severity: 'success', summary: 'Parecer', detail: res.data, life: 4000});
                refreshData()
            })
            .catch((error)=>{
                let detail = error.response.data.detail
                toast.current.show({severity: 'error', summary: 'Parecer', detail: detail, life: 4000});

            })
    }


    function getParecer() {
        setLoading(true)
        const params = props.match.params;
        const codigo = params.parecer
        parecerService.consultarParecerPorCodigo(codigo).then((res) => {
            setParecer(res.data)
        }).catch((error)=>{
            let detail = error.response.data.detail
            toast.current.show({severity: 'error', summary: 'Parecer', detail: detail, life: 4000});

        }) .finally(()=>{setLoading(false)})
    }
    function getHistoricoParecer() {
        const params = props.match.params;
        const codigo = params.parecer
        historicoParecerService.consultarTodosHistorico(codigo).then((res) => {
            setMensagens(res.data)
        }) .catch((error)=>{
            let detail = error.response.data.detail
            toast.current.show({severity: 'error', summary: 'Parecer', detail: detail, life: 4000});

        })
    }
    function getArquivosParecer(){
        const params = props.match.params;
        const codigo = params.parecer
        arquivosParecerService.listarArquivos(codigo)
            .then((res)=>{
                setArquivos(res.data)
            })
            .catch(()=>{

            }
            )
    }

    function refreshData(){
        getParecer()
        getHistoricoParecer()
    }

    // async function consultarArquivoDescricao(arquivo) {
    //     // setArquivo(arquivo)
    //     // setDsArquivo(arquivo.dsArquivo)
    //     // setModalDescricaoArquivo(true)
    // }

    async function download(rowData) {
        setLoading(true)
        await arquivosParecerService.download(rowData.codigo)
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', rowData.arquivo); //or any other extension
                document.body.appendChild(link);
                link.click();
            }).finally(()=>{setLoading(false)})
    }

    function  confirmaExclusaoArquivo(arquivo) {

        confirmDialog({
            message: 'Deseja realmente excluir este arquivo?',
            header: 'Exclusão de arquivos',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: ()=>excluirArquivo(arquivo.codigo),
            reject: reject
        });
    }

    const excluirArquivo = (codigo) => {
        setLoading(true)
        arquivosParecerService.excluirArquivo(codigo)
            .then(async () => {
                toast.current.show({ severity: 'success', summary: 'Arquivos', detail: 'Arquivo excluído com sucesso', life: 4000 });
                await getArquivosParecer();
            }).catch(error => {
            toast.current.show({ severity: 'error', summary: 'Arquivos', detail: `${error.response.data.detail}`, life: 4000 });
        }).finally(()=>{setLoading(false)})
    }

    useEffect(() => {
        getParecer()
        getHistoricoParecer()
        getArquivosParecer()
    }, [])


    function ButtonsFooter() {
        if(parecer && parecer.status === FINALIZADO){
            return <></>
        }
        return (
            <div className="flex-row justify-content-start gap-4 my-4 px-2">
                {buttonDevolver()}
                {buttonAtribuir()}
                {buttonResponder()}
                {buttonConcluir()}
                {buttonFinalizar()}
                <Button label="Anexar"
                        id={"btn-anexar"}
                        icon="pi pi-paperclip"
                        onClick={handleOpenDialogAnexarArquivo}
                        className={strings.buttonHelp} />
            </div>
        );
    }

    const buttonDevolver = () => {
        if (parecer && parecer.usuarioCriou.codigo === usuarioAutenticado.codigo
            && usuarioAutenticado.perfil.codigo === PERFIL_PARECER
            && (parecer.status === RESPONDIDO || parecer.status === AGUARDANDO_FINALIZACAO
            )) {
            return <Button label="Enviar mensagem"
                           id={"btn-enviar-mensagem"}
                           className={strings.buttonPrimaryStyle}
                           onClick={handleOpenDialogDevolverParecer}/>
        }
    }
    const buttonResponder = () => {
        if (parecer && parecer.usuarioResponsavel
            && usuarioAutenticado.codigo === parecer.usuarioResponsavel.codigo
            && parecer.status !== RESPONDIDO && parecer.status !== AGUARDANDO_FINALIZACAO) {
            return <Button label="Enviar mensagem"
                           id={"btn-enviar-mensagem"}
                           className={strings.buttonPrimaryStyle}
                           onClick={handleOpenDialogResponerParecer}/>
        }
    }
    const buttonAtribuir = () => {
        if (usuarioAutenticado.perfil.codigo === PERFIL_ADM || usuarioAutenticado.perfil.codigo === PERFIL_PARECER_ADMIN) {
            return <Button label="Atribuir responsável"
                           id={"btn-atribuir"}
                           className={strings.buttonPrimaryStyle}
                           onClick={handleOpenDialogAtribuirResponsavel}/>
        }
    }
    const buttonConcluir = () => {
        if (parecer && parecer.usuarioResponsavel && usuarioAutenticado.codigo === parecer.usuarioResponsavel.codigo && parecer.status !== AGUARDANDO_FINALIZACAO) {
            return <Button label="Concluir"
                           id={"btn-concluir"}
                           className={strings.buttonPrimaryStyle}
                           onClick={handleOpenDialogConcluir}/>
        }
    }
    const buttonFinalizar = () => {
        if (parecer && usuarioAutenticado.codigo === parecer.usuarioCriou.codigo && (parecer.status === RESPONDIDO || parecer.status === AGUARDANDO_FINALIZACAO) ) {
            return <Button label="Finalizar"
                           id={"btn-finalizar"}
                           className={strings.buttonPrimaryStyle}
                           onClick={handleConfirmDialog}/>
        }
    }
    const bodyData = (data)=>{
        return(
            <div>
                {formatDateHoraTela(data)}
            </div>
        )
    }

    function bodyArquivos(rowData) {
        return (
            <div className="flex-row justify-content-start align-items-center my-2 gap-4">
            <span>
                {rowData.descricao}
            </span>
            <div className="flex-row justify-content-start gap-3">
                {/*<Button icon="pi pi-comment" className="p-button-rounded p-button-text"*/}
                {/*        tooltip="Adicionar descrição ao arquivo"*/}
                {/*        tooltipOptions={{ position: 'top' }}*/}
                {/*        onClick={() => consultarArquivoDescricao(rowData)}*/}
                {/*        style={{ width: '20px', height: '10px' }} />*/}

                <Button icon="pi pi-cloud-download" className="p-button-rounded p-button-text"
                        tooltip="Baixar arquivo"
                        onClick={() => download(rowData)}
                        tooltipOptions={{ position: 'top' }}
                        style={{ width: '20px', height: '10px' }} />
                {/*verificando se o usuario tem permisao para exlcuir o arquivo*/}
               <ButtonExcluirArquivo arquivo={rowData}/>
            </div>
            </div>
        );
    }

    const ButtonExcluirArquivo = ({arquivo})=>{
    if(usuarioAutenticado.codigo === arquivo.usuario.codigo || usuarioAutenticado.perfil.codigo === PERFIL_ADM
        ){
            return  <Button icon="pi pi-trash" className="p-button-rounded p-button-text text-red"
                    tooltip="Excluir arquivo"
                    onClick={() => confirmaExclusaoArquivo(arquivo)}
                    tooltipOptions={{ position: 'top' }}
                    style={{ width: '20px', height: '10px' }} />
        }
    return null
    }


    if(!parecer){
        return (
        <> <NavBar/>
            <Loading open={true}/>
        </>
        )
    }
    const findStatusParecer = parecer ? statusParecer.find(({_, valor}) => (valor === parecer.status)) : null

    return (
        <>
            <NavBar/>
            <Toast ref={toast}/>
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="flex-row">
                            <div className="col-md-12">
                                <div id="DetalheParecer" className="card card-primary card-outline">
                                    <div className="card-header">
                                        <h3 className="card-title">
                                            <i className="fas fa-regular fa-file"></i>
                                            <span> Detalhe Parecer</span>
                                        </h3>
                                    </div>

                                    <div className="flex-1 flex-column">
                                        <div className={"mt-4"}>
                                            <div className="flex-row">
                                                <div className="col-sm-4 mb-3">
                                                    <span className="font-weight-bold">Código Parecer: </span>
                                                    <span>{parecer.codigo}</span>
                                                </div>
                                                <div className="col-sm-4 mb-3">
                                                    <span className="font-weight-bold">Tipo de Parecer: </span>
                                                    <span>{parecer.tipo.nome}</span>
                                                </div>
                                                <div className="col-sm-4 mb-3">
                                                    <span className="font-weight-bold">Tempo SLA: </span>
                                                    <span>{parecer.tipo.tempoSla}</span>
                                                </div>
                                            </div>
                                            <div className="flex-row">
                                                <div className="col-sm-4 mb-3">
                                                    <span className="font-weight-bold">Pessoa: </span>
                                                    <span>{parecer.pessoa.nome}</span>
                                                </div>
                                                <div className="col-sm-4 mb-3">
                                                    <span className="font-weight-bold">Data Parecer: </span>
                                                    <span>{formatDateHoraTela(parecer.dtParecer)}</span>
                                                </div>
                                                <div className="col-sm-4 mb-3">
                                                    <span className="font-weight-bold">Usuário Solicitação: </span>
                                                    <span>{parecer.usuarioCriou.nome}</span>
                                                </div>
                                            </div>
                                            <div className="flex-row">
                                                <div className="col-sm-4 mb-3">
                                                    <span className="font-weight-bold">Status: </span>
                                                    <span>{findStatusParecer.nome}</span>
                                                </div>
                                                <div className="col-sm-4 mb-3">
                                                    <span className="font-weight-bold">Data Recebimento: </span>
                                                    {parecer.dtRecebimento && <span>{formatDateHoraTela(parecer.dtRecebimento)}</span>}
                                                </div>
                                                <div className="col-sm-4 mb-3">
                                                    <span className="font-weight-bold">Usuário Responsável: </span>
                                                    {parecer.usuarioResponsavel && <span>{parecer.usuarioResponsavel.nome}</span>}
                                                </div>
                                            </div>
                                            <div className="flex-column">

                                                    {arquivos.length > 0
                                                        &&
                                                        <div className="col-sm-6 mb-3">
                                                            <span className="font-weight-bold mb-2">Arquivos</span>
                                                            {arquivos.map(bodyArquivos)}
                                                        {/*<DataTable value={arquivos}*/}
                                                        {/*           // paginator responsiveLayout="scroll" size="small"*/}
                                                        {/*           //  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"*/}
                                                        {/*           //  currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}" rows={10}*/}
                                                        {/*            emptyMessage="Nenhum arquivo">*/}
                                                        {/*    <Column body={bodyArquivos} exportable={false}*/}
                                                        {/*            style={{minWidth: '8rem'}}*/}
                                                        {/*            headerStyle={{background: 'none'}}*/}
                                                        {/*            header="Arquivos"/>*/}
                                                        {/*</DataTable>*/}
                                                        </div>
                                                        }

                                            </div>
                                        </div>

                                        <div className="flex-column px-2">
                                            <span className={"font-weight-bold mb-2 "}>Mensagens</span>
                                            <DataTable value={mensagens} paginator responsiveLayout="scroll"
                                                       paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                                       currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}"
                                                       rows={5} emptyMessage=" "
                                                      >
                                                <Column field="dshistorico" header="Mensagem" style={{maxWidth: "436px"}}></Column>
                                                <Column body={parecer=>bodyData(parecer.dtHistorico)} header="Data"></Column>
                                                <Column field="usuario.nome" header="Usuario"></Column>
                                            </DataTable>
                                        </div>

                                        <ButtonsFooter/>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {showDialogAtribuirUsuario && <AtribuirResponsavelDialog
                parecer={parecer}
                open={showDialogAtribuirUsuario}
                close={handlecloseDialogAtribuirResponsavel}
                toast={toast}
                refresh={refreshData}
            />}
            {showDialogResponderParecer &&
                <EnviarMensagemDialog
                    open={showDialogResponderParecer}
                    close={handleCloseDialogResponerParecer}
                    submit={handleSubmitMessageResposta}
                    toast={toast}

                    />

            }
            {showDialogDevolverParecer &&
                <EnviarMensagemDialog
                    open={showDialogDevolverParecer}
                    close={handleCloseDialogDevolverParecer}
                    submit={handleSubmitMessageDevolucao}
                    toast={toast}

                    />

            }
            {showDialogConcluir &&
                <EnviarMensagemDialog
                    open={showDialogConcluir}
                    close={handleCloseDialogConcluir}
                    submit={handleSubmitConcluirParecer}
                    toast={toast}
                    title={"Concluir Parecer"}
                    text={TEXT_CONCLUIR_PARECER}

                    />

            } {showDialogAnexarArquivo &&
                <AnexarArquivosParecerDialog
                    open={showDialogAnexarArquivo}
                    onClose={handleCloseDialogAnexarArquivo}
                    toast={toast.current}
                    parecer={parecer}
                    />

            }
        </>
    )


}
