import axios from 'axios'

const baseUrl = process.env.REACT_APP_BASE_URL;
const key = process.env.REACT_APP_KEY_LOG_EMPRESA_CADASTRO;
const param_key = process.env.REACT_APP_PARAM_KEY;

axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
// const api = axios.create({
//     baseURL: baseUrl,
//     withCredentials: false
// })


export default class LogEmpresaCadastroService{
    constructor() {
        this.url = baseUrl + "/api/log/cadastro/empresa";
        this.api = axios.create({
            baseURL: this.url,
            withCredentials: false
        })
    }

    registrarLog(log) {
        this.api.defaults.headers.common[`${param_key}`] = `${key}`
        return this.api.post("", log)
    }
}