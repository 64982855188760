import React from 'react'

import {withRouter} from 'react-router-dom'

import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button'
import {ConfirmDialog, confirmDialog} from 'primereact/confirmdialog'
import {Dialog} from 'primereact/dialog'
import {Dropdown} from 'primereact/dropdown'
import {InputText} from 'primereact/inputtext'
import {InputTextarea} from 'primereact/inputtextarea'
import {DataView} from 'primereact/dataview';
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'

import NavBar from '../../componentes/Navbar'

import AtendimentoService from '../../app/service/AtendimentoService'
import GrupoTrabalhoService from '../../app/service/GrupoTrabalhoService'
import ProcessoService from '../../app/service/ProcessoService'
import {AuthContext} from '../../main/ProvedorAutenticacao'

import './atendimento.css'
import {strings} from "../../utils/strings";
import ReactToPrint from "react-to-print";
import {ComponentToPrintHistoricoAtendimento} from "./ComponentToPrintHistoricoAtendimento";
import {Loading} from "../../componentes/Loading";

class HistoricoAtendimento extends React.Component {
  constructor(props) {
    super(props);

    this.atendimentoService = new AtendimentoService();
    this.grupoTrabalhoService = new GrupoTrabalhoService();
    this.processoService = new ProcessoService();

    this.template = this.template.bind(this);
    this.accept = this.accept.bind(this);
    this.onGrupoTrabalhoChange = this.onGrupoTrabalhoChange.bind(this);
    this.acoesProcesso = this.acoesProcesso.bind(this);

    this.state = {
      atendimento: null,
      historico: '',
      pessoa: '',
      assunto: '',
      grupos: [],
      grupo: '',
      selectProcesso: '',
      processoConsulta: '',
      processo: null,
      processos: [],
      selectGrupo: null,
      dtAtendimento: '',
      dtHistorico: '',
      historicos: [],
      detail: '',
      layout: 'list',
      modalNovoHistorico: false,
      modalProcesso: false,
      visible: false,
      exibiGrupo: 'none',
      exibiProcesso: 'none',
      labelLinkProcesso: '',
      moduloRetorno: '',
      pessoaRetonro: '',
      loading: true
    }
  }

  consultarAtendimento = (atendimento) => {
    this.setState({loading: true})
    this.atendimentoService.consultarAtendimento(atendimento)
      .then(response => {
        this.setState({atendimento: response.data});
        this.setState({pessoa: response.data.pessoa.nome});
        this.setState({assunto: response.data.assunto});
        this.setState({grupo: response.data.grupo.nome});
        if(response.data.processo != null) {
          this.setState({selectProcesso: response.data.processo.nrCnj});
          this.setState({labelLinkProcesso: 'Alterar processo'});
        } else {
          this.setState({selectProcesso: 'Nenhum processo'});
          this.setState({labelLinkProcesso: 'Adicionar processo'});
        }
        this.setState({dtAtendimento: response.data.dtAtendimento});
      }).catch(error => {
        this.setState(error.response.data);
        this.toast.show({severity:'error', summary: 'Atendimentos', detail:`${this.state.detail}`, life: 4000});
      })
    this.setState({loading: false})

  }

  consultarHistorico = (atendimento) => {
    this.setState({loading: true})
    this.atendimentoService.consultarHistoricos(atendimento)
      .then(response => {
        this.setState({historicos: response.data});
      }).catch(error => {
        this.setState(error.response.data);
        this.toast.show({severity:'error', summary: 'Atendimentos', detail:`${this.state.detail}`, life: 4000});
      })
    this.setState({loading: false})

  }

  listarGruposTrabalho = () => {
    const usuarioLogado = this.context.usuarioAutenticado
    this.setState({loading: true})


    this.grupoTrabalhoService.listarGruposUsuario(usuarioLogado.codigo)
      .then(response => {
        this.setState({grupos: response.data});
      }).catch(error => {
        this.setState(error.response.data);
        this.toast.show({severity:'error', summary: 'Atendimentos', detail:`${this.state.detail}`, life: 4000});
      })
    this.setState({loading: false})

  }

  async onGrupoTrabalhoChange(e) {
    await this.setState({selectGrupo: e.value});
    this.alterarAtendimentoGrupo();
  }

  exibirGrupo = () => {
    this.setState({loading: true})
    if(this.state.exibiGrupo === 'none') {
      this.setState({exibiGrupo: ''})
      this.listarGruposTrabalho();
    } else {
      this.setState({exibiGrupo: 'none'})
    }
    this.setState({loading: true})
  }

  exibirProcesso = () => {
    if(this.state.exibiProcesso === 'none') {
      this.setState({exibiProcesso: ''})
    } else {
      this.setState({exibiProcesso: 'none'})
    }
  }

  alterarAtendimentoGrupo = () => {
    this.setState({loading: true})

    this.atendimentoService.alterarAtendimentoGrupo({
      codigo: this.state.atendimento.codigo,
      grupo: this.state.selectGrupo,
    }).then(response => {
      this.toast.show({severity:'success', summary: 'Atendimentos', detail:'Grupo alterado!', life: 4000});
      this.consultarAtendimento(this.state.atendimento.codigo);
      this.setState({exibiGrupo: 'none'});
    }).catch(error => {
      this.setState(error.response.data);
      this.toast.show({severity:'error', summary: 'Atendimentos', detail:`${this.state.detail}`, life: 4000});
    })
    this.setState({loading: false})

  }

  alterarAtendimentoProcesso = () => {
    this.setState({loading: true})
    this.atendimentoService.alterarAtendimentoProcesso({
      codigo: this.state.atendimento.codigo,
      processo: this.state.processo,
    }).then(response => {
      this.toast.show({severity:'success', summary: 'Atendimentos', detail:'Processo alterado!', life: 4000});
      this.consultarAtendimento(this.state.atendimento.codigo);
    }).catch(error => {
      this.setState(error.response.data);
      this.toast.show({severity:'error', summary: 'Atendimentos', detail:`${this.state.detail}`, life: 4000});
    })
    this.setState({loading: false})
  }

  cadastrarNovoHistorico = () => {
    const usuarioLogado = this.context.usuarioAutenticado

    if(this.state.historico === '') {
      this.toast.show({severity:'error', summary: 'Atendimentos', detail:'Informe o histórico', life: 4000});
      return false;
    }
    this.setState({loading: true})
    this.atendimentoService.cadastrarHistorico({
      atendimento: this.state.atendimento,
      historico: this.state.historico,
      usuario: usuarioLogado
    }).then(response => {
      this.toast.show({severity:'success', summary: 'Atendimentos', detail:'Histórico registrado com sucesso', life: 4000});
      this.consultarHistorico(this.state.atendimento.codigo);
      this.fecharModal('modalNovoHistorico');
    }).catch(error => {
      this.setState(error.response.data);
      this.toast.show({severity:'error', summary: 'Atendimentos', detail:`${this.state.detail}`, life: 4000});
    })
    this.setState({loading: false})
  }

  abrirModal(name, position) {
    let state = {
        [`${name}`]: true
    };

    if (position) {
        state = {
            ...state,
            position
        }
    }
    this.setState({historico: ''});
    this.setState(state);
  }

  fecharModal(name) {
    this.setState({
        [`${name}`]: false
    });
  }

  confirmaExclusao(historico) {
    this.setState({historico: historico});
    confirmDialog({
        message: 'Deseja realmente excluir histórico?',
        header: 'Exclusão de histórico de atendimento',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        accept: this.accept,
        reject: this.reject
    });
  }

  excluirHistorico = () => {
    this.setState({loading: true})
    this.atendimentoService.excluirHistorico(this.state.historico.codigo)
      .then(response => {
        this.toast.show({severity:'success', summary: 'Atendimentos', detail:'Histórico excluído com sucesso', life: 4000});
        this.consultarHistorico(this.state.atendimento.codigo);
      }).catch(error => {
        this.setState(error.response.data);
        this.toast.show({severity:'error', summary: 'Atendimentos', detail:`${this.state.detail}`, life: 4000});
      });
    this.setState({loading: false})

  }

  accept() {
    this.excluirHistorico();
  }

  voltar = () => {
    if(this.state.moduloRetorno === 'atendimento') {
      this.props.history.push('/atendimentos');
    } else {
      this.props.history.push(`/consultarPessoas/${this.state.pessoaRetorno}`);
    }
  }

  consultarProcessoPorIndice = async() => {
    const usuarioLogado = this.context.usuarioAutenticado

    const indice = {
      indice: this.state.processoConsulta,
      empresa: usuarioLogado.empresa.codigo,
      usuario: usuarioLogado.codigo
    }
    this.setState({loading: true})
    await this.processoService.consultarProcessoPorIndice(indice)
      .then(response => {
        this.setState({processos: response.data});
        this.abrirModal('modalProcesso');
      }).catch(error => {
        this.setState(error.response.data)
        this.toast.show({severity:'error', summary: 'Atividades', detail:`${this.state.detail}`, life: 4000});
      });
    this.setState({loading: false})

  }

  acoesProcesso(rowData) {
    return(
      <React.Fragment>
        <Button icon="pi pi-check" className="p-button-rounded p-button-text"
                tooltip="Selecionar"
                onClick={() => this.selecionarProcesso(rowData)}
                tooltipOptions={{position: 'top'}} />
      </React.Fragment>
    );
  }

  async selecionarProcesso(processo) {
    this.setState({loading: true})
    await this.processoService.consultarProcessoPorCodigo(processo.codigo)
      .then(response => {
        this.setState({processo: response.data});
        this.setState({selectProcesso: response.data.nrCnj});
        this.alterarAtendimentoProcesso();
        this.setState({exibiProcesso: 'none'});
        this.setState({processoConsulta: ''});
        this.fecharModal('modalProcesso');
      }).catch(error => {
        this.setState(error.response.data)
        this.toast.show({severity:'error', summary: 'Atendimentos', detail:`${this.state.detail}`, life: 4000});
      })
    this.setState({loading: false})
  }

  componentDidMount() {
    this.setState({loading: true})
    const params = this.props.match.params;
    this.setState({moduloRetorno: params.modulo});
    this.setState({pessoaRetorno: params.pessoa});
    if(params.codigo) {
      this.consultarAtendimento(params.codigo);
      this.consultarHistorico(params.codigo)
    }
    this.setState({loading: false})
  }

  template(data) {
    return (
        <div>
          <div className="row">
            <div className="col-sm-8">
              <div className="form-group">
                <InputTextarea id="registro"
                               type="text"
                               style={{width: '100%'}}
                               value={data.historico}
                               disabled="true"
                               className="p-inputtext-sm p-d-block p-mb-1"
                               rows={4} cols={30}/>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="flex-col">
                <span style={{color: '#4F88FF', fontStyle: 'italic'}}>{data.usuario.nome}</span>
                <span style={{color:"#000"}}>{data.dtAtendimento}</span>
                <div className="mt-1">
                   <Button onClick={() => this.confirmaExclusao(data)} title="Excluir registro"
                           className={strings.buttonDangerSm}>Excluir</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }

  render() {
    return(
      <>
        <Loading open={this.state.loading}/>
        <NavBar/>
        <Toast ref={(el) => this.toast = el} />
        <div className="content-wrapper">
          <div className="container-fluid">
            <section className="content-header">
              <div className="card card-primary card-outline">
                <div className="card-header gap-1">
                  <h3 className="card-title spaced" style={{marginTop: ".5rem"}}>
                    <i className="pi pi-comments"></i>
                    <span> Histórico do atendimento</span>
                  </h3>
                  <div style={{textAlign: 'right'}} className="flex-1 gap-1">
                  <Button label="Novo histórico"
                            onClick={() => this.abrirModal('modalNovoHistorico')}
                            data-toggle="modal"
                            data-target="#modal-default"
                            className={strings.buttonPrimarySm} />
                    <Button label="Voltar"
                              onClick={this.voltar}
                              className={strings.buttonSecondarySm} />
                    <ReactToPrint
                        trigger={() => <Button icon="pi pi-print"
                                               title="Imprimir histórico da pessoa"
                                               className={strings.buttonInfoSm} />}
                        content={() => this.componentRef}>
                    </ReactToPrint>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <div>
                          <span style={{fontSize: '20px', fontWeight: 'bold'}}>{this.state.assunto}</span>
                          <p>
                            <span style={{color: '#000', fontWeight: 'bold'}}>{this.state.pessoa}</span>
                            <br></br>
                            <br></br>
                            <div className="group-row mb-0" id={"processo"} >
                            <span style={{color: '#000', fontWeight: 'bold'}}>{this.state.selectProcesso}</span>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a  style={{color: '#4F88FF', cursor: 'pointer'}} onClick={this.exibirProcesso} >&nbsp;{this.state.labelLinkProcesso}</a>
                            &nbsp;
                            &nbsp;

                            <div className="group-row mb-0"
                                 style={{display: `${this.state.exibiProcesso}`}}
                            >
                            <Button icon="pi pi-undo"
                                  onClick={this.exibirProcesso}
                                    style={{height: '37px'}}
                                  className={strings.buttonSecondarySm} />
                              &nbsp;
                              &nbsp;
                            <InputText value={this.state.processoConsulta} style={{width: '25%', height: '37px'}}
                                       onKeyPress={event => event.key === 'Enter' && this.consultarProcessoPorIndice()}
                                       onChange={e => this.setState({processoConsulta: e.target.value})}
                                       id="processo"
                                       placeholder={"Pesquise um processo"}
                                       className="p-inputtext-sm p-d-block p-mb-1"/>
                            <span className="input-group-prepend pointer">
                                            <span onClick={this.consultarProcessoPorIndice} className="input-group-text">
                                                <i className="fas fa-search"></i>
                                            </span>
                                        </span>
                              </div>
                            </div>
                            <span style={{color: '#000', fontWeight: 'bold'}}>{this.state.grupo}</span>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                             <a  style={{color: '#4F88FF', cursor: 'pointer'}} onClick={this.exibirGrupo} >&nbsp;Aterar grupo de assunto</a>
                             &nbsp;
                             &nbsp;
                             <Button icon="pi pi-undo"
                                    style={{display: `${this.state.exibiGrupo}`, height: '37px'}}
                                    onClick={this.exibirGrupo}
                                     className={strings.buttonSecondarySm}  />
                              &nbsp;
                              &nbsp;
                              <Dropdown value={this.state.selectGrupo}
                                        options={this.state.grupos} style={{width: '21%', height: '37px', display: `${this.state.exibiGrupo}`}}
                                        optionLabel="nome"
                                        filter showClear filterBy="nome"
                                        onChange={this.onGrupoTrabalhoChange}
                                        id="grupoTrabalho"
                                        className="p-inputtext-sm p-d-block p-mb-1"/>

                            <br></br>
                            <text style={{color: "black"}}>Início:&nbsp;
                              {this.state.dtAtendimento}</text>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dataview-demo">
                    <DataView value={this.state.historicos}
                              layout={this.state.layout}
                              emptyMessage=" "
                              itemTemplate={this.template}/>
                  </div>
                </div>

              </div>
              <ConfirmDialog visible={this.state.visible}
                             onHide={() => this.setState({ visible: false })}
                             message="Are you sure you want to proceed?"
                             header="Confirmation"
                             icon="pi pi-exclamation-triangle"
                             accept={() => this.excluirHistorico}
                             reject={() => this.setState({visible: false})}/>
              {/**Incluir novo histórico */}
              <Dialog header="Novo registro de atendimento"
                      style={{ width: '50vw' }}
                      visible={this.state.modalNovoHistorico}
                      footer={
                        <div>
                          <Button label="Cadastrar"
                                  className={strings.buttonPrimaryStyle}
                                  onClick={this.cadastrarNovoHistorico}/>
                          &nbsp;
                          <Button label="Cancelar"
                                  className={strings.buttonSecondaryStyle}
                                  onClick={() => this.fecharModal('modalNovoHistorico')}/>
                        </div>
                      }
                      onHide={() => this.fecharModal('modalNovoHistorico')}>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="formGroup">
                      <label>Histórico</label>
                      <InputTextarea id="historico"
                                     type="text"
                                     style={{width: '100%'}}
                                     value={this.state.historico}
                                     onChange={(e) => this.setState({historico: e.target.value})}
                                     className="p-inputtext-sm p-d-block p-mb-1"
                                     rows={6} cols={30}/>
                    </div>
                  </div>
                </div>
              </Dialog>
              {/**Pesquisa de Processos */}
              <Dialog header="Busca de processo"
                      visible={this.state.modalProcesso}
                      style={{ width: '60vw' }}
                      footer={
                        <Button label="Cancelar"
                                className={strings.buttonSecondaryStyle}
                                onClick={() => this.fecharModal('modalProcesso')}/>
                      }
                      onHide={() => this.fecharModal('modalProcesso')}>

                  <DataTable value={this.state.processos}  alwaysShowPaginator responsiveLayout="scroll"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}" rows={4} emptyMessage=" "
                            paginatorRight selectionMode="multiple">
                    <Column field="pasta" header="Pasta"></Column>
                    <Column field="nrProcesso" header="Processo original"></Column>
                    <Column field="nrCnj" header="Numeração única"></Column>
                    <Column field="autor" header="Autor x Réu" style={{width: '300px'}}></Column>
                    <Column field="grupoTrabalho.nome" header="Grupo trab."></Column>
                    <Column body={this.acoesProcesso} exportable={false} style={{ minWidth: '8rem' }} header="Ações"></Column>
                  </DataTable>
              </Dialog>
            </section>
          </div>
        </div>
        <div hidden>
          <ComponentToPrintHistoricoAtendimento
            historicos={this.state.historicos}
            selectProcesso={this.state.selectProcesso}
            grupo={this.state.grupo}
            assunto={this.state.assunto}
            pessoa={this.state.pessoa}
            dataAtendimento={this.state.dtAtendimento}
            template={this.template}
            ref={el => (this.componentRef = el)}
          />
        </div>
      </>
    )
  }
}

HistoricoAtendimento.contextType = AuthContext;
export default withRouter(HistoricoAtendimento)
