import ApiService from '../apiservice'

export default class EspecialidadesService extends ApiService {
  constructor() {
    super('/especialidades')
  }

  getAllEspecialidades(){
    return this.get("")
  }
  getEspecialidadeById(codigo) {
    return this.get(`/${codigo}`)
  }
}
