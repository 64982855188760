import React, {useContext, useEffect, useState} from "react";
import {gerarCorAleatoria} from "../../../utils/Cores";
import {AuthContext} from "../../../main/ProvedorAutenticacao";
import imagemRelatorio from '../../../img/etrium-logo1.svg';
import {formatDateHoraImpressao} from "../../../utils/DateFormat";
import {Chart} from "primereact/chart";
import {chartsOptions} from '../../../utils/graficosTheme'

export const ParecerGraficoPrintComponent = React.forwardRef(({
                                                                  graficoProdutividade,
                                                                  graficoEficacia,
                                                                  graficoEficiencia,
                                                                  filtro
                                                              }, ref) => {

    const [graficoEficaciaDateset, setGraficoEficaciaDataset] = useState([])
    const [graficoEficienciaDateset, setGraficEficienciaDataset] = useState([])
    const [graficoProdutividadeDataset, setGraficoProdutividadeDataset] = useState([])

    const usuarioLogado = useContext(AuthContext).usuarioAutenticado;

    function loadChartProdutividade() {
        let responsaveis = graficoProdutividade.map(item => (`${item.responsavel} - (${item.total})`))
        let total = graficoProdutividade.map(item => (item.total))
        let cores = graficoProdutividade.map(() => ((gerarCorAleatoria())))
        setGraficoProdutividadeDataset({
            labels: responsaveis,
            datasets: [
                {
                    data: total,
                    backgroundColor: cores,
                }
            ]
        })
    }

    function loadChartEficacia() {
        let responsaveis = graficoEficacia.map(item => (`${item.responsavel} - (${item.total})`))
        let total = graficoEficacia.map(item => (item.total))
        let cores = graficoEficacia.map(() => ((gerarCorAleatoria())))
        setGraficoEficaciaDataset({
            labels: responsaveis,
            datasets: [
                {
                    data: total,
                    backgroundColor: cores,
                }
            ]
        })
    }

    function loadChartEficiencia() {
        let responsaveis = graficoEficiencia.map(item => (`${item.responsavel} - (${item.total})`))
        let total = graficoEficiencia.map(item => (item.total))
        let cores = graficoEficiencia.map(() => ((gerarCorAleatoria())))
        setGraficEficienciaDataset({
            labels: responsaveis,
            datasets: [
                {
                    data: total,
                    backgroundColor: cores,
                }
            ]
        })
    }

    useEffect(() => {
        loadChartProdutividade()
        loadChartEficacia()
        loadChartEficiencia()
    }, [])


    return (
        <div ref={ref}>
            <div ref={ref}>
                <br></br>
                <div>
                    <div>
                        <img src={imagemRelatorio} width="200" height="140" alt={'Logo do Sistema'}/>
                    </div>
                    <span className="relatorio">Relatório de Pareceres</span>

                    <div className="flex-row gap-0">
                        <text>Impresso por: {usuarioLogado.nome}</text>
                    </div>
                    <div className="flex-row gap-0">
                        <text>{formatDateHoraImpressao()}</text>
                    </div>
                    <div>
                        <span className="filtro">Filtro: {filtro}</span>
                    </div>
                    {/*<div>*/}
                    {/*    Quantidade de Registros:&nbsp;{quantidade}*/}
                    {/*</div>*/}
                    <hr></hr>
                </div>
            </div>
               <div className="flex-row">
                <div className="col-sm-4">
                    <div className="formGroup">
                        <div className="card justify-content-center"
                             style={{alignItems: 'center'}}>
                            <h5>
                                Produtividade
                            </h5>
                            <Chart type="pie"
                                   data={graficoProdutividadeDataset}
                                   options={chartsOptions}/>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="formGroup">
                        <div className="card justify-content-center"
                             style={{alignItems: 'center'}}>
                            <h5>
                                Eficiência
                            </h5>
                            <Chart type="pie"
                                   data={graficoEficienciaDateset}
                                   options={chartsOptions}/>
                        </div>
                    </div>
                </div>
                   <div className="col-sm-4">
                       <div className="formGroup">
                           <div className="card justify-content-center"
                                style={{alignItems: 'center'}}>
                               <h5>
                                   Eficácia
                               </h5>
                               <Chart type="pie"
                                      data={graficoEficaciaDateset}
                                      options={chartsOptions}/>
                           </div>
                       </div>
                   </div>

            </div>

            {/*<div className={"card"}>*/}
            {/*        <DataTable value={graficoProdutividade}>*/}
            {/*            <Column header={"Responsavel"} field={"responsavel"}*/}
            {/*            style={{width:"150px"}}*/}
            {/*            />*/}
            {/*            <Column header={"Produtividade"} field={"total"}*/}
            {/*                    style={{width:"150px"}}*/}
            {/*            />*/}
            {/*        </DataTable>*/}
            {/*    </div>*/}
            {/*<div className={"card"}>*/}

            {/*    <DataTable value={graficoEficacia}*/}
            {/*    >*/}
            {/*        <Column header={"Responsavel"} field={"responsavel"}*/}
            {/*                style={{width:"150px"}}*/}
            {/*        />*/}
            {/*        <Column header={"Eficácia"} field={"total"}*/}
            {/*                style={{width:"150px"}}*/}
            {/*        />*/}
            {/*    </DataTable>*/}
            {/*</div>*/}
            {/*<div className={"card"}>*/}
            {/*        <DataTable value={graficoEficiencia}>*/}
            {/*            <Column header={"Responsavel"} field={"responsavel"}*/}
            {/*                    style={{width:"150px"}}*/}
            {/*            />*/}
            {/*            <Column header={"Eficiência"} field={"total"}*/}
            {/*                style={{width:"150px"}}*/}
            {/*            />*/}
            {/*        </DataTable>*/}
            {/*    </div>*/}


        </div>
    )
})
