import ApiService from '../../app/apiservice'

export default class TipoPagamentoService extends ApiService {

  constructor() {
    super('/tiposPagamento');
  }

  listarTiposPagamentos(empresa) {
    return this.get(`?empresa=${empresa}`);
  }

  cadastrarTipoPagamento(tipo) {
    return this.post('',tipo);
  }

  excluirTipoPagamento(codigo) {
    return this.delete(`/${codigo}`);
  }

  consultarTipoPagamento(codigo) {
    return this.get(`/${codigo}`);
  }

  alterarTipoPagamento(tipo) {
    return this.put(`/${tipo.codigo}`, tipo);
  }
}