import React, {useEffect, useRef, useState} from "react";
import LocalStorageService from "../../app/service/localStorageService";
import {USUARIO_LOGADO} from "../../app/service/AuthService";
import {EmpresaConsts} from "../../Consts/EmpresaConsts";
import EmpresaPlanosService from "../../app/service/EmpresaPlanosService";
import {CardPlano} from "../../componentes/Empresa/CardPlano";
import {Dialog} from "primereact/dialog";
import PaymentPlanosService from "../../app/service/PaymentPlanosService";
import {Toast} from 'primereact/toast';
import {ProgressBar} from 'primereact/progressbar';

export default function AlterarPlanos() {

  const empresaPlanosService = new EmpresaPlanosService();
  const paymentPlanosService = new PaymentPlanosService();
  const toast = useRef(null);
  const [planos, setPlanos] = useState([]);
  const user = LocalStorageService.obterItem(USUARIO_LOGADO);
  const [recurrentStatus, setRecurrentStatus] = useState({});
  const [isPlanoExpirado, setIsPlanoExpirado] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');
  const [countdown, setCountdown] = useState(5); // Countdown state
  const empresa = user.empresa;
  const planoAtual = empresa.plano.tipoPlano.codigo;

  useEffect(() => {
    findTipoPlanos();
    fetchRecentTransaction();
    verificarPlanoExpirado();
  }, []);

  async function findTipoPlanos() {
    await empresaPlanosService.getTiposPlanos().then((res) => {
      let data = res.data;
      setPlanos(data);
    });
  }

  function verificarPlanoExpirado() {
    const dataExpiracao = new Date(empresa.plano.dataExpiracao);
    const dataAtual = new Date();

    if (dataAtual > dataExpiracao) {
      setIsPlanoExpirado(true);
    } else {
      setIsPlanoExpirado(false);
    }
  }

  async function fetchRecentTransaction() {
    try {
      const response = await paymentPlanosService.buscarTransacoesRecentesPorCdEmpresa(empresa.codigo);
      const recentTransactions = response.data;
      
      // Encontrar a transação mais recente
      const mostRecentTransaction = recentTransactions.reduce((mostRecent, transaction) => {
        return mostRecent.created_date > transaction.created_date ? mostRecent : transaction;
      });
  
      // Verificar o estado de recorrência da transação mais recente
      const isRecurrentActive = mostRecentTransaction.transacao.recurrent_status === 'Ativa';
  
      // Definir o estado de recorrência como verdadeiro ou falso
      setRecurrentStatus(isRecurrentActive);
    } catch (error) {
      console.error("Erro ao buscar transação mais recente:", error);
    }
  }
  

  const isPlanoSelecionado = (planoAtual, planoMensal, planoAnual) => {
    return planoAtual === planoMensal?.codigo || planoAtual === planoAnual?.codigo;
  };
  


  const planoStarted = planos.find(
    (tipo) => tipo.codigo === EmpresaConsts.STARTED
  );
  const planoStartedYear = planos.find(
    (tipo) => tipo.codigo === EmpresaConsts.STARTED_YEAR
  );
  const planoStandart = planos.find(
    (tipo) => tipo.codigo === EmpresaConsts.STANDART
  );
  const planoStandartYear = planos.find(
    (tipo) => tipo.codigo === EmpresaConsts.STANDART_YEAR
  );

  const handleAssinar = async (plano, intervalo) => {
    const cdEmpresa = user.empresa.codigo;
    const tipoPlano = plano.codigo;
    //plano.valor = "valor": 49.9,
    const valorEmCentavos = plano.valor * 100;
    
    const pagamentoRequest = {
      SoftDescriptor: "Recorrencia",
      Cart: {
        Items: [
          {
            Name: plano.nome,
            Description: plano.descricao || "Assinatura",
            UnitPrice: valorEmCentavos,
            Quantity: 1,
            Type: "Service",
          },
        ],
      },
      Shipping: {
        Type: "WithoutShipping",
      },
      Payment: {
        BoletoDiscount: 0,
        DebitDiscount: 0,
        RecurrentPayment: {
          Interval: intervalo,
          EndDate: "",
        },
      },
    };

    try {
      const response = await new PaymentPlanosService().criarPagamento(
        pagamentoRequest,
        cdEmpresa,
        tipoPlano
      );
      const checkoutUrl = response.data.checkoutUrl;
      if (checkoutUrl) {
        setRedirectUrl(checkoutUrl);
        setShowDialog(true);
        let countdownValue = 5;
        setCountdown(countdownValue);
        const interval = setInterval(() => {
          countdownValue -= 1;
          setCountdown(countdownValue);
          if (countdownValue <= 0) {
            clearInterval(interval);
            setShowDialog(false);
            window.open(checkoutUrl, "_self");
          }
        }, 1000);
      } else {
        console.error("URL de checkout não encontrado na resposta da API.");
      }
    } catch (error) {
      console.error("Erro ao criar pagamento:", error);
    }
  };

  const handleEncerrar = async () => {
    const cdEmpresa = user.empresa.codigo;
    try {
      // Tentativa de desativar a recorrência
      await paymentPlanosService.desativarRecurrence(cdEmpresa);
      
      
      toast.current.show({
        severity: 'success',
        summary: 'Sucesso',
        detail: 'Recorrência desativada com sucesso.',
        life: 4000
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Erro',
        detail: 'Erro ao desativar a recorrência.',
        life: 4000
      });
      console.error("Erro ao encerrar o plano:", error);
    }
  };

  const handleAvaliar = (plano)=>{
    const cdEmpresa = user.empresa.codigo;
    const tipoPlano = plano.codigo;
    if(tipoPlano){
      empresaPlanosService.iniciarAvaliacao(cdEmpresa, tipoPlano).then(() => {
        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Avaliação iniciada com sucesso.', life: 4000 });
        setTimeout(() => {
            window.location.reload()
        },3000)
      }).catch(() => {
          toast.current.show({ severity: 'error', summary: 'Erro', detail: 'Erro ao iniciar avaliação.', life: 4000 });
      })
    }
  }
  return (
    <div className={"flex-row justify-content-center gap-4"}>
      <Toast ref={toast} />
      <Dialog header="Redirecionando" visible={showDialog} style={{ width: '50vw' }} modal onHide={() => setShowDialog(false)}>
        <p>Você será redirecionado para a página de pagamento em {countdown} segundos...</p>
        <ProgressBar value={(5 - countdown) * 20} />
      </Dialog>
      <CardPlano
        id="InicianteMensal"
        planoMensal={planoStarted}
        planoAnual={planoStartedYear}
        selected={isPlanoSelecionado(planoAtual, planoStarted, planoStartedYear)}
        onAssinar={handleAssinar}
        onEncerrar={handleEncerrar}
        recurrentStatus={isPlanoExpirado}
        planoAtual={empresa.plano.tipoPlano.nome}
        onAvaliacao7Dias={handleAvaliar}
      />
      <CardPlano
        id="PadraoMensal"
        planoMensal={planoStandart}
        planoAnual={planoStandartYear}
        selected={isPlanoSelecionado(planoAtual, planoStandart, planoStandartYear)}
        onAssinar={handleAssinar}
        onEncerrar={handleEncerrar}
        recurrentStatus={isPlanoExpirado}
        planoAtual={empresa.plano.tipoPlano.nome}
        onAvaliacao7Dias={handleAvaliar}
      />
      <CardPlano
        planoMensal={"Empresarial"}
        selected={empresa.plano.tipoPlano.nome === "Empresarial"}
      />
    </div>
  );
}
