import React, {useContext, useEffect, useState} from 'react'
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";
import {Dropdown} from "primereact/dropdown";
import {MultiSelect} from "primereact/multiselect";
import {Calendar} from "primereact/calendar";
import {Button} from "primereact/button";
import {DateFormat, DateFormatHour, formatDateHoraTela, verificaHoraZerada} from "../../utils/DateFormat";
import {AuthContext} from "../../main/ProvedorAutenticacao";
import GrupoTrabalhoService from "../../app/service/GrupoTrabalhoService";
import UsuarioGrupoTrabalhoService from "../../app/service/UsuarioGrupoTrabalhoService";
import {strings} from '../../utils/strings';
import AtividadeService from "../../app/service/AtividadeService";
import {Loading} from "../Loading";
import {DialogPesquisaProcesso} from "./DialogPesquisaProcesso";
import ProcessoService from "../../app/service/ProcessoService";
import {formatCnj} from "../../utils/nrCnj-format";
import {InputTextarea} from "primereact/inputtextarea";
import {IsNullOrEmpty} from "../../utils/IsNullOrEmpy";
import {DialogGrupoTrabalho} from "../DialogGrupoTrabalho";
import {AnexarArquivosDialogTemp} from "./AnexarArquivosDialogTemp";
import ArquivoAtividadeService from "../../app/service/ArquivoAtividadeService";

export const CadastrarAtividadeDialog = ({isOpen, onClose, toast, refresh, datetLimit, _processo}) => {
    //states
    //dropdown options list
    const [responsaveis, setResponsaveis] = useState([])
    const [interessados, setInteressados] = useState([])
    const [grupos, setGrupos] = useState([]);
    const [subGrupos, setSubGrupos] = useState([]);
    //inputs
    const [titulo, setTitulo] = useState("");
    const [selectGrupo, setSelectGrupo] = useState("");
    const [selectSubGrupo, setSelectSubGrupo] = useState("");
    const [processo, setProcesso] = useState(_processo ? _processo : null);
    const [pesquisaProcesso, setPesquisaProcesso] = useState('');
    const [responsaveisLista, setResponsaveisLista] = useState(null)
    const [interessadosLista, setInteressadosLista] = useState(null)
    const [dtLimite, setDtLimite] = useState('');
    const [dtFatal, setDtFatal] = useState('');
    // const [hora, setHora] = useState('');
    const [descricao, setDescricao] = useState('');
    //render conditions
    const [openModalProcesso, setOpenModalProcesso] = useState(false)
    const [modalAnexarArquivo, setModalAnexarArquivo] = useState(false)
    const [novoGrupo, setNovoGrupo] = useState(false)
    //loading
    const [loading, setLoading] = useState(false);
    const [loadingProcesso, setLoadingProcesso] = useState(false);
    //search list
    const [processos, setProcessos] = useState(null);
    //context
    const {usuarioAutenticado} = useContext(AuthContext)
    //service
    const grupoTrabalhoService = new GrupoTrabalhoService()
    const usuarioGrupoTrabalhoService = new UsuarioGrupoTrabalhoService()
    const atividadeService = new AtividadeService()
    const processoService = new ProcessoService()
    const arquivoAtividadeService = new ArquivoAtividadeService()

    const cadastrarAtividade = async () => {
        var tipoTarefa = 'A';
        const usuarioLogado = usuarioAutenticado;
        if (IsNullOrEmpty(titulo)) {
            toast.show({severity: 'error', summary: 'Atividades', detail: 'Informe o título da atividade', life: 4000});
            return false;
        }

        if (IsNullOrEmpty(selectGrupo)) {
            toast.show({severity: 'error', summary: 'Atividades', detail: 'Informe o grupo de trabalho', life: 4000});
            return false;
        }

        if (IsNullOrEmpty(selectSubGrupo)) {
            toast.show({
                severity: 'error',
                summary: 'Atividades',
                detail: 'Informe o sub-grupo de trabalho',
                life: 4000
            });
            return false;
        }

        if (IsNullOrEmpty(responsaveisLista)) {
            toast.show({
                severity: 'error',
                summary: 'Atividades',
                detail: 'A atividade precisa de ao menos um responsável',
                life: 4000
            });
            return false;
        }

        if (IsNullOrEmpty(dtLimite)) {
            toast.show({severity: 'error', summary: 'Atividades', detail: 'Informe a data limite', life: 4000});
            return false;
        }
        if (verificaHoraZerada(dtLimite)) {
            tipoTarefa = "T"
        }
        let dataLimite = DateFormatHour(dtLimite);
        let dataFatal = null
        if (!IsNullOrEmpty(dtFatal)) {
            dataFatal = DateFormat(dtFatal);

        }
        setLoading(true)

        const cadastroAtividade = {
            atividade: {
                titulo: titulo,
                processo: processo,
                subGrupo: selectSubGrupo,
                dtLimite: dataLimite,
                dtFatal: dataFatal,
                descricao: descricao,
                usuario: usuarioLogado,
                tipo: tipoTarefa,
                status: {
                    codigo: 2
                } // Aguardando Inicio
            },
            responsaveis: responsaveisLista,
            interessados: interessadosLista
        }


        await atividadeService.cadastrarAtividade(cadastroAtividade).then(() => {
            toast.show({
                severity: 'success',
                summary: 'Atividades',
                detail: 'Atividade cadastrada com sucesso',
                life: 4000
            });
            close()
        }).catch(error => {
            let detail = error.response.data.detail
            toast.show({severity: 'error', summary: 'Atividades', detail: `${detail}`, life: 4000});
        }).finally(() => setLoading(false))
        if (refresh) {
            refresh();
        }

    }

    async function onGrupoTrabalhoChange(e) {
        if (e.value) {
            await setSelectGrupo(e.value)
            await consultarSubGrupos(e.value.codigo);
            await setResponsaveisLista([]);
            await setInteressadosLista([]);
            await listarResponsaveis(e.value.codigo);
            await listarInteressados(e.value.codigo);
        }

    }

    function onSubGrupoChange(e) {
        if (e.value) {
            setSelectSubGrupo(e.value)
        }
    }

    const listarResponsaveis = async (codigo) => {
        await usuarioGrupoTrabalhoService.listarUsuariosAtividade(codigo)
            .then(response => {
                setResponsaveis(response.data)
            }).catch(() => {
                toast.show({
                    severity: 'error',
                    summary: 'Atividades',
                    detail: 'Erro ao listar responśaveis',
                    life: 4000
                });
            })
    }

    const listarInteressados = async (codigo) => {
        await usuarioGrupoTrabalhoService.listarUsuariosAtividade(codigo)
            .then(response => {
                setInteressados(response.data)
            }).catch(() => {
                toast.show({
                    severity: 'error',
                    summary: 'Atividades',
                    detail: 'Erro ao listar interessados',
                    life: 4000
                });
            })
    }

    const consultarGruposTrabalho = async () => {
        await grupoTrabalhoService.listarGruposUsuario(usuarioAutenticado.codigo)
            .then(response => {
                setGrupos(response.data)
            }).catch(() => {
                toast.show({
                    severity: 'error',
                    summary: 'Atividades',
                    detail: 'Erro ao carregar grupos de trabalhos do usuário',
                    life: 4000
                });
            })
    }

    const consultarSubGrupos = async (codigo) => {
        await grupoTrabalhoService.listarSubGrupos(codigo)
            .then(response => {
                setSubGrupos(response.data)
            }).catch(() => {
                toast.show({
                    severity: 'error',
                    summary: 'Atividades',
                    detail: 'Erro ao carregar sub-grupos de trabalho',
                    life: 4000
                });
            })
    }

    const consultarProcessoPorIndice = async () => {
        const usuarioLogado = usuarioAutenticado
        const indice = {
            indice: pesquisaProcesso,
            empresa: usuarioLogado.empresa.codigo,
            usuario: usuarioLogado.codigo
        }
        setLoadingProcesso(true)
        await processoService.consultarProcessoPorIndice(indice)
            .then(response => {
                setProcessos(response.data)
                if (response.data.length > 0) {
                    setOpenModalProcesso(true)
                } else {
                    setPesquisaProcesso('')
                    toast.show({severity: 'error', summary: 'Processo', detail: 'Processo não encontrado', life: 4000});
                }
            }).catch(error => {
                toast.show({
                    severity: 'error',
                    summary: 'Atividades',
                    detail: `${error.response.data.detail}`,
                    life: 4000
                });
            })
        setLoadingProcesso(false)

    }


    const excluirPesquisaProcesso = () => {
        setProcesso(null)
        setSelectGrupo(null);
        setSubGrupos(null);
        setSelectSubGrupo(null);
        setResponsaveisLista([]);
        setInteressadosLista([]);
    }

    const setSelectedProcesso = async (data) => {
        setProcesso(data)
        setSelectGrupo(data.grupoTrabalho)
        await consultarSubGrupos(data.grupoTrabalho.codigo);
        await listarResponsaveis(data.grupoTrabalho.codigo);
        await listarInteressados(data.grupoTrabalho.codigo);
    }

    const excluirArquivosTemp = async () => {
        const codigo = usuarioAutenticado.codigo
        await arquivoAtividadeService.excluirArquivosTemp(codigo)
            .then(() => {

            }).catch(error => {
                let detail = error.response.data.detail
                toast.show({severity: 'error', summary: 'Arquivos', detail: `${detail}`, life: 4000});
            })
    }

    const close = async () => {
        await excluirArquivosTemp().finally(() => {
            onClose()
        })

    }

    const handleTituloChange = (event) => {
        const newValue = event.target.value;
        if (newValue.length <= 100) {
            setTitulo(newValue);
        }
    };

    async function load() {
        setLoading(true)
        await consultarGruposTrabalho()
        await excluirArquivosTemp()
        let newdtLimite = new Date()
        newdtLimite.setHours(0, 0, 0, 0)

        setDtLimite(datetLimit || newdtLimite)
        setLoading(false)
    }

    useEffect(() => {
        load()
// eslint-disable-next-line
    }, [])


    function renderFooterAtividade() {
        return (
            <div className="row-etrium gap-0">
                <Button label="Cadastrar"
                        onClick={cadastrarAtividade}
                        className={strings.buttonPrimaryStyle}/>
                <Button label="Anexar"
                        icon="pi pi-paperclip"
                        onClick={() => setModalAnexarArquivo(true)}
                        className={strings.buttonHelp}/>


                {/*<Button label="Cancelar"*/}
                {/*        className={strings.buttonSecondaryStyle}*/}
                {/*        onClick={onClose}/>*/}
            </div>
        );
    }

    return (<>
            <Loading open={loading}/>
            <Dialog header="Cadastrar atividade" autoComplete="nope"
                    visible={isOpen}
                // style={{ width: '50vw' }}
                    footer={renderFooterAtividade('displayAtividade')}
                    onHide={() => close()}>
                {/* {loadingProcesso && <Progresso classeSecundaria='mb-2' />} */}
                <Loading open={loadingProcesso}/>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label>Título<span className="obrigatorioAsterisco"> *</span></label>
                            <InputText id="titulo"
                                       style={{width: '100%', height: '37px'}}
                                       maxLength={100}
                                       type="text"
                                       autoComplete="nope"
                                       value={titulo}
                                       onChange={handleTituloChange}
                                       className="p-inputtext-sm p-d-block p-mb-1"
                            />
                            {/*<span className={"flex-row justify-content-end"}>{100 - titulo.length}</span>*/}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>Data limite<span className="obrigatorioAsterisco"> *</span></label>
                            <Calendar id="mask"
                                      value={dtLimite}
                                      name={'dtLimite'}
                                      onChange={(e) => setDtLimite(e.target.value)}
                                      locale="es"
                                      showTime
                                      style={{width: '100%', height: "37px"}}
                                      dateFormat="dd/mm/yy"


                            />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>Prazo fatal</label>
                            <Calendar id="mask"
                                      value={dtFatal}
                                      onChange={(e) => setDtFatal(e.target.value)}
                                      locale="es"
                                      style={{width: '100%', height: "37px"}}
                                      dateFormat="dd/mm/yy"

                            />
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>Grupo de trabalho<span className="obrigatorioAsterisco"> * </span>
                                <span>
                                <Button icon="pi pi-plus-circle" className="p-button-rounded p-button-text"
                                        tooltip="Clique para cadastrar um novo grupo de trabalho"
                                        tooltipOptions={{position: 'top'}}
                                        style={{width: '20px', height: '10px'}}
                                        onClick={() => setNovoGrupo(true)}

                                />
                            </span>
                            </label>
                            <Dropdown value={selectGrupo} style={{width: '100%', height: '37px'}}
                                      options={grupos}
                                // disabled={bloqueiaGrupo}
                                      optionLabel="nome"
                                      filter showClear filterBy="nome"
                                      onChange={onGrupoTrabalhoChange}
                                      id="grupoTrabalho"
                                      className="p-inputtext-sm p-d-block p-mb-1"/>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                            <div className="second-dropdown">
                                <label>Sub-grupo de trabalho<span className="obrigatorioAsterisco"> *</span></label>
                                <Dropdown value={selectSubGrupo} style={{width: '100%', height: '37px'}}
                                          options={subGrupos}
                                          optionLabel="nome"
                                          emptyMessage=" "
                                          filter showClear filterBy="nome"
                                          onChange={onSubGrupoChange}
                                          id="subGrupoTrabalho"
                                          className="p-inputtext-sm p-d-block p-mb-1"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">

                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>Responsáveis <span className="obrigatorioAsterisco">*</span></label>
                            <MultiSelect inputId="multiselect"
                                         value={responsaveisLista}
                                         options={responsaveis}
                                         style={{width: '100%', height: '37px', padding_top: '1px'}}
                                         onChange={(e) => setResponsaveisLista(e.value)}
                                         filter showClear filterBy="nome"
                                         optionLabel="nome"
                                         id="responsaveis"
                            />
                            {/* <label className="linkCadGrupo">{`Responsaveis da Atividade (${responsaveisLista.length})`}</label> */}
                        </div>
                    </div>


                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>Interessados</label>
                            <MultiSelect inputId="multiselect"
                                         value={interessadosLista}
                                         options={interessados}
                                         id="interessados"
                                         style={{width: '100%', height: '37px', padding_top: '1px'}}
                                         onChange={(e) => setInteressadosLista(e.value)}
                                         filter showClear filterBy="nome"
                                         optionLabel="nome"
                            />
                            {/* <label
                                className="linkCadGrupo">{`Interessados da Atividade (${interessadosLista.length})`}</label> */}
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div
                        // className={state.sizePesquisa}
                        className='col-sm-12'
                    >
                        {!processo ?

                            <div className="form-group">
                                <label>Vincular ao processo</label>
                                <div className="group-row p-inputtext-sm p-d-block p-mb-1">

                                    <InputText id="processo" style={{width: '100%', height: '37px'}}
                                               type="text"
                                               autoComplete="nope"
                                               value={pesquisaProcesso}
                                               onChange={e => setPesquisaProcesso(e.target.value)}
                                               placeholder="Pesquise o processo por pessoa, pasta ou número"/>
                                    <div className="input-group-append ml-negative">
                                        <button className="btn btn-navbar" onClick={consultarProcessoPorIndice}>
                                            <i className="fas fa-search"></i>
                                        </button>
                                    </div>

                                </div>
                            </div>
                            :
                            <div className="form-group"
                                 style={{display: `block`}}
                            >
                                <strong>Processo: {formatCnj.formatCnjMascarado(processo.nrCnj)}</strong>
                                <br/>
                                <span
                                    onClick={excluirPesquisaProcesso}
                                    id="btnExcluirProcesso"
                                    className="linkCadGrupo" style={{cursor: 'pointer'}}>Desvincular Processo</span>
                            </div>}
                    </div>
                </div>


                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label>Descrição</label>
                            <InputTextarea id="descricao"
                                           type="text"
                                           autoComplete="nope"
                                           style={{width: '100%'}}
                                           value={descricao}
                                           onChange={e => setDescricao(e.target.value)}
                                           className="p-inputtext-sm p-d-block p-mb-1"
                                           rows={6} cols={30}
                                           maxLength={1000}
                            />
                            <span className={"flex-row justify-content-end"}>{1000 - descricao.length}</span>
                        </div>
                    </div>
                </div>
            </Dialog>

            {openModalProcesso && <DialogPesquisaProcesso
                open={openModalProcesso}
                onClose={() => setOpenModalProcesso(false)}
                processos={processos}
                selecionarProcesso={setSelectedProcesso}

            />}

            {modalAnexarArquivo &&
                <AnexarArquivosDialogTemp
                    open={modalAnexarArquivo}
                    onClose={() => setModalAnexarArquivo(false)}
                    toast={toast}/>
            }
            {novoGrupo &&
                <DialogGrupoTrabalho open={novoGrupo}
                                     close={() => setNovoGrupo(false)}
                                     toast={toast} refrashList={consultarGruposTrabalho}/>
            }

        </>
    )
}
