import React, {useEffect, useState} from "react";
import {Loading} from "../../Loading";
import {HeaderPanelFichaProcesso} from "./HeaderPanelFichaProcesso";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import ProcessoService from "../../../app/service/ProcessoService";
import {formatarMoeda} from "../../../utils/formatar-moeda-tela";
import {CadastrarLancamentoDialog} from "../../Financeiro/PainelFinanceiro/CadastrarLancamentoDialog";
import {ConsultaLancamentoDetalhadoDialog} from "../../Financeiro/PainelFinanceiro/ConsultaLancamentoDetalhadoDialog";

export const FinanceiroProcesso = ({
                                       processo,
                                       autor,
                                       reu,
                                       nrCnj,
                                       pasta,
                                       nrProcesso,
                                       toast,
                                   }) => {

    const [lancamentosFinanceiros, setLancamentosFinanceiros] = useState([])


    const [lancamento, setLancamento] = useState(null)

    const [modalConsulta, setModalConsulta] = useState(false)
    const [modalNovoLancamento, setModalNovoLancamento] = useState(false)

    const [loading, setLoading] = useState(false);

    // const {usuarioAutenticado} = useContext(AuthContext)

    //service
    const processoService = new ProcessoService()

    const consultarLancamentosFinanceiros = (codigoProceso) => {
        setLoading(true)
        processoService.consultarLancamentosFinanceiros(codigoProceso)
            .then(response => {
                setLancamentosFinanceiros(response.data)
            }).catch(error => {
            let detail = error.response.data.detail
            toast.show({
                severity: 'error',
                summary: 'Processos - Lançamentos financeiros',
                detail: `${detail}`,
                life: 4000
            });
        }).finally(() => {
            setLoading(false)
        })
    }

    function consultarLancamento(lancamento) {

        setLancamento(lancamento)
        setModalConsulta(true)
    }

    function acoesFinanceiro(rowData) {
        return (
            <Button icon="pi pi-search-plus" className="p-button-rounded p-button-text"
                    tooltip="Consultar lançamento"
                    id={"btn-consultar-lancamento"}
                    onClick={() => consultarLancamento(rowData)}
                    tooltipOptions={{position: 'top'}}
                    style={{width: '20px', height: '10px'}}/>
        )
    }

    function novoLancamento() {
        return (
            <div>
                <Button icon="pi pi-plus-circle" className="p-button-rounded p-button-text"
                        tooltip="Cadastrar lançamento"
                        id={"btn-novo-lancamento"}
                        onClick={() => setModalNovoLancamento(true)}
                        tooltipOptions={{position: 'top'}}/>
            </div>
        )
    }

    useEffect(() => {
        consultarLancamentosFinanceiros(processo.codigo)


        // eslint-disable-next-line
    }, [])


    return (
        <>
            <div className={"card-body"}>
                <Loading open={loading}/>
                <HeaderPanelFichaProcesso
                    autor={autor}
                    reu={reu}
                    nrCnj={nrCnj}
                    pasta={pasta}
                    nrProcesso={nrProcesso}
                />
                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <DataTable value={lancamentosFinanceiros} paginator
                                       responsiveLayout="scroll" size="small"
                                       paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                       currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}"
                                       rows={10}
                                       emptyMessage="Nenhum lançamento financeiro">
                                <Column field="categoria" header="Categoria"></Column>
                                <Column field="tipo.nome" header="Tipo"></Column>
                                <Column field="dtVencimento" header="Data vencto."></Column>
                                <Column body={(rowData) => formatarMoeda(rowData.vlLancamento)} header="Valor"></Column>
                                <Column body={(rowData) => formatarMoeda(rowData.vlPago)} header="Vl pago"></Column>
                                <Column field="dtPago" header="Data pgto."></Column>
                                <Column field="situacao" header="Situação"></Column>
                                <Column body={acoesFinanceiro} exportable={false}
                                        style={{minWidth: '8rem'}} /*header="Ações"*/  header={novoLancamento}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>

            {modalConsulta && <ConsultaLancamentoDetalhadoDialog
                lancamento={lancamento}
                open={modalConsulta}
                toast={toast}
                onClose={() => setModalConsulta(false)}
                refresh={() => consultarLancamentosFinanceiros(processo.codigo)}
            />}
            {modalNovoLancamento &&
                <CadastrarLancamentoDialog
                    hasProcesso={processo}
                    open={modalNovoLancamento}
                    toast={toast}
                    onClose={()=>setModalNovoLancamento(false)}
                    refresh={()=> consultarLancamentosFinanceiros(processo.codigo)}
                />}
        </>
    )
}
