import {Button} from "primereact/button";
import React, {useContext, useState} from "react";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {AuthContext} from "../main/ProvedorAutenticacao";
import AreaAtuacaoService from "../app/service/AreaAtuacaoService";
import { strings } from "../utils/strings";


export const DialogAreaAtuacao = ({ open, toast, close, refrashList }) => {

    const [state, setState] = useState({
        areaAtuacaoService: new AreaAtuacaoService(),
        //Cadastro
        codigo: '',
        nome: '',
        empresa: null,
        //Progresso
        classeDiv: 'hide',

    });
    const { usuarioAutenticado } = useContext(AuthContext);

    const cadastrarAreaAtuacao = async () => {
        stateChange("classeDiv", 'show');
        if (state.nome === '') {
            toast.show({
                severity: 'error',
                summary: 'Área atuação',
                detail: 'Informe o nome da área de atuação',
                life: 4000
            });
            stateChange("classeDiv", 'hide');
            return false;
        }
        await state.areaAtuacaoService.cadastrarAreaAtuacao({
            nome: state.nome,
            empresa: usuarioAutenticado.empresa
        }).then(response => {
            toast.show({
                severity: 'success',
                summary: 'Área atuação',
                detail: 'Área atuação cadastrado com sucesso',
                life: 4000
            });
            refrashList();

        }).catch(error => {
            stateChange("detail", error.response.data.detail);
            toast.show({ severity: 'error', summary: 'Area atuação', detail: `${state.detail}`, life: 4000 });
        });

        stateChange("classeDiv", 'hide');
        limparCampos();
        close();
    }

    function renderFooterCadastro(name) {
        return (
            <div className="row-etrium gap-1">
                <Button label="Cadastrar"
                    className={strings.buttonPrimaryStyle}
                    onClick={cadastrarAreaAtuacao} />
            </div>
        );
    }

    function stateChange(name, value) {
        setState({
            ...state,
            [`${name}`]: value
        });
    }

    const limparCampos = () => {
        stateChange("nome", '');
    }
    /**Cadastrar Nova Area de Atuação */

    return (

        <Dialog header="Cadastrar nova área de atuação"
            visible={open}
            style={{ width: '40vw' }}
            footer={renderFooterCadastro()}
            onHide={close}>

            <div className="p-fluid">
                <div className="p-field">
                    <label htmlFor="nomeArea">Nome<span className="obrigatorioAsterisco"> * </span></label>
                    <InputText id="nomeArea"
                        type="text"
                        value={state.nome}
                        onChange={e => stateChange("nome", e.target.value)}
                        className="p-inputtext-sm p-d-block p-mb-1" />
                </div>
            </div>
        </Dialog>

    );
}