import {InputText} from "primereact/inputtext";
import {formatCnj} from "../../utils/nrCnj-format";
import React from "react";


export const InputPesquisaProcessoFinanceiro = ({
                                                    processo,
                                                    consultarProcessoPorIndice,
                                                    excluirPesquisaProcesso,
                                                    ...rest
                                                }) => {

    return (
        <div className="flex-column">
            <label>Processo</label>
            <div className="group-row"
            >
                {!processo ?
                    <>
                        <InputText
                            {...rest}
                            id="processo" style={{width: '100%', height: '37px'}}
                            type="text"
                            autoComplete="nope"
                            className="p-inputtext-sm p-d-block p-mb-1"
                            placeholder="Digite aqui para pesquisar processos"/>
                        <span className="input-group-prepend pointer">
                                <span
                                    onClick={() => consultarProcessoPorIndice()}
                                    className="input-group-text">
                                    <i className="fas fa-search"></i>
                                </span>
                            </span>
                    </> :

                    <span className="p-inputtext-sm p-d-block p-mb-1 w-full">
                          <InputText
                              style={{width:"100%"}}
                              value={formatCnj.formatCnjMascarado(processo.nrCnj)}
                          />
                        <span
                            onClick={excluirPesquisaProcesso}
                            style={{cursor: 'pointer', marginLeft: "-25px"}}><i className="pi pi-times" style={{fontSize: '1rem'}}></i></span>
                        </span>

                }
            </div>
        </div>)

}
