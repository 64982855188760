export const FiltroRelatorioFinanceiro = (categoria, situacao, tipo, dataInicial, dataFinal) => {
    //Montar a legenda do filtro
    let filtro = '';
    let dia = '';
    let mes = '';
    let ano = '';

    ano = dataInicial.substring(0, 4);
    mes = dataInicial.substring(5, 7);
    dia = dataInicial.substring(8, 10);

    filtro = 'Datas[ ' + dia + '/' + mes + '/' + ano;

    ano = dataFinal.substring(0, 4);
    mes = dataFinal.substring(5, 7);
    dia = dataFinal.substring(8, 10);

    filtro = filtro + ' a ' + dia + '/' + mes + '/' + ano + ' ]';

    if(categoria){
        if (categoria === 'T') {
            filtro = filtro + ', Despesas/Receitas[ Ambos ]';
        } else if (categoria === 'D') {
            filtro = filtro + ', Despesas/Receitas[ Despesas ]';
        } else {
            filtro = filtro + ', Despesas/Receitas[ Receitas ]';
        }
    }

if(tipo){
    if (tipo.codigo === '0' || tipo.codigo === undefined) {
        filtro = filtro + ', Tipo[ Todos ]';
    } else {
        filtro = filtro + ', Tipo[ ' + tipo.nome + ' ]';

    }
}

if(situacao){
    if (situacao === 'T') {
        filtro = filtro + ', Situação[ Todas ]';
    } else if (situacao === 'P') {
        filtro = filtro + ', Situação[ Pagos ]';
    } else if (situacao === 'A') {
        filtro = filtro + ', Situação[ Ativos ]';
    } else {
        filtro = filtro + ', Situação[ Cancelados ]';
    }
}


    return filtro

}
