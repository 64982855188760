import ApiService from '../apiservice'

export default class TipoDespesaReceitaService extends ApiService {
  constructor() {
    super('/tipoDespesaReceita')
  }

  cadastrarTipoDespesaReceita(tipoDespesaReceita) {
    console.log('Tipo de ', tipoDespesaReceita)
    return this.post('',tipoDespesaReceita);
  }

  listarTiposDespesasReceitas(empresa) {
    return this.get(`/${empresa}`);
  }

  consultarTipoDespesaReceita(codigo) {
    return this.get(`/porCodigo/${codigo}`);
  }

  alterarTipoDespesaReceita(tipo) {
    return this.put(`/${tipo.codigo}`, tipo);
  }

  excluirTipoDespesaReceita(codigo) {
    return this.delete(`/${codigo}`);
  }

  listarTiposDespesasReceitasPorTipo(empresa, tipo) {
    return this.get(`/despesasReceitasPorTipo?empresa=${empresa}&tipo=${tipo}`);
  }
} 