import {AutoComplete} from "primereact/autocomplete";
import React, {useState} from "react";
import LocalTramiteService from "../app/service/LocalTramiteService";


export const AutoCompleteLocalTramite = ({selectLocalTramite, setSelect}) => {
    const localTramiteService = new LocalTramiteService();
    const [filter, setFilter] = useState();

    function consultarLocalTramite(event) {
        if (event.query.toLowerCase().length > 2) {
            localTramiteService.listarLocalTramitePorNomes(event.query.toLowerCase()).then((res) => {
                setFilter(res.data)
            })
        }
    }

    return (
        <div className="form-group">
            <label>Local de trâmite
                <span className="obrigatorioAsterisco"> * </span>
            </label>
            <AutoComplete value={selectLocalTramite}
                          suggestions={filter}
                          completeMethod={consultarLocalTramite}
                          style={{width: '100%'}}
                          field="nome"
                          id="localtramite-ac"
                          placeholder="Digite aqui para pesquisar locais de trâmite"
                          onChange={(e) => setSelect(e.value)}
                          className="p-inputtext-sm width-100 p-d-block p-mb-1"/>

        </div>
    );
}