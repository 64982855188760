import ApiService from '../apiservice'

class CepService extends ApiService {

  constructor() {
    super('https://brasilapi.com.br/api/cep/v2/')
  }

  buscarEndereco(cep) {
    return this.get(`${cep}`);
  }

}

export default CepService;
