import React from 'react'
import {withRouter} from 'react-router-dom'

import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button'
import {ConfirmDialog, confirmDialog} from 'primereact/confirmdialog'

import NavBar from '../../componentes/Navbar'
import Progresso from '../../componentes/Progresso'
import UsuarioService from '../../app/service/UsuarioService'
import {AuthContext} from '../../main/ProvedorAutenticacao'
import {strings} from "../../utils/strings";
import {ButtonSm} from '../../componentes/ButtonSm';
import {UsuarioGrupoTrabalhoDialog} from "../../componentes/GrupoTrabalho/UsuarioGrupoTrabalhoDialog";
import {Loading} from "../../componentes/Loading";

class Usuarios extends React.Component {
    constructor(props) {

        super(props);
        this.usuarioService = new UsuarioService();

        this.state = {
            classeDiv: 'hide',
            usuarios: [],
            visible: '',
            codigo: '',
            consulta: '',
            //Consulta dos Grupos de Trabaho
            displayBasic: '',
            usuarioGrupo: '',
            gruposTrabalho: [],
            gruposSemUsuario: [],
            selectGrupo: null,
            usuario: null,
            filtroUsuarioNome: [],
            modalGrupoUsuario: false,
            loading: true,
        };


        this.acoes = this.acoes.bind(this);
        this.accept = this.accept.bind(this);
        this.renderFooterGruposUsuarios = this.renderFooterGruposUsuarios.bind(this);
        this.excluirUsuario = this.excluirUsuario.bind(this);
    }

    async confirmaExclusao(usuario) {
        this.setState({codigo: usuario.codigo});
        confirmDialog({
            message: 'Deseja realmente excluir o usuário selecionado?',
            header: 'Exclusão de usuário',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: this.accept,
            reject: this.reject
        });
    }

    accept() {
        this.excluirUsuario();
    }

    excluirUsuario = async () => {
        this.setState({classeDiv: 'show'})
        await this.usuarioService.excluirUsuario(this.state.codigo)
            .then(() => {
                this.toast.show({
                    severity: 'success',
                    summary: 'Usuários',
                    detail: 'Usuário excluído com sucesso',
                    life: 4000
                });
                this.listarUsuarios();
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({severity: 'error', summary: 'Usuários', detail: `${this.state.detail}`, life: 4000});
                this.setState({classeDiv: 'hide'})
            });

        this.setState({classeDiv: 'hide'});
    }

    listarUsuarios = async () => {
        this.setState({classeDiv: 'show'});
        const usuarioLogado = this.context.usuarioAutenticado;

        const filtroUsuario = {
            consulta: this.state.consulta,
            empresa: usuarioLogado.empresa.codigo
        }

        await this.usuarioService.listarUsuario(filtroUsuario)
            .then(response => {
                this.setState({usuarios: response.data})
            }).catch(error => {
                this.setState({classeDiv: 'hide'});
                this.setState(error.response.data);
                this.toast.show({severity: 'error', summary: 'Usuários', detail: `${this.state.detail}`, life: 4000});
            }).finally(()=> {
                this.setState({loading: false})
            });

        this.setState({classeDiv: 'hide'});
    }

    novoUsuario = () => {
        this.props.history.push('/cadastroUsuario')
    }

    async componentDidMount() {
        await this.listarUsuarios();
        await  this.setState({
            filtroUsuarioNome: this.state.usuarios.filter(user => user.nome.toUpperCase().includes(this.state.consulta.toUpperCase()))
        })
    }

    onConsultaChange = async (e) => {
        await this.setState({
            consulta: e.target.value
        })
        await  this.setState({
            filtroUsuarioNome: this.state.usuarios.filter(user => user.nome.toUpperCase().includes(this.state.consulta.toUpperCase()))
        })
    }


    exibirGruposTrabalho(usuario) {
        this.setState({usuarioGrupo: usuario.nome});
        this.setState({usuario: usuario});
        this.abrirModaUsuariosGrupo('modalGrupoUsuario');
    }


    abrirModaUsuariosGrupo(name, position) {
        let state = {
            [`${name}`]: true
        };

        if (position) {
            state = {
                ...state,
                position
            }
        }

        this.setState(state);
    }

    acoes(rowData) {
        return (
            <div className="row gap-1">
                <Button icon="pi pi-pencil" className={strings.buttonIconSecundary}
                        onClick={() => this.editar(rowData)}
                        tooltip="Editar usuário"
                        tooltipOptions={{position: 'top'}}/>
                <Button icon="pi pi-sitemap" className="p-button-rounded p-button-text"
                        tooltip="Grupos acessíveis"
                        tooltipOptions={{position: 'top'}}
                        onClick={() => this.exibirGruposTrabalho(rowData)}/>
                <Button icon="pi pi-trash" className={strings.buttonIconDanger}
                        onClick={() => this.confirmaExclusao(rowData)}
                        tooltip="Excluir usuário"
                        tooltipOptions={{position: 'top'}}/>

            </div>
        );
    }



    renderFooterGruposUsuarios(name) {
        return (
            <div>
                <Button label="Fechar"
                        className="p-button-secondary p-button-text"
                        onClick={() => this.fecharModalCadastro(name)}/>
            </div>
        );
    }

    fecharModalCadastro(name) {
        this.setState({
            [`${name}`]: false
        });
    }

    editar(usuarioEditar) {
        this.props.history.push(`/editarUsuario/${usuarioEditar.codigo}`);
    }

    usuarioStatusBody = ( user)=>{
            return user.situacao === 0 ? "Ativo" : "Inativo"
    }

    render() {
        return (
            <>
                <Loading open={this.state.loading} />
                <NavBar/>
                <Toast ref={(el) => this.toast = el}/>
                <div className="content-wrapper">
                    {/**Consultar Grupos por usuário e adicionar */}
                    {this.state.modalGrupoUsuario && <UsuarioGrupoTrabalhoDialog
                        open={this.state.modalGrupoUsuario}
                        onClose={() => this.setState({modalGrupoUsuario: false})}
                        toast={this.toast}
                        usuario={this.state.usuario}
                        usuarioNome={this.state.usuarioGrupo}
                    />}
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card card-primary card-outline">
                                        <div className="card-header">
                                            <h3 className="card-title">
                                                <i className="fas fa-users"></i>
                                                <span> Usuários</span>
                                            </h3>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-5">
                            <span className="input-group">
                                  <ButtonSm type="button" className={strings.buttonPrimarySm}
                                            onClick={this.novoUsuario}>
                              <i className="fas fa-plus"></i> Cadastrar</ButtonSm>
                              <div className="flex-1 space-etrium">
                                  <input id='ConsultaUser' type="text" className="form-control"
                                         placeholder="Consulte um usuário"
                                         value={this.state.consulta}
                                         onChange={this.onConsultaChange}
                                  />
                              <span className="input-group-prepend">
                                  <span className="input-group-text">
                                  <i className="fas fa-search"></i>
                                  </span>
                              </span>
                              </div>
                            </span>
                                                </div>
                                            </div>
                                            <Progresso classeSecundaria={this.state.classeDiv}/>
                                            <ConfirmDialog visible={this.state.visible}
                                                           onHide={() => this.setState({visible: false})}
                                                           message="Are you sure you want to proceed?"
                                                           header="Confirmation"
                                                           icon="pi pi-exclamation-triangle"
                                                // eslint-disable-next-line
                                                           accept={() => this.excluirUsuario} style="margin-top: -16px;"
                                                           reject={() => this.setState({visible: false})}/>
                                        </div>
                                        <DataTable value={this.state.filtroUsuarioNome} paginator
                                                   responsiveLayout="scroll"
                                                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                                   currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}"
                                                   rows={10}
                                                   emptyMessage="Nenhum usuário encontrado">
                                            <Column field="nome" header="Nome"></Column>
                                            <Column field="email" header="E-mail"></Column>
                                            <Column field="perfil.nome" header="Perfil"></Column>
                                            <Column field="dataCadastro" header="Data de cadastro"></Column>
                                            <Column header={"Status"} body={this.usuarioStatusBody}></Column>
                                            <Column body={this.acoes} exportable={false} style={{minWidth: '8rem'}}
                                                    header="Ações"></Column>
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        );
    }
}

Usuarios.contextType = AuthContext;
export default withRouter(Usuarios);
