import React, {useContext} from "react";
import {formatDateHoraImpressao} from "../../../utils/DateFormat";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import imagemRelatorio from '../../../pages/seguranca/img/etrium-logo1.svg';
import {AuthContext} from "../../../main/ProvedorAutenticacao";


export const ProcessosFiltradosComponentPrint = React.forwardRef((
    {processos, filtro, quantidade}, ref) => {


    const usuarioLogado = useContext(AuthContext).usuarioAutenticado;

    return (
        <div ref={ref}>
            <br></br>
            <div style={{margin: '38px'}}>
                <div>
                    <img src={imagemRelatorio} width="200" height="140" alt={'Logo do Sistema'}/>
                </div>
                <span className="relatorio">Relatório de processos</span>
                <div className="flex-row gap-0">
                    <text>Impresso por: {usuarioLogado.nome}</text>
                </div>
                <div className="flex-row gap-0">
                    <text>{formatDateHoraImpressao()}</text>
                </div>
                <div>
                    <span className="filtro">Filtros:&nbsp;{filtro}</span>
                </div>
                <div>
                    Quantidade de Registros:&nbsp;{quantidade}
                </div>
            </div>
            <div style={{margin: '46px'}}>
                <DataTable value={processos} alwaysShowPaginator responsiveLayout="scroll"
                           paginatorRight selectionMode="multiple">
                    <Column field="pasta" header="Pasta" style={{width: '200px'}}></Column>
                    {/*<Column field="nrProcesso" header="Processo Original" style={{ width: '180px' }}></Column>*/}
                    <Column field="nrCnj" header="Numeração única" style={{width: '200px'}}></Column>
                    {/*<Column field="autor" header="Autor x Réu" style={{ width: '300px' }}></Column>*/}
                    <Column field="grupoTrabalho.nome" header="Grupo trab." style={{width: '200px'}}></Column>
                </DataTable>
            </div>

        </div>

    )
});
