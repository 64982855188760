import React from "react";


export const MenuItemWithPerfil = ({perfilList, ususarioPerfil, element}) => {
    if (perfilList.includes(ususarioPerfil)) {
        return element
    } else {
        return <></>
    }
}
