import {Dialog} from "primereact/dialog";
import React from "react";


export const AlertDialog = ({title, message, open, onClose})=>{
    return(
        <Dialog
            header={title} autoComplete="nope"
            visible={open}
            style={{width: '50vw'}}
            // footer={renderFooter}
            onHide={onClose}
        >
            <div className="flex-row align-content-center gap-1 mb-5">
                <div className="my-auto">
                    <i className="pi pi-exclamation-triangle" style={{fontSize: '2.5rem',}}></i>
                </div>
                <p className="mb-0">
                    {message}
                </p>
            </div>
        </Dialog>
    )
}
