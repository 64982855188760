import ApiService from '../apiservice'

export default class AtividadeService extends ApiService {
  constructor() {
    super('/atividades')
  }

  cadastrarAtividade(atividade) {
    return this.post('',atividade);
  }

  cadastrarAtividadeUsuario(atividadeUsuario) {
    return this.post('/atividadeUsuario',atividadeUsuario);
  }

  atividadesAtrasadasResponsavel(usuario,pesquisa) {
    return this.get(`/atividadesAtrasadasResponsavel/${usuario}?pesquisa=${pesquisa}`);
  }

  atividadesHojeResponsavel(usuario,pesquisa) {
    return this.get(`/atividadesHojeResponsavel/${usuario}?pesquisa=${pesquisa}`);
  }

  atividadesSeteResponsavel(usuario,pesquisa) {
    return this.get(`/atividadesSeteResponsavel/${usuario}?pesquisa=${pesquisa}`);
  }

  atividadesTrintaResponsavel(usuario,pesquisa) {
    return this.get(`/atividadesTrintaResponsavel/${usuario}?pesquisa=${pesquisa}`);
  }

  atividadesAtrasadasInteressados(usuario,pesquisa) {
    return this.get(`/atividadesAtrasadasInteressados/${usuario}?pesquisa=${pesquisa}`);
  }

  atividadesHojeInteressados(usuario,pesquisa) {
    return this.get(`/atividadesHojeInteressados/${usuario}?pesquisa=${pesquisa}`);
  }

  atividadesSeteInteressados(usuario,pesquisa) {
    return this.get(`/atividadesSeteInteressados/${usuario}?pesquisa=${pesquisa}`);
  }

  atividadesTrintaInteressados(usuario,pesquisa) {
    return this.get(`/atividadesTrintaInteressados/${usuario}?pesquisa=${pesquisa}`);
  }

  compromissosAtrasados(usuario,pesquisa) {
    return this.get(`/compromissosAtrasados/${usuario}?pesquisa=${pesquisa}`);
  }

  compromissosHoje(usuario,pesquisa) {
    return this.get(`/compromissosHoje/${usuario}?pesquisa=${pesquisa}`);
  }

  compromissosSete(usuario,pesquisa) {
    return this.get(`/compromissosSete/${usuario}?pesquisa=${pesquisa}`);
  }

  compromissosTrinta(usuario,pesquisa) {
    return this.get(`/compromissosTrinta/${usuario}?pesquisa=${pesquisa}`);
  }

  consultarAtividade(codigo) {
    return this.get(`/${codigo}`);
  }

  consultarUsuariosAtividade(atividade, tipo) {
    return this.get(`/usuariosAtividade?atividade=${atividade}&tipo=${tipo}`);
  }

  consultarHistorico(atividade) {
    return this.get(`/historico/${atividade}`);
  }

  consultarEvents(){
    return this.get('/historico/eventos');
  }

  incluirHistorico(historico) {
    return this.post('/historico',historico);
  }

  excluirHistorico(historico, usuarioCadastrou, usuarioSolicitou) {
    return this.delete(`/historico?historico=${historico}&usuarioCadastrou=${usuarioCadastrou}&usuarioSolicitou=${usuarioSolicitou}`);
  }

  alterarAtividade(dataAntiga, statusAntigo, usuario, atividade) {
    return this.put(`/alterarAtividade/${atividade.atividade.codigo}/dataAnterior/${dataAntiga}/statusAnterior/${statusAntigo}/usuario/${usuario}`, atividade);
  }

  consultarAtividadePorProcesso(processo) {
    return this.get(`/porProcesso/${processo}`);
  }

  consultaAtividadesCriadasUsuario(usuario) {
    return this.get(`/atividadesCriadasUsuario/${usuario}`);
  }

  consultaAtividadesUltimosTrintaDias(usuario) {
    return this.get(`/atividadesUltimosTrintaDiasUsuario/${usuario}`);
  }

  consultaAtividadesUltimosTrintaDiasConcluidas(usuario) {
    return this.get(`/atividadesUltimosTrintaDiasConcluidasUsuario/${usuario}`);
  }

  consultaAtividadesUltimosTrintaDiasConcluidasAtrasadas(usuario) {
    return this.get(`/atividadesUltimosTrintaDiasConcluidasAtrasadasUsuario/${usuario}`);
  }

  consultaAtividadesUltimosTrintaDiasConcluidasNoPrazo(usuario) {
    return this.get(`/atividadesUltimosTrintaDiasConcluidasNoPrazoUsuario/${usuario}`);
  }

  consultaPontuacao(responsavel, interesse, agenda) {
    return this.get(`/pontuacao?responsavel=${responsavel}&interesse=${interesse}&agenda=${agenda}`);
  }

  //Kanban
  consultarKanban(filtro) {

    let params = `/kanban?empresa=${filtro.empresa}&grupo=${filtro.grupo}&usuario=${filtro.usuario}&status=${filtro.status}&limite=${filtro.limite}`;
    if(filtro.dataInicial !== null && filtro.dataFinal !== null) {
      params = `${params}&dataInicial=${filtro.dataInicial}&dataFinal=${filtro.dataFinal}`;
    }
    // console.log(params);

    return this.get(params);
  }

  alterarKanban(atividade, codigoUsuario){
    return this.put(`/alterarKanban?status=${atividade.status}&codigoAtividade=${atividade.codigo}&codigoUsuario=${codigoUsuario}`)
  }
  alterarKanbanOrdem(codigo, destino, listAtividades){
    return this.put(`/alterarKanban/ordem?codigoAtividadeShort=${Number(codigo)}&destino=${destino}`, listAtividades)
  }
//suporte
    cadastrarAtendimento(atividade) {
        return this.post('/cadastrarAtendimento',atividade);
    }
    cadastrarSuporte(atividade){
      return this.post('/suporte/cadastrar',atividade);
    }
    consultarUsuarioAtendimentos(codigo) {
        return this.get(`/usuarioAtendimentos/${codigo}`);
    }
}
