import {Dialog} from "primereact/dialog";
import React, {useEffect, useState} from "react";
import {MultiSelect} from "primereact/multiselect";
import {Button} from "primereact/button";
import {strings} from "../../utils/strings";
import ObjetoAcaoService from "../../app/service/ObjetoAcaoService";
import {Dropdown} from "primereact/dropdown";


export const AlterarObjetosSelectDialog = ({
                                               open,
                                               close,
                                               objetosOptions,
                                               alterar,
                                               objetosList,
                                               objetoPai
                                           }) => {
    const [objeto, setObjeto] = useState(null)
    const [subObjetos, setSubObjetos] = useState([])

    // const {usuarioAutenticado, setLoading} = useContext(AuthContext)
    const objetoAcaoService = new ObjetoAcaoService();

    const [subObjetosOptions, setSubObjetoOptions] = useState([])

    function handleAlterarObjetos() {
            let newArray = [...objetosList]
        if (objeto) {
            let oldSubs = objetosList.filter(obj => obj.objetoPai !== null && obj.objetoPai.codigo === objetoPai.codigo)


            if (subObjetos && subObjetos.length > oldSubs) {
                let newArraySemSubs = newArray.filter(obj => (obj.objetoPai === null || (obj.objetoPai.codigo !== objetoPai.codigo)))
                newArray = newArraySemSubs.concat(subObjetos)
            } else {
                if (subObjetos === null) {
                    newArray = newArray.filter(obj => (obj.objetoPai === null || (obj.objetoPai.codigo !== objetoPai.codigo)))

                } else {

                    let newArraySemSubs = newArray.filter(obj => (obj.objetoPai === null || (obj.objetoPai.codigo !== objetoPai.codigo)))
                    newArray = newArraySemSubs.concat(subObjetos)
                }
            }
            alterar(newArray)

        } else {
            newArray = newArray.filter(item=> item.codigo !== objetoPai.codigo)
            newArray = newArray.filter(item=> !(item.objetoPai !== null && item.objetoPai.codigo === objetoPai.codigo))
            alterar(newArray)
        }
        close()
    }

    function footer() {
        return (
            <div className="row-etrium gap-1">
                <Button label="Confirmar"
                        className={strings.buttonPrimaryStyle}
                        onClick={handleAlterarObjetos}
                />
            </div>
        );
    }

    const onObjetoSelected = (e) => {
        const {value} = e.target
        setObjeto(value)
        if (value) {
            getSubsObjetosOptions(value.codigo)
        } else {
            setSubObjetos([])
        }

    }

    function getSubsObjetosOptions(codigo) {
        objetoAcaoService.listarSubObjetosAcao(codigo).then((res) => {
            if (res.data && res.data.length > 0) {
                setSubObjetoOptions(res.data)
            }
        })
    }

    function ladingSubObjetos() {
        if (objetoPai) {
            setObjeto(objetoPai)
            getSubsObjetosOptions(objetoPai.codigo)
            let subs = objetosList.filter(obj => obj.objetoPai !== null && obj.objetoPai.codigo === objetoPai.codigo)
            setSubObjetos(subs)
        }
    }

    useEffect(ladingSubObjetos, [])

    return (<>
        <Dialog header="Selecionar Objetos"
                visible={open}
                style={{width: '40vw'}}
                footer={footer}
                onHide={close}>

            <div className="row">
                <div className="width-100">
                    <div className="form-group">
                        <label>Objetos de ação</label>
                        <Dropdown inputId="uniqueselection"
                                  value={objeto}
                                  options={[objetoPai] || objetosOptions}
                                  style={{
                                      width: '100%',
                                      height: '37px',
                                      padding_top: '1px'
                                  }}
                                  onChange={onObjetoSelected}
                                  filter showClear filterBy="nome"
                                  optionLabel="nome"/>
                    </div>
                    <div className="form-group">
                        <label>Sub objetos de ação</label>
                        <MultiSelect inputId="multiselect"
                                     value={subObjetos}
                                     options={subObjetosOptions}
                                     style={{
                                         width: '100%',
                                         height: '37px',
                                         padding_top: '1px'
                                     }}
                                     onChange={e => setSubObjetos(e.target.value)}
                                     filter showClear filterBy="nome"
                                     optionLabel="nome"/>
                    </div>
                </div>
            </div>
        </Dialog>
    </>)

}
