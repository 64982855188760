import ApiService from '../apiservice';

export default class PaymentPlanosService extends ApiService {
  constructor() {
    super('/pagamentos');
  }

  criarPagamento(pagamentoRequest, cdEmpresa, tipoPlano) {
    return this.post(`/criar/${cdEmpresa}/${tipoPlano}`, pagamentoRequest);
  }

  desativarRecurrence(cdempresa) {
    return this.delete(`/desativar/${cdempresa}`);
  }

  getTransaction(checkoutCieloOrderNumber) {
    return this.get(`/${checkoutCieloOrderNumber}`);
  }

  buscarTransacoesRecentesPorCdEmpresa(cdempresa) {
    return this.get(`/recentes/${cdempresa}`);
  }
}
