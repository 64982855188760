import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {ButtonEtrium} from "../../componentes/ButtonEtrium";
import AtividadeService from "../../app/service/AtividadeService";
import {IsNullOrEmpty} from "../../utils/IsNullOrEmpy";
import React, {useRef, useState} from "react";
import {useHistory, withRouter} from "react-router-dom";

import ReCAPTCHA from "react-google-recaptcha";
import {Toast} from "primereact/toast";

import '../css/util.css'
import './login.css'
import './usuarios.css'
import Logo from '../../img/etrium-logo1.svg';
import {Loading} from "../../componentes/Loading";
export const Suporte = ()=>{
    const toast = useRef(null);

    const [ captcha,setCaptcha]= useState('')
    const [titulo, setTitulo] = useState('')
    const [descricao, setDescricao] = useState('')
    const [email,setEmail] = useState('')
    const [loading,setLoading] = useState(false)

    //service
    const atividadeService = new AtividadeService()

    const navigation = useHistory();

    function voltar(){
        navigation.push('/');
    }
    const cadastrarAtividade = ()=>{
        if(IsNullOrEmpty(email)){
            toast.current.show({ severity: 'error', summary: 'Atividades', detail: 'Informe o seu email', life: 4000 });
            return
        }
        if(IsNullOrEmpty(titulo)){
            toast.current.show({ severity: 'error', summary: 'Atividades', detail: 'Informe o título da atividade', life: 4000 });
            return
        }
        if(IsNullOrEmpty(descricao)){
            toast.current.show({ severity: 'error', summary: 'Atividades', detail: 'Informe a descrição da atividade', life: 4000 });
            return
        }

        if (IsNullOrEmpty(captcha)) {
            toast.current.show({
                severity: 'error',
                summary: 'Atividades',
                detail: 'Verificação não foi resolvida',
                life: 4000
            });
            return false;
        }
        
        setLoading(true)
        const novaAtividade = {
                titulo: titulo,
                descricao: descricao,
                email: email,
                captcha: captcha
                // Aguardando Inicio
        }

        atividadeService.cadastrarSuporte(novaAtividade)
            .then(()=>{
                toast.current.show({
                    severity: 'success',
                    summary: 'Suporte',
                    detail: 'Mensagem enviada',
                    life: 4000
                });
                setTitulo('')
                setDescricao('')
                setEmail('')
                setTimeout(()=>{
                    voltar()
                }, 3000)
            })
            .catch((error)=>{
                const detail = error.response.data.detail
                toast.current.show({ severity: 'error', summary: 'Suporte', detail: `${detail}`, life: 4000 });

            })
            .finally(()=>{
                setLoading(false)
            })

    }

    return(

            <>
                <Loading loading={loading}/>
                <Toast ref={toast} />
                <div className="limiter">
                    <div className="flex flex-column align-items-center justify-content-center w-full p-4">
                        <div className="logo-container">
                            <img className="logo" src={Logo} alt='Logo Etrium'></img>
                        </div>
                        <form className="">
                            <div>
                                <h1 className="text-login-h1">Suporte</h1>
                                <span className="txt2 ">Precisa de ajuda?</span>
                                <p className="txt2 font-size-13">Envie-nos sua mensagem e nossa equipe de suporte entrará em contato!</p>
                            </div>
                            {/*<div>*/}
                            {/*    <h3 className="card-title">*/}
                            {/*        <span> Suporte </span>*/}
                            {/*    </h3>*/}
                            {/*</div>*/}
                            <div>
                                <div className="card-form-cad">

                                    <div className="row-ertium">
                                        <div className="input-group mb-3">
                                            <label>Email<span className="obrigatorioAsterisco"> *</span></label>
                                            <InputText id="email"
                                                       style={{width: '100%', height: '37px'}}
                                                       type="text"
                                                       autoComplete="nope"
                                                       maxLength={100}
                                                       value={email}
                                                       onChange={e => setEmail(e.target.value)}
                                                       className="p-inputtext-sm p-d-block p-mb-1"
                                                       placeholder="Email"/>
                                        </div>
                                    </div>

                                    <div className="row-etrium">
                                        <div className="input-group mb-3">
                                            <label>Titulo<span
                                                className="obrigatorioAsterisco"> *</span></label>
                                            <InputText id="titulo"
                                                       style={{width: '100%', height: '37px'}}
                                                       type="text"
                                                       maxLength={100}
                                                       autoComplete="nope"
                                                       value={titulo}
                                                       onChange={e => setTitulo(e.target.value)}
                                                       className="p-inputtext-sm p-d-block p-mb-1"
                                                       placeholder="Título"/>
                                        </div>
                                    </div>

                                    <div className="row-etrium">
                                        <div className="input-group mb-3">
                                            <label>Descrição<span
                                                className="obrigatorioAsterisco"> *</span></label>
                                            <InputTextarea id="descricao"
                                                           type="text"
                                                           maxLength={500}
                                                           autoComplete="nope"
                                                           style={{width: '100%'}}
                                                           value={descricao}
                                                           onChange={e => setDescricao(e.target.value)}
                                                           className="p-inputtext-sm p-d-block p-mb-1"
                                                           rows={6} cols={30}
                                                           placeholder="Descrição"/>
                                        </div>
                                    </div>
                                    <div className="row-etrium mb-3">
                                        <ReCAPTCHA
                                            sitekey={process.env.REACT_APP_SITE_KEY}
                                            name='captcha'
                                            onChange={(value) => setCaptcha(value)}/>
                                    </div>
                                    <div className="row-etrium gap-1">

                                        <ButtonEtrium label="Cadastrar"
                                                      onClick={cadastrarAtividade}
                                                      buttonType={'primary'}/>

                                        <ButtonEtrium label="Voltar"
                                                      buttonType={'secondary'}
                                                      onClick={voltar}
                                        />
                                    </div>

                                    {/*fim inputs*/}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        // </Dialog>
    );
}

export default withRouter(Suporte)
