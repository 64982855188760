import React, {useContext, useEffect, useState} from 'react';
import imagemRelatorio from '../seguranca/img/etrium-logo1.svg';
import {AuthContext} from '../../main/ProvedorAutenticacao';
import {DataView} from "primereact/dataview";
import {InputTextarea} from "primereact/inputtextarea";

export const ComponentToPrintHistoricoAtendimento = React.forwardRef(({
                                                                          historicos,
                                                                          selectProcesso,
                                                                          grupo,
                                                                          assunto, pessoa,

                                                                          dataAtendimento
                                                                      }, ref) => {
    const [nomeUsuario, setNomeUsuario] = useState('');
    const [dataImpressao, setDataImpressao] = useState('');
    const [horaImpressao, setHoraImpressao] = useState('');

    const usuarioLogado = useContext(AuthContext).usuarioAutenticado;

    const template = (data) => {
        return (
            <div>
                <div className="row gap-3 my-3">
                    <InputTextarea id="registro"
                                   type="text"
                                   style={{display: "flex", flex: 1}}
                                   value={data.historico}
                                   disabled="true"
                                   className="p-inputtext-sm p-d-block p-mb-1"
                                   rows={4} cols={30}/>
                    <div className="flex-col center-items">
                        <div>
                        <span style={{
                            color: '#4F88FF',
                            fontStyle: 'italic'
                        }}>{data.usuario.nome}</span>
                        <p>{data.dtAtendimento}
                        </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    useEffect(() => {
        setNomeUsuario(usuarioLogado.nome);
        const dayName = ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'];
        const monName = [
            'janeiro',
            'fevereiro',
            'março',
            'abril',
            'Maio',
            'junho',
            'agosto',
            'outubro',
            'novembro',
            'dezembro',
        ];

        const data = new Date();
        const dataCompleta =
            dayName[data.getDay()] +
            ', ' +
            data.getDate() +
            ' de ' +
            monName[data.getMonth()] +
            ' de ' +
            data.getFullYear();
        const hora =
            data.getHours() + ':' + data.getMinutes() + ':' + data.getSeconds();

        setDataImpressao(dataCompleta);
        setHoraImpressao(hora);
    }, [usuarioLogado]);
    return (
        <div ref={ref}>
            <br></br>
            <div style={{margin: '38px'}}>
                <div>
                    <img src={imagemRelatorio} width="200" height="140" alt={'Logo do sistema'}/>
                </div>
                <div>
                    Impresso por: {nomeUsuario}&nbsp;
                    em: {dataImpressao}&nbsp;
                    {horaImpressao}
                </div>
                <hr></hr>
            </div>

            <div style={{margin: '46px'}}>
                <span style={{fontSize: '20px', fontWeight: 'bold'}}>{"Assunto: "+assunto}</span>
                <br></br>
                <br></br>
                <span style={{fontWeight: 'bold'}}>{"Pessoa: "+pessoa}</span>
                <br></br>
                <span style={{fontWeight: 'bold'}}>{"Processo: " + selectProcesso}</span>
                <br></br>
                <span style={{fontWeight: 'bold'}}>{"Grupo de Assunto: " + grupo}</span>
                <br></br>
                <text style={{color: "black"}}>Início:&nbsp;
                    {dataAtendimento}</text>
                <div className="dataview-demo">
                    <DataView value={historicos}
                              layout={"list"}
                              emptyMessage=" "
                              itemTemplate={template}/>
                </div>
            </div>

        </div>
    );
});

