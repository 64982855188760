import React, {useRef, useState} from 'react'

import {Loading} from "../../componentes/Loading";
import {Toast} from 'primereact/toast';
import Navbar from '../../componentes/Navbar';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {CadastrarSuporte} from "./CadastrarSuporte";
import {MeusSuportes} from "./MeusSuportes";
import {ButtonSm} from "../../componentes/ButtonSm";
import {strings} from "../../utils/strings";

export function AtividadeParaSuporte() {

    const [consultar, setConsultar] = useState('')
    //context
    // const {usuarioAutenticado} = useContext(AuthContext)

    const [novoSuporte, setNovoSuporte] = useState(false)

    const navigation = useHistory()
    const params = useRouteMatch().params
    const [loading, setLoading] = useState(false)
    const toastRef = useRef(null)
    function voltar() {
        navigation.goBack()
    }
    function fecharModalSuporte() {
        setNovoSuporte(false)
    }

    function reload(){
        window.location.reload()
    }


    return (
            <>
                <Navbar/>
                <Toast ref={toastRef}/>
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <section className="content-header">
                            <div className='card card-primary card-outline gap-1'>
                                <div className='card-header'>
                                    <h3 className="card-title row gap-1">
                                        <i className="fas fa-users"></i>
                                        <span>Suporte</span>
                                    </h3>
                                </div>
                                <div className='flex-row'>
                                    <div className="col-md-7">
                            <span className="input-group">
                                  <ButtonSm type="button"
                                            className={strings.buttonPrimarySm}
                                            onClick={() => setNovoSuporte(true)}
                                  >
                              <i className="fas fa-plus"></i> Cadastrar suporte</ButtonSm>
                              <form className="flex-1 space-etrium">
                                  <input type="text" className="form-control"
                                         placeholder="Consultar suporte"
                                         value={consultar}
                                         onChange={e => setConsultar(e.target.value)}
                                  />

                                  <button className="input-group-text"
                                          type={'submit'}
                                  >
                                  <i className="fas fa-search"></i>
                                  </button>

                              </form>
                            </span>
                                    </div>
                                </div>
                                <Loading open={loading}/>


                                {novoSuporte &&
                                        <CadastrarSuporte
                                                isOpen={novoSuporte}
                                                toast={toastRef.current}
                                                setLoading={setLoading}
                                                onClose={fecharModalSuporte}
                                                refresh={reload}
                                        />}

                                        <MeusSuportes
                                                filtro={consultar}
                                                toast={toastRef.current}
                                                setLoading={setLoading}
                                                codigoAtividade={params.codigoAtividade}
                                                voltar={voltar}
                                        />


                            </div>

                        </section>
                    </div>
                </div>
            </>

    );
}
