import axios from 'axios'
import dotenv from 'dotenv';
dotenv.config();

const baseUrl = process.env.REACT_APP_BASE_URL;


axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
export const httpClient = axios.create({
  baseURL: baseUrl,
  withCredentials: false
})

export const httpClient2 = axios.create({
  baseURL: baseUrl,
  withCredentials: false,
  responseType: 'blob'
})

class ApiService {
  constructor(apiUrl) {
    this.apiUrl = apiUrl;
  }

  static registrarToken(token) {
    if (token) {
      httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`
      httpClient2.defaults.headers.common['Authorization'] = `Bearer ${token}`
    }
  }

  post(url, objeto) {
    const requestUrl = `${this.apiUrl}${url}`
    return httpClient.post(requestUrl, objeto);
  }

  put(url, objeto) {
    const requestUrl = `${this.apiUrl}${url}`
    return httpClient.put(requestUrl, objeto);
  }

  delete(url) {
    const requestUrl = `${this.apiUrl}${url}`
    return httpClient.delete(requestUrl);
  }

  get(url) {
    const requestUrl = `${this.apiUrl}${url}`
    return httpClient.get(requestUrl);
  }

  get2(url) {
    const requestUrl = `${this.apiUrl}${url}`
    return httpClient2.get(requestUrl);
  }
}

export default ApiService
