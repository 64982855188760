
// export function exportToCSV(items, fileName) {
//     const csvContent = "data:text/csv;charset=utf-8,"
//         + items.map(item => Object.values(item).join(',')).join('\n');
//
//     const encodedUri = encodeURI(csvContent);
//     const link = document.createElement("a");
//     link.setAttribute("href", encodedUri);
//     link.setAttribute("download", fileName);
//     document.body.appendChild(link);
//     link.click();
// }
export function exportToCSV(items, fileName, columnNames) {
    const escapeCSVValue = (value) => {
        if (value === null || value === undefined) {
            return ""; // Se o valor for nulo ou indefinido, retornar vazio
        } else {
            return `"${value}"`;
        }
    };

    const csvContent = "data:text/csv;charset=utf-8,"
        + "sep=,\n"
        + columnNames.join(',') + '\n'  // Adiciona o cabeçalho com o nome das colunas
        + items.map(item => columnNames.map(colName => escapeCSVValue(item[colName])).join(',')).join('\n');

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
}

function formatarData(valor) {
    if (valor instanceof Date) {
        const dia = String(valor.getDate()).padStart(2, '0');
        const mes = String(valor.getMonth() + 1).padStart(2, '0'); // Mês começa do zero
        const ano = valor.getFullYear();
        return `${dia}-${mes}-${ano}`;
    }
    return valor;
}
export function concatenarCampos(objeto) {
    const valores = Object.values(objeto)
        .map(value => formatarData(value)) // Formatar campos de data
        .filter(value => value !== null && value !== '');

    if (valores.length === 0) {
        const numeroAleatorio = Math.floor(Math.random() * 1000) + 1;
        return `no filter ${numeroAleatorio}`;
    }

    return valores.join(' - ');
}

export function extractPropertyNames(obj) {
    return Object.keys(obj);
}

// export function concatenarCampos(objeto) {
//     const valores = Object.values(objeto).filter(value => value !== null && value !== '');
//
//     if (valores.length === 0) {
//         const numeroAleatorio = Math.floor(Math.random() * 1000) + 1;
//         return `no filter ${numeroAleatorio}`;
//     }
//
//     return valores.join(' - ');
// }
