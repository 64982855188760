import React from 'react'

import AuthService from '../app/service/AuthService'
import jwt from 'jsonwebtoken'
import {Loading} from "../componentes/Loading";
import EmpresaPlanosService from "../app/service/EmpresaPlanosService";

export const AuthContext = React.createContext()
export const AuthConsumer = AuthContext.Consumer;

const AuthProvider = AuthContext.Provider;

class ProvedorAutenticacao extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            usuarioAutenticado: null,
            isAutenticado: false,
            isLoading: true,
            loading: false,
            expirou: false,
            selectPlano: false
        }
        this.empresaPlanoService = new EmpresaPlanosService()

    }



    iniciarSessao = (tokenDTO) => {
        const token = tokenDTO.token
        const claims = jwt.decode(token)
        const usuario = {
            codigo: claims.codigo,
            nome: claims.nome,
            perfil: claims.perfil,
            empresa: claims.empresa
        }
        this.setState({ isAutenticado: true, usuarioAutenticado: usuario })
        AuthService.logar(usuario, token);

    }

    encerrarSessao = () => {
        AuthService.removerUsuarioAutenticado();
        this.setState({ isAutenticado: false, usuarioAutenticado: null })
    }

    async componentDidMount() {
        const isAutenticado = AuthService.isUsuarioAutenticado()
        if (isAutenticado) {
            const usuario = await AuthService.refreshSession()
            this.setState({
                isAutenticado: true,
                usuarioAutenticado: usuario,
                isLoading: false
            })
            this.empresaExpirou()
            this.empresaNaoTemPlanoSelecionado()
        } else {
            this.setState(previousState => {
                return {
                    ...previousState,
                    isLoading: false
                }
            })
        }
    }

    setLoading=(bol)=>{
        this.setState({loading: bol})
    }

    empresaExpirou(){
        if(this.state.usuarioAutenticado){
        this.empresaPlanoService.getEmpresaPlanoExpirou(this.state.usuarioAutenticado.empresa.codigo).then((res)=>
        {
            this.setState({expirou: res.data})
        })
        }
    }
    empresaNaoTemPlanoSelecionado(){
        const {usuarioAutenticado} = this.state
        if(usuarioAutenticado){
            this.empresaPlanoService.getEmpresaPlanoHasntPlanSelected(usuarioAutenticado.empresa.codigo).then((res)=>
            {
                this.setState({selectPlano: res.data})
            })
        }
    }

    render() {

        if (this.state.isLoading) {
            return null;
        }

        const contexto = {
            usuarioAutenticado: this.state.usuarioAutenticado,
            isAutenticado: this.state.isAutenticado,
            setLoading: this.setLoading,
            iniciarSessao: this.iniciarSessao,
            encerrarSessao: this.encerrarSessao,
            expirou: this.state.expirou,
            selectPlano: this.state.selectPlano
        }

        return (
            <AuthProvider value={contexto} >
                <Loading open={this.state.loading}/>
                {this.props.children}
            </AuthProvider>
        )
    }
}

export default ProvedorAutenticacao;
