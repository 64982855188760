export const formatCnj = {

    removeDots(string) {
        return string.replace(/\./g, "").replace(/-/g, "");
    },
    formatCnjMascarado(nrCnj) {
        if (nrCnj) {
            let cnjMascarado = nrCnj.substring(0, 7);
            cnjMascarado = cnjMascarado + "-" + nrCnj.substring(7, 9);
            cnjMascarado = cnjMascarado + "." + nrCnj.substring(9, 13);
            cnjMascarado = cnjMascarado + "." + nrCnj.substring(13, 14);
            cnjMascarado = cnjMascarado + "." + nrCnj.substring(14, 16);
            cnjMascarado = cnjMascarado + "." + nrCnj.substring(16, 20);
            return cnjMascarado
        }
        return ""

    },
    formatarNumeroProcesso(number) {
        if(number && number.length > 19){
            let newNumber = this.removeDots(number)
            return this.formatCnjMascarado(newNumber)
        }
        return number
    }
}
