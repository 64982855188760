import ApiService from '../apiservice'

export default class UsuarioGrupoTrabalhoService extends ApiService {

  constructor() {
    super('/usuarioGrupoTrabalho')
  }

  listarUsuarios(grupo) {
    return this.get(`/usuariosPorGrupo/${grupo}`);
  }

  listarGruposPorUsuario(usuario) {
    return this.get(`/gruposPorUsuario/${usuario}`);
  }

  excluirUsuarioGrupo(usuario) {
    return this.delete(`/${usuario}`);
  }

  incluirUsuarioGrupo(usuarioGrupo) {
    return this.post('',usuarioGrupo);
  }

  listarUsuariosAtividade(grupo) {
    return this.get(`/usuariosAtividade/${grupo}`);
  }
}