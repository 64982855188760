import ApiService from '../apiservice'

export default class UploadPessoaService extends ApiService {
  
  constructor() {
    super('/uploadPessoa');
  }

  upload(formData) {
    return this.post('/',formData);
  }

  uploadTemp(formData) {
    return this.post('/temp',formData);
  }
}