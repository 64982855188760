import ApiService from '../apiservice'

export default class AgendaService extends ApiService {
  constructor() {
    super('/agenda')
  }

  consultarAgenda(filtro) {
    return this.get(`/all?usuario=${filtro.usuario}&usuarios=${filtro.usuarios}&grupos=${filtro.grupos}&status=${filtro.status}`);
  }
}