import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import Upload from "../Upload/Index";
import FileList from "../FileList";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {InputText} from "primereact/inputtext";
import React, {useContext, useEffect, useState} from 'react';
import {reject, uniqueId} from "lodash";
import filesize from "filesize";
import {AuthContext} from "../../main/ProvedorAutenticacao";
import ArquivoAtividadeService from "../../app/service/ArquivoAtividadeService";
import {confirmDialog} from "primereact/confirmdialog";
import {strings} from "../../utils/strings";
import Progresso from "../Progresso";


export const AnexarArquivosDialog = ({open, onClose,atividade, toast})=>{

    const [upLoadFiles, setUpLoadFiles] = useState([]);
    const [arquivos, setArquivos] = useState([]);
    const [arquivo, setArquivo] = useState(null);
    const [modalDescricaoArquivo, setModalDescricaoArquivo] = useState(false);
    const [dsArquivo, setDsArquivo] = useState('');

    //conditions
    const [loading, setLoading] = useState(false);


    //context
    const {usuarioAutenticado} = useContext(AuthContext)

    //service
    const arquivoAtividadeService = new ArquivoAtividadeService()

    const   handleUpload = async files => {
        const upLoadFiles = files.map(file => ({
            file,
            id: uniqueId(),
            name: file.name,
            readableSize: filesize(file.size),
            progress: 0,
            uploaded: false,
            error: false,
            url: file.url
        }))
        setUpLoadFiles(upLoadFiles.concat(upLoadFiles))

        await upLoadFiles.forEach(processar);
    }
    const handleDelete = async id => {
        setUpLoadFiles(upLoadFiles.filter(file => file.id !== id))
    }

    const processar = async (upLoadFile) => {
        const usuarioLogado = usuarioAutenticado;
        const data = new FormData();
        data.append('file', upLoadFile.file);
        data.append('usuario', upLoadFile.file, usuarioLogado.codigo) //Envia código de atividade para salvar os arquivos

        if(atividade){
            data.append('atividade', upLoadFile.file, atividade.codigo) //Envia código de atividade para salvar os arquivos
            await arquivoAtividadeService.incluirArquivo(data)
                .then(() => {
                    listarArquivosAtividade();
                    handleDelete(upLoadFile.id);
                }).catch(error => {
                    toast.show({ severity: 'error', summary: 'Arquivos', detail: `${error.response.data.detail}`, life: 4000 });
                })
        }else{
            await arquivoAtividadeService.incluirArquivoTemp(data)
                .then(() => {
                    listarArquivosTemp();
                    handleDelete(upLoadFile.id);
                }).catch(error => {
                    toast.show({ severity: 'error', summary: 'Arquivos', detail: `${error.response.data.detail}`, life: 4000 });
                })
        }


    }

    const listarArquivosAtividade = async () => {
        await arquivoAtividadeService.listarArquivos(atividade.codigo)
            .then(response => {
                setArquivos(response.data)
            }).catch((error) => {
                toast.show({ severity: 'error', summary: 'Arquivos', detail: `${error.response.data.detail}`, life: 4000 });
            })
    }
    const listarArquivosTemp = async () => {

        await arquivoAtividadeService.listarArquivosTemp(usuarioAutenticado.codigo)
            .then(response => {
                setArquivos(response.data)
            }).catch((error) => {
                toast.show({ severity: 'error', summary: 'Arquivos', detail: `${error.response.data.detail}`, life: 4000 });
            })
    }

   const adicionarDescricao = () => {
        arquivoAtividadeService.adicionarDescricao({
            codigo: arquivo.codigo,
            dsArquivo: dsArquivo
        }).then(async () => {
            toast.show({ severity: 'success', summary: 'Arquivos', detail: 'Descrição adicionada com sucesso', life: 4000 });
            await listarArquivosAtividade();
        }).catch(error => {
            toast.show({ severity: 'error', summary: 'Arquivos', detail: `${error.response.data.detail}`, life: 4000 });
        });

        setModalDescricaoArquivo(false)
    }

    async function consultarArquivoDescricao(arquivo) {
        setArquivo(arquivo)
        setDsArquivo(arquivo.dsArquivo)
        setModalDescricaoArquivo(true)
    }

    async function download(rowData) {
        setLoading(true)
        await arquivoAtividadeService.download(rowData.codigo)
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', rowData.arquivo); //or any other extension
                document.body.appendChild(link);
                link.click();
            }).finally(()=>{setLoading(false)})
    }

    function  confirmaExclusaoArquivo(arquivo) {

        confirmDialog({
            message: 'Deseja realmente excluir este arquivo?',
            header: 'Exclusão de arquivos',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: ()=>excluirArquivo(arquivo.codigo),
            reject: reject
        });
    }

    const excluirArquivo = (codigo) => {
        setLoading(true)
        arquivoAtividadeService.excluirArquivo(codigo)
            .then(async () => {
                toast.show({ severity: 'success', summary: 'Arquivos', detail: 'Arquivo excluído com sucesso', life: 4000 });
                await listarArquivosAtividade();
            }).catch(error => {
            toast.show({ severity: 'error', summary: 'Arquivos', detail: `${error.response.data.detail}`, life: 4000 });
        }).finally(()=>{setLoading(false)})
    }

    async function load(){
        setLoading(true)
        if(atividade){
            await listarArquivosAtividade()

        }else{
            await listarArquivosTemp()
        }
        setLoading(false)
    }

    useEffect(()=>{
       load()
    },[])

    function acoesArquivos(rowData) {
        return (
            <>
                <Button icon="pi pi-comment" className="p-button-rounded p-button-text"
                        tooltip="Adicionar descrição ao arquivo"
                        tooltipOptions={{ position: 'top' }}
                        onClick={() => consultarArquivoDescricao(rowData)}
                        style={{ width: '20px', height: '10px' }} />
                &nbsp;
                <Button icon="pi pi-cloud-download" className="p-button-rounded p-button-text"
                        tooltip="Baixar arquivo"
                        onClick={() => download(rowData)}
                        tooltipOptions={{ position: 'top' }}
                        style={{ width: '20px', height: '10px' }} />
                &nbsp;
                <Button icon="pi pi-trash" className="p-button-rounded p-button-text"
                        tooltip="Excluir arquivo"
                        onClick={() => confirmaExclusaoArquivo(rowData)}
                        tooltipOptions={{ position: 'top' }}
                        style={{ width: '20px', height: '10px' }} />
            </>
        );
    }


   function renderFooterDescricaoArquivo(){
        return (
            <div>

                <Button label="Cancelar"
                        className={strings.buttonSecondaryStyle}
                        onClick={() => setModalDescricaoArquivo(false)} />
                &nbsp;
                <Button label="Alterar"
                        className={strings.buttonPrimaryStyle}
                        onClick={adicionarDescricao} />

            </div>
        );
    }

    return(<>
        {/**Arquivos Anexados */}
        <Dialog header="Arquivos anexados"
                style={{ width: '40vw' }}
                visible={open}
                onHide={() => onClose()}>

            <div className="row">
                <div className="col-sm-12">
                    <div className="form-group">
                        <div className="container">

                            <Upload onUpload={handleUpload} />
                            {!!upLoadFiles && (
                                <FileList files={upLoadFiles} onDelete={handleDelete} />
                            )}

                        </div>
                    </div>
                </div>
            </div>
            {loading ? <Progresso />
                :<DataTable value={arquivos} paginator responsiveLayout="scroll" size="small"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}" rows={10}
                        emptyMessage="Nenhum arquivo">
                <Column field="arquivo" header="Arquivo"/>
                <Column field="dsArquivo" header="Descrição"/>
                <Column body={acoesArquivos} exportable={false} style={{minWidth: '8rem'}} header="Ações"/>
            </DataTable>}
        </Dialog>
        {/**Adicionar descrição ao arquivo */}
        {modalDescricaoArquivo && <Dialog header="Descrição do arquivo"
                 visible={modalDescricaoArquivo}
                 style={{width: '40vw'}}
                 footer={renderFooterDescricaoArquivo()}
                 onHide={() => setModalDescricaoArquivo(false)}>

            <div className="row">
                <div className="col-sm-12">
                    <div className="form-group">
                        <label htmlFor="descricaoArquivo">Descrição</label>
                        <InputText id="descricaoArquivo"
                                   type="text"
                                   style={{width: '100%', height: '37px'}}
                                   value={dsArquivo}
                                   onChange={e => setDsArquivo(e.target.value)}
                                   className="p-inputtext-sm p-d-block p-mb-1"/>
                    </div>
                </div>
            </div>
        </Dialog>}

    </>)
}
