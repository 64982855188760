import ApiService from "../apiservice";

class FilaMovimentacaoService extends ApiService {
    constructor() {
        super("/filaMovimentacao");
    }

    async adicionarProcessoFilaMovimentacao(codigoProcesso, codigoUsuario, credentials, codigoSistemaTribunal ){
       return  this.post(`?codigoProcesso=${codigoProcesso}&codigoUsuario=${codigoUsuario}&credentials=${credentials}&codigoSistemaTribunal=${codigoSistemaTribunal}`, )
    }

    async removerProcessoFilaMovimentacao(codigoProcesso, codigoUsuario ){
        return  this.delete(`?codigoProcesso=${codigoProcesso}&codigoUsuario=${codigoUsuario}`, )
    }
}
export default FilaMovimentacaoService