export function limparTelefone(telefone) {
    // // Remove os parenteses, espaço em branco e traço do numero de telefone
    // const telefoneLimpo = telefone.replace(/\(|\)|\s+|-/g, "");
    // return telefoneLimpo;
    if (telefone === null || telefone === undefined) {
        return telefone
    }
    if (telefone.lenght === 0) {
        return telefone
    }


    // Remove todos os caracteres não numéricos do número de telefone
    const telefoneNumerico = telefone.replace(/\D/g, "");

    // Verifica se o número de telefone contém apenas números
    if (/^\d+$/.test(telefone)) {
        // Se o número de telefone já contém apenas números, retorna o número original
        return telefone;
    } else {
        // Caso contrário, retorna apenas os números do número de telefone
        return telefoneNumerico;
    }
}

export function formatarTelefone(telefone) {
    if (telefone != null && telefone.lenght > 0) {
        // Remove todos os caracteres não numéricos do número de telefone

        const telefoneNumerico = limparTelefone(telefone);

        // Extrai os primeiros 2 dígitos do número de telefone
        const ddd = telefoneNumerico.substring(0, 2);

        // Extrai os próximos 4 dígitos do número de telefone
        const primeiraParte = telefoneNumerico.substring(2, 6);

        // Extrai os últimos 4 dígitos do número de telefone
        const segundaParte = telefoneNumerico.substring(6, 10);

        // Formata o número de telefone no formato (99) 9999-9999
        const telefoneFormatado = `(${ddd}) ${primeiraParte}-${segundaParte}`;

        return telefoneFormatado;
    }
    return telefone
}

export function validarTelefone(telefone) {
    // Remove todos os caracteres não numéricos do número de telefone
    const telefoneNumerico = telefone.replace(/\D/g, "");

    // Verifica se o número de telefone começa com um dígito entre 2 e 9 (o primeiro dígito do DDD)
    if (!/^([2-9]{1})/.test(telefoneNumerico)) {
        return false;
    }

    // Verifica se o segundo dígito do número de telefone é 1, 2, 3, 4, 5 ou 9 (o segundo dígito do DDD)
    if (!/^([2-5,9]{1})/.test(telefoneNumerico.substring(1, 2))) {
        return false;
    }
    // Se todas as verificações passaram, o número de telefone é válido
    return true;
}

