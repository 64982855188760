import React from 'react'
import {withRouter} from 'react-router-dom'

import {Toast} from 'primereact/toast';
import {Dropdown} from 'primereact/dropdown'
import {InputText} from 'primereact/inputtext'
import {InputMask} from 'primereact/inputmask';
import {Button} from 'primereact/button';
import {strings} from '../../utils/strings'

import NavBar from '../../../src/componentes/Navbar'
import UsuarioService from '../../app/service/UsuarioService'
import EstadoService from '../../app/service/EstadoService'
import CidadeService from '../../app/service/CidadeService'
import PerfilService from '../../app/service/PerfilService'
import LocalStorageService from '../../app/service/localStorageService'
import {AuthContext} from '../../main/ProvedorAutenticacao';
import {IsNullOrEmpty} from '../../utils/IsNullOrEmpy';
import {limparTelefone} from '../../utils/RemoveCaracteresTelefone';
import {Loading} from "../../componentes/Loading";
import {SelectButton} from "primereact/selectbutton";

class EditarUsuario extends React.Component {

    constructor(props) {
        super(props)

        this.usuarioService = new UsuarioService();
        this.estadoService = new EstadoService();
        this.cidadeService = new CidadeService();
        this.perfilService = new PerfilService();

        this.options = ['Ativo', 'Inativo'];

        this.state = {
            //Edição de usuário
            codigo: '',
            nome: '',
            email: '',
            cpf: '',
            endereco: '',
            numero: '',
            cep: '',
            bairro: '',
            complemento: '',
            telefone1: '',
            telefone2: '',
            cidades: [],
            estados: [],
            estado: null,
            cidade: null,
            perfis: [],
            perfil: null,
            empresa: null,
            situacao: '',
            ligaSituacao: '',
            loading: true,
            isSituacaoDesativado: false,

        };
    }

    async componentDidMount() {
        this.setState({loading: true})
        const params = this.props.match.params;
        if (params.codigo) {
            await this.consultarUsuario(params.codigo);
            this.listarPerfis();
        }
        this.setState({loading: false})
    }

    listarPerfis = async () => {
        await this.perfilService.listarPerfis()
            .then(response => {
                this.setState({perfis: response.data})
            }).catch(() => {
                this.toast.show({severity: 'error', summary: 'Perfis', detail: 'Erro ao listar perfis', life: 4000});
            })
    }

    consultarUsuario = async (codigo) => {
        await this.usuarioService.consultarUsuario(codigo)
            .then(response => {
                this.setState({codigo: response.data.codigo});
                this.setState({nome: response.data.nome});
                this.setState({email: response.data.email});
                this.setState({cpf: response.data.cpf});
                this.setState({endereco: response.data.endereco});
                this.setState({numero: response.data.numero});
                this.setState({cep: response.data.cep});
                this.setState({bairro: response.data.bairro});
                this.setState({complemento: response.data.complemento});
                this.setState({telefone1: response.data.telefone1});
                this.setState({telefone2: response.data.telefone2});
                this.setState({estado: response.data.cidade.estado});
                this.setState({cidade: response.data.cidade});
                this.setState({perfil: response.data.perfil});
                this.setState({empresa: response.data.empresa});
                if (response.data.situacao === 0) {
                    this.setState({ligaSituacao: 'Ativo'});
                } else {
                    this.setState({ligaSituacao: 'Inativo'});
                    this.setState({isSituacaoDesativado: true});

                }
                this.setState({situacao: this.state.situacao});
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({severity: 'error', summary: 'Usuários', detail: `${this.state.detail}`, life: 4000});
            });

        this.listarEstados();
    }

    alterarUsuario = () => {
        if (this.state.nome === '') {
            this.toast.show({
                severity: 'error',
                summary: 'Cadastro de usuários',
                detail: 'Informe o nome do usuário',
                life: 4000
            });
            return false;
        }

        if (this.state.email === '') {
            this.toast.show({
                severity: 'error',
                summary: 'Cadastro de usuários',
                detail: 'Informe o e-mail do usuário',
                life: 4000
            });
            return false;
        }

        if (this.state.cidade == null) {
            this.toast.show({
                severity: 'error',
                summary: 'Cadastro de usuários',
                detail: 'Informe a cidade',
                life: 4000
            });
            return false;
        }

        if (this.state.perfil == null) {
            this.toast.show({
                severity: 'error',
                summary: 'Cadastro de usuários',
                detail: 'Informe o perfil do usuário',
                life: 4000
            });
            return false;
        }
        if (IsNullOrEmpty(this.state.telefone1)) {
            this.toast.show({
                severity: 'error',
                summary: 'Cadastro de usuários',
                detail: 'Informe o telefone do usuário',
                life: 4000
            });
            return false;
        }
       const {telefone1, telefone2} = this.state
        let newTelefone1 = limparTelefone(telefone1)
        let newTelefone2 = null
        if (!IsNullOrEmpty(this.state.telefone2)) {
            newTelefone2 = limparTelefone(telefone2)
        }


        const usuario = LocalStorageService.obterItem('_usuario_logado');
        this.setState({loading: true})
        this.usuarioService.alterarUsuario({
            codigo: this.state.codigo,
            nome: this.state.nome,
            email: this.state.email,
            cpf: this.state.cpf,
            endereco: this.state.endereco,
            numero: this.state.numero,
            cep: this.state.cep,
            bairro: this.state.bairro,
            complemento: this.state.complemento,
            cidade: this.state.cidade,
            telefone1: newTelefone1,
            telefone2: newTelefone2,
            perfil: this.state.perfil,
            empresa: usuario.empresa,
            situacao: this.state.ligaSituacao === 'Ativo' ? 0 : 1
        }).then(() => {
            this.toast.show({
                severity: 'success',
                summary: 'Edição de usuários',
                detail: 'Usuário alterado com sucesso',
                life: 4000
            });
            setTimeout(() => {
                this.voltar()
            }, 1500)
        }).catch(error => {
            this.setState(error.response.data);
            this.toast.show({
                severity: 'error',
                summary: 'Edição de usuários',
                detail: `${this.state.detail}`,
                life: 4000
            });
        })
        this.setState({loading: false})
    }

    listarEstados = async () => {
        await this.estadoService.listarEstados()
            .then(response => {
                this.setState({estados: response.data});
            });

        this.listarCidades();
    }

    listarCidades = async () => {
        if (this.state.estado) {
            await this.cidadeService.listarCidades(this.state.estado.codigo)
                .then(response => {
                    this.setState({cidades: response.data})
                }).catch(error => {
                    this.setState(error.response.data)
                    this.toast.show({
                        severity: 'error',
                        summary: 'Usuários',
                        detail: `${this.state.detail}`,
                        life: 4000
                    });
                })
        }
    }

    onEstadoChange = (e) => {
        this.setState({estado: e.target.value})
    }

    onCidadeChange = (e) => {
        this.setState({cidade: e.target.value})
    }

    onPerfilChange = (e) => {
        this.setState({perfil: e.target.value})
    }

    voltar = () => {
        this.props.history.push('/usuarios')
    }

    onUsuarioSituacaoChange = (e) => {
        const {value} = e
        this.setState({ligaSituacao: value})
        // if(!this.state.isSituacaoDesativado) {
        //     this.toast.show({severity: 'warn', summary: 'Usuario', detail: 'Não é possivel ativar um usuario', life: 4000});
        // }
        // else{
        // }
    }

    render() {
        return (
            <>
                <NavBar/>
                <Loading open={this.state.loading}/>
                <Toast ref={(el) => this.toast = el}/>
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <section className="content-header">
                            <div className="card card-primary card-outline">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-users"></i>
                                        <span> Consulta de usuários</span>
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <SelectButton value={this.state.ligaSituacao}
                                                              options={this.options}
                                                              onChange={this.onUsuarioSituacaoChange}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Nome<span className="obrigatorioAsterisco"> *</span></label>
                                                <InputText id="nome"
                                                           type="text"
                                                           maxLength={100}
                                                           style={{width: '100%'}}
                                                           value={this.state.nome}
                                                           onChange={e => this.setState({nome: e.target.value})}
                                                           className="p-inputtext-sm p-d-block p-mb-1"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label>E-mail<span className="obrigatorioAsterisco"> *</span></label>
                                                <InputText id="email"
                                                           type="text"
                                                           maxLength={100}
                                                           style={{width: '100%'}} autoComplete="off"
                                                           value={this.state.email}
                                                           onChange={e => this.setState({email: e.target.value})}
                                                           className="p-inputtext-sm p-d-block p-mb-1"/>

                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <div className="form-group">
                                                <label>CPF</label>
                                                <InputMask mask="999.999.999-99"
                                                           id="cpf"
                                                           type="text"
                                                           style={{width: '100%'}}
                                                           value={this.state.cpf}
                                                           onChange={e => this.setState({cpf: e.target.value})}
                                                           className="p-inputtext-sm p-d-block p-mb-1"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Endereço</label>
                                                <InputText id="endereco"
                                                           type="text"
                                                           maxLength={200}
                                                           style={{width: '100%'}}
                                                           autocomplete="off"
                                                           value={this.state.endereco}
                                                           onChange={e => this.setState({endereco: e.target.value})}
                                                           className="p-inputtext-sm p-d-block p-mb-1"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Bairro</label>
                                                <InputText id="bairro"
                                                           type="text"
                                                           maxLength={100}
                                                           style={{width: '100%'}}
                                                           value={this.state.bairro}
                                                           onChange={e => this.setState({bairro: e.target.value})}
                                                           className="p-inputtext-sm p-d-block p-mb-1"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-1">
                                            <div className="form-group">
                                                <label>Número</label>
                                                <InputText id="numero"
                                                           type="text"
                                                           maxLength={10}
                                                           style={{width: '100%'}}
                                                           value={this.state.numero}
                                                           onChange={e => this.setState({numero: e.target.value})}
                                                           className="p-inputtext-sm p-d-block p-mb-1"/>
                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <div className="form-group">
                                                <label>Cep</label>
                                                <InputMask mask="99999-999"
                                                           id="cep"
                                                           type="text"
                                                           maxLength={20}
                                                           style={{width: '100%'}}
                                                           value={this.state.cep}
                                                           onChange={e => this.setState({cep: e.target.value})}
                                                           className="p-inputtext-sm p-d-block p-mb-1"/>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label>Complemento</label>
                                                <InputText id="complemento"
                                                           type="text"
                                                           maxLength={50}
                                                           style={{width: '100%'}}
                                                           value={this.state.complemento}
                                                           onChange={e => this.setState({complemento: e.target.value})}
                                                           className="p-inputtext-sm p-d-block p-mb-1"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {this.state.estado === null ?
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>Estado<span
                                                        className="obrigatorioAsterisco"> *</span></label>
                                                    <Dropdown value={this.state.estado}
                                                              options={this.state.estados}
                                                              optionLabel="estado"
                                                              filter showClear filterBy="estado"
                                                              style={{width: '100%'}}
                                                              onChange={this.onEstadoChange}
                                                              id="estado"
                                                              className="p-inputtext-sm p-d-block p-mb-1"/>
                                                </div>
                                            </div>
                                            :
                                            <>
                                                <div className="col-sm-3">
                                                    <div className="form-group">
                                                        <label>Estado<span
                                                            className="obrigatorioAsterisco"> *</span></label>
                                                        <Dropdown value={this.state.estado}
                                                                  options={this.state.estados}
                                                                  optionLabel="estado"
                                                                  filter showClear filterBy="estado"
                                                                  style={{width: '100%'}}
                                                                  onChange={this.onEstadoChange}
                                                                  id="estado"
                                                                  className="p-inputtext-sm p-d-block p-mb-1"/>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-group">
                                                        <label>Cidade<span
                                                            className="obrigatorioAsterisco"> *</span></label>
                                                        <Dropdown value={this.state.cidade}
                                                                  options={this.state.cidades}
                                                                  optionLabel="nome"
                                                                  filter showClear filterBy="nome"
                                                                  style={{width: '100%'}}
                                                                  onChange={this.onCidadeChange}
                                                                  id="cidade"
                                                                  className="p-inputtext-sm p-d-block p-mb-1"/>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label>Telefone 1<span
                                                    className="obrigatorioAsterisco"> *</span></label>
                                                <InputMask mask="(99)9 9999-9999"
                                                           id="telefone1"
                                                           type="text"
                                                           style={{width: '100%'}}
                                                           value={this.state.telefone1}
                                                           onChange={e => this.setState({telefone1: e.target.value})}
                                                           className="p-inputtext-sm p-d-block p-mb-1"/>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label>Telefone 2</label>
                                                <InputMask mask="(99)9 9999-9999"
                                                           id="telefone2"
                                                           type="text"
                                                           style={{width: '100%'}}
                                                           value={this.state.telefone2}
                                                           onChange={e => this.setState({telefone2: e.target.value})}
                                                           className="p-inputtext-sm p-d-block p-mb-1"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Perfil<span className="obrigatorioAsterisco"> *</span></label>
                                                <Dropdown value={this.state.perfil}
                                                          options={this.state.perfis} style={{width: '100%'}}
                                                          optionLabel="nome"
                                                          filter showClear filterBy="nome"
                                                          onChange={this.onPerfilChange}
                                                          id="estado"
                                                          className="p-inputtext-sm p-d-block p-mb-1"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-etrium gap-1" style={{paddingLeft: '20px'}}>
                                    <Button label="Alterar"
                                            className={strings.buttonPrimaryStyle}
                                            onClick={this.alterarUsuario}/>

                                    <Button label="Voltar"
                                            className={strings.buttonSecondaryStyle}
                                            onClick={this.voltar}/>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </>
        );
    }
}

EditarUsuario.contextType = AuthContext;

export default withRouter(EditarUsuario)
