import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";
import { Toast } from "primereact/toast";
import PaymentPlanosService from "../../app/service/PaymentPlanosService";
import LocalStorageService from "../../app/service/localStorageService";
import { USUARIO_LOGADO } from "../../app/service/AuthService";
import logo from '../../img/Logo-Etrium-semTexto.svg'

const paymentMethodTypes = {
  1: "Cartão de Crédito",
  2: "Boleto Bancário",
  4: "Cartão de Débito",
  5: "QR Code Crédito",
  6: "Pix",
  7: "QRCode Débito",
};

const paymentMethodBrands = {
  1: "Visa",
  2: "Master",
  3: "AmericanExpress",
  4: "Diners",
  5: "Elo",
  6: "Aura",
  7: "JCB",
  8: "Discover",
  9: "HiperCard",
};

const paymentStatusColors = {
  Pendente: "orange",
  Negada: "red",
  Pago: "green",
  Ativa: "green",
  Autorizado: "blue",
  "Desativado pelo usuário": "gray",
  "Desativado por cartão de crédito expirado": "red",
  "Desativado por número máximo de tentativas": "red",
  "Aguardando conciliação": "orange",
  Finalizada: "gray",
  1: "orange", // Pendente
  2: "green", // Pago
  3: "red", // Negada
  4: "gray", // Expirado
  5: "purple", // Cancelado
  6: "blue", // Não Finalizado
  7: "teal", // Autorizado
  8: "black", // Chargeback
};

const statusIcons = {
  1: "pi pi-history", // Pendente
  2: "pi pi-check-circle", // Pago
  3: "pi pi-times-circle", // Negado
  4: "pi pi-ban", // Expirado
  5: "pi pi-times", // Cancelado
  6: "pi pi-question-circle", // Não Finalizado
  7: "pi pi-check-circle", // Autorizado
  8: "pi pi-exclamation-circle", // Chargeback
};

const statusMessages = {
  1: "Pagamento pendente.",
  2: "Pagamento realizado com sucesso!",
  3: "Pagamento negado.",
  4: "Pagamento expirado.",
  5: "Pagamento cancelado.",
  6: "Pagamento não finalizado.",
  7: "Pagamento autorizado.",
  8: "Chargeback no pagamento.",
};


const paymentService = new PaymentPlanosService();

const PaymentSuccess = () => {
  const [transaction, setTransaction] = useState(null);
  const user = LocalStorageService.obterItem(USUARIO_LOGADO);
  const empresa = user.empresa;
  const toast = useRef(null);

  useEffect(() => {
    async function fetchRecentTransaction() {
      try {
        // Adiciona um atraso de 5 segundos
        await new Promise(resolve => setTimeout(resolve, 5000));
    
        // Busca transações recentes
        const response = await paymentService.buscarTransacoesRecentesPorCdEmpresa(empresa.codigo);
        const recentTransactions = response.data;
    
        // Encontra a transação mais recente
        const mostRecentTransaction = recentTransactions.reduce((mostRecent, transaction) => {
          return new Date(mostRecent.created_date) > new Date(transaction.created_date)
            ? mostRecent
            : transaction;
        });
    
        // Atualiza o estado com a transação mais recente
        setTransaction(mostRecentTransaction);
      } catch (error) {
        console.error("Erro ao buscar transação mais recente:", error);
        toast.current.show({
          severity: "error",
          summary: "Erro",
          detail: "Erro ao buscar a transação.",
          life: 4000,
        });
      }
    }
    

    fetchRecentTransaction();
  }, [empresa.codigo, paymentService]);

  const getStatusStyle = (status) => {
    return {
      backgroundColor: paymentStatusColors[status] || "white",
      color: "white",
      padding: "2px 5px",
      borderRadius: "3px",
    };
  };

  const formatDateBR = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return "Pendente";
      case 2:
        return "Pago";
      case 3:
        return "Negado";
      case 4:
        return "Expirado";
      case 5:
        return "Cancelado";
      case 6:
        return "Não Finalizado";
      case 7:
        return "Autorizado";
      case 8:
        return "Chargeback";
      default:
        return "Desconhecido";
    }
  };
  const NavbarWelcome = () => {
    return (
        <div
            className="flex-1 mb-3">
            <div className="navbar navbar-white navbar-light border-blue flex-1">
                <div className="navbar-logo">
                    <a href={"/#/login"} className="text-primary no-effect">
                        <img src={logo} alt="AdminLTE Logo" className="img-logo-home" style={{marginLeft: '30px'}}/>
                    </a>
                </div>
            </div>
        </div>
    );
};

  return (
    <div style={{ width: "100%", height: "100vh", backgroundColor: "#bbc6dd" }}>
      <Toast ref={toast} />
      <NavbarWelcome/>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        
        <Card
          className="p-p-4"
          style={{ width: "450px", marginTop: "10vh", borderRadius: "30px" }}
        >
          {transaction ? (
            <>

            <div style={{textAlign: "center", marginBottom: "80px", marginTop: "20px"}}>
              <i
                className={statusIcons[transaction.transacao.payment_status]}
                style={{
                  fontSize: "4em",
                  color:
                    paymentStatusColors[transaction.transacao.payment_status],
                }}
              ></i>
              <p id="StatusPayment" style={{marginTop: "20px", color:"#808080", fontWeight: 500}}>{statusMessages[transaction.transacao.payment_status]}</p>
              <span style={{
                  fontWeight: "bold", fontSize: "25px"}}>
                          R$ {transaction.transacao.amount}
              </span>

            </div>


            <div className="p-mb-3">
                <div className="p-d-flex p-ai-center">
                  <div>
                    <div className="p-d-flex p-jc-between" style={{ display: "flex",justifyContent: 'space-between' }}>
                      <span style={{ textAlign: 'left',color:"#808080", fontWeight: 500}}>Método de Pagamento </span>
                      <span style={{ fontWeight: "bold", textAlign: 'right' }}>
                      {paymentMethodTypes[transaction.transacao.payment_method_type]}
                      </span>
                    </div>
                    <div className="p-d-flex p-jc-between" style={{ display: "flex",justifyContent: 'space-between', marginTop: "20px"}}>
                      <span style={{ textAlign: 'left',color:"#808080", fontWeight: 500  }}>Data e hora do Pagamento </span>
                      <span style={{ fontWeight: "bold", textAlign: 'right' }}>
                      {formatDateBR(transaction.transacao.created_date)}
                      </span>
                    </div>
                    <div className="p-d-flex p-jc-between" style={{ display: "flex",justifyContent: 'space-between', marginTop: "20px" }}>
                      <span style={{ textAlign: 'left',color:"#808080", fontWeight: 500 }}>Cartão </span>
                      <span style={{ fontWeight: "bold", textAlign: 'right' }}>
                        {transaction.transacao.payment_maskedcreditcard}
                      </span>
                    </div>
                    <div className="p-d-flex p-jc-between" style={{ display: "flex",justifyContent: 'space-between', marginTop: "20px" }}>
                      <span style={{ textAlign: 'left',color:"#808080", fontWeight: 500 }}>Bandeira </span>
                      <span style={{ fontWeight: "bold", textAlign: 'right' }}>
                        {
                          paymentMethodBrands[
                            transaction.transacao.payment_method_brand
                          ]
                        }
                      </span>
                    </div>
                    <div className="p-d-flex p-jc-between" style={{ display: "flex",justifyContent: 'space-between', marginTop: "20px" }}>
                      <span style={{ textAlign: 'left',color:"#808080", fontWeight: 500 }}>Nome </span>
                      <span style={{ fontWeight: "bold", textAlign: 'right' }}>
                        {transaction.transacao.customer_name}
                      </span>
                    </div>
                    <Divider />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <p>Carregando informações da transação...</p>
          )}

{(transaction && [2, 3, 4, 5, 6, 8].includes(transaction.transacao.payment_status)) ||
![1, 7].includes(transaction?.transacao.payment_status) ? (
  <div style={{textAlign: "center"}}>
  <Button
    className="p-button-outlined p-mb-2"
    style={{ marginTop: "20px"}}
  >
    <a
      href="#/home"
      style={{ textDecoration: "none", color: "inherit" }}
    >
      Voltar para Etrium
    </a>
  </Button>
  </div>


) : (
  <div style={{ textAlign: "center", marginTop: "20px" }}>
    <p>Aguarde a finalização da transação, enquanto isso visite nosso site.</p>
    <Button
      className="p-button-outlined p-mb-2"
      style={{ marginTop: "10px" }}
    >
      <a
        href="https://etrium.com.br/"
        target="_self"
        rel="noopener noreferrer"
        style={{ textDecoration: "none", color: "inherit"}}
      >
        Visite o Etrium
      </a>
    </Button>
  </div>
)}

        </Card>
      </div>
    </div>
  );
};

export default PaymentSuccess;
