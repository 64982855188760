import React, { useState } from "react";
import { ObjetosSelectDialog } from "./ObjetosSelectDialog";
import { AlterarObjetosSelectDialog } from "./AlterarObjetosSelectDialog";
import { Button } from "primereact/button";


export const ObjetoAcaoSelecter = ({ objetos, onChange, objetosOptions }) => {

    const [dialogAlterar, setDialogAlterar] = useState(false)
    const [objetoAlterar, setObjetoAlterar] = useState(null)
    const [modalObjetos, setModalObjetos] = useState(false);

    const abrirModalObjetos = () => {
        setModalObjetos(true);
    };
    const fecharModalObjetos = () => {
        setModalObjetos(false);
    };

    function handleAlterar(objeto) {
        setObjetoAlterar(objeto)
        setDialogAlterar(true)
    }

    function handleCloseAlterar() {
        setDialogAlterar(false)
    }

    function addedNewObjetos(obj) {
        let newObjeto = [...objetos, ...obj]
        onChange(newObjeto)
    }

    const objetosFiltereddOptions = objetos ? objetosOptions.filter(item => !objetos.includes(item)) : objetosOptions

    const objetosFilteredOptionsALterar = objetoAlterar ? objetosFiltereddOptions.concat(objetosOptions.filter(item => item.codigo === objetoAlterar.codigo)) : objetosOptions

    const SelectedItemTemplate = () => {

        if (objetos) {
            let newObjetos = objetos.filter(obj => obj.objetoPai === null)
            return (
                <div className="p-inputtext p-inputtext-sm"
                    style={{ minHeight: "37px" }}
                >


                    <div className={"flex-col gap-2"}>

                        {
                            newObjetos.map((objeto, index) => {
                                const subs = objetos.filter(sub => sub.objetoPai !== null && sub.objetoPai.codigo === objeto.codigo)
                                return <div className={"partes cursor hover w-full"} onClick={() => {
                                    handleAlterar(objeto)
                                }} key={index}>
                                    <span>{objeto.nome}</span>
                                    {subs.length > 0 && <span> >> ({subs.map(sub => sub.nome).join(', ')})</span>}
                                    {/*{newObjetos.length !== (index + 1) && <span>, </span>}*/}
                                </div>
                            })
                        }
                    </div>
                </div>
            );
        } else {
            return <div className="p-inputtext p-inputtext-sm"
                style={{ minHeight: "37px" }}
            ></div>;
        }
    }

    return (<>
        <label>Objetos de ação
            <span className={"ml-1"}>
                <Button icon="pi pi-plus-circle"
                    className="p-button-rounded p-button-text"
                    tooltip="Clique para adicionar objetos"
                    tooltipOptions={{ position: 'top' }}
                    data-toggle="modal"
                    onClick={() => abrirModalObjetos()}
                    data-target="#modal-default"
                    id="add-obj-acao"
                    style={{ width: '20px', height: '10px' }} />
            </span>
        </label>
        <SelectedItemTemplate />
        {modalObjetos &&
            <ObjetosSelectDialog
                addedObjetos={addedNewObjetos}
                open={abrirModalObjetos}
                close={fecharModalObjetos}
                objetosOptions={objetosFiltereddOptions}
            />}
        {dialogAlterar &&
            <AlterarObjetosSelectDialog
                alterar={onChange}
                open={dialogAlterar}
                close={handleCloseAlterar}
                objetosOptions={objetosFilteredOptionsALterar}
                objetoPai={objetoAlterar}
                objetosList={objetos}
            />}
    </>
    )
}
