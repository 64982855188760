import React from "react";


export const CardProcess = ({title, onPress, info, id})=>{
    if (onPress){

    return(
        <button
                id={id}
                className={"card-processo card-press"}
                onClick={onPress}
                data-toggle="modal"
                data-target="#modal-default">
            <div>
                <h6 className="textoCard">{title}</h6>
                <text className="text-white">{info}</text>
            </div>
        </button>
    )
    }
    return(
        <button
            className={"card-processo not-press"}
            data-toggle="modal"
            data-target="#modal-default">
            <div>
                <h6 className="textoCard">{title}</h6>
                <text className="text-white">{info}</text>
            </div>
        </button>
    )

}
