import ApiService from '../apiservice'

class EstadoService extends ApiService {

  constructor() {
    super('/estados')
  }

  listarEstados() {
    return this.get('/all');
  }
}

export default EstadoService;