import ApiService from '../apiservice'

class PerfilService extends ApiService {

  constructor() {
    super('/perfil')
  }

  listarPerfis() {
    return this.get('/all')
  }
}

export default PerfilService