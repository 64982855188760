import ApiService from '../apiservice'

class CnpjService extends ApiService {

  constructor() {
    super('https://brasilapi.com.br/api/cnpj/v1/')
  }

  buscarCnpj(cnpj) {
    return this.get(`${cnpj}`);
  }

}

export default CnpjService;
