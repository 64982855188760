import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";
import React, {useContext, useState} from "react";
import {Button} from "primereact/button";
import {strings} from "../../utils/strings";
import {IsNullOrEmpty} from "../../utils/IsNullOrEmpy";
import {AuthContext} from "../../main/ProvedorAutenticacao";
import ObjetoAcaoService from "../../app/service/ObjetoAcaoService";

export const CadastrarObjetoAcaoDialog = ({open, close, toast, objetoPai, refresh}) => {

    const [nome, setNome] = useState("")

    //context
    const {usuarioAutenticado, setLoading} = useContext(AuthContext)
    const objetoAcaoService = new ObjetoAcaoService();
    function cadastrarObjeto(){
        if(IsNullOrEmpty(nome)){
            toast.show({severity:'error', summary: 'Objetos de ação', detail: "Campo nome é obrigatório.", life: 4000});
            return
        }
        let newObjeto = {}
        if(objetoPai){
            newObjeto.objetoPai = objetoPai
        }
        newObjeto.empresa = usuarioAutenticado.empresa
        newObjeto.nome = nome
        setLoading(true)
        objetoAcaoService.cadastrarObjeto(newObjeto).then(() => {
            toast.show({severity:'success', summary: 'Objetos de ação', detail:'Objeto de ação cadastrado com sucesso', life: 4000});
            refresh()
            close()
        }).catch(error => {
            let detail = error.response.detail
            toast.show({severity:'error', summary: 'Objetos de ação', detail:`${detail}`, life: 4000});
        })
        setLoading(false)

    }

    function footer() {
        return (
            <div className="row-etrium gap-1">
                <Button label="Cadastrar"
                        className={strings.buttonPrimaryStyle}
                        onClick={cadastrarObjeto}
                />
            </div>
        );
    }
    return (<>
        <Dialog header="Cadastrar novo objeto de ação"
                visible={open}
                style={{ width: '40vw' }}
                footer={footer}
                onHide={close}>

            <div className="p-fluid">
                <div className="p-field">
                    <label htmlFor="nomeStatus">Nome<span className="obrigatorioAsterisco"> *</span></label>
                    <InputText id="nomeStatus"
                               type="text"
                               maxLength={100}
                               value={nome}
                               onChange={e=>setNome(e.target.value)}
                               className="p-inputtext-sm p-d-block p-mb-1"/>
                </div>
            </div>
        </Dialog>
    </>)
}
