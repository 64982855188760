import React from "react";
import './StylesRelatorioProcesso.css'
import {formatarMoeda} from "../../../utils/formatar-moeda-tela";

export const FolhaRelatorioProcesso = ({processo, displayInf})=>{
// console.log(processo)

    return ( <div class="report-container">
            <div style={{ background: 'none', display: `${displayInf.exibirNumeroProcesso}` }} id={'result-numeroprocesso'}><strong>Número do Processo: </strong>{processo.numeroProcesso}</div>
            <div style={{ background: 'none', display: `${displayInf.exibirNumeroCnj}` }} id={'result-cnj'}><strong>CNJ: </strong>{processo.numeroCnj}</div>
            <div style={{ background: 'none', display: `${displayInf.exibirPasta}` }} id={'result-pasta'}><strong>Pasta: </strong>{processo.pasta}</div>
            <div style={{ background: 'none', display: `${displayInf.exibirCliente}` }} id={'result-pa'}><strong>Polo ativo: </strong>{processo.poloAtivo}</div>
            <div style={{ background: 'none', display: `${displayInf.exibirParteContraria}` }} id={'result-pp'}><strong>Polo passivo: </strong>{processo.poloPassivo}</div>
            <div style={{ background: 'none', display: `${displayInf.exibirAdvogado}` }} id={'result-op'}><strong>Outra(s) parte(s): </strong>{processo.outrasPartes}</div>
            <div style={{ background: 'none', display: `${displayInf.exibirTipoDecisao}` }} id={"result-tipodecisao"}><strong>Tipo decisão: </strong>{processo.tipoDecisao}</div>
            <div style={{ background: 'none', display: `${displayInf.exibirTipoAcao}` }} id={'result-tipoacao'}><strong>Tipo ação: </strong>{processo.tipoAcao}</div>
            <div style={{ background: 'none', display: `${displayInf.exibirAreaAtuacao}` }} id={'result-areaatuacao'}><strong>Área atuação: </strong>{processo.areaAtuacao}</div>
            <div style={{ background: 'none', display: `${displayInf.exibirDataDecisao}` }} id={'result-datadecisao'} ><strong>Data decisão: </strong>{processo.dataUltimaDecisao}</div>
            <div style={{ background: 'none', display: `${displayInf.exibirDataCadastro}` }} id={'result-datacadastro'}><strong>Data cadastro: </strong>{processo.dataCadastro}</div>
            <div style={{ background: 'none', display: `${displayInf.exibirDataSentenca}` }} id={'result-datasentenca'}><strong>Data sentença: </strong>{processo.dataSentenca}</div>
            {/*<div style={{ background: 'none', display: `${displayInf.exibirDataAlteracao}` }} id={'result-dataalteracao'}><strong>Data alteração: </strong>{processo.dataAlteracao}</div>*/}
            <div style={{ background: 'none', display: `${displayInf.exibirDataDistribuicao}` }} id={'result-datadistribuicao'}><strong>Data distribuição: </strong>{processo.dataDistribuicao}</div>
            <div style={{ background: 'none', display: `${displayInf.exibirStatusProcessual}` }} id={'result-statusprocessual'}><strong>Status processual: </strong>{processo.statusProcessual}</div>
            <div style={{ background: 'none', display: `${displayInf.exibirGrupoTrabalho}` }} id={'result-grupotrabalho'}><strong>Grupo trabalho: </strong>{processo.grupoTrabalho}</div>
            <div style={{ background: 'none', display: `${displayInf.exibirCumprimentoLiminar}` }} id={'result-cumprimentoliminar'}><strong>Cumprimento da liminar: </strong>{processo.liminarCumprimento}</div>
            <div style={{ background: 'none', display: `${displayInf.exibirStatusLiminar}` }} id={'result-statusliminar'}><strong>Status da liminar: </strong>{processo.liminarStatus}</div>
            <div style={{ background: 'none', display: `${displayInf.exibirObjetoAcao}` }} id={'result-objetoacao'}><strong>Objeto(s) ação: </strong>{processo.objetos}</div>
            <div style={{ background: 'none', display: `${displayInf.exibirGarantia}` }} id={'result-garantia'}><strong>Garantia: </strong>{processo.garantias}</div>
            <div style={{ background: 'none', display: `${displayInf.exibirValorGarantia}` }} id={'result-valorgarantia'}><strong>Valor Garantia: </strong>{formatarMoeda(processo.valorGarantias)}</div>
            <div style={{ background: 'none', display: `${displayInf.exibirPagamento}` }} id={'result-pagamento'}><strong>Pagamentos: </strong>{processo.pagamentos}</div>
            <div style={{ background: 'none', display: `${displayInf.exibirValorPagamento}` }} id={'result-valorpagamento'}><strong>Valor. pagamentos: </strong>{formatarMoeda(processo.valorPagamentos)}</div>
            <div style={{ background: 'none', display: `${displayInf.exibirCustas}` }} id={'result-custas'}><strong>Custas: </strong>{processo.custas}</div>
            <div style={{ background: 'none', display: `${displayInf.exibirValorCustas}` }} id={'result-valorcustas'}><strong>Valor. custas: </strong>{formatarMoeda(processo.valorCustas)}</div>
            <div style={{ background: 'none', display: `${displayInf.exibirPush}` }} id={'result-push'}><strong>Push: </strong>{processo.snPush}</div>
            <div style={{ background: 'none', display: `${displayInf.exibirPushEmail}` }} id={'result-pushemail'}><strong>Push email: </strong>{processo.snEmail}</div>
            <div style={{ background: 'none', display: `${displayInf.exibirPushHistorico}` }} id={'result-historico'}><strong>Push histórico: </strong>{processo.snHistorico}</div>
            <div style={{ background: 'none', display: `${displayInf.exibirAtividade}` }} id={'result-atividade'}><strong>Ultima atividade: </strong>{processo.ultimaAtividade}</div>
            <div style={{ background: 'none', display: `${displayInf.exibirDataUltimaAtividade}` }} id={'result-dataultimaatividade'}><strong>Data ultima atividade: </strong>{processo.dataUltimaAtividade}</div>
            <div style={{ background: 'none', display: `${displayInf.exibirUltimoHistorico}` }} id={'result-ultimohistorico'}><strong>Ultimo histórico: </strong>{processo.ultimoHistorico}</div>
            <div style={{ background: 'none', display: `${displayInf.exibirDataUltimoHistorico}` }} id={'result-dataultimohistorico'}><strong>Data ultimo histórico: </strong>{processo.dataUltimoHistorico}</div>

        </div>
    )
}
