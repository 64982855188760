import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {strings} from "../../utils/strings";
import React, {useState} from "react";
import {IsNullOrEmpty} from "../../utils/IsNullOrEmpy";
import {InputTextarea} from "primereact/inputtextarea";


export const EnviarMensagemDialog = ({open, close, toast, submit, title, text}) => {
    const [message, setMessage] = useState('')

    const handleSubmit = () => {

        if (IsNullOrEmpty(message) && IsNullOrEmpty(text)) {
            toast.current.show({
                severity: 'error',
                summary: 'Enviar mensagem',
                detail: 'Campo vazio, por favor preencha o campo antes do envio.',
                life: 4000
            });
            return
        }
        submit(message)


    };
    const handleChange = (event) => {
        setMessage(event.target.value)
    };

    function renderFooter() {
        return (
            <div className="flex-row justify-content-start">
                <Button label="Enviar"
                        id={"btn-enviar"}
                        className={strings.buttonPrimaryStyle}
                        onClick={handleSubmit}/>
            </div>
        );
    }

    return (
        <Dialog header={title || "Mensagem"}
                visible={open}
            style={{width: '40vw'}}
                footer={renderFooter()}
                onHide={close}>
            <div className="col-sm-12">
                {text &&
                <label className={"mb-4"}>{text}</label>}
                <InputTextarea
                    value={message}
                    style={{width: '100%'}}
                    onChange={handleChange}
                    rows={4} cols={30}
                    maxLength={500}
                    id="tipop-id-no-internal"
                    className="p-inputtext-sm p-d-block "/>
                <span className={"flex-row justify-content-end"}>{500 - message.length}/500</span>

            </div>

        </Dialog>
    )
}
