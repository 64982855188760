import {ButtonSm} from "../../ButtonSm";
import {strings} from "../../../utils/strings";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Dialog} from "primereact/dialog";
import React, {useContext, useEffect, useState} from "react";
import {Button} from "primereact/button";
import FinanceiroService from "../../../app/service/FinanceiroService";
import {DateFormat} from "../../../utils/DateFormat";
import {AuthContext} from "../../../main/ProvedorAutenticacao";
import TipoDespesaReceitaService from "../../../app/service/TipoDespesaReceita";
import {confirmDialog} from "primereact/confirmdialog";
import {DropdownComponente} from "../../Inputs/DropdownComponente";
import {ConsultaLancamentoDetalhadoDialog} from "./ConsultaLancamentoDetalhadoDialog";
import {Loading} from "../../Loading";
import {formatarMoeda} from "../../../utils/formatar-moeda-tela";
import {IsNotNull, IsNullOrEmpty} from "../../../utils/IsNullOrEmpy";


export const AtrasadosDialog = ({ dataInicial ,open, onClose, toast}) => {

    const [lancamentosLista, setLancamentosLista] = useState([])

    const [tipo, setTipo] = useState(null);
    const [tipos, setTipos] = useState([]);
    const [categoria, setCategoria] = useState(null);
    const [lancamento, setLancamento] = useState(null)
    const [modalConsulta, setModalConsulta] = useState(false);
    const [loading, setLoading] = useState(false);

    const {usuarioAutenticado} = useContext(AuthContext)

    const financeiroService = new FinanceiroService()
    const tipoDespesaReceita = new TipoDespesaReceitaService()

    const categorias = [
        {codigo: 'D', nome: 'Despesa'},
        {codigo: 'R', nome: 'Receita'}
    ];



    const listarAtrasados = async () => {
       setLoading(true)
        let dataFormatadaInicial = '';
        if (dataInicial !== '') {
            dataFormatadaInicial = DateFormat(dataInicial);
        }
        await financeiroService.consultarAtrasados(usuarioAutenticado.empresa.codigo, dataFormatadaInicial)
            .then(response => {
               setLancamentosLista(response.data);
            }).catch(error => {
            let detail = error.response.data.detail
            toast.show({
                severity: 'error',
                summary: 'Financeiro - Atrasados',
                detail: `${detail}`,
                life: 4000
            });
        }).finally(()=>{
        setLoading(false)
        })

    }

    function consultarLancamento(lancamento) {
        setLancamento(lancamento)
        setModalConsulta(true)
    }

    function onCategoriaChange(e){
        setCategoria(e.value)
        setTipo(null)
        listarTiposDespesasReceitas(e.value)
    }

    const listarTiposDespesasReceitas = (tipo) => {
        tipoDespesaReceita.listarTiposDespesasReceitasPorTipo(usuarioAutenticado.empresa.codigo, tipo.codigo)
            .then(response => {
                setTipos(response.data);
            }).catch(error => {
            let detail = error.response.data.detail
            toast.show({
                severity: 'error',
                summary: 'Financeiro - Tipos',
                detail: `${detail}`,
                life: 4000
            });
        })
    }

    const filtrarTipoPeriodo = async () => {

        if(IsNullOrEmpty(categoria)){
            toast.show(
                {
                    severity: 'error',
                    summary: 'Financeiro - Despesas - Filtro',
                    detail: `Selecione a categoria`,
                    life: 4000
                }
            )
            return
        }
        let codigoTipo = 0
        if(IsNotNull(tipo)){
            codigoTipo = tipo.codigo
        }
        const usuarioLogado = usuarioAutenticado

        //Data Inicial
        let dataFormatadaInicial = '';
        if (dataInicial !== '') {
            dataFormatadaInicial = DateFormat(dataInicial);
        }

        financeiroService.filtrarAtrasados(usuarioLogado.empresa.codigo, dataFormatadaInicial,categoria.codigo, codigoTipo).then(response => {
                setLancamentosLista(response.data);
        }).catch(error => {
            let detail = error.response.data.detail
            toast.show({
                severity: 'error',
                summary: 'Financeiro - Despesas - Filtro',
                detail: `${detail}`,
                life: 4000
            });
        })
    }

    const cancelarLancamento = (lancamento) => {
        lancamento.situacao= "C"
        financeiroService.pagarOuCancelarLancamento(lancamento).then(() => {
            toast.show({
                severity: 'success',
                summary: 'Cancelamento de lançamentos',
                detail: 'Lançamento cancelado',
                life: 4000
            });
            listarAtrasados();
        }).catch(error => {
            let detail = error.response.data.detail
            toast.show({
                severity: 'error',
                summary: 'Cancelamento de lançamentos',
                detail: `${detail}`,
                life: 4000
            });
        })

    }

    const confirmaCancelamento = (data) => {
        confirmDialog({
            message: 'Deseja realmente cancelar os lançamentos selecionados ?',
            header: 'Cancelar lançamentos',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: ()=> cancelarLancamento(data),
        });
    }

    function templateVlLancamento(rowData){
        return rowData.categoria.toString().toLowerCase() === "despesa"? `${formatarMoeda(-rowData.vlLancamento)}` : formatarMoeda(rowData.vlLancamento)
    }

    useEffect(()=>{
        listarAtrasados()

        // eslint-disable-next-line
    },[])

    function acoes(rowData) {
        if(rowData.situacao==="A") {
            return (
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text"
                        tooltip="Cancelar"
                        onClick={() => confirmaCancelamento(rowData)}
                        tooltipOptions={{position: 'top'}}/>
            );
        }
    }

    return (
        <Dialog header="Lançamentos atrasados"
                visible={open}
                // style={{width: "70vh"}}
                onHide={() => onClose()}>
            <Loading open={loading}/>
            <div className="row">
                <div className="col-sm-6">
                    <div className="formGroup">
                        <label>Categoria</label>
                        <DropdownComponente value={categoria} style={{width: '100%', height: '32px'}}
                                  options={categorias}
                                  optionLabel="nome"
                                  emptyMessage=" "
                                  onChange={onCategoriaChange}
                                  id="idCategoria"
                                  className="p-inputtext-sm p-d-block p-mb-1"/>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="formGroup">
                        <label>Tipo</label>
                        <DropdownComponente value={tipo}
                                  options={tipos} style={{width: '100%', height: '32px'}}
                                  optionLabel="nome"
                                  filter showClear filterBy="nome"
                                  onChange={(e)=>setTipo(e.value)}
                                  id="tipoDespesa"
                                  className="p-inputtext-sm p-d-block p-mb-1"/>
                    </div>
                </div>
                <div className="col-sm-2">
                    <div className="formGroup">
                        <label>&nbsp;</label>
                        <ButtonSm className={strings.buttonInfoSm} style={{height: "32px"}}
                                  onClick={filtrarTipoPeriodo}>
                            <i className="pi pi-filter"  style={{fontSize: '14px'}}></i>Filtrar</ButtonSm>
                    </div>
                </div>
            </div>
            <br></br>
            <DataTable
                rowClassName="cursor row-hover"
                value={lancamentosLista}
                paginator responsiveLayout="scroll"
                       paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                       currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}"
                       emptyMessage="Nenhum lançamento encontrado"
                       onRowClick={(row)=>consultarLancamento(row.data)}
                       rows={5}>
                <Column field="categoria" header="Categoria"></Column>
                <Column field="tipo.nome" header="Tipo"></Column>
                <Column body={templateVlLancamento} style={{width:"150px"}} header="Valor"></Column>
                <Column field="dtVencimento" header="Vencimento"></Column>
                <Column body={acoes} exportable={false} style={{minWidth: '8rem'}} header="Ações"></Column>
            </DataTable>
            {modalConsulta && <ConsultaLancamentoDetalhadoDialog
                lancamento={lancamento}
                toast={toast}
                open={modalConsulta}
                refresh={listarAtrasados}
                onClose={() => setModalConsulta(false)}/>
            }
        </Dialog>
    )
}
