import ApiService from '../apiservice'

export default class ProcessoImportanciaService extends ApiService {
  constructor() {
    super('/processoImportancia')
  }

  incluirProcesso(processo) {
    return this.post('',processo);
  }

  consultarProcessoImportancia(filtro) {
    return this.get(`?usuario=${filtro.usuario}&processo=${filtro.processo}`);
  }

  excluirProcesso(codigo) {
    return this.delete(`/${codigo}`);
  }
}
