import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {confirmDialog} from "primereact/confirmdialog";
import React, {useEffect, useState} from "react";
import HistoricoProcessoService from "../../../app/service/HistoricoProcessoService";
import {CadastroHistoricoDialog} from "./CadastroHistoricoDialog";
import {Button} from "primereact/button";
import {Loading} from "../../Loading";
import {HeaderPanelFichaProcesso} from "./HeaderPanelFichaProcesso";

export const HistoricoProcesso = ({
                                      processo,
                                      autor,
                                      reu,
                                      nrCnj,
                                      pasta,
                                      nrProcesso,
                                      toast
                                  }) => {

    const [listaHistorico, setListaHistorico] = useState([])
    const [modalCadastro, setModalCadastro] = useState(false)

    const [loading, setLoading] = useState(false);

    //service
    const historicoProcessoService = new HistoricoProcessoService()
    const listarHistoricoProcesso = async () => {
        setLoading(true)
        await historicoProcessoService.listarHistorico(processo.codigo)
            .then(response => {
                setListaHistorico(response.data)
            }).catch(error => {
                let detail = error.response.data.detail
                toast.show({severity: 'error', summary: 'Processos', detail: `${detail}`, life: 4000});
            }).finally(() => {
                setLoading(false)
            })
    }
    const excluirHistoricoProcesso = async (codigo) => {
        historicoProcessoService.excluirHistorico(codigo)
            .then(() => {
                toast.show({
                    severity: 'success',
                    summary: 'Movimentação do processo',
                    detail: 'Movimentação de processo excluído',
                    life: 4000
                });
                listarHistoricoProcesso();
            }).catch(error => {
            let detail = error.response.data.detail
            toast.show({severity: 'error', summary: 'Processos', detail: `${detail}`, life: 4000});
        });

        //add fechar aqui
    }

    function confirmaExclusaoHistorico(historico) {
        confirmDialog({
            message: 'Deseja realmente excluir o movimentação do processo?',
            header: 'Exclusão de movimentação de processo',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: () => excluirHistoricoProcesso(historico.codigo)
        })
    }

    function acoesHistoricoProcesso(rowData) {
        //automatico
        if (rowData.tipoHistorico === "A") {
            return (<>
                </>
            )
        }
        return (
            <div className={"flex-1 justify-content-center align-items-center"}>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-text"
                        tooltip="Excluir movimentação"
                        id={"delete-historico"}
                        onClick={() => confirmaExclusaoHistorico(rowData)}
                        tooltipOptions={{position: 'top'}}
                        style={{width: '20px', height: '10px'}}/>
            </div>
        );
    }

    useEffect(() => {
        listarHistoricoProcesso().finally()
        // eslint-disable-next-line
    }, [])


    function usuarioHistorico(rowData) {
        if (rowData.tipoHistorico === 'A') {
            return (
                <i className="fa fa-solid fa-robot" title="Incluído de forma automática"/>
            )

        } else {
            return (<i className="pi pi-user-plus" title="Incluído manualmente"/>
            )
        }

    }

    function novoHistoricoHeader() {
        return (
            <div className={"w-full justify-content-center align-items-center"}>
                {/*<span></span>*/}
                <Button icon="pi pi-plus-circle" className="p-button-rounded p-button-text"
                        tooltip="Cadastrar movimentação"
                        id={"cadastro-historico"}
                        onClick={() => setModalCadastro(true)}
                        tooltipOptions={{position: 'top'}}/>
            </div>
        )
    }

    return (
        <>
            <div className={"card-body"}>
                <Loading open={loading}/>
                <HeaderPanelFichaProcesso
                    autor={autor}
                    reu={reu}
                    nrCnj={nrCnj}
                    pasta={pasta}
                    nrProcesso={nrProcesso}

                />
                {/*<br/>*/}
                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <DataTable value={listaHistorico} paginator
                                       responsiveLayout="scroll" size="small"
                                       paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                       currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}"
                                       rows={10}
                                       emptyMessage="Nenhuma movimentação">
                                <Column body={usuarioHistorico} exportable={false}
                                        style={{textAlign: 'center', minWidth: '8rem'}}/>
                                <Column field="historico" header="Movimentação"
                                        style={{width: '70rem'}}></Column>
                                <Column field="dataOcorrencia" header="Data ocorrência"></Column>
                                <Column field="dataHistorico" header="Data cadastro"></Column>
                                <Column field="usuario.nome" header="Usuário"></Column>
                                <Column body={acoesHistoricoProcesso} exportable={false} header={novoHistoricoHeader}/>
                            </DataTable>
                            {modalCadastro &&
                                <CadastroHistoricoDialog open={modalCadastro}
                                                         onClose={() => {
                                                             setModalCadastro(false)
                                                         }}
                                                         processo={processo}
                                                         refreshList={listarHistoricoProcesso}
                                                         historicoProcessoService={historicoProcessoService}
                                                         toast={toast}
                                />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
