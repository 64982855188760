export const IsNullOrEmpty = (str) => {
    // return str == null || str.length === 0;
    if (str === null) {
        return true;
    }
    if (str === undefined) {
        return true;
    }
    if (str.toString().trim() === "") {
        return true;
    }

    return str.length === 0;


}
export const IsNotNull = (obj) => {
    // return str == null || str.length === 0;
    if (obj === null) {
        return false;
    }

    return obj !== undefined;
}
