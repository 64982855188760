import {TabPanel, TabView} from "primereact/tabview";
import {DropdownComponente} from "../../Inputs/DropdownComponente";
import {InputNumber} from "primereact/inputnumber";
import {Calendar} from "primereact/calendar";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {Dialog} from "primereact/dialog";
import React, {useContext, useEffect, useState} from "react";
import {DialogPesquisaProcesso} from "../../Atividade/DialogPesquisaProcesso";
import {AuthContext} from "../../../main/ProvedorAutenticacao";
import ProcessoService from "../../../app/service/ProcessoService";
import {InputPesquisaProcesso} from "../../Inputs/InputPesquisaProcesso";
import {Button} from "primereact/button";
import {ArquivosTempCadastrarLancamento} from "./ArquivosTempCadastrarLancamento";
import {strings} from "../../../utils/strings";
import FinanceiroService from "../../../app/service/FinanceiroService";
import {DateFormat} from "../../../utils/DateFormat";
import TipoDespesaReceitaService from "../../../app/service/TipoDespesaReceita";
import {InputTextEtrium} from "../../Inputs/InputTextEtrium";
import {IsNullOrEmpty} from "../../../utils/IsNullOrEmpy";
import ArquivoFinanceiroService from "../../../app/service/ArquivoFinanceiroService";
import {Loading} from "../../Loading";


export const CadastrarLancamentoDialog = ({open, onClose, toast, refresh, hasProcesso}) => {

    //inputs
    const [processo, setProcesso] = useState(null);
    const [pesquisaProcesso, setPesquisaProcesso] = useState('');
    const [categoria, setCategoria] = useState(null);
    const [tipo, setTipo] = useState(null);
    const [tipos, setTipos] = useState([]);
    const [vlLancamento, setVlLancamento] = useState(null);
    const [dtVencimento, setDtVencimento] = useState(null);
    const [optionRecorrencia, setOptionRecorrencia] = useState({codigo: 'S', nome: 'Sem recorrência'});
    const [parcelas, setParcelas] = useState("");
    const [dsLancamento, setDsLancamento] = useState('');
    const [observacao, setObservacao] = useState('');
    //render conditions
    const [openModalProcesso, setOpenModalProcesso] = useState(false)
    //loading
    const [loading, setLoading] = useState(false);
    //search list
    const [processos, setProcessos] = useState(null);
    //context
    const {usuarioAutenticado} = useContext(AuthContext)

    //service
    const processoService = new ProcessoService()
    const financeiroService = new FinanceiroService()
    const tipoDespesaReceita = new TipoDespesaReceitaService()
    const arquivoFinanceiroService = new ArquivoFinanceiroService()


    const categorias = [
        {codigo: 'D', nome: 'Despesa'},
        {codigo: 'R', nome: 'Receita'}
    ];
    const recorrencia = [
        {codigo: 'S', nome: 'Sem recorrência'},
        {codigo: 'R', nome: 'Repetição'},
        {codigo: 'P', nome: 'Parcelada'}
    ]

    const cadastrarLancamento = async () => {
        if (!categoria) {
            showToast('error', 'Cadastro de lançamento', 'Informe a categoria');
            return false;
        }

        if (!tipo) {
            showToast('error', 'Cadastro de lançamento', 'Informe o tipo de despesa ou receita');
            return false;
        }

        if (IsNullOrEmpty(vlLancamento)) {
            showToast('error', 'Cadastro de lançamento', 'Informe o valor');
            return false;
        }

        if (IsNullOrEmpty(dtVencimento)) {
            showToast('error', 'Cadastro de lançamento', 'Informe a data de vencimento');
            return false;
        }
        if (optionRecorrencia === null || optionRecorrencia === undefined) {
            showToast('error', 'Cadastro de lançamento', 'Informe a recorrência');
            return false;
        }

        if (optionRecorrencia.codigo !== 'S') {
            if (parcelas === '' || parcelas === '0') {
                showToast('error', 'Cadastro de lançamento', 'Informe o número de parcelas');
                return false;
            }
        }

        // Data de vencimento
        let dataFormatadaDataVencimento = '';
        if (dtVencimento !== '') {
            dataFormatadaDataVencimento = DateFormat(dtVencimento);
        }
        setLoading(true)
        try {
            if (optionRecorrencia.codigo === 'S') {
                await financeiroService.cadastrarLancamento({
                    tipo,
                    usuario: usuarioAutenticado,
                    vlLancamento,
                    dtVencimento: dataFormatadaDataVencimento,
                    dsLancamento,
                    observacao,
                    processo,
                    empresa: usuarioAutenticado.empresa
                });

                showToast('success', 'Cadastro de lanlamento', 'Lançamento cadastrado com sucesso');

                refresh()
                onClose()
            } else {
                await financeiroService.cadastrarLancamentoParcelas(parcelas, optionRecorrencia.codigo, {
                    tipo,
                    usuario: usuarioAutenticado,
                    vlLancamento,
                    dtVencimento: dataFormatadaDataVencimento,
                    dsLancamento,
                    observacao,
                    processo,
                    empresa: usuarioAutenticado.empresa
                });
                showToast('success', 'Cadastro de lançamento', 'Lançamento cadastrado com sucesso');
                refresh()
                onClose()
            }
        } catch (error) {
            let detail = error
            console.log(error)
            showToast('error', 'Cadastro de lançamento', `${detail}`);
        } finally {
            setLoading(false)
        }
    };

    const showToast = (severity, summary, detail) => {
        toast.show({
            severity,
            summary,
            detail,
            life: 4000
        });
    };

    const renderFooterCadastro = () => {
        return (<div className="row gap-1">
            <Button label="Cadastrar"
                    className={strings.buttonPrimaryStyle}
                    onClick={cadastrarLancamento}/>
        </div>)
    };

    const onChangeCategoria = (e) => {
        setCategoria(e.value)
        setTipo(null)
        listarTiposDespesasReceitas(e.value)
    };

    const listarTiposDespesasReceitas = (tipo) => {
        tipoDespesaReceita.listarTiposDespesasReceitasPorTipo(usuarioAutenticado.empresa.codigo, tipo.codigo)
            .then(response => {
                setTipos(response.data);
            }).catch(error => {
            let detail = error.response.data.detail
            toast.show({
                severity: 'error',
                summary: 'Financeiro - Tipos',
                detail: `${detail}`,
                life: 4000
            });
        })
    }

    const onDespesaChange = (e) => {
        setTipo(e.value);
    };

    const onOptionRecoorencia = (e) => {
        setOptionRecorrencia(e.value);
    };


    const consultarProcessoPorIndice = async () => {
        const indice = {
            indice: pesquisaProcesso,
            empresa: usuarioAutenticado.empresa.codigo,
            usuario: usuarioAutenticado.codigo
        }
        setLoading(true)
        await processoService.consultarProcessoPorIndice(indice)
            .then(response => {
                setProcessos(response.data)
                if (response.data.length > 0) {
                    setOpenModalProcesso(true)
                } else {
                    setPesquisaProcesso('')
                    toast.show({severity: 'error', summary: 'Processo', detail: 'Processo não encontrado', life: 4000});
                }
            }).catch(error => {
                toast.show({
                    severity: 'error',
                    summary: 'Atividades',
                    detail: `${error.response.data.detail}`,
                    life: 4000
                });
            })
        setLoading(false)

    }


    const excluirPesquisaProcesso = () => {
        setProcesso(null)
    }

    const setSelectedProcesso = async (data) => {
        setProcesso(data)
    }



    useEffect(()=>{
        const excluirArquivosTemp = () => {
            arquivoFinanceiroService.excluirArquivosTemp(usuarioAutenticado.codigo)
                .catch(error => {
                    toast.show({severity: 'error', summary: 'Arquivos', detail: `${error}`, life: 4000});
                })
        }
        if(hasProcesso){
            setProcesso(hasProcesso)
        }
        excluirArquivosTemp()
    },[])

    return (
        <>
            <Loading open={loading}/>
            <Dialog header="Cadastrar lançamento"
                    visible={open}
                    style={{width: '50vw'}}
                    footer={renderFooterCadastro}
                    onHide={() => onClose()}>
                <TabView activeIndex={0}>
                    <TabPanel header="Lançamento">
                        <form className={"flex-column gap-1"}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="formGroup">
                                        <label>Categoria <span className="obrigatorioAsterisco"> *</span></label>
                                        <DropdownComponente value={categoria}
                                                            options={categorias}
                                                            optionLabel="nome"
                                                            emptyMessage=" "
                                                            onChange={onChangeCategoria}
                                                            id="idCategoria"
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="formGroup">
                                        <label>Tipo <span className="obrigatorioAsterisco"> *</span></label>
                                        <DropdownComponente value={tipo}
                                                            options={tipos}
                                                            optionLabel="nome"
                                                            emptyMessage=" "
                                                            filter showClear filterBy="nome"
                                                            onChange={onDespesaChange}
                                                            id="tipoDespesa"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={"col-sm-12"}>
                            <div className="row gap-5">
                                <div className="width-50">
                                    <div className="formGroup">
                                        <label>Valor <span className="obrigatorioAsterisco"> *</span></label>
                                        <InputNumber id={"nvValor-input-text"}
                                                     style={{width: '100%', height: '37px'}}
                                                     value={vlLancamento}
                                                     onChange={(e) => setVlLancamento(e.value)}
                                                     mode="decimal" locale="de-BR" minFractionDigits={2}
                                        />
                                    </div>
                                </div>
                                <div className="width-50">
                                    <div className="formGroup">
                                        <label>Vencimento <span
                                            className="obrigatorioAsterisco"> *</span></label>
                                        <Calendar id="mask"
                                                  value={dtVencimento}
                                                  onChange={(e) => setDtVencimento(e.value)}
                                                  locale="es"
                                                  style={{width: '100%', height: '37px'}}
                                                  dateFormat="dd/mm/yy"
                                                  mask="99/99/9999"/>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div className="row">
                                {optionRecorrencia.codigo === 'S' ?
                                    <div className="col-sm-12">
                                        <div className="formGroup">
                                            <label>Recorrência <span
                                                className="obrigatorioAsterisco"> *</span></label>
                                            <Dropdown value={optionRecorrencia}
                                                      options={recorrencia} style={{width: '100%'}}
                                                      optionLabel="nome"
                                                      onChange={onOptionRecoorencia}
                                                      id="recorrencia"
                                                      className="p-inputtext-sm p-d-block p-mb-1"/>
                                        </div>
                                    </div>
                                    :
                                    <>
                                        <div className="col-sm-6">
                                            <div className="formGroup">
                                                <label>Recorrência <span
                                                    className="obrigatorioAsterisco"> *</span></label>
                                                <Dropdown value={optionRecorrencia}
                                                          options={recorrencia} style={{width: '100%'}}
                                                          optionLabel="nome"
                                                          onChange={onOptionRecoorencia}
                                                          id="recorrencia"
                                                          className="p-inputtext-sm p-d-block p-mb-1"/>
                                            </div>
                                        </div>

                                        <div className="col-sm-6">
                                            <div className="formGroup">
                                                <label>Parcelas</label>
                                                <InputText id="parcelas"
                                                           type="text"
                                                           style={{width: '100%', height: '37px'}}
                                                           value={parcelas}
                                                           onChange={e => setParcelas(e.target.value)}
                                                           className="p-inputtext-sm p-d-block p-mb-1"/>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <InputTextEtrium id="dsLancamento"
                                                     label={"Descrição"}
                                                     value={dsLancamento}
                                                     onChange={e => setDsLancamento(e.target.value)}
                                    />

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">

                                    <InputPesquisaProcesso
                                        processo={processo}
                                        consultarProcessoPorIndice={consultarProcessoPorIndice}
                                        excluirPesquisaProcesso={excluirPesquisaProcesso}
                                        value={pesquisaProcesso}
                                        onChange={(e) => setPesquisaProcesso(e.target.value)}
                                    />

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label>Observação</label>
                                        <InputTextarea id="pedidos"
                                                       type="text"
                                                       style={{width: '100%'}}
                                                       value={observacao}
                                                       onChange={e => setObservacao(e.target.value)}
                                                       className="p-inputtext-sm p-d-block p-mb-1"
                                                       rows={2} cols={30}/>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </TabPanel>
                    <TabPanel header="Arquivos">
                        <ArquivosTempCadastrarLancamento
                            toast={toast}
                            arquivoFinanceiroService={arquivoFinanceiroService}
                        />
                    </TabPanel>
                </TabView>
            </Dialog>

            {
                openModalProcesso && <DialogPesquisaProcesso
                    open={openModalProcesso}
                    onClose={() => setOpenModalProcesso(false)}
                    processos={processos}
                    selecionarProcesso={setSelectedProcesso}

                />
            }
        </>
    )
}
