import React, {useContext, useState} from 'react';
import {Dialog} from 'primereact/dialog';
import {InputTextarea} from 'primereact/inputtextarea';
import {AuthContext} from "../../main/ProvedorAutenticacao";
import AtividadeService from "../../app/service/AtividadeService";
import {ButtonEtrium} from "../ButtonEtrium";
import {IsNullOrEmpty} from "../../utils/IsNullOrEmpy";
import {Loading} from "../Loading";

export const IncluirHistoricoDialog = ({open, onClose,toast, listarHistorico, atividade}) => {
    const [dsHistorico, setDsHistorico] = useState('');

    //loading
    const [loading, setLoading] = useState(false);

    //context
    const {usuarioAutenticado} = useContext(AuthContext)

   //service
    const atividadeService = new AtividadeService()

    const incluirHistorico = async () => {
        if (IsNullOrEmpty(dsHistorico)) {
            toast.show({ severity: 'error', summary: 'Atividades', detail: 'Informe o histórico da atividade', life: 4000 });
            return false;
        }

        try {
            setLoading(true)
            await atividadeService.incluirHistorico({
                atividade: atividade,
                dsHistorico: dsHistorico,
                usuario: usuarioAutenticado,
                tpHistorico: 'M'
            });
            listarHistorico();
            setLoading(false)
            toast.show({ severity: 'success', summary: 'Atividades', detail: 'Histórico incluído com sucesso', life: 4000 });
            onClose()
        } catch (error) {
            toast.show({ severity: 'error', summary: 'Atividades', detail: 'Erro ao inserir novo historico', life: 4000 });

        }
        setLoading(false)
    };

    const fecharModalAtividade = () => {
        onClose()
    };

    return (
        <Dialog header="Incluir histórico" autoComplete="nope" style={{ width: '40vw' }}
                visible={open}
                footer={
                    <div className={"flex-row"}>
                        {/*<ButtonEtrium label="Fechar" type={"secondary"}*/}
                        {/*        onClick={() => fecharModalAtividade('displayHistoricoIncluir')} />*/}
                        <ButtonEtrium label="Incluir" id={"incluirHistorico"} buttonType={'primary'}
                                onClick={incluirHistorico} />
                    </div>
                }
                onHide={() => fecharModalAtividade('displayHistoricoIncluir')}>
            <div className="row">
                <div className="col-sm-12">
                    <div className="form-group">
                        <InputTextarea id="historico" type="text" autoComplete="nope" style={{ width: '100%' }}
                                       value={dsHistorico}
                                       maxLength={500}
                                       onChange={e => setDsHistorico(e.target.value)}
                                       className="p-inputtext-sm p-d-block p-mb-1" rows={6} cols={30} />
                        <span className={"flex-row justify-content-end"}>{500 - dsHistorico.length}/500</span>
                    </div>
                </div>
                <Loading open={loading}/>
            </div>
        </Dialog>
    );
};
