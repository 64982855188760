import ApiService from '../apiservice'

export default class ArquivoProcessoService extends ApiService {
  constructor() {
    super('/arquivoProcesso')
  }

  incluirArquivo(formData) {
    return this.post('',formData);
  }

  listarArquivos(processo) {
    return this.get(`/consultarArquivos/${processo}`);
  }

  consultarArquivo(codigo) {
    return this.get(`/consultarArquivo/${codigo}`);
  }

  adicionarDescricao(arquivo) {
    return this.put(`/adicionarDescricao/${arquivo.codigo}`, arquivo);
  }

  excluirArquivo(codigo) {
    return this.delete(`/excluirArquivo/${codigo}`);
  }

  download(codigo) {
    return this.get2(`/download/${codigo}`);
  }
}
