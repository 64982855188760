import React, {useRef} from 'react';
import { useDropzone } from 'react-dropzone';
import './style.css';
import {Toast} from "primereact/toast";

const Upload = ({ onUpload }) => {
const toastRef = useRef(null)
  const renderDragMessage = (isDragActive, isDragReject) => {
    if(!isDragActive) {
      return <div className="uploadMessage">Arraste os arquivos aqui...</div>
    }

    if(isDragReject) {
      return <div className="uploadMessage">Arquivo não suportado</div>
    }

    return <div className="uploadMessage">Solte os arquivos aqui</div>
  }
  const onDrop = (acceptedFiles) => {
  let count= true
    for (let i = 0; i< acceptedFiles.length; i++) {
      const file = acceptedFiles[i];
      if (file && file.size > 10485760) {
        count=false
        toastRef.current.show({
          severity: 'error',
          summary: 'Upload',
          detail: `O arquivo excede 10 MB. Por favor, escolha um arquivo menor.`,
          life: 4000
        })
        // Desativa o aviso após 3 segundos
      }
    }
    if(count){
        onUpload(acceptedFiles); // Carrega o arquivo, pois o tamanho é válido

    }
  };


  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    onDrop,
  });

  return (
      <div >
        <div className="dropContainer" {...getRootProps()}>
          <input  {...getInputProps()} />
          {renderDragMessage(isDragActive, isDragReject)}
        </div>
        <Toast ref={toastRef}/>
      </div>
  );
};

export default Upload;

