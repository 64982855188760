import ApiService from '../apiservice'

export default class StatusProcessual extends ApiService {

  constructor() {
    super('/statusProcessual')
  }

  listarStatusProcessual(empresa) {
    return this.get(`?empresa=${empresa}`);
  }

  cadastrarStatusProcessual(status) {
    return this.post('',status);
  }

  consultarStatusProcessual(codigo) {
    return this.get(`/${codigo}`);
  }

  alterarStatusProcessual(status) {
    return this.put(`/${status.codigo}`, status);
  }

  excluirStatusProcessual(codigo) {
    return this.delete(`/${codigo}`);
  }
}