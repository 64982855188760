import React, {useContext, useEffect, useRef, useState} from "react";
import NavBar from "../../componentes/Navbar";
import {Toast} from "primereact/toast";
import {ButtonSm} from "../../componentes/ButtonSm";
import {strings} from "../../utils/strings";
import {Calendar} from "primereact/calendar";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {AuthContext} from "../../main/ProvedorAutenticacao";
import {DateFormat} from "../../utils/DateFormat";
import {MultiSelect} from "primereact/multiselect";
import AcompanhamentoService from "../../app/service/AcompanhamentoService";
import UsuarioService from "../../app/service/UsuarioService";

const Acompanhamento = () => {

    const [logs, setLogs] = useState([]);
    const [date, setDate] = useState(new Date());

    // const [modulos, setModulos] = useState([]);
    const modulos = [
        {nome: 'Atividades', nomeTela : 'Atividades'},
        {nome: 'Processos', nomeTela: 'Processos'},
        {nome: 'Pessoas', nomeTela: 'Pessoas'},
        {nome: 'Financeiro', nomeTela: 'Financeiro'},
        {nome: 'Parecer', nomeTela: 'Parecer'},
        {nome: 'Relatorios', nomeTela: 'Relatórios'},
        {nome: 'Painel', nomeTela: 'Painel'},
        {nome: 'Administracao', nomeTela: 'Administração'},
        {nome: 'Usuarios', nomeTela: 'Usuários'},
        {nome: 'Suporte', nomeTela: 'Suporte'},
        //todo: Adicionar outros modulos depois de finizado back-end
    ]
    const [modulosSelected, setModulosSelected] = useState(modulos);

    const [usuarios, setUsuarios] = useState([]);
    const [selectUsuarios, setSelectUsuarios] = useState([]);
    const [selectTipoEventos, setSelectTipoEventos] = useState([]);
    // const [tipoEventos, setSelecioneEventos] = useState([]);
    const tipoEventos = [
        {nome: 'SELECT'},
        {nome: 'INSERT'},
        {nome: 'UPDATE'},
        {nome: 'DELETE'},
        //todo: Adicionar outros eventos depois de finizado back-end
    ]
    // const mod
    const acompanhamentoService = new AcompanhamentoService();
    const usuarioService = new UsuarioService();
    // const atividadeService = new AtividadeService();
    // const statusAtividadeService = new StatusAtividadeService();
    const {usuarioAutenticado, setLoading} = useContext(AuthContext);


    const toastRef = useRef(null);

    const consultar = (_date) => {

        const usuarioLogado = usuarioAutenticado;
        //Data inicial
        let dtIni = DateFormat(_date || date);
        let usuarios = '', _modulos = '', tipoLog = '';

        if (modulosSelected) {
            _modulos = modulosSelected.map(m => m.nome);
            _modulos = _modulos.join(',');
        }

        if (selectUsuarios) {
            usuarios = selectUsuarios.map(u => u.codigo);
            usuarios = usuarios.join(',');
        }

        if (selectTipoEventos) {
            tipoLog = selectTipoEventos.map(t => t.nome);
            tipoLog = tipoLog.join(',');
        }

        const filtro = {
            data : dtIni,
            usuarios : usuarios,
            modulo : _modulos,
            tipoLog : tipoLog,
            empresa : usuarioLogado.empresa.codigo
        }
        setLoading(true);

        acompanhamentoService.consultar(filtro)
            .then(response => {
                setLogs(response.data);
            })
            .catch(error => {
                let detail = error.response.data.detail || error.response.data.message;
                toastRef.current.show({ severity: 'error', summary: 'Acomanhamento', detail: detail });
            })
        setLoading(false);
    }

    function getUsuarios() {
        usuarioService.listarUsuariosEmpresa(usuarioAutenticado.empresa.codigo)
            .then(response => {
                setUsuarios(response.data);
            })
            .catch(error => {
                let detail = error.response.data.detail || error.response.data.message;
                toastRef.current.show({ severity: 'error', summary: 'Acomanhamento', detail: detail });
            })
    }

    useEffect(() => {
        consultar()
        getUsuarios()

    }, []);


    return (
        <>
            <NavBar/>
            <Toast ref={toastRef}/>
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-primary card-outline">
                                    <div className="card-header">
                                        <h3 className="card-title">
                                            <i className="fa fa-history "></i>
                                            <span> Acompanhamento</span>
                                        </h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12 flex-1 gap-1">
                                                <MultiSelect id='filtroModulo' inputId="filtroModulo"
                                                             value={modulosSelected}
                                                             options={modulos}
                                                             className="multiselect-kanban-etrium"
                                                             onChange={(e) => setModulosSelected(e.value)}
                                                             filter showClear filterBy="nome"
                                                             optionLabel="nomeTela"/>
                                                <MultiSelect id='filtroUsuario' inputId="filtroUsuario-i"
                                                             value={selectUsuarios}
                                                             options={usuarios}
                                                             className="multiselect-kanban-etrium"
                                                             onChange={(e) => setSelectUsuarios(e.value)}
                                                             filter showClear filterBy="nome"
                                                             optionLabel="nome"/>
                                                <MultiSelect id='filtroEvento' inputId="filtro-evento-i"
                                                             value={selectTipoEventos}
                                                             options={tipoEventos}
                                                             className="multiselect-kanban-etrium"
                                                             onChange={(e) => setSelectTipoEventos(e.value)}
                                                             filter showClear filterBy="nome"
                                                             optionLabel="nome"/>
                                                <Calendar id="mask"
                                                          value={date}
                                                          onChange={(e) => setDate(e.value)}
                                                          locale="es"
                                                          style={{width: '15%', height: '37px'}}
                                                          dateFormat="dd/mm/yy"
                                                          placeholder="Data Inicial"
                                                          mask="99/99/9999"/>
                                                {/*<Calendar id="mask"*/}
                                                {/*          value={dataFinal}*/}
                                                {/*          onChange={(e) => setDataFinal(e.value)}*/}
                                                {/*          locale="es"*/}
                                                {/*          style={{width: '15%', height: '37px'}}*/}
                                                {/*          dateFormat="dd/mm/yy"*/}
                                                {/*          placeholder="Data Final"*/}
                                                {/*          mask="99/99/9999"/>*/}
                                                <ButtonSm style={{height: '37px'}} type="button"
                                                          className={strings.buttonSecondarySm}
                                                          onClick={() => consultar()}>
                                                    <i className="pi pi-filter"></i>Filtrar</ButtonSm>
                                            </div>
                                        </div>
                                        <div className={"mt-3"}>
                                            <DataTable value={logs} paginator responsiveLayout="scroll"
                                                       paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                                       emptyMessage="Nenhum registro encontrado" /*rowsPerPageOptions={[5, 10, 25, 50, 100]}*/
                                                       currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}"
                                                       rows={100}
                                            >
                                                <Column
                                                    field={"id"}
                                                    exportable={false}
                                                    style={{minWidth: '8rem'}}
                                                    header="Codigo"></Column>
                                                <Column
                                                    field={"tplog"}
                                                    exportable={false}
                                                    style={{minWidth: '8rem'}}
                                                    header="Tipo de evento"></Column>
                                                <Column
                                                    field={"descricao"}
                                                    exportable={false}
                                                    style={{minWidth: '8rem'}}
                                                    header="Mensagem"></Column>
                                                <Column
                                                    field={"data"}
                                                    exportable={false}
                                                    style={{minWidth: '8rem'}}
                                                    header="Data"></Column>
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </div>
        </>
    )
}
export default Acompanhamento