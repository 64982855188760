import ApiService from '../apiservice'

export default class TipoGarantiaService extends ApiService {

  constructor() {
    super('/tiposGarantias');
  }

  listarGarantias(empresa) {
    return this.get(`?empresa=${empresa}`);
  }

  cadastrarGarantia(garantia) {
    return this.post('',garantia);
  }

  consultarGarantia(codigo) {
    return this.get(`/${codigo}`);
  }

  alterarGarantia(garantia) {
    return this.put(`/${garantia.codigo}`, garantia);
  }

  excluirGarantia(codigo) {
    return this.delete(`/${codigo}`);
  }
}