import ApiService from '../apiservice'

export default class GarantiaProcessoService extends ApiService {

  constructor() {
    super('/garantiaProcesso');
  }

  listarGarantiasProcesso(codigo) {
    return this.get(`/${codigo}`);
  }

  excluirGarantia(codigo) {
    return this.delete(`/${codigo}`);
  }

  incluirGarantias(garantia) {
    return this.post('',garantia);
  }
}
