import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {ButtonEtrium} from "../../componentes/ButtonEtrium";
import {AuthContext} from "../../main/ProvedorAutenticacao";
import AtividadeService from "../../app/service/AtividadeService";
import {IsNullOrEmpty} from "../../utils/IsNullOrEmpy";
import {useContext, useState} from "react";
import {Dialog} from "primereact/dialog";


export const CadastrarSuporte = ({toast, setLoading, onClose, isOpen, refresh}) => {

    const [titulo, setTitulo] = useState('')
    const [descricao, setDescricao] = useState('')

    const {usuarioAutenticado} = useContext(AuthContext)

    //service
    const atividadeService = new AtividadeService()

    const cadastrarAtividade = () => {
        if (IsNullOrEmpty(titulo)) {
            toast.show({severity: 'error', summary: 'Suporte', detail: 'Informe o título do suporte', life: 4000});
            return
        }
        if (IsNullOrEmpty(descricao)) {
            toast.show({
                severity: 'error',
                summary: 'Suporte',
                detail: 'Informe a descrição do suporte',
                life: 4000
            });
            return
        }

        setLoading(true)
        const novaAtividade = {
            atividade: {
                titulo: titulo,
                processo: null,
                subGrupo: null,
                dtLimite: '',
                dtFatal: null,
                descricao: descricao,
                usuario: usuarioAutenticado,
                tipo: 'T',
                status: {
                    codigo: 2
                } // Aguardando Inicio
            }
        }

        atividadeService.cadastrarAtendimento(novaAtividade)
            .then(() => {
                toast.show({
                    severity: 'success',
                    summary: 'Suporte',
                    detail: 'Suporte cadastrado com sucesso',
                    life: 4000
                });
                setTitulo('')
                setDescricao('')
                refresh()
            })
            .catch((error) => {
                const detail = error.response.data.detail
                toast.show({severity: 'error', summary: 'Suporte', detail: `${detail}`, life: 4000});

            })
            .finally(() => {
                setLoading(false)
            })

    }

    function renderFooter() {
        return <div className="row-etrium justify-content-start gap">
            <ButtonEtrium label="Cadastrar"
                          onClick={cadastrarAtividade}
                          buttonType={'primary'}/>
        </div>
    }

    return (
        <Dialog
            header="Solicitar suporte" autoComplete="nope"
            visible={isOpen}
            style={{width: '40vw'}}
            footer={renderFooter}
            onHide={() => onClose()}
        >
            <div className="flex-1 flex-column gap-1">
                <div className="flex-row flex-column">
                    <label>Título<span className="obrigatorioAsterisco"> *</span></label>
                    <InputText id="titulo"
                               style={{width: '100%', height: '37px'}}
                               type="text"
                               autoComplete="nope"
                               maxLength={255}
                               value={titulo}
                               onChange={e => setTitulo(e.target.value)}
                               className="p-inputtext-sm p-d-block p-mb-1"
                               placeholder="Título"/>
                </div>


                <div className="flex-row flex-column">
                    <label>Descrição<span className="obrigatorioAsterisco"> *</span></label>
                    <InputTextarea id="descricao"
                                   type="text"
                                   maxLength={500}
                                   autoComplete="nope"
                                   style={{width: '100%'}}
                                   value={descricao}
                                   onChange={e => setDescricao(e.target.value)}
                                   className="p-inputtext-sm p-d-block p-mb-1"
                                   rows={6} cols={30}
                                   placeholder="Descrição"/>
                </div>
                {/*<div className="row-etrium justify-content-end gap-1">*/}

                {/*    <ButtonEtrium label="Fechar"*/}
                {/*                  type={'secondary'}*/}
                {/*                  onClick={onClose}*/}
                {/*    />*/}
                {/*    <ButtonEtrium label="Cadastrar"*/}
                {/*                  onClick={cadastrarAtividade}*/}
                {/*                  type={'primary'}/>*/}
            </div>
        </Dialog>
    );
}
