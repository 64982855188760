import React, {useContext, useState} from "react";
import {IsNullOrEmpty} from "../../utils/IsNullOrEmpy";
import AtividadeService from "../../app/service/AtividadeService";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {ButtonEtrium} from "../ButtonEtrium";
import {Dialog} from "primereact/dialog";
import {AuthContext} from "../../main/ProvedorAutenticacao";
import {Loading} from "../Loading";


export const EntreContatoDialog = ({open, onclose, toast}) => {
    // const toast = useRef(null);

    const titulo = 'Plano empresarial'
    const [descricao, setDescricao] = useState('')
    const [email,setEmail] = useState('')
    const [loading,setLoading] = useState(false)

    //service
    const atividadeService = new AtividadeService()

    const {usuarioAutenticado} = useContext(AuthContext)

    const cadastrarAtividade = ()=>{

        if(IsNullOrEmpty(email)){
            toast.show({ severity: 'error', summary: 'Plano empresarial', detail: 'Informe o seu email', life: 4000 });
            return
        }
        if(IsNullOrEmpty(titulo)){
            toast.show({ severity: 'error',  summary: 'Plano empresarial', detail: 'Informe o título da atividade', life: 4000 });
            return
        }
        if(IsNullOrEmpty(descricao)){
            toast.show({ severity: 'error', summary: 'Plano empresarial', detail: 'Informe detalhes do plano empresarial', life: 4000 });
            return
        }


        const newDescrition =
        `${descricao}
        empresa: ${usuarioAutenticado.empresa.nome}
        email: ${email}
        solicitado por: ${usuarioAutenticado.nome}
        `
        const novaAtividade = {
            atividade: {
                titulo: titulo,
                processo: null,
                subGrupo: null,
                dtLimite: '',
                dtFatal: null,
                descricao: newDescrition,
                usuario: usuarioAutenticado,
                tipo: 'T',
                status: {
                    codigo: 2
                } // Aguardando Inicio
            }
        }
           setLoading(true)
            atividadeService.cadastrarAtendimento(novaAtividade)
            .then(()=>{
                toast.show({
                    severity: 'success',
                    summary: 'Plano empresarial',
                    detail: 'Mensagem enviada - você receberá uma resposta em breve.',
                    life: 4000
                });
                    onclose()
            })
            .catch((error)=>{
                console.log(error)
                const detail = error.response.data.detail  || "Erro inesperado ao tentar entrar em contato"
                toast.show({ severity: 'error', summary: 'Suporte', detail: `${detail}`, life: 4000 });

            })
            .finally(()=>{
                setLoading(false)
            })

    }

    return(

        <>
            <Dialog header="Entre em contato" visible={open} style={{minWidth: '50vw'}} onHide={onclose} >
            <div className="limiter">
                <div className="flex-column">
                    <div className="row-etrium">
                        <div className="input-group mb-3">
                            <label>Titulo<span className="obrigatorioAsterisco"> *</span></label>
                            <InputText id="titulo"
                                       style={{width: '100%', height: '37px'}}
                                       type="text"
                                       maxLength={100}
                                       autoComplete="nope"
                                       value={titulo}
                                       disabled
                                // onChange={e => setTitulo(e.target.value)}
                                       className="p-inputtext-sm p-d-block p-mb-1"
                                       placeholder="Título"/>
                        </div>
                    </div>
                    <div className="row-ertium">
                        <div className="input-group mb-3">
                            <label>Email<span className="obrigatorioAsterisco"> *</span></label>
                            <InputText id="email"
                                       style={{width: '100%', height: '37px'}}
                                       type="text"
                                       autoComplete="nope"
                                       maxLength={100}
                                       value={email}
                                       onChange={e => setEmail(e.target.value)}
                                       className="p-inputtext-sm p-d-block p-mb-1"
                                       placeholder="Email"/>
                        </div>
                    </div>
                    <div className="row-etrium">
                        <div className="input-group mb-3">
                            <label>Detalhes do Plano Empresarial<span className="obrigatorioAsterisco"> *</span></label>
                            <InputTextarea id="descricao"
                                           type="text"
                                           maxLength={500}
                                           autoComplete="nope"
                                           style={{width: '100%'}}
                                           value={descricao}
                                           onChange={e => setDescricao(e.target.value)}
                                           className="p-inputtext-sm p-d-block p-mb-1"
                                           rows={6} cols={30}
                                           placeholder="Informe o número de usuários, número de processos, dúvidas etc."/>
                            <span className={"flex-1 flex-row justify-content-end "}>{500 - descricao.length}</span>
                        </div>
                    </div>
                    <div className="row-etrium gap-1">

                    <ButtonEtrium label="Enviar"
                                      onClick={cadastrarAtividade}
                                      buttonType={'primary'}/>
                    </div>
                    {/*fim inputs*/}
                </div>
            </div>
                <Loading open={loading} />
            </Dialog>
        </>
    );
}