import {AuthRoute} from './main/Rotas'
import '../src/pages/css/custom2.css'
import ProvedorAutenticacao from '../src/main/ProvedorAutenticacao'

function App() {
    localStorage.getItem('_usuario_logado');
    return (
        <>
      <div>
        <ProvedorAutenticacao>
            <AuthRoute/>
        </ProvedorAutenticacao>
      </div>
    </>
  );
}

export default App;
