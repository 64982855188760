import React, {useContext, useEffect, useRef, useState} from 'react';
import {Loading} from "../../componentes/Loading";
import {AuthContext} from '../../main/ProvedorAutenticacao'
import {Toast} from 'primereact/toast';
import Navbar from "../../componentes/Navbar";
import {withRouter} from "react-router-dom";
import {ButtonSm} from "../../componentes/ButtonSm";
import {strings} from "../../utils/strings";
import EstadoService from "../../app/service/EstadoService";
import CidadeService from "../../app/service/CidadeService";
import EmpresaService from "../../app/service/EmpresaService";
import {InputMask} from "primereact/inputmask";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import LocalStorageService from "../../app/service/localStorageService";
import {USUARIO_LOGADO} from "../../app/service/AuthService";
import {CardPlano} from "../../componentes/Empresa/CardPlano";
import EmpresaPlanosService from "../../app/service/EmpresaPlanosService";
import {EmpresaConsts} from "../../Consts/EmpresaConsts";
import EmpresaGraficoService from "../../app/service/EmpresaGraficoService";
import '../css/util.css'
import {formatarMoeda} from "../../utils/formatar-moeda-tela";
import AlterarPlanosDialog from "../planos/AlterarPlanoDialog";
import TransacoesEmpresa from './TransacoesEmpresa';


function EditarEmpresa() {
    const [loading, setLoading] = useState(false)
    const [razaoSocial, setRazaoSocial] = useState("")
    const [cnpj, setCnpj] = useState("")
    const [selectEstado, setSelectEstado] = useState()
    const [selectCidade, setSelectCidade] = useState()
    const [cidades, setCidades] = useState([])
    const [estados, setEstados] = useState([])
    const [detail, setDetail] = useState()
    const [empresaDetail, setEmpresaDetail] = useState(null)
    const [openAlterarPlanos,setOpenAlterarPlanos] = useState(false)

    const user = LocalStorageService.obterItem(USUARIO_LOGADO);

    const estadoService = new EstadoService();
    const cidadeService = new CidadeService();
    const empresaService = new EmpresaService();
    const empresaGraficoService = new EmpresaGraficoService()

    const toast = useRef(null);

    async function consultarEmpresaByCodigo(codigo) {
        await empresaGraficoService.findEmpresaDetailInfo(codigo).then(r => {
            console.log(r.data)
            setEmpresaDetail(r.data)
            console.log(empresaDetail)
        })
        
    }

    const listarEstados = async () => {
        await estadoService.listarEstados()
            .then(response => {
                setEstados(response.data);
            })
    }
    
    async function consultarEmpresa(){
        await empresaService.consultarEmpresa(user.empresa.codigo).then(e=>{
            console.log(e.data)
            setCnpj(e.data.cpfCnpj)
            setSelectEstado(e.data.cidade.estado)
            listarCidades(e.data.cidade.estado.codigo)
            setSelectCidade(e.data.cidade)
            setRazaoSocial(e.data.nome)
        }).catch(error=>{
            setDetail(error.response.data)
            toast.current.show({
                severity: 'error',
                summary: 'Usuários',
                detail: `${detail}`,
                life: 4000
            });
        })
    }

    useEffect(() => {
        listarEstados()
        consultarEmpresa()
        consultarEmpresaByCodigo(user.empresa.codigo)
    }, [])

    const isNullOrBlank = (item) => {
        if (item == null) {
            return true
        }
        return item === '';
    }

    async function onEstadoChange(e) {
        setSelectEstado(e);
        await listarCidades(e.codigo);
    }

    async function listarCidades(cod){
        await cidadeService.listarCidades(cod)
            .then(response => {
                setCidades(response.data)
            }).catch(error => {
                setDetail(error.response.data)
                toast.current.show({
                    severity: 'error',
                    summary: 'Empresa',
                    detail: `${detail}`,
                    life: 4000
                });
            })
    }
    async function handleSubmit(){

        const empresa={
            codigo:user.empresa.codigo,
            cnpj:cnpj,
            razaoSocial:razaoSocial,
            cidade:selectCidade
        }
        await empresaService.editarEmpresa(empresa).then(()=>{
            toast.current.show({
                severity: 'success',
                summary: 'Empresa',
                detail: `A empresa foi editada com sucesso`,
                life: 4000
            })
        }).catch((error)=>{
            setDetail(error.response.data)
            toast.current.show({
                severity: 'error',
                summary: 'Empresa',
                detail: `${detail}`,
                life: 4000
            });
        })
    }

    const EmpresaStatus = {
        AGUARDANDO_CONFIRMACAO_EMAIL: 1,
        ATIVO: 2,
        SUSPENSA: 3,
        CANCELADA: 4
      };
      
    const getStatusText = (status) => {
        switch (status) {
          case EmpresaStatus.AGUARDANDO_CONFIRMACAO_EMAIL:
            return 'Aguardando Confirmação de Email';
          case EmpresaStatus.ATIVO:
            return 'Ativo';
          case EmpresaStatus.SUSPENSA:
            return 'Suspensa';
          case EmpresaStatus.CANCELADA:
            return 'Cancelada';
          default:
            return 'Status Desconhecido';
        }
      };

    return(
        <>
            <Loading open={loading}/>
            <Navbar/>
            <Toast ref={toast} />
            <AlterarPlanosDialog toast={toast} open={openAlterarPlanos} close={() => setOpenAlterarPlanos(false)} />
            <div className="content-wrapper">
                <div className="container-fluid">
                    <form onSubmit={handleSubmit}>
                        <section className="content-header">
                            <div className='card card-primary card-outline gap-1'>
                                <div className='card-header'>
                                    <h3 className="card-title row-etrium gap-1 align-center">
                                        <i className="pi pi-building"></i>
                                        <span>Alterar dados da empresa</span>
                                    </h3>
                                </div>
                                    {empresaDetail && <div className="flex-1 flex-row justify-content-start align-items-start" style={{width:"max-content", gap: "0.6rem"}}>
                                        <div className="flex-col justify-content-start align-items-center card m-b-0">
                                            <span className="border-bottom card-header px-5">Usuários:</span><span className="card-body"> {empresaDetail.usuarios}/{empresaDetail.quantidadeUsuariosPlano}</span>
                                        </div>
                                        <div className="flex-col justify-content-end align-items-center card m-b-0">
                                            <span className="border-bottom card-header px-5">Processos:</span><span className="card-body"> {empresaDetail.processos}/{empresaDetail.quantidadeProessosPushPlano}</span>
                                        </div>
                                        <div
                                            className="flex-col justify-content-end align-items-center card m-b-0">
                                            <span className="border-bottom card-header px-5">Atividades:</span><span className="card-body"> {empresaDetail.atividades}</span>
                                        </div>
                                        <div
                                        id='PlanoName'
                                            className="flex-col justify-content-end align-items-center card m-b-0">
                                            <span className="border-bottom card-header px-5">Plano:</span><span className="card-body">{empresaDetail.plano}</span>
                                            
                                        </div>
                                        <div
                                        id='StatusPlano'
                                            className="flex-col justify-content-end align-items-center card m-b-0">
                                            <span className="border-bottom card-header px-2">Status do Plano:</span>
                                            <span className="card-body">{getStatusText(empresaDetail.status)}</span>
                                        </div>
                                    </div>
                                    }
                                <div className="row-etrium" style={{gap: '5px', marginBottom: "0", borderBottom: 'none', paddingLeft: "1.25rem", paddingRight: "1.25rem"}}>

                                    <div className="group">
                                        <label>CNPJ<span className="obrigatorioAsterisco"> *</span></label>
                                        <InputMask mask="99.999.999/9999-99"
                                                   id="cpnj"
                                                   name='cnpj'
                                                   disabled
                                                   type="text"
                                                   style={{width: '100%'}}
                                                   value={cnpj}
                                                   className="p-inputtext-sm p-d-block p-mb-1"/>
                                    </div>
                                    <div className="group">
                                        <label>Razão social<span
                                            className="obrigatorioAsterisco"> *</span></label>
                                        <InputText id="razaoSocial"
                                                   name="razaoSocial"
                                                   type="text"
                                                   style={{width: '100%'}} autoComplete="off"
                                                   value={razaoSocial}
                                                   onChange={e => setRazaoSocial(e.target.value)}
                                                   className="p-inputtext-sm p-d-block p-mb-1"/>

                                    </div>
                                </div>
                                <div className="row-etrium" style={{gap: '5px', marginBottom: "0", borderBottom: 'none', paddingLeft: "1.25rem", paddingRight: "1.25rem"}}>

                                    <div className="form-group" style={{flex: 1}}>
                                        <label>Estado<span className="obrigatorioAsterisco"> *</span></label>
                                        <Dropdown value={selectEstado}
                                                  options={estados} style={{width: '100%'}}
                                                  optionLabel="estado"
                                                  filter showClear filterBy="estado"
                                                  onChange={e => onEstadoChange(e.target.value)}
                                                  id="estado"
                                                  className="p-inputtext-sm p-d-block p-mb-1"/>
                                    </div>


                                    {!isNullOrBlank(selectEstado) &&

                                        <div className="form-group" style={{flex: 1}}>
                                            <label>Cidade<span
                                                className="obrigatorioAsterisco"> *</span></label>
                                            <Dropdown value={selectCidade}
                                                      options={cidades}
                                                      style={{width: '100%'}}
                                                      optionLabel="nome"
                                                      emptyMessage="Nenhuma cidade para este estado"
                                                      filter showClear filterBy="nome"
                                                      onChange={e => setSelectCidade(e.target.value)}
                                                      id="cidade"
                                                      className="p-inputtext-sm p-d-block p-mb-1"/>
                                        </div>}
                                </div>
                                <div>
                                    <TransacoesEmpresa empresaCodigo={user.empresa.codigo} />
                                </div>
                                <div className='row-etrium gap-1'>
                                    <Button label="Alterar"
                                            type={'submit'}
                                            className={strings.buttonPrimaryStyle}/>
                                    {user.perfil.codigo === 1 &&
                                            <Button id='Modify-plan-btn' label="Alterar plano"
                                                    type={'button'}
                                                    onClick={()=> setOpenAlterarPlanos(true)}
                                                    className={strings.buttonSecondaryStyle}/>
                                    }
                                </div>
                            </div>
                            
                        </section>
                    </form>
                </div>
            </div>
        </>
    )

}

export default withRouter(EditarEmpresa)