import {Dialog} from "primereact/dialog";
import {Dropdown} from "primereact/dropdown";
import React, {useContext, useEffect, useState} from "react";
import ParecerService from "../../app/service/ParecerService";
import TipoParecerService from "../../app/service/TipoParecerService";
import {AuthContext} from "../../main/ProvedorAutenticacao";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {Button} from "primereact/button";
import {strings} from "../../utils/strings";
import {IsNullOrEmpty} from "../../utils/IsNullOrEmpy";
import {AnexarArquivosParecerDialogTemp} from "./AnexarArquivosParecerDialogTemp";
import ArquivoParecerService from "../../app/service/ArquivoParecerService";
import {AutoCompletePessoas} from "./AutoCompletePessoas";


export const CadastrarParecerDialog = ({open, close, toast, refresh}) => {

    const [tipoPareceres, setTipoPareceres] = useState([])
    const [modalAnexarArquivo, setModalAnexarArquivo] = useState(false)

    const [formParecer, setFormParecer] = useState({
        pessoa: "",
        codigoCitrus: "",
        tipo: null,
        descricao: ""
    })
    const handleChange = (event) => {
        const {name, value} = event.target;
        setFormParecer(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const {usuarioAutenticado,setLoading } = useContext(AuthContext)

    const parecerService = new ParecerService()
    const tipoParecerService = new TipoParecerService()
    const arquivoParecerService = new ArquivoParecerService()


    function carregarTipoParecer() {
        const codigoEmpresa = usuarioAutenticado.empresa.codigo
        tipoParecerService.buscarTipoParecerPorEmpresa(codigoEmpresa).then((res) => {
            setTipoPareceres(res.data)
        })
    }

    function onSubmit() {
        const {
            pessoa,
            codigoCitrus,
            tipo,
            descricao
        } = formParecer

        if (IsNullOrEmpty(codigoCitrus)) {
            toast.current.show({
                severity: 'error',
                summary: 'Parecer',
                detail: 'Número do documento é obrigatório',
                life: 4000
            });
            return;
        }
        if (IsNullOrEmpty(pessoa)) {
            toast.current.show({
                severity: 'error',
                summary: 'Parecer',
                detail: 'Pessoa é obrigatório',
                life: 4000
            });
            return;
        }

        if (IsNullOrEmpty(tipo)) {
            toast.current.show({
                severity: 'error',
                summary: 'Parecer',
                detail: 'Tipo parecer é obrigatório',
                life: 4000
            });
            return;
        }
        if (IsNullOrEmpty(descricao)) {
            toast.current.show({severity: 'error', summary: 'Parecer', detail: 'Descrição é obrigatório', life: 4000});
            return
        }
        const parecer = {...formParecer}
        parecer.usuarioCriou = usuarioAutenticado
        parecer.status = 1
        parecer.empresa = usuarioAutenticado.empresa
        setLoading(true)
        parecerService.cadastrarParece(parecer).then(()=>{
            toast.current.show({severity: 'success', summary: 'Parecer', detail: 'Cadastro realizado', life: 4000});
            refresh()
            onClose()
        }).catch(()=>{
            toast.current.show({severity: 'error', summary: 'Parecer', detail: 'Erro ao cadastrar um novo parecer', life: 4000});
        }).finally(()=>{
            setLoading(false)
        })



    }

    const excluirArquivosTemp = async () => {
        const codigo = usuarioAutenticado.codigo
        await arquivoParecerService.excluirArquivosTemp(codigo)
            .then(() => {
            }).catch(error => {
                let detail = error.response.data.detail
                toast.show({ severity: 'error', summary: 'Arquivos', detail: `${detail}`, life: 4000 });
            })
    }

    const onClose = async () => {
        await excluirArquivosTemp().finally(() => {
            close()
        })

    }

    useEffect(()=>{
        carregarTipoParecer()
    },[])

    function renderFooter() {
        return (
            <div className="flex-row justify-content-start">
                <Button label="Cadastrar"
                        id={"btn-cadastrar"}
                        className={strings.buttonPrimaryStyle}
                        onClick={onSubmit}/>
                <Button label="Anexar"
                        id={"btn-anexar"}
                        icon="pi pi-paperclip"
                        onClick={() => setModalAnexarArquivo(true)}
                        className={strings.buttonHelp}/>
            </div>
        );
    }

    return (
        <>

        <Dialog header="Cadastrar parecer"
                visible={open}
                style={{width: '40vw'}}
                footer={renderFooter}
                onHide={onClose}>

            <div className="flex-1 flex-column">
                <div className="col-sm-12">
                    <div className="form-group">
                        <label>Nº do documento <span className="obrigatorioAsterisco"> * </span></label>
                        <InputText id="proto-sfhgskjfhsdjkfhsd"
                                   type="text"
                                   max={20}
                                   maxLength={20}
                                   style={{width: '100%'}}
                                   name={"codigoCitrus"}
                                   value={formParecer.codigoCitrus}
                                   onChange={handleChange}
                                   className="p-inputtext-sm p-d-block p-mb-1"/>
                    </div>

                </div>
                <div className="col-sm-12">
                    <div className="form-group">
                        <label>Pessoa <span className="obrigatorioAsterisco"> * </span></label>
                        <AutoCompletePessoas
                            selectPessoa={formParecer.pessoa}
                            setSelect={handleChange}
                        />
                    </div>

                </div>
                <div className="col-sm-12">
                    <div className="form-group">
                        <label>Tipo de Parecer <span className="obrigatorioAsterisco"> * </span></label>
                        <Dropdown
                            value={formParecer.tipo}
                            name={"tipo"}
                            optionLabel="nome"
                            options={tipoPareceres}
                            filter showClear filterBy="nome"
                            style={{width: '100%'}}
                            onChange={handleChange}
                            id="tipop-id-no-internal"
                            className="p-inputtext-sm p-d-block p-mb-1"/>
                    </div>

                </div>
                <div className="col-sm-12">
                    <div className="form-group">
                        <label>Descrição <span className="obrigatorioAsterisco"> * </span></label>
                        <InputTextarea id="txtares-inp-dev-sek"
                                       type="text"
                                       style={{width: '100%'}}
                                       name={"descricao"}
                                       value={formParecer.descricao}
                                       placeholder={"Descreva nesse espaço o conteúdo da solicitação do seu parecer"}
                                       onChange={handleChange}
                                       maxLength={500}
                                       className="p-inputtext-sm p-d-block p-mb-1"
                                       rows={6} cols={30}/>
                    </div>
                    <span className={"flex-row justify-content-end"}>{500 - formParecer.descricao.length}/500</span>

                </div>
            </div>

        </Dialog>
            {modalAnexarArquivo && <>
            <AnexarArquivosParecerDialogTemp open={modalAnexarArquivo}
                                             onClose={() => setModalAnexarArquivo(false)}
                                             toast={toast.current} />
            </>}
        </>
    )
}
