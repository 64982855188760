import React from 'react'

import { MdCheckCircle, MdError, MdLink, MdHourglassEmpty } from 'react-icons/md'
import 'react-circular-progressbar/dist/styles.css'

import { Container, FileInfo } from "./style";
import './index.css'

const FileList = ( {files, onDelete} ) => {
  const uniqueFiles = removeDuplicatesById(files);
  function removeDuplicatesById(files) {
    const uniqueFiles = [];
    const fileIds = new Set();

    for (const file of files) {
      if (!fileIds.has(file.id)) {
        uniqueFiles.push(file);
        fileIds.add(file.id);
      }
    }

    return uniqueFiles;
  }
  return(
    <Container>
      {uniqueFiles.map((upLoadFile) =>(
        <li key={upLoadFile.id}>
          <strong className="corArquivo">Processando arquivo: </strong>
        <FileInfo>
          <div>
            <strong>{upLoadFile.name}</strong>
            <span>{upLoadFile.readableSize}{ " " } 
              <button onClick={() => onDelete(upLoadFile.id)}>Excluir</button>
            </span>
          </div>
        </FileInfo>
        <div>
          {!upLoadFile.uploaded && !upLoadFile.error && (
            <MdHourglassEmpty size={24} color="#6699FF" title="Não enviado"/>
          )}

          {upLoadFile.uploaded && (
            <a href={'#top'}
            target="_blank"
            rel="noopener noreferrer">
              <MdLink style={{marginRight: 8}} size={24} color="#222"/>
            </a>
          )}
          {upLoadFile.uploaded && <MdCheckCircle size={24} color="#78e5d5"/>}
          {upLoadFile.error && <MdError size={24} color="#e57878"/>}
        </div>
        </li>
      ))}
    </Container>
  );
}

export default FileList
