import React from "react";
import {Dialog} from "primereact/dialog";
import AlterarPlanos from "./AlterarPlano";

export default function AlterarPlanosDialog({ open, close }) {
  return (
    <Dialog header={"Alterar plano"} visible={open} onHide={close}>
      <AlterarPlanos />
    </Dialog>
  );
}
