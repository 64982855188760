import ApiService from "../apiservice";

class AcompanhamentoService extends ApiService {
    constructor() {
        super("/auditoria");
    }

    consultar(filtro) {
        let params = `cdempresa=${filtro.empresa}&data=${filtro.data}`

        if (filtro.usuarios) {
            params += `&usuarios=${filtro.usuarios}`
        }

        if (filtro.modulo) {
            params += `&modulo=${filtro.modulo}`
        }

        if (filtro.tipoLog) {
            params += `&tplog=${filtro.tipoLog}`
        }
        return this.get(`/logs?${params}`);
    }
}

export default AcompanhamentoService