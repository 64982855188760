import React from 'react'
import {withRouter} from 'react-router-dom'

import {Toast} from 'primereact/toast'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Dialog} from 'primereact/dialog'
import {ConfirmDialog, confirmDialog} from 'primereact/confirmdialog'
import {ButtonSm} from '../../componentes/ButtonSm';

import NavBar from '../../componentes/Navbar'
import TiposCustasService from '../../app/service/TiposCustasService'
import {AuthContext} from '../../main/ProvedorAutenticacao'
import {strings} from "../../utils/strings";
import {IsNullOrEmpty} from "../../utils/IsNullOrEmpy";
import {Loading} from "../../componentes/Loading";

class TiposCustas extends React.Component {

  constructor(props) {
    super(props)

    this.tiposCustasService = new TiposCustasService();
    this.accept = this.accept.bind(this);
    this.acoes = this.acoes.bind(this);
    this.renderFooterAlterarTipos = this.renderFooterAlterarTipos.bind(this);
    this.renderFooterCadastroTipos = this.renderFooterCadastroTipos.bind(this);

    this.state = {
      //Cadastro
      codigo: '',
      nome: '',
      empresa: null,
      tiposCustas: [],
      modalCadastro: false,
      modalAlterar: false,
      visivble: false,
      //Progresso
      classeDiv: 'hide',
        loading: true,
    }
  }

  fecharModalCadastro(name) {
    this.setState({
        [`${name}`]: false
    });
  }

  abrirModalCadastro(name, position) {
    let state = {
        [`${name}`]: true
    };

    if (position) {
        state = {
            ...state,
            position
        }
    }
    this.limparCampos();
    this.setState(state);
  }

  abrirModaAlterar(name, position) {
    let state = {
        [`${name}`]: true
    };

    if (position) {
        state = {
            ...state,
            position
        }
    }

    this.setState(state);
  }

  limparCampos = () => {
    this.setState({nome: ''});
  }

  accept() {
    this.excluirTipoCusta();
  }

  cadastrarTiposCustas = async () => {
    if(IsNullOrEmpty(this.state.nome)) {
      this.toast.show({severity:'error', summary: 'Tipos de custas', detail:'Informe o nome do tipo de custa', life: 4000});
      return false;
    }
    this.setState({ classeDiv: 'show'});
    const usuarioLogado = this.context.usuarioAutenticado
    await this.tiposCustasService.cadastrarTipoCusta({
      nome: this.state.nome,
      empresa: usuarioLogado.empresa
    }).then(response => {
      this.toast.show({severity:'success', summary: 'Tipos de custas', detail:'Tipo de custa cadastrado com sucesso', life: 4000});
      this.listarTiposCustas();
    }).catch(error => {
      this.toast.show({severity:'error', summary: 'Tipos de custas', detail:`${this.state.detail}`, life: 4000});
    });

    this.fecharModalCadastro('modalCadastro');
    this.setState({classeDiv: 'hide'});
  }

  listarTiposCustas = async () => {
    this.setState({classeDiv: 'show'});
    const usuarioLogado = this.context.usuarioAutenticado
    await this.tiposCustasService.listarTiposCusta(usuarioLogado.empresa.codigo)
      .then(response => {
        this.setState({tiposCustas: response.data});
      }).catch(error => {
        this.setState(error.response.data);
        this.toast.show({severity:'error', summary: 'Tipos de custas', detail:`${this.state.detail}`, life: 4000});
      }).finally(()=>{
            this.setState({loading:false});
        });

    this.setState({classeDiv: 'hide'});
  }

  async editar(tipo) {
    this.setState({classeDiv: 'show'});
    if(tipo != null) {
      await this.tiposCustasService.consultarTipoCusta(tipo.codigo)
        .then(response => {
          this.setState({codigo: response.data.codigo});
          this.setState({nome: response.data.nome});
          this.abrirModaAlterar('modalAlterar');
        }).catch(error => {
          this.setState(error.response.data);
          this.toast.show({severity:'error', summary: 'Tipos de custas', detail:`${this.state.detail}`, life: 4000});
        });
    }
    this.setState({classeDiv: 'hide'});
  }

  alterarTipoCustas = async () => {
    this.setState({classeDiv: 'show'});
    if(this.state.nome === '') {
      this.toast.show({severity:'error', summary: 'Tipos de custas', detail:'Informe o nome do tipo de custa', life: 4000});
      this.setState({classeDiv: 'hide'});
      return false;
    }
    const usuarioLogado = this.context.usuarioAutenticado
    this.tiposCustasService.alterarTipoCusta({
      codigo: this.state.codigo,
      nome: this.state.nome,
      empresa: usuarioLogado.empresa
    }).then(response => {
      this.listarTiposCustas();
      this.toast.show({severity:'success', summary: 'Tipos de custas', detail:'Tipo de custa alterado com sucesso', life: 4000});
      this.fecharModalCadastro('modalAlterar')
    }).catch(error => {
      this.setState(error.response.data);
      this.toast.show({severity:'error', summary: 'Tipos de custas', detail:`${this.state.detail}`, life: 4000});
    });

    this.setState({classeDiv: 'hide'});
  }

  renderFooterCadastroTipos(name) {
    return (
        <div className="row-etrium gap-1">
            <Button label="Cadastrar"
                    className={strings.buttonPrimaryStyle}
                    onClick={this.cadastrarTiposCustas}/>
        </div>
    );
  }

  renderFooterAlterarTipos(name) {
    return (
        <div className="row-etrium gap-1">
            <Button label="Alterar"
                    className={strings.buttonPrimaryStyle}
                    onClick={this.alterarTipoCustas}/>
        </div>
    );
  }

  acoes(rowData) {
    return(
      <div className="row gap-1">
        <Button icon="pi pi-pencil" className={strings.buttonIconSecundary}
                onClick={() => this.editar(rowData)}
                tooltip="Editar tipo de custas"
                tooltipOptions={{position: 'top'}} />
        <Button icon="pi pi-trash" className={strings.buttonIconDanger}
                onClick={() => this.confirmaExclusao(rowData)}
                tooltip="Excluir tipo de custas"
                tooltipOptions={{position: 'top'}}/>
      </div>
    );
  }

  confirmaExclusao(tipo) {
    this.setState({codigo: tipo.codigo});
    confirmDialog({
        message: 'Deseja realmente excluir o tipo de custa?',
        header: 'Exclusão de tipo de custa',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        accept: this.accept,
        reject: this.reject
    });
  }

  async excluirTipoCusta() {
    this.setState({classeDiv: 'show'});
    this.tiposCustasService.excluirTipoCusta(this.state.codigo)
      .then(response => {
        this.listarTiposCustas();
        this.toast.show({severity:'success', summary: 'Tipos de custas', detail:'Tipo de custa excluído com sucesso', life: 4000});
      }).catch(error => {
        this.setState(error.response.data);
        this.toast.show({severity:'error', summary: 'Tipos de custas', detail:`${this.state.detail}`, life: 4000});
      });

      this.setState({classeDiv: 'hide'});
  }

  componentDidMount() {
    this.listarTiposCustas();
  }

  render() {
    return(
      <>
          <Loading open={this.state.loading} />
          <NavBar/>
        <Toast ref={(el) => this.toast = el} />
        <div className="content-wrapper">
          {/**Cadastro de Tipo de Custas */}
          <Dialog header="Cadastro de tipo de custas"
                    visible={this.state.modalCadastro}
                    style={{ width: '40vw' }}
                    footer={this.renderFooterCadastroTipos('modalCadastro')}
                    onHide={() => this.fecharModalCadastro('modalCadastro')}>

                <div className="p-fluid">
                    <div className="p-field">
                        <label htmlFor="nomeGrupo">Nome<span className="obrigatorioAsterisco"> *</span></label>
                        <InputText id="nomeGrupo"
                                   type="text"
                                   maxLength={50}
                                   value={this.state.nome}
                                   onChange={e => this.setState({nome: e.target.value})}
                                   className="p-inputtext-sm p-d-block p-mb-1"/>
                    </div>
                </div>
          </Dialog>
          {/**Alterar Tipo de Pagamento */}
          <Dialog header="Alterar tipo de custas"
                    visible={this.state.modalAlterar}
                    style={{ width: '40vw' }}
                    footer={this.renderFooterAlterarTipos('modalAlterar')}
                    onHide={() => this.fecharModalCadastro('modalAlterar')}>

                <div className="p-fluid">
                    <div className="p-field">
                        <label htmlFor="nomeTipo">Nome<span className="obrigatorioAsterisco"> *</span></label>
                        <InputText id="nomeTipo"
                                   type="text"
                                   maxLength={50}
                                   value={this.state.nome}
                                   onChange={e => this.setState({nome: e.target.value})}
                                   className="p-inputtext-sm p-d-block p-mb-1"/>
                    </div>
                </div>
          </Dialog>
          <ConfirmDialog visible={this.state.visible}
                         onHide={() => this.setState({ visible: false })}
                         message="Are you sure you want to proceed?"
                         header="Confirmation"
                         icon="pi pi-exclamation-triangle"
                         accept={() => this.excluirTipoCusta}
                         reject={() => this.setState({visible: false})}/>
          <section className="content-header">
            <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                  <div className="card card-primary card-outline">
                    <div className="card-header">
                      <h3 className="card-title">
                        <i className="fas fa-dollar-sign"></i>
                        <span> Tipos de custas</span>
                      </h3>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-5">
                          <span className="input-group">
                            <ButtonSm type="button" className={strings.buttonPrimarySm}
                                    onClick={() => this.abrirModalCadastro('modalCadastro')}
                                    data-toggle="modal"
                                    data-target="#modal-default">
                            <i className="fas fa-plus"></i> Cadastrar</ButtonSm>
                          </span>
                        </div>
                      </div>
                    </div>
                    <DataTable value={this.state.tiposCustas}  paginator responsiveLayout="scroll"
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}" rows={10}>
                      <Column field="nome" header="Nome"></Column>
                      <Column key="codigo"></Column>
                      <Column body={this.acoes} exportable={false} style={{ minWidth: '8rem' }}header="Ações"></Column>
                    </DataTable>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

TiposCustas.contextType = AuthContext;
export default withRouter (TiposCustas)
