import React from "react";
import {Button} from 'primereact/button';
import {strings} from "../utils/strings";


export const ButtonEtrium=({children, buttonType, ...rest})=>{
    const tipoBotao = (tipo)=>{
        switch (tipo) {
            case 'primary':
                return strings.buttonPrimaryStyle;
            case 'secondary':
                return strings.buttonSecondaryStyle;
            case 'warning':
                return strings.buttonWarningStyle;
            case 'help':
                return strings.buttonHelp;
            case 'danger':
                return strings.buttonDanger;
            case 'info':
                return strings.buttonInfo;
            case 'plain':
                return strings.buttonPlain;
                case 'success':
                return strings.buttonSuccess;
            default:
                return strings.buttonPrimaryStyle;
        }
    }

    const tipo = tipoBotao(buttonType)

    return(
        <Button {...rest} className={tipo}>
            {/*<div className="row gap-3 center-items">*/}
                {children}
            {/*</div>*/}
        </Button>
    )
}
