import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {Dialog} from "primereact/dialog";
import React, {useContext, useEffect, useState} from "react";
import {PagamentoReceitasDespesasDialog} from "../PagamentoReceitasDespesasDialog";
import {TabPanel, TabView} from "primereact/tabview";
import {Arquivos} from "./Arquivos";
import FinanceiroService from "../../../app/service/FinanceiroService";
import {ButtonEtrium} from "../../ButtonEtrium";
import ArquivoFinanceiroService from "../../../app/service/ArquivoFinanceiroService";
import {formatarMoeda} from "../../../utils/formatar-moeda-tela";
import {AuthContext} from "../../../main/ProvedorAutenticacao";
import {PERFIL_ADM} from "../../../utils/ConstsParecer";
import {FINANCEIRO} from "../../../Consts/PerfilConsts";


export const ConsultaLancamentoDetalhadoDialog = ({lancamento, open, onClose, toast, refresh}) => {

    const [tipo, setTipo] = useState('');
    const [categoriaNome, setCategoriaNome] = useState('');
    const [tipoNome, setTipoNome] = useState('');
    const [vlLancamento, setVlLancamento] = useState('');
    const [dtVencimento, setDtVencimento] = useState('');
    const [vlPago, setVlPago] = useState('');
    const [dtPago, setDtPago] = useState('');
    const [dsLancamento, setDsLancamento] = useState('');
    const [observacao, setObservacao] = useState('');
    const [dsProcesso, setDsProcesso] = useState('');
    const [situacao, setSituacao] = useState('');
    const [arquivos, setArquivos] = useState([]);

    const [modalReceitaDespesa, setModalReceitaDespesa] = useState(false);

    const {usuarioAutenticado} = useContext(AuthContext)

    const financeiroService = new FinanceiroService()
    const arquivoFinanceiroService = new ArquivoFinanceiroService()


    const reabrirLancamento = () => {
        if (lancamento.situacao === 'A') {
            toast.show({severity: 'error', summary: 'Pagamento', detail: 'O lançamento está ativo', life: 4000});
            return false;
        }
        financeiroService.reabrirLancamento({
            codigo: lancamento.codigo,
            situacao: 'A'
        }).then(() => {
            toast.show({severity: 'success', summary: 'Pagamento', detail: 'Lançamento reaberto', life: 4000});
            onClose()
            refresh()
        }).catch(error => {
            let detail = error.response.data.detail
            toast.show({severity: 'error', summary: 'Pagamento', detail: `${detail}`, life: 4000});
        })

    }

    const consultarLancamentoPagamento = () => {
        // setVlLancamentoNovo('');
        // setDtVencimentoNovo('');

        setModalReceitaDespesa(true)

    }

    const listarArquivos = () => {
        arquivoFinanceiroService.listarArquivos(lancamento.codigo)
            .then(response => {
                setArquivos(response.data);
            }).catch(error => {
            let detail = error.response.data.detail
            this.toast.show({
                severity: 'error',
                summary: 'Financeiro - Arquivos',
                detail: `${detail}`,
                life: 4000
            });
        })
    }


    function preencherDados(data) {

        setTipo(data.tipo);

        if (data.tipo.tipo === 'D') {
            setCategoriaNome('Despesa');
        } else {
            setCategoriaNome('Receita');
        }

        setTipoNome(data.tipo.nome);
        setVlLancamento(data.vlLancamento);
        setDtVencimento(data.dtVencimento);
        setVlPago(data.vlPago);
        setDtPago(data.dtPago);
        setDsLancamento(data.dsLancamento);
        setObservacao(data.observacao);

        if (data.processo !== null) {
            setDsProcesso(data.processo.nrCnj);
        } else {
            setDsProcesso('');
        }

        if (data.situacao === 'P') {
            setSituacao('Pago');
        } else {
            setSituacao('Ativo');
        }

    }

    function closeAll() {
        refresh()
        setModalReceitaDespesa(false)
        onClose()
    }

    useEffect(() => {
        // console.log(lancamento)
        preencherDados(lancamento)
        listarArquivos()
    }, [])

    function renderFooterConsultaLancamento() {
        //verificando se usuario tem perfil para reabir o lancamento
        if (
            usuarioAutenticado.perfil.codigo === PERFIL_ADM ||
            usuarioAutenticado.perfil.codigo === FINANCEIRO
        ) {
            return (
                <div className="right flex-1 gap-1">
                    {(lancamento.situacao === "P" || lancamento.situacao === "Pago") ? <ButtonEtrium
                        buttonType={"info"}
                        label="Reabrir"
                        id="reabrirLancamento"
                        // className="p-button-info p-button-text"
                        onClick={reabrirLancamento}/> : <></>}
                    {lancamento.situacao === "A" || lancamento.situacao === "Ativo" ? <ButtonEtrium
                        buttonType={"success"}
                        label="Pagar"
                        id="pagarLancamento"
                        onClick={consultarLancamentoPagamento}/> : <></>}
                </div>

            )
        } else return <></>
    }

    return (
        <>
            <Dialog header="Consulta lançamento"
                    visible={open}
                    style={{width: '60vw'}}
                    footer={renderFooterConsultaLancamento}
                    onHide={onClose}>
                <TabView>
                    <TabPanel header="Lançamento">
                        <div className='flex-column gap-1'>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="formGroup">
                                        <label>Categoria</label>
                                        <InputText id="categoriaConsulta"
                                                   type="text"
                                                   style={{width: '100%', height: '37px'}}
                                                   value={categoriaNome}
                                                   disabled
                                                   className="p-inputtext-sm p-d-block p-mb-1"/>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="formGroup">
                                        <label>Tipo</label>
                                        <InputText id="tipoNome"
                                                   type="text"
                                                   style={{width: '100%', height: '37px'}}
                                                   value={tipoNome}
                                                   disabled
                                                   className="p-inputtext-sm p-d-block p-mb-1"/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="formGroup">
                                        <label>Valor</label>
                                        <InputText id="valorConsulta"
                                                   type="text"
                                                   style={{width: '100%', height: '37px'}}
                                                   value={formatarMoeda(vlLancamento)}
                                                   disabled
                                                   className="p-inputtext-sm p-d-block p-mb-1"/>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="formGroup">
                                        <label>Vencimento</label>
                                        <InputText id="dataVencimentoConsulta"
                                                   type="text"
                                                   style={{width: '100%', height: '37px'}}
                                                   value={dtVencimento}
                                                   disabled
                                                   className="p-inputtext-sm p-d-block p-mb-1"/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="formGroup">
                                        <label>Situação</label>
                                        <InputText id="situacaoConsulta"
                                                   type="text"
                                                   style={{width: '100%', height: '37px'}}
                                                   value={situacao}
                                                   disabled
                                                   className="p-inputtext-sm p-d-block p-mb-1"/>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="formGroup">
                                        <label>Valor Pago</label>
                                        <InputText id="valorPago"
                                                   type="text"
                                                   style={{width: '100%', height: '37px'}}
                                                   value={formatarMoeda(vlPago)}
                                                   disabled
                                                   className="p-inputtext-sm p-d-block p-mb-1"/>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="formGroup">
                                        <label>Data do Pagamento</label>
                                        <InputText id="dataPagamento"
                                                   type="text"
                                                   style={{width: '100%', height: '37px'}}
                                                   value={dtPago}
                                                   disabled
                                                   className="p-inputtext-sm p-d-block p-mb-1"/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="formGroup">
                                        <label>Processo</label>
                                        <InputText id="processoConsulta"
                                                   type="text"
                                                   style={{width: '100%', height: '37px'}}
                                                   value={dsProcesso}
                                                   disabled
                                                   className="p-inputtext-sm p-d-block p-mb-1"/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="formGroup">
                                        <label>Descrição</label>
                                        <InputText id="descricaoConsulta"
                                                   type="text"
                                                   style={{width: '100%', height: '37px'}}
                                                   value={dsLancamento}
                                                   disabled
                                                   className="p-inputtext-sm p-d-block p-mb-1"/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="formGroup">
                                        <label>Observação</label>
                                        <InputTextarea id="observacao"
                                                       type="text"
                                                       style={{width: '100%'}}
                                                       value={observacao}
                                                       disabled
                                                       className="p-inputtext-sm p-d-block p-mb-1"
                                                       rows={2} cols={30}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel header="Arquivos">
                        <Arquivos arquivos={arquivos} toast={toast} lancamento={lancamento} refresh={listarArquivos}/>
                    </TabPanel>
                </TabView>
            </Dialog>
            {modalReceitaDespesa &&
                <PagamentoReceitasDespesasDialog
                    open={modalReceitaDespesa}
                    onClose={() => setModalReceitaDespesa(false)}
                    toast={toast}
                    lancamento={lancamento}
                    tipo={tipo}
                    refresh={closeAll}
                />}
        </>
    )
}
