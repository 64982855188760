import React, {useEffect, useState} from 'react';
import ConfiguracaoNotificacaoService from "../../app/service/ConfiguracaoNotificacaoService";
import {AuthContext} from "../../main/ProvedorAutenticacao";
import {InputSwitch} from "primereact/inputswitch";

const ConfiguracaoNotificacaoComponent = ({toast}) => {

    const [configuracao, setConfiguracao] = useState({
        cdUsuario: 0, // Substitua pelo valor do usuário atual, se aplicável
        atividades: false,
        processos: false,
        buscaAutomaticaProcesso: false,
        parecer: false
    });

    const {atividades, processos, buscaAutomaticaProcesso, parecer} = configuracao;

    const handleRadioChange = (campo, valor) => {
        alterarConfiguracao(campo, valor)
    };

    const {usuarioAutenticado} = React.useContext(AuthContext);
    const configService = new ConfiguracaoNotificacaoService()

    function findConfigs() {
        configService.consultar(usuarioAutenticado.codigo)
            .then(r => {
                let data = r.data
                preencherDados(data)

            })
            .catch(error => {
                let detail = error.response.data.detail || "Erro ao carregar os dados."
                toast.show({severity: 'error', summary: 'Configurações', detail: `${detail}`, life: 4000});
            })

    }

    function alterarConfiguracao(campo, valor) {
        let data = configuracao
        data[campo] = valor
        configService.alterar(data.cdUsuario, data)
            .then(r => {
                let data = r.data
                preencherDados(data)
            })
            .catch(error => {
                let detail = error.response.data.detail || "Erro ao alterar os dados."
                toast.show({severity: 'error', summary: 'Configurações', detail: `${detail}`, life: 4000});
            })
    }

    function preencherDados(data) {
        setConfiguracao(data)
    }

    useEffect(() => {
        findConfigs()
    }, []);


    const txtStyleAtividades = atividades ? "text-black" : "text-secondary"
    const txtStyleProcessos = processos ? "text-black" : "text-secondary"
    const txtStyleBPA = buscaAutomaticaProcesso ? "text-black" : "text-secondary"
    const txtStyleParecer = parecer ? "text-black" : "text-secondary"
    return (
        // <div className="container">
        <div className="col-sm-12 " >
            <div className="row align-center">
                <h5 className="col-sm-12 text-black text-lg">Configurações de notificação</h5></div>
            <div className={"row align-center"}>

                <div className="col-sm-10">
                    <label htmlFor="atividadesAtivar" className={`${txtStyleAtividades}`}>
                        Notificações de atividade</label>
                </div>
                <div className="col-sm-2">
                    <InputSwitch id="atividadesAtivar" checked={atividades}
                                 onChange={(e) => handleRadioChange('atividades', e.value)}/>
                </div>

            </div>
            <div className="row align-center">
                <div className="col-sm-10">
                    <label htmlFor="processosAtivar" className={`${txtStyleProcessos}`}>
                        Notificações de processos
                    </label>
                </div>
                <div className="col-sm-2 ">
                    <InputSwitch id="processosAtivar" checked={processos}
                                 onChange={(e) => handleRadioChange('processos', e.value)}/>
                </div>
            </div>
            <div className="row align-center">
                <div className="col-sm-10">
                    <label htmlFor="buscaAutomaticaProcessoAtivar" className={`${txtStyleBPA}`}>
                        Notificações de busca
                        de
                        processos
                    </label>
                </div>
                <div className="col-sm-2 ">
                    <InputSwitch id="buscaAutomaticaProcessoAtivar" checked={buscaAutomaticaProcesso}
                                 onChange={(e) => handleRadioChange('buscaAutomaticaProcesso', e.value)}/>
                </div>
            </div>
            <div className="row align-center">
                <div className="col-sm-10">
                    <label htmlFor="parecerAtivar" className={`${txtStyleParecer}`}>
                        Notificações de parecer
                    </label>
                </div>
                <div className="col-sm-2 ">
                    <InputSwitch id="parecerAtivar" checked={parecer}
                                 onChange={(e) => handleRadioChange('parecer', e.value)}/>
                </div>
            </div>
        </div>
        // </div>
    )
    // return (
    //     <div className="container">
    //         {configuracao && configuracao.cdUsuario > 0 &&
    //             <div className="col-sm-12">
    //                 {/*<h1 className="text-center mb-4">Configurações de Notificação</h1>*/}
    //
    //                 {/* Configuração de Atividades */}
    //                 <div className="mb-3">
    //                     {/*<h5>Atividades</h5>*/}
    //                     <div className="d-flex align-items-center">
    //                         <RadioButton
    //                             inputId="atividadesAtivar"
    //                             name="atividades"
    //                             value={true}
    //                             onChange={() => handleRadioChange('atividades', true)}
    //                             checked={atividades}
    //                         />
    //                         <label htmlFor="atividadesAtivar" className={`mr-2 ${txtStyleAtividades}`}>Notificações de
    //                             atividade</label>
    //                     </div>
    //                     <div className="d-flex align-items-center mt-1">
    //                         <RadioButton
    //                             inputId="atividadesDesativar"
    //                             name="atividades"
    //                             value={false}
    //                             onChange={() => handleRadioChange('atividades', false)}
    //                             checked={!atividades}
    //                         />
    //                         <label htmlFor="atividadesDesativar" className="ml-2">Desativar Notificações de
    //                             Atividade</label>
    //                     </div>
    //                 </div>
    //
    //                 {/* Configuração de Processos */}
    //                 <div className="mb-3">
    //                     <div className="d-flex align-items-center">
    //
    //                         <RadioButton
    //                             inputId="processosAtivar"
    //                             name="processos"
    //                             value={true}
    //                             onChange={() => handleRadioChange('processos', true)}
    //                             checked={processos}
    //                         />
    //                         <label htmlFor="processosAtivar" className="ml-2">Ativar Notificações de Processos</label>
    //                     </div>
    //                     <div className="d-flex align-items-center mt-1">
    //                         <RadioButton
    //                             inputId="processosDesativar"
    //                             name="processos"
    //                             value={false}
    //                             onChange={() => handleRadioChange('processos', false)}
    //                             checked={!processos}
    //                         />
    //                         <label htmlFor="processosDesativar" className="ml-2">Desativar Notificações de
    //                             Processos</label>
    //                     </div>
    //                 </div>
    //
    //                 {/* Configuração de Busca Automática de Processos */}
    //                 <div title="Busca Automática de Processos" className="mb-3">
    //                     <div className="d-flex align-items-center">
    //                         <RadioButton
    //                             inputId="buscaAutomaticaProcessoAtivar"
    //                             name="buscaAutomaticaProcesso"
    //                             value={true}
    //                             onChange={() => handleRadioChange('buscaAutomaticaProcesso', true)}
    //                             checked={buscaAutomaticaProcesso}
    //                         />
    //                         <label htmlFor="buscaAutomaticaProcessoAtivar" className="ml-2">Ativar Notificações de Busca
    //                             de
    //                             Processos</label>
    //                     </div>
    //                     <div className="d-flex align-items-center mt-1">
    //                         <RadioButton
    //                             inputId="buscaAutomaticaProcessoDesativar"
    //                             name="buscaAutomaticaProcesso"
    //                             value={false}
    //                             onChange={() => handleRadioChange('buscaAutomaticaProcesso', false)}
    //                             checked={!buscaAutomaticaProcesso}
    //                         />
    //                         <label htmlFor="buscaAutomaticaProcessoDesativar" className="ml-2">Desativar Notificações de
    //                             Busca de Processos</label>
    //                     </div>
    //                 </div>
    //
    //                 {/* Configuração de Parecer */}
    //                 <div title="Notificações de Parecer" className="mb-3">
    //                     <div className="d-flex align-items-center">
    //                         <RadioButton
    //                             inputId="parecerAtivar"
    //                             name="parecer"
    //                             value={true}
    //                             onChange={() => handleRadioChange('parecer', true)}
    //                             checked={parecer}
    //                         />
    //                         <label htmlFor="parecerAtivar" className="ml-2">Ativar Notificações de Parecer</label>
    //                     </div>
    //                     <div className="d-flex align-items-center mt-1">
    //                         <RadioButton
    //                             inputId="parecerDesativar"
    //                             name="parecer"
    //                             value={false}
    //                             onChange={() => handleRadioChange('parecer', false)}
    //                             checked={!parecer}
    //                         />
    //                         <label htmlFor="parecerDesativar" className="ml-2">Desativar Notificações de Parecer</label>
    //                     </div>
    //                 </div>
    //             </div>}
    //         <div className="row">
    //             <div className="col-6 col-md-6">
    //                 <label htmlFor="processosAtivar" className={`${txtStyleProcessos}`}>
    //                     Notificações de Processos
    //                 </label>
    //             </div>
    //             <div className="col-6 col-md-6">
    //                 <InputSwitch id="processosAtivar" checked={processos}
    //                              onChange={(e) => handleRadioChange('processos', e.value)}/>
    //             </div>
    //         </div>
    //     </div>
    // );
};

export default ConfiguracaoNotificacaoComponent;
