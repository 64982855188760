import ApiService from '../apiservice'

export default class GrupoTrabalhoService extends ApiService {
  constructor() {
    super('/gruposTrabalho');
  }

  listarGrupos(empresa) {
    let params = `?empresa=${empresa}`
    return this.get(params);
  }

  listarGruposNome(filtro) {
    let params = `?empresa=${filtro.empresa}&nome=${filtro.grupoConsulta}`
    return this.get(params);
  }

  cadastrarGrupo(grupo) {
    return this.post('', grupo);
  }

  cadastrarGrupoNovoUsuario(codigoEmpresa) {
    return this.post(`/novousuario/${codigoEmpresa}`);
  }

  consultarGrupo(codigo) {
    return this.get(`/${codigo}`);
  }

  alterarGrupo(grupo) {
    return this.put(`/${grupo.codigo}`, grupo);
  }

  excluirGrupo(codigo) {
    return this.delete(`/${codigo}`);
  }

  excluirSubGrupo(codigo) {
    return this.delete(`/subGrupo/${codigo}`)
  }

  listarSubGrupos2(codigo) {
    return this.get(`/usuariosSemGrupo/${codigo}`)
  }

  listarSubGrupos(grupoPai) {
    return this.get(`/subGrupos/${grupoPai}`);
  }

  cadastrarSubGrupo(subGrupo) {
    return this.post('/subGrupo', subGrupo)
  }

  listarGruposSemUsuario(filtroGrupo) {
    return this.get(`/gruposSemUsuarios?empresa=${filtroGrupo.empresa}&usuario=${filtroGrupo.usuario}`);
  }

  listarGruposUsuario(usuario) {
    return this.get(`/gruposUsuario/${usuario}`);
  }
}