export const stepsGenerico = [
  {
    selector: '#body',
    title: "Aviso!",
    content: (
      <>
        <div>O tour (tutorial) só está disponível para algumas páginas específicas. Aqui está a lista das páginas suportadas:</div>
        <br />
        <ul>
          <li>Agenda</li>
          <li>Cadastrar Processo</li>
          <li>Processos</li>
          <li>Processos Excluídos</li>
          <li>Cadastrar Pessoa</li>
          <li>Pessoas</li>
          <li>Painel Financeiro</li>
          <li>Relatório de Processos</li>
          <li>Relatórios Financeiros</li>
          <li>Gráficos de Atividades</li>
          <li>Painel de Processo</li>
          <li>Usuários</li>
          <li>Grupo de Trabalho</li>
          <li>Suporte ao Usuário</li>
          <li>Parecer</li>
          <li>Detalhes do Parecer</li>
        </ul>
      </>
    ),
    style: {
      'width': '460px',
      left: '43%',
      top: '10%',
    
    }
  },
];



export const stepsHome = [
  {
    selector: 'body',
    title: "Bem-Vindo!",
    content: 'Este tour foi criado para ajudá-lo a se familiarizar com a plataforma e começar a utilizar seus recursos da melhor maneira possível. Você pode iniciar o tour novamente clicando no seu nome no canto superior direito da tela e selecionando "Iniciar Tour".',
    style: {
      'width': '460px',
      left: '45%',
      top: '30%',
      padding: '10px',
      "box-shadow": "0 0 0 9999px rgba(0, 0, 0, 0.5)"
    }
  },
  {
    selector: '#CadProcessoNav',
    title: "Cadastro de Processo",
    content: 'Clique neste ícone para ser direcionado automaticamente para a tela de cadastro de processo automático.',
    style: {
      'width': '360px',
      top: '-0.5%',
      'margin-left': '-224px',
    }
  },
  {
    selector: '#CadAtvdNav',
    title: "Cadastro de Atividade",
    content: 'Clique neste ícone para cadastrar uma atividade sem precisar sair da tela atual.',
    style: {
      'width': '360px',
      top: '-0.5%',
      'margin-left': '-224px',
    }
  },
  {
    selector: '#CadNotiNav',
    title: "Notificações",
    content: 'Clique aqui para visualizar as notificações divididas em Geral e Movimentações do Push.',
    style: {
      'width': '360px',
      'margin-left': '-230px',
      top: '-0.5%',
    }
  },
  {
    selector: '#NameUserNav',
    title: "Perfil do Usuário",
    content: 'Aqui você pode visualizar seu perfil, alterar sua senha de acesso, sair do sistema, iniciar ou retornar ao tour.',
    style: {
      'width': '360px',
      'margin-left': '-240px',
      top: '-0%',
    }
  },
  {
    selector: '#FiltrarPorGT',
    title: "Grupos de Trabalho",
    content: 'Filtre as atividades por grupo de trabalho, podendo selecionar um ou mais grupos.',
  },
  {
    selector: '#FiltrarPorUser',
    title: "Filtrar por Usuário",
    content: 'Além de filtrar por grupo de trabalho, você pode selecionar as atividades por usuário dentro dos grupos de trabalho selecionados.',
  },
  {
    selector: '#mask',
    title: "Filtrar por Período",
    content: 'A Etrium permite filtrar por um período de tempo de até 6 meses.',
    style: {
      'width': '260px',
    }
  },
  {
    selector: '#LinkAdm',
    title: "Menu Administração",
    content: 'Neste item do menu, você encontra sub-itens como Usuários e Grupo de Trabalho.',
    scrollItem: -1500,
    style: {
      'width': '360px',
      'margin-left': '250px',
      'margin-top': '-70px',
    }
  },
  {
    selector: 'body',
    title: "Conclusão",
    content: 'Parabéns por concluir a primeira etapa do tutorial!',
    style: {
      'width': '260px',
      left: '50%',
      top: '40%',
      padding: '10px',
      
    }
  },
];



export const stepsAgenda = [
  {
    selector: 'body',
    title: "Visão Geral da Agenda",
    content: 'Visualize os compromissos na agenda, com suas situações representadas por diferentes cores.',
    style: {
      'width': '360px',
      left: '50%',
      top: '20%',
      padding: '10px',
      "box-shadow": "0 0 0 9999px rgba(0, 0, 0, 0.5)"
    }
  },
  {
    selector: '#filtroSelects',
    title: "Filtrar Compromissos",
    content: 'Filtre os compromissos por Grupo de Trabalho, Usuário e Situação.',
    style: {
      'width': '360px',
      left: '58%',
    }
  },
];


export const stepsCadProcesso = [
  {
    selector: 'body',
    title: "Cadastro Automático de Processo",
    content: 'Nesta tela, é possível cadastrar um processo de forma automática, inserindo o Número e o sistema.',
    style: {
      'width': '460px',
      left: '45%',
      top: '70%',
      padding: '10px',
      "box-shadow": "0 0 0 9999px rgba(0, 0, 0, 0.5)"
    }
  },
  {
    selector: '#BtnCadProcessoManual',
    title: "Cadastro Manual de Processo",
    content: 'Para um cadastro mais detalhado, clique aqui para Cadastrar processo manualmente.',
    style: {
      'width': '360px'
    }
  },
];


export const stepsProcessoeCasos = [
  {
    selector: 'body',
    title: "Consulta de Processo",
    content: 'Nesta tela, é possível consultar um processo digitando pelo menos 3 caracteres, podendo buscar pelo Processo original e pela numeração única.',
    style: {
      'width': '460px',
      left: '45%',
      top: '70%',
      padding: '10px',
      "box-shadow": "0 0 0 9999px rgba(0, 0, 0, 0.5)"
    }
  },
  {
    selector: '#FiltroAvancado',
    title: "Filtros Avançados",
    content: 'Você também pode aplicar filtros para buscar processos, selecionando a Pessoa, Pasta, Número CNJ, Número do processo, Grupo de Trabalho, Tipos de ação, Status Processual, Tipo de decisão e/ou Processos designados como Importante para o usuário.',
    style: {
      'width': 'auto',
    }
  },
  {
    selector: '#InfosTable',
    title: "Informações do Processo",
    content: 'Após aplicar os filtros, as seguintes informações do(s) processo(s) serão exibidas: Pasta, Processo Original, Numeração única, Autor e Réu, Grupo de Trabalho. Também é possível excluir esse(s) processo(s).',
    style: {
      'width': 'auto',
      'top': 'calc(100% + 4px)',
    }
  },
];


export const stepsProcessoExcluidos = [
  {
    selector: '#ProcessosExcluidosList',
    title: "Lista de Processos Excluídos",
    content: 'Nesta seção, você pode visualizar a lista de processos excluídos, incluindo o Número CNJ, Número do Processo, Pasta e Grupo de Trabalho. É possível reativar um processo excluído, se necessário.',
    style: {
      'width': '660px',
      'top': '95%',
    }
  },
];


export const stepsPessoas = [
  {
    selector: 'body',
    title: "Cadastro de Pessoas",
    content: 'Nesta seção, é possível cadastrar uma pessoa preenchendo os campos obrigatórios e opcionais, incluindo informações pessoais, complementares, documentação e anexando arquivos relacionados a essa pessoa.',
    style: {
      'width': '560px',
      left: '40%',
      top: '30%',
      padding: '10px',
      "box-shadow": "0 0 0 9999px rgba(0, 0, 0, 0.5)"
    }
  },
];


export const stepsPessoasPesquisa = [
  {
    selector: 'body',
    title: "Consulta de Pessoas",
    content: 'Nesta tela, é possível consultar pessoas inserindo no mínimo 3 caracteres.',
    style: {
      'width': '380px',
      left: '40%',
      top: '30%',
      padding: '10px',
      "box-shadow": "0 0 0 9999px rgba(0, 0, 0, 0.5)"
    }
  },
  {
    selector: '#FiltroPorLetra',
    title: "Filtro por Letra",
    content: 'Também é possível filtrar as pessoas clicando na letra inicial desejada.',
    style: {
      'width': '460px',
      'margin-top': '50px'
    }
  },
  {
    selector: '#FiltroAvancado',
    title: "Filtros Avançados",
    content: 'Aplicar filtros avançados como Nome, CPF/CNPJ, Estado, Cidade e/ou Grupo de pessoas.',
    style: {
      'width': '460px'
    }
  },
  {
    selector: '#TableResults',
    title: "Resultados da Pesquisa",
    content: 'Após utilizar os filtros, será exibida a lista das pessoas cadastradas de acordo com os critérios escolhidos. Também é possível Editar ou Excluir a pessoa selecionada.',
    style: {
      'width': 'auto',
      'top': '93%'
    }
  },
];


export const stepsFinanceiro = [
  {
    selector: '#CadastrarLancamentoBtn',
    title: "Cadastrar Lançamento",
    content: 'Nesse botão, é possível cadastrar um lançamento futuro, seja uma despesa ou receita, com a opção de anexar arquivos.',
    style: {
      'width': '460px',
    }
  },
  {
    selector: '#MudarPeriodoBtn',
    title: "Filtrar por Período",
    content: 'Aqui, o usuário pode filtrar os lançamentos por um período de até 6 meses.',
    style: {
      'width': '360px',
      "margin-left": "-205px"
    }
  },
  {
    selector: '#CardDespesas',
    title: "Saldo de Despesas",
    content: 'Este card apresenta o saldo de todas as despesas. Ao clicar, é possível visualizar e filtrar despesas por tipo e situação, além de cancelar despesas selecionadas.',
    style: {
      'width': '460px',
      'margin-top': '50px'
    }
  },
  {
    selector: '#CardReceitas',
    title: "Saldo de Receitas",
    content: 'Aqui estão todos os lançamentos categorizados como receitas. Ao clicar, é possível visualizar e filtrar receitas por tipo e situação, além de cancelar receitas selecionadas.',
    style: {
      'width': '460px',
      'margin-top': '50px'
    }
  },
  {
    selector: '#CardLancamentos',
    title: "Lançamentos Futuros",
    content: 'Este card mostra os lançamentos futuros previstos. É possível filtrar por categoria (Despesa ou Receita) e tipo, além de cancelar lançamentos selecionados.',
    style: {
      'width': '460px',
      'margin-top': '50px'
    }
  },
  {
    selector: '#CardAtrasos',
    title: "Lançamentos em Atraso",
    content: 'Neste card, é possível visualizar lançamentos em atraso, com filtros por categoria e tipo.',
    style: {
      'width': '360px',
      'margin-top': '50px',
      
      
    }
  },
  {
    selector: '#CardSaldos',
    title: "Saldo Geral",
    content: 'Aqui está o saldo geral dos lançamentos, calculando o valor total das despesas e receitas.',
    style: {
      'width': '360px',
      'margin-top': '50px',
      "margin-left": "-170px"
    }
  },
];


export const stepsRelatoriosProcesso = [
  {
    selector: 'body',
    title: "Emitir Relatório de Processos",
    content: 'Nesta tela do Sistema Etrium, é possível emitir relatórios de processos. Utilize os campos disponíveis para filtrar e encontrar os processos desejados.',
    style: {
      'width': '460px',
      left: '43%',
      top: '15%',
      padding: '10px',
      "box-shadow": "0 0 0 9999px rgba(0, 0, 0, 0.5)"
    }
  },
  {
    selector: '#CamposExibicao',
    title: "Selecionar Campos para Exibição",
    content: 'Após filtrar os processos, você pode escolher quais campos serão exibidos no relatório, podendo selecionar entre um e todos os campos disponíveis.',
    style: {
      'width': '460px',
      'top': '50%'
    }
  },
  {
    selector: '#EmitirRelatorioBtn',
    title: "Emitir Relatório",
    content: 'Clique aqui para emitir o relatório com os campos selecionados após preencher os filtros necessários.',
    style: {
      'width': '460px',
      'top': '60%'
    }
  },
];


export const stepsRelatoriosFinanceiro = [
  {
    selector: 'body',
    title: "Consulta de Lançamentos Financeiros",
    content: 'Nesta tela, é possível consultar lançamentos financeiros de forma detalhada ou resumida. Preencha os campos obrigatórios (Data inicial e final) e, se desejar, outros campos opcionais. Ao clicar em Consultar, todos os lançamentos de acordo com os critérios preenchidos serão exibidos.',
    style: {
      'width': '560px',
      left: '40%',
      top: '15%',
      padding: '10px',
      "box-shadow": "0 0 0 9999px rgba(0, 0, 0, 0.5)"
    }
  }
];



export const stepsPainelAtividades = [
  {
    title: 'Visão Geral das Atividades',
    selector: 'body',
    content: 'No Painel de Atividades, visualize gráficos de barra organizados por mês do ano atual, destacando as atividades de forma precisa.',
    style: {
      'width': '460px',
      left: '43%',
      top: '15%',
      padding: '10px',
      "box-shadow": "0 0 0 9999px rgba(0, 0, 0, 0.5)"
    }
  },
  {
    title: 'Filtragem por Tempo',
    selector: '#FiltroTempo',
    content: 'Selecione um período de tempo, aplique filtros e visualize as atividades correspondentes por status, situação e grupo de trabalho em gráficos separados.',
    style: {
      'margin-top': '50px',
      'width': '560px',
    }
  },
  {
    title: 'Atividades por Usuário',
    selector: '#ResultsUsers',
    content: 'Veja as atividades atribuídas a cada usuário em dois gráficos: um que detalha a quantidade de atividades e outro que mostra a situação atual.',
    style: {
      'top': '32%',
      'width': 'auto',
    }
  },
  {
    title: 'Desempenho Individual',
    selector: '#NameUser',
    content: 'Ao clicar no usuário, você será direcionado para outra tela para analisar o desempenho individual de cada um.',
    style: {
      'width': 'auto',
    }
  },
];



export const stepsPainelProcesso = [
  {
    title: 'Visão Geral dos Processos',
    selector: 'body',
    content: 'Veja a situação geral dos processos cadastrados, agrupados por cards e organizados em gráficos para uma visualização completa.',
    style: {
      'width': '460px',
      left: '43%',
      top: '15%',
      padding: '10px',
      "box-shadow": "0 0 0 9999px rgba(0, 0, 0, 0.5)"
    }
  },
  {
    title: 'Processos Ativos',
    selector: '#CardAtivos',
    content: 'Visualize os processos ativos, abra ou exclua processos diretamente neste card.',
    style: {
      'margin-top': '90px',
      'width': '360px',
    }
  },
  {
    title: 'Processos Parados',
    selector: '#CardParados',
    content: 'Veja os processos parados há até 90 dias, com a opção de abrir ou excluir processos.',
    style: {
      'margin-top': '90px',
      'width': '360px',
    }
  },
  {
    title: 'Processos Arquivados',
    selector: '#CardArquivados',
    content: 'Visualize os processos arquivados, com a opção de abrir ou excluir processos.',
    style: {
      'margin-top': '90px',
      'width': '360px',
    }
  },
  {
    title: 'Processos no Push',
    selector: '#CardPush',
    content: 'Verifique os processos adicionados no Push, podendo abrir ou excluir processos.',
    style: {
      'margin-top': '90px',
      'width': '360px',
    }
  }
];



export const stepsUsuarios = [
  {
    title: 'Cadastro de Usuários',
    selector: 'body',
    content: 'Na tela de usuários, você pode cadastrar novos usuários preenchendo os campos obrigatórios com as informações necessárias.',
    style: {
      'width': '460px',
      left: '43%',
      top: '15%',
      padding: '10px',
      "box-shadow": "0 0 0 9999px rgba(0, 0, 0, 0.5)"
    }
  },
  {
    title: 'Consulta de Usuários',
    selector: '#ConsultaUser',
    content: 'Consulte usuários digitando pelo menos 3 caracteres na busca. Você pode editar, adicionar a um grupo de trabalho ou excluir o usuário encontrado.',
    style: {'width': '460px',
      padding: '10px',}
  },
];


export const stepsGT = [
  {
    title: 'Cadastro de Grupos de Trabalho',
    selector: 'body',
    content: 'Na tela de Grupos de Trabalho, você pode cadastrar novos grupos preenchendo os campos obrigatórios com as informações necessárias.',
    style: {
      'width': '460px',
      left: '43%',
      top: '15%',
      padding: '10px',
      "box-shadow": "0 0 0 9999px rgba(0, 0, 0, 0.5)"
    }
  },
  {
    title: 'Consulta de Grupos de Trabalho',
    selector: '#ConsultaGT',
    content: 'Consulte grupos de trabalho digitando pelo menos 3 caracteres na busca. Você pode editar, adicionar usuários, subgrupos ou excluir o grupo encontrado.',
    style: {}
  },
];



export const stepsSuporte = [
  {
    title: 'Cadastro e Consulta de Suporte',
    selector: 'body',
    content: 'Na tela de suporte, você pode cadastrar um novo suporte para qualquer contratempo encontrado no sistema Etrium. Também é possível consultar suportes existentes digitando pelo menos 3 caracteres.',
    style: {
      'width': '460px',
      left: '43%',
      top: '60%',
      padding: '10px',
      "box-shadow": "0 0 0 9999px rgba(0, 0, 0, 0.5)"
    }
  },
];

export const stepsParecer = [
  {
    title: 'Cadastro de Parecer',
    selector: '#CadParecer',
    content: 'Cadastre um parecer preenchendo os campos necessários e clicando em cadastrar.',
    style: {
      'width': '460px'
    }
  },
  {
    title: 'Filtro de Pareceres',
    selector: '#FiltroAv',
    content: 'Filtre os pareceres usando critérios como Data início e final, Pessoa, Protocolo citrus, Data de recebimento inicial e final, tipo e status do parecer.',
    style: {
      'width': '460px'
    }
  },
  {
    title: 'Exportar Pareceres',
    selector: '#BtnExp',
    content: 'Após aplicar os filtros desejados, clique no botão Exportar para obter uma planilha Excel com os pareceres filtrados.',
    style: {
      'width': '360px',
      "margin-left": "-245px"
    }
  },
  {
    title: 'Visualização de Pareceres',
    selector: '#TableParecer',
    content: 'Veja os pareceres já criados com suas respectivas informações.',
    style: {
      'width': '460px',
      left: '43%',
      
      top: '95%'
    }
  },
  {
    title: 'Detalhe do Parecer',
    selector: '#TableParecer',
    content: 'Clique em um parecer para ser direcionado à tela Detalhe Parecer, onde você verá informações mais detalhadas.',
    style: {
      'width': '460px',
      left: '43%',
      top: '30%',
      top: '95%'
    }
  },
];





export const stepsParecerDetalhe = [
  {
    selector: '#DetalheParecer',
    content: 'Ainda nessa tela, temos a visibilidade das mensagens trocadas entre o usuário Responsável e o usuário de Solicitação.',
    style: {
      'width': '460px',
      left: '43%',
      top: '60%',
      
    }
  },
];
