import ApiService from '../apiservice'

export default class StatusAtividadeService extends ApiService {
  constructor() {
    super('/statusAtividade')
  }

  listarStatus(empresa) {
    return this.get(`/all`);
  }
}