import React from 'react'
import { ProgressBar } from 'primereact/progressbar'
import '../../src/pages/gruposTrabalho/grupos.css'

export default class Progresso extends React.Component{

  render() {
    return(
      <div className={this.props.classeSecundaria}>
        <br/>
        <div>
            <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
        </div>
      </div>
    );
  }
}
