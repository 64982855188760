import NavBar from "../../componentes/Navbar";
import {Toast} from "primereact/toast";
import React, {useEffect, useRef, useState} from "react";
import {ButtonSm} from "../../componentes/ButtonSm";
import {strings} from "../../utils/strings";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {CadastrarObjetoAcaoDialog} from "../../componentes/ObjetosAcaoComponent/CadastrarObjetoAcaoDialog";
import ObjetoAcaoService from "../../app/service/ObjetoAcaoService";
import {AlterarObjetoAcaoDialog} from "../../componentes/ObjetosAcaoComponent/AlterarObjetoAcaoDialog";
import {confirmDialog} from "primereact/confirmdialog";


export const SubObjetos = (props) => {

    const [subObjetos, setSubObjetos] = useState([])
    const [objetoPai, setObjetoPai] = useState(null)
    const [dialogCadastro, setDialogCadastro] = useState(false)
    const [dialogAlterar, setDialogAlterar] = useState(false)
    const [objetoAlterar, setObjetoAlterar] = useState(null)

    const toast = useRef(null)
    const objetoAcaoService = new ObjetoAcaoService();

    function getObjetosPorObjetoPai() {
        const params = props.match.params;
        if (params.codigo) {
            objetoAcaoService.listarSubObjetosAcao(params.codigo).then((res) => {
                setSubObjetos(res.data)
            })
                .catch(error => {
                    let detail = error.response.detail
                    toast.current.show({
                        severity: 'error',
                        summary: 'Objetos de ação',
                        detail: `${detail}`,
                        life: 4000
                    });
                })

        }
    }

    function getObjetoPai() {
        const params = props.match.params;
        if (params.codigo) {
            objetoAcaoService.consultarObjeto(params.codigo).then((res) => {
                setObjetoPai(res.data)
            })
                .catch(error => {
                    let detail = error.response.detail
                    toast.current.show({
                        severity: 'error',
                        summary: 'Objetos de ação',
                        detail: `${detail}`,
                        life: 4000
                    });
                })

        }
    }

    function firstLoad() {
        getObjetosPorObjetoPai()
        getObjetoPai()
    }

    function editar(objeto) {
        if (objeto != null) {
            // objetoAcaoService.consultarObjeto(objeto.codigo)
            //     .then(response => {
            //      setObjetoAlterar(response.data)
            //      setDialogAlterar(true)
            //     }).catch(error => {
            //     let detail = error.response.detail
            //     toast.current.show({severity:'error', summary: 'Objetos de ação', detail:`${detail}`, life: 4000});
            // })
            setObjetoAlterar(objeto)
            setDialogAlterar(true)

        }
    }

    const voltar = () => {
        props.history.push('/objetosAcao')
    }
    function confirmaExclusao(objeto) {
        confirmDialog({
            message: 'Deseja realmente excluir o objeto de ação?',
            header: 'Exclusão de objeto de ação',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: () => accept(objeto.codigo)

        });
    }

    function accept(codigo) {
        objetoAcaoService.excluirObjeto(codigo)
            .then(() => {
                toast.current.show({
                    severity: 'success',
                    summary: 'Objetos de ação',
                    detail: 'Objeto de ação excluído com sucesso',
                    life: 4000
                });
                getObjetosPorObjetoPai();
            }).catch(error => {
            let detail = error.response.detail
            toast.current.show({
                severity: 'error',
                summary: 'Objetos de ação',
                detail: `${detail}`,
                life: 4000
            });
        })
    }


    useEffect(firstLoad, [])
    const acoes = (rowData) => {
        return (
            <div className="row gap-1">
                <Button icon="pi pi-pencil" className={strings.buttonIconSecundary}
                        onClick={() => editar(rowData)}
                        tooltip="Editar objeto"
                        tooltipOptions={{position: 'top'}}/>
                <Button icon="pi pi-trash" className={strings.buttonIconDanger}
                    onClick={() => confirmaExclusao(rowData)}
                        tooltip="Excluir objeto"
                        tooltipOptions={{position: 'top'}}/>

            </div>
        );
    }
    return (
        <>
            <NavBar/>
            <Toast ref={toast}/>
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-primary card-outline">
                                    <div className="card-header">
                                        <h3 className="card-title">
                                            <i className="far fa-object-ungroup"></i>
                                            <span> Sub-objetos ação - ({objetoPai && objetoPai.nome})</span>
                                        </h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-4 flex-1 gap-1">
                                                <ButtonSm type="button" className={strings.buttonPrimarySm}
                                                          data-toggle="modal"
                                                          onClick={() => setDialogCadastro(true)}
                                                          data-target="#modal-default">
                                                    <i className="fas fa-plus"></i>Cadastrar</ButtonSm>
                                                <ButtonSm type="button" className={strings.buttonSecondarySm}
                                                          data-toggle="modal"
                                                    onClick={voltar}
                                                          data-target="#modal-default">
                                                    Voltar</ButtonSm>
                                            </div>
                                        </div>
                                    </div>

                                    <DataTable value={subObjetos} paginator responsiveLayout="scroll"
                                               paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                               currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}"
                                               rows={10} emptyMessage="Nenhum objeto">
                                        <Column field="nome" header="Nome"></Column>
                                        <Column field="descricao" header="Descrição"></Column>
                                        <Column key="codigo"></Column>
                                        <Column body={acoes} exportable={false} style={{minWidth: '8rem'}}
                                                header="Ações">
                                        </Column>
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {dialogCadastro && <CadastrarObjetoAcaoDialog objetoPai={objetoPai}
                                                          toast={toast.current}
                                                          open={dialogCadastro}
                                                          close={() => {
                                                              setDialogCadastro(false)
                                                          }}
                                                          refresh={getObjetosPorObjetoPai}


            ></CadastrarObjetoAcaoDialog>
            }
            {dialogAlterar && <AlterarObjetoAcaoDialog objeto={objetoAlterar}
                                                       toast={toast.current}
                                                       open={dialogAlterar}
                                                       close={() => {
                                                           setDialogAlterar(false)
                                                       }}
                                                       refresh={getObjetosPorObjetoPai}


            ></AlterarObjetoAcaoDialog>
            }
        </>
    );
}
export default SubObjetos
