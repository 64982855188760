import React from 'react'

import {withRouter} from 'react-router-dom'

import {Toast} from 'primereact/toast';
import {Calendar} from 'primereact/calendar'
import {Dropdown} from 'primereact/dropdown'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {InputText} from 'primereact/inputtext'
import {Button} from 'primereact/button'
import {addLocale} from 'primereact/api'
import {TabPanel, TabView} from 'primereact/tabview';
import {Dialog} from 'primereact/dialog'
import {InputTextarea} from 'primereact/inputtextarea'

import NavBar from '../../componentes/Navbar'
import Upload from '../../componentes/Upload/Index'
import FileList from '../../componentes/FileList/index'
import {InputNumber} from 'primereact/inputnumber'

import FinanceiroService from '../../app/service/FinanceiroService'
import TipoDespesaReceitaService from '../../app/service/TipoDespesaReceita'
import {AuthContext} from '../../main/ProvedorAutenticacao'

import ArquivoFinanceiroService from '../../app/service/ArquivoFinanceiroService'
import ProcessoService from '../../app/service/ProcessoService'
import {DateFormat} from "../../utils/DateFormat";
import {strings} from "../../utils/strings";
import ReactToPrint from "react-to-print";
import {ComponentToPrint} from "./ComponentToPrint";
import {FiltroRelatorioFinanceiro} from "../../utils/FiltroRelatorioFinanceiro";
import {InputPesquisaProcessoFinanceiro} from "../../componentes/Inputs/InputPesquisaProcessoFinanceiro";
import {Loading} from "../../componentes/Loading";
import {formatarMoeda} from "../../utils/formatar-moeda-tela";

class RelatorioFinanceiro extends React.Component {
    constructor(props) {
        super(props);

        this.financeiroService = new FinanceiroService();
        this.processoService = new ProcessoService();
        this.tipoDespesaReceitaService = new TipoDespesaReceitaService();
        this.arquivoFinanceiroService = new ArquivoFinanceiroService();

        this.onChangeCategoria = this.onChangeCategoria.bind(this);
        this.onChangeTipo = this.onChangeTipo.bind(this);
        this.onChangeSituacao = this.onChangeSituacao.bind(this);
        this.onChangeSituacaoResumido = this.onChangeSituacaoResumido.bind(this);
        this.onChangeSaida = this.onChangeSaida.bind(this);
        this.onChangeClassificacao = this.onChangeClassificacao.bind(this);
        this.onChangeOrdem = this.onChangeOrdem.bind(this);
        this.onChangeRelatorio = this.onChangeRelatorio.bind(this);
        this.renderFooterConsultaLancamento = this.renderFooterConsultaLancamento.bind(this);
        this.acoesLancamento = this.acoesLancamento.bind(this);
        this.renderFooterProcesso = this.renderFooterProcesso.bind(this);
        this.acoesProcesso = this.acoesProcesso.bind(this);

        this.state = {
            dataInicial: '',
            dataFinal: '',
            dataInicialResumido: '',
            dataFinalResumido: '',
            paramsRelatorio: '',
            selectCategoria: null,
            selectSituacao: null,
            selectSituacaoResumido: null,
            selectSaida: null,
            selectClassificacao: null,
            selectOrdem: null,
            tipo: null,
            codigoLancamento: '',
            processo: null,
            processoCodigo: '',
            processoPesquisa: '',
            descricaoConsulta: '',
            tipos: [],
            detalhado: 'none',
            resumido: 'none',
            lancamentosDetalhados: [],
            lancamentosResumido: [],
            bloqueiaSituacao: '',
            labelResumido: '',
            selectResumido: null,
            detail: '',
            displayProcesso: false,
            //Relatório Impressão
            dataInicialRelatorio: '',
            dataFinalRelatorio: '',
            tipoRelatorio: '',
            categoriaRelatorio: '',
            situacaoRelatorio: '',
            classificacaoRelatorio: '',
            filtro: '',
            ordemRelatorio: '',
            //Consulta do Lançamento
            lancamento: null,
            tipoNome: '',
            categoriaNome: '',
            vlLancamento: '',
            dtVencimento: '',
            vlPago: '',
            dtPago: '',
            dsLancamento: '',
            observacao: '',
            dsProcesso: '',
            situacao: '',
            arquivos: [],
            modalConsulta: false,
            vlLancamentoNovo: '',
            dtVencimentoNovo: '',
            modalDespesaPagamento: null,
            modalReceitaPagamento: null,
            openSpinner: false,
        };

        this.categoria = [
            {codigo: 'D', nome: 'Despesa'},
            {codigo: 'R', nome: 'Receita'},
        ];

        this.situacao = [
            {codigo: 'A', nome: 'Ativos'},
            {codigo: 'P', nome: 'Pagos'},
            {codigo: 'C', nome: 'Cancelados'}
        ];

        this.saida = [
            {codigo: 'R', nome: 'Resumido'},
            {codigo: 'D', nome: 'Detalhado'}
        ];

        this.classificacao = [
            {codigo: 'VALOR', nome: 'Valor'},
            {codigo: 'DATA', nome: 'Data de Vencimento'}
        ];

        this.ordem = [
            {codigo: 'ASC', nome: 'Ascendente'},
            {codigo: 'DESC', nome: 'Decrescente'}
        ];

        this.resumido = [
            {codigo: 'CATEGORIA', nome: 'Categoria'},
            {codigo: 'TIPO', nome: 'Tipo'},
            // {codigo: 'SITUACAO', nome: 'Situação'}
        ]

        addLocale('es', {
            firstDayOfWeek: 1,
            dayNames: ['Domingo', 'Domingo', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
            dayNamesShort: ['Domingo', 'Domingo', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
            dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
            monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Stembro', 'Outubro', 'Novembro', 'Dezemro'],
            monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            today: 'Hoy',
            clear: 'Claro'
        });
    }

    pad2 = (n) => {
        return (n < 10 ? '0' : '') + n;
    }

    listarTipos = () => {
        const usuarioLogado = this.context.usuarioAutenticado

        if (this.state.selectCategoria != null) {
            this.tipoDespesaReceitaService.listarTiposDespesasReceitasPorTipo(usuarioLogado.empresa.codigo, this.state.selectCategoria.codigo)
                .then(response => {
                    this.setState({tipos: response.data});
                }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({severity: 'error', summary: 'Relatórios', detail: `${this.state.detail}`, life: 4000});
            })
        } else {
            this.setState({tipo: null});
            this.setState({tipos: []});
        }
    }

    async onChangeCategoria(e) {
        await this.setState({selectCategoria: e.value});
        this.listarTipos();
    }

    onChangeTipo(e) {
        this.setState({tipo: e.value});
    }

    onChangeSituacao(e) {
        this.setState({selectSituacao: e.value});
    }

    onChangeSituacaoResumido(e) {
        this.setState({selectSituacaoResumido: e.value});
    }

    onChangeSaida(e) {
        this.setState({selectSaida: e.value})
    }

    onChangeClassificacao(e) {
        this.setState({selectClassificacao: e.value});
    }

    onChangeOrdem(e) {
        this.setState({selectOrdem: e.value});
    }

    emitirRelatorio = async () => {
        const usuarioLogado = this.context.usuarioAutenticado;

        //Data Inicial
        var dataFormatadaDataVencimentoInicial = 'S';
        if (this.state.dataInicial !== '' && this.state.dataInicial != null) {
            dataFormatadaDataVencimentoInicial = DateFormat(this.state.dataInicial);
            this.setState({dataInicialRelatorio: dataFormatadaDataVencimentoInicial});
        } else {
            this.setState({dataInicialRelatorio: 'S'});
            this.toast.show({
                severity: 'error',
                summary: 'Relatórios',
                detail: 'A data inicial é obrigatória',
                life: 4000
            });
            return false;;
        }

        //Data Final
        var dataFormatadaDataVencimentoFinal = 'S';
        if (this.state.dataFinal !== '' && this.state.dataFinal != null) {
            dataFormatadaDataVencimentoFinal = DateFormat(this.state.dataFinal);
            this.setState({dataFinalRelatorio: dataFormatadaDataVencimentoFinal});
        } else {
            this.setState({dataFinalRelatorio: 'S'});
            this.toast.show({
                severity: 'error',
                summary: 'Relatórios',
                detail: 'A data final é obrigatória',
                life: 4000
            });
            return false;
        }
        var timeDiff= Math.abs(this.state.dataFinal.getTime() - this.state.dataInicial.getTime());
        var diffMonths = timeDiff / (1000 * 3600 * 24 * 30);
        if(diffMonths > 3){
            this.toast.show({
                severity: 'error',
                summary: 'Relatórios',
                detail: 'O período máximo de emissão do relatório é de 3 meses',
                life: 4000
            });
            return false;
        }

        var classific = '';
        if (this.state.selectClassificacao == null) {
            classific = 'DATA'
        } else {
            classific = this.state.selectClassificacao.codigo;
        }
        this.setState({classificacaoRelatorio: classific});

        var ordem = '';
        if (this.state.selectOrdem == null) {
            ordem = 'ASC'
        } else {
            ordem = this.state.selectOrdem.codigo;
        }
        this.setState({ordemRelatorio: ordem});

        var categ = 'T';
        var tipo = '0';
        var sit = 'T';
        var codigoLancamento = '0';

        if (this.state.selectCategoria != null) {
            categ = this.state.selectCategoria.codigo;
        } else {
            categ = 'T';
        }
        this.setState({categoriaRelatorio: categ});

        if (this.state.tipo != null) {
            tipo = this.state.tipo.codigo;
        } else {
            tipo = '0'
        }
        this.setState({tipoRelatorio: tipo});

        if (this.state.selectSituacao != null) {
            sit = this.state.selectSituacao.codigo;
        } else {
            sit = 'T';
        }

        if (this.state.codigoLancamento !== null && this.state.codigoLancamento !== '') {
            codigoLancamento = this.state.codigoLancamento;
        } else {
            codigoLancamento = '0';
        }

        var processo = '';
        var descricao = '';
        if (this.state.processoPesquisa === null || this.state.processoPesquisa === "") {
            this.setState({processo: null});
            processo = '0';
        } else if (this.state.processoPesquisa !== "" && this.state.processo != null) {
            processo = this.state.processo.codigo;
        }

        if (this.state.descricaoConsulta === null || this.state.descricaoConsulta === "") {
            descricao = '100';
        } else {
            descricao = this.state.descricaoConsulta;
        }


        this.setState({situacaoRelatorio: sit});

        this.setState({detalhado: 'block'});

        this.setState({openSpinner: true});
        await this.financeiroService.relatorioDetalhado(usuarioLogado.empresa.codigo,
            dataFormatadaDataVencimentoInicial, dataFormatadaDataVencimentoFinal, tipo, categ, sit, codigoLancamento,
            processo, descricao, classific, ordem)
            .then(response => {
                this.setState({lancamentosDetalhados: response.data});
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({severity: 'error', summary: 'Relatórios', detail: `${this.state.detail}`, life: 4000});
            });

        this.setState({openSpinner: false});
        this.changeFiltro(
            dataFormatadaDataVencimentoInicial,
            dataFormatadaDataVencimentoFinal,
            categ,
            this.state.tipo,
            sit
        )

    }

    relatorioResumido = async () => {
        const usuarioLogado = this.context.usuarioAutenticado

        if (this.state.dataInicialResumido === '') {
            this.toast.show({
                severity: 'error',
                summary: 'Relatórios',
                detail: 'A data inicial é obrigatória',
                life: 4000
            });
            return false;
        }

        if (this.state.dataFinalResumido === '') {
            this.toast.show({
                severity: 'error',
                summary: 'Relatórios',
                detail: 'A data final é obrigatória',
                life: 4000
            });
            return false;
        }
        if (this.state.selectResumido == null) {

            this.toast.show({
                severity: 'error',
                summary: 'Relatórios',
                detail: 'Campo relatório é obrigatorio',
                life: 4000
            });
            return false;
        }

        //Data Inicial
        var dataFormatadaDataVencimentoInicial = '';
        if (this.state.dataInicialResumido !== '') {
            dataFormatadaDataVencimentoInicial = DateFormat(this.state.dataInicialResumido);
        }

        //Data Final
        var dataFormatadaDataVencimentoFinal = '';
        if (this.state.dataFinalResumido !== '') {
            dataFormatadaDataVencimentoFinal = DateFormat(this.state.dataFinalResumido);
        }


        if (this.state.selectResumido.codigo === 'SITUACAO') {
            this.setState({bloqueiaSituacao: 'false'});
        } else {
            this.setState({bloqueiaSituacao: 'true'});
        }


        let situacao = '';
        if (this.state.selectSituacaoResumido !== null) {
            situacao = this.state.selectSituacaoResumido.codigo;
        } else {
            situacao = 'T';
        }
        this.setState({labelResumido: this.state.selectResumido.nome, openSpinner:true}); // Cabeçalho do relatório

        await this.financeiroService.relatorioResumido(usuarioLogado.empresa.codigo, dataFormatadaDataVencimentoInicial, dataFormatadaDataVencimentoFinal,
            situacao, this.state.selectResumido.codigo)
            .then(response => {
                this.setState({lancamentosResumido: response.data});
                this.setState({resumido: 'block'})
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({severity: 'error', summary: 'Relatórios', detail: `${this.state.detail}`, life: 4000});
            })
        this.changeFiltro(dataFormatadaDataVencimentoInicial, dataFormatadaDataVencimentoFinal)
        this.setState({openSpinner: false})
    }

    onChangeRelatorio(e) {
        this.setState({selectResumido: e.value});
    }

    // imprimirRelatorioDetalhado = () => {
    //     const usuarioLogado = this.context.usuarioAutenticado
    //     const {
    //         dataInicialRelatorio,
    //         dataFinalRelatorio,
    //         tipoRelatorio,
    //         categoriaRelatorio,
    //         situacaoRelatorio,
    //         codigoLancamento,
    //         classificacaoRelatorio,
    //         ordemRelatorio
    //     } = this.state
    //
    //     // this.setState({paramsRelatorio: `#/relatorioDetalhado/${usuarioLogado.empresa.codigo}/${_temp.dataInicialRelatorio}/${_temp.dataFinalRelatorio }/${_temp.tipoRelatorio}/${_temp.categoriaRelatorio}/${_temp.situacaoRelatorio}/${_temp.codigoLancamento}/${_temp.classificacaoRelatorio}/${_temp.ordemRelatorio}`});
    //     this.setState({paramsRelatorio: `#/relatorioDetalhado/${usuarioLogado.empresa.codigo}/${IsNullOrEmpty(dataInicialRelatorio) ? 'S' : dataInicialRelatorio}/${IsNullOrEmpty(dataFinalRelatorio) ? 'S' : dataFinalRelatorio}/${IsNullOrEmpty(tipoRelatorio) ? '0' : tipoRelatorio}/${IsNullOrEmpty(categoriaRelatorio) ? 'T' : categoriaRelatorio}/${IsNullOrEmpty(situacaoRelatorio) ? 'T' : situacaoRelatorio}/${IsNullOrEmpty(codigoLancamento) ? '0' : codigoLancamento}/${IsNullOrEmpty(classificacaoRelatorio) ? 'DATA' : categoriaRelatorio}/${IsNullOrEmpty(ordemRelatorio) ? 'ASC' : ordemRelatorio}`});
    // }

    async consultarLancamento(lancamento) {
        this.setState({openSpinner: true});
        this.financeiroService.consultarLancamento(lancamento.codigo)
            .then(response => {
                this.setState({lancamento: response.data});
                this.setState({codigoLancamento: response.data.codigo});
                this.setState({tipo: response.data.tipo});
                if (response.data.tipo.tipo === 'D') {
                    this.setState({categoriaNome: 'Despesa'})
                } else {
                    this.setState({categoriaNome: 'Receita'})
                }
                this.setState({tipoNome: response.data.tipo.nome});
                this.setState({vlLancamento: response.data.vlLancamento});
                this.setState({dtVencimento: response.data.dtVencimento});
                this.setState({vlPago: response.data.vlPago});
                this.setState({dtPago: response.data.dtPago});
                this.setState({dsLancamento: response.data.dsLancamento});
                this.setState({observacao: response.data.observacao});
                if (response.data.processo != null) {
                    this.setState({dsProcesso: response.data.processo.nrCnj});
                } else {
                    this.setState({dsProcesso: ''});
                }
                if (response.data.situacao === 'P') {
                    this.setState({situacao: 'Pago'});
                } else {
                    this.setState({situacao: 'Ativo'});
                }
                this.listarArquivos(lancamento.codigo);
                this.abrirModaConsultaLancamanto('modalConsulta');
            }).catch(error => {
            this.setState(error.response.data);
            this.toast.show({
                severity: 'error',
                summary: 'Consulta de Lancamento',
                detail: `${this.state.detail}`,
                life: 4000
            });
        });

        this.setState({openSpinner: false});
    }

    renderFooterConsultaLancamento(name) {
        return (
            <div className="right flex-1 gap-1">
                <Button label="Reabrir"
                        className="p-button-info p-button-text"
                        onClick={this.reabrirLancamento}/>
                <Button label="Pagar"
                        className="p-button-success p-button-text"
                        onClick={this.consultarLancamentoPagamento}/>
                <Button label="Fechar"
                        className="p-button-secondary p-button-text"
                        onClick={() => this.fecharModal(name)}/>
            </div>
        );
    }

    abrirModaConsultaLancamanto(name, position) {
        let state = {
            [`${name}`]: true
        };

        if (position) {
            state = {
                ...state,
                position
            }
        }
        this.setState(state);
    }

    listarArquivos = (lancamento) => {
        this.setState({openSpinner: true})
        this.arquivoFinanceiroService.listarArquivos(lancamento)
            .then(response => {
                this.setState({arquivos: response.data});
            }).catch(error => {
            this.setState(error.response.data);
            this.toast.show({
                severity: 'error',
                summary: 'Financeiro - Arquivos',
                detail: `${this.state.detail}`,
                life: 4000
            });
        })
        this.setState({openSpinner: false})
    }

    acoesLancamento(rowData) {
        return (
            <a href className={rowData.naoLida} onClick={() => this.consultarLancamento(rowData)}
               style={{cursor: 'pointer'}}>{rowData.codigo}</a>
        );
    }

    fecharModal(name) {
        this.setState({
            [`${name}`]: false
        });
    }

    consultarLancamentoPagamento = () => {
        if (this.state.lancamento.situacao === 'P') {
            this.toast.show({severity: 'warn', summary: 'Pagamento', detail: 'Lançamento já pago', life: 4000});
            return false;
        }

        if (this.state.lancamento.situacao === 'C') {
            this.toast.show({severity: 'warn', summary: 'Pagamento', detail: 'Lançamento cancelado', life: 4000});
            return false;
        }

        this.setState({vlLancamentoNovo: ''});
        this.setState({dtVencimentoNovo: ''});
        if (this.state.lancamento.tipo.tipo === 'D') {
            this.abrirModal('modalDespesaPagamento')
        } else {
            this.abrirModal('modalReceitaPagamento')
        }
    }

    abrirModal(name, position) {
        let state = {
            [`${name}`]: true
        };

        if (position) {
            state = {
                ...state,
                position
            }
        }
        this.setState(state);
    }

    //Pagar Despesa e Receita
    pagarDespesaReceita = async () => {
        const usuarioLogado = this.context.usuarioAutenticado;

        if (this.state.lancamento.situacao === 'P') {
            this.toast.show({severity: 'warn', summary: 'Pagamento', detail: 'Lançamento já pago', life: 4000});
            return false;
        }

        if (this.state.lancamento.situacao === 'C') {
            this.toast.show({severity: 'warn', summary: 'Pagamento', detail: 'Lançamento cancelado', life: 4000});
            return false;
        }

        //Se o campo de novo valor estiver preenchido, paga o novo valor e cria um novo lancamento com a diferença
        if (this.state.vlLancamentoNovo !== '') {
            //Efetua pagamento
            if (this.state.dtVencimentoNovo === '') {
                this.toast.show({
                    severity: 'error',
                    summary: 'Pagamento',
                    detail: 'Informe a data de vencimento',
                    life: 4000
                });
                return false;
            }
            this.setState({openSpinner: true})
            await this.financeiroService.pagarOuCancelarLancamentoIndividual({
                codigo: this.state.lancamento.codigo,
                situacao: 'P',
                vlPago: this.state.vlLancamentoNovo,
                observacao: this.state.observacao
            }).then(response => {
                this.toast.show({severity: 'success', summary: 'Pagamento', detail: 'Pagamento efetuado', life: 4000});
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({severity: 'error', summary: 'Pagamento', detail: `${this.state.detail}`, life: 4000});
            });

            //Data de vencimento
            var novoValor = this.state.vlLancamento - this.state.vlLancamentoNovo;
            var dataFormatadaDataVencimento = '';
            if (this.state.dtVencimentoNovo !== '') {
                var date = this.state.dtVencimentoNovo;
                var month = this.pad2(date.getMonth() + 1);//months (0-11)
                var day = this.pad2(date.getDate());//day (1-31)
                var year = date.getFullYear();
                dataFormatadaDataVencimento = year + "-" + month + "-" + day;
            }
            ;

            this.financeiroService.cadastrarLancamento({
                tipo: this.state.tipo,
                vlLancamento: novoValor,
                dtVencimento: dataFormatadaDataVencimento,
                observacao: `Difereça do pagamento anterior, lançamento número: ${this.state.codigoLancamento}`,
                situacao: 'A',
                usuario: usuarioLogado.codigo,
                vlPago: '0',
                processo: this.state.lancamento.processo
            }).then(response => {
                this.toast.show({
                    severity: 'success',
                    summary: 'Cadastro de lançamento',
                    detail: 'Novo lançamento cadastrado',
                    life: 4000
                });
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({
                    severity: 'error',
                    summary: 'Cadastro de lançamento',
                    detail: `${this.state.detail}`,
                    life: 4000
                });
            });

            //Aqui regra de fechar o modal
            if (this.state.lancamento.tipo.tipo === 'D') {
                this.fecharModal('modalDespesaPagamento');
                this.consultarDespesasFiltro();
            } else {
                this.fecharModal('modalReceitaPagamento');
                this.consultarReceitasFiltro();
            }
            this.fecharModal('modalConsulta');

        } else {
            //Paga o valor total
            await this.financeiroService.pagarOuCancelarLancamentoIndividual({
                codigo: this.state.lancamento.codigo,
                situacao: 'P',
                vlPago: this.state.vlLancamento,
                observacao: this.state.observacao
            }).then(response => {
                this.toast.show({
                    severity: 'success',
                    summary: 'Pagamento de despesa',
                    detail: 'Pagamento efetuado',
                    life: 4000
                });
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({
                    severity: 'error',
                    summary: 'Pagamento de despesa',
                    detail: `${this.state.detail}`,
                    life: 4000
                });
            });

            if (this.state.lancamento.tipo.tipo === 'D') {
                this.fecharModal('modalDespesaPagamento');
                //Atualiza tela principal
            } else {
                this.fecharModal('modalReceitaPagamento');
                //Atualiza tela principal
            }
            this.fecharModal('modalConsulta');
        }
        this.setState({openSpinner: false})

    }

    limparCamposPesquisa = () => {
        this.setState({dataInicial: null});
        this.setState({dataInicialRelatorio: 'S'});
        this.setState({dataFinal: null});
        this.setState({dataFinalRelatorio: 'S'});
        this.setState({selectClassificacao: null});
        this.setState({classificacaoRelatorio: null});
        this.setState({selectOrdem: null});
        this.setState({ordemRelatorio: null});
        this.setState({selectCategoria: null});
        this.setState({categoriaRelatorio: null});
        this.setState({tipo: null});
        this.setState({tipoRelatorio: null});
        this.setState({selectSituacao: null});
        this.setState({codigoLancamento: ''});
        this.setState({lancamentosDetalhados: []});
        this.setState({processo: null});
        this.setState({processoPesquisa: ''});
        this.setState({descricao: '', filtro: '',});
    }

    limparCamposRelatorioResumido = () => {
        this.setState({
            lancamentosResumido: [],
            filtro: '',
            dataInicialResumido: '',
            dataFinalResumido: '',
            selectSituacaoResumido: null,
            selectResumido: null,
        })
    }

    consultarProcessoPorIndice = async () => {
        this.setState({openSpinner: true});
        const usuarioLogado = this.context.usuarioAutenticado;
        const indice = {
            indice: this.state.processoPesquisa,
            empresa: usuarioLogado.empresa.codigo,
            usuario: usuarioLogado.codigo
        }
        await this.processoService.consultarProcessoPorIndice(indice)
            .then(response => {
                this.setState({processos: response.data});
                if (response.data.length > 0) {
                    this.abrirModalProcesso('displayProcesso');
                } else {
                    this.setState({processoPesquisa: ''});
                    this.toast.show({
                        severity: 'error',
                        summary: 'Processos',
                        detail: 'Processo não encontrado',
                        life: 4000
                    });
                }
            }).catch(error => {
                this.setState(error.response.data)
                this.toast.show({severity: 'error', summary: 'Atividades', detail: `${this.state.detail}`, life: 4000});
            });

        this.setState({openSpinner: false});
    }

    fecharModalAtividade(name) {
        this.setState({
            [`${name}`]: false
        });
    }

    abrirModalProcesso(name, position) {
        let state = {
            [`${name}`]: true
        };

        if (position) {
            state = {
                ...state,
                position
            }
        }
        this.setState(state);
    }

    renderFooterProcesso(name) {
        return (
            <div className="left">
                <Button label="Fechar"
                        className="p-button-secondary p-button-text"
                        onClick={() => this.fecharModalAtividade(name)}/>
            </div>
        );
    }

    acoesProcesso(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-check" className="p-button-rounded p-button-text"
                        tooltip="Selecionar"
                        onClick={() => this.selecionarProcesso(rowData)}
                        tooltipOptions={{position: 'top'}}/>
            </React.Fragment>
        );
    }

    async selecionarProcesso(processo) {
        this.setState({processo: processo});
        this.fecharModalAtividade('displayProcesso');
    }

    changeFiltro = (dataInicialRelatorio,
                    dataFinalRelatorio,
                    categoriaRelatorio,
                    tipoRelatorio,
                    situacaoRelatorio) => {

        let _filtro = FiltroRelatorioFinanceiro(categoriaRelatorio, situacaoRelatorio, tipoRelatorio, dataInicialRelatorio, dataFinalRelatorio)
        this.setState({filtro: _filtro});

    }

    templateVlLancamento=(rowData)=>{
        return rowData.categoria.toString().toLowerCase() === "despesa"? `${formatarMoeda(-rowData.vlLancamento)}` : formatarMoeda(rowData.vlLancamento)
    }
    templateVlPago=(rowData)=>{
        return formatarMoeda(rowData.vlPago)
    }
    templateVlLancamentoResumido=(rowData)=>{
        return formatarMoeda(rowData.valor)
    }

    render() {
        const {upLoadFiles} = this.state;
        return (
            <>
                <NavBar/>

                <Toast ref={(el) => this.toast = el}/>
                <div className="content-wrapper">
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card card-primary card-outline">
                                        <div className="card-header">
                                            <h3 className="card-title">
                                                <i className="pi pi-file-pdf"></i>
                                                <span> Relatórios financeiros</span>
                                            </h3>
                                        </div>
                                        <TabView className="tabview-custom">
                                            <TabPanel header="Detalhado">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-2">
                                                            <div className="formGroup">
                                                                <label>Data inicial<span
                                                                    className="obrigatorioAsterisco"> *</span></label>
                                                                <Calendar id="mask"
                                                                          value={this.state.dataInicial}
                                                                          onChange={(e) => this.setState({dataInicial: e.value})}
                                                                          locale="es"
                                                                          style={{width: '100%', height: '32px'}}
                                                                          dateFormat="dd/mm/yy"
                                                                          mask="99/99/9999"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="formGroup">
                                                                <label>Data final<span
                                                                    className="obrigatorioAsterisco"> *</span></label>
                                                                <Calendar id="mask"
                                                                          value={this.state.dataFinal}
                                                                          onChange={(e) => this.setState({dataFinal: e.value})}
                                                                          locale="es"
                                                                          style={{width: '100%', height: '32px'}}
                                                                          dateFormat="dd/mm/yy"
                                                                          mask="99/99/9999"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="formGroup">
                                                                <label>Categoria</label>
                                                                <Dropdown value={this.state.selectCategoria}
                                                                          style={{width: '100%', height: '37px'}}
                                                                          options={this.categoria}
                                                                          optionLabel="nome"
                                                                          filter showClear filterBy="nome"
                                                                          emptyMessage=" "
                                                                          onChange={this.onChangeCategoria}
                                                                          id="categoria"
                                                                          className="p-inputtext-sm p-d-block p-mb-1"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="formGroup">
                                                                <label>Tipo</label>
                                                                <Dropdown value={this.state.tipo}
                                                                          style={{width: '100%', height: '37px'}}
                                                                          options={this.state.tipos}
                                                                          optionLabel="nome"
                                                                          emptyMessage=" "
                                                                          filter showClear filterBy="nome"
                                                                          onChange={this.onChangeTipo}
                                                                          id="tipoDespesa"
                                                                          className="p-inputtext-sm p-d-block p-mb-1"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-2">
                                                            <div className="formGroup">
                                                                <label>Situação</label>
                                                                <Dropdown value={this.state.selectSituacao}
                                                                          style={{width: '100%', height: '37px'}}
                                                                          options={this.situacao}
                                                                          optionLabel="nome"
                                                                          emptyMessage=" "
                                                                          filter showClear filterBy="nome"
                                                                          onChange={this.onChangeSituacao}
                                                                          id="situacao"
                                                                          className="p-inputtext-sm p-d-block p-mb-1"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <div className="row">

                                                        <div className="col-sm-3">
                                                            <div className="formGroup">
                                                                <label>Código lançamento</label>
                                                                <InputText id="codigo"
                                                                           autoComplete="nope"
                                                                           type="text"
                                                                           maxLength={100}
                                                                           style={{width: '100%'}}
                                                                           value={this.state.codigoLancamento}
                                                                           onChange={e => this.setState({codigoLancamento: e.target.value})}
                                                                           className="p-inputtext-sm p-d-block p-mb-1"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            {/*<div className="formGroup">*/}
                                                            {/*    <label>Processo</label>*/}
                                                            {/*    <InputText id="processo"*/}
                                                            {/*               autoComplete="nope"*/}
                                                            {/*               type="text"*/}
                                                            {/*               style={{width: '100%'}}*/}
                                                            {/*               value={this.state.processoPesquisa}*/}
                                                            {/*               onChange={e => this.setState({processoPesquisa: e.target.value})}*/}
                                                            {/*               className="p-inputtext-sm p-d-block p-mb-1"/>*/}
                                                            {/*    <span className="input-group-prepend pointer">*/}
                                                            {/*            <span*/}
                                                            {/*                onClick={() => this.consultarProcessoPorIndice()}*/}
                                                            {/*                className="input-group-text">*/}
                                                            {/*                <i className="fas fa-search"></i>*/}
                                                            {/*            </span>*/}
                                                            {/*        </span>*/}
                                                            {/*</div>*/}
                                                            <InputPesquisaProcessoFinanceiro
                                                                processo={this.state.processo}
                                                                value={this.state.processoPesquisa}
                                                                onChange={(e) => this.setState({processoPesquisa: e.target.value})}
                                                                consultarProcessoPorIndice={this.consultarProcessoPorIndice}
                                                                excluirPesquisaProcesso={() => this.setState({processo: null})}
                                                            />
                                                        </div>

                                                        <div className="col-sm-2">
                                                            <div className="formGroup">
                                                                <label>Descrição</label>
                                                                <InputText id="descrcao"
                                                                           autoComplete="nope"
                                                                           type="text"
                                                                           style={{width: '100%'}}
                                                                           value={this.state.descricaoConsulta}
                                                                           onChange={e => this.setState({descricaoConsulta: e.target.value})}
                                                                           className="p-inputtext-sm p-d-block p-mb-1"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-2">
                                                            <div className="formGroup">
                                                                <label>Classificação</label>
                                                                <Dropdown value={this.state.selectClassificacao}
                                                                          style={{width: '100%', height: '37px'}}
                                                                          options={this.classificacao}
                                                                          optionLabel="nome"
                                                                          emptyMessage=" "
                                                                          filter showClear filterBy="nome"
                                                                          onChange={this.onChangeClassificacao}
                                                                          id="saida"
                                                                          className="p-inputtext-sm p-d-block p-mb-1"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-2">
                                                            <div className="formGroup">
                                                                <label>Ordem</label>
                                                                <Dropdown value={this.state.selectOrdem}
                                                                          style={{width: '100%', height: '37px'}}
                                                                          options={this.ordem}
                                                                          optionLabel="nome"
                                                                          emptyMessage=" "
                                                                          filter showClear filterBy="nome"
                                                                          onChange={this.onChangeOrdem}
                                                                          id="saida"
                                                                          className="p-inputtext-sm p-d-block p-mb-1"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row gap-3 center-items my-4'>

                                                        <Button label="Consultar"
                                                                className={strings.buttonPrimaryStyle}
                                                                onClick={this.emitirRelatorio}/>

                                                        {this.state.lancamentosDetalhados.length > 0 &&

                                                            <ReactToPrint
                                                                trigger={() => <Button label="Imprimir"
                                                                                       className={strings.buttonSecondaryStyle}/>}
                                                                content={() => this.componentRef}>
                                                            </ReactToPrint>
                                                        }
                                                        {/*temporariamente removido para futuras implementaçoes*/}
                                                        {/*<Button label="Exportar"*/}
                                                        {/*      className={strings.buttonHelp}*/}
                                                        {/*      onClick={this.emitirRelatorio}/>*/}

                                                        <Button label="Limpar"
                                                                className={strings.buttonPlain}
                                                                onClick={this.limparCamposPesquisa}/>

                                                    </div>
                                                </div>
                                                <div className="row" style={{display: `${this.state.detalhado}`}}>
                                                    <div className="col-sm-12">
                                                        <div className="formGroup">
                                                            <DataTable value={this.state.lancamentosDetalhados}
                                                                       emptyMessage=" ">
                                                                <Column body={this.acoesLancamento} exportable={false}
                                                                        style={{minWidth: '8rem'}}
                                                                        header="Cód. Lançamento"></Column>
                                                                <Column field="categoria" header="Categoria"></Column>
                                                                <Column field="tipo.nome" header="Tipo"></Column>
                                                                <Column body={this.templateVlLancamento} header="Valor"></Column>
                                                                <Column field="dtVencimento"
                                                                        header="Data vencto."></Column>
                                                                <Column body={this.templateVlPago} field="vlPago" header="Valor pago"></Column>
                                                                <Column field="dtPago" header="Data pgto."></Column>
                                                                <Column field="situacao" header="Situação"></Column>
                                                            </DataTable>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            <TabPanel header="Resumido">
                                                <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="formGroup">
                                                            <label>Data inicial<span
                                                                className="obrigatorioAsterisco"> *</span></label>
                                                            <Calendar id="dataInicialResumido"
                                                                      value={this.state.dataInicialResumido}
                                                                      onChange={(e) => this.setState({dataInicialResumido: e.value})}
                                                                      locale="es"
                                                                      style={{width: '100%', height: '37px'}}
                                                                      dateFormat="dd/mm/yy"
                                                                      mask="99/99/9999"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="formGroup">
                                                            <label>Data final<span
                                                                className="obrigatorioAsterisco"> *</span></label>
                                                            <Calendar id="dataFinalResumido"
                                                                      value={this.state.dataFinalResumido}
                                                                      onChange={(e) => this.setState({dataFinalResumido: e.value})}
                                                                      locale="es"
                                                                      style={{width: '100%', height: '37px'}}
                                                                      dateFormat="dd/mm/yy"
                                                                      mask="99/99/9999"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="formGroup">
                                                            <label>Relatório<span
                                                                className="obrigatorioAsterisco"> *</span></label>
                                                            <Dropdown value={this.state.selectResumido}
                                                                      style={{width: '100%', height: '37px'}}
                                                                      options={this.resumido}
                                                                      optionLabel="nome"
                                                                      filter showClear filterBy="nome"
                                                                      emptyMessage=" "
                                                                      onChange={this.onChangeRelatorio}
                                                                      id="categoria"
                                                                      className="p-inputtext-sm p-d-block p-mb-1"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <div className="formGroup">
                                                            <label>Situação</label>
                                                            <Dropdown value={this.state.selectSituacaoResumido}
                                                                      options={this.situacao}
                                                                      style={{width: '100%', height: "37px"}}
                                                                      optionLabel="nome"
                                                                      emptyMessage=" "
                                                                      filter showClear filterBy="nome"
                                                                      onChange={this.onChangeSituacaoResumido}
                                                                      id="situacao"
                                                                      className="p-inputtext-sm p-d-block p-mb-1"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row gap-3 center-items my-4">


                                                    <Button label="Consultar"
                                                            className={strings.buttonPrimaryStyle}
                                                            onClick={this.relatorioResumido}/>

                                                    {this.state.lancamentosResumido.length > 0 && <ReactToPrint
                                                        trigger={() => <Button label="Imprimir"
                                                                               className={strings.buttonSecondaryStyle}/>}
                                                        content={() => this.resumidoRef}>
                                                    </ReactToPrint>}

                                                    {/*<Button label="Imprimir"                                            */}
                                                    {/*        className={strings.buttonSecondaryStyle}/>*/}


                                                    {/*<Button label="Exportar"*/}
                                                    {/*      className={strings.buttonHelp}*/}
                                                    {/*      onClick={this.emitirRelatorio}/>*/}

                                                    <Button label="Limpar"
                                                            className={strings.buttonPlain}
                                                            onClick={this.limparCamposRelatorioResumido}/>

                                                </div>
                                                </div>
                                                <br></br>
                                                <div className="row" style={{display: `${this.state.resumido}`}}>
                                                    <div className="col-sm-12">
                                                        <div className="formGroup">
                                                            <DataTable value={this.state.lancamentosResumido}
                                                                       emptyMessage=" ">
                                                                <Column field="label"
                                                                        header={this.state.labelResumido}></Column>
                                                                <Column body={this.templateVlLancamentoResumido} header="Valor"></Column>
                                                            </DataTable>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                        </TabView>
                                        <Loading
                                            open={this.state.openSpinner}>
                                        </Loading>

                                        {/**Pesquisa de Processos */}
                                        <Dialog header="Busca de processo"
                                                visible={this.state.displayProcesso}
                                                style={{width: '60vw'}}
                                                footer={this.renderFooterProcesso('displayProcesso')}
                                                onHide={() => this.fecharModalAtividade('displayProcesso')}>

                                            <DataTable value={this.state.processos} alwaysShowPaginator
                                                       responsiveLayout="scroll"
                                                       paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                                       currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}"
                                                       rows={4} emptyMessage=" "
                                                       paginatorRight selectionMode="multiple">
                                                <Column field="pasta" header="Pasta"></Column>
                                                <Column field="nrProcesso" header="Processo original"></Column>
                                                <Column field="nrCnj" header="Numeração única"></Column>
                                                <Column field="autor" header="Autor x Réu"
                                                        style={{width: '300px'}}></Column>
                                                <Column field="grupoTrabalho.nome" header="Grupo trab."></Column>
                                                <Column body={this.acoesProcesso} exportable={false}
                                                        style={{minWidth: '8rem'}} header="Ações"></Column>
                                            </DataTable>
                                        </Dialog>

                                        {/**Consultar Lançamento */}
                                        <Dialog header={`Lançamento - [${this.state.codigoLancamento}]`}
                                                visible={this.state.modalConsulta}
                                                style={{width: '60vw'}}
                                                footer={this.renderFooterConsultaLancamento('modalConsulta')}
                                                onHide={() => this.fecharModal('modalConsulta')}>
                                            <TabView activeIndex={this.state.tabAtivo}>
                                                <TabPanel header="Lançamento">
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="formGroup">
                                                                <label>Categoria</label>
                                                                <InputText id="categoriaConsulta"
                                                                           type="text"
                                                                           style={{width: '100%', height: '37px'}}
                                                                           value={this.state.categoriaNome}
                                                                           disabled="true"
                                                                           className="p-inputtext-sm p-d-block p-mb-1"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="formGroup">
                                                                <label>Tipo</label>
                                                                <InputText id="tipoNome"
                                                                           type="text"
                                                                           style={{width: '100%', height: '37px'}}
                                                                           value={this.state.tipoNome}
                                                                           disabled="true"
                                                                           className="p-inputtext-sm p-d-block p-mb-1"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="formGroup">
                                                                <label>Valor</label>
                                                                <InputText id="valorConsulta"
                                                                           type="text"
                                                                           style={{width: '100%', height: '37px'}}
                                                                           value={this.state.vlLancamento}
                                                                           disabled="true"
                                                                           className="p-inputtext-sm p-d-block p-mb-1"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="formGroup">
                                                                <label>Vencimento</label>
                                                                <InputText id="dataVencimentoConsulta"
                                                                           type="text"
                                                                           style={{width: '100%', height: '37px'}}
                                                                           value={this.state.dtVencimento}
                                                                           disabled="true"
                                                                           className="p-inputtext-sm p-d-block p-mb-1"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="formGroup">
                                                                <label>Situação</label>
                                                                <InputText id="situacaoConsulta"
                                                                           type="text"
                                                                           style={{width: '100%', height: '37px'}}
                                                                           value={this.state.situacao}
                                                                           disabled="true"
                                                                           className="p-inputtext-sm p-d-block p-mb-1"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="formGroup">
                                                                <label>Valor pago</label>
                                                                <InputText id="valorPago"
                                                                           type="text"
                                                                           style={{width: '100%', height: '37px'}}
                                                                           value={this.state.vlPago}
                                                                           disabled="true"
                                                                           className="p-inputtext-sm p-d-block p-mb-1"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="formGroup">
                                                                <label>Data pagt.</label>
                                                                <InputText id="dataPagamento"
                                                                           type="text"
                                                                           style={{width: '100%', height: '37px'}}
                                                                           value={this.state.dtPago}
                                                                           disabled="true"
                                                                           className="p-inputtext-sm p-d-block p-mb-1"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="formGroup">
                                                                <label>Processo</label>
                                                                <InputText id="processoConsulta"
                                                                           type="text"
                                                                           style={{width: '100%', height: '37px'}}
                                                                           value={this.state.dsProcesso}
                                                                           disabled="true"
                                                                           className="p-inputtext-sm p-d-block p-mb-1"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="formGroup">
                                                                <label>Descrição</label>
                                                                <InputText id="descricaoConsulta"
                                                                           type="text"
                                                                           style={{width: '100%', height: '37px'}}
                                                                           value={this.state.dsLancamento}
                                                                           disabled="true"
                                                                           className="p-inputtext-sm p-d-block p-mb-1"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="formGroup">
                                                                <label>Observação</label>
                                                                <InputTextarea id="observacao"
                                                                               type="text"
                                                                               style={{width: '100%'}}
                                                                               value={this.state.observacao}
                                                                               disabled="true"
                                                                               className="p-inputtext-sm p-d-block p-mb-1"
                                                                               rows={2} cols={30}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TabPanel>
                                                <TabPanel header="Arquivos">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="form-group">
                                                                <div className="container">

                                                                    <Upload onUpload={this.handleUpload}/>
                                                                    {!!upLoadFiles && (
                                                                        <FileList files={upLoadFiles}
                                                                                  onDelete={this.handleDelete}/>
                                                                    )}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <DataTable value={this.state.arquivos} paginator
                                                               responsiveLayout="scroll" size="small"
                                                               paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                                               currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}"
                                                               rows={10}
                                                               emptyMessage="Nenhum arquivo">
                                                        <Column field="arquivo" header="Arquivo"/>
                                                        <Column field="descricao" header="Descrição"/>
                                                        <Column body={this.acoesArquivos} exportable={false}
                                                                style={{minWidth: '8rem'}} header="Ações"/>
                                                    </DataTable>
                                                </TabPanel>
                                            </TabView>
                                        </Dialog>
                                        {/**Pagar outro valor despesa*/}
                                        <Dialog header="Pagamento de despesas"
                                                visible={this.state.modalDespesaPagamento}
                                                style={{width: '50vw'}}
                                                footer={
                                                    <div alignItems="right flex-1 gap-1">
                                                        <Button label="Pagar lançamento"
                                                                className="p-button-success p-button-text"
                                                                onClick={this.pagarDespesaReceita}/>
                                                        <Button label="Fechar"
                                                                className="p-button-secondary p-button-text"
                                                                onClick={() => this.fecharModal('modalDespesaPagamento')}/>
                                                    </div>
                                                }
                                                onHide={() => this.fecharModal('modalDespesaPagamento')}>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="formGroup">
                                                        <label>Despesa</label>
                                                        <InputText id="tipoNomePagamento"
                                                                   type="text"
                                                                   style={{width: '100%', height: '37px'}}
                                                                   value={this.state.tipoNome}
                                                                   disabled="true"
                                                                   className="p-inputtext-sm p-d-block p-mb-1"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="formGroup">
                                                        <label>Valor</label>
                                                        <InputText id="valorPagamento"
                                                                   type="text"
                                                                   style={{width: '100%', height: '37px'}}
                                                                   value={this.state.vlLancamento}
                                                                   disabled="true"
                                                                   className="p-inputtext-sm p-d-block p-mb-1"/>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="formGroup">
                                                        <label>Vencimento</label>
                                                        <InputText id="vencimentoPagamento"
                                                                   type="text"
                                                                   style={{width: '100%', height: '37px'}}
                                                                   value={this.state.dtVencimento}
                                                                   disabled="true"
                                                                   className="p-inputtext-sm p-d-block p-mb-1"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="formGroup">
                                                        <label>Pagar um novo valor</label>
                                                        <InputNumber inputId="pagamentoNovoValorDespesa"
                                                                     value={this.state.vlLancamentoNovo}
                                                                     style={{width: '100%', height: '37px'}}
                                                                     onChange={(e) => this.setState({vlLancamentoNovo: e.value})}
                                                                     mode="decimal" locale="de-BR"
                                                                     minFractionDigits={2}/>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="formGroup">
                                                        <label>Novo vencimento saldo devedor</label>
                                                        <Calendar id="novoVencimentoDespesa"
                                                                  value={this.state.dtVencimentoNovo}
                                                                  onChange={(e) => this.setState({dtVencimentoNovo: e.value})}
                                                                  locale="es"
                                                                  style={{width: '100%', height: '37px'}}
                                                                  dateFormat="dd/mm/yy"
                                                                  mask="99/99/9999"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="formGroup">
                                                        <label>Observação</label>
                                                        <InputTextarea id="observacaoPagamento"
                                                                       type="text"
                                                                       style={{width: '100%'}}
                                                                       value={this.state.observacao}
                                                                       onChange={e => this.setState({observacao: e.target.value})}
                                                                       className="p-inputtext-sm p-d-block p-mb-1"
                                                                       rows={2} cols={30}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </Dialog>

                                        {/**Pagar outro valor receita*/}
                                        <Dialog header="Pagamento de receitas"
                                                visible={this.state.modalReceitaPagamento}
                                                style={{width: '50vw'}}
                                                footer={
                                                    <div alignItems="right flex-1 gap-1">
                                                        <Button label="Pagar Lançamento"
                                                                className="p-button-success p-button-text"
                                                                onClick={this.pagarDespesaReceita}/>
                                                        <Button label="Fechar"
                                                                className="p-button-secondary p-button-text"
                                                                onClick={() => this.fecharModal('modalReceitaPagamento')}/>
                                                    </div>
                                                }
                                                onHide={() => this.fecharModal('modalReceitaPagamento')}>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="formGroup">
                                                        <label>Receita</label>
                                                        <InputText id="tipoNomePagamento"
                                                                   type="text"
                                                                   style={{width: '100%', height: '37px'}}
                                                                   value={this.state.tipoNome}
                                                                   disabled="true"
                                                                   className="p-inputtext-sm p-d-block p-mb-1"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="formGroup">
                                                        <label>Valor</label>
                                                        <InputText id="valorPagamento"
                                                                   type="text"
                                                                   style={{width: '100%', height: '37px'}}
                                                                   value={this.state.vlLancamento}
                                                                   disabled="true"
                                                                   className="p-inputtext-sm p-d-block p-mb-1"/>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="formGroup">
                                                        <label>Vencimento</label>
                                                        <InputText id="vencimentoPagamento"
                                                                   type="text"
                                                                   style={{width: '100%', height: '37px'}}
                                                                   value={this.state.dtVencimento}
                                                                   disabled="true"
                                                                   className="p-inputtext-sm p-d-block p-mb-1"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="formGroup">
                                                        <label>Pagar um novo valor</label>
                                                        <InputNumber inputId="pagamentoNovoValorDespesa"
                                                                     value={this.state.vlLancamentoNovo}
                                                                     style={{width: '100%', height: '37px'}}
                                                                     onChange={(e) => this.setState({vlLancamentoNovo: e.value})}
                                                                     mode="decimal" locale="de-BR"
                                                                     minFractionDigits={2}/>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="formGroup">
                                                        <label>Novo vencimento saldo devedor</label>
                                                        <Calendar id="novoVencimentoDespesa"
                                                                  value={this.state.dtVencimentoNovo}
                                                                  onChange={(e) => this.setState({dtVencimentoNovo: e.value})}
                                                                  locale="es"
                                                                  style={{width: '100%', height: '37px'}}
                                                                  dateFormat="dd/mm/yy"
                                                                  mask="99/99/9999"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="formGroup">
                                                        <label>Observação</label>
                                                        <InputTextarea id="observacaoPagamento"
                                                                       type="text"
                                                                       style={{width: '100%'}}
                                                                       value={this.state.observacao}
                                                                       onChange={e => this.setState({observacao: e.target.value})}
                                                                       className="p-inputtext-sm p-d-block p-mb-1"
                                                                       rows={2} cols={30}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </Dialog>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div hidden>
                    {/*//detalhado*/}
                    <ComponentToPrint
                        lancamentos={this.state.lancamentosDetalhados}
                        filtro={this.state.filtro} quantidade={this.state.lancamentosDetalhados.length}
                        ref={el => (this.componentRef = el)}
                    />
                    {/*resumido*/}
                    <ComponentToPrint
                        lancamentosResumido={this.state.lancamentosResumido}
                        quantidade={this.state.lancamentosResumido.length}
                        filtro={this.state.filtro}
                        ref={el => (this.resumidoRef = el)}
                    />
                </div>
            </>
        )
    }
}

RelatorioFinanceiro.contextType = AuthContext;
export default withRouter(RelatorioFinanceiro)

