import React from 'react'

import {withRouter} from 'react-router-dom'

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timegridPlugin from '@fullcalendar/timegrid';
import ptBrLocale from '@fullcalendar/core/locales/pt-br';
import interactionPlugin from '@fullcalendar/interaction';
import moment from "moment";
import {Toast} from 'primereact/toast';
import {MultiSelect} from 'primereact/multiselect'

import NavBar from '../../componentes/Navbar'
import AgendaService from '../../app/service/AgendaService'
import GrupoTrabalhoService from '../../app/service/GrupoTrabalhoService'
import UsuarioService from '../../app/service/UsuarioService'
import AtividadeService from '../../app/service/AtividadeService'
import StatusAtividadeService from '../../app/service/StatusAtividadeService'
import {AuthContext} from '../../main/ProvedorAutenticacao'

import './agenda.css'
import {strings} from "../../utils/strings";
import {ButtonSm} from "../../componentes/ButtonSm";
import {DialogAlterarAtividade} from "../../componentes/Atividade/DialogAlterarAtividade";
import {Loading} from "../../componentes/Loading";
import {CadastrarAtividadeDialog} from "../../componentes/Atividade/CadastrarAtividadeDialog";
import {DateFormat} from "../../utils/DateFormat";


class Agenda extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      detail: '',
      grupos: [],
      gruposLista: [],
      // selectGrupo: null,
      usuarios: [],
      usuariosLista: [],
      selectStatusConsulta: [],
      agenda: [],
      agendaExibir: null,
      agendaCalendario: [],
      openSpinner: false,
      //Modais Atividade, Alterar e Cadastrar
      atividade: null      ,
      dtLimite: "",
      statusAtividade: [],
      //Alterar
      modalAtividadeAlterar: false,
      novaAtividade: false,
    }

    this.agendaService = new AgendaService();
    this.grupoTrabalhoService = new GrupoTrabalhoService();
    this.usuarioService = new UsuarioService();
    this.atividadeService = new AtividadeService();
    this.statusAtividadeService = new StatusAtividadeService();

  }

  listarStatusAtividade = async () => {
    const usuarioLogado = this.context.usuarioAutenticado;
    await this.statusAtividadeService.listarStatus(usuarioLogado.empresa.codigo)
      .then(response => {
        this.setState({statusAtividade: response.data});
        this.setState({selectStatusConsulta: response.data});
      }).catch(error => {
        this.setState(error.response.data)
        this.toast.show({severity:'error', summary: 'Status atividades', detail:`${this.state.detail}`, life: 4000});
      })
  }

  listarGruposTrabalho = async () => {
    const usuarioLogado = this.context.usuarioAutenticado;
    await this.grupoTrabalhoService.listarGruposUsuario(usuarioLogado.codigo)
      .then(response => {
        this.setState({gruposLista: response.data});
        this.setState({grupos: response.data});
      }).catch(error => {
        this.setState(error.response.data)
        this.toast.show({severity:'error', summary: 'Agenda de comprmissos', detail:`${this.state.detail}`, life: 4000});
      })
  }

  listarUsuarios = async () => {
    const usuarioLogado = this.context.usuarioAutenticado

    await this.usuarioService.listarUsuariosEmpresa(usuarioLogado.empresa.codigo)
      .then(response => {
        this.setState({usuarios: response.data});
        this.setState({usuariosLista: response.data});
      }).catch(error => {
        this.setState(error.response.data)
        this.toast.show({severity:'error', summary: 'Agenda de comprmissos', detail:`${this.state.detail}`, life: 4000});
      })
  }

   async componentDidMount() {
    this.setState({openSpinner: true});
     await this.listarStatusAtividade();
     await this.listarGruposTrabalho();
     await this.listarUsuarios();
     await this.listarAgenda();
     this.setState({openSpinner: false});
  }

  listarAgenda = async () => {
    this.setState({openSpinner: true});
    const usuarioLogado = this.context.usuarioAutenticado

    var contador = 0;

    var tamanhoLista = this.state.gruposLista.length;
    let codigoGrupos = '';
    for(let i = 0; i < this.state.gruposLista.length; i++) {
      if(contador < tamanhoLista -1) {
        codigoGrupos = codigoGrupos + this.state.gruposLista[i].codigo + ",";
      } else {
        codigoGrupos = codigoGrupos + this.state.gruposLista[i].codigo;
      }

      contador++;
    }

    contador = 0;
    tamanhoLista = this.state.usuariosLista.length;
    let codigosUsuarios = 0;
    for(let i = 0; i < this.state.usuariosLista.length; i++) {
      if(contador < tamanhoLista -1) {
        codigosUsuarios = codigosUsuarios + this.state.usuariosLista[i].codigo + ",";
      } else {
        codigosUsuarios = codigosUsuarios + this.state.usuariosLista[i].codigo;
      }

      contador++;
    }

    contador = 0;
    tamanhoLista = this.state.selectStatusConsulta.length;
    let codigosStatus = 0;
    for(let i = 0; i < this.state.selectStatusConsulta.length; i++) {
      if(contador < tamanhoLista -1) {
        codigosStatus = codigosStatus + this.state.selectStatusConsulta[i].codigo + ",";
      } else {
        codigosStatus = codigosStatus + this.state.selectStatusConsulta[i].codigo;
      }

      contador++;
    }

    const filtro = {
      usuario: usuarioLogado.codigo,
      usuarios: codigosUsuarios,
      grupos: codigoGrupos,
      status: codigosStatus
    }
    await this.agendaService.consultarAgenda(filtro)
      .then(response => {
        this.setState({agenda: response.data});
      });
      let dados = [];
      let cor = '';
      let textColor = '';
      let status = '';
      let titulo = '';
      for(let i = 0; i < this.state.agenda.length; i++) {
        switch(this.state.agenda[i].status) {
          case 1:
            cor = '#b8860b';
            status = 'Aguardando';
            textColor = 'white'
            break;
          case 2:
            cor = 'grey';
            status = `${this.state.statusAtividade[0].status}`;
            textColor = 'white'
            break;
          case 3:
            cor = '#00ffff'
            status = 'Ciente'
            textColor = 'white';
            break;
          case 4:
            cor = '#1229ab'
            status = 'Fazendo'
            textColor = 'white';
            break;
          case 6:
            cor = 'red';
            status = 'Cancelado';
            textColor = 'white';
            break;
          default:
            cor = 'green';
            status = 'Concluído';
            textColor = 'white';
            break;
        }

        titulo = `${this.state.agenda[i].titulo} - (${this.state.agenda[i].hora})`;

        this.setState({agendaExibir:{
          id: this.state.agenda[i].codigoAtividade,
          title: titulo,
          start: moment(this.state.agenda[i].dataCompromisso).format('YYYY-MM-DD'),
          status: status,
          color: cor,
          textColor: textColor
        }})
        dados.push(this.state.agendaExibir);
      }
      console.log('Finalizou o loop');
      this.setState({agendaCalendario: dados});
      this.setState({openSpinner: false});
  }

  abrirAtividade = (rowData) => {
    this.consultarAtividade(rowData.event.id);
  }
  novaAtividade = async (rowdata) => {
    this.setState({openSpinner: true});
    //Seta a data do calendário
    let hora = new Date().getHours() + ':' + new Date().getMinutes()  + ':' + '00'
    let newDate = new Date(DateFormat(rowdata.date) + ' ' + hora);
    this.setState({dtLimite: newDate});
    await this.abrirNovaAtividade('novaAtividade');
    this.setState({openSpinner: false});
  }
  async abrirNovaAtividade(name, position) {
    let state = {
        [`${name}`]: true
    };

    if (position) {
        state = {
            ...state,
            position
        }
    }
    this.setState(state);

  }
  consultarAtividade = async (atividade) => {
    this.setState({openSpinner: true});
     await this.atividadeService.consultarAtividade(atividade)
      .then(response => {
        this.setState({atividade: response.data});
      }
    ).catch(error => {
      this.setState({openSpinner: false});
        this.setState(error.response.data)
        this.toast.show({severity:'error', summary: 'Atividades', detail:`${this.state.detail}`, life: 4000});

    });

    this.setState({
        modalAtividadeAlterar: true,
        openSpinner: false});
  }
    render() {
    return(
      <>
        <NavBar/>
        <Toast ref={(el) => this.toast = el} />
        <div className="content-wrapper">
          <section className="content-header">
              <div className="container-fluid">
              <div className="row">
                  <div className="col-md-12">
                    <div className="card card-primary card-outline">
                      <div className="card-header">
                        <h3 className="card-title">
                          <div className="row gap-1">
                            <i className="fas fa-calendar-alt"></i>
                            <span> Agenda de compromissos</span>
                            {this.state.statusAtividade.map(item=>{return(
                              <span className={`status-${item.codigo} status-etrium`}>{item.status}</span>

                              )})}
                          </div>
                         </h3>
                      </div>
                      <div className="card-body">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <MultiSelect inputId="idGrupos"
                                        value={this.state.gruposLista}
                                        options={this.state.grupos}
                                        style={{width: '100%', height: '37px', padding_top: '1px'}}
                                        onChange={(e) => this.setState({ gruposLista: e.value })}
                                        filter showClear filterBy="nome"
                                        optionLabel="nome" />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                          <MultiSelect inputId="idUsuarios"
                                       value={this.state.usuariosLista}
                                       options={this.state.usuarios}
                                       style={{width: '100%', height: '37px', padding_top: '1px'}}
                                       onChange={(e) => this.setState({ usuariosLista: e.value })}
                                       filter showClear filterBy="nome"
                                       optionLabel="nome" />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                          <MultiSelect inputId="idStatus"
                                       value={this.state.selectStatusConsulta}
                                       options={this.state.statusAtividade}
                                       style={{width: '100%', height: '37px', padding_top: '1px'}}
                                       onChange={(e) => this.setState({ selectStatusConsulta: e.value })}
                                       filter showClear filterBy="status"
                                       optionLabel="status" />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                          <ButtonSm id='filtroSelects' style={{height: '37px'}} type="button" className={strings.buttonSecondarySm}
                                    onClick={this.listarAgenda}>
                                <i className="pi pi-filter"></i>Filtrar</ButtonSm>
                          </div>
                        </div>
                      </div>
                        <FullCalendar
                                plugins={[dayGridPlugin, interactionPlugin, timegridPlugin]}
                                initialView="dayGridMonth"
                                editable={true}
                                selectable={true}
                                locales={[ptBrLocale]}
                                selectMirror={true}
                                dayMaxEvents={true}
                                events={this.state.agendaCalendario}
                                eventClick={this.abrirAtividade}
                                dateClick={this.novaAtividade}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                    <Loading open={this.state.openSpinner}/>

                  {/**Nova Atividade */}
              {this.state.novaAtividade &&<CadastrarAtividadeDialog
                      isOpen={this.state.novaAtividade}
                      toast={this.toast}
                      onClose={() => this.setState({novaAtividade: false})}
                      refresh={this.listarAgenda}
                      datetLimit={this.state.dtLimite}
              >

              </CadastrarAtividadeDialog>}
                  {/**Alterar Atividade */}
              {this.state.modalAtividadeAlterar &&
                      <DialogAlterarAtividade
                              isOpen={this.state.modalAtividadeAlterar}
                              onClose={()=>this.setState({modalAtividadeAlterar: false})}
                              statusAtiv={this.state.statusAtividade}
                              toast={this.toast}
                              data={this.state.atividade}
                              refresh={this.listarAgenda}
                      >
                      </DialogAlterarAtividade>
              }


                </section>
              </div>
      </>
    )
  }
}
Agenda.contextType = AuthContext;
export default withRouter (Agenda)

