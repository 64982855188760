import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {Dialog} from "primereact/dialog";
import React, {useEffect, useState} from "react";
import FinanceiroService from "../../app/service/FinanceiroService";
import {ButtonEtrium} from "../ButtonEtrium";
import {InputNumber} from "primereact/inputnumber";
import {Calendar} from "primereact/calendar";
import {DateFormat} from "../../utils/DateFormat";
import {IsNullOrEmpty} from "../../utils/IsNullOrEmpy";
import {formatarMoeda} from "../../utils/formatar-moeda-tela";


export const PagamentoReceitasDespesasDialog = ({lancamento, tipo, open, onClose, toast, refresh}) => {

    const [tipoNome, setTipoNome] = useState('');
    const [vlLancamento, setVlLancamento] = useState('');
    const [dtVencimento, setDtVencimento] = useState('');
    const [observacao, setObservacao] = useState('');

    const [vlLancamentoNovo, setVlLancamentoNovo] = useState("");
    const [dtVencimentoNovo, setDtVencimentoNovo] = useState("");

    // const {usuarioAutenticado} = useContext(AuthContext)

    const financeiroService = new FinanceiroService()

    const pagarDespesaReceita = async () => {

        if (lancamento.situacao === 'P' && lancamento.situacao === 'Pago') {
            toast.show({severity: 'error', summary: 'Pagamento', detail: 'Lançamento já pago', life: 4000});
            return false;
        }
        if(!IsNullOrEmpty(vlLancamentoNovo) && vlLancamentoNovo < vlLancamento && IsNullOrEmpty(dtVencimentoNovo)){
                toast.show({
                    severity: 'error',
                    summary: 'Pagamento',
                    detail: 'Insira uma nova data de vencimento',
                    life: 4000
                });
                return false;

        }
        pagar().finally(()=>{
        refresh()
        })

    }

    async function pagar(){

        let novoLancamento = lancamento
        let dataFormatadaDataVencimento = '';
            if (!IsNullOrEmpty(dtVencimentoNovo)) {
                dataFormatadaDataVencimento = DateFormat(dtVencimentoNovo)
            }
        novoLancamento.dtVencimentoNovo = dataFormatadaDataVencimento
        novoLancamento.vlLancamentoNovo = vlLancamentoNovo
        novoLancamento.observacao = observacao

        await financeiroService.pagarOuCancelarLancamentoIndividual(novoLancamento).then(() => {
                    toast.show({severity: 'success', summary: 'Pagamento', detail: 'Pagamento efetuado', life: 4000});
                }).catch(error => {
                    let detail = error.response.data.detail
                    toast.show({severity: 'error', summary: 'Pagamento', detail: `${detail}`, life: 4000});
                });
    }


    function preencherDados(data) {
        setTipoNome(data.tipo.nome);
        setVlLancamento(data.vlLancamento);
        setDtVencimento(data.dtVencimento);

        setObservacao(data.observacao);


    }

    useEffect(() => {
        preencherDados(lancamento)
        // eslint-disable-next-line
    }, [])

    function renderFooter() {
        return (
            <div className="flex-1 gap-1">
                <ButtonEtrium
                    buttonType={"success"}
                    label="Pagar lançamento"
                    onClick={pagarDespesaReceita}/>
            </div>
        );
    }

    return (
        <Dialog header="Pagamento de despesas"
                visible={open}
                style={{width: '50vw'}}
                footer={renderFooter}
                onHide={onClose}>
            <div className='flex-column gap-1'>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="formGroup">
                            <label>{tipo.tipo === "D" ? "Despesa" : "Receita"}</label>
                            <InputText id="tipoNomePagamento"
                                       type="text"
                                       style={{width: '100%', height: '37px'}}
                                       value={tipoNome}
                                       disabled={true}
                                       className="p-inputtext-sm p-d-block p-mb-1"/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="formGroup">
                            <label>Valor</label>
                            <InputText id="valorPagamento"
                                       type="text"
                                       style={{width: '100%', height: '37px'}}
                                       value={formatarMoeda(vlLancamento)}
                                       disabled={true}
                                       className="p-inputtext-sm p-d-block p-mb-1"/>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="formGroup">
                            <label>Vencimento</label>
                            <InputText id="vencimentoPagamento"
                                       type="text"
                                       style={{width: '100%', height: '37px'}}
                                       value={dtVencimento}
                                       disabled={true}
                                       className="p-inputtext-sm p-d-block p-mb-1"/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="formGroup">
                            <label>Pagar um novo valor</label>
                            <InputNumber inputId="pagamentoNovoValorDespesa"
                                         value={vlLancamentoNovo}
                                         style={{width: '100%', height: '37px'}}
                                         onChange={(e) => setVlLancamentoNovo(e.value)}
                                         mode="decimal" locale="de-BR" minFractionDigits={2}/>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="formGroup">
                            <label>Novo Vencimento saldo devedor</label>
                            <Calendar id="novoVencimentoDespesa"
                                      value={dtVencimentoNovo}
                                      onChange={(e) => setDtVencimentoNovo(e.value)}
                                      locale="es"
                                      style={{width: '100%', height: '37px'}}
                                      dateFormat="dd/mm/yy"
                                      mask="99/99/9999"/>
                        </div>
                    </div>
                </div>
            </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="formGroup">
                            <label>Observação</label>
                            <InputTextarea id="observacaoPagamento"
                                           type="text"
                                           style={{width: '100%'}}
                                           value={observacao}
                                           maxLength={250}
                                           onChange={e => setObservacao(e.target.value)}
                                           className="p-inputtext-sm p-d-block p-mb-1"
                                           rows={2} cols={30}/>
                        </div>
                    </div>
                </div>
        </Dialog>
)
}
