import ApiService from '../apiservice'

export default class GraficoProcessoService extends ApiService {
  constructor() {
    super('/graficoProcesso')
  }

  graficoStatusProcessual(empresa) {
    return this.get(`/statusProcessual/${empresa}`);
  }

  graficoGrupoTrabalho(empresa) {
    return this.get(`/grupoTrabalho/${empresa}`);
  }

  graficoAreaAtuacao(empresa) {
    return this.get(`/areaAtuacao/${empresa}`);
  }

  graficoTipoAcao(empresa) {
    return this.get(`/tipoAcao/${empresa}`);
  }

  graficoObjetosAcao(empresa) {
    return this.get(`/objetosAcao/${empresa}`);
  }

  graficoProcessoArquivado(empresa, dataInicial, dataFinal) {
    return this.get(`/graficoProcessoArquivado?empresa=${empresa}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`);
  }

  graficoProcessoMes(empresa, dataInicial, dataFinal) {
    return this.get(`/graficoProcessoMes?empresa=${empresa}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`);
  }

  totalProcessos(empresa) {
    return this.get(`/totalProcessos/${empresa}`);
  }

  totalAtivos(empresa) {
    return this.get(`/totalAtivos/${empresa}`);
  }

  totalParados(empresa) {
    return this.get(`/processosParados/${empresa}`);
  }

  totalArquivados(empresa) {
    return this.get(`/processosArquivados/${empresa}`);
  }

  totalExcluidos(empresa) {
    return this.get(`/processosExcluidos/${empresa}`);
  }

  totalProcessosPush(empresa) {
    return this.get(`/processosPush/${empresa}`);
  }
}