import ApiService from '../apiservice'

export default class ArquivoFinanceiroService extends ApiService {
  constructor() {
    super('/arquivoFinanceiro');
  }

  incluirArquivo(formData) {
    return this.post('',formData);
  }

  incluirArquivoTemp(formData) {
    return this.post('/temp', formData);
  }

  listarArquivosTemp(usuario) {
    return this.get(`/listarTemp/${usuario}`)
  }

  excluirArquivoTemp(codigo) {
    return this.delete(`/excluirArquivoTemp/${codigo}`);
  }

  excluirArquivosTemp(usuario) {
    return this.delete(`/excluirArquivosTemp/${usuario}`);
  }

  download(codigo) {
    return this.get2(`/download/${codigo}`);
  }

  consultarArquivoTemp(codigo) {
    return this.get(`/consultarArquivoTemp/${codigo}`);
  }

  downloadTemp(codigo) {
    return this.get2(`/downloadTemp/${codigo}`);
  }

  listarArquivos(lancamento) {
    return this.get(`/arquivos/${lancamento}`);
  }

  excluirArquivo(codigo) {
    return this.delete(`/excluirArquivo/${codigo}`);
  }

  consultarArquivo(codigo) {
    return this.get(`/consultarArquivo/${codigo}`);
  }

  adicionarDescricao(arquivo) {
    return this.put(`/adicionarDescricao/${arquivo.codigo}`, arquivo);
  }

  adicionarDescricaoTemp(arquivo) {
    return this.put(`/adicionarDescricaoTemp/${arquivo.codigo}`, arquivo);
  }
}
