import ApiService from '../apiservice'
import {IsNullOrEmpty} from "../../utils/IsNullOrEmpy";

export default class PushService extends ApiService {
  constructor() {
    super('/push')
  }

  quantidadePush(empresa,dataCarregamento) {
    return this.get(`/quantidadePush?empresa=${empresa}&dataCarregamento=${dataCarregamento}`);
  }

  consultarMovimentos(filtro) {
    let params = `/movimentos?empresa=${filtro.empresa}`;
    
    if(filtro.dataInicial != null && filtro.dataFinal != null) {
      params = `${params}&dataInicial=${filtro.dataInicial}&dataFinal=${filtro.dataFinal}`;
    }

    if(!IsNullOrEmpty(filtro.dataCarregamento)) {
      params = `${params}&dataCarregamento=${filtro.dataCarregamento}`;
    }

    if(!IsNullOrEmpty(filtro.grupos)) {
      params = `${params}&grupos=${filtro.grupos}`;
    }

    if(!IsNullOrEmpty(filtro.status)) {
      params = `${params}&status=${filtro.status}`;
    }

    if(!IsNullOrEmpty(filtro.processo)) {
      params = `${params}&processo=${filtro.processo}`;
    }

    if(!IsNullOrEmpty(filtro.pasta)) {
      params = `${params}&pasta=${filtro.pasta}`;
    }

    if(!IsNullOrEmpty(filtro.movimentacao)) {
      params = `${params}&movimentacao=${filtro.movimentacao}`;
    }
    if(!IsNullOrEmpty(filtro.classificacao)) {
      params = `${params}&classificacao=${filtro.classificacao}`;
    }
    if(!IsNullOrEmpty(filtro.ordem)) {
      params = `${params}&ordem=${filtro.ordem}`;
    }

    return this.get(params);
  }

  atualizaMovimento(movimento) {
    return this.put(`/${movimento.codigo}`,movimento);
  }
}