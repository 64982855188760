export function IsValidPassword(password) {
    // //(?=.*[a-z]) A corda deve conter pelo menos 1 caracter alfabético minúsculo
    // // (?=.*[A-Z]) A corda deve conter pelo menos 1 carácter alfabético em maiúsculas
    // // (?=.*[0-9]) A cadeia deve conter pelo menos 1 carácter numérico
    // // (?=.{8,}) A cadeia deve ser de oito caracteres ou mais
    // const passRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
    //
    // return password.match(passRegex) !== null;

    // Must have 8 characters
    if (password.length < 8) {
        return false;
    }

    // Must contain at least one number
    if (!/\d/.test(password)) {
        return false;
    }
    // Must contain at least one letter
    if (!/[a-z]/.test(password)) {
        return false;
    }
    // Must contain at least one uppercase letter
    if (!/[A-Z]/.test(password)) {
        return false;
    }

    // Must contain at least one special character
    // if (!/[!@#$%^&*]/.test(password)) {
    //     return false;
    // }

    // Password is valid
    return true;
}
export function IsPasswordEquals(password1, password2) {
    return password1 === password2;
}

export function IsValidHour(horaString) {
    const [hora] = horaString.split(":").map(Number)
    return hora > 23

}

export function validarNumeroProcesso(numero) {
    // Verificar o comprimento total do número
    if (numero.length !== 23) {
        return false;
    }

    // Verificar o formato da primeira parte
    if (!/^\d{7}-\d{2}$/.test(numero.substr(0, 10))) {
        return false;
    }

    // Verificar o formato da segunda parte
    return /^\d{4}\.\d{1}\.\d{2}\.\d{4}$/.test(numero.substr(10));


}

export function validaCPF(cpf) {
    let Soma = 0;
    let Resto;

    const strCPF = String(cpf).replace(/\D/g, '');

    if (strCPF.length !== 11)
        return false

    if ([
        '00000000000',
        '11111111111',
        '22222222222',
        '33333333333',
        '44444444444',
        '55555555555',
        '66666666666',
        '77777777777',
        '88888888888',
        '99999999999',
    ].indexOf(strCPF) !== -1)
        return false

    let i;
    for (i=1; i<=9; i++)
        Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);

    Resto = (Soma * 10) % 11

    if ((Resto === 10) || (Resto === 11))
        Resto = 0

    if (Resto !== parseInt(strCPF.substring(9, 10)) )
        return false

    Soma = 0

    for (i = 1; i <= 10; i++)
        Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i)

    Resto = (Soma * 10) % 11

    if ((Resto === 10) || (Resto === 11))
        Resto = 0

    return Resto === parseInt(strCPF.substring(10, 11));


}
