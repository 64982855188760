import React from 'react'

import { AutoComplete } from 'primereact/autocomplete'
import '../../src/pages/gruposTrabalho/grupos.css'
import '../../src/pages/processo/processo.css'

export default class PartesAlterar extends React.Component {
  render() {
    return(
      <>
      <div className={this.props.classeSecundaria}>
        <div className="row">
          <div className="col-sm-8">
            <div className="form-group">
              <AutoComplete value={this.props.value}
                            suggestions={this.props.suggestions}
                            completeMethod={this.props.completeMethod}
                            name={this.props.name}
                            id={"partes-ac-search"}
                            style={{width: '100%'}}
                            field="nome" multiple onChange={this.props.change}
                            placeholder={this.props.placeholder}
                            className="p-autoComplete-sm p-d-block p-mb-0"/>
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}
