import { Button } from "primereact/button";
import React, { useContext, useState } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { AuthContext } from "../main/ProvedorAutenticacao";
import { strings } from "../utils/strings";
import TipoDecisaoService from "../app/service/TipoDecisao";


export const DialogTipoDesicao = ({ open, toast, close, refreshList }) => {

    const [state, setState] = useState({
        tipoDesicaoService: new TipoDecisaoService(),
        //Cadastro
        nome: '',
        detail: '',
        //Progresso
        classeDiv: 'hide',

    });
    const { usuarioAutenticado } = useContext(AuthContext);

    const cadastrarTipoDecisao = async () => {
        stateChange("classeDiv", 'show');

        if (state.nome === '') {
            toast.show({ severity: 'error', summary: 'Tipos de decisão', detail: 'Informe o nome do tipo de decisão', life: 4000 });
            stateChange("classeDiv", 'hide');

            return false;
        }

        await state.tipoDesicaoService.cadastrarTipoDecisao({
            nome: state.nome,
            empresa: usuarioAutenticado.empresa
        }).then(response => {
            toast.show({
                severity: 'success',
                summary: 'Tipos de decisão',
                detail: 'Tipo de decisão cadastrada com sucesso',
                life: 4000
            });
            refreshList();
        }).catch(error => {
            toast.show({ severity: 'error', summary: 'Tipos de decisão', detail: `${error.response.data.detail}`, life: 4000 });
        });
        stateChange("classeDiv", 'hide');
        limparCampos();
        close();
    }

    function renderFooterCadastro() {
        return (
            <div className="row-etrium gap-1">
                <Button label="Cadastrar"
                    className={strings.buttonPrimaryStyle}
                    onClick={cadastrarTipoDecisao} />
            </div>
        );
    }

    function stateChange(name, value) {
        setState({
            ...state,
            [`${name}`]: value
        });
    }
    const limparCampos = () => {
        stateChange("nome", '');
    }

    return (

        <Dialog header="Cadastro de tipo de decisão"
            visible={open}
            style={{ width: '40vw' }}
            footer={renderFooterCadastro()}
            onHide={close}>

            <div className="p-fluid">
                <div className="p-field">
                    <label htmlFor="nomeStatus">Nome<span className="obrigatorioAsterisco"> *</span></label>
                    <InputText id="nomeStatus"
                        type="text"
                               maxLength={50}
                        value={state.nome}
                        onChange={e => stateChange("nome", e.target.value)}
                        className="p-inputtext-sm p-d-block p-mb-1" />
                </div>
            </div>
        </Dialog>

    );
}