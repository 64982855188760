import ApiService from '../apiservice'

export default class AtendimentoService extends ApiService {
  constructor() {
    super('/atendimentos');
  }

  consultarAtendimentos(empresa, dataInicial, dataFinal, pessoa, grupo) {
    return this.get(`/lista?empresa=${empresa}&dataInicial=${dataInicial}&dataFinal=${dataFinal}&pessoa=${pessoa}&grupo=${grupo}`);
  }

  cadastrarAtendimento(atendimento) {
    return this.post('',atendimento);
  }

  cadastrarHistorico(historico) {
    return this.post('/historico', historico);
  }

  consultarHistoricos(atendimento) {
    return this.get(`/historico/${atendimento}`);
  }

  consultarAtendimento(codigo) {
    return this.get(`/atendimento/${codigo}`);
  }

  excluirHistorico(codigo) {
    return this.delete(`/historico/${codigo}`);
  }

  alterarAtendimentoGrupo(atendimento) {
    return this.put(`/atendimentoGrupo/${atendimento.codigo}`, atendimento);
  }

  alterarAtendimentoProcesso(atendimento) {
    return this.put(`/atendimentoProcesso/${atendimento.codigo}`, atendimento);
  }

  consultarAtendimentosPorPessoa(pessoa) {
    return this.get(`/porPessoa/${pessoa}`);
  }
}
