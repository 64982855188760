import {InputText} from "primereact/inputtext";
import React from "react";


export const InputTextEtrium=({label, require,...rest})=>{
    return(
        <div className="flex-column">
            <label>{label}{require &&<span className="obrigatorioAsterisco"> *</span>}</label>
            <InputText
                       {...rest}
                       style={{width: '100%', height: '37px'}}
                       className="p-inputtext-sm p-d-block p-mb-1"/>
        </div>
    )
}
