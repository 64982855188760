import React from "react";


export const CardFincanceiro = ({title, onPress, styles, info, id})=>{
    return(
        <button id={id} className={"card-financeiro "+ styles}
                   onClick={onPress}
                   data-toggle="modal"
                   data-target="#modal-default">
                    <div>
                        <h6 className="textoCard">{title}</h6>
                        <text className="text-white">{info}</text>
                    </div>
        </button>
    )
}
