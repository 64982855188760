import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import React, {useEffect, useState} from "react";
import {Button} from "primereact/button";
import {Loading} from "../../Loading";
import {HeaderPanelFichaProcesso} from "./HeaderPanelFichaProcesso";
import AtividadeService from "../../../app/service/AtividadeService";
import {CadastrarAtividadeDialog} from "../../Atividade/CadastrarAtividadeDialog";
import {DialogAlterarAtividade} from "../../Atividade/DialogAlterarAtividade";
import {formatDateHoraTela} from "../../../utils/DateFormat";

export const AtividadeProcesso = ({
                                      processo,
                                      autor,
                                      reu,
                                      nrCnj,
                                      pasta,
                                      nrProcesso,
                                      toast,
                                  }) => {

    const [atividades, setAtividades] = useState([])
    const [atividade, setAtividade] = useState(null)
    const [modalAtividade, setModalAtividade] = useState(false)
    const [modalAlterarAtividade, setModalAlterarAtividade] = useState(false)

    const [loading, setLoading] = useState(false);

    //service

    const atividadeService = new AtividadeService()

    const consultarAtividades = async () => {
        setLoading(true)
        await atividadeService.consultarAtividadePorProcesso(processo.codigo)
            .then(response => {
                setAtividades(response.data);
            }).catch(error => {
                let detail = error.response.data.detail
                toast.show({severity: 'error', summary: 'Atividades', detail: `${detail}`, life: 4000});
            }).finally(() => {
                setLoading(false)
            })
    }

    function consultarAtividade(atividade){
        setAtividade(atividade);
        setModalAlterarAtividade(true);
    }


    useEffect(() => {
        consultarAtividades().finally()
        // eslint-disable-next-line
    }, [])


    function templateStatus(data) {
        let status ;
        switch (data.status.codigo) {
            case 1:
                status = 'statusAtivo';
                break;
            case 2:
                status = 'statusAguardando';
                break;
            case 3:
                status = 'statusCancelado'
                break;
            case 4:
                status = 'statusCiente';
                break;
            case 5:
                status = 'statusConcluido'
                break;
            default:
                status = 'statusFazendo'
        }

        return (
            <React.Fragment>
                <div className={status}>{data.status.status}</div>
            </React.Fragment>
        );
    }

    function novaAtividadeHeader() {
        return (
            <div>
                <Button icon="pi pi-plus-circle" className="p-button-rounded p-button-text"
                        tooltip="Cadastrar atividade"
                        onClick={() => setModalAtividade(true)}
                        tooltipOptions={{position: 'top'}}/>
            </div>
        )
    }

    function acoesAtividade(data) {
        return (
            <div>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-text"
                        tooltip="Editar atividade"
                        onClick={() => consultarAtividade(data)}
                        tooltipOptions={{position: 'top'}}/>
            </div>

        )
    }
    function formateDataLimteTela(data){
        return(
            <div>
                {formatDateHoraTela(data.dtLimite)}
            </div>
        )
    }
    function formateDataFatalTela(data){
        return(
            <div>
                {formatDateHoraTela(data.dtFatal)}
            </div>
        )
    }

    return (
        <>
            <div className={"card-body"}>
            <Loading open={loading}/>
            <HeaderPanelFichaProcesso
                autor={autor}
                reu={reu}
                nrCnj={nrCnj}
                pasta={pasta}
                nrProcesso={nrProcesso}
            />

            <div className="row">
                <div className="col-sm-12">
                    <div className="form-group">
                        <DataTable value={atividades} paginator
                                   responsiveLayout="scroll" size="small"
                                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                   currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}"
                                   rows={10}
                                   emptyMessage="Nenhum histórico">
                            <Column body={templateStatus} exportable={false}
                                    style={{minWidth: '8rem'}} header="Situação"></Column>
                            <Column field="titulo" header="Título"></Column>
                            <Column field="subGrupo.nome" header="Sub-grupo"></Column>
                            <Column field="responsaveis" header="Responsáveis"></Column>
                            <Column body={formateDataLimteTela}  header="Data limite"></Column>
                            <Column body={formateDataFatalTela} header="Prazo fatal"></Column>
                            <Column body={acoesAtividade} exportable={false}
                                    style={{minWidth: '8rem'}}
                                    header={novaAtividadeHeader}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
            </div>
             {modalAtividade && <CadastrarAtividadeDialog
                isOpen={modalAtividade}
                onClose={() => setModalAtividade(false)}
                _processo={processo}
                refresh={consultarAtividades}
                toast={toast}
            />}
            {modalAlterarAtividade &&
                <DialogAlterarAtividade
                    isOpen={modalAlterarAtividade}
                    onClose={() => setModalAlterarAtividade(false)}
                    data={atividade}
                    toast={toast}
                    refresh={consultarAtividades}
                />}
        </>
    )
}
