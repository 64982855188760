import {Button} from "primereact/button";
import React, {useContext, useState} from "react";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {AuthContext} from "../main/ProvedorAutenticacao";
import TipoAcaoService from "../app/service/TipoAcaoService";
import { strings } from "../utils/strings";


export const DialogTipoAcao = ({ open, toast, close, refrashList }) => {

    const [state, setState] = useState({
        tipoAcaoService: new TipoAcaoService(),
        //Cadastro
        codigo: '',
        nome: '',
        empresa: null,
        //Progresso
        classeDiv: 'hide',

    });
    const { usuarioAutenticado } = useContext(AuthContext);


    const cadastrarTipoAcao = async () => {
        stateChange("classeDiv", 'show');
        if (state.nome === '') {
            toast.show({ severity: 'error', summary: 'Tipo de ação', detail: 'Informe o nome do tipo de ação', life: 4000 });
            stateChange("classeDiv", 'hide');
            return false;
        }
        await state.tipoAcaoService.cadastrarTipoAcao({
            nome: state.nome,
            empresa: usuarioAutenticado.empresa
        }).then(response => {
            toast.show({ severity: 'success', summary: 'Tipo de ação', detail: 'Tipo ação cadastrado com sucesso', life: 4000 });
            refrashList();

        }).catch(error => {
            stateChange("detail", error.response.data.detail);
            toast.show({ severity: 'error', summary: 'Tipo de ação', detail: `${state.detail}`, life: 4000 });
        });

        stateChange("classeDiv", 'hide');
        limparCampos();
        close();
    }
    function renderFooterCadastro() {
        return (
            <div className="row-etrium gap-1">
                <Button label="Cadastrar"
                    className={strings.buttonPrimaryStyle}
                    onClick={cadastrarTipoAcao} />
            </div>
        );
    }

    function stateChange(name, value) {
        setState({
            ...state,
            [`${name}`]: value
        });
    }
    const limparCampos = () => {
        stateChange("nome", '');
    }

    return (

        <Dialog header="Cadastrar tipo de ação"
            visible={open}
            style={{ width: '40vw' }}
            footer={renderFooterCadastro()}
            onHide={close}>

            <div className="p-fluid">
                <div className="p-field">
                    <label htmlFor="nomeTipo">Nome<span className="obrigatorioAsterisco"> * </span></label>
                    <InputText id="nomeTipo"
                        type="text"
                        value={state.nome}
                        onChange={e => stateChange("nome", e.target.value)}
                        className="p-inputtext-sm p-d-block p-mb-1" />
                </div>
            </div>
        </Dialog>

    );
}