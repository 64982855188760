import React from 'react'
import {withRouter} from 'react-router-dom'

import {Dropdown} from 'primereact/dropdown'
import {InputText} from 'primereact/inputtext'
import {Toast} from 'primereact/toast';
import {InputMask} from 'primereact/inputmask';
import {Button} from 'primereact/button';

import NavBar from '../../componentes/Navbar'

import EstadoService from '../../app/service/EstadoService'
import CidadeService from '../../app/service/CidadeService'
import UsuarioService from '../../app/service/UsuarioService'
import PerfilService from '../../app/service/PerfilService'
import {AuthContext} from '../../main/ProvedorAutenticacao'

import './usuarios.css'
import {ALERT_MSG_PROCESSO_AUTO_ALTER, strings} from "../../utils/strings";
import {Checkbox} from "primereact/checkbox";
import {Dialog} from "primereact/dialog";
import {IsNullOrEmpty} from "../../utils/IsNullOrEmpy";
import {Password} from "primereact/password";
import {IsPasswordEquals} from "../../utils/Validator";
import {Message} from "primereact/message";
import {limparTelefone} from "../../utils/RemoveCaracteresTelefone";

class CadastroUsuario extends React.Component {

    constructor(props) {
        super(props);

        this.estadoService = new EstadoService();
        this.cidadeService = new CidadeService();
        this.usuarioService = new UsuarioService();
        this.perfilService = new PerfilService();
        this.onEstadoChange = this.onEstadoChange.bind(this);
        this.onCidadeChange = this.onCidadeChange.bind(this);
        this.onPerfilChange = this.onPerfilChange.bind(this);

        this.state = {
            //Cadastro de Usuário
            nome: '',
            processoAutomatico: false,
            email: '',
            cpf: '',
            endereco: '',
            numero: '',
            cep: '',
            bairro: '',
            complemento: '',
            telefone1: '',
            telefone2: '',
            estados: [],
            cidades: [],
            perfil: null,
            selectEstado: null,
            selectCidade: null,
            perfis: [],
            senha: '',
            confirmSenha: '',
            alertDialog: false
        };
    }

    listarEstados = async () => {
        await this.estadoService.listarEstados()
            .then(response => {
                this.setState({estados: response.data});
            })
    }

    limparCampos = () => {
        this.setState({
            nome: '',
            processoAutomatico: false,
            email: '',
            cpf: '',
            endereco: '',
            numero: '',
            cep: '',
            bairro: '',
            complemento: '',
            telefone1: '',
            telefone2: '',
            perfil: null,
            selectEstado: null,
            cidade: null,
            selectCidade: null,
            // empresa: null
        })
    }

    cadastrarUsuario = () => {
        if (this.state.nome === '') {
            this.toast.show({
                severity: 'error',
                summary: 'Cadastro de usuários',
                detail: 'Informe o nome do usuário',
                life: 4000
            });
            return false;
        }
        if (this.state.processoAutomatico) {
            if (IsNullOrEmpty(this.state.cpf)) {
                this.toast.show({
                    severity: 'error',
                    summary: 'Cadastro de usuários',
                    detail: 'Informe o CPF do usuário',
                    life: 4000
                });
                return false;
            }
        }

        if (this.state.email === '') {
            this.toast.show({
                severity: 'error',
                summary: 'Cadastro de usuários',
                detail: 'Informe o e-mail do usuário',
                life: 4000
            });
            return false;
        }

        if (this.state.selectCidade == null) {
            this.toast.show({
                severity: 'error',
                summary: 'Cadastro de usuários',
                detail: 'Informe a cidade',
                life: 4000
            });
            return false;
        }

        if (this.state.perfil == null) {
            this.toast.show({
                severity: 'error',
                summary: 'Cadastro de usuários',
                detail: 'Informe o perfil do usuário',
                life: 4000
            });
            return false;
        }
        const {senha, confirmSenha} = this.state
        if (!IsPasswordEquals(senha, confirmSenha)) {
            this.toast.show({
                severity: 'error',
                summary: 'Cadastro de usuários',
                detail: 'As senhas são diferentes',
                life: 4000
            });
            return false;
        }
        const {telefone1, telefone2} = this.state
        let newTelefone1 = limparTelefone(telefone1)
        let newTelefone2 = null

        if (!IsNullOrEmpty(this.state.telefone2)) {
            newTelefone2 = limparTelefone(telefone2)
        }

        const usuarioLogado = this.context.usuarioAutenticado

        this.usuarioService.cadastrarUsuario({
            nome: this.state.nome,
            email: this.state.email,
            cpf: this.state.cpf,
            endereco: this.state.endereco,
            numero: this.state.numero,
            cep: this.state.cep,
            bairro: this.state.bairro,
            complemento: this.state.complemento,
            telefone1: newTelefone1,
            telefone2: newTelefone2,
            perfil: this.state.perfil,
            cidade: this.state.selectCidade,
            empresa: usuarioLogado.empresa,
            senha: this.state.senha,
            processoAutomatico: this.state.processoAutomatico // 1 para true 0 para false
        }).then(() => {
            this.toast.show({
                severity: 'success',
                summary: 'Cadastro de usuários',
                detail: 'Usuário cadastrado com sucesso',
                life: 4000
            });
            this.props.history.push('/usuarios')
        }).catch(error => {
            this.setState(error.response.data)
            this.toast.show({
                severity: 'error',
                summary: 'Cadastro de usuários',
                detail: `${this.state.detail}`,
                life: 4000
            });
        })
    }

    voltar = () => {
        this.props.history.push('/usuarios')
    }

    async onEstadoChange(e) {
        await this.setState({selectEstado: e.value});
        this.listarCidades();
    }

    onCidadeChange(e) {
        this.setState({selectCidade: e.value})
    }

    onPerfilChange(e) {
        this.setState({perfil: e.value});
    }

    listarCidades = async () => {
        if (this.state.selectEstado) {
            await this.cidadeService.listarCidades(this.state.selectEstado.codigo)
                .then(response => {
                    this.setState({cidades: response.data})
                }).catch(error => {
                    this.setState(error.response.data)
                    this.toast.show({
                        severity: 'error',
                        summary: 'Usuários',
                        detail: `${this.state.detail}`,
                        life: 4000
                    });
                })
        }
    }

    listarPerfis = async () => {
        await this.perfilService.listarPerfis()
            .then(response => {
                this.setState({perfis: response.data})
            }).catch(() => {
                this.toast.show({severity: 'error', summary: 'Perfis', detail: 'Erro ao listar perfis', life: 4000});
            })
    }

    onDialogClose = () => {
        this.setState({alertDialog: false});

    }
    onProcessoChecked = (e) => {
        this.setState({
            alertDialog: e.checked,
            processoAutomatico: e.checked
        })
    }
    handlePasswordInput = e => {
        // Set the value of the password to the current value of the input
        this.setState({senha: e.target.value});
    }

    handleRepeatPasswordInput = e => {
        // Set the value of the password to the current value of the input

        this.setState({confirmSenha: e.target.value});
        // Check that the password is at least 8 characters long
        if (IsPasswordEquals(e.target.value, this.state.senha)) {
            this.setState({isValidRepeatPassword: true})
        } else {
            this.setState({isValidRepeatPassword: false})
        }
    }

    onPasswordRepeatFocusEnd = () => {
        const {senha, confirmSenha} = this.state
        if (!IsPasswordEquals(senha, confirmSenha) && confirmSenha.length > 0) {

        }
    }

    alerts = (msg) => {
        return <div className={'mt-1'}><Message severity="warn" text={msg}/></div>
    }
    alertsucess = (msg) => {
        return <div className={'mt-1'}><Message severity="success" text={msg}/></div>
    }

    componentDidMount() {
        this.listarEstados();
        this.listarPerfis();
    }

    render() {
        return (
            <>
                <NavBar/>
                <Toast ref={(el) => this.toast = el}/>
                <Dialog
                    header="Processo automático" autoComplete="nope"
                    visible={this.state.alertDialog}
                    style={{width: '50vw'}}
                    // footer={renderFooter}
                    onHide={this.onDialogClose}

                >
                    <div className="flex-row align-content-center gap-1 mb-5">
                        <div className="my-auto">
                            <i className="pi pi-exclamation-triangle" style={{fontSize: '2.5rem',}}></i>
                        </div>
                        <p className="mb-0">
                            {ALERT_MSG_PROCESSO_AUTO_ALTER}
                        </p>

                    </div>
                </Dialog>
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <section className="content-header">
                            <div className="card card-primary card-outline">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-users"></i>
                                        <span> Cadastro de usuários</span>
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="flex-row gap-5">
                                                <div className="flex-1 flex-column mb-3">
                                                    <label>Nome<span className="obrigatorioAsterisco"> *</span></label>
                                                    <InputText id="nome"
                                                               type="text"
                                                               maxLength={100}
                                                               style={{width: "100%"}}
                                                               value={this.state.nome}
                                                               onChange={e => this.setState({nome: e.target.value})}
                                                               className="p-inputtext-sm p-d-block p-mb-1"/>
                                                </div>

                                                <div className="flex flex-row justify-content-end align-items-center">
                                                    <Checkbox inputId="processoAuto" name="Processo Automatico"
                                                              checked={this.state.processoAutomatico}
                                                              value="Processo Automático"
                                                              onChange={this.onProcessoChecked}/>
                                                    <label htmlFor="processoAuto" className="ml-2">Processo
                                                        Automático</label>

                                                </div>

                                            </div>


                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label>E-mail<span className="obrigatorioAsterisco"> *</span></label>
                                                <InputText id="email"
                                                           type="text"
                                                           maxLength={100}
                                                           style={{width: '100%'}} autoComplete="off"
                                                           value={this.state.email}
                                                           onChange={e => this.setState({email: e.target.value})}
                                                           className="p-inputtext-sm p-d-block p-mb-1"/>

                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <div className="form-group">
                                                <label>CPF
                                                    {this.state.processoAutomatico &&
                                                        <span className="obrigatorioAsterisco"> *</span>}
                                                </label>
                                                <InputMask mask="999.999.999-99"
                                                           id="cpf"
                                                           type="text"
                                                           style={{width: '100%'}}
                                                           value={this.state.cpf}
                                                           onChange={e => this.setState({cpf: e.target.value})}
                                                           className="p-inputtext-sm p-d-block p-mb-1"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Endereço</label>
                                                <InputText id="endereco"
                                                           type="text"
                                                           maxLength={200}
                                                           style={{width: '100%'}}
                                                           autocomplete="off"
                                                           value={this.state.endereco}
                                                           onChange={e => this.setState({endereco: e.target.value})}
                                                           className="p-inputtext-sm p-d-block p-mb-1"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Bairro</label>
                                                <InputText id="bairro"
                                                           type="text"
                                                           maxLength={100}
                                                           style={{width: '100%'}}
                                                           value={this.state.bairro}
                                                           onChange={e => this.setState({bairro: e.target.value})}
                                                           className="p-inputtext-sm p-d-block p-mb-1"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-1">
                                            <div className="form-group">
                                                <label>Número</label>
                                                <InputText id="numero"
                                                           type="text"
                                                           maxLength={10}
                                                           style={{width: '100%'}}
                                                           value={this.state.numero}
                                                           onChange={e => this.setState({numero: e.target.value})}
                                                           className="p-inputtext-sm p-d-block p-mb-1"/>
                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <div className="form-group">
                                                <label>Cep</label>
                                                <InputMask mask="99999-999"
                                                           id="cep"
                                                           type="text"
                                                           maxLength={20}
                                                           style={{width: '100%'}}
                                                           value={this.state.cep}
                                                           onChange={e => this.setState({cep: e.target.value})}
                                                           className="p-inputtext-sm p-d-block p-mb-1"/>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label>Complemento</label>
                                                <InputText id="complemento"
                                                           type="text"
                                                           maxLength={50}
                                                           style={{width: '100%'}}
                                                           value={this.state.complemento}
                                                           onChange={e => this.setState({complemento: e.target.value})}
                                                           className="p-inputtext-sm p-d-block p-mb-1"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {this.state.selectEstado === null ?
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>Estado<span
                                                        className="obrigatorioAsterisco"> *</span></label>
                                                    <Dropdown value={this.state.selectEstado}
                                                              options={this.state.estados}
                                                              optionLabel="estado"
                                                              filter showClear filterBy="estado"
                                                              style={{width: '100%'}}
                                                              onChange={this.onEstadoChange}
                                                              id="estado"
                                                              className="p-inputtext-sm p-d-block p-mb-1"/>
                                                </div>
                                            </div>
                                            :
                                            <>
                                                <div className="col-sm-3">
                                                    <div className="form-group">
                                                        <label>Estado<span
                                                            className="obrigatorioAsterisco"> *</span></label>
                                                        <Dropdown value={this.state.selectEstado}
                                                                  options={this.state.estados}
                                                                  optionLabel="estado"
                                                                  filter showClear filterBy="estado"
                                                                  style={{width: '100%'}}
                                                                  onChange={this.onEstadoChange}
                                                                  id="estado"
                                                                  className="p-inputtext-sm p-d-block p-mb-1"/>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-group">
                                                        <label>Cidade<span
                                                            className="obrigatorioAsterisco"> *</span></label>
                                                        <Dropdown value={this.state.selectCidade}
                                                                  options={this.state.cidades}
                                                                  optionLabel="nome"
                                                                  filter showClear filterBy="nome"
                                                                  style={{width: '100%'}}
                                                                  onChange={this.onCidadeChange}
                                                                  id="cidade"
                                                                  className="p-inputtext-sm p-d-block p-mb-1"/>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label>Telefone 1 <span
                                                    className="obrigatorioAsterisco"> *</span></label>
                                                <InputMask mask="(99)9 9999-9999"
                                                           id="telefone1"
                                                           type="text"
                                                           style={{width: '100%'}}
                                                           value={this.state.telefone1}
                                                           onChange={e => this.setState({telefone1: e.target.value})}
                                                           className="p-inputtext-sm p-d-block p-mb-1"/>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label>Telefone 2</label>
                                                <InputMask mask="(99)9999-9999"
                                                           id="telefone2"
                                                           type="text"
                                                           style={{width: '100%'}}
                                                           value={this.state.telefone2}
                                                           onChange={e => this.setState({telefone2: e.target.value})}
                                                           className="p-inputtext-sm p-d-block p-mb-1"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Perfil<span className="obrigatorioAsterisco"> *</span></label>
                                                <Dropdown value={this.state.perfil} options={this.state.perfis}
                                                          style={{width: '100%'}}
                                                          optionLabel="nome"
                                                          id="perfil"
                                                          onChange={this.onPerfilChange}
                                                          className="p-inputtext-sm p-d-block p-mb-1"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'row'}>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label>Senha<span className="obrigatorioAsterisco"> *</span></label>
                                                <Password
                                                    inputStyle={{width: '100%'}}
                                                    autoComplete="nope"
                                                    id="senha"
                                                    name="senha"
                                                    toggleMask
                                                    feedback={false}

                                                    style={{
                                                        width: "100%",
                                                        borderColor: this.state.isValidPassword ? 'green' : ''
                                                    }}
                                                    value={this.state.senha}
                                                    onChange={this.handlePasswordInput}
                                                    className="p-inputtext-sm p-d-block p-mb-1"/>

                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label>Repita a senha<span
                                                    className="obrigatorioAsterisco"> *</span></label>
                                                <Password
                                                    inputStyle={{width: '100%'}}
                                                    autoComplete="nope"
                                                    id="repitaSenha"
                                                    name="repitaSenha"
                                                    toggleMask
                                                    feedback={false}
                                                    style={{
                                                        width: '100%',
                                                        borderColor: this.state.isValidPassword ? 'green' : ''
                                                    }}
                                                    value={this.state.confirmSenha}
                                                    onBlur={this.onPasswordRepeatFocusEnd} //quando elemento sai de foco
                                                    onChange={this.handleRepeatPasswordInput}
                                                    className="p-inputtext-sm p-d-block p-mb-1"/>
                                                {this.state.confirmSenha.length > 0 && this.state.isValidRepeatPassword &&
                                                    this.alertsucess("Senhas iguais!")
                                                }{this.state.confirmSenha.length > 0 && !this.state.isValidRepeatPassword &&
                                                this.alerts("As senhas são diferentes!")
                                            }
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="row gap-1 mb-4" style={{paddingLeft: '20px'}}>
                                    <Button label="Cadastrar"
                                            className={strings.buttonPrimaryStyle}
                                            onClick={this.cadastrarUsuario}/>

                                    <Button label="Limpar"
                                            className={strings.buttonWarningStyle}
                                            onClick={this.limparCampos}/>

                                    <Button label="Voltar"
                                            className={strings.buttonSecondaryStyle}
                                            onClick={this.voltar}/>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </>
        );
    }
}

CadastroUsuario.contextType = AuthContext;
export default withRouter(CadastroUsuario);
