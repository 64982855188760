import ApiService from '../apiservice'

class ConfirmeEmailService extends ApiService {
    constructor() {
        super("/confirmeEmail");
    }
    confirmarEmail(token) {
        return this.get(`/${token}`)
    }
    confirmarEmailByTokenAndCodigoConfirmacao(token, codigoConfirmacao) {
        return this.get(`?token=${token}&codigoConfirmacao=${codigoConfirmacao}`)
    }
    solicitarEmail(email, reCaptcha) {
        return this.get(`/solicitarEmail?email=${email}&reCaptcha=${reCaptcha}`)
    }
    solicitarEmailAgainByToken(token) {
        return this.get(`/solicitarEmailAgain/${token}`)
    }
    sendEmail(email) {
        return this.get(`/sendEmail/${email}`)
    }
}
export default ConfirmeEmailService
