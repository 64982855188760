import React from 'react'
import { withRouter } from 'react-router-dom'

import { Toast } from 'primereact/toast'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'

import ObjetoAcaoService from '../../app/service/ObjetoAcaoService'
import { AuthContext } from '../../main/ProvedorAutenticacao'

import NavBar from '../../componentes/Navbar'
import Progresso from '../../componentes/Progresso'
import {strings} from "../../utils/strings";
import { ButtonSm } from '../../componentes/ButtonSm';
import {IsNullOrEmpty} from "../../utils/IsNullOrEmpy";
import {Loading} from "../../componentes/Loading";

class ObjetosAcao extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      //Cadastro
      codigo: '',
      nome: '',
      empresa: null,
      objetos: [],
      modalCadastro: false,
      modalAlterar: false,
      visivble: false,
      //Progresso
      classeDiv: 'hide',
        loading: true,
        consultar: "",
    };

    this.objetoAcaoService = new ObjetoAcaoService();
    this.accept = this.accept.bind(this);
    this.acoes = this.acoes.bind(this);
    this.renderFooterCadastroObjetos = this.renderFooterCadastroObjetos.bind(this);
    this.renderFooterAlterarObjetos = this.renderFooterAlterarObjetos.bind(this);
  }

  fecharModalCadastro(name) {
    this.setState({
        [`${name}`]: false
    });
  }

  abrirModalCadastro(name, position) {
    let state = {
        [`${name}`]: true
    };

    if (position) {
        state = {
            ...state,
            position
        }
    }
    this.limparCampos();
    this.setState(state);
  }

  abrirModaAlterar(name, position) {
    let state = {
        [`${name}`]: true
    };

    if (position) {
        state = {
            ...state,
            position
        }
    }

    this.setState(state);
  }

  limparCampos = () => {
    this.setState({nome: ''});
  }

  accept() {
    this.excluirObjeto();
  }

  componentDidMount() {
    this.listarObjetosAcao();
  }

  listarObjetosAcao = async () => {
    this.setState({classeDiv: 'show'});
    const usuarioLogado = this.context.usuarioAutenticado
    await this.objetoAcaoService.listarObjetos(usuarioLogado.empresa.codigo)
      .then(response => {
        // console.log('Objetos',response.data)
        this.setState({objetos: response.data});
      }).catch(error => {
        this.setState(error.response.data);
        this.toast.show({severity:'error', summary: 'Objetos de ação', detail:`${this.state.detail}`, life: 4000});
      }).finally(()=>{
            this.setState({loading:false});
        });

      this.setState({classeDiv: 'hide'});
  }

  cadastrarObjeto = async () => {
    if(IsNullOrEmpty(this.state.nome)) {
      this.toast.show({severity:'error', summary: 'Objetos de ação', detail:'Informe o objeto', life: 4000});
      this.setState({classeDiv: 'hide'});
      return false;
    }
    this.setState({classeDiv: 'show'});
    const usuarioLogado = this.context.usuarioAutenticado
    await this.objetoAcaoService.cadastrarObjeto({
      nome: this.state.nome,
      empresa: usuarioLogado.empresa
    }).then(() => {
      this.toast.show({severity:'success', summary: 'Objetos de ação', detail:'Objeto de ação cadastrado com sucesso', life: 4000});
      this.listarObjetosAcao();
    }).catch(error => {
      this.setState(error.response.data);
      this.toast.show({severity:'error', summary: 'Objetos de ação', detail:`${this.state.detail}`, life: 4000});
    })

    this.setState({classeDiv: 'hide'});
    this.fecharModalCadastro('modalCadastro');
  }

  async editar(objeto) {
    if(objeto != null) {
      this.setState({classeDiv: 'show'});
      this.setState({codigo: objeto.codigo});
      this.objetoAcaoService.consultarObjeto(objeto.codigo)
        .then(response => {
          this.setState({codigo: response.data.codigo});
          this.setState({nome: response.data.nome});
          this.abrirModaAlterar('modalAlterar');
        }).catch(error => {
          this.setState(error.response.data);
          this.toast.show({severity:'error', summary: 'Objetos de ação', detail:`${this.state.detail}`, life: 4000});
        });

        this.setState({classeDiv: 'hide'});
    }
  }

  alterarObjeto = async () => {
    this.setState({classeDiv: 'show'});
    if(this.state.nome === '') {
      this.toast.show({severity:'error', summary: 'Objetos de ação', detail:'Informe o objeto', life: 4000});
      this.setState({classeDiv: 'hide'});
      return false;
    }

    const usuarioLogado = this.context.usuarioAutenticado
    await this.objetoAcaoService.alterarObjeto({
      codigo: this.state.codigo,
      nome: this.state.nome,
      empresa: usuarioLogado.empresa
    }).then(() => {
      this.toast.show({severity:'success', summary: 'Objetos de ação', detail:'Objeto de ação alterado com sucesso', life: 4000});
      this.listarObjetosAcao();
    }).catch(error => {
      this.setState(error.response.data);
      this.toast.show({severity:'error', summary: 'Objetos de ação', detail:`${this.state.detail}`, life: 4000});
    });

    this.setState({classeDiv: 'hide'});
    this.fecharModalCadastro('modalAlterar');
  }

  renderFooterCadastroObjetos() {
    return (
        <div className="row-etrium gap-1">
            <Button label="Cadastrar"
                    className={strings.buttonPrimaryStyle}
                    onClick={this.cadastrarObjeto}/>
        </div>
    );
  }

  renderFooterAlterarObjetos() {
    return (
        <div className="row-etrium gap-1">
            <Button label="Alterar"
                    className={strings.buttonPrimaryStyle}
                    onClick={this.alterarObjeto}/>
        </div>
    );
  }
    listarObjetos=(objeto)=>{
        this.props.history.push(`/subObjetos/${objeto.codigo}`);
    }
  acoes(rowData) {
    return(
      <div className="row gap-1">
        <Button icon="pi pi-pencil" className={strings.buttonIconSecundary}
                onClick={() => this.editar(rowData)}
                tooltip="Editar objeto de ação"
                tooltipOptions={{position: 'top'}} />
          <Button icon="pi pi-sitemap" className="p-button-rounded p-button-text"
                  onClick={() => this.listarObjetos(rowData)}
                  tooltip="Sub-objetos"
                  tooltipOptions={{position: 'top'}}/>
        <Button icon="pi pi-trash" className={strings.buttonIconDanger}
                onClick={() => this.confirmaExclusao(rowData)}
                tooltip="Excluir objeto de ação"
                tooltipOptions={{position: 'top'}}/>
      </div>
    );
  }

  confirmaExclusao(objeto) {
    this.setState({codigo: objeto.codigo});
    confirmDialog({
        message: 'Deseja realmente excluir o objeto de ação?',
        header: 'Exclusão de objeto de ação',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        accept: this.accept,
        reject: this.reject
    });
  }

  excluirObjeto = async () => {
    this.setState({classeDiv: 'show'});
    this.objetoAcaoService.excluirObjeto(this.state.codigo)
      .then(() => {
        this.toast.show({severity:'success', summary: 'Objetos de ação', detail:'Objeto de ação excluído com sucesso', life: 4000});
        this.listarObjetosAcao();
      }).catch(error => {
        this.setState(error.response.data);
        this.toast.show({severity:'error', summary: 'Objetos de ação', detail:`${this.state.detail}`, life: 4000});
      });

      this.setState({classeDiv: 'hide'});
  }

  render() {
      const {consultar, objetos} = this.state;
      const listaObjetosFiltrada = objetos.filter(obj=>obj.nome.toUpperCase().includes(consultar.toUpperCase()))
    return(
      <>
          <Loading open={this.state.loading} />
          <NavBar/>
        <Toast ref={(el) => this.toast = el} />
        <div className="content-wrapper">
          {/**Cadastro de Objeto de Ação */}
          <Dialog header="Cadastro de objeto de ação"
                    visible={this.state.modalCadastro}
                    style={{ width: '40vw' }}
                    footer={this.renderFooterCadastroObjetos('modalCadastro')}
                    onHide={() => this.fecharModalCadastro('modalCadastro')}>

                <div className="p-fluid">
                    <div className="p-field">
                        <label htmlFor="nomeStatus">Nome<span className="obrigatorioAsterisco"> *</span></label>
                        <InputText id="nomeStatus"
                                   type="text"
                                   maxLength={100}
                                   value={this.state.nome}
                                   onChange={e => this.setState({nome: e.target.value})}
                                   className="p-inputtext-sm p-d-block p-mb-1"/>
                    </div>
                </div>
          </Dialog>
          {/**Alterar Objeto de Ação */}
          <Dialog header="Alterar objeto de ação"
                    visible={this.state.modalAlterar}
                    style={{ width: '40vw' }}
                    footer={this.renderFooterAlterarObjetos('modalAlterar')}
                    onHide={() => this.fecharModalCadastro('modalAlterar')}>

                <div className="p-fluid">
                    <div className="p-field">
                        <label htmlFor="nomeStatusAlterar">Nome<span className="obrigatorioAsterisco"> *</span></label>
                        <InputText id="nomeStatusAlterar"
                                   type="text"
                                   maxLength={100}
                                   value={this.state.nome}
                                   onChange={e => this.setState({nome: e.target.value})}
                                   className="p-inputtext-sm p-d-block p-mb-1"/>
                    </div>
                </div>
          </Dialog>
          <ConfirmDialog visible={this.state.visible}
                         onHide={() => this.setState({ visible: false })}
                         message="Are you sure you want to proceed?"
                         header="Confirmation"
                         icon="pi pi-exclamation-triangle"
                         accept={() => this.excluirObjeto}
                         reject={() => this.setState({visible: false})}/>
          <section className="content-header">
            <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                  <div className="card card-primary card-outline">
                    <div className="card-header">
                      <h3 className="card-title">
                        <i className="far fa-object-ungroup"></i>
                        <span> Objetos de ação</span>
                      </h3>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-5">
                          <span className="input-group">
                            <ButtonSm type="button" className={strings.buttonPrimarySm}
                                    onClick={() => this.abrirModalCadastro('modalCadastro')}
                                    data-toggle="modal"
                                    data-target="#modal-default">
                            <i className="fas fa-plus"></i> Cadastrar</ButtonSm>
                               <form className="flex-1 space-etrium">
                                  <input type="text" className="form-control"
                                         placeholder="Consultar objetos"
                                         value={this.state.consultar}
                                         onChange={e => this.setState({consultar: e.target.value})}
                                  />

                                  <button className="input-group-text"
                                          type={'submit'}
                                  >
                                  <i className="fas fa-search"></i>
                                  </button>

                              </form>
                          </span>
                        </div>
                      </div>
                    <Progresso classeSecundaria={this.state.classeDiv}/>
                    </div>
                    <DataTable value={listaObjetosFiltrada}  paginator responsiveLayout="scroll"
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}" rows={10}>
                      <Column field="nome" header="Nome"></Column>
                      <Column key="codigo"></Column>
                      <Column body={this.acoes} exportable={false} style={{ minWidth: '8rem' }} header="Ações"></Column>
                    </DataTable>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

ObjetosAcao.contextType = AuthContext;
export default withRouter (ObjetosAcao)
