import React from 'react'

import {withRouter} from 'react-router-dom'
import {Toast} from 'primereact/toast';
import {MultiSelect} from 'primereact/multiselect'
import {Calendar} from 'primereact/calendar'
import AtividadeService from '../../app/service/AtividadeService'
import GrupoTrabalhoService from '../../app/service/GrupoTrabalhoService'
import UsuarioService from '../../app/service/UsuarioService'
import StatusAtividadeService from '../../app/service/StatusAtividadeService'
import NavBar from '../../componentes/Navbar'
import {AuthContext} from '../../main/ProvedorAutenticacao'
import './painel.css'
import {DateFormat} from "../../utils/DateFormat";
import {strings} from "../../utils/strings";
import {IsNullOrEmpty} from "../../utils/IsNullOrEmpy";
import Kanban from "../atividades/Kanban";
import {DialogAlterarAtividade} from "../../componentes/Atividade/DialogAlterarAtividade";
import {ButtonSm} from '../../componentes/ButtonSm';
import {CadastrarAtividadeDialog} from '../../componentes/Atividade/CadastrarAtividadeDialog';
import {Card} from 'primereact/card';
import {Loading} from "../../componentes/Loading";

class Home extends React.Component {
  events = [
    { status: 'Ordered', date: '15/10/2020 10:30', icon: 'pi pi-shopping-cart', color: '#9C27B0', image: 'game-controller.jpg' },
    { status: 'Processing', date: '15/10/2020 14:00', icon: 'pi pi-cog', color: '#673AB7' },
    { status: 'Shipped', date: '15/10/2020 16:15', icon: 'pi pi-shopping-cart', color: '#FF9800' },
    { status: 'Delivered', date: '16/10/2020 10:00', icon: 'pi pi-check', color: '#607D8B' }
  ];
  constructor(props) {
    super(props);
    this.atividadeService = new AtividadeService();
    this.grupoTrabalhoService = new GrupoTrabalhoService();
    this.usuarioService = new UsuarioService();
    this.statusAtividadeService = new StatusAtividadeService();


    this.state = {
      events: [],
      pontuacao: '',
      pesquisaAtividade: '',
      exibeOcorrenciasEncontradas: 'none',
      ocorrenciasEncontradas: '',
      //loader
      openSpinner: false,
      loading: true,

      //Alterar Atividade
      atividade: null,
      displayAtividade: false,
      novaAtividade: false,
      statusAtividade: [],
      atividadesCriadasUsuario: '',

      //Kannban
      filtroKanban: null,
      //Filtros Kanban
      usuariosKanban: [],
      selectUsuariosKanban: [],
      gruposKanban: [],
      selectGruposKanban: [],
      //Filtro de data padrão
      dataKanbanInicial: new Date(),
      dataKanbanFinal: new Date(),

    };
    this.state.dataKanbanInicial.setDate(1);
    this.state.dataKanbanFinal.setMonth(this.state.dataKanbanInicial.getMonth()+1,1)
    this.options = this.getLightTheme();
    this.lightOptions = {
      aspectRatio: .8,
      plugins: {
        legend: {
          labels: {}
        }
      }
    };

    this.horizontalOptions = {
      indexAxis: 'y',
      maintainAspectRatio: false,
      aspectRatio: .8,
      plugins: {
        legend: {
          labels: {
            color: '#495057'
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        },
        y: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        }
      }
    };
  }
  getLightTheme() {
    let basicOptions = {
      maintainAspectRatio: false,
      aspectRatio: .8,
      plugins: {
        legend: {
          labels: {
            color: '#495057'
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        },
        y: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        }
      }
    };

    let horizontalOptions = {
      indexAxis: 'y',
      maintainAspectRatio: false,
      aspectRatio: .8,
      plugins: {
        legend: {
          labels: {
            color: '#495057'
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        },
        y: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        }
      }
    };

    return {
      basicOptions,
      horizontalOptions
    }
  }



  listarStatusAtividade = () => {
    const usuarioLogado = this.context.usuarioAutenticado;
    this.statusAtividadeService.listarStatus(usuarioLogado.empresa.codigo)
      .then(response => {
        this.setState({ statusAtividade: response.data });
        // this.setState({ aguardandoInicioLabel: response.data[0].status });
        // // this.setState({ kanbanAtivoExibir: response.data[0].exibir });
        // this.setState({ naoPrioziadoLabel: response.data[1].status });
        //
        // this.setState({ emAtendimentoLabel: response.data[5].status });
        //
        // this.setState({ suspensoLabel: response.data[3].status });
        // // this.setState({ kanbanCienteExibir: response.data[3].exibir });
        // this.setState({ canceladoLabel: response.data[2].status });
        //
        // this.setState({ kanbanConcluidoLabel: response.data[4].status });
      }).catch(error => {
        this.setState(error.response.data);
        this.toast.show({ severity: 'error', summary: 'Status de atividade', detail: `${this.state.detail}`, life: 4000 });
      });
  }

  //Kanban
  usuariosKanban = async () => {
    const usuarioLogado = this.context.usuarioAutenticado;
    if (usuarioLogado.perfil.codigo === 1) {
      await this.usuarioService.listarUsuariosEmpresa(usuarioLogado.empresa.codigo)
        .then(response => {
          this.setState({ usuariosKanban: response.data });
          this.setState({ selectUsuariosKanban: response.data });
        }).catch(error => {
          this.setState(error.response.data);
          this.toast.show({ severity: 'error', summary: 'Lista de usuários por empresa', detail: `${this.state.detail}`, life: 4000 });
        })
    } else {
      await this.usuarioService.listarUsuariosEmpresaFiltrado(usuarioLogado.codigo)
        .then(response => {
          this.setState({ usuariosKanban: response.data });
          this.setState({ selectUsuariosKanban: response.data });
        }).catch(error => {
          this.setState(error.response.data);
          this.toast.show({ severity: 'error', summary: 'Lista de usuários por empresa', detail: `${this.state.detail}`, life: 4000 });
        })
    }
  }

  // events = async () => {
  //   await this.atividadeService.consultarEvents()
  //       .then(response =>{
  //         this.setState({events : response.data});
  //       })
  //       .catch(error =>{
  //         this.setState(error.response.data);
  //         this.toast.show({ severity: 'error', summary: 'Lista de usuários por empresa', detail: `${this.state.detail}`, life: 4000 });
  //       })
  // }

  customizedContent = (item) => {
    return (
        <Card title={item.status} subTitle={item.date}>
          <span>
            Nome do usuário
          </span>
        </Card>
    );
  };
  customizedMarker = (item) => {
    return (
        <span className="text-white" style={{ backgroundColor: item.color }}>
                <i className={item.icon}></i>
            </span>
    );
  };

  gruposKanban = async () => {
    const usuarioLogado = this.context.usuarioAutenticado;
    await this.grupoTrabalhoService.listarGruposUsuario(usuarioLogado.codigo)
      .then(response => {

        this.setState({ gruposKanban: response.data });
        this.setState({ selectGruposKanban: response.data });
      }).catch(error => {
        this.setState(error.response.data);
        this.toast.show({ severity: 'error', summary: 'Grupos de trabalho por usuário', detail: `${this.state.detail}`, life: 4000 });
      })
  }

  consultarKanban = async () => {
    if((this.state.dataKanbanFinal.getTime()-this.state.dataKanbanInicial.getTime())/1000/60/60/24>90){
      this.toast.show({
        severity: 'error',
        summary: 'Central de notificações',
        detail: `O período máximo do filtro é 90 dias`,
        life: 4000
      });
      return
    }
    const usuarioLogado = this.context.usuarioAutenticado;
    const { selectUsuariosKanban, } = this.state
    if (selectUsuariosKanban.length === 0) {
      return
    }
    let codigosUsuarios = '';
    if (this.state.selectUsuariosKanban != null) {
      let contador = 0;
      let tamanhoLista = this.state.selectUsuariosKanban.length;
      for (let i = 0; i < this.state.selectUsuariosKanban.length; i++) {
        if (contador < tamanhoLista - 1) {
          codigosUsuarios = codigosUsuarios + this.state.selectUsuariosKanban[i].codigo + ",";
          // codigosUsuarios = this.state.selectUsuariosKanban[i].codigo;
        } else {
          codigosUsuarios = codigosUsuarios + this.state.selectUsuariosKanban[i].codigo;
        }

        contador++;
      }
    }

    let codigosGrupos = '';
    if (this.state.selectGruposKanban != null) {
      let contador = 0;
      let tamanhoLista = this.state.selectGruposKanban.length;
      for (let i = 0; i < this.state.selectGruposKanban.length; i++) {
        if (contador < tamanhoLista - 1) {
          codigosGrupos = codigosGrupos + this.state.selectGruposKanban[i].codigo + ",";
        } else {
          codigosGrupos = codigosGrupos + this.state.selectGruposKanban[i].codigo;
        }

        contador++;
      }
    }

    //Datas de filtro
    let dataInicial = null;
    let dataFinal = null;
    if (this.state.dataKanbanInicial != null && this.state.dataKanbanFinal != null) {
      //Data inicial
      dataInicial = DateFormat(this.state.dataKanbanInicial);
      //Data final
      dataFinal = DateFormat(this.state.dataKanbanFinal);
    }
    if (!IsNullOrEmpty(codigosGrupos) && !IsNullOrEmpty(codigosUsuarios)) {
      const filtroKanban = {
        empresa: usuarioLogado.empresa.codigo,
        usuario: codigosUsuarios,
        grupo: codigosGrupos,
        dataInicial: dataInicial,
        dataFinal: dataFinal,
        status: 1,
        limite: 100
      };
      this.setState({ filtroKanban: filtroKanban})
    }
    this.setState({loading: false})
  }



  async componentDidMount() {
    this.setState({ openSpinner: true });
    this.setState({ pesquisaAtividade: '' });
    await this.listarStatusAtividade();
    await this.usuariosKanban();
    await this.gruposKanban();
    await this.consultarKanban();
    this.setState({ openSpinner: false });

  }



  async consultarAtividade(atividade) {
    this.setState({ openSpinner: true });
    await this.atividadeService.consultarAtividade(atividade.codigo)
      .then(response => {
        this.setState({ atividade: response.data });
      })

    this.setState({
      displayAtividade: true,
      openSpinner: false
    });
  }



  pad2 = (n) => {
    return (n < 10 ? '0' : '') + n;
  }



  refreshPage = () => {
    window.location.reload();
  }


  editarProcesso(atividade) {
    this.props.history.push(`/fichaProcesso/${atividade.processo.codigo}`);
  }


  consultaAtividadesCriadasUsuario = () => {
    const usuarioLogado = this.context.usuarioAutenticado;
    this.atividadeService.consultaAtividadesCriadasUsuario(usuarioLogado.codigo)
      .then(response => {
        this.setState({ atividadesCriadasUsuario: response.data });
      }).catch(error => {
        this.setState(error.response.data);
        this.toast.show({ severity: 'error', summary: 'Atividades criadas usuários', detail: `${this.state.detail}`, life: 4000 });
      })
  }



  // consultaPontuacao = () => {
  //   this.atividadeService.consultaPontuacao(this.state.quantidadeAtividadesHoje, this.state.quantidadeHojeInteressados, this.state.quantidadeHojeCompromissos)
  //     .then(response => {
  //       this.setState({ pontuacao: response.data });
  //     }).catch(error => {
  //       this.setState(error.response.data);
  //       // this.toast.show({ severity: 'error', summary: 'Esquema de pontuação', detail: `${this.state.detail}`, life: 4000 });
  //     })
  // }
  // onKanbanChange= ()=>{
  //   this.graficoAtividadeUsuario()
  //   this.atualizarPaineis()
  // }

  render() {
    return (
      <>
        <Loading open={this.state.loading}/>
        <NavBar />
        <Toast ref={(el) => this.toast = el} />
        {/*Alterar atividade*/}
        {this.state.displayAtividade &&
          <DialogAlterarAtividade
            isOpen={this.state.displayAtividade}
            onClose={() => this.setState({ displayAtividade: false })}
            data={this.state.atividade}
            toast={this.toast}
            refresh={this.consultarKanban}
            statusAtiv={this.state.statusAtividade}
          />}
        {this.state.novaAtividade &&
          <CadastrarAtividadeDialog
            isOpen={this.state.novaAtividade}
            onClose={() => this.setState({ novaAtividade: false })}
            toast={this.toast}
            refresh={this.consultarKanban}
          />}

        <div className="content-wrapper">
          <div className="container-fluid">
            <section className="content-header">
              <div className='card card-primary card-outline gap-1'>
                <div className='card-header'>
                  <h3 className="card-title row-etrium gap-1 align-center">
                    <i className="fa fa-solid fa-network-wired"></i>
                    <span>Meu quadro</span>
                    <ButtonSm id= 'Teste' type="button"
                      className={strings.buttonPrimaryStyle}
                      onClick={() => this.setState({ novaAtividade: true })}
                      aria-haspopup aria-controls="overlay_panel">
                      <i className="fas fa-plus"></i>Cadastrar atividade</ButtonSm>

                    {/* <span className="cursor" title="Atualizar Painéis" onClick={this.atualizarPaineis}>
                    <i className="pi pi-refresh"></i>
                  </span> */}
                  </h3>
                </div>
                <div className="row-etrium gap-1">
                  <MultiSelect id='FiltrarPorGT' inputId="idGrupos"
                    value={this.state.selectGruposKanban}
                    options={this.state.gruposKanban}
                    // style={{width: '100%', height: '32px'}}
                    className="multiselect-kanban-etrium"
                    onChange={(e) => this.setState({ selectGruposKanban: e.value })}
                    filter showClear filterBy="nome"
                    optionLabel="nome" />


                  <MultiSelect id='FiltrarPorUser' inputId="idGrupos"
                    value={this.state.selectUsuariosKanban}
                    options={this.state.usuariosKanban}
                    className="multiselect-kanban-etrium"
                    // style={{width: '100%', height: '32px'}}
                    onChange={(e) => this.setState({ selectUsuariosKanban: e.value })}
                    filter showClear filterBy="nome"
                    optionLabel="nome" />

                  <Calendar id="mask"
                    value={this.state.dataKanbanInicial}
                    onChange={(e) => this.setState({ dataKanbanInicial: e.value })}
                    locale="es"
                    style={{ display: "flex", flex: 1, height: '37px' }}

                    // style={{ width: '45%', height: '32px' }}
                    dateFormat="dd/mm/yy"
                    placeholder="Data inicial"
                    mask="99/99/9999" />

                  <Calendar id="mask"
                    value={this.state.dataKanbanFinal}
                    onChange={(e) => this.setState({ dataKanbanFinal: e.value })}
                    locale="es"
                    style={{ display: "flex", flex: 1, height: '37px' }}

                    // style={{ width: '45%', height: '32px' }}
                    dateFormat="dd/mm/yy"
                    placeholder="Data final"
                    mask="99/99/9999" />

                  <ButtonSm style={{ height: '37px' }} type="button"
                    className={strings.buttonSecondarySm}
                    onClick={this.consultarKanban}>
                    <i className="pi pi-filter"></i>Filtrar</ButtonSm>

                </div>
                {/* <div style={{ display: this.state.exibeOcorrenciasEncontradas === 'none' ? 'inline' : `${this.state.exibeOcorrenciasEncontradas}`, paddingTop: '10px', fontWeight: 'bold', fontSize: '12px' }}>
                  &nbsp;
                  &nbsp;
                  {this.state.ocorrenciasEncontradas}
                </div> */}
                <div className="row-etrium gap-1">
                  {/* aguardando inicio */}
                  <div className="kanbam">
                  <Kanban
                    toast={this.toast}
                    filtro={this.state.filtroKanban}
                    colunas={this.state.statusAtividade}
                    consultarAtividade={(atvd) => this.consultarAtividade(atvd)}
                    editarProcesso={(atvd) => this.editarProcesso(atvd)}
                  />
                  </div>
                    {/*<Timeline value={this.events} align="right" className='Timeline-Css p-timeline-event-opposite p-timeline-event-content' content={this.customizedContent} marker={this.customizedMarker}/>*/}


                </div>
              </div>

            </section>
          </div >
        </div >

      </>
    )
  }
}

Home.contextType = AuthContext;

export default withRouter(Home)
