import ApiService from '../apiservice'

export default class ObjetoAcaoProcessoService extends ApiService {

  constructor() {
    super('/objetoAcaoProcesso');
  }

  cadastrarObjetoAcaoProcesso(objetoProcesso) {
    return this.post(``,objetoProcesso);
  }

  consultarObjetos(codigo) {
    return this.get(`/${codigo}`);
  }

  excluirObjetoProcesso(codigo) {
    return this.delete(`/${codigo}`);
  }
  excluirPorCodigoProcessoAndObejto(processo, objeto) {
    return this.delete(`/delete?codigoProcesso=${processo}&codigoObjeto=${objeto}`);
  }
}
