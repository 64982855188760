export function gerarCorAleatoria() {
    // Gerar valores aleatórios para os componentes RGB
    var r = Math.floor(Math.random() * 256); // componente vermelho
    var g = Math.floor(Math.random() * 256); // componente verde
    var b = Math.floor(Math.random() * 256); // componente azul

    // Converter os componentes para formato hexadecimal
    var corHexadecimal = '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);

    return corHexadecimal;
}

function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
}
