import React from 'react'

import { withRouter } from 'react-router-dom'

import { Toast } from 'primereact/toast';
import { ButtonSm } from '../../componentes/ButtonSm'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { AutoComplete } from 'primereact/autocomplete';
import { InputTextarea } from 'primereact/inputtextarea'

import NavBar from '../../componentes/Navbar'

import AtendimentoService from '../../app/service/AtendimentoService'
import PessoaService from '../../app/service/PessoaService'
import GrupoTrabalhoService from '../../app/service/GrupoTrabalhoService'
import { AuthContext } from '../../main/ProvedorAutenticacao'
import {strings} from "../../utils/strings";

class RegistrarAtendimento extends React.Component {
  constructor(props) {
    super(props);

    this.atendimentoSevice = new AtendimentoService();
    this.pessoaService = new PessoaService();
    this.grupoTrabalhoService = new GrupoTrabalhoService();

    this.consultarPessoa = this.consultarPessoa.bind(this);
    this.onGrupoTrabalhoChange = this.onGrupoTrabalhoChange.bind(this);

    this.state = {
      codigoAtendimento: '',
      atendimento: null,
      dtAtendimento: '',
      dsAtendimento: '',
      pessoa: null,
      pessoas: [],
      grupo: null,
      grupos: [],
      processo: null,
      processos: [],
      assunto: '',
      detail: '',
      //Consultas
      listaPessoas: [],
      selectPessoa: null,
      filterPessoas: null,
      gruposConsulta: [],
      selectGrupo: null,
    }
  }

  listarPessoas = () => {
    const usuarioLogado = this.context.usuarioAutenticado
    this.pessoaService.listarPessoas(usuarioLogado.empresa.codigo)
      .then(response => {
        this.setState({listaPessoas: response.data});
      }).catch(error => {
        this.setState(error.response.data);
        this.toast.show({severity:'error', summary: 'Atendimentos', detail:`${this.state.detail}`, life: 4000});
      })
  }

  listarGruposTrabalho = () => {
    const usuarioLogado = this.context.usuarioAutenticado
    this.grupoTrabalhoService.listarGrupos(usuarioLogado.empresa.codigo)
      .then(response => {
        this.setState({gruposConsulta: response.data});
      }).catch(error => {
        this.toast.show({severity:'error', summary: 'Atendimentos', detail:'Erro ao carregar grupos de trabalhos do usuário', life: 4000});
      });
  }

  onGrupoTrabalhoChange(e) {
    this.setState({selectGrupo: e.value})
  }

  consultarPessoa(event) {
    setTimeout(() => {
        let filterPessoas;
        if (!event.query.trim().length) {
            filterPessoas = [...this.state.pessoas];
        }
        else {
          if(event.query.toLowerCase().length>2){
            filterPessoas = this.state.listaPessoas.filter((pessoa) => {
                return pessoa.nome.toLowerCase().includes(event.query.toLowerCase());
            });
        }}

        this.setState({ filterPessoas });
    }, 250);
  }

  cadastrarAtendimento = async () => {
    if(this.state.selectPessoa === null) {
      this.toast.show({severity:'error', summary: 'Atendimento', detail:'Informe a pessoa para o atendimento', life: 4000});
      return false;
    }

    if(this.state.selectGrupo === null || this.state.selectGrupo.codigo === null) {
      this.toast.show({severity:'error', summary: 'Atendimento', detail:'Informe o grupo de assunto', life: 4000});
      return false;
    }

    if(this.state.assunto === '') {
      this.toast.show({severity:'error', summary: 'Atendimento', detail:'Informe o assunto', life: 4000});
      return false;
    }

    if(this.state.dsAtendimento === '') {
      this.toast.show({severity:'error', summary: 'Atendimento', detail:'Descreva o atendimento', life: 4000});
      return false;
    }

    const usuarioLogado = this.context.usuarioAutenticado
    if(this.state.selectPessoa.length > 1) {
      for(let i = 0; i < this.state.selectPessoa.length; i++) {
        await this.atendimentoSevice.cadastrarAtendimento({
          pessoa: this.state.selectPessoa[i],
          grupo: this.state.selectGrupo,
          empresa: usuarioLogado.empresa,
          assunto: this.state.assunto
        }).then(response => {
          this.setState({atendimento: response.data})
          this.cadastrarHistorico(response.data);
        }).catch(error => {
          this.setState(error.response.data);
          this.toast.show({severity:'error', summary: 'Atendimentos', detail:`${this.state.detail}`, life: 4000});
        })
      }
    } else {
      await this.atendimentoSevice.cadastrarAtendimento({
        pessoa: this.state.selectPessoa[0],
        grupo: this.state.selectGrupo,
        empresa: usuarioLogado.empresa,
        assunto: this.state.assunto
      }).then(response => {
        this.setState({atendimento: response.data})
        this.cadastrarHistorico(response.data);
      }).catch(error => {
        this.setState(error.response.data);
        this.toast.show({severity:'error', summary: 'Atendimentos', detail:`${this.state.detail}`, life: 4000});
      })
    }
  }

  cadastrarHistorico = (atendimento) => {
    const usuarioLogado = this.context.usuarioAutenticado

    this.atendimentoSevice.cadastrarHistorico({
      atendimento: atendimento,
      usuario: usuarioLogado,
      historico: this.state.dsAtendimento
    }).then(response => {
      this.props.history.push('/atendimentos')
    }).catch(error => {
      this.setState(error.response.data);
      this.toast.show({severity:'error', summary: 'Atendimentos', detail:`${this.state.detail}`, life: 4000});
    })
  }

  cancelar = () => {
    this.props.history.push('/atendimentos')
  }

  componentDidMount() {
    this.listarPessoas();
    this.listarGruposTrabalho();
  }

  render() {
    return(
      <>
        <NavBar/>
        <Toast ref={(el) => this.toast = el} />
        <div className="content-wrapper">
          <div className="container-fluid">
              <section className="content-header">
                <div className="card card-primary card-outline">
                  <div className="card-header">
                    <h3 className="card-title">
                      <i className="pi pi-comments"></i>
                      <span> Novo atendimento</span>
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="row ">
                      <div className="col-sm-7">
                        <div className="form-group">
                          <label>Pessoas<span className="obrigatorioAsterisco"> *</span></label>
                          <AutoComplete value={this.state.selectPessoa}
                                        suggestions={this.state.filterPessoas}
                                        completeMethod={this.consultarPessoa}
                                        style={{width: '100%', height: '37px'}}
                                        field="nome" multiple
                                        placeholder="Digite aqui para pesquisar pessoas"
                                        onChange={(e) => this.setState({selectPessoa: e.value })}
                                        className="p-autocomplete"/>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-7">
                        <div className="form-group">
                          <label>Grupos de assunto<span className="obrigatorioAsterisco"> *</span></label>
                          <Dropdown value={this.state.selectGrupo}
                                              options={this.state.gruposConsulta} style={{width: '100%', height: '37px'}}
                                              optionLabel="nome"
                                              filter showClear filterBy="nome"
                                              onChange={this.onGrupoTrabalhoChange}
                                              id="grupoTrabalho"
                                              className="p-inputtext-sm p-d-block p-mb-1"/>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-7">
                        <div className="form-group">
                          <label>Assunto<span className="obrigatorioAsterisco"> *</span></label>
                          <InputText id="assunto"
                                      autoComplete="nope"
                                      type="text"
                                      style={{width: '100%'}}
                                      value={this.state.assunto}
                                      onChange={e => this.setState({assunto: e.target.value})}
                                      className="p-inputtext-sm p-d-block p-mb-1"/>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-7">
                        <div className="form-group">
                          <label>Registro do primeiro atendimento<span className="obrigatorioAsterisco"> *</span></label>
                          <InputTextarea id="registro"
                                         type="text"
                                         style={{width: '100%'}}
                                         value={this.state.dsAtendimento}
                                         onChange={e => this.setState({dsAtendimento: e.target.value})}
                                         className="p-inputtext-sm p-d-block p-mb-1"
                                         rows={6} cols={30}/>
                        </div>
                      </div>
                    </div>
                    <div className="direita">
                      <div className="row-etrium gap-1">
                            <ButtonSm
                                      className={strings.buttonPrimaryStyle}
                                      onClick={this.cadastrarAtendimento}>
                              <i className="fas fa-plus"/>
                              Cadastrar
                            </ButtonSm>
                          <ButtonSm
                                  className={strings.buttonSecondaryStyle}
                                  onClick={this.cancelar}>
                            Cancelar
                          </ButtonSm>

                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
      </>
    )
  }
}
RegistrarAtendimento.contextType = AuthContext;
export default withRouter(RegistrarAtendimento)
