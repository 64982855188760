import {InputText} from "primereact/inputtext";
import {InputMask} from "primereact/inputmask";
import React from "react";


export const HeaderPanelFichaProcesso = ({
                                             autor,
                                             reu,
                                             nrCnj,
                                             pasta,
                                             nrProcesso,
                                         }) => {
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="form-group">
                        <label className="flex-1 gap-1">Polo ativo:<span
                            className="partes">{autor}</span></label>
                    </div>
                    <div className="form-group">
                        <label className="flex-1 gap-1">Polo passivo:<span
                            className="partes">{reu}</span></label>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                        <label>Numeração única</label>
                        <InputMask id="numeroCnj"
                                   type="text"
                                   mask="9999999-99.9999.9.99.9999"
                                   style={{width: '100%', height: '37px'}}
                                   value={nrCnj}
                                   className="p-inputtext-sm p-d-block p-mb-1"
                                   disabled="true"/>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className="form-group">
                        <label>Pasta</label>
                        <InputText id="pasta"
                                   type="text"
                                   style={{width: '100%', height: '37px'}}
                                   value={pasta}
                                   className="p-inputtext-sm p-d-block p-mb-1"
                                   disabled="true"/>
                    </div>
                </div>

                <div className="col-sm-3">
                    <div className="form-group">
                        <label>Processo original</label>
                        <InputText id="numeroProcesso"
                                   type="text"
                                   style={{width: '100%', height: '37px'}}
                                   value={nrProcesso}
                                   className="p-inputtext-sm p-d-block p-mb-1"
                                   disabled="true"/>
                    </div>
                </div>
            </div>
        </>
    )
}
