import {Button} from "primereact/button";
import React from "react";

export const TooltipInfo = ({tooltip}) => {


    return(
        <Button icon="fas fa-info-circle"
                id="info-grafico-tooltip"
                className={'p-button-rounded p-button-text'}
                tooltip={tooltip}
                tooltipOptions={{
                    className: 'blue-tooltip',
                    position: 'top'
                }}
                style={{width: '29px', height: '26px'}}/>
    )
}