import React, {useEffect, useRef} from "react";
import {ParecerGraficoPrintComponent} from "../../componentes/ParecerGraficos/Print/ParecerGraficoPrintComponent";
import {useReactToPrint} from "react-to-print";
import {Button} from "primereact/button";
import {strings} from "../../utils/strings";


export const ImprimirRelatorioParecer = (props) => {
    const {
        graficoProdutividade,
        graficoEficacia,
        graficoEficiencia,
        filtro
    } = props.location.state


    const printRef = useRef(null)

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });

    return (
        <>{
            graficoProdutividade &&
            <div className="card-body">
                {/*<div className="card">*/}
                    <div className={"position-absolute top-10"}>
                        <Button onClick={handlePrint}
                                className={strings.buttonSecondarySm}><i
                            className={"pi pi-print mr-2"}/> Imprimir</Button>

                    </div>
                    <ParecerGraficoPrintComponent graficoProdutividade={graficoProdutividade}
                                                  graficoEficiencia={graficoEficiencia}
                                                  graficoEficacia={graficoEficacia}
                                                  filtro={filtro}
                                                  ref={printRef}/>
                {/*</div>*/}
            </div>
        }
        </>
    )
}
