import ApiService from '../apiservice'

export default class ParecerService extends ApiService {
  constructor() {
    super('/parecer')
  }

  cadastrarParece(parecer){
    return this.post("", parecer)
  }
  atribuirResponsavel(parecer){
    return this.put("/atribuir", parecer)
  }
  responderParecer(parecer){
    return this.put("/responder", parecer)
  }
  devolverParecer(parecer){
    return this.put("/devolver", parecer)
  }
  concluirParecer(codigoParecer, message){
    let url = `/concluir/${codigoParecer}`
    if(message){
      url += `?message=${message}`
    }
    return this.put(url)
  }
  finalizarParecer(codigoParecer){
    return this.put(`/finalizar/${codigoParecer}`)
  }

  consultaParecerLista(filtro, codigoUsuario) {
    const { dataInicio, dataFim, pessoa, dataRecebimentoInicio, dataRecebimentoFim, protocoloCitrus, status, tipoParecer } = filtro;
    const queryParams = new URLSearchParams({
      dataInicio: dataInicio || '',
      dataFim: dataFim || '',
      codigoPessoa: pessoa ? pessoa.codigo : '',
      dataRecebimentoInicio: dataRecebimentoInicio || '',
      dataRecebimentoFim: dataRecebimentoFim || '',
      protocoloCitrus: protocoloCitrus || '',
      status: status || 0,
      tipoParecer: tipoParecer || 0
    });

    return this.get(`/usuario/${codigoUsuario}?${queryParams}`);
  }

  consultarParecerPorCodigo(codigo){
    return this.get(`/${codigo}`);
  }
}
