export function formatarMoeda(valor) {
    // const formatter = new Intl.NumberFormat('pt-BR', {
    //     style: 'currency',
    //     currency: 'BRL'
    // });
    //
    // return formatter.format(valor);
        // Verifica se o valor é negativo
        const negativo = valor < 0;

        // Converte o valor absoluto para uma string com duas casas decimais
        const absValor = Math.abs(valor).toFixed(2);

        // Separa a parte inteira da parte decimal
        const [parteInteira, parteDecimal] = absValor.split('.');

        // Formata a parte inteira adicionando separadores de milhares
        let parteInteiraFormatada = parteInteira.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        // Adiciona o sinal de negativo, se necessário
        if (negativo) {
            parteInteiraFormatada = '-' + parteInteiraFormatada;
        }

        // Retorna a string formatada com o símbolo de R$ e duas casas decimais
        return `R$ ${parteInteiraFormatada},${parteDecimal}`;

}
