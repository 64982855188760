import {Dialog} from "primereact/dialog";
import React, {useContext, useState} from "react";
import {MultiSelect} from "primereact/multiselect";
import {Button} from "primereact/button";
import {strings} from "../../utils/strings";
import {AuthContext} from "../../main/ProvedorAutenticacao";
import ObjetoAcaoService from "../../app/service/ObjetoAcaoService";
import {Dropdown} from "primereact/dropdown";


export const ObjetosSelectDialog = ({open, close, toast, objetosOptions, refresh, addedObjetos}) => {
    const [objeto, setObjeto] = useState(null)
    const [subObjetos, setSubObjetos] = useState([])

    const {usuarioAutenticado, setLoading} = useContext(AuthContext)
    const objetoAcaoService = new ObjetoAcaoService();

    const [subObjetosOptions, setSubObjetoOptions] = useState([])

    function handleAddedSubObjetos() {
        if (objeto) {
            let newArray = [objeto]
            if (subObjetos.length > 0) {
                newArray = newArray.concat(subObjetos)
            }
            addedObjetos(newArray)
        }
        close()
    }

    function footer() {
        return (
            <div className="row-etrium gap-1">
                <Button label="Confirmar"
                        id="btn-confirm-obj-acao"
                        className={strings.buttonPrimaryStyle}
                        onClick={handleAddedSubObjetos}
                />
            </div>
        );
    }

    const onObjetoSelected = (e) => {
        const {value} = e.target
        setObjeto(value)

        objetoAcaoService.listarSubObjetosAcao(value.codigo).then((res) => {
            if (res.data && res.data.length > 0) {
                setSubObjetoOptions(res.data)
            }
        })

    }

    return (<>
        <Dialog header="Selecionar Objetos"
                visible={open}
                style={{width: '40vw'}}
                footer={footer}
                onHide={close}>

            <div className="row">
                <div className="width-100">
                    <div className="form-group">
                        <label>Objetos de ação</label>
                        <Dropdown inputId="multiselect"
                                  id="obj-acao-select"
                                  value={objeto}
                                  options={objetosOptions}
                                  style={{
                                      width: '100%',
                                      height: '37px',
                                      padding_top: '1px'
                                  }}
                                  onChange={onObjetoSelected}
                                  filter showClear filterBy="nome"
                                  optionLabel="nome"/>
                    </div>
                    <div className="form-group">
                        <label>Sub objetos de ação</label>
                        <MultiSelect inputId="multiselect"
                                     value={subObjetos}
                                     id="sub-obj-acao-select"
                                     options={subObjetosOptions}
                                     style={{
                                         width: '100%',
                                         height: '37px',
                                         padding_top: '1px'
                                     }}
                                     onChange={e => setSubObjetos(e.target.value)}
                                     filter showClear filterBy="nome"
                                     optionLabel="nome"/>
                    </div>
                </div>
            </div>
        </Dialog>
    </>)

}
