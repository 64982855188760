import React, {useContext, useEffect, useState} from 'react';
import {ButtonSm} from "../../ButtonSm";
import {strings} from "../../../utils/strings";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Dialog} from "primereact/dialog";
import {DateFormat} from "../../../utils/DateFormat";
import {AuthContext} from "../../../main/ProvedorAutenticacao";
import FinanceiroService from "../../../app/service/FinanceiroService";
import {Button} from "primereact/button";
import {Tag} from "primereact/tag";
import {confirmDialog} from "primereact/confirmdialog";
import {DropdownComponente} from "../../Inputs/DropdownComponente";
import TipoDespesaReceitaService from "../../../app/service/TipoDespesaReceita";
import {ConsultaLancamentoDetalhadoDialog} from "./ConsultaLancamentoDetalhadoDialog";
import {Loading} from "../../Loading";
import {formatarMoeda} from "../../../utils/formatar-moeda-tela";
import {IsNotNull, IsNullOrEmpty} from "../../../utils/IsNullOrEmpy";

export const DespesaOuReceitasDetalhadasDialog = ({dataInicial, dataFinal, open, onClose, toast, Titulo}) => {
    const [tipo, setTipo] = useState(null);
    const [tipos, setTipos] = useState([]);
    const [optionSituacao, setOptionSituacao] = useState(null);
    const [lancamentosPeriodo, setLancamentosPeriodo] = useState([]);
    const [lancamento, setLancamento] = useState(null);

    const [modalConsulta, setModalConsulta] = useState(false);
    const [loading, setLoading] = useState(false);

    const statusSituacao =
        [
            {codigo: 'P', nome: 'Pagos'},
            {codigo: 'A', nome: 'Abertos'},
            {codigo: 'T', nome: 'Todos'}
        ]


    const tipoBusca = Titulo === "Receitas" ? "R" : "D"


    const {usuarioAutenticado} = useContext(AuthContext);

    const financeiroService = new FinanceiroService()
    const tipoDespesaReceita = new TipoDespesaReceitaService()

    function consultarLancamento(lancamento) {
       setLancamento(lancamento)
       setModalConsulta(true)
    }


    // const fecharModal = () => {
    //     setModalConsulta(false);
    // };

    const onDespesaChange = (event) => {
        setTipo(event.target.value);
    };

    const onChangeOptionSituacao = (event) => {
        setOptionSituacao(event.target.value);
    };

    const filtrarTipoDespesaPeriodo = async () => {

        const usuarioLogado = usuarioAutenticado

        //Data Inicial
        let dataFormatadaInicial = '';
        if (dataInicial !== '') {
            dataFormatadaInicial = DateFormat(dataInicial);
        }

        //Data final
        let dataFormatadaFinal = '';
        if (dataFinal !== '') {
            dataFormatadaFinal = DateFormat(dataFinal);
        }

        let tipoSelecao

        if (IsNullOrEmpty(tipo)) {
            tipoSelecao = '0';
        } else {
            tipoSelecao = tipo.codigo;
        }
        let opSituacao
        if (IsNotNull(optionSituacao)) {
            opSituacao = optionSituacao
        }else{
            opSituacao = {
                codigo: 'T',
                    nome: 'Todos'
            }
        }
        setLoading(true)
        //lembrar de ajustar para receitas caso possivel
        financeiroService.lancamentosPorTipoDescricaoPeriodo(usuarioLogado.empresa.codigo, dataFormatadaInicial, dataFormatadaFinal,
            tipoSelecao, tipoBusca, opSituacao.codigo).then(response => {
            setLancamentosPeriodo(response.data);
        }).catch(error => {
            let detail = error.response.data.detail
            toast.show({severity: 'error', summary: `Financeiro - ${Titulo} - Filtro`, detail: `${detail}`, life: 4000});
        }).finally(()=>setLoading(false))
    }

    const consultarDespesasReceitasFiltro = async () => {
        const usuarioLogado = usuarioAutenticado

        //Data Inicial
        let dataFormatadaInicial = '';
        if (dataInicial !== '') {
            dataFormatadaInicial = DateFormat(dataInicial);
        }

        //Data final
        let dataFormatadaFinal = '';
        if (dataFinal !== '') {
            dataFormatadaFinal = DateFormat(dataFinal);
        }

        await financeiroService.lancamentosPorTipoPeriodo(usuarioLogado.empresa.codigo, dataFormatadaInicial, dataFormatadaFinal, tipoBusca)
            .then(response => {
                setLancamentosPeriodo(response.data);
            }).catch(error => {
                let detail = error.response.data.detail
                toast.show({
                    severity: 'error',
                    summary: `Financeiro - ${Titulo}`,
                    detail: `${detail}`,
                    life: 4000
                });
            });
    }
    //Cancelar Despesas ou Receitas
    const cancelarLancamento = (lancamento) => {
        lancamento.situacao= "C"
            financeiroService.pagarOuCancelarLancamento(lancamento).then(() => {
                toast.show({
                    severity: 'success',
                    summary: 'Cancelamento de lançamentos',
                    detail: 'Lançamento cancelado',
                    life: 4000
                });
                consultarDespesasReceitasFiltro();
            }).catch(error => {
                let detail = error.response.data.detail
                toast.show({
                    severity: 'error',
                    summary: 'Cancelamento de lançamentos',
                    detail: `${detail}`,
                    life: 4000
                });
            })

    }

    const confirmaCancelamento = (data) => {
        confirmDialog({
            message: 'Deseja realmente cancelar os lançamentos selecionados ?',
            header: 'Cancelar lançamentos',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: ()=> cancelarLancamento(data),
        });
    }

    const situacaoTags = {
        A: {className: "", severity: "info", value: "Ativo"},
        C: {className: "", severity: "danger", value: "Cancelado"},
        R: {className: "", severity: "success", value: "Recebido"},
        default: {className: "mr-2", severity: "success", value: "Pago"}
    };

    const situacaoDespesaReceita = (rowData) => {

        const situacaoTag = situacaoTags[rowData.situacao] || situacaoTags.default;
        return (
            <Tag className={situacaoTag.className} severity={situacaoTag.severity} value={situacaoTag.value}></Tag>
        );
    };
    const listarTiposDespesasReceitas = () => {
        tipoDespesaReceita.listarTiposDespesasReceitasPorTipo(usuarioAutenticado.empresa.codigo, tipoBusca)
            .then(response => {
                setTipos(response.data);
            }).catch(error => {
            let detail = error.response.data.detail
            toast.show({
                severity: 'error',
                summary: 'Financeiro - Tipos',
                detail: `${detail}`,
                life: 4000
            });
        })
    }

    const acoesDespesasReceitas = (rowData) => {
        if(rowData.situacao==="A"){
        return (
            <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text"
                    tooltip="Cancelar"
                    onClick={() => confirmaCancelamento(rowData)}
                    tooltipOptions={{position: 'top'}}/>
        );
        }
    };

    function templateVlLancamento(rowData){
        return Titulo.toString().toLowerCase() === "despesas"? `${formatarMoeda(-rowData.vlLancamento)}` : formatarMoeda(rowData.vlLancamento)
    }

    useEffect(() => {
        listarTiposDespesasReceitas()
        filtrarTipoDespesaPeriodo().finally()


    }, [])


    return (
        <Dialog
            header={`${Titulo} Detalhadas`}
            visible={open}
            // footer={renderDespesas}
            onHide={onClose}
        >
            <Loading open={loading}/>
            <div className='row-etrium gap-1'>
                <div className="flex-1 flex-column">
                        <label>Tipos de {Titulo}</label>
                        <DropdownComponente
                            value={tipo}
                            options={tipos}
                            optionLabel="nome"
                            filter
                            showClear
                            filterBy="nome"
                            emptyMessage=" "
                            onChange={onDespesaChange}
                            id="tipoDespesa"
                            className="p-inputtext-sm p-d-block p-mb-1"
                        />
                </div>

                <div className=" flex-1 flex-column">
                        <label>Situação</label>
                        <DropdownComponente
                            value={optionSituacao}
                            options={statusSituacao}
                            optionLabel="nome"
                            emptyMessage=" "
                            filter
                            showClear
                            filterBy="nome"
                            onChange={onChangeOptionSituacao}
                            id="idSituacao"
                            className="p-inputtext-sm p-d-block p-mb-1"
                        />
                </div>
                    <div className="mt-auto">
                        <ButtonSm
                            className={strings.buttonInfoSm}
                            style={{height: '32px'}}
                            onClick={filtrarTipoDespesaPeriodo}
                        >
                            <i className="pi pi-filter" style={{fontSize: '14px'}}></i>Filtrar
                        </ButtonSm>

                    </div>

            </div>

            <br></br>

            <DataTable
                rowClassName="cursor row-hover"
                value={lancamentosPeriodo}
                paginator
                responsiveLayout="scroll"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}"
                emptyMessage="Nenhum tipo de despesa ou receita cadastrados"
                onRowClick={(row)=>consultarLancamento(row.data)}
                rows={5}
            >
                <Column field="tipo.nome" header={Titulo}></Column>
                <Column field="dtVencimento" header="Vencimento"></Column>
                <Column body={templateVlLancamento} header="Valor"></Column>
                <Column body={(rowData)=>formatarMoeda(rowData.vlPago)} header="Vl pago"></Column>
                <Column
                    body={situacaoDespesaReceita}
                    exportable={false}
                    style={{minWidth: '8rem'}}
                    header="Situação"
                />
                <Column
                    body={acoesDespesasReceitas}
                    exportable={false}
                    style={{minWidth: '8rem'}}
                    header="Ações"
                ></Column>
            </DataTable>
            {modalConsulta && <ConsultaLancamentoDetalhadoDialog
                lancamento={lancamento}
                toast={toast}
                open={modalConsulta}
                refresh={consultarDespesasReceitasFiltro}
                onClose={() => setModalConsulta(false)}/>
            }
        </Dialog>
    );
};




