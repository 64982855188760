import React from "react";
import {Dialog} from "primereact/dialog";
import {EmpresaConsts} from "../../Consts/EmpresaConsts";
import {formatDateHoraTela} from "../../utils/DateFormat";


export const GraficoEmpresaDetailDialog = ({open, close, empresa}) => {
    function renderFooter() {
        return (
            <div className="row-etrium gap-1">
                {/*<Button label="Cadastrar"*/}
                {/*        className={strings.buttonPrimaryStyle}*/}
                {/*        onClick={cadastrarAreaAtuacao} />*/}
            </div>
        );
    }

    return (
        <>
        <Dialog header={empresa.nome || "Empresa"}
                visible={open}
                style={{width: '40vw'}}
                footer={renderFooter}
                onHide={close}>
            <div>
                <div className={"header"}>
                    <p><span className={"text-black"}>Status:</span> {EmpresaConsts.getEmpresaStatusString(empresa.status)}</p>
                    <p><span className={"text-black"}>Plano:</span> {empresa.plano}</p>
                    <p><span className={"text-black"}>Data de contratação:</span> {formatDateHoraTela(empresa.dataContratacao)}</p>
                    <p><span className={"text-black"}>Data de expiração:</span> {formatDateHoraTela(empresa.dataExpiracao)}</p>
                </div>
                <div className={"body"}>
                    <p><span className={"text-black"}>Usuários:</span> {empresa.usuarios}/{empresa.quantidadeUsuariosPlano}</p>
                    <p><span className={"text-black"}>Processos:</span> {empresa.processos}/{empresa.quantidadeProessosPushPlano}</p>
                    <p><span className={"text-black"}>Atividades:</span> {empresa.atividades}</p>

                </div>
            </div>

        </Dialog>
</>
)
}
