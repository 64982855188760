import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import UsuarioService from '../../app/service/UsuarioService';
import Progresso from '../../componentes/Progresso';
import '../css/util.css';
import './login.css';
import Logo from './img/etrium-logo1.svg';
import {strings} from "../../utils/strings";
import {Button} from "primereact/button";
import {IsPasswordEquals, IsValidPassword} from "../../utils/Validator";
import {Password} from "primereact/password";
function ResetPassword(props) {
    const [senha, setSenha] = useState('');
    const [repitaSenha, setRepitaSenha] = useState('');
    const [detail, setDetail] = useState('');
    const [classColor, setClassColor] = useState('')
    const [token, setToken] = useState('');
    const [classeDiv, setClasseDiv] = useState('hide');
    const [validPassword, setValidPassword] = useState(false);
    const [validRepeatPassword, setValidRepeatPassword] = useState(false);
    const [mostrarInstrucoes, setMostrarInstrucoes] = useState(false);

    const service = new UsuarioService();

    const alterarSenha = async (e) => {
        e.preventDefault();


        if (!IsValidPassword(senha) || !IsValidPassword(repitaSenha)) {
            // setDetail("Senha é Invalida")
            return
        }
        setClasseDiv('show');
        await service.redefinirSenha(token, senha)
            .then(async (response) => {
                setDetail(response.data)
                setClassColor("success")
                setTimeout(() => toLogin(), 4000)
            }).catch(erro => {
                setDetail(erro.response.data)
                setClassColor("erroAutenticacao")
            })
            .finally(() => {
                setClasseDiv('hide');
            })


    }

    const toLogin = () => {
        props.history.push('/login')
    }


    const handlePasswordInput = (e) => {
        setSenha(e.target.value);
        if (IsValidPassword(e.target.value)) {
            setValidPassword(true);
        } else {
            setValidPassword(false);
        }
    };

    const handleRepeatPasswordInput = (e) => {
        setRepitaSenha(e.target.value);
        if (IsPasswordEquals(e.target.value, senha)) {
            setValidRepeatPassword(true);
        } else {
            setValidRepeatPassword(false);
        }
    };

    const onPasswordFocusEnd = () => {
        if (!IsValidPassword(senha) && senha.length > 0) {
            setMostrarInstrucoes(true);
        } else {
            setMostrarInstrucoes(false);
        }
    }

    const onPasswordRepeatFocusEnd = () => {
        if (!IsPasswordEquals(senha, repitaSenha) && repitaSenha.length > 0) {
            //add instructions to create password
        }
    }

    useEffect(() => {
        let params = props.location.search
        if (params.split("=")[1] === undefined) {
            props.history.push("/login")
        }
        setToken(params.split("=")[1])
        // eslint-disable-next-line
    }, [])

    return (
        <div className="limiter">
            {/*<Toast ref={(el) => this.toast = el} />*/}
            <div className="card-body-cad">
                <div className="card-body-cad card-form-align-center">
                    <Progresso classeSecundaria={classeDiv}/>
                    <div className="logo-container">
                        <img className="logo" src={Logo} alt='Logo Etrium'></img>
                    </div>
                    <form className='w-full card-max-width' onSubmit={alterarSenha}>
                        <div className={'row'}>
                            <div className="group">
                                <label>Senha<span className="obrigatorioAsterisco"> *</span></label>
                                <Password
                                    inputStyle={{width: '100%', flex: 1}}
                                    autoComplete="nope"
                                    id="senha"
                                    name="senha"
                                    toggleMask
                                    feedback={false}

                                    style={{
                                        width: "100%",
                                        borderColor: validPassword ? 'green' : ''
                                    }}
                                    value={senha}
                                    onBlur={onPasswordFocusEnd} //quando elemento sai de foco
                                    onChange={handlePasswordInput}
                                    className="p-inputtext-sm p-d-block p-mb-1"/>
                                {validPassword &&
                                    <span className={'valid-password'}>Senha valida!</span>}
                                {senha.length > 0 && !validPassword &&
                                    <span className={'invalid-password'}>Senha invalida! Insira uma senha seguindo as intruções abaixo</span>}
                            </div>

                        </div>

                        <div className={'row'}>
                            <div className="group" style={{flex: 1}}>
                                <label>Repita a senha<span
                                    className="obrigatorioAsterisco"> *</span></label>
                                <Password
                                    inputStyle={{width: '100%', flex: 1}}
                                    autoComplete="nope"
                                    id="repitaSenha"
                                    name="repitaSenha"
                                    toggleMask
                                    feedback={false}
                                    style={{
                                        width: '100%',
                                        borderColor: validPassword ? 'green' : ''
                                    }}
                                    value={repitaSenha}
                                    onBlur={onPasswordRepeatFocusEnd} //quando elemento sai de foco
                                    onChange={handleRepeatPasswordInput}
                                    className="p-inputtext-sm p-d-block p-mb-1"/>
                                {repitaSenha.length > 0 && validRepeatPassword &&
                                    <span className={'valid-password'}>Senha iguais!</span>
                                }{repitaSenha.length > 0 && !validRepeatPassword &&
                                <span
                                    className={'invalid-password'}>As senhas são diferentes!</span>
                            }
                                <p className={classColor}>{detail}</p>


                            </div>
                        </div>

                        <div className="container-login100-form-btn gap-3 col">
                            <Button type={'submit'} className={strings.buttonPrimaryStyle}
                            >
                                Alterar
                            </Button>

                        </div>
                        {mostrarInstrucoes &&
                            <div className={'col-colum-password-msg'}>
                                <span className={'obrigatorioAsterisco'}>A senha deve conter pelo menos</span>
                                <span
                                    className={'obrigatorioAsterisco'}>uma letra maiúscula e uma minúscula (A-Z, a-z) </span>
                                <span className={'obrigatorioAsterisco'}>um número (0-9)</span>
                                <span className={'obrigatorioAsterisco'}>e  no minimo 8 (oito) caracteres</span>
                            </div>
                        }
                    </form>


                </div>
            </div>
        </div>
    )
}

export default withRouter(ResetPassword)
