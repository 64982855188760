import ApiService from '../apiservice'

export default class GruposPessoasSevice extends ApiService {

  constructor() {
    super('/gruposClientes')
  }

  listarGrupos(empresa) {
    return this.get(`?empresa=${empresa}`);
  }

  cadastrarGrupoPessoa(grupo) {
    return this.post('',grupo);
  }

  consultarGrupo(codigo) {
    return this.get(`/${codigo}`);
  }

  alterarGrupo(grupo) {
    return this.put(`/${grupo.codigo}`,grupo);
  }

  excluirGrupo(codigo) {
    return this.delete(`/${codigo}`);
  }
}