import ApiService from '../apiservice'

export default class PessoaService extends ApiService {

  constructor() {
    super('/pessoas')
  }

  cadastrarPessoa(pessoa) {
    return this.post('', pessoa);
  }

  cadastrarPessoaProcesso(pessoa) {
    return this.post('/processo', pessoa);
  }

  listarPessoas(empresa) {
    return this.get(`/porEmpresa/${empresa}`);
  }

  listarPessoasEmpresaPerfil(empresa, perfil) {
    return this.get(`/porEmpresaPerfil?empresa=${empresa}&perfil=${perfil}`);
  }

  listarPessoasForaDasPartesProcesso(filtro) {
    return this.get(`/foraPartes?empresa=${filtro.empresa}&processo=${filtro.processo}&perfil=${filtro.perfil}`);
  }

  consultarPessoas(filtro) {

    let params = `?empresa=${filtro.empresa}&usuario=${filtro.usuario}`;

    if (filtro.nome != null) {
      params = `${params}&nome=${filtro.nome}`;
    }

    if (filtro.cpfCnpj != null) {
      params = `${params}&cpfCnpj=${filtro.cpfCnpj}`;
    }

    if (filtro.cidade != null) {
      params = `${params}&cidade=${filtro.cidade}`;
    }
    if (filtro.estado != null) {
      params = `${params}&estado=${filtro.estado}`;
    }

    if (filtro.telefone != null) {
      params = `${params}&telefone=${filtro.telefone}`;
    }

    if (filtro.porLetra != null) {
      params = `${params}&letra=${filtro.letra}&porLetra=${filtro.porLetra}`;
    }

    if (filtro.grupo != null) {
      params = `${params}&grupo=${filtro.grupo}`;
    }

    return this.get(params);
  }

  consultarPessoa(codigo) {
    return this.get(`/${codigo}`)
  }

  listarPerfilPessoa() {
    return this.get('/perfisPessoa');
  }

  listarEstadoCivil() {
    return this.get('/estadoCivil');
  }

  listarTiposEndereco() {
    return this.get('/tiposEndereco');
  }

  excluirPessoa(codigo) {
    return this.delete(`/${codigo}`);
  }

  consultarProcessos(pessoa, usuario) {
    return this.get(`/processos?pessoa=${pessoa}&usuario=${usuario}`);
  }

  listarParceiros(perfil, empresa) {
    return this.get(`/captadores?perfil=${perfil}&empresa=${empresa}`);
  }

  alterarPessoa(pessoa) {
    return this.put(`/${pessoa.codigo}`, pessoa);
  }
}
