import ApiService from '../apiservice'

export default class GrupoPessoasUsuariosService extends ApiService {

  constructor() {
    super('/gruposClientesUsuarios')
  }

  listarUsuarios(grupo) {
    return this.get(`/porGrupo?grupo=${grupo}`);
  }

  incluirUsuario(grupoPessoa) {
    return this.post('',grupoPessoa);
  }

  excluirUsuario(codigo) {
    return this.delete(`/${codigo}`);
  }

  listarGrupos(usuario) {
    return this.get(`/porUsuario?usuario=${usuario}`);
  }

  listarGruposConsulta(usuario) {
    return this.get(`/porUsuarioConsulta/${usuario}`);
  }
}
