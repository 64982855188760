import React, {useContext, useEffect, useState} from "react";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {strings} from "../../utils/strings";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {IsNullOrEmpty} from "../../utils/IsNullOrEmpy";
import {AuthContext} from "../../main/ProvedorAutenticacao";
import PessoaService from "../../app/service/PessoaService";
import EstadoService from "../../app/service/EstadoService";
import CidadeService from "../../app/service/CidadeService";


export const CadastrarPessoaDialog = ({open, onClose, toast, refresh,}) => {

    const [perfis, setPerfis] = useState([])
    const [nome, setNome] = useState('');
    const [selectTipo, setSelectTipo] = useState(null);
    const [selectPerfil, setSelectPerfil] = useState(null);
    const [selectEstado, setSelectEstado] = useState(null);
    const [selectCidade, setSelectCidade] = useState(null);
    const [cidades, setCidades] = useState([]);
    const [estados, setEstados] = useState([]);
    const tiposPessoa = [
        {codigo: 'F', nome: 'Física'},
        {codigo: 'J', nome: 'Jurídica'}
    ]

    const {usuarioAutenticado} = useContext(AuthContext)
    //service

    const estadoService = new EstadoService();
    const cidadeService = new CidadeService();
    const pessoaService = new PessoaService()

    const onChangeCidade = (e) => {
        setSelectCidade(e.value);
    };

    const listarEstados = async () => {
        estadoService.listarEstados()
            .then(response => {
                setEstados(response.data);
            }).catch(() => {
            toast.show({severity: 'error', summary: 'Processos', detail: 'Erro ao listar estados', life: 4000});
        });
    }
    const listarPerfisPessoa = async () => {
        await pessoaService.listarPerfilPessoa()
            .then(response => {
                setPerfis(response.data);
            }).catch(error => {
                toast.show({
                    severity: 'error',
                    summary: 'Pessoas',
                    detail: 'Erro ao listar tipos de perfis de pessoa',
                    life: 4000
                });
            })
    }

    async function onChangeTipoPessoa(e) {
        setSelectTipo(e.value)
    }

    async function onChangePerfil(e) {
        setSelectPerfil(e.value)
    }

    const onChangeEstado = async (e) => {
        await setSelectEstado(e.value);
        await listarCidades(e.value);
    };

// Função para listar cidades
    const listarCidades = async (estado) => {
       await cidadeService.listarCidades(estado.codigo).then(response => {
            setCidades(response.data);
        }).catch(() => {
            toast.show({severity: 'error', summary: 'Processos', detail: 'Erro ao listar cidades', life: 4000});
        })
    };

    const cadastrar = () => {
        const usuarioLogado = usuarioAutenticado

        if (IsNullOrEmpty(nome)) {
            toast.show({
                severity: 'error',
                summary: 'Cadastro de nova pessoa',
                detail: 'Informe o nome da pessoa',
                life: 4000
            });
            return false;
        }

        if (selectTipo == null || selectTipo.codigo == null) {
            toast.show({
                severity: 'error',
                summary: 'Cadastro de nova pessoa',
                detail: 'Informe o tipo de pessoa',
                life: 4000
            });
            return false;
        }

        if (selectCidade == null || selectCidade.codigo == null) {
            toast.show({
                severity: 'error',
                summary: 'Cadastro de nova pessoa',
                detail: 'Informe a cidade',
                life: 4000
            });
            return false;
        }

        pessoaService.cadastrarPessoaProcesso({
            nome: nome,
            tipoPessoa: selectTipo.codigo,
            perfil: selectPerfil,
            cidade: selectCidade,
            empresa: usuarioLogado.empresa,
            usuario: usuarioLogado.codigo
        }).then(() => {
            toast.show({
                severity: 'success',
                summary: 'Cadastro de nova pessoa',
                detail: 'Nova Pessoa cadastrada com sucesso',
                life: 4000
            });
            refresh()
            onClose()
        }).catch(error => {
            let detail = error.response.data.detail;
            toast.show({
                severity: 'error',
                summary: 'Cadastro de nova pessoa',
                detail: `${detail}`,
                life: 4000
            });
        })
    }

    useEffect(() => {
        listarEstados()
        listarPerfisPessoa();
    }, [])


    return (<>

            {/**Cadastrar um novo cliente */}
            <Dialog header="Cadastrar nova pessoa"
                    visible={open}
                    style={{width: '40vw'}}
                    footer={
                        <div className="row gap-1">
                            <Button label="Cadastrar"
                                    className={strings.buttonPrimaryStyle}
                                    onClick={cadastrar}/>
                        </div>
                    }
                    onHide={() => onClose()}>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label htmlFor="descricaoArquivo">Nome<span
                                className="obrigatorioAsterisco"> *</span></label>
                            <InputText id="nome"
                                       autoComplete="nope"
                                       type="text"
                                       style={{width: '100%'}}
                                       value={nome}
                                       onChange={e => setNome(e.target.value)}
                                       className="p-inputtext-sm p-d-block p-mb-1"/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>Tipo de pessoa<span
                                className="obrigatorioAsterisco"> *</span></label>
                            <Dropdown value={selectTipo}
                                      options={tiposPessoa}
                                      style={{width: '100%', height: '37px'}}
                                      optionLabel="nome"
                                      onChange={onChangeTipoPessoa}
                                      id="tipo"
                                      className="p-inputtext-sm p-d-block p-mb-1"/>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>Perfil pessoa<span
                                className="obrigatorioAsterisco"> *</span></label>
                            <Dropdown value={selectPerfil}
                                      options={perfis}
                                      style={{width: '100%', height: '37px'}}
                                      optionLabel="nome"
                                      onChange={onChangePerfil}
                                      id="tipo"
                                      className="p-inputtext-sm p-d-block p-mb-1"/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">
                        <div className="form-group">
                            <label>Estado<span className="obrigatorioAsterisco"> *</span></label>
                            <Dropdown value={selectEstado}
                                      options={estados}
                                      style={{width: '100%', height: '37px'}}
                                      optionLabel="estado"
                                      onChange={onChangeEstado}
                                      id="estado"
                                      className="p-inputtext-sm p-d-block p-mb-1"/>
                        </div>
                    </div>
                    <div className="col-sm-8">
                        <div className="form-group">
                            <label>Cidade<span className="obrigatorioAsterisco"> *</span></label>
                            <Dropdown value={selectCidade}
                                      options={cidades}
                                      style={{width: '100%', height: '37px'}}
                                      optionLabel="nome"
                                      emptyMessage=" "
                                      onChange={onChangeCidade}
                                      id="cidade"
                                      className="p-inputtext-sm p-d-block p-mb-1"/>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    )
}
