import ApiService from '../apiservice'

export default class LiminarService extends ApiService {
  constructor() {
    super('/liminar')
  }
  getAllCumprimento() {
    return this.get("/list/cumprimento");
  }
  getAllStatus(){
    return this.get("/list/status")
  }
}