import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import React, {useState} from "react";
import {Button} from "primereact/button";
import {strings} from "../../utils/strings";
import ReCAPTCHA from "react-google-recaptcha";
import {IsNullOrEmpty} from "../../utils/IsNullOrEmpy";
import {Loading} from "../Loading";


export const RedefinirSenha=({open, onClose, toast, service})=>{

    const [email,setEmail]= useState('')
    const [ captcha,setCaptcha]= useState('')
    const [ loading,setLoading]= useState(false)



 const handleEsqueciSenha = () => {
        if (IsNullOrEmpty(email)) {
            toast.show({
                severity: 'error',
                summary: 'Esqueceu a senha',
                detail: "Digite o seu e-mail",
                life: 4000
            });
            return
        }
     if (IsNullOrEmpty(captcha)) {
         toast.show({
             severity: 'error',
             summary: 'Esqueceu a senha',
             detail: 'Verificação não foi resolvida',
             life: 4000
         });
         return false;
     }
        setLoading(true)
        service.esqueceuSenha(email, captcha).then(() => {
            toast.show({
                severity: 'success',
                summary: "Esqueceu a senha",
                detail: " Se o e-mail informado estiver em nossa base, um email com um link de alteração de senha será enviado.",
                life: 4000
            })
        }).catch(() => {
            toast.show({
                severity: 'success',
                summary: 'Esqueceu a senha',
                detail: " Se o e-mail informado estiver em nossa base, um email com um link de alteração de senha será enviado.",
                life: 4000
            });

        }).finally(() =>
            {
                setLoading(false)
                onClose()
            }

        )
    }

    return(
        <>
            <Loading open={loading}/>
        <Dialog header="Esqueceu a senha"
                style={{ width: '30vw' }}
                visible={open}
                onHide={() => onClose()}>

            <div className="flex-column gap-1 ">

                            <InputText type="email"
                                       value={email}
                                       autoComplete="none"
                                       onChange={e => setEmail(e.target.value)}
                                       className="form-control" placeholder="E-mail" />
                <ReCAPTCHA
                    sitekey= {process.env.REACT_APP_SITE_KEY}
                    name='captcha'
                    onChange={(value) => setCaptcha(value)} />
                <div className={'flex mr-full'} >
                    <Button onClick={handleEsqueciSenha} className={strings.buttonPrimaryStyle}
                    >
                        Solicitar
                    </Button>
                </div>


            </div>



    </Dialog>

</>
    )
}
