import React from 'react'

import {withRouter} from 'react-router-dom'

import {Chart} from 'primereact/chart';
import {DataView} from 'primereact/dataview';
import {Badge} from 'primereact/badge';
import {Calendar} from 'primereact/calendar'
import {Toast} from 'primereact/toast';

import NavBar from '../../componentes/Navbar'

import GraficoAtividadeService from '../../app/service/GraficoAtividadeService'
import FinanceiroService from '../../app/service/FinanceiroService'
import UsuarioService from '../../app/service/UsuarioService'
import {AuthContext} from '../../main/ProvedorAutenticacao'

import './grafico.css'
import '../processo/processo.css'
import {DateFormat} from "../../utils/DateFormat";
import {graficosLegendas, strings} from "../../utils/strings";
import {ButtonSm} from "../../componentes/ButtonSm";
import {Loading} from "../../componentes/Loading";
import ChartDialog from '../../componentes/ChartDialogAtvd';
import {Button} from "primereact/button";

class GraficoAtividades extends React.Component {
  constructor(props) {
    super(props);

    this.graficoAtividadeService = new GraficoAtividadeService();
    this.financeiroService = new FinanceiroService();
    this.usuarioService = new UsuarioService();

    this.templateUsuarios = this.templateUsuarios.bind(this);

    this.state = {
      meses: [],
      graficoAtividades: [],
      graficoAtividadesData: null,
      graficoAtividadesDataLimite: [],
      graficoAtividadesConcluido: [],
      graficoAtividadesDataLImiteConcluidoData: null,
      graficoStatus: [],
      graficoStatusData: null,
      graficoSituacaoEmpresa: [],
      graficoSituacaoEmpresaData: null,
      options: {
          plugins: {
              legend: {
                  position: 'bottom',
              },
          },
      },
      detail: '',
      openSpinner: false,
      //Grupos de Trabalho
      graficoAtividadeGrupo: [],
      graficoAtividadeGrupoPie: null,
      //Gráficos Usuarios
      graficoUsuarios: [],
      graficoUsuariosData: null,
      usuarios: [],
      layout: 'list',
      graficoUsuario: [],
      //Consulta
      dataInicialGeral: '',
      dataFinalGeral: '',
      dataInicialUsuario: '',
      dataFinalUsuario: '',
      paramGraficoUsuario: '',
      modalLoad: '',
    };

    this.chartsOptions = {
      maintainAspectRatio: false,
      aspectRatio: .8,
      plugins: {
          legend: {
              labels: {
                  color: '#495057'
              },
              position: 'bottom',
          }
      },
    }

    this.options = this.getLightTheme();
  }

  // listarMeses = async () => {
  //   await this.financeiroService.listarMeses()
  //     .then(response => {
  //       this.setState({meses: response.data});
  //     }).catch(error => {
  //       this.setState(error.response.data);
  //       this.toast.show({severity:'error', summary: 'Financeiro - Meses', detail:`${this.state.detail}`, life: 4000});
  //     })
  // }

  ativdadesStatus = async (dataInicial, dataFinal) => {
    const usuarioLogado = this.context.usuarioAutenticado
    await this.graficoAtividadeService.graficoAtividadesStatus(usuarioLogado.empresa.codigo, dataInicial, dataFinal)
      .then(async (response) => {
       await this.setState({graficoStatus: response.data});
      }).catch(error => {
        this.setState(error.response.data);
        this.toast.show({severity:'error', summary: 'Graficos atividades', detail:`${this.state.detail}`, life: 4000});
      });

      let status = [];
      for(let i = 0; i < this.state.graficoStatus.length; i++) {
        status.push(this.state.graficoStatus[i].status);
      }

      let totais = [];
      for(let i = 0; i < this.state.graficoStatus.length; i++) {
        totais.push(this.state.graficoStatus[i].total);
      }

      let labels = status.map((status, index) => `${status} - (${totais[index]})`);

      await this.setState({graficoStatusData: {
        labels: labels,
        datasets: [
          {
            data: totais,
            backgroundColor: [
              "#42A5F5",
              "#66BB6A",
              "#FFA726",
              "#BBCCCC",
              "#FF0000"
            ],
          }
        ]
      }});
  }

  // atividadesDataLimiteConcluidas = async () => {
  //   const usuarioLogado = this.context.usuarioAutenticado
  //
  //   await this.graficoAtividadeService.graficoDataLimite(usuarioLogado.empresa.codigo, '2022-01-01', '2022-12-31')
  //     .then(response => {
  //       this.setState({graficoAtividadesDataLimite: response.data});
  //     }).catch(error => {
  //       this.setState(error.response.data);
  //       this.toast.show({severity:'error', summary: 'Graficos Atividades', detail:`${this.state.detail}`, life: 4000});
  //     });
  //
  //   await this.graficoAtividadeService.graficoDataConcluido(usuarioLogado.empresa.codigo, '2022-01-01', '2022-12-31')
  //     .then(response => {
  //       this.setState({graficoAtividadesConcluido: response.data});
  //     }).catch(error => {
  //       this.setState(error.response.data);
  //       this.toast.show({severity:'error', summary: 'Graficos Atividades', detail:`${this.state.detail}`, life: 4000});
  //     });
  //
  //   // let nomeMeses = [];
  //   // for(let i = 0; i < this.state.meses.length; i++) {
  //   //   nomeMeses.push(this.state.meses[i].mes);
  //   // }
  //
  //   let dataLimite = [];
  //   for(let i = 0; i < this.state.graficoAtividadesDataLimite.length; i++) {
  //     dataLimite.push(this.state.graficoAtividadesDataLimite[i].total);
  //   }
  //
  //   let concluidos = [];
  //   for(let i = 0; i < this.state.graficoAtividadesConcluido.length; i++) {
  //     concluidos.push(this.state.graficoAtividadesConcluido[i].total);
  //   }
  //
  //   await this.setState({graficoAtividadesDataLImiteConcluidoData: {
  //     labels: nomesMeses,
  //     datasets: [
  //       {
  //
  //         label: 'Data Limite',
  //         backgroundColor: '#FF0000',
  //         data: dataLimite
  //
  //       },
  //       {
  //
  //         label: 'Concluídas',
  //         backgroundColor: '#008000',
  //         data: concluidos
  //
  //       }
  //     ]
  //   }})
  // }

  atividadesCriadas = async (dataInicial, dataFinal) => {
    const usuarioLogado = this.context.usuarioAutenticado
      var meses = [];

    await this.graficoAtividadeService.graficoTotal(usuarioLogado.empresa.codigo, dataInicial, dataFinal)
      .then(response => {
        this.setState({graficoAtividades: response.data});
          for(let i = 0; i < response.data.length; i++) {
              meses.push(response.data[i].mes);
          }
      }).catch(error => {
        this.setState(error.response.data);
        this.toast.show({severity:'error', summary: 'Graficos atividades', detail:`${this.state.detail}`, life: 4000});
      })

    let atividades = [];
    for(let i = 0; i < this.state.graficoAtividades.length; i++) {
      atividades.push(this.state.graficoAtividades[i].total);
    }

    await this.setState({graficoAtividadesData: {
      labels: meses,
      datasets: [
        {

          label: 'Atividades',
          backgroundColor: '#008000',
          data: atividades
        }
      ]
    }})
  }

  graficosAtividadesSituacao = async (dataInicial, dataFinal) => {
    const usuarioLogado = this.context.usuarioAutenticado;
    await this.graficoAtividadeService.graficoAtividadesSituacao(usuarioLogado.empresa.codigo, dataInicial, dataFinal)
      .then(response => {
        this.setState({graficoSituacaoEmpresa: response.data});
      }).catch(error => {
        this.setState(error.response.data);
        this.toast.show({severity:'error', summary: 'Graficos atividades', detail:`${this.state.detail}`, life: 4000});
      });

    // let valores = [];
    // for(let i = 0; i < this.state.graficoSituacaoEmpresa.length; i++) {
    //   valores.push(this.state.graficoSituacaoEmpresa[i].atrasados);
    //   valores.push(this.state.graficoSituacaoEmpresa[i].concluidosAtrasados);
    //   valores.push(this.state.graficoSituacaoEmpresa[i].concluidosPrazo);
    // }

    await this.setState({graficoSituacaoEmpresaData: {
      
      labels: [`Atrasados - (${this.state.graficoSituacaoEmpresa[0].atrasados ?? 0})`, `Concl. Atraso - (${this.state.graficoSituacaoEmpresa[0].concluidosAtrasados ?? 0})`, `Concl. Prazo - (${this.state.graficoSituacaoEmpresa[0].concluidosPrazo ?? 0})`],
      datasets: [
        {
          data: [this.state.graficoSituacaoEmpresa[0].atrasados,
                 this.state.graficoSituacaoEmpresa[0].concluidosAtrasados,
                 this.state.graficoSituacaoEmpresa[0].concluidosPrazo],
          backgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56"
          ],
          hoverBackgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56"
          ]
        }
      ]
    }})
  }

  graficoAtividadeGrupo = async (dataInicial, dataFinal) => {
    const usuarioLogado = this.context.usuarioAutenticado;
    await this.graficoAtividadeService.graficoAtividadeGrupo(usuarioLogado.empresa.codigo, dataInicial, dataFinal)
      .then(response => {
        this.setState({graficoAtividadeGrupo: response.data});
      }).catch(error => {
        this.setState(error.response.data);
        this.toast.show({severity:'error', summary: 'Graficos atividades', detail:`${this.state.detail}`, life: 4000});
      });

      //Grupos
      let grupos = [];
      for(let i = 0; i < this.state.graficoAtividadeGrupo.length; i++) {
        grupos.push(this.state.graficoAtividadeGrupo[i].grupo);
      }
      //Cores
      let cores = [];
      for(let i = 0; i < this.state.graficoAtividadeGrupo.length; i++) {
        cores.push(this.state.graficoAtividadeGrupo[i].cor);
      }

      //Total
      let valores = [];
      for(let i = 0; i < this.state.graficoAtividadeGrupo.length; i++) {
        valores.push(this.state.graficoAtividadeGrupo[i].total);
      }

      let labels = grupos.map((grupos, index) => `${grupos} - (${valores[index] ?? 0})`);

      await this.setState({graficoAtividadeGrupoPie: {
        labels: labels,
        datasets: [
          {
            data: valores,
            backgroundColor: cores,
          }
        ]
      }});
  }

  graficoUsuarios = async (dataInicial, dataFinal) => {
    const usuarioLogado = this.context.usuarioAutenticado

    await this.graficoAtividadeService.graficoUsuarios(usuarioLogado.empresa.codigo, dataInicial, dataFinal)
      .then(response => {
        this.setState({graficoUsuarios: response.data})
      })
  }

  // graficoUsuario = async (usuario) => {
  //   await this.graficoAtividadeService.graficoAtividadesUsuario(usuario, '2022-01-01', '2022-12-31')
  //     .then(response => {
  //       this.setState({graficoUsuario: response.data});
  //     }).catch(error => {
  //       this.setState(error.response.data);
  //       this.toast.show({severity:'error', summary: 'Graficos Atividades', detail:`${this.state.detail}`, life: 4000});
  //     })
  // }

  consultarGraficoPorusuario(usuario){
    //Data Inicial
    var dataFormatadaInicial = '';
    if(this.state.dataInicialGeral !== '') {
      dataFormatadaInicial =  DateFormat(this.state.dataInicialGeral);
    }

    //Data final
    var dataFormatadaFinal = '';
    if(this.state.dataFinalGeral !== '') {
      dataFormatadaFinal =  DateFormat(this.state.dataFinalGeral);
    }
    this.setState({paramGraficoUsuario: `#/graficosAtividadesUsuario/${usuario.codigo}/${dataFormatadaInicial}/${dataFormatadaFinal}`});
  }

  templateUsuarios(data) {
    let chartData = {
      labels: [`Atrasados - (${data.atrasados ?? 0})`, `Concl. Atraso - (${data.concluidosAtrasados ?? 0})`, `Concl. Prazo - (${data.concluidosPrazo ?? 0})`],
      datasets: [
          {
              data: [data.atrasados, data.concluidosAtrasados, data.concluidosPrazo],
              backgroundColor: [
                  "#FF6384",
                  "#36A2EB",
                  "#FFCE56"
              ],
              hoverBackgroundColor: [
                  "#FF6384",
                  "#36A2EB",
                  "#FFCE56"
              ]
          }]
    };
    let chartData2 = {

      labels: [
          `Não priorizado - (${data.naoPriorizado ?? 0})`,
          `Aguardando início - (${data.aguardandoInicio ?? 0})`,
          `Suspenso - (${data.suspenso ?? 0})`,
          `Em atendimento - (${data.emAtendimento ?? 0})`,
          `Concluídas - (${data.concluidas ?? 0})`,
          `Canceladas - (${data.canceladas ?? 0})`,
      ],
      datasets: [
        {
          data: [
              data.naoPriorizado,
              data.aguardandoInicio,
              data.suspenso,
              data.emAtendimento,
              data.concluidas,
              data.canceladas,
          ],
          backgroundColor: [
              "#b8860b",
              "#b4b4aae3",
            "#00ffff",
              "#1229ab",
              "#4e8814",
              "#ff0000",
          ]
        }
      ]
    }
    const quantidade = data.aguardandoInicio + data.naoPriorizado + data.suspenso + data.canceladas + data.concluidas + data.emAtendimento
    //var des = qyantidade == 0 ?
    let n = data.nome;
    n = n.replace(/\s(de|da|dos|das)\s/g, ' '); // Remove os de,da, dos,das.
    var nome = n.split(' ')[0].toUpperCase(); // Primeiro nome.
    //alert(nome + sobrenomes);
      if(quantidade === 0 ){
          return null
      }
    return (
      <div className="card">
        <div className="row">
          <div className="col-sm-2">
            <div className="formGroup" style={{paddingLeft: '90px'}}>
                <span style={{fontSize: '16px', color: '#ccsfcc', fontWeight: 'bold'}}>
                  <br></br>
                  <br></br>
                  <br></br>
                    <a id='NameUser' href={this.state.paramGraficoUsuario}
                       target="_blank"
                       onClick={() => this.consultarGraficoPorusuario(data)}
                       style={{pointerEvents: `${quantidade === 0 ? 'none' : 'hide'}`}}>
                      <Badge value={nome} size='normal'></Badge>
                    </a>
                </span>
            </div>
          </div>

          <div className="col-sm-4">
            <div className="formGroup">
              <Chart type="doughnut" data={chartData} options={this.chartsOptions} width="150" style={{display: `${quantidade === 0 ? 'none' : 'hide'}`}} />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="formGroup">
              <div>
              </div>
              <Chart type="doughnut" data={chartData2} options={this.chartsOptions} width="150" style={{display: `${quantidade === 0 ? 'none' : 'hide'}`}} />
            </div>
          </div>

          <div className="col-sm-2" style={{display: `${quantidade !== 0 ? 'none' : 'hide'}`}}>
            <div className="formGroup">
              <br></br>
              <span style={{fontSize: '100px', color: '#36A2EB', fontFamily: 'Times New Roman Bold Italic', paddingRight: '3px'}}>
                <h2>Sem atividades</h2>
              </span>
            </div>
          </div>

          <div className="col-sm-2" style={{display: `${quantidade === 0 ? 'none' : 'hide'}`}}>
            <div className="formGroup">
              <br></br>
              <span style={{fontSize: '100px', color: '#36A2EB', fontFamily: 'Times New Roman Bold Italic', paddingRight: '3px'}}>
                {quantidade}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  listarUsuarios = () => {
    const usuarioLogado = this.context.usuarioAutenticado

    this.usuarioService.listarUsuariosEmpresa(usuarioLogado.empresa.codigo)
      .then(response => {
        this.setState({usuarios: response.data});
      }).catch(error => {
        this.setState(error.response.data);
        this.toast.show({severity:'error', summary: 'Graficos atividades', detail:`${this.state.detail}`, life: 4000});
      })
  }

  consultarGraficos = async () => {
    this.setState({openSpinner: true});
    // await this.listarMeses();



    //Data Inicial
    var dataFormatadaInicial = '';
    if(this.state.dataInicialGeral !== '') {
      dataFormatadaInicial =  DateFormat(this.state.dataInicialGeral)
    }

    //Data final
    var dataFormatadaFinal = '';
    if(this.state.dataFinalGeral !== '') {
      dataFormatadaFinal =  DateFormat(this.state.dataFinalGeral)
    }

      await this.atividadesCriadas(dataFormatadaInicial, dataFormatadaFinal);
      await this.ativdadesStatus(dataFormatadaInicial, dataFormatadaFinal);

    await this.graficoAtividadeGrupo(dataFormatadaInicial, dataFormatadaFinal);
    await this.graficoUsuarios(dataFormatadaInicial, dataFormatadaFinal);
    await this.graficosAtividadesSituacao(dataFormatadaInicial, dataFormatadaFinal);
    this.setState({openSpinner: false});
  }

  async componentDidMount() {
    this.setState({openSpinner: true});
    // await this.listarMeses();


    var data = new Date();
    String(data.getDate()).padStart(2, '0');
    String(data.getMonth() + 1).padStart(2, '0');
    var ano = data.getFullYear();
    var dataInicial = `${ano}-01-01`;
    var dataFinal = `${ano}-12-31`;

    var dataInicial2 = new Date(dataInicial);
    dataInicial2.setDate(dataInicial2.getDate()+1)

    var dataFinal2 = new Date(dataFinal);
    dataFinal2.setDate(dataFinal2.getDate()+1)

    this.setState({dataInicialGeral: dataInicial2});
    this.setState({dataFinalGeral: dataFinal2});
    this.setState({dataInicialUsuario: dataInicial2});
    this.setState({dataFinalUsuario: dataFinal2});

    await this.atividadesCriadas(dataInicial, dataFinal);
    await this.ativdadesStatus(dataInicial, dataFinal);
    await this.graficoAtividadeGrupo(dataInicial, dataFinal);
    await this.graficoUsuarios(dataInicial, dataFinal);
    await this.graficosAtividadesSituacao(dataInicial, dataFinal);
    this.setState({openSpinner: false});
  }

  getLightTheme() {
    let basicOptions = {
        maintainAspectRatio: false,
        aspectRatio: .8,
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                },
                position: 'bottom',
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            }
        }
    };

    let horizontalOptions = {
        indexAxis: 'y',
        maintainAspectRatio: false,
        aspectRatio: .8,
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            }
        }
    };

    let stackedOptions = {
        maintainAspectRatio: false,
        aspectRatio: .8,
        plugins: {
            tooltips: {
                mode: 'index',
                intersect: false
            },
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            x: {
                stacked: true,
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                stacked: true,
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            }
        }
    };

    let multiAxisOptions = {
        maintainAspectRatio: false,
        aspectRatio: .8,
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            },
            tooltips: {
                mode: 'index',
                intersect: true
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                ticks: {
                    min: 0,
                    max: 100,
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y1: {
                type: 'linear',
                display: true,
                position: 'right',
                grid: {
                    drawOnChartArea: false,
                    color: '#ebedef'
                },
                ticks: {
                    min: 0,
                    max: 100,
                    color: '#495057'
                }
            }
        }
    };

    return {
        basicOptions,
        horizontalOptions,
        stackedOptions,
        multiAxisOptions
    }
  }

  render() {
    const { basicOptions } = this.options;
    return(
      <>
        <NavBar/>
        <Toast ref={(el) => this.toast = el} />
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                  <div className="card card-primary card-outline">
                    <div className="card-header">
                      <h3 className="card-title">
                        <i className="nav-icon fa fa-solid fa-network-wired"></i>
                          <span> Painel de atividades</span>
                      </h3>
                    </div>
                    <div className="card-body">

                      <div className="card">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="form-group">
                                <div id='FiltroTempo' className={'formGroupP'}>
                                  <span style={{textAlign: 'center'}} className="flex-1 gap-1">
                                    <Calendar id="mask"
                                              value={this.state.dataInicialGeral}
                                              onChange={(e) => this.setState({ dataInicialGeral: e.value })}
                                              locale="es"
                                              style={{height: '37px', width: '145px'}}
                                              dateFormat="dd/mm/yy"
                                              mask="99/99/9999"/>
                                    <Calendar id="mask"
                                              value={this.state.dataFinalGeral}
                                              onChange={(e) => this.setState({ dataFinalGeral: e.value })}
                                              locale="es"
                                              style={{height: '37px', width: '145px'}}
                                              dateFormat="dd/mm/yy"
                                              mask="99/99/9999"/>
                                    <ButtonSm style={{height: '37px'}} type="button" className={strings.buttonSecondarySm}
                                            onClick={this.consultarGraficos}>
                                        <i className="pi pi-filter"></i>Filtrar</ButtonSm>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <Loading
                          open={this.state.openSpinner}>
                        </Loading>

                      <div className="row">
                        <div className="col-sm-12">
                          <div className="formGroup">
                            <div className="card flex justify-content-center">
                                <h5 className={"flex flex-row gap-0"}>Atividades criadas
                                                        <Button icon="fas fa-info-circle"
                                                               id="info-grafico-eficacia"
                                                               className={'p-button-rounded p-button-text'}
                                                               tooltip={graficosLegendas.atividadesCriadas}
                                                               tooltipOptions={{
                                                                   className: 'blue-tooltip',
                                                                   position: 'top'
                                                               }}
                                                               style={{width: '29px', height: '26px'}}/></h5>
                                <Chart type="bar" data={this.state.graficoAtividadesData} options={basicOptions} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="formGroup">
                          <ChartDialog title="Situação" chartData={{ type: "doughnut", data: this.state.graficoSituacaoEmpresaData }} chartOptions={this.state.options} />
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="formGroup">
                          <ChartDialog title="Por status" chartData={{ type: "pie", data: this.state.graficoStatusData }} chartOptions={this.state.options} />

                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="formGroup">
                          <ChartDialog title="Por grupo de trabalho" chartData={{ type: "pie", data: this.state.graficoAtividadeGrupoPie }} chartOptions={this.state.options} />

                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="formGroup">
                              <div style={{padding: '5px'}}>
                                <h5>
                                  Usuários
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="formGroup">
                            <DataView id='ResultsUsers' value={this.state.graficoUsuarios}
                                layout={this.state.layout}
                                emptyMessage=" "
                                itemTemplate={this.templateUsuarios}/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    )
  }
}

GraficoAtividades.contextType = AuthContext;
export default withRouter (GraficoAtividades)
