import React, {useContext, useRef, useState} from "react";
import {formatarMoeda} from "../../utils/formatar-moeda-tela";
import "../styles/navbar.css"
import {strings} from "../../utils/strings";
import {Button} from "primereact/button";
import {EntreContatoDialog} from "../Plano/EntreContatoDialog";
import {Toast} from "primereact/toast";
import {AuthContext} from "../../main/ProvedorAutenticacao";

export const CardPlano = ({
                              id,
                              planoMensal,
                              planoAnual,
                              selected,
                              onAssinar,
                              onEncerrar,
                              recurrentStatus,
                              planoAtual,
                              onAvaliacao7Dias
                          }) => {


    const [modalContato, setModalContato] = useState(false); // [state, set]
    const toast = useRef(null);

    const {selectPlano} = useContext(AuthContext);
    const handleAssinarMensal = () => {
        onAssinar(planoMensal, "Monthly");
    };

    const handleAssinarAnual = () => {
        onAssinar(planoAnual, "Annual");
    };

    const handleEncerrarAssinatura = () => {
        onEncerrar();
    };
    const handleAvaliacao7Dias = () => {
        onAvaliacao7Dias(planoMensal);
    };


    const handleEntreEmContato = () => {
        setModalContato(true)
    };

    if (planoMensal === "Empresarial") {
        return (
            <div className="card-deck my-2">
                <Toast ref={toast}/>
                <div className="card m-l-0">
                    <div className="flex-row card-header justify-content-center text-bold text-lg">
                        Empresarial
                    </div>
                    <div className="flex flex-col card-body flex-column gap-5 pb-0">
                        <div id={'head'} className={"flex-column"} style={{height: "50px"}}>
                            <h5 className="card-title justify-content-center align-items-center flex-row">A
                                combinar</h5>
                        </div>
                        <div id={"body"} className="flex-column gap-5 mb-5">
                            <span className="card-text text-black"><i className="pi pi-user text-blue"></i> vários usuários</span>
                            <span className="card-text text-black"><i className="fas fa-balance-scale text-blue"></i> vários processos</span>
                            <span className="card-text text-black"><i className="pi pi-check text-blue"></i> Acesso ao aplicativo</span>
                            <span className="card-text text-black"><i className="pi pi-check text-blue"></i> Quadro de atividades</span>
                            <span className="card-text text-black"><i className="pi pi-check text-blue"></i> Gestão processual</span>
                            <span className="card-text text-black"><i className="pi pi-check text-blue"></i> Robôs de pesquisa de processos</span>
                            <span className="card-text text-black"><i className="pi pi-check text-blue"></i> Controle financeiro</span>
                            <span className="card-text text-black"><i className="pi pi-check text-blue"></i> Geração de relatórios</span>
                            <span className="card-text text-black"><i className="pi pi-check text-blue"></i> Funcionalidades de parecer</span>
                            <span className="card-text text-black"><i className="pi pi-check text-blue"></i> Logo Customizada</span>
                        </div>
                        <div className="flex flex-col justify-content-start">
                            {selected
                                ? <span className="flex mb-3 container align-items-center justify-content-center">
                    <Button label="Assinado" type={'button'}
                            className={strings.buttonSecondaryStyle + " align-items-center justify-content-center w-full"}/>
                  </span>
                                : <div className="flex mb-3 container align-items-center justify-content-center">
                                    <Button label="Entre em contato" type={'button'}
                                            className={strings.buttonSecondaryStyle + " align-items-center justify-content-center w-full"}
                                            onClick={handleEntreEmContato}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {modalContato && <EntreContatoDialog open={modalContato} onclose={() => setModalContato(false)}
                                                     toast={toast.current}/>}
            </div>
        );
    } else if (planoMensal) {
        return (
            <div className="card-deck my-2" id={id}>
                <div className="card m-l-0">
                    <div className="flex-row card-header justify-content-center text-bold text-lg">
                        {planoMensal.nome}
                    </div>
                    <div className="card-body flex-column gap-5 pb-0">
                        <div id={'head'} className="flex-column" style={{height: "50px"}}>
                            {planoMensal.codigo === 1
                                ? <h5 className="card-title justify-content-center align-items-center flex-row">Gratuita
                                    por 7 dias</h5>
                                : <>
                                    <h5 className="card-title justify-content-center align-items-center flex-row">{formatarMoeda(planoMensal.valor)} /
                                        mês</h5>
                                    {planoAnual &&
                                        <h5 className="card-title justify-content-center align-items-center flex-row">{formatarMoeda(planoAnual.valor)} /
                                            ano</h5>}
                                </>
                            }
                        </div>
                        <div id={"body"} className="flex-column gap-5 mb-5">
                    <span className="card-text text-black"><i
                        className="pi pi-user text-blue"></i> {planoMensal.quantidadeUsuarios} usuário(s)</span>
                            <span className="card-text text-black"><i
                                className="fas fa-balance-scale text-blue"></i> {planoMensal.quantidadeProcessos} processos</span>
                            <span className="card-text text-black"><i className="pi pi-check text-blue"></i> Acesso ao aplicativo</span>
                            <span className="card-text text-black"><i className="pi pi-check text-blue"></i> Quadro de atividades</span>
                            <span className="card-text text-black"><i
                                className="pi pi-check text-blue"></i> Gestão processual</span>
                            <span className="card-text text-black"><i className="pi pi-check text-blue"></i> Robôs de pesquisa de processos</span>
                            <span
                                className="card-text text-black">{planoMensal.codigo >= 4 || planoMensal.codigo === 1 ?
                                <i className="pi pi-check text-blue"></i> :
                                <i className="pi pi-times text-red"></i>} Controle financeiro</span>
                            <span
                                className="card-text text-black">{planoMensal.codigo >= 4 || planoMensal.codigo === 1 ?
                                <i className="pi pi-check text-blue"></i> :
                                <i className="pi pi-times text-red"></i>} Geração de relatórios</span>
                            <span
                                className="card-text text-black">{planoMensal.codigo >= 6 || planoMensal.codigo === 1 ?
                                <i className="pi pi-check text-blue"></i> :
                                <i className="pi pi-times text-red"></i>} Funcionalidades de parecer</span>
                            <span className="card-text text-black"><i
                                className="pi pi-times text-red"></i> Logo Customizada</span>
                        </div>
                        {selected && !recurrentStatus
                            ? <div className="flex flex-col justify-content-start">
                                {planoAtual.includes('Mensal')
                                    ? <>
                          <span className="flex mb-3 container align-items-center justify-content-center">
                            <Button
                                label="Encerrar Plano"
                                type={'button'}
                                className={`${strings.buttonDanger} align-items-center justify-content-center w-full`}
                                onClick={handleEncerrarAssinatura}
                            />
                          </span>
                                        <span className="flex mb-3 container align-items-center justify-content-center">
                            <Button
                                label="Assinar anual"
                                type={'button'}
                                className={`${strings.buttonPrimaryStyle} align-items-center justify-content-center w-full`}
                                onClick={handleAssinarAnual}
                            />
                          </span>
                                    </>
                                    : <>
                          <span className="flex mb-3 container align-items-center justify-content-center">
                            <Button
                                label="Assinar mensal"
                                type={'button'}
                                className={`${strings.buttonPrimaryStyle} align-items-center justify-content-center w-full`}
                                onClick={handleAssinarMensal}
                            />
                          </span>
                                        <span className="flex mb-3 container align-items-center justify-content-center">
                            <Button
                                label="Encerrar Plano"
                                type={'button'}
                                className={`${strings.buttonDanger} align-items-center justify-content-center w-full`}
                                onClick={handleEncerrarAssinatura}
                            />
                          </span>
                                    </>
                                }
                            </div>
                            : selected && recurrentStatus
                                ? <div className="flex flex-col justify-content-start">
                                    <div className="flex mb-3 container align-items-center justify-content-center">
                                        <Button
                                            label={planoAtual.includes('Mensal') ? "Renovar mensal" : "Assinar mensal"}
                                            type={'button'}
                                            className={`${planoAtual.includes('Mensal') ? strings.buttonWarningStyle : strings.buttonPrimaryStyle} align-items-center justify-content-center w-full`}
                                            onClick={handleAssinarMensal}
                                        />
                                    </div>
                                    {planoAnual &&
                                        <div className="flex mb-3 container align-items-center justify-content-center">
                                            <Button
                                                label={planoAtual.includes('Anual') ? "Renovar anual" : "Assinar anual"}
                                                type={'button'}
                                                className={`${planoAtual.includes('Anual') ? strings.buttonWarningStyle : strings.buttonPrimaryStyle} align-items-center justify-content-center w-full`}
                                                onClick={handleAssinarAnual}
                                            />
                                        </div>}
                                </div>
                                : <div className="flex flex-col">
                                    <div className="flex mb-3 container align-items-center justify-content-center">
                                        <Button label="Assinar mensal" type={'button'}
                                                className={strings.buttonPrimaryStyle + " align-items-center justify-content-center w-full"}
                                                onClick={handleAssinarMensal}/>
                                    </div>
                                    {planoAnual &&
                                        <div className="flex mb-3 container align-items-center justify-content-center">
                                            <Button label="Assinar anual" type={'button'}
                                                    className={strings.buttonPrimaryStyle + " align-items-center justify-content-center w-full"}
                                                    onClick={handleAssinarAnual}/>
                                        </div>}
                                    {selectPlano &&
                                        <div className="flex mb-3 container align-items-center justify-content-center">
                                            <Button
                                                label="Avaliação de 7 dias"
                                                type={'button'}
                                                className={`${strings.buttonPrimaryStyle} align-items-center justify-content-center w-full`}
                                                onClick={handleAvaliacao7Dias}
                                            />
                                        </div>
                                    }
                                </div>
                        }
                    </div>
                </div>
            </div>
        );
    } else {
        return <></>;
    }
};
