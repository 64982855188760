import React from 'react'

import {withRouter} from 'react-router-dom'

import {Chart} from 'primereact/chart';
import {Toast} from 'primereact/toast';
import {Calendar} from 'primereact/calendar'
import {addLocale} from 'primereact/api'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


import NavBar from '../../componentes/Navbar'
import Progresso from '../../componentes/Progresso'

import FinanceiroService from '../../app/service/FinanceiroService'
import ArquivoFinanceiroService from '../../app/service/ArquivoFinanceiroService'
import {AuthContext} from '../../main/ProvedorAutenticacao'

import './financeiro.css'
import {DateFormat} from "../../utils/DateFormat";
import {graficosLegendas, strings} from "../../utils/strings";
import {ButtonSm} from "../../componentes/ButtonSm";
import {
    DespesaOuReceitasDetalhadasDialog
} from "../../componentes/Financeiro/PainelFinanceiro/DespesaOuReceitasDetalhadasDialog";
import {CardFincanceiro} from "../../componentes/Financeiro/PainelFinanceiro/CardFincanceiro";
import {LancamentosFuturosDialog} from "../../componentes/Financeiro/PainelFinanceiro/LancamentosFuturosDialog";
import {AtrasadosDialog} from "../../componentes/Financeiro/PainelFinanceiro/AtrasadosDialog";
import {CadastrarLancamentoDialog} from "../../componentes/Financeiro/PainelFinanceiro/CadastrarLancamentoDialog";
import {formatarMoeda} from "../../utils/formatar-moeda-tela";
import {TooltipInfo} from "../../componentes/TooltipInfo";

class PainelFinanceiro extends React.Component {
    constructor(props) {
        super(props);

        this.financeiroService = new FinanceiroService();
        this.arquivoFinanceiroService = new ArquivoFinanceiroService();

        // this.onDespesaChange = this.onDespesaChange.bind(this);
        // // this.situacaoDespesa = this.situacaoDespesa.bind(this);
        // this.onChangeCategoria = this.onChangeCategoria.bind(this);
        // this.acoesProcesso = this.acoesProcesso.bind(this);
        // this.renderFooterProcesso = this.renderFooterProcesso.bind(this);
        // this.renderFooterCadastro = this.renderFooterCadastro.bind(this);
        // this.onChangeOptionSituacao = this.onChangeOptionSituacao.bind(this);
        // this.onOptionRecoorencia = this.onOptionRecoorencia.bind(this);

        // this.acoesArquivosTemp = this.acoesArquivosTemp.bind(this);
        // this.acoesArquivos = this.acoesArquivos.bind(this);
        // this.acceptExcluirArquivo = this.acceptExcluirArquivo.bind(this);
        // this.renderFooterDescricaoArquivo = this.renderFooterDescricaoArquivo.bind(this);
        // this.renderFooterDescricaoArquivoTemp = this.renderFooterDescricaoArquivoTemp.bind(this);
        // this.onChangeCategoriaConsulta = this.onChangeCategoriaConsulta.bind(this);
        // this.onDespesaChangeConsulta = this.onDespesaChangeConsulta.bind(this);


        this.state = {
            classeDiv: 'hide',
            detail: '',
            meses: [],
            despesas: [],
            receitas: [],
            pendentes: [],
            modalPendentes: false,
            selectPendentes: null,
            pendenteConsultaTipo: null,
            pendenteConsultaTipoCodigo: null,
            basicaData2: [],
            despesasPeriodo: [],
            receitasPeriodo: [],
            graficoDespesa: [],
            graficoReceita: [],
            pieDespesas: [],
            pieReceitas: [],
            dataInicial: '',
            dataFinal: '',
            despesaTotal: '',
            receitaTotal: '',
            saldo: '',
            modalDespesas: null,
            modalReceitas: null,
            modalCadastro: null,
            modalConsulta: false,
            // modalDespesaPagamento: null,
            // modalReceitaPagamento: null,
            // selectDespesas: null,
            // selectReceitas: null,
            // vencendoAmanha: '',
            futurosTotal: '',
            modalLancamentosFuturos: false,
            // vencendoHojeLista: [],
            // selectVencendoHoje: null,
            // vencendoHojeConsultaTipo: '',
            // vencendoHojeConsultaTipoCodigo: '',
            totalAtrasados: '',
            modalAtrasados: false,
            // codigoLancamento: '',
            //Novo Lançamento
            // lancamento: null,
            // tipo: null,
            // tipoNome: '',
            // dtVencimento: '',
            // dtVencimentoConsulta: '',
            // vlLancamento: '',
            // vlLancamentoNovo: '',
            // dtVencimentoNovo: '',
            // dsLancamento: '',
            // observacao: '',
            // processo: null,
            // processos: [],
            // dsProcesso: '',
            // modalProcesso: null,
            // tipos: [],
            // selectCategoria: null,
            // categoriaNome: '',
            // optionSituacao: null,
            // optionRecorrencia: null,
            // parcelas: '',
            // vlPago: '',
            // dtPago: '',
            // situacao: '',
            // visible: false,
            // //Upload de arquivos
            // upLoadFiles: [],
            // arquivosTemp: [],
            // arquivoTemp: null,
            // arquivo: null,
            // arquivos: [],
            // arquivoCodigo: '',
            // descricao: '',
            // modalDescricaoArquivo: false,
            // modalDescricaoArquivoTemp: false,
            openSpinner: false,
            options: {
                plugins: {
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        };

        // this.statusSituacao = [
        //     {codigo: 'P', nome: 'Pagos'},
        //     {codigo: 'A', nome: 'Abertos'},
        //     {codigo: 'T', nome: 'Todos'}
        // ];
        //
        // this.categoria = [
        //     {codigo: 'D', nome: 'Despesa'},
        //     {codigo: 'R', nome: 'Receita'}
        // ];
        //
        // this.recorrencia = [
        //     {codigo: 'S', nome: 'Sem recorrência'},
        //     {codigo: 'R', nome: 'Repetição'},
        //     {codigo: 'P', nome: 'Parcelada'}
        // ]

        addLocale('es', {
            firstDayOfWeek: 1,
            dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
            dayNamesShort: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
            dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
            monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Stembro', 'Outubro', 'Novembro', 'Dezemro'],
            monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            today: 'Hoy',
            clear: 'Claro'
        });

        this.chartData = {
            labels: ['A', 'B', 'C'],
            datasets: [
                {
                    data: [300, 50, 100],
                    backgroundColor: [
                        "#42A5F5",
                        "#66BB6A",
                        "#FFA726"
                    ],
                    hoverBackgroundColor: [
                        "#64B5F6",
                        "#81C784",
                        "#FFB74D"
                    ]
                }
            ]
        };

        this.options = this.getLightTheme();
    }




    fecharModalCadastro(name) {
        this.setState({
            [`${name}`]: false
        });
    }

    calcularLancamentosFuturos = async () => {
        const usuarioLogado = this.context.usuarioAutenticado;
        let dataFormatadaFinal = '';
        if (this.state.dataFinal !== '') {
            dataFormatadaFinal = DateFormat(this.state.dataFinal);
        }
        await this.financeiroService.somaLancamentosFuturos(usuarioLogado.empresa.codigo, dataFormatadaFinal)
            .then(response => {
                this.setState({futurosTotal: response.data});
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({
                    severity: 'error',
                    summary: 'Financeiro - Vencendo hoje',
                    detail: `${this.state.detail}`,
                    life: 4000
                });
            });

        const valor = this.state.futurosTotal.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'});
        this.setState({futurosTotal: valor});
    }

    somarAtrasados = async () => {
        const {dataInicial} = this.state;
        const usuarioLogado = this.context.usuarioAutenticado;
        let dataFormatadaInicial = "";
        if (dataInicial !== '') {
            dataFormatadaInicial = DateFormat(dataInicial);
        }
        await this.financeiroService.somarAtrasados(usuarioLogado.empresa.codigo, dataFormatadaInicial)
            .then(response => {
                this.setState({totalAtrasados: response.data});
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({
                    severity: 'error',
                    summary: 'Financeiro - Atrasados',
                    detail: `${this.state.detail}`,
                    life: 4000
                });
            });

        const valor = this.state.totalAtrasados.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'});
        this.setState({totalAtrasados: valor});
    }

    consultarGraficosDespesas = async () => {
        const usuarioLogado = this.context.usuarioAutenticado

        //Data Inicial
        var dataFormatadaInicial = '';
        if (this.state.dataInicial !== '') {
            dataFormatadaInicial = DateFormat(this.state.dataInicial);
        }

        //Data final
        var dataFormatadaFinal = '';
        if (this.state.dataFinal !== '') {
            dataFormatadaFinal = DateFormat(this.state.dataFinal);
        }

        await this.financeiroService.lancamentoGrafico(usuarioLogado.empresa.codigo, dataFormatadaInicial, dataFormatadaFinal, 'D')
            .then(response => {
                this.setState({graficoDespesa: response.data})
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({
                    severity: 'error',
                    summary: 'Financeiro - Gráficos',
                    detail: `${this.state.detail}`,
                    life: 4000
                });
            });

        //Tipos
        let tipos = [];
        for (let i = 0; i < this.state.graficoDespesa.length; i++) {
            tipos.push(this.state.graficoDespesa[i].tipo);
        }
        //Cores
        let cores = [];
        for (let i = 0; i < this.state.graficoDespesa.length; i++) {
            cores.push(this.state.graficoDespesa[i].cor);
        }

        //Valor
        let valores = [];
        for (let i = 0; i < this.state.graficoDespesa.length; i++) {
            valores.push(this.state.graficoDespesa[i].valor);
        }

        this.setState({
            pieDespesas: {
                labels: tipos,
                datasets: [
                    {
                        data: valores,
                        backgroundColor: cores,
                    }
                ]
            }
        })
    }

    consultarGraficosReceitas = async () => {
        const usuarioLogado = this.context.usuarioAutenticado;

        //Data Inicial
        var dataFormatadaInicial = '';
        if (this.state.dataInicial !== '') {
            dataFormatadaInicial = DateFormat(this.state.dataInicial);
        }

        //Data final
        var dataFormatadaFinal = '';
        if (this.state.dataFinal !== '') {
            dataFormatadaFinal = DateFormat(this.state.dataFinal);
        }

        await this.financeiroService.lancamentoGrafico(usuarioLogado.empresa.codigo, dataFormatadaInicial, dataFormatadaFinal, 'R')
            .then(response => {
                this.setState({graficoReceitas: response.data})
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({
                    severity: 'error',
                    summary: 'Financeiro - Gráficos',
                    detail: `${this.state.detail}`,
                    life: 4000
                });
            });

        //Tipos
        let tipos = [];
        for (let i = 0; i < this.state.graficoReceitas.length; i++) {
            tipos.push(this.state.graficoReceitas[i].tipo);
        }
        //Cores
        let cores = [];
        for (let i = 0; i < this.state.graficoReceitas.length; i++) {
            cores.push(this.state.graficoReceitas[i].cor);
        }

        //Valor
        let valores = [];
        for (let i = 0; i < this.state.graficoReceitas.length; i++) {
            valores.push(this.state.graficoReceitas[i].valor);
        }

        this.setState({
            pieReceitas: {
                labels: tipos,
                datasets: [
                    {
                        data: valores,
                        backgroundColor: cores,
                    }
                ]
            }
        })
    }

    consultaUnica = async () => {
        this.setState({openSpinner: true});
        await this.consultarGraficosDespesas(this.state.dataInicial, this.state.dataFinal);
        await this.consultarGraficosReceitas(this.state.dataInicial, this.state.dataFinal);
        // await this.vencendoAmanha();
        await this.calcularLancamentosFuturos();
        await this.somarAtrasados();

        var totalDespesas = 0;
        var totalReceitas = 0;

        for (let i = 0; i < this.state.graficoDespesa.length; i++) {
            totalDespesas = totalDespesas + this.state.graficoDespesa[i].valor;
        }

        for (let i = 0; i < this.state.graficoReceitas.length; i++) {
            totalReceitas = totalReceitas + this.state.graficoReceitas[i].valor;
        }
        var totralSaldo = totalReceitas - totalDespesas;

        this.setState({despesaTotal: formatarMoeda(-totalDespesas)});
        this.setState({receitaTotal: formatarMoeda(totalReceitas)});
        this.setState({saldo: formatarMoeda(totralSaldo)});
        const usuarioLogado = this.context.usuarioAutenticado;
        await this.consultarDespesasxReceitas(usuarioLogado.empresa.codigo);
        this.setState({openSpinner: false});
    }

    pad2 = (n) => {
        return (n < 10 ? '0' : '') + n;
    }

    consultarDespesasxReceitas = async (empresa) => {
        await this.consultarDepesas(empresa);
        await this.consultarReceitas(empresa);

        let nomesMeses = [
            'Janeiro',
            'Fevereiro',
            'Março',
            'Abril',
            'Maio',
            'Junho',
            'Julho',
            'Agosto',
            'Setembro',
            'Outubro',
            'Novembro',
            'Dezembro'
        ];

        // for (let i = 0; i < this.state.meses.length; i++) {
        //     nomesMeses.push(this.state.meses[i].mes);
        // }
        let despesas = [];
        for (let i = 0; i < nomesMeses.length; i++) {
            let mesEncontrado = this.state.despesas.find(item => item.mes === nomesMeses[i]);
            if (mesEncontrado) {
                despesas.push(mesEncontrado.total);
            } else {
                despesas.push(0);
            }
        }

        let receitas = [];
        for (let j = 0; j < nomesMeses.length; j++) {
            let mesEncontrado = this.state.receitas.find(item => item.mes === nomesMeses[j]);
            if (mesEncontrado) {
                receitas.push(mesEncontrado.total);
            } else {
                receitas.push(0);
            }
        }
        // let despesas = [];
        // for (let i = 0; i < this.state.despesas.length; i++) {
        //     despesas.push(this.state.despesas[i].total);
        // }
        //
        // let receitas = [];
        // for (let j = 0; j < this.state.receitas.length; j++) {
        //     receitas.push(this.state.receitas[j].total);
        // }
        await this.setState({
            basicData2: {
                labels: nomesMeses,
                datasets: [
                    {
                        label: 'Despesa',
                        backgroundColor: '#FF0000',
                        data: despesas
                    },
                    {

                        label: 'Receita',
                        backgroundColor: '#008000',
                        data: receitas
                    }
                ]
            }
        })
    }

    consultarDepesas = async (empresa) => {
        const{ dataInicial, dataFinal }=this.state;
        //Data Inicial
        let dataFormatadaInicial = '';
        if (dataInicial !== '') {
            dataFormatadaInicial = DateFormat(dataInicial);
        }

        //Data final
        let dataFormatadaFinal = '';
        if (dataFinal !== '') {
            dataFormatadaFinal = DateFormat(dataFinal);
        }

        await this.financeiroService.depesasPorMes(empresa, dataFormatadaInicial, dataFormatadaFinal)
            .then(response => {

                this.setState({despesas: response.data});
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({
                    severity: 'error',
                    summary: 'Financeiro - Despesas',
                    detail: `${this.state.detail}`,
                    life: 4000
                });
            })
    }

    consultarReceitas = async (empresa) => {
        const{ dataInicial, dataFinal }=this.state;
        //Data Inicial
        let dataFormatadaInicial = '';
        if (dataInicial !== '') {
            dataFormatadaInicial = DateFormat(dataInicial);
        }

        //Data final
        let dataFormatadaFinal = '';
        if (dataFinal !== '') {
            dataFormatadaFinal = DateFormat(dataFinal);
        }
        await this.financeiroService.receitasPorMes(empresa, dataFormatadaInicial, dataFormatadaFinal)
            .then(response => {
                this.setState({receitas: response.data});
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({
                    severity: 'error',
                    summary: 'Financeiro - Receitas',
                    detail: `${this.state.detail}`,
                    life: 4000
                });
            })
    }


    async componentDidMount() {
        this.setState({openSpinner: true});

        // await this.listarMeses();


        var data = new Date();
        String(data.getDate()).padStart(2, '0');
        var mes = String(data.getMonth() + 1).padStart(2, '0');
        var ano = data.getFullYear();
        var dataInicial = ano + '-' + mes + '-01';
        var dataFinal = `${ano}-${mes}-30`;

        var dataInicial2 = new Date(dataInicial);
        dataInicial2.setDate(dataInicial2.getDate() + 1)

        var dataFinal2 = new Date(dataFinal);
        dataFinal2.setDate(dataFinal2.getDate() + 1)

        await this.setState({dataInicial: dataInicial2});
        await this.setState({dataFinal: dataFinal2});

        //this.consultarGraficosDespesas(dataInicial,dataFinal);
        //this.consultarGraficosReceitas(dataInicial,dataFinal)
        await this.consultaUnica();

        this.setState({openSpinner: false});
    }

    async abrirModaCadastro(name, position) {
        let state = {
            [`${name}`]: true
        };

        if (position) {
            state = {
                ...state,
                position
            }
        }
        // await this.excluirArquivosTemp();
        // await this.limparCamposCadastro();
        this.setState(state);
    }

    fecharModal(name) {
        this.setState({
            [`${name}`]: false
        });
    }


    getLightTheme() {
        let basicOptions = {
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };

        let horizontalOptions = {
            indexAxis: 'y',
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };

        let stackedOptions = {
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
                tooltips: {
                    mode: 'index',
                    intersect: false
                },
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    stacked: true,
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    stacked: true,
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };

        let multiAxisOptions = {
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                },
                tooltips: {
                    mode: 'index',
                    intersect: true
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    ticks: {
                        min: 0,
                        max: 100,
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y1: {
                    type: 'linear',
                    display: true,
                    position: 'right',
                    grid: {
                        drawOnChartArea: false,
                        color: '#ebedef'
                    },
                    ticks: {
                        min: 0,
                        max: 100,
                        color: '#495057'
                    }
                }
            }
        };

        return {
            basicOptions,
            horizontalOptions,
            stackedOptions,
            multiAxisOptions
        }
    }


    abrirModal(name, position) {
        let state = {
            [`${name}`]: true
        };

        if (position) {
            state = {
                ...state,
                position
            }
        }
        this.setState(state);
    }

    render() {
        const {basicOptions} = this.options;

        return (
            <>
                <NavBar/>
                <Toast ref={(el) => this.toast = el}/>
                <div className="content-wrapper">
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card card-primary card-outline">
                                        <div className="card-header flex-1 gap-1 align-items-center">
                                            <h3 className="card-title">
                                                <i className="fas fa-hand-holding-usd"></i>
                                                <span> Painel financeiro</span>
                                            </h3>

                                            <div style={{textAlign: 'right'}} className="flex-1 gap-1">

                                                <ButtonSm id='CadastrarLancamentoBtn' className={strings.buttonPrimarySm}
                                                          onClick={() => this.abrirModaCadastro('modalCadastro')}
                                                          data-toggle="modal"
                                                          style={{height: '37px'}}
                                                          data-target="#modal-default">
                                                    <i className="fas fa-plus"></i>Cadastrar lançamento</ButtonSm>
                                                <Calendar id="mask"
                                                          value={this.state.dataInicial}
                                                          onChange={(e) => this.setState({dataInicial: e.value})}
                                                          locale="es"
                                                          style={{height: '37px', width: '135px'}}
                                                          dateFormat="dd/mm/yy"
                                                          mask="99/99/9999"/>
                                                <Calendar id="mask"
                                                          value={this.state.dataFinal}
                                                          onChange={(e) => this.setState({dataFinal: e.value})}
                                                          locale="es"
                                                          style={{height: '37px', width: '135px'}}
                                                          dateFormat="dd/mm/yy"
                                                          mask="99/99/9999"/>
                                                <ButtonSm id='MudarPeriodoBtn' style={{height: '37px'}} type="button"
                                                          className={strings.buttonSecondarySm}
                                                          onClick={this.consultaUnica}>
                                                    <i className="pi pi-filter"></i>Mudar período</ButtonSm>
                                            </div>
                                        </div>

                                        <Backdrop
                                            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                                            open={this.state.openSpinner}>
                                            <CircularProgress color="primary"
                                                              style={{width: '130px', height: '130px'}}/>
                                        </Backdrop>

                                        <div className="card-body">
                                            <Progresso classeSecundaria={this.state.classeDiv}/>
                                            <div className="row gap-1">
                                                <CardFincanceiro
                                                    id='CardDespesas'
                                                    title={"Despesas"}
                                                    info={`${this.state.despesaTotal}`}
                                                    onPress={() => this.setState({modalDespesas: true})}
                                                    styles={"cardDespesa"}
                                                />
                                                <CardFincanceiro
                                                id='CardReceitas'
                                                    title={"Receitas"}
                                                    info={this.state.receitaTotal}
                                                    onPress={() => this.setState({modalReceitas: true})}
                                                    styles="cardReceita"
                                                />
                                                <CardFincanceiro
                                                id='CardLancamentos'
                                                    title={"Lançamentos Futuros"}
                                                    info={this.state.futurosTotal}
                                                    onPress={() => this.setState({modalLancamentosFuturos: true})}
                                                    styles="cardVencendoHoje"
                                                />
                                                <CardFincanceiro
                                                id='CardAtrasos'
                                                    title={"Atrasados"}
                                                    info={this.state.totalAtrasados}
                                                    onPress={() => this.setState({modalAtrasados: true})}
                                                    styles="cardPendente"
                                                />
                                                <CardFincanceiro
                                                id='CardSaldos'
                                                    title={"Saldo"}
                                                    info={this.state.saldo}
                                                    styles="cardSaldo"
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="formGroup">
                                                    <div className="card flex justify-content-center">
                                                        <h5>Despesas x Receitas <TooltipInfo tooltip={graficosLegendas.financeiroReceitasDespesas}/></h5>
                                                        <Chart type="bar" data={this.state.basicData2}
                                                               options={basicOptions} options={this.state.options}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="formGroup">
                                                    <div className="card flex justify-content-center"
                                                         style={{alignItems: 'center'}}>
                                                        <h5>Despesas por tipo <TooltipInfo tooltip={graficosLegendas.financeiroDespesas}/></h5>
                                                        <Chart type="pie" data={this.state.pieDespesas}
                                                               style={{position: 'relative', width: '50%'}}
                                                               options={this.state.options}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="formGroup">
                                                    <div className="card flex justify-content-center"
                                                         style={{alignItems: 'center'}}>
                                                        <h5>Receitas por tipo <TooltipInfo tooltip={graficosLegendas.financeiroReceitas}/></h5>
                                                        <Chart type="pie" data={this.state.pieReceitas}
                                                               style={{position: 'relative', width: '50%'}}
                                                               options={this.state.options}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*detalahar despesas e receitas*/}
                        {this.state.modalDespesas && <DespesaOuReceitasDetalhadasDialog
                            Titulo={"Despesas"}
                            open={this.state.modalDespesas}
                            onClose={() => this.fecharModal('modalDespesas')}
                            dataInicial={this.state.dataInicial}
                            dataFinal={this.state.dataFinal}
                            toast={this.toast}
                        />}
                        {this.state.modalReceitas && <DespesaOuReceitasDetalhadasDialog
                            Titulo={"Receitas"}
                            open={this.state.modalReceitas}
                            onClose={() => this.fecharModal('modalReceitas')}
                            dataInicial={this.state.dataInicial}
                            dataFinal={this.state.dataFinal}
                            toast={this.toast}
                        />}

                        {/**Novo Lancamento */}
                        {this.state.modalCadastro &&<CadastrarLancamentoDialog
                            open={this.state.modalCadastro}
                            onClose={()=>this.setState({modalCadastro: false})}
                            refresh={this.consultaUnica}
                            toast={this.toast}
                        />}

                        {/**Detalhar lancamentos futuros */}
                        {this.state.modalLancamentosFuturos && <LancamentosFuturosDialog
                            open={this.state.modalLancamentosFuturos}
                            onClose={() => this.fecharModal('modalLancamentosFuturos')}
                            dataInicial={this.state.dataInicial}
                            dataFinal={this.state.dataFinal}
                            toast={this.toast}
                        />}

                    </section>
                </div>
                        {this.state.modalAtrasados && <AtrasadosDialog
                            open={this.state.modalAtrasados}
                            onClose={() => this.fecharModal('modalAtrasados')}
                            dataInicial={this.state.dataInicial}
                            dataFinal={this.state.dataFinal}
                            toast={this.toast}
                        />}
            </>
        )
    }
}

PainelFinanceiro.contextType = AuthContext;

export default withRouter(PainelFinanceiro)
