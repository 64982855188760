import React, {useContext, useState} from 'react';
import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import {AuthContext} from "../../main/ProvedorAutenticacao";
import AtividadeService from "../../app/service/AtividadeService";
import {strings} from "../../utils/strings";
import {Loading} from "../Loading";

export function HistoricoAtividadeDialog(props) {
    //props
    const { open, historico, onClose, novoHistorico, toast, listarHistorico } = props;
    //states
    const [visibleHistorico, setVisibleHistorico] = useState(false);

    //loading
    const [loading, setLoading] = useState(false);

    //context
    const {usuarioAutenticado} = useContext(AuthContext)

    //service
    const atividadeService = new AtividadeService()

    const acceptExcluirHistorico = async (historico) => {
        setLoading(true)
        await atividadeService.excluirHistorico(historico.codigo, historico.usuario.codigo, usuarioAutenticado.codigo)
            .then(() => {
                listarHistorico();
                toast.show({ severity: 'success', summary: 'Atividades', detail: 'Histórico excluído', life: 4000 });
            }).catch(error => {
                toast.show({ severity: 'error', summary: 'Atividades', detail: `${error.response.data.detail}`, life: 4000 });
            })
        setLoading(false)
    };

    const reject = () => {
        onClose()
    };

    const handleConfirmDialog = (historico) => {
        confirmDialog({
            message: 'Deseja realmente excluir o histórico da atividade?',
            header: 'Exclusão de histórico de atividades',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: ()=> acceptExcluirHistorico(historico),
            reject: reject
        });
    };

    const acoesHistoricoUsuario = (rowData) => {
        if (rowData.tpHistorico === 'A') {
            return (
                    <i className="fa fa-solid fa-robot" title="Incluído de forma automática" />
            )

        } else {
            return (  <i className="pi pi-user-plus" title="Incluído manualmente" />
            )
        }
    }

    const acoesHistorico = (rowData) => {
        if (rowData.tpHistorico !== 'A' && rowData.usuario.codigo === usuarioAutenticado.codigo) {
            return ( <Button icon="pi pi-trash" className="p-button-rounded p-button-text"
                            tooltip="Excluir histórico"
                             id={"btnExcluirHistorico"}
                            onClick={() => handleConfirmDialog(rowData)}
                            tooltipOptions={{ position: 'top' }}
                            style={{ width: '20px', height: '10px' }} />

            )
        }
    }


    return (
        <>
            <Loading open={loading}/>
        <Dialog header="Histórico da atividade"
                autoComplete="nope"
                style={{ width: '50vw' }}
                visible={open}
                footer={
                    <div className={"flex-row"}>
                        {/*<Button label="Fechar"*/}
                        {/*        className={strings.buttonSecondaryStyle}*/}
                        {/*        onClick={() => onClose('displayHistorico')} />*/}
                        <Button label="Incluir"
                                className={strings.buttonPrimaryStyle}
                                onClick={novoHistorico} />

                    </div>
                }
                onHide={() => onClose('displayHistorico')}>
            <DataTable value={historico}
                       paginator
                       responsiveLayout="scroll"
                       paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                       currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}"
                       rows={5}
                       emptyMessage=" "
                       selectionMode="multiple">
                <Column body={acoesHistoricoUsuario}
                        exportable={false}
                        style={{ minWidth: '4rem' }} />
                <Column field="dsHistorico" header="Histórico" />
                <Column field="dtHistorico" header="Data" />
                <Column field="usuario.nome" header="Usuário" />
                <Column body={acoesHistorico}
                        exportable={false}
                        style={{ minWidth: '4rem' }} />
            </DataTable>
        </Dialog>

    {/**Confirmação de exclusão de Histórico */}

            <ConfirmDialog
                visible={visibleHistorico}
                onHide={() => setVisibleHistorico(false)}
                message="Are you sure you want to proceed?"
                header="Confirmation"
                icon="pi pi-exclamation-triangle"
                accept={acceptExcluirHistorico}
                reject={() => setVisibleHistorico(false)}
            />
    </>
    );
}


