import {Dialog} from "primereact/dialog";
import React, {useEffect, useState} from "react";
import {ButtonEtrium} from "./ButtonEtrium";
import UsuarioService from "../app/service/UsuarioService";
import {Password} from "primereact/password";
import {IsNullOrEmpty} from "../utils/IsNullOrEmpy";
import {AuthContext} from "../main/ProvedorAutenticacao";
import Upload from "./Upload/Index";
import FileList from "./FileList";
import {reject, uniqueId} from "lodash";
import filesize from "filesize";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {confirmDialog} from "primereact/confirmdialog";
import ProcessoAutomaticoService from "../app/service/ProcessoAutomaticoService";
import {CERTIFICADO_PROCESSO_AUTO} from "../utils/strings";


export const AdicionarCertificadoProcessoAutoDialog = ({usuario, open, onClose,  toast}) => {

    const [upLoadFiles, setUpLoadFiles] = useState([]);
    const [arquivos, setArquivos] = useState([]);
    const service = new ProcessoAutomaticoService()

    const {setLoading} = React.useContext(AuthContext);
    const [state, setState] = React.useState({
        senha: null,
        confirmSenha: null,
    })


    function stateChange(e) {
        const {value: valor, name: campo} = e.target;
        setState({...state, [campo]: valor});
    }

    useEffect(() => {

    }, []);

    const renderFooter = () => {
        return (
            <div className={"row-etrium"}>
                <ButtonEtrium label={"Confirmar"} onClick={handleConfirmeUpload}/>
            </div>
        );
    };
    const handleUpload = async files => {
        // Limita o envio a apenas 1 arquivo (o primeiro da lista)
        const selectedFile = files[0];

        // Cria o objeto do arquivo para upload
        const upLoadFile = {
            file: selectedFile,
            id: uniqueId(),
            name: selectedFile.name,
            readableSize: filesize(selectedFile.size),
            progress: 0,
            uploaded: false,
            error: false,
            url: selectedFile.url
        };

        // Atualiza a lista de arquivos com apenas o arquivo selecionado
        setUpLoadFiles([upLoadFile]);


        // await processar(upLoadFile);


    }
    const handleDelete = async id => {
        setUpLoadFiles(upLoadFiles.filter(file => file.id !== id))
    }

    const handleConfirmeUpload = async ()=>{
        await upLoadFiles.forEach(processar);
    }

    const processar = async (upLoadFile) => {
        // const usuarioLogado = usuarioAutenticado;
        if (IsNullOrEmpty(state.senha)) {
            toast.show({
                severity: 'error',
                summary: CERTIFICADO_PROCESSO_AUTO.PROCESSO.ALERT_SUMARY_CADASTRO,
                detail: 'Senha obrigatória',
                life: 4000
            });
            return
        }
        if (state.senha !== state.confirmSenha) {
            toast.show({
                severity: 'error',
                summary: CERTIFICADO_PROCESSO_AUTO.PROCESSO.ALERT_SUMARY_CADASTRO,
                detail: 'Senha não coicidem.',
                life: 4000
            });
            return
        }
        const data = new FormData();
        data.append('arquivo', upLoadFile.file);
        data.append('codigo', usuario.codigo) //Envia código do usuario para salvar os arquivos
        data.append('senha', state.senha)
         await service.adicionarUsuarioComCertificadoProcessoAuto(data)
             .then(()=>{
                 toast.show({
                     severity: 'success',
                     summary: CERTIFICADO_PROCESSO_AUTO.PROCESSO.ALERT_SUMARY_CADASTRO,
                     detail: 'Certificado adicionado com sucesso.',
                     life: 4000
                 })
                 listarArquivos()
                 setUpLoadFiles([])
             })
             .catch(error => {
                 toast.show({
                     severity: 'error',
                     summary: CERTIFICADO_PROCESSO_AUTO.PROCESSO.ALERT_SUMARY_CADASTRO,
                     detail: `${error.response.data.detail}`,
                     life: 4000
                 })
             })
    }

    function acoesArquivos() {
        return (
            <>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-text"
                        tooltip="Excluir arquivo"
                        onClick={() => confirmaExclusaoArquivo()}
                        tooltipOptions={{ position: 'top' }}
                        style={{ width: '20px', height: '10px' }} />
            </>
        );
    }

    function  confirmaExclusaoArquivo() {

        confirmDialog({
            message: 'Deseja realmente excluir este arquivo?',
            header: 'Exclusão de arquivos',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: ()=>excluirArquivo(),
            reject: reject
        });
    }

    const excluirArquivo = () => {
        setLoading(true)
        service.deleteAllByUsuario(usuario.codigo)
            .then(async () => {
                toast.show({ severity: 'success', summary: 'Arquivos', detail: 'Arquivo excluído com sucesso', life: 4000 });
                await listarArquivos();
            }).catch(error => {
            toast.show({ severity: 'error', summary: 'Arquivos', detail: `${error.response.data.detail}`, life: 4000 });
        }).finally(()=>{setLoading(false)})
    }

    const listarArquivos = () => {
        service.getUsuarioCertifiedAuthenticationProcesso(usuario.codigo)
            .then((res) => {
            const data = res.data
            setArquivos(data)
        })
            .catch(
                (error) => {
                    const detail = error.response.data.detail || "Erro ao buscar credenciais"
                    toast.show({
                        severity: 'error',
                        summary: 'Erro',
                        detail: `${detail}`,
                        life: 4000
                    })
                }
            )
            .finally(()=>{
                setLoading(
                    false
                )
            })
    }

    useEffect(() => {
        listarArquivos()
    }, []);

    return (
        <Dialog
            header={CERTIFICADO_PROCESSO_AUTO.PROCESSO.ALERT_SUMARY_CADASTRO} autoComplete="nope"
            visible={open}
            style={{width: '50vw'}}
            footer={renderFooter}
            onHide={onClose}

        >
            <div className="col">
                <div className="col-sm-12">
                    <div className="form-group">
                        {/*<div className="container">*/}
                            <Upload onUpload={handleUpload}/>
                            {!!upLoadFiles && (
                                <>
                                <FileList files={upLoadFiles} onDelete={handleDelete}/>
                                <span>Insira a senha para confirmar o upload do arquivo</span>
                                </>
                            )}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
            {<DataTable value={arquivos} responsiveLayout="scroll" size="small"
                            rows={5}
                            emptyMessage="Nenhum arquivo">
                    <Column body={"certi"} header="Arquivo"/>
                    <Column field="usuario.nome" header="Usuário"/>
                    <Column body={acoesArquivos} exportable={false} style={{minWidth: '8rem'}} header="Ações"/>
                </DataTable>}

            <div className="p-fluid m-t-21">
                <div className="form-group">
                    <label htmlFor="nomeStatus">Senha<span className="obrigatorioAsterisco"> *</span></label>
                    <Password id="senha"
                              size="60"
                              autoComplete="nope"
                              toggleMask
                              feedback={false}
                              name={"senha"}
                              value={state.senha}
                              onChange={stateChange}
                              className="p-inputtext-sm p-d-block p-mb-1"
                              placeholder="Senha do certificado"/>

                </div>
                <div className="form-group">
                    <label htmlFor="nomeStatus">Confirmar senha<span className="obrigatorioAsterisco"> *</span></label>
                    <Password id="confirmSenha"
                              size="60"
                              autoComplete="nope"
                              toggleMask
                              feedback={false}
                              name={"confirmSenha"}
                              value={state.confirmSenha}
                              onChange={stateChange}
                              className="p-inputtext-sm p-d-block p-mb-1"
                              placeholder="Senha do certificado"/>

                </div>
            </div>
        </Dialog>
    )
}
