import ApiService from "../apiservice";



class ConfiguracaoNotificacaoService extends ApiService {

    constructor() {
        super("/configuracao/notificacao");
    }

    consultar(cdUsuario) {
        return this.get(`/${cdUsuario}`);
    }
    alterar(cdUsuario, configuracaoNotificacao) {
        return this.put(`/${cdUsuario}`, configuracaoNotificacao);
    }
}
export default ConfiguracaoNotificacaoService