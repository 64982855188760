import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import React, {useContext, useEffect, useState} from "react";
import {Button} from "primereact/button";
import {Loading} from "../../Loading";
import {HeaderPanelFichaProcesso} from "./HeaderPanelFichaProcesso";
import ArquivoProcessoService from "../../../app/service/ArquivoProcessoService";
import {uniqueId} from "lodash";
import filesize from "filesize";
import {confirmDialog} from "primereact/confirmdialog";
import {strings} from "../../../utils/strings";
import Upload from "../../Upload/Index";
import FileList from "../../FileList";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {AuthContext} from "../../../main/ProvedorAutenticacao";

export const DocumentosProcesso = ({
                                       processo,
                                       autor,
                                       reu,
                                       nrCnj,
                                       pasta,
                                       nrProcesso,
                                       toast,
                                   }) => {

    const [upLoadFiles, setUpLoadFiles] = useState([])
    const [arquivos, setArquivos] = useState([])


    const [arquivo, setArquivo] = useState(null)
    const [dsArquivo, setDsArquivo] = useState("")
    const [modalDescricao, setModalDescricao] = useState(false)

    const [loading, setLoading] = useState(false);

    const {usuarioAutenticado} = useContext(AuthContext)

    //service

    const arquivoProcessoService = new ArquivoProcessoService()


    //Upload de Documentos
    const handleUpload = async files => {
        const _upLoadFiles = files.map(file => ({
            file,
            id: uniqueId(),
            name: file.name,
            readableSize: filesize(file.size),
            progress: 0,
            uploaded: false,
            error: false,
            url: file.url
        }))
        setUpLoadFiles(_upLoadFiles.concat(_upLoadFiles))

        await _upLoadFiles.forEach(processar);
    }

    // const updateFile = (id, data) => {
    //     setState({
    //         upLoadFiles: upLoadFiles.map(upLoadFile => {
    //             return id === upLoadFile.id ? {...upLoadFile, ...data} : upLoadFile;
    //         })
    //     })
    // }

    const processar = async (upLoadFile) => {
        const usuarioLogado = usuarioAutenticado;
        const data = new FormData();
        data.append('file', upLoadFile.file);
        data.append('processo', upLoadFile.file, processo.codigo) //Envia código do processo para criar diretório
        data.append('usuario', upLoadFile.file, usuarioLogado.codigo);
        await arquivoProcessoService.incluirArquivo(data)
            .then(() => {
                handleDelete(upLoadFile.id);
                listarArquivosProcesso(processo.codigo);
            }).catch(error => {
                let detail = error.response.data.detail
                toast.show({severity: 'error', summary: 'Arquivos', detail: `${detail}`, life: 4000});
            })
    }
    const listarArquivosProcesso = async (codigo) => {
        setLoading(true)
        await arquivoProcessoService.listarArquivos(codigo)
            .then(response => {
                setArquivos(response.data);
            }).catch(error => {
                let detail = error.response.data.detail
                toast.show({
                    severity: 'error',
                    summary: 'Histórico do processo',
                    detail: `${detail}`,
                    life: 4000
                })
            }).finally(() => setLoading(false))
    }

    const handleDelete = async id => {
        setUpLoadFiles(upLoadFiles.filter(file => file.id !== id))
    }

    async function consultarArquivoDescricao(arquivo) {
        setArquivo(arquivo)
        setDsArquivo(arquivo.dsArquivo)
        // await arquivoProcessoService.consultarArquivo(arquivo.codigo)
        //     .then(response => {
        //         setArquivo(response.data)
        //         setDsArquivo(response.data.dsArquivo)
        //     }).catch(error => {
        //         let detail = error.response.data.detail
        //         toast.show({severity: 'error', summary: 'Arquivos', detail: `${detail}`, life: 4000});
        //     });

        setModalDescricao(true)
    }

    const adicionarDescricao = async () => {
        await arquivoProcessoService.adicionarDescricao({
            codigo: arquivo.codigo,
            nome: arquivo.nome,
            dsArquivo: dsArquivo,
            dataArquivo: arquivo.dataArquivo,
            usuario: arquivo.usuario,
            processo: arquivo.processo
        }).then(() => {
            toast.show({
                severity: 'success',
                summary: 'Arquivos',
                detail: 'Descrição de arquiivo alterada',
                life: 4000
            });
            listarArquivosProcesso(processo.codigo);
        }).catch(error => {
            let detail = error.response.data.detail
            toast.show({severity: 'error', summary: 'Arquivos', detail: `${detail}`, life: 4000});
        }).finally(() => {
            setModalDescricao(false)
        })

    }

    async function download(rowData) {
        await arquivoProcessoService.download(rowData.codigo)
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', rowData.nome); //or any other extension
                document.body.appendChild(link);
                link.click();
            });
    }

    function acoesArquivos(rowData) {
        return (
            <div className="row gap-1">
                <Button icon="pi pi-comment" className="p-button-rounded p-button-text"
                        tooltip="Adicionar descrição ao arquivo"
                        tooltipOptions={{position: 'top'}}
                        onClick={() => consultarArquivoDescricao(rowData)}
                        style={{width: '20px', height: '10px'}}/>
                <Button icon="pi pi-cloud-download" className="p-button-rounded p-button-text"
                        tooltip="Baixar arquivo"
                        onClick={() => download(rowData)}
                        tooltipOptions={{position: 'top'}}
                        style={{width: '20px', height: '10px'}}/>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-text"
                        tooltip="Excluir arquivo"
                        onClick={() => confirmaExclusaoArquivo(rowData)}
                        tooltipOptions={{position: 'top'}}
                        style={{width: '20px', height: '10px'}}/>
            </div>
        );
    }

    const excluirArquivo = async (codigo) => {

        await arquivoProcessoService.excluirArquivo(codigo)
            .then(() => {
                toast.show({
                    severity: 'success',
                    summary: 'Arquivos',
                    detail: 'Arquivo excluído com sucesso',
                    life: 4000
                });
                listarArquivosProcesso(processo.codigo);
            }).catch(error => {
                let detail = error.response.data.detail
                toast.show({severity: 'error', summary: 'Arquivos', detail: `${detail}`, life: 4000});

            })


    }

    function confirmaExclusaoArquivo(arquivo) {
        confirmDialog({
            message: 'Deseja realmente excluir este arquivo?',
            header: 'Exclusão de arquivos',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: () => excluirArquivo(arquivo.codigo)

        })
    }


    function renderFooterDescricaoArquivo() {
        return (
            <div className="row gap-1">
                <Button label="Alterar"
                        className={strings.buttonPrimaryStyle}
                        onClick={adicionarDescricao}/>
            </div>
        );
    }


    useEffect(() => {
        listarArquivosProcesso(processo.codigo).finally()

        // eslint-disable-next-line
    }, [])


    return (
        <>
            <div className={"card-body"}>
                <Loading open={loading}/>
                <HeaderPanelFichaProcesso
                    autor={autor}
                    reu={reu}
                    nrCnj={nrCnj}
                    pasta={pasta}
                    nrProcesso={nrProcesso}
                />
                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <div className="container">

                                <Upload onUpload={handleUpload}/>
                                {!!upLoadFiles && (
                                    <FileList files={upLoadFiles} onDelete={handleDelete}/>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
                <DataTable value={arquivos} paginator responsiveLayout="scroll"
                           size="small"
                           paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                           currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}"
                           rows={10}
                           emptyMessage="Nenhum arquivo">
                    <Column field="nome" header="Arquivo"/>
                    <Column field="dsArquivo" header="Descrição"></Column>
                    <Column field="usuario.nome" header="Usuário"></Column>
                    <Column body={acoesArquivos} exportable={false}
                            style={{minWidth: '8rem'}} header="Ações"/>
                </DataTable>
                {/**Adicionar descrição ao arquivo */}
                <Dialog header="Descrição do arquivo"
                        visible={modalDescricao}
                        style={{width: '40vw'}}
                        footer={renderFooterDescricaoArquivo}
                        onHide={() => setModalDescricao(false)}>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="descricaoArquivo">Descrição</label>
                                <InputText id="descricaoArquivo"
                                           type="text"
                                           style={{width: '100%', height: '37px'}}
                                           value={dsArquivo}
                                           onChange={e => setDsArquivo(e.target.value)}
                                           className="p-inputtext-sm p-d-block p-mb-1"/>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </div>

        </>
    )
}
