import {InputText} from "primereact/inputtext";
import {formatCnj} from "../../utils/nrCnj-format";
import React from "react";


export const InputPesquisaProcesso = ({
                                          processo,
                                          consultarProcessoPorIndice,
                                          excluirPesquisaProcesso,
                                          ...rest
                                      }) => {

    if (!processo) {
        return (
            <div className="flex-column">
                <label>Processo</label>
                <div className="group-row"
                ><InputText
                    {...rest}
                    id="processo" style={{width: '100%', height: '37px'}}
                    type="text"
                    autoComplete="nope"
                    className="p-inputtext-sm p-d-block p-mb-1"
                    placeholder="Digite aqui para pesquisar processos"/>
                    <span className="input-group-prepend pointer">
                                <span
                                    onClick={() => consultarProcessoPorIndice()}
                                    className="input-group-text">
                                    <i className="fas fa-search"></i>
                                </span>
                            </span>
                </div>
            </div>

        )
    } else {
        return (
            <div className="form-group"
                 style={{display: `block`}}
            >
                <strong>Processo: {formatCnj.formatCnjMascarado(processo.nrCnj)}</strong>
                <br/>
                <span
                    onClick={excluirPesquisaProcesso}
                    className="linkCadGrupo" style={{cursor: 'pointer'}}>Desvincular Processo</span>
            </div>)

    }
}
