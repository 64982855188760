import React from 'react'
import {withRouter} from 'react-router-dom'
import {Toast} from 'primereact/toast';
import {Message} from 'primereact/message';

import UsuarioService from '../../app/service/UsuarioService'
import {AuthContext} from '../../main/ProvedorAutenticacao';

import '../css/util.css'
import './login.css'
import Logo from '../../img/etrium-logo-png.png';
import {strings} from "../../utils/strings";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {Loading} from '../../componentes/Loading';
import {RedefinirSenha} from "../../componentes/seguranca/RedefinirSenha";
import {SolicitarUsuarioEmailConfirmDialog} from "../../componentes/seguranca/SolicitarUsuarioEmailConfirmDialog";
import {Password} from "primereact/password";

const baseUrl = process.env.REACT_APP_BASE_URL;

class Login extends React.Component {

    state = {
        email: '',
        senha: '',
        detail: '',
        classColor: "",
        loading: false,
        modalRedefinirSenha: false,
        modalResendEmail: false,
    }

    constructor(props) {
        super(props);
        this.service = new UsuarioService();
    }

    entrar = async (e) => {
        e.preventDefault()
        this.setState({loading: true})
        await this.service.autenticar({
            email: this.state.email,
            senha: this.state.senha
        }).then(async (response) => {
            const data = response.data
            await this.context.iniciarSessao(data);
            this.props.history.push('/home');
            window.location.reload();

        }).catch(erro => {
            // console.log(erro.response.data.detail)
            try {
                let detail = erro.response.data.detail
                this.toast.show({
                    severity: 'error',
                    summary: 'Login',
                    detail: detail,
                    life: 4000
                })
                if (detail.toString().includes("Por favor, verifique seu e-mail para acessar o sistema")) {
                    this.props.history.push("/confirmarEmail")
                    // this.setState({modalResendEmail: true})
                }
            } catch (error) {
                this.toast.show({
                    severity: 'error',
                    summary: 'Login',
                    detail: "Erro inesperado. Tente novamente em alguns instantes",
                    life: 4000
                });
                console.log(erro);
            }

            this.setState({loading: false})
        })

    }

    handleEsqueciSenha = () => {
        this.setState({modalRedefinirSenha: true})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.detail.length > 2) {
            setTimeout(() => {
                this.setState({detail: ""})
            }, 10000)
        }
    }

    isEtrium = () => {
        return !!baseUrl.includes("etrium.com.br") || !!baseUrl.includes("localhost")
    }

    render() {
        return (
            <div className="limiter">
                <Toast ref={(el) => this.toast = el}/>
                <Loading open={this.state.loading}/>
                <div className="container-login100">
                    <div className={"flex flex-row w-full"}>
                        <div className={"full-screen-container"}>
                            {/*imagem jus*/}
                        </div>
                        <div className="container-logo-form">
                            <div className="logo-container">
                                <img className="logo" src={Logo} alt='Logo Etrium'></img>
                            </div>
                            <div className={"form-s"}>
                                <div>
                                    <div>
                                        {/*<h1 className="txt1">Bem vindo!</h1>*/}
                                        {/*<p className="txt2">Acesse sua conta</p>*/}
                                        <h1 className="text-login-h1">Login</h1>
                                        <p className="txt2">Entre na sua conta e inove no mundo jurídico</p>
                                    </div>
                                    <form onSubmit={this.entrar}>
                                        <div className="form-group">
                                            <label>Email</label>
                                            <InputText type="email"
                                                       value={this.state.email}
                                                       autoComplete="none"
                                                       onChange={e => this.setState({email: e.target.value})}
                                                       className="form-control" placeholder="E-mail"/>
                                        </div>
                                        <div className="form-group">
                                            <div>
                                                <label>Senha</label>
                                                <Password
                                                    autoComplete="nope"
                                                    toggleMask
                                                    feedback={false}
                                                    value={this.state.senha}
                                                    style={{
                                                        width: "100%",
                                                        borderColor: this.state.isValidPassword ? 'green' : ''
                                                    }}
                                                    inputStyle={{width: '100%', flex: 1}}
                                                    onChange={e => this.setState({senha: e.target.value})}
                                                    placeholder="Senha"

                                                />
                                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                            </div>
                                            <div className={"flex-row justify-content-end align-items-end"}>
                                                <a className="ml-auto link-etrium-login" onClick={this.handleEsqueciSenha}>
                                                    Esqueceu a senha?
                                                </a>
                                            </div>
                                            {this.state.detail.length > 0 &&
                                                <Message severity={this.state.classColor} text={this.state.detail}/>}

                                            {/* <span className={this.state.classColor}>{this.state.detail}</span> */}
                                        </div>
                                        <div className="form-group">
                                            <Button type={'submit'}
                                                    className={strings.buttonPrimaryStyle + " btn-login100"}
                                            >
                                                Entrar
                                            </Button>
                                        </div>
                                    </form>
                                    <div className="text-center p-t-10">
                                        {this.isEtrium() &&
                                            <>
                                                <div className="divider-login form-group">
                                                    <span className="divider-text">ou</span>
                                                </div>

                                                <a className="link-etrium-login " href={"#/cadastro"}>
                                                    Cadastrar-se
                                                </a><br/>
                                            </>
                                        }
                                        {/*<a className="txt1" href={"#/suporte"}>*/}
                                        {/*    Suporte*/}
                                        {/*</a>*/}

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {this.state.modalRedefinirSenha && <><RedefinirSenha service={this.service}
                                                                     toast={this.toast}
                                                                     open={this.state.modalRedefinirSenha}
                                                                     onClose={() => this.setState({modalRedefinirSenha: false})}/></>}
                {this.state.modalResendEmail && <><SolicitarUsuarioEmailConfirmDialog
                    toast={this.toast}
                    open={this.state.modalResendEmail}
                    onClose={() => this.setState({modalResendEmail: false})}/></>}
                <Button className={"flooting-button-suport"} onClick={()=>this.props.history.push("/suporte")}>
                    <i className={"pi pi-question-circle"} style={{fontSize: 25}}></i>
                </Button>
            </div>
        )
    }
}

Login.contextType = AuthContext
export default withRouter(Login)
