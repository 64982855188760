import ApiService from '../apiservice'

class EmpresaPlanosService extends ApiService {

  constructor() {
    super('/empresa/planos')
  }

  iniciarAvaliacao(codigoEmpresa, tipoPlano) {
    return this.post(`/inicarAvaliacao?empresa=${codigoEmpresa}&tipoPlano=${tipoPlano}`);
  }
  getEmpresaPlanoExpirou(codigoEmpresa) {
    return this.get(`/expirou/${codigoEmpresa}`);
  }
  getEmpresaPlanoHasntPlanSelected(codigoEmpresa) {
    return this.get(`/empresaNaoTemPlano/${codigoEmpresa}`);
  }
  getTiposPlanos() {
    return this.get('/tipos');
  }
}


export default EmpresaPlanosService;
