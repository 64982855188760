import ApiService from '../apiservice'

export default class FinanceiroService extends ApiService {
  constructor() {
    super('/lancamentos')
  }

  listarMeses() {
    return this.get('/meses');
  }

  cadastrarLancamento(lancamento) {
    return this.post('',lancamento);
  }

  cadastrarLancamentoParcelas(parcelas, recorrencia, lancamento) {
    return this.post(`/parcelada?parcelas=${parcelas}&recorrencia=${recorrencia}`,lancamento);
  }

  consultarLancamento(codigo) {
    return this.get(`/${codigo}`);
  }

  depesasPorMes(empresa,dataInicial, dataFinal) {
    return this.get(`/lancamentosDespesaDatas?empresa=${empresa}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`);
  }

  receitasPorMes(empresa, dataInicial, dataFinal) {
    return this.get(`/lancamentosReceitaDatas?empresa=${empresa}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`);
  }

  lancamentosPorTipoPeriodo(empresa, dataInicial, dataFinal, tipo) {
    return this.get(`/lancamentosPorTipoPeriodo?empresa=${empresa}&dataInicial=${dataInicial}&dataFinal=${dataFinal}&tipo=${tipo}`);
  }

  lancamentosPorTipoDescricaoPeriodo(empresa, dataInicial, dataFinal, tipoCodigo, tipo, situacao) {
    let params = `/lancamentosPorTipoDescricaoPeriodo?empresa=${empresa}&dataInicial=${dataInicial}&dataFinal=${dataFinal}&tipoCodigo=${tipoCodigo}&tipo=${tipo}&situacao=${situacao}`
    return this.get(params);
  }

  lancamentoGrafico(empresa, dataInicial, dataFinal, tipo) {
    return this.get(`/lancamentosGrafico?empresa=${empresa}&dataInicial=${dataInicial}&dataFinal=${dataFinal}&tipo=${tipo}`);
  }

  pagarOuCancelarLancamento(lancamento) {
    return this.put(`/pagarOuCancelarLancamento/${lancamento.codigo}`, lancamento);
  }

  pagarOuCancelarLancamentoIndividual(lancamento) {
    return this.put(`/pagarOuCancelarLancamentoIndividual/${lancamento.codigo}`, lancamento);
  }

  reabrirLancamento(lancamento) {
    return this.put(`/reabrirLancamento/${lancamento.codigo}`, lancamento);
  }

  relatorioDetalhado(empresa, dataInicial, dataFinal, tipo, categoria, situacao, codigo, processo, descricao, classificacao, ordem) {
    let params = `/relatorioDetalhado?empresa=${empresa}&dataInicial=${dataInicial}&dataFinal=${dataFinal}&tipo=${tipo}&categoria=${categoria}&situacao=${situacao}&codigo=${codigo}&processo=${processo}&descricao=${descricao}&classificacao=${classificacao}&ordem=${ordem}`

    return this.get(params);
  }

  relatorioResumido(empresa, dataInicial, dataFinal, situacao, relatorio) {
    return this.get(`/relatorioResumido?empresa=${empresa}&dataInicial=${dataInicial}&dataFinal=${dataFinal}
                                                &situacao=${situacao}&relatorio=${relatorio}`);
  }

  vencendoAmanha(empresa) {
    return this.get(`/vencendoAmanha/${empresa}`);
  }

  somaLancamentosFuturos(empresa, dataFinal) {
    return this.get(`/futuros/somar?empresa=${empresa}&dataFinal=${dataFinal}`);
  }

  somarAtrasados(empresa, dataInicial) {
    return this.get(`/atrasados/somar?empresa=${empresa}&dataInicial=${dataInicial}`);
  }
  filtrarAtrasados(empresa, dataInicial,categoria, tipo) {
    let params = `/atrasados/filtrar?empresa=${empresa}&dataInicial=${dataInicial}&categoria=${categoria}`
    if(tipo !== 0 && tipo !== null && tipo){
      params += `&tipo=${tipo}`
    }
    return this.get(params);
  }

  filtrarLancamentosFuturos(empresa, dataFinal,categoria, tipo) {
    let params = `/futuros/filtrar?empresa=${empresa}&dataFinal=${dataFinal}&categoria=${categoria}`
    if(tipo !== 0 && tipo !== null && tipo){
      params += `&tipo=${tipo}`
    }
    return this.get(params);
  }

  consultarAtrasados(empresa, dataInicial) {
    let params = `/atrasados?empresa=${empresa}&dataInicial=${dataInicial}`;
    return this.get(params);
  }

  consultarLancamentosFuturos(empresa, dataFinal) {
    let params = `/futuros?empresa=${empresa}&dataFinal=${dataFinal}`;
    return this.get(params);
  }
}
