import React from 'react'
import {withRouter} from 'react-router-dom'

import {DialogGrupoTrabalho} from '../../componentes/DialogGrupoTrabalho'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Dialog} from 'primereact/dialog'
import {Dropdown} from 'primereact/dropdown';
import {Toast} from 'primereact/toast';
import {ConfirmDialog, confirmDialog} from 'primereact/confirmdialog';

import Navbar from '../../componentes/Navbar'

import GrupoTrabalhoService from '../../app/service/GrupoTrabalhoService'
import UsuarioService from '../../app/service/UsuarioService'
import UsuarioGrupoTrabalhoService from '../../app/service/UsuarioGrupoTrabalhoService'
import {AuthContext} from '../../main/ProvedorAutenticacao'

import './grupos.css'
import {strings} from "../../utils/strings";
import {ButtonSm} from '../../componentes/ButtonSm';
import {IsNullOrEmpty} from "../../utils/IsNullOrEmpy";
import {Loading} from "../../componentes/Loading";
import {ButtonEtrium} from "../../componentes/ButtonEtrium";


class GrupoTrabalho extends React.Component {

  constructor(props) {
    super(props);
    this.grupoService = new GrupoTrabalhoService();
    this.usuarioService = new UsuarioService();
    this.usuariosGrupoTrabalhoService = new UsuarioGrupoTrabalhoService();

    this.state = {
        grupos: [],
        subGrupos: [],
        grupoConsulta: '',
        grupoTrabalho: null,
        //Para incluir usuaŕios nos grupos
        grupo: null,
        usuario: null,
        //Fim de Para incluir usuaŕios nos grupos
        grupoTrabalhoNome: '',
        usuarios: [],
        usuarioGrupoUsuario: null,
        usuarioGrupoGrupo: null,
        usuariosGrupo: [],
        classeDiv: 'hide',
        //Modal de Cadastro
        displayBasic: false,
        displayBasic2: false,
        displayBasic3: false,
        position: 'center',
        //Cadastro de Grupos
        codigo: '',
        nome: '',
        descricao: '',
        empresa: '',
        grupoPai: '',
        detail: '',
        visible: false,
        //Combo de Usuários
        selectUsuario: null,
        openSpinner: false,
        loading: true,
    };
        this.acoes = this.acoes.bind(this);
        this.acoesUsuariosGrupo = this.acoesUsuariosGrupo.bind(this);
        this.accept = this.accept.bind(this);
        this.onUsuarioChange = this.onUsuarioChange.bind(this);
        this.confirmaExclusao = this.confirmaExclusao.bind(this);
    }

    limparCampos = () => {
        this.setState({nome: '', descricao: ''});
    }

    cadastrarGrupo  = async () => {
        if(IsNullOrEmpty(this.state.nome)) {
            this.toast.show({severity:'error', summary: 'Grupo de trabalho', detail:'Informe o nome do grupo de trabalho', life: 90000});
            this.setState({classeDiv: 'hide'})
            return false;
        }
        this.setState({openSpinner: true});
        const usuarioLogado = this.context.usuarioAutenticado
        const empresa = usuarioLogado.empresa;

        await this.grupoService.cadastrarGrupo({
            nome: this.state.nome,
            descricao: this.state.descricao,
            empresa: empresa
        }).then(response => {
            this.toast.show({severity:'success', summary: 'Grupo de trabalho', detail:'Grupo de trabalho cadastrado com sucesso', life: 4000})
            this.fecharModalCadastro('displayBasic')
            this.listarGrupos()
            this.limparCampos()
        }).catch(error => {
            this.setState(error.response.data)
            this.toast.show({severity:'error', summary: 'Grupo de trabalho', detail:`${this.state.detail}`, life: 4000});
        })

        this.setState({openSpinner: false});
    }

    async editar(grupo) {
        this.setState({openSpinner: true});
        await this.grupoService.consultarGrupo(grupo.codigo)
            .then(response => {
                this.setState({nome: response.data.nome})
                this.setState({descricao: response.data.descricao})
                this.setState({codigo: response.data.codigo})
            }).catch(error => {
                this.setState(error.response.data)
                this.toast.show({severity:'error', summary: 'Grupo de trabalho', detail:`${this.state.detail}`, life: 4000});
            })
            this.setState({openSpinner: false});
        this.abrirModaAlterar('displayBasic2')

    }

     alterarGrupoTrabalho = async () => {
        this.setState({openSpinner: true});
        const usuarioLogado = this.context.usuarioAutenticado;
        await this.grupoService.alterarGrupo({
            codigo: this.state.codigo,
            nome: this.state.nome,
            descricao: this.state.descricao,
            empresa: usuarioLogado.empresa,
            grupoPai: this.state.codigo
        }).then(response => {
            this.toast.show({severity:'success', summary: 'Grupo de trabalho', detail:'Grupo de trabalho alterado com sucesso', life: 4000})
            this.fecharModalCadastro('displayBasic2')
            this.listarGrupos()
        }).catch(error => {
            this.setState(error.response.data)
            this.toast.show({severity:'error', summary: 'Grupo de trabalho', detail:`${this.state.detail}`, life: 4000});
        })

        this.setState({openSpinner: false});
    }

    abrirModalCadastro(name, position) {
        let state = {
            [`${name}`]: true
        };

        if (position) {
            state = {
                ...state,
                position
            }
        }
        this.limparCampos();
        this.setState(state);
    }

    abrirModaAlterar(name, position) {
        let state = {
            [`${name}`]: true
        };

        if (position) {
            state = {
                ...state,
                position
            }
        }

        this.setState(state);
    }

    abrirModaUsuariosGrupo(name, position) {
        let state = {
            [`${name}`]: true
        };

        if (position) {
            state = {
                ...state,
                position
            }
        }

        this.setState(state);
    }

    fecharModalCadastro(name) {
        this.setState({
            [`${name}`]: false
        });
    }

    accept() {
        this.excluirGrupoTrabalho();
    }

    confirmaExclusao(grupo) {
        this.setState({codigo: grupo.codigo});
        confirmDialog({
            message: 'Deseja realmente excluir o grupo selecionado?',
            header: 'Exclusão de grupo de trabalho',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: this.accept,
            reject: this.reject
        });
    }

    excluirGrupoTrabalho = async () => {
        this.setState({openSpinner: true});
        await this.grupoService.excluirGrupo(this.state.codigo)
        .then(response =>
            this.toast.show({severity:'success', summary: 'Grupo de trabalho', detail:'Grupo de trabalho excluído com sucesso', life: 4000})
        ).catch(error => {
            this.setState(error.response.data);
            this.toast.show({severity:'error', summary: 'Grupo de trabalho', detail:`${this.state.detail}`, life: 4000});
        })

        this.setState({visible: false});
        this.listarGrupos();
        this.setState({openSpinner: false});
    }

    renderFooterCadastroGrupos(name) {
        return (
            <div className="row-etrium gap-1">
                <Button label="Cadastrar"
                        className={strings.buttonPrimaryStyle}
                        onClick={this.cadastrarGrupo}/>
            </div>
        );
    }

    renderFooterAlterarGrupos(name) {
        return (
            <div className="row-etrium gap-1">
                <Button
                        className={strings.buttonPrimaryStyle}
                          onClick={this.alterarGrupoTrabalho}>Alterar</Button>
             </div>
        );
    }

    renderFooterUsuariosGrupo(name) {
        return (
            <div>
                <ButtonEtrium label="Concluído"
                              buttonType={'primary'}
                        onClick={() => this.fecharModalCadastro(name)}/>
            </div>
        );
    }

    listarGrupos = () => {

        const usuarioLogado = this.context.usuarioAutenticado;

        this.grupoService.listarGrupos(usuarioLogado.empresa.codigo)
            .then(response => {
                this.setState({ grupos: response.data })
            }).catch( error => {
                this.setState(error.response.data);
                this.toast.show({severity:'error', summary: 'Grupo de trabalho', detail:`${this.state.detail}`, life: 4000});
            }).finally(()=>{
                this.setState({loading:false});
        })
    }

    listarSubGrupos(grupo) {
        this.props.history.push(`/subGrupos/${grupo.codigo}`);
    }

    listarUsuarios = async (grupo) => {

        const usuarioLogado = this.context.usuarioAutenticado
        const grupoConsulta = {
            grupo: grupo.codigo,
            empresa: usuarioLogado.empresa.codigo
        }

        await this.usuarioService.listarUsuariosSemGrupo(grupoConsulta)
            .then(response => {
                this.setState({ usuarios: response.data})
        }).catch( error => {
            this.setState(error.response.data);
            this.toast.show({severity:'success', summary: 'Grupo de trabalho', detail:'Nenhum usuário encontrado', life: 4000});
        })
    }

    usuariosGrupo(grupo) {
        this.listarUsuarios(grupo);
        this.listarUsuariosGrupos(grupo);
        this.abrirModaUsuariosGrupo('displayBasic3');
    }

    excluirUsuarioGrupo = async (usuario) => {
        this.setState({loading: true});
        const grupo = usuario.grupo;
        await this.usuariosGrupoTrabalhoService.excluirUsuarioGrupo(usuario.codigo)
            .then(response => {
                this.listarUsuariosGrupos(grupo);
                this.listarUsuarios(grupo);
            }).catch(error => {
                this.toast.show({severity:'error',
                    summary: 'Usuários do grupo',
                    detail: 'Erro ao tentar excluir usuaŕio do grupo de trabalho', life: 4000});
            })
        this.setState({loading: false});
    }

    listarUsuariosGrupos = async (grupo) => {
        this.setState({loading: true});

      await  this.usuariosGrupoTrabalhoService.listarUsuarios(grupo.codigo)
            .then(response => {
                this.setState({usuariosGrupo: response.data})
                this.setState({grupoTrabalho: grupo})
                this.setState({grupoTrabalhoNome: grupo.nome})
            }).catch(error => {
                this.setState({detail: error.response.data})
                this.toast.show({severity:'error', summary: 'Usuários do grupo', detail:`${this.state.detail}`, life: 4000});
            })
        this.setState({loading: false});
    }

    incluirUsuarioGrupo = async () => {
        this.setState({loading: true});

        if(this.state.selectUsuario == null) {
            this.toast.show({severity:'error', summary: 'Usuários do grupo', detail:'Informe o usuário', life: 4000});
            return false;
        }

       await this.usuariosGrupoTrabalhoService.incluirUsuarioGrupo({
            usuario: this.state.selectUsuario,
            grupo: this.state.grupoTrabalho
        }).then(response => {
            console.log("add")
            this.listarUsuariosGrupos(this.state.grupoTrabalho)
            this.listarUsuarios(this.state.grupoTrabalho)
        }).catch(error => {
            this.setState(error.response.data)
            this.toast.show({severity:'error', summary: 'Usuários do grupo', detail:`${this.state.detail}`, life: 4000});
        });
        this.setState({loading: false});
    }

    async onUsuarioChange(e) {
      await this.setState({ selectUsuario: e.value });
      this.incluirUsuarioGrupo();
    }

    componentDidMount() {
        this.listarGrupos();
    }

    // filterGruposPorNome = this.state.grupos.filter(grupo => grupo.nome.includes(this.state.grupoConsulta));

    consultarGrupoPorNome = (value) => {
        const usuarioLogado = this.context.usuarioAutenticado;
        this.setState({grupoConsulta:value})
        const filtroNome = {
            grupoConsulta: value,
            empresa: usuarioLogado.empresa.codigo
        };

        this.grupoService.listarGruposNome(filtroNome)
            .then(response => {
                this.setState({grupos: response.data})
            }).catch(error => {
                console.log('Erro ao consultar por nome');
            })
    }

    acoes(rowData) {
        return (
            <div className="row gap-1">
                <Button icon="pi pi-pencil" className={strings.buttonIconSecundary}
                        onClick={() => this.editar(rowData)}
                        tooltip="Editar grupo de trabalho"
                        tooltipOptions={{position: 'top'}} />
                <Button icon="pi pi-users" className="p-button-rounded p-button-text"
                        onClick={() => this.usuariosGrupo(rowData)}
                        tooltip="Usuário com permissão ao grupo de trabalho"
                        tooltipOptions={{position: 'top'}}/>
                <Button icon="pi pi-sitemap" className="p-button-rounded p-button-text"
                        onClick={() => this.listarSubGrupos(rowData)}
                        tooltip="Sub-grupos de trabalho"
                        tooltipOptions={{position: 'top'}}/>
                <Button icon="pi pi-trash" className={strings.buttonIconDanger}
                        onClick={() => this.confirmaExclusao(rowData)}
                        tooltip="Excluir grupo de trabalho"
                        tooltipOptions={{position: 'top'}}/>
            </div>
        );
    }

    acoesUsuariosGrupo(rowData) {
        return (
            <>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-text"
                    onClick={() => this.excluirUsuarioGrupo(rowData)}/>
            </>
        )
    }

  render() {
    return(
      <>
        <Navbar/>
        <Toast ref={(el) => this.toast = el} />
          <Loading open={this.state.loading}/>
        <div className="content-wrapper">
            {this.state.displayBasic && <DialogGrupoTrabalho open = {this.state.displayBasic} toast={this.toast} close={() => this.fecharModalCadastro('displayBasic')} refrashList={() => this.listarGrupos()} />}

            {/*    /!**Cadastro de Grupos de Trabalho *!/*/}
        {/*    <Dialog header="Cadastro de Grupo de Trabalho"*/}
        {/*            visible={this.state.displayBasic}*/}
        {/*            style={{ width: '40vw' }}*/}
        {/*            footer={this.renderFooterCadastroGrupos('displayBasic')}*/}
        {/*            onHide={() => this.fecharModalCadastro('displayBasic')}>*/}
        {/*        */}
        {/*        <div className="p-fluid">*/}
        {/*            <div className="p-field">*/}
        {/*                <label htmlFor="nomeGrupo">Nome</label>*/}
        {/*                <InputText id="nomeGrupo"*/}
        {/*                           type="text"*/}
        {/*                           value={this.state.nome}*/}
        {/*                           onChange={e => this.setState({nome: e.target.value})}/>*/}
        {/*            </div>*/}
        {/*            <div className="p-field">*/}
        {/*                <label htmlFor="descricao">Descrição</label>*/}
        {/*                <InputText id="descricao"*/}
        {/*                           type="text"*/}
        {/*                           value={this.state.descricao}*/}
        {/*                           onChange={e => this.setState({descricao: e.target.value})}/>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </Dialog>*/}
            {/**Alterar Grupo de Trabalho */}
            <Dialog header="Alterar grupo de trabalho"
                    visible={this.state.displayBasic2}
                    style={{ width: '40vw' }}
                    footer={this.renderFooterAlterarGrupos('displayBasic2')}
                    onHide={() => this.fecharModalCadastro('displayBasic2')}>
                <Loading open={this.state.loading}/>
                <div className="p-fluid">
                    <div className="p-field mb-3">
                        <label htmlFor="nomeGrupo">Nome<span className="obrigatorioAsterisco"> *</span></label>
                        <InputText id="nomeGrupo"
                                   type="text"
                                   maxLength={50}
                                   value={this.state.nome}
                                   onChange={e => this.setState({nome: e.target.value})}/>
                    </div>
                    <div className="p-field">
                        <label htmlFor="descricao">Descrição</label>
                        <InputText id="descricao"
                                   type="text"
                                   maxLength={100}
                                   value={this.state.descricao}
                                   onChange={e => this.setState({descricao: e.target.value})}/>
                    </div>
                </div>
            </Dialog>
            {/**Consultar e adicionar usuários ao Grupo */}
            <Dialog header={`Usuários do grupo de trabalho - (${this.state.grupoTrabalhoNome})`}
                    visible={this.state.displayBasic3}
                    style={{ width: '40vw' }}
                    footer={this.renderFooterUsuariosGrupo('displayBasic3')}
                    onHide={() => this.fecharModalCadastro('displayBasic3')}>
                <Loading open={this.state.loading}/>
                <div class="row">
                    <div class="col-md-12">
                        <div className="p-fluid">
                            <div className="p-field">
                                <label htmlFor="usuarioGrupo">Usuário(Adicionar)</label>
                                <Dropdown value={this.state.selectUsuario} options={this.state.usuarios}
                                        onChange={this.onUsuarioChange}
                                        optionLabel="nome" filter showClear filterBy="nome"
                                        placeholder="Selecione o usuario"
                                        className="p-inputtext-sm p-d-block p-mb-1"/>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
                <div className="row">
                    <div class="col-md-12">

                        <DataTable value={this.state.usuariosGrupo}  paginator responsiveLayout="scroll"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}" rows={5}
                            emptyMessage="Nenhum usuário para este grupo">
                            <Column field="usuario.nome" header="Usuário"></Column>
                            <Column body={this.acoesUsuariosGrupo} exportable={false} style={{ minWidth: '8rem' }}header="Ações"/>
                        </DataTable>
                    </div>
                </div>
            </Dialog>

            <ConfirmDialog visible={this.state.visible}
                           onHide={() => this.setState({ visible: false })}
                           message="Are you sure you want to proceed?"
                           header="Confirmation"
                           icon="pi pi-exclamation-triangle"
                           accept={() => this.excluirGrupoTrabalho}
                           reject={() => this.setState({visible: false})}/>

          <section className="content-header">
            <div className="container-fluid">
             <div className="row">
                <div className="col-md-12">
                  <div className="card card-primary card-outline">
                    <div className="card-header">
                      <h3 className="card-title">
                        <i className="fas fa-boxes"></i>
                        <span> Grupos de trabalho</span>
                      </h3>
                    </div>

                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-7">
                          <span className="input-group gap-1 flex-1">
                            <ButtonSm type="button" className={strings.buttonPrimarySm}
                                    data-toggle="modal"
                                    onClick={() => this.abrirModalCadastro('displayBasic')}
                                    data-target="#modal-default">
                            <i className="fas fa-plus"></i>Cadastrar</ButtonSm>
                              <div className = "flex-1">
                                <input id='ConsultaGT' type="text" className="form-control"
                                    placeholder="Consulte um grupo de trabalho"
                                    value={this.state.grupoConsulta}
                                    onChange={e => this.consultarGrupoPorNome (e.target.value)}
                                    // onKeyDown={this.consultarGrupoPorNome}
                                    onKeyPress={event => event.key === 'Enter' && this.consultarGrupoPorNome(this.state.grupoConsulta)}/>
                                <span className="input-group-prepend">
                                    <button className="input-group-text">
                                    <i className="fas fa-search"></i>
                                    </button>
                                </span>
                            </div>
                          </span>
                        </div>
                      </div>

                    </div>

                    <DataTable value={this.state.grupos}  paginator responsiveLayout="scroll"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}" rows={10}>
                        <Column field="nome" header="Nome"></Column>
                        <Column field="descricao" header="Descrição"></Column>
                        <Column key="codigo"></Column>
                        <Column body={this.acoes} exportable={false} style={{ minWidth: '8rem' }}header="Ações">
                        </Column>
                    </DataTable>

                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div>
        </div>
      </>
    )
  }
}

GrupoTrabalho.contextType = AuthContext;
export default withRouter (GrupoTrabalho)
