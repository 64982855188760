import React, {useState} from "react";
import {Dialog} from "primereact/dialog";
import {Chart} from "primereact/chart";
import {graficosLegendas} from "../utils/strings";
import {TooltipInfo} from "./TooltipInfo";

function ChartDialog({ title, chartData, chartOptions }) {
  const [visible, setVisible] = useState(false);

  const showDialog = () => {
    setVisible(true);
  };

  const hideDialog = () => {
    setVisible(false);
  };

  const legendaGrafico = getLegendaGrafico()

      function getLegendaGrafico() {
        if(title === "Situação"){
            return graficosLegendas.atividadeSituacaoGeral
        }
        if(title === "Por status"){
            return graficosLegendas.atividadeStatusGeral
        } if(title === "Por grupo de trabalho"){
            return graficosLegendas.atividadeGrupoTrabalho
        }if(title === "Grupo de trabalho"){
            return graficosLegendas.processosGrupoTrabalho
        }if(title === "Status processual"){
            return graficosLegendas.processoStatusProcessual
        }if(title === "Área de atuação"){
            return graficosLegendas.processosAreaAtuacao
        }if(title === "Objetos de ação"){
            return graficosLegendas.processosObjetos
        }if(title === "Tipos de ação"){
            return graficosLegendas.processosTipoAcao
        }
          return "Legenda não encontrada"
      }

  return (
    <>
      {title === "Cadastrados x Arquivados" ? (
        <div
          className="card flex flex-column align-items-center"
          style={{ alignItems: "center", height: "100%" }}
          onClick={showDialog}
        >
          <h5>{title}
              <TooltipInfo tooltip={graficosLegendas.processosCadastradosArquivados}/>
             </h5>
          <Chart
            type={chartData.type}
            data={chartData.data}
            style={{ position: "relative", width: "100%" }}
            options={chartOptions}
          />
        </div>
      ) : (
        <div
          className="card flex flex-column align-items-center"
          style={{ alignItems: "center", height: "100%" }}
          onClick={showDialog}
        >
          <h5>{title}<TooltipInfo tooltip={legendaGrafico}/></h5>
          <Chart
            type={chartData.type}
            data={chartData.data}
            style={{ position: "relative", width: "100%" }}
            options={chartOptions}
          />
        </div>
      )}

      <Dialog
        header={title}
        visible={visible}
        onHide={hideDialog}
        style={{ width: "50vw" }}
      >
        <Chart
          type={chartData.type}
          data={chartData.data}
          style={{ position: "relative", width: "100%" }}
          options={chartOptions}
        />
      </Dialog>
    </>
  );
}

export default ChartDialog;
