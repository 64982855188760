import ApiService from '../apiservice'

export default class CustasProcessoService extends ApiService {

  constructor() {
    super('/custasProcesso');
  }

  listarCustas(codigo) {
    return this.get(`/${codigo}`);
  }

  excluirCustas(codigo) {
    return this.delete(`/${codigo}`);
  }

  incluirCustas(custas) {
    return this.post('',custas);
  }
}
