export function substituiStringVaziaPorNull(obj) {
    const newObj = {};

    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            newObj[key] = obj[key] === "" ? null : obj[key];
        }
    }

    return newObj;
}
export function alterarCamposVaziosObjeto(obj) {
    const newObj = {};

    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                newObj[key] = substituiStringVaziaPorNull(obj[key]);
            } else {
                newObj[key] = obj[key] === "" || obj[key] === undefined ? null : obj[key];
            }
        }
    }

    return newObj;
}

export function undefinedToNull(obj){
    const newObj = {};

    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
                newObj[key] = obj[key] === undefined ? null : obj[key];
        }
    }
    return newObj
}

export function removerCamposVaziosENulos(obj) {
    const newObj = {};

    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];

            if (typeof value === 'object' && value !== null) {
                const nestedObj = removerCamposVaziosENulos(value);
                if (Object.keys(nestedObj).length > 0) {
                    newObj[key] = nestedObj;
                }
            } else if (value !== null && value !== undefined && value !== '') {
                newObj[key] = value;
            }
        }
    }

    return newObj;
}
