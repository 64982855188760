import React from 'react'

import {withRouter} from 'react-router-dom'

import {AuthContext} from '../../main/ProvedorAutenticacao'
import {Toast} from 'primereact/toast';
import {Chart} from 'primereact/chart';

import GraficoProcessoService from '../../app/service/GraficoProcessoService'
import FinanceiroService from '../../app/service/FinanceiroService'

import NavBar from '../../componentes/Navbar'
import './processo.css'
import {nomesMeses} from "../../utils/nomes-meses";
import {ProcessosFiltradoDialog} from "../../componentes/Processo/Gerencial/ProcessosFiltradoDialog";
import {CardProcess} from "../../componentes/Processo/Gerencial/CardProcess";
import {Loading} from "../../componentes/Loading";
import ChartDialog from '../../componentes/ChartDialog';

class PainelGerencialProcesso extends React.Component {
    constructor(props) {
        super(props);

        this.graficoProcessoService = new GraficoProcessoService();
        this.financeiroService = new FinanceiroService();

        this.state = {
            meses: [],
            totalProcessos: '',
            totalAtivos: [],
            totalAtivoPercentual: '',
            totalExcluidos: [],
            totalExcluidosPercentual: '',
            totalArquivados: [],
            totalArquivadosPercentual: '',
            totalParados: [],
            totalParadosPercentual: '',
            totalProcessosPush: [],
            totalProcessosPushPercentual: '',
            statusProcessual: [],
            pieStatusProcessual: [],
            grupoTrabalho: [],
            pieGrupoTrabalho: [],
            areaAtuacao: [],
            pieAreaAtuacao: [],
            tipoAcao: [],
            pieTipoAcao: [],
            objetosAcao: [],
            pieObjetosAcao: [],
            openSpinner: false,
            detail: '',
            arquivados: [],
            cadastrados: [],
            graficoArquivadosCadastrados: null,
            modalAtivos: false,
            modalParados: false,
            modalArquivados: false,
            // modal: false,
            modalPush: false,
            loading: true,
            options: {
                plugins: {
                    legend: {
                        position: 'bottom',
                    }
                },
            },
        }

        this.options = this.getLightTheme();
    }
    totalProcessos = (usuario) => {
        this.graficoProcessoService.totalProcessos(usuario.empresa.codigo)
            .then(response => {
                this.setState({totalProcessos: response.data});
            }).catch(error => {
            this.setState(error.response.data);
            this.toast.show({
                severity: 'error',
                summary: 'Processos - Total',
                detail: `${this.state.detail}`,
                life: 4000
            });
        })
    }

    processosAtivos = async () => {
        const usuario = this.context.usuarioAutenticado;
        let total = 0
        await this.graficoProcessoService.totalAtivos(usuario.empresa.codigo)
            .then(response => {
                total = response.data.length
                this.setState({totalAtivos: response.data});
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({
                    severity: 'error',
                    summary: 'Processos - Ativos',
                    detail: `${this.state.detail}`,
                    life: 4000
                });
            });

        const percent = parseInt(total) / parseInt(this.state.totalProcessos) * 100;
        this.setState({totalAtivoPercentual: percent.toFixed(0)});
    }

    processosParados = async () => {
        const usuario = this.context.usuarioAutenticado;
        let total = 0
        await this.graficoProcessoService.totalParados(usuario.empresa.codigo)
            .then(response => {
                total = response.data.length
                this.setState({totalParados: response.data});
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({
                    severity: 'error',
                    summary: 'Processos - Parados',
                    detail: `${this.state.detail}`,
                    life: 4000
                });
            });

        const percent = (parseInt(total) / parseInt(this.state.totalProcessos)) * 100;
        this.setState({totalParadosPercentual: percent.toFixed(0)});
    }

    processosArquivados = async () => {
        const usuario = this.context.usuarioAutenticado;
        let total = 0
        await this.graficoProcessoService.totalArquivados(usuario.empresa.codigo)
            .then(response => {
                total = response.data.length
                this.setState({totalArquivados: response.data});
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({
                    severity: 'error',
                    summary: 'Processos - Arquivados',
                    detail: `${this.state.detail}`,
                    life: 4000
                });
            }).finally(
                () => {
                    const percent = (parseInt(total) / parseInt(this.state.totalProcessos)) * 100;
                    this.setState({totalArquivadosPercentual: percent.toFixed(0)});
                }
            )

    }

    processosExcluidos = async () => {
        const usuario = this.context.usuarioAutenticado;
        let total = 0
        await this.graficoProcessoService.totalExcluidos(usuario.empresa.codigo)
            .then(response => {
                total = response.data.length
                this.setState({totalExcluidos: response.data});
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({
                    severity: 'error',
                    summary: 'Processos - Excluidos',
                    detail: `${this.state.detail}`,
                    life: 4000
                });
            });

        const percent = (parseInt(total) / parseInt(this.state.totalProcessos)) * 100;
        this.setState({totalExcluidosPercentual: percent.toFixed(0)});
    }

    processosPush = async () => {
        const usuario = this.context.usuarioAutenticado;
        let total = 0
        await this.graficoProcessoService.totalProcessosPush(usuario.empresa.codigo)
            .then(response => {
                total = response.data.length
                this.setState({totalProcessosPush: response.data});
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({
                    severity: 'error',
                    summary: 'Processos - Push',
                    detail: `${this.state.detail}`,
                    life: 4000
                });
            });

        const percent = (parseInt(total) / parseInt(this.state.totalProcessos)) * 100;
        this.setState({totalProcessosPushPercentual: percent.toFixed(0)});
    }

    statusProcessual = async (usuario) => {
        await this.graficoProcessoService.graficoStatusProcessual(usuario.empresa.codigo)
            .then(response => {
                this.setState({statusProcessual: response.data});
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({
                    severity: 'error',
                    summary: 'Processos - Gráficos',
                    detail: `${this.state.detail}`,
                    life: 4000
                });
            });

        //Nome
        let nomes = [];
        for (let i = 0; i < this.state.statusProcessual.length; i++) {
            nomes.push(this.state.statusProcessual[i].nome);
        }
        //Cores
        let cores = [];
        for (let i = 0; i < this.state.statusProcessual.length; i++) {
            cores.push(this.state.statusProcessual[i].cor);
        }

        //Quantidade
        let valores = [];
        for (let i = 0; i < this.state.statusProcessual.length; i++) {
            valores.push(this.state.statusProcessual[i].quantidade);
        }
        let labels = nomes.map((nome, index) => `${nome} - (${valores[index]})`);

        this.setState({
            pieStatusProcessual: {
                labels: labels,
                datasets: [
                    {
                        data: valores,
                        backgroundColor: cores,
                    }
                ]
            }
        });
    }

    grupoTrabalho = async (usuario) => {
        await this.graficoProcessoService.graficoGrupoTrabalho(usuario.empresa.codigo)
            .then(response => {
                this.setState({grupoTrabalho: response.data});
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({
                    severity: 'error',
                    summary: 'Processos - Gráficos',
                    detail: `${this.state.detail}`,
                    life: 4000
                });
            });

        //Nome
        let nomes = [];
        for (let i = 0; i < this.state.grupoTrabalho.length; i++) {
            nomes.push(this.state.grupoTrabalho[i].nome);
        }
        console.log(nomes)
        //Cores
        let cores = [];
        for (let i = 0; i < this.state.grupoTrabalho.length; i++) {
            cores.push(this.state.grupoTrabalho[i].cor);
        }

        //Quantidade
        let valores = [];
        for (let i = 0; i < this.state.grupoTrabalho.length; i++) {
            valores.push(this.state.grupoTrabalho[i].quantidade);
        }

        const labels = [];
        for (let i = 0; i < this.state.grupoTrabalho.length; i++) {
            const grupo = this.state.grupoTrabalho[i];
            labels.push(`${grupo.nome} - (${grupo.quantidade})`);
        }
        

        this.setState({
            pieGrupoTrabalho: {
                labels: labels,
                datasets: [
                    {
                        data: valores,
                        backgroundColor: cores,
                    }
                ]
            }
        });
    }

    areaAtuacao = async (usuario) => {
        await this.graficoProcessoService.graficoAreaAtuacao(usuario.empresa.codigo)
            .then(response => {
                this.setState({areaAtuacao: response.data});
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({
                    severity: 'error',
                    summary: 'Processos - Gráficos',
                    detail: `${this.state.detail}`,
                    life: 4000
                });
            });

        //Nome
        let nomes = [];
        for (let i = 0; i < this.state.areaAtuacao.length; i++) {
            nomes.push(this.state.areaAtuacao[i].nome);
        }
        //Cores
        let cores = [];
        for (let i = 0; i < this.state.areaAtuacao.length; i++) {
            cores.push(this.state.areaAtuacao[i].cor);
        }

        //Quantidade
        let valores = [];
        for (let i = 0; i < this.state.areaAtuacao.length; i++) {
            valores.push(this.state.areaAtuacao[i].quantidade);
        }
        // Configuração dos rótulos do gráfico
        let labels = nomes.map((nome, index) => `${nome} - (${valores[index]})`);

        this.setState({
            pieAreaAtuacao: {
                labels: labels,
                datasets: [
                    {
                        data: valores,
                        backgroundColor: cores,
                    }
                ]
            }
        });
    }

    tipoAcao = async (usuario) => {
        await this.graficoProcessoService.graficoTipoAcao(usuario.empresa.codigo)
            .then(response => {
                this.setState({tipoAcao: response.data});
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({
                    severity: 'error',
                    summary: 'Processos - Gráficos',
                    detail: `${this.state.detail}`,
                    life: 4000
                });
            });

        //Nome
        let nomes = [];
        for (let i = 0; i < this.state.tipoAcao.length; i++) {
            nomes.push(this.state.tipoAcao[i].nome);
        }
        //Cores
        let cores = [];
        for (let i = 0; i < this.state.tipoAcao.length; i++) {
            cores.push(this.state.tipoAcao[i].cor);
        }

        //Quantidade
        let valores = [];
        for (let i = 0; i < this.state.tipoAcao.length; i++) {
            valores.push(this.state.tipoAcao[i].quantidade);
        }

        let labels = nomes.map((nome, index) => `${nome} - (${valores[index]})`);

        this.setState({
            pieTipoAcao: {
                labels: labels,
                datasets: [
                    {
                        data: valores,
                        backgroundColor: cores,
                    }
                ]
            }
        });
    }

    objetosAcao = async (usuario) => {
        await this.graficoProcessoService.graficoObjetosAcao(usuario.empresa.codigo)
            .then(response => {
                this.setState({objetosAcao: response.data});
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({
                    severity: 'error',
                    summary: 'Processos - Gráficos',
                    detail: `${this.state.detail}`,
                    life: 4000
                });
            });

        //Nome
        let nomes = [];
        for (let i = 0; i < this.state.objetosAcao.length; i++) {
            nomes.push(this.state.objetosAcao[i].nome);
        }
        //Cores
        let cores = [];
        for (let i = 0; i < this.state.objetosAcao.length; i++) {
            cores.push(this.state.objetosAcao[i].cor);
        }

        //Quantidade
        let valores = [];
        for (let i = 0; i < this.state.objetosAcao.length; i++) {
            valores.push(this.state.objetosAcao[i].quantidade);
        }
        let labels = nomes.map((nome, index) => `${nome} - (${valores[index]})`);

        this.setState({
            pieObjetosAcao: {
                labels: labels,
                datasets: [
                    {
                        data: valores,
                        backgroundColor: cores,
                    }
                ]
            }
        });
    }


    consultarPainel = async () => {
        const usuarioLogado = this.context.usuarioAutenticado;
        // await this.listarMeses();
        await this.totalProcessos(usuarioLogado);
        await this.processosAtivos(usuarioLogado);
        await this.processosExcluidos(usuarioLogado);
        await this.processosParados(usuarioLogado);
        await this.processosArquivados(usuarioLogado);
        await this.processosPush(usuarioLogado);
        await this.statusProcessual(usuarioLogado);
        await this.grupoTrabalho(usuarioLogado);
        await this.areaAtuacao(usuarioLogado);
        await this.tipoAcao(usuarioLogado);
        await this.objetosAcao(usuarioLogado);
        await this.consultarArquivadosCadastrados(usuarioLogado)
        this.setState({loading:false});

    }


    componentDidMount() {
        this.consultarPainel();
    }

    consultarGraficoArquivados = async (usuario) => {
        const dataAtual = new Date();
        const anoAtual = dataAtual.getFullYear();
        var dataFormatadaInicial = anoAtual + "-01-01";
        var dataFormatadaFinal = anoAtual + "-12-31";
        await this.graficoProcessoService.graficoProcessoArquivado(usuario.empresa.codigo, dataFormatadaInicial, dataFormatadaFinal)
            .then(response => {
                this.setState({arquivados: response.data});
            }).catch(error => {
                console.log('Cadastrados', error);
                this.setState(error.response.data);
                this.toast.show({
                    severity: 'error',
                    summary: 'Processos - Gráficos',
                    detail: `${this.state.detail}`,
                    life: 4000
                });
            })
    }

    consultarGraficoCadastrados = async (usuario) => {
        const dataAtual = new Date();
        const anoAtual = dataAtual.getFullYear();
        var dataFormatadaInicial = anoAtual + "-01-01";
        var dataFormatadaFinal = anoAtual + "-12-31";
        await this.graficoProcessoService.graficoProcessoMes(usuario.empresa.codigo, dataFormatadaInicial, dataFormatadaFinal)
            .then(response => {
                this.setState({cadastrados: response.data});
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({
                    severity: 'error',
                    summary: 'Processos - Gráficos',
                    detail: `${this.state.detail}`,
                    life: 4000
                });
            })
    }

    // listarMeses = async () => {
    //   await this.financeiroService.listarMeses()
    //     .then(response => {
    //       this.setState({meses: response.data});
    //     }).catch(error => {
    //       this.setState(error.response.data);
    //       this.toast.show({severity:'error', summary: 'Financeiro - Meses', detail:`${this.state.detail}`, life: 4000});
    //     })
    // }

    consultarArquivadosCadastrados = async (usuario) => {
        await this.consultarGraficoCadastrados(usuario);
        await this.consultarGraficoArquivados(usuario);

        // let nomesMeses = [];
        //
        // for(let k = 0; k < this.state.meses.length; k++) {
        //   nomesMeses.push(this.state.meses[k].mes);
        // }

        let arquivados = [];
        for (let i = 0; i < this.state.arquivados.length; i++) {
            arquivados.push(this.state.arquivados[i].total);
        }

        let cadastrados = [];
        for (let j = 0; j < this.state.cadastrados.length; j++) {
            cadastrados.push(this.state.cadastrados[j].total);
        }

        await this.setState({
            basicData2: {
                labels: nomesMeses,
                datasets: [
                    {

                        label: 'Cadastrados',
                        backgroundColor: '#008000',
                        data: cadastrados
                    },
                    {

                        label: 'Arquivados',
                        backgroundColor: '#cccccc',
                        data: arquivados
                    }
                ]
            }
        })
    }

    getLightTheme() {
        let basicOptions = {
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };

        let horizontalOptions = {
            indexAxis: 'y',
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };

        let stackedOptions = {
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
                tooltips: {
                    mode: 'index',
                    intersect: false
                },
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    stacked: true,
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    stacked: true,
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };

        let multiAxisOptions = {
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057',
                    }
                },
                tooltips: {
                    mode: 'index',
                    intersect: true
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    ticks: {
                        min: 0,
                        max: 100,
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y1: {
                    type: 'linear',
                    display: true,
                    position: 'right',
                    grid: {
                        drawOnChartArea: false,
                        color: '#ebedef'
                    },
                    ticks: {
                        min: 0,
                        max: 100,
                        color: '#495057'
                    }
                }
            }
        };

        return {
            basicOptions,
            horizontalOptions,
            stackedOptions,
            multiAxisOptions
        }
    }

    abrirModal = (
        name,
        // position
    ) => {
        this.setState({[`${name}`]: true})
    }
    fecharModal = (name) => {
        this.setState({
            [`${name}`]: false
        });
    }


    render() {
        // const {basicOptions} = this.options;
        return (
          <>
            <Loading open={this.state.loading} />
            {this.state.modalAtivos && (
              <ProcessosFiltradoDialog
                processos={this.state.totalAtivos}
                open={this.state.modalAtivos}
                onClose={() => this.fecharModal("modalAtivos")}
                toast={this.toast}
                refresh={this.processosAtivos}
                tipo={"Ativos"}
              />
            )}
            {this.state.modalParados && (
              <ProcessosFiltradoDialog
                processos={this.state.totalParados}
                open={this.state.modalParados}
                onClose={() => this.fecharModal("modalParados")}
                toast={this.toast}
                refresh={this.processosParados}
                tipo={"Parados"}
              />
            )}
            {this.state.modalArquivados && (
              <ProcessosFiltradoDialog
                processos={this.state.totalArquivados}
                open={this.state.modalArquivados}
                onClose={() => this.fecharModal("modalArquivados")}
                toast={this.toast}
                refresh={this.processosArquivados}
                tipo={"Arquivados"}
              />
            )}
            {this.state.modalPush && (
              <ProcessosFiltradoDialog
                processos={this.state.totalProcessosPush}
                open={this.state.modalPush}
                onClose={() => this.fecharModal("modalPush")}
                toast={this.toast}
                refresh={this.processosPush}
                tipo={"Push"}
              />
            )}
            <NavBar />
            <Toast ref={(el) => (this.toast = el)} />
            <div className="content-wrapper">
              <div className="container-fluid">
                <section className="content-header">
                  <div className="card card-primary card-outline">
                    <div className="card-header">
                      <h3 className="card-title">
                        <i className="nav-icon fas fa-balance-scale"></i>
                        <span> Painel de processos</span>
                      </h3>
                    </div>

                    <div className="card-body">
                      <br></br>
                      <div className="flex-row gap-1">
                        <CardProcess
                          id={"CardAtivos"}
                          onPress={() => this.abrirModal("modalAtivos")}
                          title={"Ativos"}
                          info={`${this.state.totalAtivos.length} - (${this.state.totalAtivoPercentual}%)`}
                        />
                        <CardProcess
                          id={"CardParados"}
                          onPress={() => this.abrirModal("modalParados")}
                          title={"Parados 90 dias"}
                          info={`${this.state.totalParados.length} - (${this.state.totalParadosPercentual}%)`}
                        />
                        <CardProcess
                          id={"CardArquivados"}
                          onPress={() => this.abrirModal("modalArquivados")}
                          title={"Arquivados"}
                          info={`${this.state.totalArquivados.length} - (${this.state.totalArquivadosPercentual}%)`}
                        />
                        <CardProcess
                          id={"CardPush"}
                          onPress={() => this.abrirModal("modalPush")}
                          title={"Push"}
                          info={`${this.state.totalProcessosPush.length} - (${this.state.totalProcessosPushPercentual}%)`}
                        />
                        <CardProcess
                          id={"CardTotalProcesso"}
                          // onPress={() => this.abrirModal('Total Processos')}
                          title={"Total processos"}
                          info={`${this.state.totalProcessos} - (100%)`}
                          
                        />

                        {/*// <div className="col-sm-2">
                    //   <div className="formGroup">
                    //     <a href className="cursor" onClick={() => this.abrirModal('modalDespesa')}*/}
                        {/*                                data-toggle="modal"*/}
                        {/*                                data-target="#modal-default">*/}
                        {/*      <div className="cardTeste blue">*/}
                        {/*        <h6 className="textoCard">Excluídos</h6>*/}
                        {/*        <br></br>*/}
                        {/*        <p>{this.state.totalExcluidos.length} - ({this.state.totalExcluidosPercentual}%)</p>*/}
                        {/*      </div>*/}
                        {/*    </a>*/}
                        {/*  </div>*/}
                        {/*</div>*/}

                        </div>
                                    <br></br>
                                    <br></br>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="formGroup">
                                            <ChartDialog title="Grupo de trabalho" chartData={{ type: "pie", data: this.state.pieGrupoTrabalho }} chartOptions={this.state.options} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="formGroup">
                                            <ChartDialog title="Status processual" chartData={{ type: "pie", data: this.state.pieStatusProcessual }} chartOptions={this.state.options} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="formGroup">
                                            <ChartDialog title="Área de atuação" chartData={{ type: "pie", data: this.state.pieAreaAtuacao }} chartOptions={this.state.options} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="formGroup">
                                            <ChartDialog title="Objetos de ação" chartData={{ type: "pie", data: this.state.pieObjetosAcao }} chartOptions={this.state.options} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="formGroup">
                                                    <ChartDialog title="Cadastrados x Arquivados" chartData={{ type: "bar", data: this.state.basicData2 }} chartOptions={this.state.options} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="formGroup">
                                                    <ChartDialog title="Tipos de ação" chartData={{ type: "pie", data: this.state.pieAreaAtuacao }} chartOptions={this.state.options} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </>
        )
    }
}

PainelGerencialProcesso.contextType = AuthContext;
export default withRouter(PainelGerencialProcesso)
