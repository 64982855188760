import {Dialog} from "primereact/dialog";
import {Calendar} from "primereact/calendar";
import {InputTextarea} from "primereact/inputtextarea";
import React, {useContext, useState} from "react";
import {Button} from "primereact/button";
import {strings} from "../../../utils/strings";
import {AuthContext} from "../../../main/ProvedorAutenticacao";
import {DateFormat} from "../../../utils/DateFormat";
import {Loading} from "../../Loading";
import {IsNullOrEmpty} from "../../../utils/IsNullOrEmpy";


export const CadastroHistoricoDialog = ({processo, tipoDecisao, open, onClose, refreshList, historicoProcessoService, toast, onCancel}) => {
    const [dataOcorrencia, setDataOcorrencia] = useState("");
    const [historico, setHistorico] = useState("");
    const [loading, setLoading] = useState(false);

    const {usuarioAutenticado} = useContext(AuthContext)

    const incluirHistorico = async () => {
        const usuarioLogado = usuarioAutenticado;

        if (dataOcorrencia === '') {
            toast.show({
                severity: 'error',
                summary: 'Movimentação do processo',
                detail: 'Informe a data de ocorrência',
                life: 4000
            });
            return false;
        }

        if (dataOcorrencia !== '') {
            var dataFormatadaDataOcorrencia = DateFormat(dataOcorrencia);
        }

        setLoading(true);
        if (tipoDecisao){
           await incluirHistoricoComTipoDecisao(usuarioLogado, dataFormatadaDataOcorrencia)
        }else{
            if (IsNullOrEmpty(historico)) {
                toast.show({
                    severity: 'error',
                    summary: 'Movimentação do processo',
                    detail: 'Informe o movimentação',
                    life: 4000
                });
                setLoading(false);

                return false;
            }
           await incluirHistoricoSemTipoDecisao(usuarioLogado, dataFormatadaDataOcorrencia)
        }

    }

    async function incluirHistoricoSemTipoDecisao(usuarioLogado, dataFormatadaDataOcorrencia){
        await historicoProcessoService.incluirHistorico({
            processo: processo,
            historico: historico,
            dataOcorrencia: dataFormatadaDataOcorrencia,
            usuario: usuarioLogado
        }).then(() => {
            toast.show({
                severity: 'success',
                summary: 'Movimentação do processo',
                detail: 'Movimentação incluído com sucesso',
                life: 4000
            });
        }).catch(error => {
            console.log(error.response.data);
            let detail = error.response.data.detail
            toast.show({
                severity: 'error',
                summary: 'Movimentação do processo',
                detail: `${detail}`,
                life: 4000
            });
        });

        refreshList()
        onClose()
        setLoading(false);
    }

   async function incluirHistoricoComTipoDecisao (usuarioLogado, dataFormatadaDataOcorrencia) {
        console.log({
            processo: processo,
            historico: historico,
            dataOcorrencia: dataFormatadaDataOcorrencia,
            tipoDecisao: tipoDecisao,
            usuario: usuarioLogado
        })
        await historicoProcessoService.incluirHistoricoComTipoDecisao({
            processo: processo,
            historico: historico,
            dataOcorrencia: dataFormatadaDataOcorrencia,
            tipoDecisao: tipoDecisao,
            usuario: usuarioLogado
        }).then(() => {
            toast.show({
                severity: 'success',
                summary: 'Movimentação do processo',
                detail: 'Movimentação incluído com sucesso',
                life: 4000
            });
        }).catch(error => {
            let detail = error.response.data.detail
            toast.show({
                severity: 'error',
                summary: 'Movimentação do processo',
                detail: `${detail}`,
                life: 4000
            });
        });

        refreshList()
        onClose()
        setLoading(false);
    }

    function handleClose(){
        if(tipoDecisao){
            onCancel()
        }else {
            onClose()
        }
    }


    function renderFooterCadastroHistorico() {
        return (
            <div className="row gap-1 m-auto">
                <Button label="Cadastrar"
                        id={"cad-movi"}
                        className={strings.buttonPrimaryStyle}
                        onClick={incluirHistorico}/>
            </div>
        );
    }

    return (
        <Dialog header="Cadastrar movimentação"
                visible={open}
                style={{width: '40vw'}}
                footer={renderFooterCadastroHistorico}
                onHide={handleClose}>

            <div className="row">
                <div className="col-sm-5">
                    <div className="form-group">
                        <label htmlFor="dataPagamentoHistorico">Data de
                            Ocorrência</label>
                        <Calendar id="mask"
                                  value={dataOcorrencia}
                                  onChange={(e) => setDataOcorrencia(e.value)}
                                  locale="es"
                                  style={{width: '100%', height: '32px'}}
                                  dateFormat="dd/mm/yy"
                                  mask="99/99/9999"/>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="form-group">
                        <label htmlFor="historico">Movimentação</label>
                        <InputTextarea id="ds-movimentacao"
                                       type="text"
                                       style={{width: '100%'}}
                                       maxLength={500}
                                       value={historico}
                                       onChange={e => setHistorico(e.target.value)}
                                       className="p-inputtext-sm p-d-block p-mb-1"
                                       rows={6} cols={30}/>
                    </div>
                </div>
            </div>
            <Loading open={loading}/>
        </Dialog>
    )
}
