import ApiService from '../apiservice'

export default class GraficoAtividadeService extends ApiService {
  constructor() {
    super('/atividadeGraficos');
  }

  graficoTotal(empresa, dataInicial, dataFinal) {
    return this.get(`/total?empresa=${empresa}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`);
  }

  graficoDataLimite(empresa, dataInicial, dataFinal) {
    return this.get(`/dataLimite?empresa=${empresa}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`)
  }

  graficoDataConcluido(empresa, dataInicial, dataFinal) {
    return this.get(`/concluido?empresa=${empresa}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`)
  }

  graficoTotalUsuario(usuario, dataInicial, dataFinal) {
    return this.get(`/totalUsuario?usuario=${usuario}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`);
  }

  graficoDataLimiteUsuario(usuario, dataInicial, dataFinal) {
    return this.get(`/dataLimiteUsuario?usuario=${usuario}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`)
  }

  graficoDataConcluidoUsuario(usuario, dataInicial, dataFinal) {
    return this.get(`/concluidoUsuario?usuario=${usuario}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`)
  }

  graficoAtividadesStatus(empresa, dataInicial, dataFinal) {
    return this.get(`/status?empresa=${empresa}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`)
  }

  graficoAtividadesStatusUsuario(usuario, dataInicial, dataFinal) {
    return this.get(`/statusUsuario?usuario=${usuario}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`)
  }

  graficoUsuarios(empresa, dataInicial, dataFinal) {
    return this.get(`/usuarios?empresa=${empresa}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`)
  }

  graficoAtividadesSituacao(empresa, dataInicial, dataFinal) {
    return this.get(`/situacao?empresa=${empresa}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`);
  }

  graficoAtividadesSituacaoUsuario(usuario, dataInicial, dataFinal) {
    return this.get(`/situacaoUsuario?usuario=${usuario}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`);
  }

  consultarAtividadesPorUsuario(usuario, dataInicial, dataFinal, situacao, status) {
    return this.get(`/consultaPorUsuario?usuario=${usuario}&dataInicial=${dataInicial}&dataFinal=${dataFinal}&situacao=${situacao}&status=${status}`);
  }

  graficoAtividadeStatusUsuarioPainel(usuario) {
    return this.get(`/graficoStatusUsuarioPainel?usuario=${usuario}`);
  }

  graficoAtividadeGrupo(empresa, dataInicial, dataFinal) {
    return this.get(`/graficoAtividadeGrupo?empresa=${empresa}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`);
  }

  graficoAtividadeGrupoUsuario(usuario, dataInicial, dataFinal) {
    return this.get(`/graficoAtividadeGrupoUsuario?usuario=${usuario}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`);
  }
}