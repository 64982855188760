import React from 'react'

import {withRouter} from 'react-router-dom'

import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Toast} from 'primereact/toast';
import {Dropdown} from 'primereact/dropdown'
import {OverlayPanel} from 'primereact/overlaypanel';
import {Calendar} from 'primereact/calendar'
import {addLocale} from 'primereact/api'
import {AutoComplete} from 'primereact/autocomplete';

import NavBar from '../../componentes/Navbar';
import {strings} from '../../utils/strings';
import {ButtonSm} from '../../componentes/ButtonSm';

import AtendimentoService from '../../app/service/AtendimentoService'
import PessoaService from '../../app/service/PessoaService'
import GrupoTrabalhoService from '../../app/service/GrupoTrabalhoService'
import {AuthContext} from '../../main/ProvedorAutenticacao'

import './atendimento.css'
import {DateFormat} from "../../utils/DateFormat";
import {IsNullOrEmpty} from "../../utils/IsNullOrEmpy";
import {Loading} from "../../componentes/Loading"
import {Message} from "primereact/message";

class Atendimentos extends React.Component{
  constructor(props) {
    super(props);

    this.atendimentoService = new AtendimentoService();
    this.pessoaService = new PessoaService();
    this.grupoTrabalhoService = new GrupoTrabalhoService();

    this.consultarPessoa = this.consultarPessoa.bind(this);
    this.onGrupoTrabalhoChange = this.onGrupoTrabalhoChange.bind(this);

    this.state = {
      atendimentos: [],
      codigoAtendimento: '',
      dtAtendimento: '',
      pessoa: null,
      pessoas: [],
      grupo: null,
      grupos: [],
      processo: null,
      processos: [],
      assunto: '',
      //Consulta de Atendimentos Filtros
      dataInicial: '',
      dataFinal: '',
      listaPessoas: [],
      selectPessoa: null,
      filterPessoas: null,
      gruposConsulta: [],
      selectGrupo: null,
      loading: true

    };

    addLocale('es', {
      firstDayOfWeek: 1,
      dayNames: ['Domingo', 'Domingo', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
      dayNamesShort: ['Domingo', 'Domingo', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
      dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoy',
      clear: 'Claro'
    });
  }

  listarPessoas = () => {
    this.setState({loading: true})
    const usuarioLogado = this.context.usuarioAutenticado
    this.pessoaService.listarPessoas(usuarioLogado.empresa.codigo)
      .then(response => {
        this.setState({listaPessoas: response.data});
      }).catch(error => {
        this.setState(error.response.data);
        this.toast.show({severity:'error', summary: 'Atendimentos', detail:`${this.state.detail}`, life: 4000});
      })
    this.setState({loading: false})

  }

  listarGruposTrabalho = () => {
    this.setState({loading: true})
    const usuarioLogado = this.context.usuarioAutenticado
    this.grupoTrabalhoService.listarGrupos(usuarioLogado.empresa.codigo)
      .then(response => {
        this.setState({gruposConsulta: response.data});
      }).catch(error => {
        this.toast.show({severity:'error', summary: 'Atendimentos', detail:'Erro ao carregar grupos de trabalhos do usuário', life: 4000});
      });
    this.setState({loading: false})

  }

  onGrupoTrabalhoChange(e) {
    this.setState({selectGrupo: e.value})
  }

  limparCamposConsulta = () => {
    this.setState({dataInicial: ''});
    this.setState({dataFinal: ''});
    this.setState({selectGrupo: null});
    this.setState({selectPessoa: null});
  }

  consultarPessoa(event) {
    this.setState({loading: true})
    setTimeout(() => {
        let filterPessoas;
        if (!event.query.trim().length) {
            filterPessoas = [...this.state.pessoas];
        }
        else {
          if(event.query.toLowerCase().length>2){
            filterPessoas = this.state.listaPessoas.filter((pessoa) => {
                return pessoa.nome.toLowerCase().includes(event.query.toLowerCase());
            });
        }}

        this.setState({ filterPessoas });
    }, 250);
    this.setState({loading: false})

  }

  pad2 = (n) => {
    return (n < 10 ? '0' : '') + n;
  }

  alerts = (msg) => {
    return <div className={'mt-1'}><Message severity="warn" text={msg} /></div>
  }

  consultarAtendimentos = (e =null) => {
    if(e){
      e.preventDefault()
    }
    this.setState({loading: true})
    const usuarioLogado = this.context.usuarioAutenticado

    let pessoa = '0';
    if(!IsNullOrEmpty(this.state.selectPessoa)) {
      pessoa = this.state.selectPessoa[0].codigo;
    }

    let grupo = '0';
    if(!IsNullOrEmpty(this.state.selectGrupo)) {
      grupo = this.state.selectGrupo.codigo;
    }

    //Data Inicial
    var dataFormatadaInicial = '';
    if(!IsNullOrEmpty(this.state.dataInicial)) {
      dataFormatadaInicial =  DateFormat(this.state.dataInicial);
    }

    //Data final
    var dataFormatadaFinal = '';
    if(!IsNullOrEmpty(this.state.dataFinal)) {
      dataFormatadaFinal =  DateFormat(this.state.dataFinal);
    }

    this.atendimentoService.consultarAtendimentos(usuarioLogado.empresa.codigo,
      dataFormatadaInicial, dataFormatadaFinal, pessoa, grupo)
      .then(response => {
        this.setState({atendimentos: response.data});
      }).catch(error => {
        this.setState(error.response.data);
        this.toast.show({severity:'error', summary: 'Atendimentos', detail:`${this.state.detail}`, life: 4000});
      });
    this.setState({loading: false})


    this.op.hide();
  }

  consultarAtendimento(atendimento) {
    this.props.history.push(`/historicoAtendimento/${atendimento.codigo}/atendimento/${atendimento.pessoa.codigo}`)
  }

  novoAtendimento =() => {
    this.props.history.push('/novoAtendimento')
  }

  async componentDidMount() {
    this.setState({loading: true})
    await this.consultarAtendimentos();
    this.listarPessoas();
    this.listarGruposTrabalho();
    this.setState({loading:false})
  }

  render() {
    return(
      <>
        <NavBar/>
        <Loading open={this.state.loading}/>
        <Toast ref={(el) => this.toast = el} />
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-primary card-outline">
                    <div className="card-header">
                      <h3 className="card-title">
                        <i className="pi pi-comments"></i>
                        <span> Atendimentos</span>
                      </h3>
                    </div>

                    <div className="card-body">
                      <div className="row">
                        <div>
                          <span className="input-group gap-1">
                            <ButtonSm type="button" className={strings.buttonPrimarySm}
                                      onClick={this.novoAtendimento}
                                      aria-haspopup aria-controls="overlay_panel">
                              <i className="fas fa-plus"></i> Cadastrar</ButtonSm>
                              <ButtonSm type="button" className={strings.buttonSecondarySm}
                                      data-toggle="modal"
                                      title="Consultar"
                                      onClick={(e) => this.op.toggle(e)}
                                      aria-haspopup aria-controls="overlay_panel">
                              <i className="fas fa-filter"></i></ButtonSm>

                              <OverlayPanel ref={(el) => this.op = el} showCloseIcon id="overlay_panel"
                                            onShow={this.carregaCombosConsulta}
                                            className="overlaypanel-demo">
                                <form onSubmit={this.consultarAtendimentos} >
                                <div className="row">
                                  <div className="col-sm-6">
                                    <div className="form-group">
                                      <label>Data atendimento</label>
                                      <Calendar id="dtPesquisa1"
                                                value={this.state.dataInicial}
                                                onChange={(e) => this.setState({ dataInicial: e.value })}
                                                locale="es"
                                                required
                                                style={{width: '100%', height: '37px'}}
                                                dateFormat="dd/mm/yy"

                                                mask="99/99/9999"/>
                                    </div>
                                  </div>
                                  <div className="col-sm-6">
                                    <div className="form-group gap-1">
                                      <label>&nbsp;</label>
                                      <Calendar id="dtPesquisa2"
                                                value={this.state.dataFinal}
                                                onChange={(e) => this.setState({ dataFinal: e.value })}
                                                locale="es"
                                                required={true}
                                                style={{width: '100%', height: '37px'}}
                                                dateFormat="dd/mm/yy"
                                                mask="99/99/9999"/>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-sm-6">
                                    <div className="form-group">
                                    <label>Pessoa</label>
                                    <AutoComplete value={this.state.selectPessoa}
                                                  suggestions={this.state.filterPessoas}
                                                  completeMethod={this.consultarPessoa}
                                                  style={{width: '207%', height: '37px'}}
                                                  field="nome" multiple
                                                  placeholder="Digite aqui para pesquisar pessoas"
                                                  onChange={(e) => this.setState({selectPessoa: e.value })}
                                                  className="p-autoComplete-sm p-d-block p-mb-0"/>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-sm-12">
                                    <div className="form-group">
                                    <label>Grupo de assuntos</label>
                                    <Dropdown value={this.state.selectGrupo}
                                              options={this.state.gruposConsulta} style={{width: '100%', height: '37px'}}
                                              optionLabel="nome"
                                              filter showClear filterBy="nome"
                                              onChange={this.onGrupoTrabalhoChange}
                                              id="grupoTrabalho"
                                              className="p-inputtext-sm p-d-block p-mb-1"/>
                                    </div>
                                  </div>
                                </div>
                                <div className="direita">
                                  <ButtonSm type="submit" className={strings.buttonSuccessSm}>
                                    <i className="fas fa-check"></i> Consultar
                                  </ButtonSm>
                                </div>
                                </form>
                              </OverlayPanel>
                          </span>
                        </div>
                      </div>
                    </div>
                    <DataTable value={this.state.atendimentos}  paginator responsiveLayout="scroll"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}" rows={10} emptyMessage=" "
                        selectionMode="multiple" onRowClick={(e) => this.consultarAtendimento(e.data)}>
                            <Column field="pessoa.nome" header="Pessoa"></Column>
                            <Column field="grupo.nome" header="Grupo de assuntos"></Column>
                            <Column field="assunto" header="Assunto"></Column>
                            <Column field="dtAtendimento" header="Data atendimento"></Column>
                    </DataTable>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    )
  }
}
Atendimentos.contextType = AuthContext;
export default withRouter(Atendimentos)

