import React, {useContext, useEffect, useState} from 'react'
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";
import {Dropdown} from "primereact/dropdown";
import {MultiSelect} from "primereact/multiselect";
import {Calendar} from "primereact/calendar";
import {Button} from "primereact/button";
import {DateFormat, DateFormatHour, obterDataApartirDeDataString, verificaHoraZerada} from "../../utils/DateFormat";
import {AuthContext} from "../../main/ProvedorAutenticacao";
import GrupoTrabalhoService from "../../app/service/GrupoTrabalhoService";
import UsuarioGrupoTrabalhoService from "../../app/service/UsuarioGrupoTrabalhoService";
import {strings} from '../../utils/strings';
import AtividadeService from "../../app/service/AtividadeService";
import {DialogPesquisaProcesso} from "./DialogPesquisaProcesso";
import ProcessoService from "../../app/service/ProcessoService";
import {formatCnj} from "../../utils/nrCnj-format";
import {InputTextarea} from "primereact/inputtextarea";
import {HistoricoAtividadeDialog} from "./HistoricoAtividadeDialog";
import {IncluirHistoricoDialog} from "./IncluirHistoricoDialog";
import {AnexarArquivosDialog} from "./AnexarArquivosDialog";
import StatusAtividadeService from "../../app/service/StatusAtividadeService";
import {IsNullOrEmpty} from "../../utils/IsNullOrEmpy";

export const DialogAlterarAtividade = ({isOpen, onClose, data, statusAtiv, toast, refresh}) => {
    //states
    const [atividade, setAtividade] = useState({...data});
    //dropdown options list
    const [responsaveis, setResponsaveis] = useState([])
    const [interessados, setInteressados] = useState([])
    const [statusAtividade, setStatusAtividade] = useState(statusAtiv);
    const [grupos, setGrupos] = useState("");
    const [subGrupos, setSubGrupos] = useState("");
    //inputs
    const [titulo, setTitulo] = useState("");
    const [selectGrupo, setSelectGrupo] = useState("");
    const [selectSubGrupo, setSelectSubGrupo] = useState("");
    const [status, setStatus] = useState("");
    const [processo, setProcesso] = useState(null);
    const [pesquisaProcesso, setPesquisaProcesso] = useState('');
    const [responsaveisLista, setResponsaveisLista] = useState(null)
    const [interessadosLista, setInteressadosLista] = useState(null)
    const [dtLimite, setDtLimite] = useState('');
    const [hora, setHora] = useState('');
    const [dtFatal, setDtFatal] = useState(null);
    //render conditions
    const [openModalProcesso, setOpenModalProcesso] = useState(false)
    const [modalHistorico, setModalHistorico] = useState(false)
    const [modalIncuirHistorico, setModalIncuirHistorico] = useState(false)
    const [modalAnexarArquivo, setModalAnexarArquivo] = useState(false)

    //search list
    const [processos, setProcessos] = useState(null);
    const [historico, setHistorico] = useState(null);
    //context
    const {usuarioAutenticado, setLoading} = useContext(AuthContext)
    //service
    const grupoTrabalhoService = new GrupoTrabalhoService()
    const usuarioGrupoTrabalhoService = new UsuarioGrupoTrabalhoService()
    const atividadeService = new AtividadeService()
    const processoService = new ProcessoService()
    const statusAtividadeService = new StatusAtividadeService()
    const alterarAtividade = async () => {

        const usuarioLogado = usuarioAutenticado;
        if (titulo === '') {
            toast.show({severity: 'error', summary: 'Atividades', detail: 'Informe o título da atividade', life: 4000});
            return false;
        }

        if (atividade.selectGrupo === null) {
            toast.show({severity: 'error', summary: 'Atividades', detail: 'Informe o grupo de trabalho', life: 4000});
            return false;
        }

        if (atividade.selectSubGrupo === null) {
            toast.show({
                severity: 'error',
                summary: 'Atividades',
                detail: 'Informe o sub-grupo de trabalho',
                life: 4000
            });
            return false;
        }

        if (responsaveisLista === null || responsaveisLista.length === 0) {
            toast.show({
                severity: 'error',
                summary: 'Atividades',
                detail: 'A atividade precisa ter pelo menos um responsavel!',
                life: 4000
            });
            return false;
        }
        let novaAtividade = atividade;
        let dataFormatadaDataLimite = '';
        if (IsNullOrEmpty(dtLimite)) {
            toast.show({severity: 'error', summary: 'Atividades', detail: 'Informe a data limite', life: 4000});
            return false;
        } else {

            dataFormatadaDataLimite = DateFormatHour(dtLimite);
        }
        let dataFormatadaDataFatal ;
        if (IsNullOrEmpty(dtFatal)) {
            dataFormatadaDataFatal = null;
        } else {
            dataFormatadaDataFatal = DateFormat(dtFatal);
        }
        setAtividade(novaAtividade);
        setLoading(true);
        const atividadeAlterar = {
            atividade: {
                codigo: novaAtividade.codigo,
                titulo: titulo,
                tipo: verificaHoraZerada(dtLimite) ? "T" : "A",
                descricao: novaAtividade.descricao,
                usuario: usuarioLogado,
                processo: processo,
                dtLimite: dataFormatadaDataLimite,
                dtFatal: dataFormatadaDataFatal,
                status: status,
                subGrupo: selectSubGrupo
            },
            responsaveis: responsaveisLista,
            interessados: interessadosLista
        };


        await atividadeService.alterarAtividade(atividade.dtLimite,
            atividade.status.codigo, usuarioLogado.codigo, atividadeAlterar
        ).then(() => {
            toast.show({
                severity: 'success',
                summary: 'Atividades',
                detail: 'Atividade alterada com sucesso',
                life: 4000
            });
            onClose()
        }).catch(error => {
            console.log(error.response.data)
            let detail = error.response.data.detail
            toast.show({severity: 'error', summary: 'Atividades', detail: `${detail}`, life: 4000});
        }).finally(() => setLoading(false))
        if (refresh) {
            refresh();
        }

    }

    async function onGrupoTrabalhoChange(e) {
        if (e.value) {
            await setSelectGrupo(e.value)
            await consultarSubGrupos(e.value.codigo);
            await setResponsaveisLista([]);
            await setInteressadosLista([]);
            await listarResponsaveis(e.value.codigo);
            await listarInteressados(e.value.codigo);
        }

    }

    function onSubGrupoChange(e) {
        if (e.value) {
            setSelectSubGrupo(e.value)
        }
    }

    function onChangeStatusAtividade(e) {
        setStatus(e.value)
    }

    const listarResponsaveis = async (codigo) => {
        await usuarioGrupoTrabalhoService.listarUsuariosAtividade(codigo)
            .then(response => {
                setResponsaveis(response.data)
            }).catch(() => {
                toast.show({
                    severity: 'error',
                    summary: 'Atividades',
                    detail: 'Erro ao listar responśaveis',
                    life: 4000
                });
            })
    }

    const listarInteressados = async (codigo) => {
        await usuarioGrupoTrabalhoService.listarUsuariosAtividade(codigo)
            .then(response => {
                setInteressados(response.data)
            }).catch(() => {
                toast.show({
                    severity: 'error',
                    summary: 'Atividades',
                    detail: 'Erro ao listar interssados',
                    life: 4000
                });
            })
    }

    const listarHistorico = async () => {
        await atividadeService.consultarHistorico(atividade.codigo)
            .then(response => {
                setHistorico(response.data)
            }).catch(error => {
                toast.show({
                    severity: 'error',
                    summary: 'Atividades',
                    detail: `${error.response.data.detail}`,
                    life: 4000
                });
            })
    }

    async function consultarAtividade() {
        await setAtividade(data);
        setSelectSubGrupo(data.subGrupo);
        await consultarGrupoTrabalhoPai(data.subGrupo.grupoPai);
        await consultarSubGrupos(data.subGrupo.grupoPai);
        if (data.processo !== null) {
            setProcesso(data.processo);
        }
        setDtLimite(new Date(data.dtLimite));
        if (!IsNullOrEmpty(data.dtFatal)) {
            let newDate = obterDataApartirDeDataString(data.dtFatal)
            setDtFatal(newDate);
        } else {
            setDtFatal(null);
        }
        setTitulo(data.titulo)
        setStatus(data.status);
        await consultarGruposTrabalho();
        await consultarResponsaveis();
        await consultarInteressados(data.codigo);
        await consultarStatusAtividade();

        // await listarArquivosAtividade();
        // this.abrirModalAtividadeAlterar('displayAtividade');
    }

    const consultarStatusAtividade = async () => {
        await statusAtividadeService.listarStatus(usuarioAutenticado.empresa.codigo).then((res) => {
            setStatusAtividade(res.data)
        }).catch(() => {
            toast.show({
                severity: 'error',
                summary: 'Atividades',
                detail: 'Erro ao carregar status atividade',
                life: 4000
            })
        })
    }

    const consultarGruposTrabalho = async () => {
        await grupoTrabalhoService.listarGruposUsuario(usuarioAutenticado.codigo)
            .then(response => {
                setGrupos(response.data)
            }).catch(() => {
                toast.show({
                    severity: 'error',
                    summary: 'Atividades',
                    detail: 'Erro ao carregar grupos de trabalhos do usuário',
                    life: 4000
                });
            })
    }
    const consultarGrupoTrabalhoPai = async (codigo) => {
        await grupoTrabalhoService.consultarGrupo(codigo)
            .then(async (response) => {
                const grupo = response.data
                await setSelectGrupo(grupo)
                await listarResponsaveis(grupo.codigo)
                await listarInteressados(grupo.codigo)
            }).catch(() => {
                toast.show({
                    severity: 'error',
                    summary: 'Atividades',
                    detail: 'Erro ao consultar grupo de trabalho',
                    life: 4000
                });
            })
    }

    const consultarInteressados = async (codigo) => {
        await atividadeService.consultarUsuariosAtividade(codigo, 'I')
            .then(async (response) => {
                await setInteressadosLista(response.data);
            }).catch(() => {
                toast.show({
                    severity: 'error',
                    summary: 'Atividades',
                    detail: 'Erro ao listar responsáveis da atividade',
                    life: 4000
                });
            })
    }

    const consultarResponsaveis = async () => {
        await atividadeService.consultarUsuariosAtividade(atividade.codigo, 'R')
            .then(async (response) => {
                await setResponsaveisLista(response.data)

            }).catch(() => {
                toast.show({
                    severity: 'error',
                    summary: 'Atividades',
                    detail: 'Erro ao listar responsáveis da atividade',
                    life: 4000
                });
            })
    }

    const consultarSubGrupos = async (codigo) => {
        await grupoTrabalhoService.listarSubGrupos(codigo)
            .then(response => {
                setSubGrupos(response.data)
            }).catch(() => {
                toast.show({
                    severity: 'error',
                    summary: 'Atividades',
                    detail: 'Erro ao carregar sub-grupos de trabalho',
                    life: 4000
                });
            })
    }

    const consultarProcessoPorIndice = async () => {
        const usuarioLogado = usuarioAutenticado
        const indice = {
            indice: pesquisaProcesso,
            empresa: usuarioLogado.empresa.codigo,
            usuario: usuarioLogado.codigo
        }
        setLoading(true)
        await processoService.consultarProcessoPorIndice(indice)
            .then(response => {
                setProcessos(response.data)
                if (response.data.length > 0) {
                    setOpenModalProcesso(true)
                } else {
                    setPesquisaProcesso('')
                    toast.show({severity: 'error', summary: 'Processo', detail: 'Processo não encontrado', life: 4000});
                }
            }).catch(error => {
                toast.show({
                    severity: 'error',
                    summary: 'Atividades',
                    detail: `${error.response.data.detail}`,
                    life: 4000
                });
            })
        setLoading(false)

    }

    const consultarHistorico = async () => {
        await atividadeService.consultarHistorico(atividade.codigo)
            .then(response => {
                setHistorico(response.data)
                setModalHistorico(true)
            }).catch(error => {
                toast.show({
                    severity: 'error',
                    summary: 'Atividades',
                    detail: `${error.response.data.detail}`,
                    life: 4000
                });
            })
    }

    const excluirPesquisaProcesso = () => {
        setProcesso(null)
        setSelectGrupo(null);
        setSubGrupos(null);
        setSelectSubGrupo(null);
        setResponsaveisLista([]);
        setInteressadosLista([]);
    }

    const setSelectedProcesso = async (data) => {
        setProcesso(data)
        setSelectGrupo(data.grupoTrabalho)
        await consultarSubGrupos(data.grupoTrabalho.codigo);
        await listarResponsaveis(data.grupoTrabalho.codigo);
        await listarInteressados(data.grupoTrabalho.codigo);
    }

    function stateAtividadeChange(name, value) {
        setAtividade({
            ...atividade,
            [`${name}`]: value
        });
    }

    const handleTituloChange = (event) => {
        const newValue = event.target.value;
        if (newValue.length <= 100) {
            setTitulo(newValue);
        }
    };

    async function load() {
        setLoading(true)

        await consultarAtividade().finally(() => {
            setLoading(false)

        })
    }

    useEffect(() => {
        load()
    }, [])


    function renderFooterAtividade() {
        return (
            <div className="etrium-row gap-1 left">
                <Button label="Alterar"
                        onClick={alterarAtividade}
                        className={strings.buttonPrimaryStyle}/>
                <Button label="Anexar"
                        icon="pi pi-paperclip"
                        onClick={() => setModalAnexarArquivo(true)}
                        className={strings.buttonHelp}/>
            </div>
        );
    }

    return (<>
            <Dialog header="Atividade" autoComplete="nope"
                    visible={isOpen}
                    footer={renderFooterAtividade('displayAtividade')}
                    onHide={onClose}>
                {/*<Loading open={loadingProcesso || loading}/>*/}
                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label>Título<span className="obrigatorioAsterisco"> *</span></label>
                            <InputText id="titulo"
                                       style={{width: '100%', height: '37px'}}
                                       type="text"
                                       autoComplete="nope"
                                       value={titulo}
                                       onChange={handleTituloChange}
                                       className="p-inputtext-sm p-d-block p-mb-1"
                            />
                            {/*<span className={"flex-row justify-content-end"}>{100 - titulo.length}</span>*/}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>Data limite<span className="obrigatorioAsterisco"> *</span></label>
                            <Calendar id="mask"
                                      value={dtLimite}
                                      name={'dtLimite'}
                                      onChange={(e) => setDtLimite(e.value)}
                                      locale="es"
                                      style={{width: '100%', height: "37px"}}
                                      dateFormat="dd/mm/yy"
                                      mask="99/99/9999 99:99"
                                      showTime

                            />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>Prazo fatal</label>
                            <Calendar id="dtfatlmask"
                                      value={dtFatal}
                                      onChange={(e) => setDtFatal(e.value)}
                                      locale="es"
                                      style={{width: '100%', height: "37px"}}
                                      dateFormat="dd/mm/yy"
                                      mask="99/99/9999"
                            />
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>Grupo de trabalho<span className="obrigatorioAsterisco"> *</span></label>
                            <Dropdown value={selectGrupo} style={{width: '100%', height: '37px'}}
                                      options={grupos}
                                // disabled={bloqueiaGrupo}
                                      optionLabel="nome"
                                      filter filterBy="nome"
                                      onChange={onGrupoTrabalhoChange}
                                      id="grupoTrabalho"
                                      className="p-inputtext-sm p-d-block p-mb-1"
                            />
                            {/*<span className="linkCadGrupo cursor">Incluir novo Grupo de Trabalho</span>*/}
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>Sub-grupo de trabalho<span className="obrigatorioAsterisco"> *</span></label>
                            <Dropdown value={selectSubGrupo} style={{width: '100%', height: '37px'}}
                                      options={subGrupos}
                                      optionLabel="nome"
                                      emptyMessage=" "
                                      filter filterBy="nome"
                                      onChange={onSubGrupoChange}
                                      id="subGrupoTrabalho"
                                      className="p-inputtext-sm p-d-block p-mb-1"
                            />
                        </div>
                    </div>
                </div>
                <div className="row">

                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>Responsáveis <span className="obrigatorioAsterisco">*</span></label>

                            <MultiSelect inputId="multiselect"
                                         value={responsaveisLista}
                                         options={responsaveis}
                                         id="responsaveis"
                                         style={{width: '100%', height: '37px', padding_top: '1px'}}
                                         onChange={(e) => setResponsaveisLista(e.value)}
                                         filter showClear filterBy="nome"
                                         optionLabel="nome"
                            />
                            {responsaveisLista && <label
                                className="linkCadGrupo">{`Responsaveis da atividade (${responsaveisLista.length})`}</label>
                            }
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>Interessados</label>
                            {/*verificando se o codigo do grupo eh de atendimento, para nao exibir o multiselect*/}
                            {data.subGrupo.grupoPai === 2 && interessadosLista ?
                                <InputText
                                    style={{width: '100%', height: '37px', padding_top: '1px'}}
                                    className="p-inputtext-sm p-d-block p-mb-1"
                                    value={interessadosLista[0].nome}/> :
                                <MultiSelect inputId="multiselect"
                                             value={interessadosLista}
                                             options={interessados}
                                             id="interessados"
                                             style={{width: '100%', height: '37px', padding_top: '1px'}}
                                             onChange={(e) => setInteressadosLista(e.value)}
                                             filter showClear filterBy="nome"
                                             optionLabel="nome"
                                />}
                            {interessadosLista && <label
                                className="linkCadGrupo">{`Interessados da atividade (${interessadosLista.length})`}</label>}
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>Status da atividade</label>
                            <Dropdown value={status}
                                      style={{width: '100%', height: "37px"}}
                                      options={statusAtividade}
                                      optionLabel="status"
                                      emptyMessage=" "
                                      filter showClear filterBy="status"
                                      onChange={onChangeStatusAtividade}
                                      id="idStatusAtividade"
                                      className="p-inputtext-sm p-d-block p-mb-1"/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div
                        // className={state.sizePesquisa}
                        className='col-sm-12'
                    >
                        {!processo ?
                            <div className="form-group">
                                <label>Vincular ao processo</label>
                                <div className="group-row p-inputtext-sm p-d-block p-mb-1">

                                    <InputText id="processo" style={{width: '100%', height: '37px'}}
                                               type="text"
                                               autoComplete="nope"
                                               value={pesquisaProcesso}
                                               onChange={e => setPesquisaProcesso(e.target.value)}
                                               placeholder="Pesquise o processo por pessoa, pasta ou número"/>
                                    <div className="input-group-append ml-negative">
                                        <button className="btn btn-navbar" onClick={consultarProcessoPorIndice}>
                                            <i className="fas fa-search"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="form-group"
                                 style={{display: `block`}}
                            >
                                <strong>Processo: {formatCnj.formatCnjMascarado(processo.nrCnj)}</strong>
                                <br/>
                                <span
                                    onClick={excluirPesquisaProcesso}
                                    className="linkCadGrupo" style={{cursor: 'pointer'}}>Desvincular Processo</span>
                            </div>}
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label>Descrição</label>
                            <InputTextarea id="descricao"
                                           type="text"
                                           autoComplete="nope"
                                           style={{width: '100%'}}
                                           value={atividade.descricao}
                                           onChange={e => stateAtividadeChange('descricao', e.target.value)}
                                           className="p-inputtext-sm p-d-block p-mb-1"
                                           rows={6} cols={30}
                                           maxLength={1000}
                            />
                            <span className={"flex-row justify-content-end"}>{1000 - atividade.descricao.length}</span>
                        </div>
                    </div>
                </div>
                <div className="flex-row gap-1">


                    <div className="form-group">
                    <span className="linkCadGrupoHistorico cursor"
                          onClick={consultarHistorico}
                    >Consultar histórico</span>

                    </div>
                    <div className="form-group">
                    <span className="linkCadGrupoHistorico cursor"
                          onClick={() => setModalAnexarArquivo(true)}
                    >
                        <i className="pi pi-paperclip"></i>
                        &nbsp;
                        Arquivos anexados</span>
                    </div>


                </div>
            </Dialog>

            {openModalProcesso && <DialogPesquisaProcesso
                open={openModalProcesso}
                onClose={() => setOpenModalProcesso(false)}
                processos={processos}
                selecionarProcesso={setSelectedProcesso}

            />}
            {modalHistorico &&
                <HistoricoAtividadeDialog
                    open={modalHistorico}
                    historico={historico} onClose={() => setModalHistorico(false)}
                    novoHistorico={() => setModalIncuirHistorico(true)}
                    toast={toast}
                    listarHistorico={listarHistorico}
                />
            }
            {modalIncuirHistorico && <IncluirHistoricoDialog
                toast={toast}
                atividade={atividade}
                open={modalIncuirHistorico}
                onClose={() => setModalIncuirHistorico(false)}
                listarHistorico={listarHistorico}
            />}
            {modalAnexarArquivo &&
                <AnexarArquivosDialog
                    open={modalAnexarArquivo}
                    onClose={() => setModalAnexarArquivo(false)}
                    atividade={atividade}
                    toast={toast}/>
            }
        </>
    )
}
