import ApiService from '../apiservice'

export default class CentralService extends ApiService {
  constructor() {
    super('/central')
  }

  notificacoesQuantidade(usuario) {
    return this.get(`/notificacoesQuantidade/${usuario}`);
  }

  consultarCentral(usuario, dataInicial, dataFinal) {
    return this.get(`/consulta?usuario=${usuario}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`);
  }

  lerNotificacao(central) {
    return this.put(`/lerNotificacao/${central.codigo}`, central);
  }
  updateVariasNotificacoes(notifications, status) {
    return this.put(`/alterararVariasNotificacoes/${status}`, notifications);
  }

  excluirNotificacoes(codigos) {
    return this.delete(`/excluirNotificacoes?codigos=${codigos}`);
  }
}
