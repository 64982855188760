import ApiService from '../apiservice'

class CidadeService extends ApiService {

  constructor() {
    super('/cidades')
  }

  listarCidades(estado) {
    return this.get(`/${estado}`);
  }
}

export default CidadeService;